import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import arrayMove from 'array-move'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { ListSwitch } from 'components'
import { DragIndicator as DragIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  list: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(4),
    zIndex: 9999,
    overflow: 'visible',
  },
  listItem: {
    zIndex: 9999,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dragging: {
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',
  },
}))

const SortableItem = SortableElement(
  ({ label, isActive, loading, handleSetActive, getIndex, canBeDeactivated }) => {
    const classes = useStyles()

    return (
      <ListItem
        ContainerComponent="div"
        className={classes.listItem}
        button
        disabled={loading}
        divider
      >
        <ListItemIcon>
          <DragIcon />
        </ListItemIcon>
        <ListItemText primary={label} />
        <ListSwitch
          isChecked={isActive}
          onChange={() => handleSetActive(getIndex, !isActive)}
          size="small"
          isDisabled={loading || (!canBeDeactivated && isActive)}
        />
      </ListItem>
    )
  }
)

const SortableListContainer = SortableContainer(
  ({ items, loading, handleSetActive, canBeDeactivated }) => {
    const classes = useStyles()
    return (
      <List className={classes.list} disablePadding component="div">
        {items.map((member, index) => (
          <SortableItem
            key={member.label}
            index={index}
            loading={loading}
            label={member.label}
            isActive={member.is_active}
            handleSetActive={handleSetActive}
            getIndex={index}
            canBeDeactivated={canBeDeactivated}
          />
        ))}
      </List>
    )
  }
)

const DraggableUserList = ({ loading, members, setMembers, canBeDeactivated }) => {
  const classes = useStyles()

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setMembers((items) => arrayMove(items, oldIndex, newIndex))
  }

  const handleSetActive = (index, value) => {
    if (members.filter((x) => x.is_active).length <= 8 && value === true || value === false) {
      const newMembers = [...members]
      newMembers[index] = { ...newMembers[index], is_active: value }
      setMembers(newMembers)
    }
  }

  return (
    <SortableListContainer
      items={members}
      loading={loading}
      onSortEnd={onSortEnd}
      lockAxis="y"
      helperClass={classes.dragging}
      handleSetActive={handleSetActive}
      canBeDeactivated={canBeDeactivated}
    />
  )
}

export default DraggableUserList
