import React from 'react'
import { DrawerWrapper, DrawerSection, Paper, PaperContent, DrawerListItem } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { Slide } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DrawerButtons from 'views/Drawer/components/DrawerButtons'
import { ArrowRightAlt as ChangeArrowIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  changeIcon: {
    verticalAlign: 'middle',
    paddingBottom: 2,
  },
  code: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    border: `2px solid ${theme.palette.background.default}`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.light,
  },
}))

const OrderDrawer = ({ match, history, order }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const closeDrawer = () => {
    history.push(`/${match.params.customer_id}/organisation/ordrer`)
  }
  return (
    <DrawerWrapper closeDrawer={closeDrawer}>
      <Paper>
        <PaperContent>
          <DrawerSection
            title={t('Organization_orders_drawer_title', {
              orderInfo: `${order.order_id} - ${order.typeFormatted}`,
            })}
          />
          <Slide direction="left" in>
            <div>
              <DrawerListItem
                primaryText={t('Organization_orders_drawer_id')}
                secondaryText={order.order_id}
              />
              <DrawerListItem primaryText="Status" secondaryText={order.statusFormatted} />
              <DrawerListItem
                primaryText={t('Organization_orders_drawer_action_type')}
                secondaryText={`${order.typeFormatted} (${order.type})`}
              />
              <DrawerListItem
                primaryText={t('Organization_orders_drawer_orderedby')}
                secondaryText={order.orderedby}
              />
              <DrawerListItem
                primaryText={t('Organization_orders_drawer_ordereddate')}
                secondaryText={`${order.inserted} ${
                  order.actionDate !== null
                    ? t('Organization_orders_drawer_carried_out', { date: order.actionDate })
                    : ''
                }`}
              />
              <DrawerSection
                title={t('Organization_orders_drawer_title_two', {
                  orderInfo: `${order.order_id} - ${order.typeFormatted}`,
                })}
              />
              {Boolean(order.firstName || order.lastName) && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_name')}
                  secondaryText={`${order.firstName} ${order.lastName}`}
                />
              )}
              {order.subscriptionType && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_sub_type')}
                  secondaryText={order.subscriptionType}
                />
              )}
              {order.oldSubscriptionType && order.newSubscriptionType && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_sub_change')}
                  secondaryText={
                    <span>
                      {order.oldSubscriptionType} <ChangeArrowIcon className={classes.changeIcon} />
                      {order.newSubscriptionType}
                    </span>
                  }
                />
              )}
              {order.subscriptionId && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_sub_id')}
                  secondaryText={order.subscriptionId}
                />
              )}
              {order.oldAnumber && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_number_change')}
                  secondaryText={
                    <span>
                      {order.oldAnumber} <ChangeArrowIcon className={classes.changeIcon} />
                      {order.newLandline}
                      {order.newMobile}
                    </span>
                  }
                />
              )}
              {order.landlineNumber && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_landline')}
                  secondaryText={order.landlineNumber}
                />
              )}
              {order.mobileNumber && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_mobile')}
                  secondaryText={order.mobileNumber}
                />
              )}
              {order.mobileType && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_mobile_type')}
                  secondaryText={order.mobileType}
                />
              )}
              {order.originalEmail && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_original_email')}
                  secondaryText={order.originalEmail}
                />
              )}
              {order.set_email && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_newemail')}
                  secondaryText={order.set_email}
                />
              )}
              {order.set_sip && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_newsip')}
                  secondaryText={order.set_sip}
                />
              )}
              {order.set_firstName && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_newname')}
                  secondaryText={order.set_firstName}
                />
              )}
              {order.set_lastName && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_newsurname')}
                  secondaryText={order.set_lastName}
                />
              )}
              {order.set_initials && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_newinitials')}
                  secondaryText={order.set_initials}
                />
              )}
              {order.set_title && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_newtitle')}
                  secondaryText={order.set_title}
                />
              )}
              {order.set_department && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_newdepartment')}
                  secondaryText={order.set_department}
                />
              )}
              {Boolean(order.oldFirstName || order.newFirstName) && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_firstnamechange')}
                  secondaryText={
                    <span>
                      {order.oldFirstName} <ChangeArrowIcon className={classes.changeIcon} />
                      {order.newFirstName}
                    </span>
                  }
                />
              )}
              {Boolean(order.oldLastName || order.newLastName) && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_lastnamechange')}
                  secondaryText={
                    <span>
                      {order.oldLastName} <ChangeArrowIcon className={classes.changeIcon} />
                      {order.newLastName}
                    </span>
                  }
                />
              )}
              {Boolean(order.oldEmail || order.newEmail) && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_emailchange')}
                  secondaryText={
                    <span>
                      {order.oldEmail} <ChangeArrowIcon className={classes.changeIcon} />
                      {order.newEmail}
                    </span>
                  }
                />
              )}
              {order.newSipAddress && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_sipchange')}
                  secondaryText={order.newSipAddress}
                />
              )}
              {order.newUpnAddress && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_upnchange')}
                  secondaryText={order.newUpnAddress}
                />
              )}
              {order.huntgroupNumber && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_huntgroupnumber')}
                  secondaryText={order.huntgroupNumber}
                />
              )}
              {order.service_type_name && (
                <DrawerListItem primaryText="Service" secondaryText={order.service_type_name} />
              )}
              {order.sent_to_recipient && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_receiver')}
                  secondaryText={order.sent_to_recipient}
                />
              )}
              {order.sent_to_address && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_address')}
                  secondaryText={order.sent_to_address}
                />
              )}
              {order.sent_to_zip && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_zip')}
                  secondaryText={order.sent_to_zip}
                />
              )}
              {order.sent_to_city && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_city')}
                  secondaryText={order.sent_to_city}
                />
              )}
              {order.sent_to_att && (
                <DrawerListItem primaryText="Att." secondaryText={order.sent_to_att} />
              )}
              {order.sent_comment && (
                <DrawerListItem
                  primaryText={t('Organization_orders_drawer_comment')}
                  secondaryText={order.sent_comment}
                />
              )}
              {/* <DrawerSection title={`Ordre ${order.order_id} - ${order.typeFormatted} - Rå data`} />
              <pre className={classes.code}>{JSON.stringify(order, null, 4)}</pre> */}
              <DrawerButtons
                withSubmitButton={false}
                backLink={closeDrawer}
                backText={t('Organization_orders_drawer_close')}
              />
            </div>
          </Slide>
        </PaperContent>
      </Paper>
    </DrawerWrapper>
  )
}

export default OrderDrawer
