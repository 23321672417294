import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { LibraryAddOutlined as AddIcon, UpdateOutlined as ChangeIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 62,
    border: `2px dashed ${theme.palette.border}`,
    color: '#d0d0d0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    borderRadius: 4,
    transition: theme.transitions.create(['border-color', 'color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  typography: {
    marginLeft: theme.spacing(),
  },
}))

const SecretPlaceholder = ({ onClick, hasSecrets }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Box className={classes.wrapper} onClick={onClick}>
      {hasSecrets ? <ChangeIcon /> : <AddIcon />}
      <Typography variant="h5" color="inherit" className={classes.typography}>
        {hasSecrets ? t('Replace') : t('Wizard_add')}
      </Typography>
    </Box>
  )
}

export default SecretPlaceholder
