import React from 'react'
import classNames from 'classnames'
import { Grid } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Paper, PaperContent, CloudContainer } from 'components'

const useStyles = makeStyles((theme) => ({
  grid: {
    maxWidth: 900,
  },
  mobileMargin: {
    margin: theme.spacing(2),
  },
  desktopMargin: {
    margin: theme.spacing(3),
  },
}))

const CustomerSelectLayout = ({ content }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <CloudContainer>
      <Grid item lg={6} md={8} sm={10} xs={10} className={classes.grid}>
        <Paper>
          <PaperContent
            className={classNames({
              [classes.mobileMargin]: isMobile,
              [classes.desktopMargin]: !isMobile,
            })}
          >
            {content}
          </PaperContent>
        </Paper>
      </Grid>
    </CloudContainer>
  )
}

export default CustomerSelectLayout
