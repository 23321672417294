import React from 'react'
import { MuiDataTable } from 'components'
import { useTranslation } from 'react-i18next'
import NumberTableCell from './NumberTableCell'
import ListItemTableCell from './ListItemTableCell'
import TotalNumberCell from './TotalNumberCell'
import DownloadPdfIconButton from '../components/DownloadPdfIconButton'
import ListWrapper from '../components/ListWrapper'

const options = {
  search: false,
  print: false,
  viewColumns: false,
  pagination: false,
  rowHover: false,
  sort: false,
  download: false,
  filter: false,
  searchOpen: false,
  selectableRows: 'none',
}

const sharedColumnOptions = {
  filter: false,
  sort: false,
  searchable: false,
}

const InvoiceTable = ({ invoices, goToInvoiceRoute, ski }) => {
  const { t } = useTranslation()
  const columns = [
    {
      name: 'period',
      label: t(ski ? 'Invoice_quarter_title' : 'Invoice_month'),
      align: 'left',
      options: {
        ...sharedColumnOptions,
        customBodyRender: (value) => {
          return (
            <ListItemTableCell
              primary={
                ski
                  ? t('Invoice_quarter', {
                      quarter: value.quarter,
                      year: value.year,
                    })
                  : t(value.period)
              }
              secondary={value.date}
            />
          )
        },
      },
    },
    {
      name: 'subscriptionFees',
      label: t('Invoice_subs'),
      align: 'right',
      options: {
        ...sharedColumnOptions,
        customBodyRender: (value) => {
          return (
            <NumberTableCell
              current={value.current}
              type="abonnementer"
              goToInvoiceRoute={goToInvoiceRoute}
              invoiceId={value.invoiceId}
              percentageOfTotal={value.percentageOfTotal}
              percentageDifference={value.percentageDifference}
              ski={ski}
            />
          )
        },
      },
    },
    {
      name: 'extraServiceFees',
      label: t('Invoice_extraServiceFees'),
      align: 'right',
      options: {
        ...sharedColumnOptions,
        customBodyRender: (value) => {
          return (
            <NumberTableCell
              current={value.current}
              type="ekstra-services"
              goToInvoiceRoute={goToInvoiceRoute}
              invoiceId={value.invoiceId}
              percentageOfTotal={value.percentageOfTotal}
              percentageDifference={value.percentageDifference}
              ski={ski}
            />
          )
        },
      },
    },
    {
      name: 'fixedLineFees',
      label: t('Invoice_landline'),
      align: 'right',
      options: {
        ...sharedColumnOptions,
        customBodyRender: (value) => {
          return (
            <NumberTableCell
              current={value.current}
              type="fastnet"
              goToInvoiceRoute={goToInvoiceRoute}
              invoiceId={value.invoiceId}
              percentageOfTotal={value.percentageOfTotal}
              percentageDifference={value.percentageDifference}
              ski={ski}
            />
          )
        },
      },
    },
    {
      name: 'mobileFees',
      label: t('Invoice_mobile'),
      align: 'right',
      options: {
        ...sharedColumnOptions,
        customBodyRender: (value) => {
          return (
            <NumberTableCell
              current={value.current}
              type="mobil"
              goToInvoiceRoute={goToInvoiceRoute}
              invoiceId={value.invoiceId}
              percentageOfTotal={value.percentageOfTotal}
              percentageDifference={value.percentageDifference}
              ski={ski}
            />
          )
        },
      },
    },
    {
      name: 'totalFees',
      label: t('Invoice_total_price'),
      align: 'right',
      options: {
        ...sharedColumnOptions,
        customBodyRender: (value) => {
          return (
            <TotalNumberCell
              current={value.current}
              percentageDifference={value.percentageDifference}
            />
          )
        },
      },
    },
    {
      name: 'utilities',
      label: ' ',
      align: 'right',
      width: 74,
      options: {
        ...sharedColumnOptions,
        customBodyRender: (value) => {
          return (
            <ListWrapper>
              <DownloadPdfIconButton
                canDownloadPdf={value.canDownloadPdf}
                invoiceId={value.invoiceId}
                customerId={value.customerId}
              />
            </ListWrapper>
          )
        },
      },
    },
  ]

  return <MuiDataTable options={options} data={invoices} columns={columns} />
}

export default InvoiceTable
