import { getUser } from 'services/sweb/user'

export const FETCH_FAILURE = 'teamsUsers/FETCH_FAILURE'
export const FETCH_BEGIN = 'teamsUsers/FETCH_BEGIN'
export const FETCH_SUCCESS = 'teamsUsers/FETCH_SUCCESS'

export const fetchTeamsUsersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchTeamsUsersBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchTeamsUsersSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchTeamsUsers = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchTeamsUsersBegin({ customerId }))
    try {
      const response = await getUser.teamsUsersOnly({ customerId })
      dispatch(fetchTeamsUsersSuccess(response.data))
    } catch (err) {
      dispatch(fetchTeamsUsersFailure(err.response))
    }
  }
}
