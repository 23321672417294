import React from 'react'
import { Paper, PaperHeader, PaperTitle, PaperContent, PaperFooter } from '@skytdc/mui/components'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { ArrowRightRounded as ArrowRightIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  setMaxHeight: {
    height: '100%',
  },
  link: {
    textDecoration: 'none',
    marginLeft: 'auto',
  },
}))

const InvoiceWidgetContainer = ({ title, content, showFooter, customerId }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Paper className={classes.setMaxHeight}>
      <PaperHeader noDivider>
        <PaperTitle title={title} />
      </PaperHeader>
      <PaperContent noPadding>{content}</PaperContent>
      {showFooter && (
        <PaperFooter alignRight>
          <Link to={`/${customerId}/faktura`} className={classes.link}>
            <Button color="primary" size="small" variant="text">
              {t('Dashboard_general_see_all')} <ArrowRightIcon />
            </Button>
          </Link>
        </PaperFooter>
      )}
    </Paper>
  )
}

export default InvoiceWidgetContainer
