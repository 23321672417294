import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'intNumberVoxbone/FETCH_FAILURE'
export const FETCH_BEGIN = 'intNumberVoxbone/FETCH_BEGIN'
export const FETCH_SUCCESS = 'intNumberVoxbone/FETCH_SUCCESS'

export const fetchIntNumberVoxboneFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchIntNumberVoxboneBegin = ({ customerId, e164 }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
    e164,
  }
}

export const fetchIntNumberVoxboneSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchIntNumberVoxbone = ({ customerId, e164 }) => {
  return async (dispatch) => {
    dispatch(fetchIntNumberVoxboneBegin({ customerId, e164 }))
    try {
      const response = await getCustomer.intNumbersVoxbone({
        customerId,
        e164,
      })
      dispatch(fetchIntNumberVoxboneSuccess(response.data))
    } catch (err) {
      dispatch(fetchIntNumberVoxboneFailure(err.response))
    }
  }
}
