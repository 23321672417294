import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Button,
  Tooltip,
  Badge,
} from '@material-ui/core'
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons'
import { Link as RouterLink } from 'react-router-dom'

const DarkTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: 4,
    // minHeight: '63px',
  },
  cta: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  ctaButton: {
    fontSize: theme.typography.body2.fontSize,
  },
  chevron: {
    color: theme.palette.text.secondary,
  },
  avatar: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
  },
  disableVerticalPadding: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
  },
}))

const DrawerListItem = ({
  isLoading = false,
  isDisabled = false,
  selected,
  tooltip = '',
  icon,
  primaryText,
  secondaryText,
  link = '',
  linkAnchor = '',
  secondaryActionText = null,
  secondaryActionButton = null,
  onSecondaryActionButton,
  children,
  onClick,
  isButton = false,
  avatar,
  withChevron,
  disableGutters = false,
  dense,
  className,
  disableVerticalPadding,
  ...rest
}) => {
  const classes = useStyles()
  const LinkComponent = forwardRef((props, ref) => <RouterLink {...props} innerRef={ref} />)
  const listItemClasses = classNames(
    [classes.listItem],
    {
      [classes.disableVerticalPadding]: disableVerticalPadding,
    },
    className
  )
  return (
    <ListItem
      ContainerComponent="div"
      onClick={onClick}
      selected={selected}
      disableGutters={disableGutters}
      dense={dense}
      button={Boolean(link || isButton)}
      disabled={Boolean(isDisabled || isLoading)}
      component={link ? LinkComponent : 'div'}
      className={listItemClasses}
      to={link || ''}
      {...rest}
    >
      {icon && (
        <DarkTooltip placement="right" title={tooltip || ''} arrow className={classes.tooltip}>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <Badge color="primary" variant="dot" invisible={Boolean(!tooltip) || isLoading}>
                {isLoading ? <CircularProgress size={18} /> : icon}
              </Badge>
            </Avatar>
          </ListItemAvatar>
        </DarkTooltip>
      )}
      {avatar && (
        <Tooltip placement="right" title={tooltip || ''}>
          <ListItemAvatar>{avatar}</ListItemAvatar>
        </Tooltip>
      )}
      {(primaryText || secondaryText) && (
        <ListItemText
          style={{ marginTop: 4 }}
          primary={isLoading ? '' : primaryText || ''}
          secondary={isLoading ? 'Arbejder...' : secondaryText || ''}
        />
      )}
      {linkAnchor && (
        <>
          <Typography variant="body2" color="textSecondary" display="inline">
            {linkAnchor}
          </Typography>
          <ChevronRightIcon className={classes.chevron} />
        </>
      )}
      {secondaryActionText && (
        <ListItemSecondaryAction>
          <Typography variant="body1" color="primary" display="inline">
            {secondaryActionText}
          </Typography>
        </ListItemSecondaryAction>
      )}
      {secondaryActionButton && (
        <ListItemSecondaryAction>
          <Button
            color="primary"
            size="small"
            className={classes.ctaButton}
            onClick={onSecondaryActionButton}
          >
            {secondaryActionButton}
          </Button>
        </ListItemSecondaryAction>
      )}
      {withChevron && <ChevronRightIcon className={classes.chevron} />}
      {children && <ListItemSecondaryAction>{children}</ListItemSecondaryAction>}
    </ListItem>
  )
}

export default DrawerListItem
