import React from 'react'
import classNames from 'classnames'
import { Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    paddingTop: theme.spacing(),
    marginRight: theme.spacing(),
    color: theme.palette.text.secondary,
  },
}))

interface Props {
  className?: string
  icon?: JSX.Element
  title: string
  subtitle?: string
}

const PaperTitle = ({ className, icon, title }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(classes.root, className)

  return (
    <div className={rootClassName}>
      {icon && <span className={classes.icon}>{icon}</span>}
      <Typography variant="h5">{title}</Typography>
    </div>
  )
}

export default PaperTitle
