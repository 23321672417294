import { FETCH_BEGIN, FETCH_SUCCESS, FETCH_FAILURE } from './actions'

const initialState = {
  loading: false,
  customerId: null,
  e164: '',
  pagenumber: 0,
  pagesize: 10,
  countryCode: '',
  data: null,
  error: null,
}

// && action.e164 === state.e164 && action.pagenumber === state.pagenumber && action.pagesize === state.pagesize && action.countryCode === state.countryCode

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        customerId: action.customerId,
        e164: action.e164,
        pagenumber: action.pagenumber,
        pagesize: action.pagesize,
        countryCode: action.countryCode,
        error: null,
        data: action.customerId === state.customerId ? state.data : null,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      }

    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: null,
      }

    default:
      return state
  }
}
