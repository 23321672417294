import React from 'react'
import classNames from 'classnames'
import { Fab, Tooltip, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add as AddIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingBottom: theme.spacing(5),
  },
}))

interface Props {
  icon?: JSX.Element
  tooltip?: string
  disabled?: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}

const FloatingButton = ({
  icon = <AddIcon />,
  tooltip = 'Tilføj',
  disabled = false,
  onClick,
  className,
}: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.grid]: true,
    },
    className
  )

  return (
    <Grid container direction="row" justify="center" alignItems="center" className={rootClassName}>
      <Tooltip title={tooltip} aria-label={tooltip} arrow>
        <Fab color="primary" aria-label={tooltip} disabled={disabled} onClick={onClick}>
          {icon}
        </Fab>
      </Tooltip>
    </Grid>
  )
}

export default FloatingButton
