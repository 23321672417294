import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(),
  },
  endBlock: {
    marginBottom: theme.spacing(2.5),
    display: 'block',
  },
}))

const TextBlock = ({ children, endBlock = false, component = 'p' }) => {
  const classes = useStyles()

  const root = classNames({
    [classes.root]: true,
    [classes.endBlock]: endBlock,
  })

  return (
    <Typography variant="body1" className={root} component={component}>
      {children}
    </Typography>
  )
}

export default TextBlock
