import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  CardActions,
  CardActionArea,
  Typography,
  Button,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  CheckCircleOutlined as CheckIcon,
  OfflineBoltOutlined as OfflineIcon,
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'none',
    position: 'relative',
  },
  cardHeader: {
    display: 'block',
  },
  cardHeaderInactive: {
    backgroundColor: theme.palette.primary.light,
  },
  cardHeaderActive: {
    backgroundColor: theme.palette.success.main,
  },
  cardContent: {
    minHeight: '125px',
  },
  avatar: {
    margin: 'auto',
    color: '#66788a',
    backgroundColor: '#ffffff',
    width: '100px',
    height: '100px',
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  description: {
    lineHeight: '22px',
  },
  price: {
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
  },
  activeText: {
    color: theme.palette.success.main,
    display: 'flex',
    alignItems: 'center',
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  activeButton: {
    color: theme.palette.success.main,
  },
  activeIcon: {
    marginRight: theme.spacing(0.6),
  },
  notActiveText: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  footer: {
    borderTop: '1px solid #DFE3E8',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}))

interface AddonProps {
  title: string
  description: string
  price: number
  active: boolean
  icon: JSX.Element
  canBeActivated: boolean
  openTicketModal: (data: { open: boolean; title: string; description: string }) => void
  isService?: boolean
  page?: string
  goToPage?: (page: string) => void
}

const CustomCardHeader = ({ title, active, icon, description }) => {
  const classes = useStyles()
  return (
    <>
      <CardHeader
        className={classNames([classes.cardHeader], {
          [classes.cardHeaderActive]: active,
          [classes.cardHeaderInactive]: !active,
        })}
        avatar={
          <Avatar aria-label={title} className={classes.avatar}>
            {icon}
          </Avatar>
        }
      />
      <CardContent className={classes.cardContent}>
        <Typography variant="body1" className={classes.title}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.description}
        >
          {description}
        </Typography>
      </CardContent>
    </>
  )
}

const AddonCard = ({
  title,
  description,
  price,
  active,
  icon,
  canBeActivated,
  openTicketModal,
  isService,
  page,
  goToPage,
}: AddonProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Card className={classes.card} raised={false}>
      {canBeActivated ? (
        <>
          <CustomCardHeader title={title} description={description} icon={icon} active={active} />
          <CardActions disableSpacing className={classes.footer}>
            {active ? (
              <>
                <Typography variant="body2" className={classes.activeText}>
                  <CheckIcon className={classes.activeIcon} /> {t('Addons_card_activated')}
                </Typography>
                {page && (
                  <Button size="small" color="primary" onClick={() => goToPage(page)}>
                    {t('Addons_card_administrate')}
                  </Button>
                )}
              </>
            ) : (
              <Button size="small" color="primary">
                {t('Addons_card_request')}
              </Button>
            )}
            {price > 0 && (
              <Typography variant="body1" className={classes.price}>
                {t('Addons_card_request', { price })}
              </Typography>
            )}
          </CardActions>
        </>
      ) : (
        <>
          <CardActionArea onClick={() => openTicketModal({ open: true, title, description })}>
            <CustomCardHeader title={title} description={description} icon={icon} active={active} />
          </CardActionArea>
          <CardActions disableSpacing className={classes.footer}>
            {!isService && (
              <Typography variant="body2" className={classes.notActiveText}>
                <OfflineIcon className={classes.activeIcon} /> {t('Addons_card_notactivated')}
              </Typography>
            )}
            <Button
              size="small"
              color="primary"
              onClick={() => openTicketModal({ open: true, title, description })}
            >
              {t('Addons_card_plz_call')}
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  )
}

export default AddonCard
