import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { authenticatedNonAdmin, authenticatedAdmin } from './authenticatedCustomer'
import authenticatedCustomerRoutes from './authenticatedCustomer.routes'

import Login from '../views/Login'
import CustomerSelect from '../views/CustomerSelect'
import NotFound from '../views/NotFound'

import { ENV } from '../constants/configurable'
import ErrorTest from '../views/ErrorTest'

export const mustAuthenticate = ({ location }) => {
  if (location.pathname === '/login') return <Login location={location} />
  return (
    <>
      <Route component={Login} path="*" />
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location.pathname },
        }}
      />
    </>
  )
}

export default React.memo<{ me: any; broadworks: boolean }>(({ me, broadworks }) => {
  const CustomerRoutes =
    me.user.role === 'admin'
      ? authenticatedAdmin({
          user: me.user,
        })
      : authenticatedNonAdmin({
          user: me.user,
          authorizations: me.authorizations,
          activeSubscriptions: me.activeSubscriptions,
        })
  const customerRoutePaths = authenticatedCustomerRoutes.reduce((routes, route) => {
    if ((broadworks && route.title !== 'Layout_hardware') || !broadworks) {
      routes.push(route.path)
    }
    return routes
  }, [])

  return (
    <Switch>
      <Route exact path="/" component={CustomerSelect} />
      <Route exact path="/login" component={Login} />
      {ENV !== 'production' && <Route exact path="/error-test" component={ErrorTest} />}
      <Route path={customerRoutePaths} component={CustomerRoutes} />
      <Route component={NotFound} />
    </Switch>
  )
})
