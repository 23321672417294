import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: 'column',
    borderRadius: '4px',
  },
  displayFlex: {
    display: 'flex',
  },
}))

const CustomPaper = (props) => {
  const classes = useStyles()
  const { children, className, displayFlex = true, ...rest } = props

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.displayFlex]: displayFlex,
    },
    className
  )

  return (
    <Paper className={rootClassName} elevation={0} component="div" square={false} {...rest}>
      {children}
    </Paper>
  )
}

export default CustomPaper
