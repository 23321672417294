import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { MuiDataTable, PaperLoader, PaperFeedbackMessage } from 'components'
import { useTranslation } from 'react-i18next'
import UnparsedChargeTableCell from '../../components/UnparsedChargeTableCell'
import TableFooterWithTotal from '../../components/TableFooterWithTotal'

const UsageCategoryTable = ({ data, invoiceId, fetchSpecifications, prevLocationTypes = [] }) => {
  const { t } = useTranslation()
  const invoiceIdsMatch = Boolean(data.invoiceId === invoiceId)
  const [table, setTable] = useState({
    rowsSelected: [],
    locationTypes: [],
  })

  const columns = [
    {
      name: 'description',
      label: t('Invoice_cat'),
      align: 'left',
      options: {
        sortDirection: 'asc',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'volumen.formatted',
      label: t('Invoice_volume'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'number_calls',
      label: t('Invoice_call'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'duration.formatted',
      label: t('Invoice_duration'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'charge',
      label: t('Invoice_price'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <UnparsedChargeTableCell value={value} />
        },
      },
    },
    {
      name: 'location_type',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
        searchable: false,
        download: false,
      },
    },
  ]

  const handleFetchSpecifications = () => {
    fetchSpecifications(table.locationTypes)
  }

  const getSelectedLocationTypes = (selectedDataIndexArr = []) => {
    const locationTypes = selectedDataIndexArr
      .map((itemIdx) => data.data.items[itemIdx])
      .reduce((agg, curr) => [...agg, ...curr.location_type], [])

    return locationTypes
  }

  const handleOnRowSelect = (selected, everySelected) => {
    const selectedDataIndexArr = everySelected.map((s) => s.dataIndex)
    const locationtypesArr = getSelectedLocationTypes(selectedDataIndexArr)
    setTable({
      ...table,
      rowsSelected: selectedDataIndexArr,
      locationTypes: locationtypesArr,
    })
  }

  const handleTableInit = (action, tableState) => {
    if (
      data.data &&
      data.data.items.length > 0 &&
      prevLocationTypes &&
      prevLocationTypes.length > 0 &&
      action === 'tableInitialized'
    ) {
      const preselectRows = tableState.displayData.filter((row) =>
        prevLocationTypes.includes(row.data[5][0])
      )
      if (preselectRows.length > 0) {
        const getDataIndex = preselectRows.map((row) => row.dataIndex)
        setTable({
          ...table,
          rowsSelected: getDataIndex,
          locationTypes: getSelectedLocationTypes(getDataIndex),
        })
      }
    }
  }

  const handleCustomToolbarSelect = () => {
    return (
      <Button color="primary" variant="outlined" onClick={handleFetchSpecifications}>
        {t('Invoice_get_specs')}
      </Button>
    )
  }

  const handleCustomFooter = (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
    const totalPrice = data.data.items
      .map((item) => item.unparsed_charge)
      .reduce((calculatedPrice, currentPrice) => calculatedPrice + currentPrice)
    return (
      <TableFooterWithTotal
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
        totalPrice={totalPrice}
        withPagination={false}
      />
    )
  }

  const options = {
    search: false,
    sort: false,
    filter: false,
    searchOpen: false,
    print: false,
    viewColumns: false,
    download: false,
    pagination: false,
    responsive: 'scrollFullHeight',
    rowsPerPage: 100,
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    rowsSelected: table.rowsSelected,
    onRowsSelect: handleOnRowSelect,
    selectedRows: table.selectedRows,
    customToolbarSelect: handleCustomToolbarSelect,
    customFooter: handleCustomFooter,
    onTableInit: handleTableInit,
  }

  if (data.error && invoiceIdsMatch) {
    return (
      <PaperFeedbackMessage
        message={t('Invoice_error', { error: data.error.statusText })}
        type="error"
        withMargin
      />
    )
  }

  if (data.data && data.data.items.length === 0 && invoiceIdsMatch) {
    return <PaperFeedbackMessage message={t('Invoice_no_occurences')} withMargin />
  }

  if (data.data && data.data.items.length > 0 && invoiceIdsMatch) {
    return <MuiDataTable options={options} data={data.data.items} columns={columns} />
  }

  return <PaperLoader />
}

const hasDataFromCurrentInvoice = (prev) => {
  return Boolean(prev.data.data !== null && prev.invoiceId === prev.data.invoiceId)
}

export default React.memo(UsageCategoryTable, hasDataFromCurrentInvoice)
