import React, { useState, useEffect } from 'react'
import { SearchableSelect, DrawerSection } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, FormControlLabel, Switch, Collapse } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DrawerButtons from '../DrawerButtons'
import { phoneNumberFormat } from 'utils/helpers'

const useStyles = makeStyles((theme) => ({
  message: {
    paddingBottom: theme.spacing(3),
  },
  switch: {
    marginBottom: theme.spacing(2),
  },
}))

const getOptions = (
  currentMobileNumber,
  currentLandlineNumber,
  currentIntNumber,
  availableDisplayNumbers
) => {
  const { t } = useTranslation()
  const optionGroups = []
  if (currentMobileNumber) {
    optionGroups.push({
      label: t('Organization_orders_drawer_mobile'),
      options: [
        {
          label: `+45 ${currentMobileNumber}`,
          value: {
            number: currentMobileNumber,
          },
        },
      ],
    })
  }
  if (currentLandlineNumber) {
    optionGroups.push({
      label: t('Organization_orders_drawer_landline'),
      options: [
        {
          label: `+45 ${phoneNumberFormat(currentLandlineNumber)}`,
          value: {
            number: currentLandlineNumber,
          },
        },
      ],
    })
  }
  if (availableDisplayNumbers && availableDisplayNumbers.length > 0) {
    optionGroups.push({
      label: t('Drawer_display_group_numbers'),
      options: availableDisplayNumbers.map((number) => ({
        label: `+${number.prefix} ${phoneNumberFormat(number.number)}`,
        value: number,
      })),
    })
  }
  if (currentIntNumber) {
    optionGroups.push({
      label: t('Int_number'),
      options: [
        {
          label: `+${currentIntNumber.prefix} ${currentIntNumber.number}`,
          value: currentIntNumber,
        },
      ],
    })
  }
  return optionGroups
}

const DisplayNumberForm = ({
  loading,
  backLink,
  setDisplayNumber,
  setInternationalDisplayNumber,
  setPrivateNumber,
  privateNumber,
  displayNumber,
  availableDisplayNumbers,
  title,
  currentLandlineNumber,
  currentMobileNumber,
  currentIntNumber,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [hasPrivateNumber, setHasPrivateNumber] = useState(
    privateNumber && privateNumber.supported ? privateNumber.enabled : false
  )
  const [chosenDisplayNumber, setChosenDisplayNumber] = useState(null)

  const privateNumberSupported = privateNumber && privateNumber.supported
  const privateNumberHasChanged =
    privateNumberSupported && Boolean(hasPrivateNumber) !== privateNumber.enabled
  const isValid =
    privateNumberHasChanged ||
    (!hasPrivateNumber &&
      chosenDisplayNumber &&
      chosenDisplayNumber.value.number !== displayNumber.number)

  useEffect(() => {
    if (displayNumber) {
      setChosenDisplayNumber({
        label: `+${displayNumber.prefix} ${displayNumber.number}`,
        value: {
          number: displayNumber.number,
        },
      })
    }
  }, [])

  const handleSetChosenDisplayNumber = (value) => {
    setChosenDisplayNumber(value)
  }

  const handlePrivateNumber = (event) => {
    setHasPrivateNumber(event.target.checked)
  }

  const handleSetDisplayNumber = () => {
    if (privateNumberHasChanged && hasPrivateNumber) {
      setPrivateNumber({ enabled: true })
    } else if (
      !chosenDisplayNumber.value.prefix ||
      Number(chosenDisplayNumber.value.prefix) === 45
    ) {
      setDisplayNumber(
        chosenDisplayNumber.value.number,
        Boolean(privateNumberSupported && privateNumberHasChanged && !hasPrivateNumber)
      )
    } else {
      setInternationalDisplayNumber(
        {
          number: chosenDisplayNumber.value.number,
          prefix: chosenDisplayNumber.value.prefix,
        },
        Boolean(privateNumberSupported && privateNumberHasChanged && !hasPrivateNumber)
      )
    }
  }

  return (
    <>
      <DrawerSection title={title}>
        <Typography className={classes.message}>{t('Drawer_display_desc')}</Typography>
        {privateNumberSupported && (
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(hasPrivateNumber)}
                onChange={handlePrivateNumber}
                value={hasPrivateNumber}
                color="primary"
                disabled={loading}
              />
            }
            className={classes.switch}
            label={t('Drawer_display_activate')}
          />
        )}

        <Collapse in={!hasPrivateNumber}>
          <SearchableSelect
            label={t('Drawer_display_choose')}
            value={chosenDisplayNumber}
            onChange={handleSetChosenDisplayNumber}
            disabled={loading}
            options={getOptions(
              currentMobileNumber,
              currentLandlineNumber,
              currentIntNumber,
              availableDisplayNumbers
            )}
            helperText={
              (!availableDisplayNumbers || availableDisplayNumbers.length === 0) &&
              t('Drawer_display_none_avail')
            }
          />
        </Collapse>
      </DrawerSection>
      <DrawerButtons
        backLink={backLink}
        disabled={!isValid}
        onSubmit={handleSetDisplayNumber}
        submitText={t('Drawer_display_save')}
        loading={loading}
      />
    </>
  )
}

export default DisplayNumberForm
