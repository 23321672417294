import React from 'react'
import classNames from 'classnames'
import { Drawer } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    overflow: 'auto',
  },
  white: {
    backgroundColor: theme.palette.common.white,
  },
  grey: {
    backgroundColor: theme.palette.divider,
  },
  drawerPaper: {
    border: 'none',
    borderRight: `1px solid ${theme.palette.divider}`,
    zIndex: 1300,
    height: 'calc(100% - 60px)',
    transition: theme.transitions.create(['width'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
}))

interface Props {
  open: boolean
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  isMobile: boolean
  color?: 'white' | 'grey'
  children: JSX.Element | JSX.Element[]
  dockOrientation?: 'left' | 'top' | 'right' | 'bottom'
  className?: string
  width?: number
  justify?: 'space-between' | 'flex-start'
}

const SidebarMenu = ({
  open,
  onClose,
  isMobile,
  color = 'white',
  children,
  dockOrientation = 'left',
  className,
  width = 270,
  justify = 'flex-start',
}: Props) => {
  const classes = useStyles()

  const paperClassName = classNames(
    {
      [classes.drawerPaper]: true,
      [classes[color]]: color,
    },
    className
  )

  return (
    <Drawer
      anchor={dockOrientation}
      classes={{ paper: paperClassName }}
      onClose={onClose}
      open={open}
      variant={isMobile ? 'temporary' : 'permanent'}
      PaperProps={{
        style: {
          width: `${width}px`,
        },
      }}
    >
      <nav className={classes.root} style={{ justifyContent: justify }}>
        {children}
      </nav>
    </Drawer>
  )
}

export default SidebarMenu
