import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceLocationTypes/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceLocationTypes/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceLocationTypes/FETCH_SUCCESS'

export const fetchInvoiceLocationTypesFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceLocationTypesBegin = ({ invoiceId }) => {
  return {
    type: FETCH_BEGIN,
    invoiceId,
  }
}

export const fetchInvoiceLocationTypesSuccess = ({ invoiceId, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    invoiceId,
  }
}

export const fetchInvoiceLocationTypes = ({ customerId, invoiceId }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceLocationTypesBegin({ invoiceId }))
    try {
      const response = await getInvoice.locationTypes({
        customerId,
        invoiceId,
      })
      dispatch(fetchInvoiceLocationTypesSuccess({ invoiceId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceLocationTypesFailure(err.response))
    }
  }
}
