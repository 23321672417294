export const getMonth = (date) => {
  const split = date.split('-')
  const removeZero = split[1] === '10' ? split[1] : split[1].replace('0', '')
  const toInt = parseInt(removeZero, 0)
  return [
    'Invoice_jan',
    'Invoice_feb',
    'Invoice_mar',
    'Invoice_apr',
    'Invoice_may',
    'Invoice_jun',
    'Invoice_jul',
    'Invoice_aug',
    'Invoice_sep',
    'Invoice_oct',
    'Invoice_nov',
    'Invoice_dec',
  ][toInt - 1]
}

export const convertKbToGb = (value: number, decimals = 0, addType = true) => {
  if (!value) return 0

  const dataUsage = ((value * 10) / (1000 * 1000)).toFixed(decimals)
  return addType ? `${dataUsage}GB` : dataUsage
}

export const phoneNumberFormat = (number, characters = 2) => {
  if (!number) return
  const [firstPart, secondPart] = number?.split(' ')

  const addSpace = (text): string => {
    const regex = new RegExp('.{' + characters + '}(?!$)', 'g')
    return text.replace(regex, '$& ')
  }

  const evenOrOdd = (numberText: string) => {
    if (numberText.length % 2 === 0) {
      return addSpace(numberText)
    }
    const firstThreecharacters = numberText.substring(0, 3)
    const fromFirstThreeCharacters = numberText.substring(3)

    return `${firstThreecharacters} ${addSpace(fromFirstThreeCharacters)}`
  }
  return secondPart ? `${firstPart} ${evenOrOdd(secondPart)}` : evenOrOdd(firstPart)
}
