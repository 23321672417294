import React, { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { fetchCustomers } from 'state/customers/actions'
import { cleanCustomer } from 'state/customer/actions'
import { useTranslation } from 'react-i18next'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'

import { PaperLoader, PaperFeedbackMessage } from 'components'
import CustomerSelectLayout from './CustomerSelectLayout'
import CustomerList from './components/CustomerList'
import EndUserPageLink from './components/EndUserPageLink'

const removeNonTeamsCustomers = (customers) => {
  return customers.filter((customer) => customer.customer_type === 'teams')
}

const CustomerSelect = ({ history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const stored = useSelector(
    (state) => ({
      me: state.me,
      customer: state.customer,
      customers: state.customers,
    }),
    shallowEqual
  )

  changeDocumentTitle(t('Organization_customer_select_meta_title'), ' - ', ':applicationName')

  const userIsAdmin = stored.me.data.user.role === 'admin'

  useEffect(() => {
    if (userIsAdmin && !stored.customers.loading) {
      dispatch(fetchCustomers())
    }
    if (stored.customer.error || stored.customer.data) {
      dispatch(cleanCustomer())
    }
  }, [])

  const customers = userIsAdmin ? stored.customers.data : stored.me.data.authorizations
  const [activeSubscription] = stored.me.data.activeSubscriptions
  const getSubscriptionCustomer =
    activeSubscription &&
    customers?.find((customer) => customer.customer_id === activeSubscription.customerId)

  if (customers && customers.length > 0) {
    if (customers.length === 1) {
      const firstCustomer = customers[0]
      const allowed = firstCustomer.customer_type === 'teams'
      if (allowed) {
        return (
          <Redirect
            to={{
              pathname: `/${firstCustomer.customer_id}/dashboard`,
            }}
          />
        )
      }
    }
    return (
      <CustomerSelectLayout
        content={
          <>
            <CustomerList
              history={history}
              authorizations={stored.me.data?.authorizations}
              customerList={userIsAdmin ? removeNonTeamsCustomers(customers) : customers}
            />
            {getSubscriptionCustomer && (
              <EndUserPageLink
                subscription={activeSubscription}
                history={history}
                customerName={getSubscriptionCustomer?.name}
              />
            )}
          </>
        }
      />
    )
  }

  if (customers && customers.length === 0 && activeSubscription) {
    return (
      <Redirect
        to={{
          pathname: `/${activeSubscription.customerId}/min-side`,
        }}
      />
    )
  }

  if (customers && customers.length === 0 && !activeSubscription) {
    return <PaperFeedbackMessage message={t('Organization_customer_select_no_sub_msg')} />
  }

  if (stored.customers.error) {
    // display error at some point, ex.
    // content = <CustomerSelectError message={} />
  }

  return <CustomerSelectLayout content={<PaperLoader />} />
}

export default CustomerSelect
