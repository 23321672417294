import React, { useEffect } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Paper, PaperContent } from '@skytdc/mui/components'
import InnerContent from './InnerContent'

const useStyles = makeStyles((theme) => ({
  typography: {
    marginBottom: theme.spacing(3),
  },
  container: {
    padding: theme.spacing(3),
  },
}))

const ContactUs = ({ title }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
  }, [])

  return (
    <Grid container justify="center">
      <Grid item lg={6} sm={8} xs={12}>
        <Paper>
          <PaperContent className={classes.container}>
            <InnerContent />
          </PaperContent>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ContactUs
