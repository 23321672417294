import React, { useEffect } from 'react'
import { matchPath } from 'react-router'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isFuture } from 'date-fns'

import { fetchCustomer } from 'state/customer/actions'

import { PaperLoader } from 'components'

import redirectWithSnackbar from './utils/redirectWithSnackbar'
import { setTitleOption } from './utils/changeDocumentTitle/store'

import authenticatedCustomerRoutes from './authenticatedCustomer.routes'

const hasOneOfRequiredRoles = (givenRoles = [], requiredRoles = []) => {
  return requiredRoles.find((reqRole) => givenRoles.includes(reqRole))
}

const chooseAnotherOrganisationRedirect = () => {
  const snackbar = {
    message: 'Routerasdf_',
    options: { variant: 'warning' },
  }
  return redirectWithSnackbar({ to: '/', snackbar })
}

const mustCompleteSetupWizard = ({ user, currentCustomer }) => ({ location, history }) => {
  const setupRoute = authenticatedCustomerRoutes.find((r) => r.key === 'ViewSetupWizard')
  const pathMatchSetup = matchPath(location.pathname, { path: setupRoute.path })

  useEffect(() => {
    if (!pathMatchSetup) {
      history.replace(`/${currentCustomer.customer_id}/setup`)
    }
  }, [location.pathname])

  const isAuthorizedForSetupRoute = hasOneOfRequiredRoles(
    [user.role, ...(currentCustomer.roles || [])],
    [...setupRoute.requiresOneOf.role, ...setupRoute.requiresOneOf.roles]
  )

  const props = { title: setupRoute.title }

  const component = isAuthorizedForSetupRoute
    ? setupRoute.component(props)
    : setupRoute.fallback(props)

  return <Route component={component} />
}

const CurrentCustomerRoutes = React.memo(({ user, currentCustomer }) => {
  const dispatch = useDispatch()
  const customer = useSelector((s) => s.customer)
  const customerIdsMatch =
    currentCustomer.customer_id === customer.customerId ||
    currentCustomer.subId === customer.customerId
  const fetchCustomerAsEndUser = Boolean(currentCustomer.subId)

  setTitleOption(':customerName', currentCustomer.name)

  useEffect(() => {
    if (!customerIdsMatch) {
      dispatch(
        fetchCustomer({
          customerId: fetchCustomerAsEndUser ? currentCustomer.subId : currentCustomer.customer_id,
          asEndUser: fetchCustomerAsEndUser,
        })
      )
    }
  }, [currentCustomer.customer_id])

  const customerLoading =
    !customerIdsMatch || customer.loading || (!customer.data && !customer.error)
  if (customerLoading) return <PaperLoader />
  if (customer.error) return chooseAnotherOrganisationRedirect()

  const goLiveDateMissingOrInFuture =
    Boolean(!customer.data.go_live_date) || Boolean(isFuture(new Date(customer.data.go_live_date)))

  if (goLiveDateMissingOrInFuture && user.role !== 'admin') {
    const component = mustCompleteSetupWizard({ user, currentCustomer })
    return <Route component={component} />
  }

  return (
    <>
      {authenticatedCustomerRoutes.map((r) => {
        const isAuthorizedForRoute = hasOneOfRequiredRoles(
          [user.role, ...(currentCustomer.roles || [])],
          [...r.requiresOneOf.role, ...r.requiresOneOf.roles]
        )
        const props = { title: r.title }
        const component = isAuthorizedForRoute ? r.component(props) : r.fallback(props)
        return <Route key={r.key} exact path={r.path} component={component} />
      })}
    </>
  )
})

// eslint-disable-next-line
export const authenticatedNonAdmin = ({ user, authorizations, activeSubscriptions }) => ({
  match,
}) => {
  const customerId = Number(match.params.customer_id)
  const findInAuthorizations = authorizations.find((a) => a.customer_id === customerId)
  const findInActiveSubscriptions = activeSubscriptions.find((a) => a.customerId === customerId)
  const currentCustomer =
    findInAuthorizations ||
    (findInActiveSubscriptions && {
      customer_id: findInActiveSubscriptions.customerId,
      ...findInActiveSubscriptions,
    })

  if (!currentCustomer) {
    return chooseAnotherOrganisationRedirect()
  }

  return <CurrentCustomerRoutes user={user} currentCustomer={currentCustomer} />
}

// eslint-disable-next-line
export const authenticatedAdmin = ({ user }) => ({ match }) => {
  const customerId = Number(match.params.customer_id)
  const dispatch = useDispatch()
  const customer = useSelector((s) => s.customer)
  const customerIdsMatch = customer.customerId === customerId

  useEffect(() => {
    if (!customer.data || !customerIdsMatch) {
      dispatch(fetchCustomer({ customerId }))
    }
  }, [customerId])

  if (customer.error) {
    return chooseAnotherOrganisationRedirect()
  }

  if (customerIdsMatch && customer.data) {
    return <CurrentCustomerRoutes user={user} currentCustomer={customer.data} />
  }

  return <PaperLoader />
}
