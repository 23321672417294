import React, { Fragment } from 'react'
import { Tooltip, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const InvoiceTooltip = ({ invoice, children }) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      placement="left"
      title={
        <>
          {invoice && invoice.base_subscription && invoice.base_subscription.price ? (
            <Typography color="inherit" variant="inherit" display="block">
              {`- ${invoice.base_subscription.product_type_name}: ${invoice.base_subscription.price},-`}
            </Typography>
          ) : (
            t('Users_tooltip')
          )}
          {invoice &&
            invoice.add_ons.length > 0 &&
            invoice.add_ons.map((addon, index) => {
              return (
                <Typography
                  color="inherit"
                  variant="inherit"
                  // eslint-disable-next-line
                  key={`${addon.product_type_id}-${index}`}
                  display="block"
                >
                  {`- ${addon.product_type_name}: ${addon.price},-`}
                </Typography>
              )
            })}
        </>
      }
      aria-label="See monthly prices for this user"
    >
      {children}
    </Tooltip>
  )
}

export default InvoiceTooltip
