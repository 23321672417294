import React from 'react'
import classNames from 'classnames'
import { Typography, TypographyProps } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  withMargin: {
    marginBottom: theme.spacing(2),
  },
  inputMargin: {
    marginBottom: theme.spacing(2.5),
    display: 'block',
  },
}))

interface Props extends TypographyProps {
  children: JSX.Element | string | number
  inputMargin?: boolean
  withMargin?: boolean
}

const TextBlock = ({ children, withMargin = true, inputMargin = false }: Props) => {
  const classes = useStyles()

  const root = classNames({
    [classes.withMargin]: withMargin,
    [classes.inputMargin]: inputMargin,
  })

  return <Typography className={root}>{children}</Typography>
}

export default TextBlock
