import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const DonutChart = ({ data, backgroundColor, cutout = '80%', size = 100, className = '' }) => {
  const allData = {
    datasets: [
      {
        data,
        backgroundColor,
        borderWidth: 0,
      },
    ],
    labels: [],
  }
  return (
    <Doughnut
      className={className}
      type="doughnut"
      data={allData}
      width={size}
      height={size}
      options={{
        labels: [],
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false,
          animateScale: false,
        },
        cutout,
        layout: {
          padding: 0,
        },
        events: [],
      }}
    />
  )
}

export default DonutChart
