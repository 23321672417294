import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Toolbar } from '@material-ui/core'
import { IMG_TDC_LOGO_BUSSINESS } from 'constants/cdn'
import SignOut from 'views/Layout/components/SignOut'
import LanguageSwitcher from 'views/Layout/components/LanguageSwitcher'
import SupportMenu from '../SupportMenu'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    height: 63,
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: theme.zIndex.appBar,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.08), 0px 4px 5px 0px rgba(0, 0, 0, 0), 0px 1px 10px 0px rgba(0, 0, 0, 0)',
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
    justifyContent: 'space-between',
  },
  logoAndText: {
    height: '36px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const EndUserTopbar = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <img src={IMG_TDC_LOGO_BUSSINESS} alt="TDC Erhverv logo" className={classes.logoAndText} />
        <div className={classes.container}>
          <LanguageSwitcher />
          <SupportMenu />
          <SignOut />
        </div>
      </Toolbar>
    </div>
  )
}

export default EndUserTopbar
