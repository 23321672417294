import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PaperLoader } from 'components'
import { getBearer } from 'services/sweb/authentication'
import { teams } from 'services/appOpts'
import { fetchMe } from '../state/me/actions'
import Authenticated, { mustAuthenticate } from './Authenticated'
import NotAuthenticated from './NotAuthenticated'
import PartyRouter from './PartyRouter'

import PublicTeamsRoutes, { allowedPublicTeamsRoutes } from './PublicTeamsRoutes'
// import { setLastVisitedPath } from './utils/lastVisit'
import { hideFeedbackButton, showFeedbackButton } from '../utils/htmlscript/usabilla'

const removeTrailingSlashFromPath = (history, pathname) => {
  const pathWithoutTrailingSlash = pathname.substr(0, pathname.length - 1)
  return history.replace(pathWithoutTrailingSlash)
}

const Router = ({ history, location }) => {
  const noLoginNeeded = location.pathname?.startsWith('/service/')
  const isPartySite = location.pathname?.startsWith('/party')

  if (noLoginNeeded) {
    return <NotAuthenticated />
  }

  if (isPartySite) {
    return <PartyRouter />
  }

  const dispatch = useDispatch()
  const me = useSelector((state) => state.me)
  const customer = useSelector((s) => s.customer)
  const broadworks = customer?.data?.customer_properties?.broadworks

  const { isInTeams } = teams

  if (isInTeams && allowedPublicTeamsRoutes(location.pathname)) {
    return <PublicTeamsRoutes match={location.pathname} />
  }

  const meInvokedOnce = me.data !== null && me.error !== null
  const bearer = getBearer()
  const hasBearer = Boolean(bearer && bearer.value)

  useEffect(() => {
    const isPathWithTrailingSlash = location.pathname.endsWith('/')
    if (isPathWithTrailingSlash) {
      removeTrailingSlashFromPath(history, location.pathname)
    }
    if (!noLoginNeeded && hasBearer && meInvokedOnce === false) {
      dispatch(fetchMe())
    }
  }, [])

  const isNotAuthenticated = (!hasBearer && me.isAuthenticated === false) || me.error

  if (isNotAuthenticated) {
    hideFeedbackButton()
    return mustAuthenticate({ location })
  }

  if (!me.data) return <PaperLoader />

  if (!location.pathname?.includes('skyapp')) {
    showFeedbackButton()
  }

  // if (isInTeams && allowedPublicTeamsRoutes(location.pathname) === false) {
  //   setLastVisitedPath(location.pathname)
  // }
  return <Authenticated me={me.data} broadworks={broadworks} />
}

export default withRouter(Router)
