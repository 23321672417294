import React, { useState, useEffect } from 'react'
import { SkySelect, DrawerSection, SkyTextField } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DrawerButtons from '../../components/DrawerButtons'

const useStyles = makeStyles((theme) => ({
  message: {
    paddingBottom: theme.spacing(2),
  },
}))

const OrderSimcardForm = ({
  loading,
  admins,
  noneAd,
  backLink,
  orderSimcard,
  address,
  recipient,
  attention,
  unassignedSimcards,
}) => {
  const { t } = useTranslation()
  const newSimcardOption = {
    label: t('Drawer_simcard_order_new'),
    value: '0',
    group: '',
  }

  const esimSimcardOption = {
    label: t('Drawer_simcard_order_esim'),
    value: 'esim',
    group: '',
  }

  const getSimcardOptions = (simcards) => {
    const reservedSimcardOptions =
      (simcards.length > 0 &&
        simcards.map((simcard) => {
          return {
            value: simcard.id,
            group: t('Drawer_simcard_activate_unassigned'),
            label: simcard.icc,
          }
        })) ||
      []
    return [newSimcardOption, ...(!noneAd ? [esimSimcardOption] : []), ...reservedSimcardOptions]
  }
  const getAdminEmails = (allAdmins) => {
    const adminEmails =
      (allAdmins?.length > 0 &&
        allAdmins?.map(({ mail }) => {
          return {
            value: mail,
            label: mail,
          }
        })) ||
      []
    return [{ value: recipient, label: recipient }, ...adminEmails]
  }

  const classes = useStyles()
  const [form, setForm] = useState({
    email: recipient || '',
    simcard: newSimcardOption,
    blockSimcard: false,
    city: (address && address.city) || '',
    zip: (address && address.zip) || '',
    company: (address && address.recipient) || '',
    streetAddress: (address && address.streetAddress) || '',
    attention: attention || '',
  })

  const formIsValid = Boolean(
    form.email.length > 2 &&
      form.simcard &&
      form.city.length > 2 &&
      form.zip.length === 4 &&
      form.company.length > 2 &&
      form.streetAddress.length > 2 &&
      form.attention.length > 2
  )

  const handleForm = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleCheckbox = (event) => {
    setForm({ ...form, blockSimcard: event.target.checked })
  }

  const handleSetSimcard = (event, newValue) => {
    setForm({ ...form, simcard: newValue })
  }

  useEffect(() => {
    if (form.simcard?.value === 'esim' && form.email !== recipient) {
      handleForm({ target: { name: 'email', value: recipient } })
    }
  }, [form.simcard?.value === 'esim'])

  const handleOrderSimcard = () => {
    if (formIsValid) {
      const data = {}
      data.sim_order = {
        email: {
          email_address: form.email,
        },
        immediate_block: form.blockSimcard,
        simtype: form.simcard.value === 'esim' ? 8 : 6,
      }
      data.address = {
        city: form.city,
        zip: form.zip,
        recipient: form.company,
        streetAddress: form.streetAddress,
        attention: form.attention,
      }
      if (form.simcard.value > 4) {
        data.sim_order.reserved_sim_card_id = form.simcard.value
      } else {
        data.sim_order.reserved_sim_card_id = null
      }
      data.test_mode = false
      orderSimcard(data)
    }
  }

  return (
    <>
      <DrawerSection title={t('Drawer_simcard_order_title')}>
        <SkySelect
          label={t('Drawer_simcard_select')}
          value={form.simcard}
          required
          disabled={loading}
          disableClearable
          onChange={handleSetSimcard}
          options={getSimcardOptions(unassignedSimcards.reserved_sim_cards)}
          groupBy={(option) => option.group}
          helperText={
            form.simcard?.value !== 'esim' &&
            unassignedSimcards.reserved_sim_cards.length === 0 &&
            t('Drawer_simcard_no_unassigned')
          }
        />
        {form.simcard?.value !== 'esim' && !unassignedSimcards?.display_reserved_sim_cards && (
          <Typography className={classes.message}>{t('Drawer_simcard_price')}</Typography>
        )}
        <SkySelect
          label={t('Drawer_simcard_activation_recipient')}
          value={{ value: form.email, label: form.email }}
          required
          disabled={admins.loading || form.simcard?.value === 'esim'}
          disableClearable
          onChange={(lo, data) => handleForm({ target: { name: 'email', value: data.value } })}
          options={getAdminEmails(admins.data)}
        />
        <SkyTextField
          label={t('Drawer_simcard_company_name')}
          name="company"
          value={form.company}
          onChange={handleForm}
          disabled={loading}
          required
          white
        />
        <SkyTextField
          label="Att."
          name="attention"
          value={form.attention}
          onChange={handleForm}
          disabled={loading}
          required
          white
        />
        <SkyTextField
          label={t('Drawer_simcard_address')}
          name="streetAddress"
          value={form.streetAddress}
          onChange={handleForm}
          disabled={loading}
          required
          white
        />
        <SkyTextField
          label={t('Drawer_simcard_zip')}
          name="zip"
          value={form.zip}
          onChange={handleForm}
          disabled={loading}
          required
          white
        />
        <SkyTextField
          white
          label={t('Drawer_simcard_city')}
          name="city"
          value={form.city}
          onChange={handleForm}
          disabled={loading}
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              name="blockSimcard"
              onChange={handleCheckbox}
              disabled={loading}
              value={form.blockSimcard}
              color="primary"
            />
          }
          label={t('Drawer_simcard_block')}
        />
        <Typography variant="body2" color="textSecondary">
          {t('Drawer_simcard_block_desc')}
        </Typography>
      </DrawerSection>
      <DrawerButtons
        backLink={backLink}
        disabled={!formIsValid}
        onSubmit={handleOrderSimcard}
        submitText={t('Drawer_simcard_order_title')}
        loading={loading}
      />
    </>
  )
}

export default OrderSimcardForm
