import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, Typography, MenuItem } from '@material-ui/core'
import Pagination from 'material-ui-flat-pagination'
import {
  ChevronRightRounded as RightIcon,
  ChevronLeftRounded as LeftIcon,
  ArrowDropDownRounded as DropdownIcon,
} from '@material-ui/icons'
import DropdownMenu from '../DropdownMenu'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  count: {
    display: 'block',
    marginLeft: theme.spacing(),
  },
}))

interface Props {
  rowsPerPage: number
  page: number
  totalCount: number
  setPage: (newPage: number) => void
  setRowsPerPage: (newRowsPerPage: number) => void
  rowsPerPageOptions?: number[]
  showingFromTo: string
  perPage: string
}

const TablePagination = ({
  rowsPerPage,
  page,
  totalCount,
  setPage,
  setRowsPerPage,
  rowsPerPageOptions = [10, 15, 25],
  showingFromTo,
  perPage,
}: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const handleChange = (
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
    _offset: number,
    newPage: number
  ) => {
    setPage(newPage - 1)
  }

  const handleSetRowsPerPage = (newValue: number) => {
    setAnchorEl(null)
    setRowsPerPage(newValue)
  }

  /*
  t('ShowingFromTo', {
              from: page * rowsPerPage + 1,
              to:
                page * rowsPerPage + rowsPerPage > totalCount
                  ? totalCount
                  : page * rowsPerPage + rowsPerPage,
              totalCount,
            })
  */

  /*
  t('PerPage')
  */

  return (
    <>
      <Grid container justify="space-between" className={classes.container}>
        <div>
          <Button variant="text" color="primary" onClick={handleClick} endIcon={<DropdownIcon />}>
            {rowsPerPage} {perPage}
          </Button>
          <Typography variant="caption" className={classes.count}>
            {showingFromTo}
          </Typography>
        </div>
        <Pagination
          limit={rowsPerPage}
          offset={page * rowsPerPage}
          total={totalCount || 0}
          disableRipple
          size="large"
          onClick={handleChange}
          previousPageLabel={<LeftIcon />}
          nextPageLabel={<RightIcon />}
          reduced
        />
      </Grid>
      <DropdownMenu anchorEl={anchorEl} handleClose={handleClose}>
        {rowsPerPageOptions.map((x) => {
          return (
            <MenuItem key={x} selected={rowsPerPage === x} onClick={() => handleSetRowsPerPage(x)}>
              {x}
            </MenuItem>
          )
        })}
      </DropdownMenu>
    </>
  )
}

export default TablePagination
