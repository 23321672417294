import React, { useState, useEffect } from 'react'
import { updateInvoice } from 'services/sweb/invoice'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import { InputBase, CircularProgress /* , InputAdornment, Tooltip */ } from '@material-ui/core'
// import { InfoOutlined as InfoIcon } from '@material-ui/icons'
import useDebounce from 'utils/hooks/useDebounce'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  input: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    width: '100%',
    maxWidth: '210px',
    marginTop: -theme.spacing(),
    marginBottom: -theme.spacing(),
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(),
    paddingTop: theme.spacing(0.75),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  savingSpinner: {
    position: 'absolute',
    left: '-22px',
    top: '8px',
  },
  infoIcon: {
    width: 18,
    height: 18,
    color: theme.palette.text.secondary,
  },
}))

const ExtraInfoInput = ({ value, updateValue, data, placeholder }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState(value || '')
  const [debouncedValue] = useDebounce(inputValue, 1000)

  const handleUpdateValue = () => {
    setLoading(true)
    const payload = {
      extra_info: inputValue,
      ...(data.reference?.reference_id && { reference_id: data.reference.reference_id }),
      subscription_id: data.subscriptionId,
    }
    const json = { items: [{ ...payload }] }
    updateInvoice
      .extraInfoAndReference({ customerId: data.customerId, json })
      .then(() => {
        setLoading(false)
        updateValue(inputValue)
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  useEffect(() => {
    if (inputValue !== value && inputValue !== '') {
      handleUpdateValue()
    }
  }, [debouncedValue])

  useEffect(() => {
    setInputValue(value || '')
  }, [data])

  const handleUpdateInputValue = (e) => {
    setInputValue(e.target.value)
  }

  return (
    <div className={classes.container}>
      <div className={classes.input}>
        <InputBase
          fullWidth
          value={inputValue}
          placeholder={placeholder || t('Invoice_extrainfo')}
          onChange={handleUpdateInputValue}
          disabled={loading}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{
            'aria-label': placeholder || t('Invoice_extrainfo'),
          }}
          /* endAdornment={
          data.snapshotExtraInfo.trim().length > 0 &&
          data.snapshotExtraInfo.trim() !== value.trim() && (
            <Tooltip title={`Beskrivelse på fakturatidspunket: "${data.snapshotExtraInfo}"`}>
              <InputAdornment position="start">
                <InfoIcon className={classes.infoIcon} />
              </InputAdornment>
            </Tooltip>
          )
        } */
        />
      </div>
      {loading && <CircularProgress size={14} className={classes.savingSpinner} />}
    </div>
  )
}

export default ExtraInfoInput
