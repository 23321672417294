import { simcardOrders } from 'services/sweb/me'

export const FETCH_FAILURE = 'endUserSimcardOrders/FETCH_FAILURE'
export const FETCH_BEGIN = 'endUserSimcardOrders/FETCH_BEGIN'
export const FETCH_SUCCESS = 'endUserSimcardOrders/FETCH_SUCCESS'

export const fetchEndUserSimcardOrdersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchEndUserSimcardOrdersBegin = ({ abbId }) => {
  return {
    type: FETCH_BEGIN,
    abbId,
  }
}

export const fetchEndUserSimcardOrdersSuccess = ({ data, abbId }) => {
  return {
    type: FETCH_SUCCESS,
    data,
    abbId,
  }
}

export const fetchEndUserSimcardOrders = ({ abbId }) => {
  return async (dispatch) => {
    dispatch(fetchEndUserSimcardOrdersBegin({ abbId }))
    try {
      const response = await simcardOrders({
        abbId,
      })
      dispatch(fetchEndUserSimcardOrdersSuccess({ abbId, data: response.data }))
    } catch (err) {
      dispatch(fetchEndUserSimcardOrdersFailure(err.response))
    }
  }
}
