import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableRow, TableCell, IconButton, Chip } from '@material-ui/core'
import { ChevronRightRounded as ChevronRightIcon } from '@material-ui/icons'
import { TableCellListItem } from 'components'
import StatusCell from './components/StatusCell'

const useStyles = makeStyles((theme) => ({
  listItemAvatar: {
    minWidth: 44,
  },
  avatar: {
    width: 22,
    height: 22,
    marginLeft: theme.spacing(),
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  chip: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
}))

const NumberTableRow = ({
  country,
  countryName,
  numberWithCountryCode,
  hasErrors,
  isMediationMissing,
  hasValidReference,
  hasInconsistentCustomerIds,
  comments,
  productVersion,
  provider,
}) => {
  const classes = useStyles()
  return (
    <TableRow hover>
      <TableCell padding="none">
        <StatusCell
          hasErrors={hasErrors}
          countryName={countryName}
          countryCode={country.code}
          isMediationMissing={isMediationMissing}
          hasValidReference={hasValidReference}
          hasInconsistentCustomerIds={hasInconsistentCustomerIds}
          numberWithCountryCode={numberWithCountryCode}
        />
      </TableCell>
      <TableCell padding="none">
        <TableCellListItem primary={provider} />
      </TableCell>
      <TableCell padding="none">
        <Chip label={`Version ${productVersion}`} className={classes.chip} size="small" />
      </TableCell>
      <TableCell padding="none">
        <TableCellListItem secondary={<i>{comments}</i>} />
      </TableCell>
      <TableCell padding="none" align="right" style={{ paddingRight: 8, maxWidth: 50, width: 50 }}>
        <IconButton>
          <ChevronRightIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default NumberTableRow
