import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'unassignedSimcards/FETCH_FAILURE'
export const FETCH_BEGIN = 'unassignedSimcards/FETCH_BEGIN'
export const FETCH_SUCCESS = 'unassignedSimcards/FETCH_SUCCESS'

export const fetchUnassignedSimcardsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchUnassignedSimcardsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchUnassignedSimcardsSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchUnassignedSimcards = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchUnassignedSimcardsBegin({ customerId }))
    try {
      const response = await getCustomer.availableUnassignedSimcards({
        customerId,
      })
      dispatch(fetchUnassignedSimcardsSuccess(response.data))
    } catch (err) {
      dispatch(fetchUnassignedSimcardsFailure(err.response))
    }
  }
}
