import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Link } from '@material-ui/core'
import { GradientButton } from 'components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  typography: {
    marginBottom: theme.spacing(3),
  },
  supportLink: {
    textDecoration: 'none !important',
  },
}))

const InnerContent = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <>
      <Typography variant="body1" gutterBottom className={classes.typography}>
        {t('Guides_1')}
      </Typography>
      <Typography variant="body1" gutterBottom display="block" className={classes.typography}>
        {t('Guides_2')}
      </Typography>
      <Typography variant="body1" gutterBottom display="block" className={classes.typography}>
        {t('Guides_3')}
      </Typography>
      <Link
        href="https://support.sky.tdc.dk/hc/da/categories/360001348939-Teams"
        target="_blank"
        className={classes.supportLink}
      >
        <GradientButton>{t('Guides_4')}</GradientButton>
      </Link>
    </>
  )
}

export default InnerContent
