export default {
  responsiveStacked: {
    borderTop: 'none !important',
    borderLeft: 'none !important',
    borderRight: 'none !important',
    borderBottom: '2px solid #f3f3f4 !important',
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}
