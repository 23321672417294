import { getCustomer } from 'services/sweb/customer'
import {
  OCLANDLINE_NUMBERS_FAILURE,
  OCLANDLINE_NUMBERS_BEGIN,
  OCLANDLINE_NUMBERS_SUCCESS,
  ParamsProps,
  IntNumbersActions,
  IntNumbersState,
} from './model'

export const fetchOCLandlineNumbersFailure = (
  error: IntNumbersState['error']
): IntNumbersActions => {
  return {
    type: OCLANDLINE_NUMBERS_FAILURE,
    error,
  }
}

export const fetchOCLandlineNumbersBegin = ({ customerId }: ParamsProps): IntNumbersActions => {
  return {
    type: OCLANDLINE_NUMBERS_BEGIN,
    customerId,
  }
}

export const fetchOCLandlineNumbersSuccess = (data: IntNumbersState['data']): IntNumbersActions => {
  return {
    type: OCLANDLINE_NUMBERS_SUCCESS,
    data,
  }
}

export const fetchOCLandlineNumbers = ({ customerId, pageSize, pageNumber }: ParamsProps) => {
  return async (dispatch) => {
    dispatch(fetchOCLandlineNumbersBegin({ customerId }))
    try {
      const response = await getCustomer.OCLandlineNumbers({
        customerId,
        pageSize,
        pageNumber,
      })

      dispatch(fetchOCLandlineNumbersSuccess(response.data))
    } catch (err) {
      dispatch(fetchOCLandlineNumbersFailure(err.response))
    }
  }
}
