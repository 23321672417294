import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Link } from '@material-ui/core'
import { GetAppOutlined as AppIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import ListSwitch from 'components/ListSwitch'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '12px',
    textTransform: 'none',
    marginRight: -theme.spacing(),
  },
  link: {
    textDecoration: 'none !important',
  },
}))

const AddonsAndFeatures = ({ includesMusic }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <>
      <DrawerListItem
        icon={<AppIcon />}
        primaryText={t('Drawer_user_yousee_music')}
        secondaryText={t('Drawer_user_yousee_desc')}
      >
        {includesMusic ? (
          <Link
            className={classes.link}
            href="https://support.sky.tdc.dk/hc/da/articles/115002664683-Hvordan-f%C3%A5r-jeg-adgang-til-YouSee-Musik-"
            target="_blank"
          >
            <Button variant="text" color="primary" className={classes.button}>
              {t('Drawer_mypage_getaccess')}
            </Button>
          </Link>
        ) : (
          <ListSwitch
            isChecked={Boolean(false)}
            isDisabled={Boolean(true)}
            label={t('Drawer_user_req_mobile')}
          />
        )}
      </DrawerListItem>
    </>
  )
}

export default AddonsAndFeatures
