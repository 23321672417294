import { SWEB_API_ENV } from 'constants/configurable'

export const formatSimcardOptions = (simcards, noneAd = false) => {
  const newSimcardOption = {
    label: 'Drawer_simcard_order_new',
    value: 'orderNew',
    group: '',
    disabled: true,
  }
  const esimSimcardOption = {
    label: 'Drawer_simcard_order_esim',
    value: 'esim',
    group: '',
  }
  const existingSimcardOption = {
    label: 'Drawer_simcard_activate_existing',
    value: 'activateExisting',
    group: '',
  }
  const reservedSimcardOptions =
    (simcards.display_reserved_sim_cards &&
      simcards.reserved_sim_cards &&
      simcards.reserved_sim_cards.length > 0 &&
      simcards.reserved_sim_cards.map((simcard) => {
        return {
          value: simcard,
          group: 'Drawer_simcard_activate_unassigned',
          label: simcard.icc,
        }
      })) ||
    []
  return [
    newSimcardOption,
    !noneAd && esimSimcardOption,
    existingSimcardOption,
    ...reservedSimcardOptions,
  ].filter(Boolean)
}

export const formatLandlineOrder = (landline) => {
  return {
    landline_number_id:
      landline.landlineNumber.value.ani_id || landline.landlineNumber.value.ddi.ani_id,
    extension: landline.extension.localExtension,
  }
}

export const formatMobileOrder = (mobile) => {
  const mobileNumberId = mobile.mobileNumber.value.ani_id || null
  const isImportNumber = mobileNumberId === null
  const isRestingNumber = mobileNumberId > 0
  const isAvailableNumber = mobileNumberId < 0
  const simcardVal = (mobile.simcard && mobile.simcard.value) || null

  const numberImportRequest = () => {
    return {
      mobile_number: mobile.importMobileNumber,
      consent_signers_email_address: mobile.importSigner,
      release_type: 'asap', // release number asap ... cause what else ? who knows
    }
  }

  const atRestNumber = () => {
    const res = {}
    if (simcardVal.id) res.reserved_sim_card_id = simcardVal.id
    return {
      ...res,
      mobile_number_id: mobileNumberId,
      assign_new_simcard: simcardVal !== 'activateExisting',
      esim: simcardVal === 'esim',
      /*
        assign new simcard is only send when it is rest number
        apparently assign new simcard is only false in the scenario where user has chosen to activate an existing simcard
        so assign new simcard will be true for the occasion of choosing an sim card (simcardVal.id)
      */
    }
  }

  const availableNumber = () => {
    const res = {}
    if (simcardVal.id) res.reserved_sim_card_id = simcardVal.id
    return {
      ...res,
      mobile_number_id: mobileNumberId * -1,
      esim: simcardVal === 'esim',
      // for available mobile number choice (not rest mobile number) the mobilenumber id (which is the ani id) most be flipped to a positive number
      // available number will always be a negative number (-123) so we flip the number by doing: -123 * -1
    }
  }

  const res = {}
  if (isImportNumber) res.number_import_request = numberImportRequest()
  if (isRestingNumber) res.at_rest_number = atRestNumber()
  if (isAvailableNumber) res.available_number = availableNumber()

  return {
    ...res,
    flatrate_package_type: mobile.mobilePackage.value.product_type_id,
  }
}

export const formatSubscriptionOrder = ({
  withLandline,
  withMobile,
  withLandlineCancelAction = '',
  withMobileCancelAction = '',
  teamsUserId,
  receiptEmail,
  landline,
  mobile,
  comment = '',
  first_name,
  last_name,
  receiveWelcomeEmail,
}) => {
  const datasimAmount = withMobile && mobile && mobile.datasim ? mobile.datasim.value : 0
  const hasAlternativeSIMAddr =
    mobile.alternativeSimcardAddress && mobile.alternativeSimcardAddress.length > 0

  // only for internal use -> thrown on a zendesk ticket
  const dataSIMTxt =
    datasimAmount > 0
      ? ` | OBS: Denne bruger ønsker følgende antal datadelingskort tilsendt: ${datasimAmount} | `
      : ''
  const alternativeSIMAddrTxt = hasAlternativeSIMAddr
    ? ` | OBS: Denne bruger ønsker simkort leveret til følgende ALTERNATIVE leveringsadresse: ${mobile.alternativeSimcardAddress} | `
    : ''

  const customComment = comment ? ` | OBS - custom comment: ${comment} | ` : ''

  const combinedComment = dataSIMTxt + alternativeSIMAddrTxt + customComment

  const res = {}
  if (withLandline) res.landline = formatLandlineOrder(landline)
  if (withMobile) res.mobile = formatMobileOrder(mobile)

  const cancel = {}
  if (withLandlineCancelAction) cancel.cancel = { landline_cancel_action: withLandlineCancelAction }
  if (withMobileCancelAction) cancel.cancel = { mobile_cancel_action: withMobileCancelAction }

  const teamsId = teamsUserId ? { teams_user_id: teamsUserId } : {}

  const testMode = SWEB_API_ENV === 'development'
  return {
    test_mode: testMode,
    order_info: {
      receipt_email: receiptEmail,
      comment: combinedComment.length > 0 ? combinedComment : '',
      receive_welcome_email: receiptEmail && receiveWelcomeEmail,
    },
    ...cancel,
    ...res,
    ...teamsId,
    ...(first_name && { first_name, last_name }),
  }
}

export const validateMobileForm = ({
  mobileNumber,
  importMobileNumber,
  mobilePackage,
  simcard,
}) => {
  const mobileNumberVal = mobileNumber && mobileNumber.value
  const mobilePackageValid = mobilePackage !== null
  const mobileNumberAniId = mobileNumberVal && Number.isInteger(mobileNumberVal.ani_id)
  const importMobileNumberValid = !mobileNumberAniId && importMobileNumber.length >= 8
  const mobileNumberValid =
    (mobileNumberAniId && mobileNumber.isValid === true) || importMobileNumberValid
  const simValid = importMobileNumberValid ? !simcard : simcard !== null

  return simValid && mobilePackageValid && mobileNumberValid
}
