import palette from '../palette'

export default {
  root: {
    marginLeft: '24px',
    marginRight: '24px',
    borderRadius: '4px',
    backgroundColor: palette.background.default,
    boxShadow: 'none',
    marginBottom: '16px',
    justifyContent: 'flex-start',
    '& div:first-child': {
      marginRight: '24px',
    },
  },
}
