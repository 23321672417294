import React, { useState } from 'react'
import { updateSubscription } from 'services/sweb/subscription'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PaperFeedbackMessage } from 'components'
import DrawerButtons from '../../../components/DrawerButtons'
import { formatSubscriptionOrder } from '../../../helpers/subscription'

const useStyles = makeStyles((theme) => ({
  message: {
    paddingBottom: theme.spacing(3),
  },
  messageWithAlert: {
    paddingBottom: theme.spacing(2),
  },
}))

const CancelConfirmation = ({ abbId, customerId, goBack, cancelTeams, handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const me = useSelector((state) => state.me)
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = () => {
    setLoading(true)
    const { email: receiptEmail } = me.data.user
    const json = formatSubscriptionOrder({
      withLandline: false,
      withMobile: false,
      withLandlineCancelAction: cancelTeams && 'keepnumber',
      withMobileCancelAction: !cancelTeams && 'keepnumber',
      receiptEmail,
      mobile: {},
      landline: {},
    })
    updateSubscription
      .updateSingle({ customerId, abbId, json })
      .then(() => {
        setLoading(false)
        enqueueSnackbar(t('Drawer_cancel_confirmation'), {
          variant: 'success',
        })
        handleClose()
      })
      .catch(() => {
        setLoading(false)
        enqueueSnackbar(t('Downgrade_error_404'), {
          variant: 'warning',
        })
      })
  }

  return (
    <>
      {cancelTeams ? (
        <Typography className={classes.message}>{t('Drawer_cancel_teams_confirmation')}</Typography>
      ) : (
        <>
          <Typography gutterBottom className={classes.messageWithAlert}>
            {t('Drawer_cancel_mobile_confirmation')}
          </Typography>
          <PaperFeedbackMessage withMargin message={t('Resting_mobile_notice')} />
        </>
      )}

      <DrawerButtons
        loading={loading}
        backLink={goBack}
        submitText={t('Confirm')}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default CancelConfirmation
