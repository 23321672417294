import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const LoadingTableRow = ({ colSpan = 8 }) => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={colSpan} padding="none">
          <Skeleton variant="rect" animation="wave" width="100%" height={62} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={colSpan} padding="none">
          <Skeleton variant="rect" animation="wave" width="100%" height={62} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={colSpan} padding="none">
          <Skeleton variant="rect" animation="wave" width="100%" height={62} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={colSpan} padding="none">
          <Skeleton variant="rect" animation="wave" width="100%" height={62} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={colSpan} padding="none">
          <Skeleton variant="rect" animation="wave" width="100%" height={62} />
        </TableCell>
      </TableRow>
    </>
  )
}

export default LoadingTableRow
