import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { TableCellListItem } from '@skytdc/mui/components'
import StatusCell from './components/StatusCell'

const DomainTableRow = ({
  primarySBC,
  backupSBC,
  canBeProvisioned,
  errors,
  onlineVoiceRouteDescription,
  onlineVoiceRouteName,
  // onlineVoiceRoutingPolicyName,
}) => {
  return (
    <TableRow hover>
      <TableCell padding="none">
        <StatusCell hasErrors={Boolean(errors)} primarySbc={primarySBC} backupSbc={backupSBC} />
      </TableCell>
      <TableCell padding="none">
        <TableCellListItem primary={onlineVoiceRouteName} secondary={onlineVoiceRouteDescription} />
      </TableCell>
      <TableCell padding="none">
        <TableCellListItem secondary={canBeProvisioned ? 'Klar til brug' : 'Ikke korrekt opsat'} />
      </TableCell>
    </TableRow>
  )
}

export default DomainTableRow
