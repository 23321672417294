import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { fetchInvoice } from 'state/invoice/actions'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { LoadingSpinner, Alert, PageTitle, Paper, PaperContent } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'
import Layout from '../Layout'
import InvoiceOverview from './InvoiceOverview'
import SingleInvoice from './SingleInvoice'
import InvoiceIntroduction from './InvoiceIntroduction'
import { formatInvoices } from './helpers'

const Invoice = ({ title, match, history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const invoice = useSelector(
    (state) => ({
      data: state.invoice.data,
      error: state.invoice.error,
      ski: state.customer?.data?.customer_properties?.ski,
    }),
    shallowEqual
  )
  const routeIsInvoice = Boolean(match.url.endsWith('/faktura' || '/faktura/'))

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    dispatch(
      fetchInvoice({
        customerId: match.params.customer_id,
        limit: invoice.ski ? 48 : 6,
      })
    )
  }, [])

  const goToInvoiceRoute = (id, type) => {
    history.push(`/${match.params.customer_id}/faktura/${id}/${type}`)
  }
  let content = <LoadingSpinner />

  if (invoice.data && invoice.data.invoices.length === 0) {
    content = (
      <PaperContent>
        <Alert message={t('Invoice_none')} />
      </PaperContent>
    )
  }

  if (invoice.error) {
    content = (
      <PaperContent>
        <Alert type="error" message={invoice.error.statusText} />
      </PaperContent>
    )
  }

  if (invoice.data && invoice.data.invoices.length > 0) {
    content = (
      <>
        {routeIsInvoice && (
          <>
            <PaperContent>
              <InvoiceIntroduction ski={invoice.ski} />
            </PaperContent>
            <InvoiceOverview
              invoices={formatInvoices(invoice.data.invoices, invoice.ski)}
              goToInvoiceRoute={goToInvoiceRoute}
              ski={invoice.ski}
            />
          </>
        )}
        <Switch>
          {invoice.data.invoices.map((inv) => {
            return (
              <Route
                key={inv.billing_id}
                render={(props) => <SingleInvoice invoice={inv} ski={invoice.ski} {...props} />}
                exact
                path={`${match.path}/${inv.billing_id}/:details?`}
              />
            )
          })}
          <Redirect to={`/${match.params.customer_id}/faktura`} />
        </Switch>
      </>
    )
  }

  return (
    <Layout title={t('Invoice_title')}>
      <PageTitle title={t('Invoice_title')} />
      <Paper>
        <PaperContent noPadding>{content}</PaperContent>
      </Paper>
    </Layout>
  )
}

const isInvoiceEqSame = (p, n) => p.match.params.invoice === n.match.params.invoice

export default React.memo(Invoice, isInvoiceEqSame)
