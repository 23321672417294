import React, { memo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { PhoneInTalkOutlined as NumberMaskIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'
import { phoneNumberFormat } from 'utils/helpers'

const DisplayNumber = ({ currentUrl, disabled }) => {
  const { t } = useTranslation()
  const stored = useSelector(
    (state) => ({
      landlineDisplayNumber: state.landlineDisplayNumber,
      landlinePrivateNumber: state.landlinePrivateNumber,
    }),
    shallowEqual
  )

  const createLabel = () => {
    let label = `+${stored.landlineDisplayNumber.data.prefix} ${phoneNumberFormat(
      stored.landlineDisplayNumber.data.number
    )}`
    if (stored.landlinePrivateNumber.data.supported && stored.landlinePrivateNumber.data.enabled) {
      label = t('Drawer_user_secret')
    }
    return label
  }

  const hasError = stored.landlinePrivateNumber.error || stored.landlineDisplayNumber.error

  return (
    <DrawerListItem
      icon={<NumberMaskIcon />}
      primaryText={t('Drawer_user_number_shown')}
      secondaryText={
        stored.landlineDisplayNumber.data && stored.landlinePrivateNumber.data
          ? createLabel()
          : (hasError && t('Drawer_user_err')) || ''
      }
      isDisabled={disabled}
      isLoading={Boolean(!stored.landlineDisplayNumber.data && !stored.landlinePrivateNumber.data)}
      link={`${currentUrl}/fastnet-nummervisning`}
      linkAnchor={
        stored.landlineDisplayNumber.error ? t('Drawer_user_see_more') : t('Drawer_user_skift')
      }
    />
  )
}

export default memo(DisplayNumber)
