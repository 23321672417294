import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import MyParties from '../views/Party/MyParties'
import Admin from '../views/Party/Admin'
import Login from '../views/Party/Login'
import NotFound from '../views/NotFound'

export const mustAuthenticate = ({ location }) => {
  if (location.pathname === '/party/login') return <Login location={location} />
  return (
    <>
      <Route component={Login} path="*" />
      <Redirect
        to={{
          pathname: '/party/login',
          search: location.search,
          state: { from: location.pathname },
        }}
      />
    </>
  )
}

export default React.memo<{ me: any }>(({ me }) => {
  return (
    <Switch>
      <Redirect exact from="/party" to="/party/login" />
      {me.parties.map(({ id }) => (
        <Route key={id} exact path={`/party/id/${id}`} component={MyParties} />
      ))}
      <Route exact path="/party/login" component={Login} />
      {me?.user?.admin && <Route exact path="/party/admin/:partyId?" component={Admin} />}
      <Route component={NotFound} />
    </Switch>
  )
})
