import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'presenceAppSecrets/FETCH_FAILURE'
export const FETCH_BEGIN = 'presenceAppSecrets/FETCH_BEGIN'
export const FETCH_SUCCESS = 'presenceAppSecrets/FETCH_SUCCESS'

export const fetchPresenceAppSecretsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchPresenceAppSecretsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchPresenceAppSecretsSuccess = ({ customerId, data }) => {
  return {
    type: FETCH_SUCCESS,
    customerId,
    data,
  }
}

export const fetchPresenceAppSecrets = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchPresenceAppSecretsBegin({ customerId }))
    try {
      const response = await getCustomer.presenceAppSecret({
        customerId,
      })
      dispatch(fetchPresenceAppSecretsSuccess({ customerId, data: response.data }))
    } catch (err) {
      dispatch(fetchPresenceAppSecretsFailure(err.response))
    }
  }
}
