import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'customers/FETCH_FAILURE'
export const FETCH_BEGIN = 'customers/FETCH_BEGIN'
export const FETCH_SUCCESS = 'customers/FETCH_SUCCESS'

export const fetchCustomersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchCustomersBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchCustomersSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchCustomers = () => {
  return async (dispatch) => {
    dispatch(fetchCustomersBegin())
    try {
      const response = await getCustomer.all()
      dispatch(fetchCustomersSuccess(response.data))
    } catch (err) {
      dispatch(fetchCustomersFailure(err.response))
    }
  }
}
