import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Menu, MenuProps } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    minWidth: 250,
    maxWidth: '90%',
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    boxShadow:
      '0px 5px 4px -3px rgb(0 0 0 / 8%), 0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 9%)',
  },
}))

interface Props {
  anchorEl: MenuProps['anchorEl']
  handleClose: () => void
  children: JSX.Element | JSX.Element[]
  autoFocus?: boolean
  showBelowAnchor?: boolean
  variant?: MenuProps['variant']
  disableAutoFocusItem?: boolean
}

const DropdownMenu = ({
  anchorEl,
  handleClose,
  children,
  autoFocus = true,
  showBelowAnchor,
  variant = 'selectedMenu',
  disableAutoFocusItem,
}: Props) => {
  const classes = useStyles()

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      autoFocus={autoFocus}
      disableAutoFocusItem={disableAutoFocusItem}
      transitionDuration={200}
      variant={variant}
      classes={{
        paper: classes.menuPaper,
      }}
      getContentAnchorEl={showBelowAnchor ? null : undefined}
      anchorOrigin={showBelowAnchor ? { vertical: 'bottom', horizontal: 'center' } : undefined}
      transformOrigin={showBelowAnchor ? { vertical: 'top', horizontal: 'center' } : undefined}
    >
      <div>{children}</div>
    </Menu>
  )
}

export default DropdownMenu
