import palette from '../palette'
import shape from '../shape'

export default {
  root: {
    borderBottom: `0px solid ${palette.background.default}`,
    padding: '16px 40px 14px 16px',
    height: 62,
    fontSize: '14px',
    '&:first-child': {
      borderTopLeftRadius: shape.borderRadius,
      borderBottomLeftRadius: shape.borderRadius,
    },
    '&:last-child': {
      borderTopRightRadius: shape.borderRadius,
      borderBottomRightRadius: shape.borderRadius,
    },
  },
  head: {
    fontSize: '14px',
    fontWeight: 700,
  },
}
