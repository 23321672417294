import client from '../client'

export const msAuthenticate = ({ code, redirectUri } = {}) => {
  return client
    .post(`authentication/aad-authcode`, {
      json: {
        auth_code: code,
        redirect_uri: redirectUri,
      },
    })
    .json()
}

export const addBearer = (bearer) => client.addBearer({ storage: 'localStorage', value: bearer })

export const removeBearer = () => client.removeBearer({ storage: 'localStorage' })

export const getBearer = () => client.getBearer()
