import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'dataPool/FETCH_FAILURE'
export const FETCH_BEGIN = 'dataPool/FETCH_BEGIN'
export const FETCH_SUCCESS = 'dataPool/FETCH_SUCCESS'

export const fetchDataPoolFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchDataPoolBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchDataPoolSuccess = ({ data }) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchDataPool = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchDataPoolBegin())
    try {
      const response = await getSubscription.dataPool({
        customerId,
      })
      dispatch(fetchDataPoolSuccess({ data: response.data }))
    } catch (err) {
      dispatch(fetchDataPoolFailure(err.response))
    }
  }
}
