import { responsiveFontSizes, createMuiTheme } from '@material-ui/core/styles'
import palette from './palette'
import typography from './typography'
import overrides from './overrides'
import notistack from './notistack'
import shape from './shape'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    info: PaletteColor
    border: string
    divider: string
  }
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    border: string
    divider: string
  }
  interface ThemeOptions {
    border?: string
    divider?: string
  }
}

const muiTheme = createMuiTheme({
  typography,
  palette,
  overrides,
  shape,
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
})

export { palette, notistack }
export default responsiveFontSizes(muiTheme)
