import { format } from 'date-fns'

export const formatTimestamp = (timestamp) => {
  const convertToDate = new Date(timestamp)
  return format(convertToDate, 'dd-MM-yyyy HH:mm')
}

export const formatStatus = (resultId) => {
  // done
  if (resultId === 1) return 'Organization_orders_status_done'
  // processing
  if (resultId === 2) return 'Organization_orders_status_ongoing'
  // cancelled
  if (resultId === 3) return 'Organization_orders_status_cancelled'
  // paused
  if (resultId === 4) return 'Organization_orders_status_paused'
  // if above 5 -> error
  if (resultId > 4) return 'OrdersError'
  return null
}
