import React from 'react'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Paper, PaperHeader, PaperTitle, PaperToolbar } from 'components'
import { useTranslation } from 'react-i18next'
import DownloadPdfIconButton from '../components/DownloadPdfIconButton'
import GeneralInvoiceData from './GeneralInvoiceData'
import BackButton from './BackButton'
import DetailsTabs from './DetailsTabs'

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: theme.spacing(3),
  },
  chartWrapper: {
    float: 'left',
    marginLeft: theme.spacing(3),
  },
}))

const SingleInvoice = ({ invoice, ski, history, match, location }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const title = ski ? t('Invoice_subTitle_Quarter_Spent') : t('Invoice_subTitle')

  const backLink = () => {
    history.push(`/${match.params.customer_id}/faktura`)
  }

  if (location.pathname === `/${match.params.customer_id}/faktura/${invoice.billing_id}`) {
    return (
      <Redirect to={`/${match.params.customer_id}/faktura/${invoice.billing_id}/abonnementer`} />
    )
  }

  return (
    <Grid container style={{ padding: '0 16px' }}>
      <Grid item xs={12}>
        <Paper>
          <PaperHeader noDivider>
            <PaperTitle title={t('Invoice_invoice_number', { number: invoice.billing_id })} />
            <PaperToolbar>
              <DownloadPdfIconButton
                invoiceId={invoice.billing_id}
                customerId={invoice.customer_id}
                canDownloadPdf={invoice.pdf_download_enabled}
              />
            </PaperToolbar>
          </PaperHeader>
          <BackButton backLink={backLink} />
          <GeneralInvoiceData
            title={title}
            subscriptionFees={invoice.subscriptionFees}
            mobileFees={invoice.mobileFees}
            fixedLineFees={invoice.fixedLineFees}
            extraServiceFees={invoice.extraServiceFees}
            totalFees={invoice.totalFees}
            billingDate={invoice.billing_date}
            billingPeriod={t('Invoice_period', {
              from: invoice.billing_from,
              to: invoice.billing_to,
            })}
            ocr={invoice.ocr_line}
          />
          <DetailsTabs
            pathname={location.pathname}
            path={match.path}
            customerId={match.params.customer_id}
            invoiceId={invoice.billing_id}
            subscriptionFees={invoice.subscription_fees}
            mobileFees={invoice.mobile_fees}
            fixedLineFees={invoice.fixed_line_fees}
            extraServiceFees={invoice.extra_service_fees}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

const isLocationEq = (p, n) => p.location.pathname === n.location.pathname

export default React.memo(SingleInvoice, isLocationEq)
