import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Fab, Tooltip, Grid } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingBottom: theme.spacing(5),
  },
}))

const FloatingButton = ({
  icon = <AddIcon />,
  tooltip,
  disabled = false,
  onClick,
  size,
  className,
}) => {
  const classes = useStyles()
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={`${classes.grid} ${className || ''}`}
    >
      <Tooltip title={tooltip} aria-label={tooltip} arrow>
        <Fab color="primary" aria-label={tooltip} disabled={disabled} onClick={onClick} size={size}>
          {icon}
        </Fab>
      </Tooltip>
    </Grid>
  )
}

export default FloatingButton
