import React, { Component, ErrorInfo, ReactNode } from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { ENV, SENTRY_DSN } from 'constants/configurable'
import { MODE, SENTRY_RELEASE } from 'constants/build'

import Fallback from './Fallback'

const shouldConnectLog = MODE === 'production' && SENTRY_RELEASE
const config = {}

if (shouldConnectLog) {
  Object.assign(config, {
    environment: ENV,
    release: SENTRY_RELEASE,
    dsn: SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5, // https://docs.sentry.io/platforms/javascript/performance/
  })
}

Sentry.init(config)

type Props = {
  children: ReactNode
}
type State = {
  error: Error
  errorInfo: ErrorInfo
}

class ErrorBoundary extends Component<Props, State> {
  // It is OOP based at this point (early 2021). unless using wrapper library (found this to be overkill).
  // > official doc: https://reactjs.org/docs/error-boundaries.html
  constructor(props: Props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
    }
    this.reset = this.reset.bind(this)
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })
    // the error will be consoled. should be no need to console.error or whatever floats
    // .. note that you can install react devtools for more react like errors
  }

  reset() {
    this.setState({
      error: null,
      errorInfo: null,
    })
  }

  public render() {
    const { state, props } = this
    if (state.error) {
      return <Fallback error={state.error} errorInfo={state.errorInfo} handleReset={this.reset} />
    }

    return props.children
  }
}

export default ErrorBoundary
