import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchOrderDetailsOverview } from 'state/orderDetailsOverview/actions'
import { Paper, PaperContent, PaperLoader, PaperFeedbackMessage, MuiDataTable } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, ListItem, ListItemText } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { formatStatus, formatType } from './helpers'
import OrderDrawer from './components/OrderDrawer'

const useStyles = makeStyles((theme) => ({
  intro: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  complete: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main,
  },
  pending: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
  },
  failed: {
    backgroundColor: '#ffcece',
    color: '#b63f3f',
  },
  shared: {
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
  },
  paddingMargin: {
    padding: 0,
    margin: 0,
  },
}))

const Orders = ({ match, history }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const orderDetailsOverview = useSelector((state) => state.orderDetailsOverview)
  const [combinedOrders, setCombinedOrders] = useState([])

  const options = {
    searchOpen: true,
    searchPlaceholder: t('Organization_orders_carried_out'),
    selectableRows: 'none',
    rowHover: true,
    viewColumns: false,
    onRowClick: (rowData) => {
      history.push(`/${match.params.customer_id}/organisation/ordrer/${rowData[0]}`)
    },
  }

  const columns = [
    {
      name: 'order_id',
      label: 'ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: 'statusFormatted',
      label: 'Status',
      align: 'left',
      options: {
        customBodyRender: (value) => {
          return (
            <span
              className={`${classes.shared} ${(value === 'Udført' && classes.complete) ||
                (value === 'Igangværende' && classes.pending) ||
                (value === 'Annulleret' && classes.failed)}`}
            >
              {value}
            </span>
          )
        },
      },
    },
    {
      name: 'typeFormatted',
      label: t('Organization_orders_action'),
      align: 'left',
    },
    {
      name: 'orderedby',
      label: t('Organization_orders_orderedby'),
      align: 'left',
    },
    {
      name: 'inserted',
      label: t('Organization_orders_order_date'), // + actionDate if one
      align: 'left',
      options: {
        customBodyRender: (value, tableMeta) => {
          const getActionDate = tableMeta.rowData[tableMeta.rowData.length - 1]
          return (
            <ListItem disableGutters className={classes.paddingMargin}>
              <ListItemText
                className={classes.paddingMargin}
                primary={value}
                secondary={
                  getActionDate &&
                  t('Organization_orders_drawer_action_date', { date: getActionDate })
                }
              />
            </ListItem>
          )
        },
      },
    },
    {
      name: 'actionDate',
      options: {
        display: false,
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ]

  useEffect(() => {
    dispatch(fetchOrderDetailsOverview({ customerId: match.params.customer_id }))
  }, [])

  useEffect(() => {
    if (orderDetailsOverview.data) {
      let combined = []
      Object.keys(orderDetailsOverview.data).forEach((orderKey) => {
        combined = [...combined, ...orderDetailsOverview.data[orderKey]]
      })
      combined = combined.map((order) => {
        return {
          ...order,
          statusFormatted: t(formatStatus(order.status)),
          typeFormatted: t(formatType(order.type)),
          inserted: `${order.inserted || t('Organization_orders_not_available')}`,
          actionDate: order.actionDate || null,
        }
      })
      setCombinedOrders(combined)
    }
  }, [orderDetailsOverview])

  let content = <PaperLoader />

  if (orderDetailsOverview.error) {
    content = (
      <PaperFeedbackMessage
        message={orderDetailsOverview.error.statusText}
        type="error"
        withMargin
        disableGutters={false}
      />
    )
  }

  if (combinedOrders.length === 0 && !orderDetailsOverview.loading && orderDetailsOverview.data) {
    content = (
      <PaperFeedbackMessage
        message={t('Organization_orders_no_orders')}
        withMargin
        disableGutters={false}
      />
    )
  }

  if (combinedOrders.length > 0) {
    content = (
      <>
        <MuiDataTable data={combinedOrders} options={options} columns={columns} />
        <Switch>
          {combinedOrders.map((order) => {
            return (
              <Route
                key={order.order_id}
                render={(props) => <OrderDrawer order={order} {...props} />}
                exact
                path={`/:customer_id/organisation/ordrer/${order.order_id}`}
              />
            )
          })}
        </Switch>
      </>
    )
  }

  return (
    <Paper>
      <PaperContent noPadding>
        <Typography variant="body1" className={classes.intro}>
          {t('Organization_orders_description')}
        </Typography>
        {content}
      </PaperContent>
    </Paper>
  )
}

export default Orders
