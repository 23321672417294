import palette, { primaryLight } from '../palette'

export default {
  label: {
    textTransform: 'none',
  },
  sizeSmall: {
    fontSize: '12px',
  },
  sizeLarge: {},
  outlined: {},
  contained: {
    backgroundColor: palette.common.white,
    '&:hover': {
      backgroundColor: palette.common.neutral,
    },
  },
  containedPrimary: {
    '&:hover': {
      backgroundColor: primaryLight,
    },
  },
}
