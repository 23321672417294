import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List } from '@material-ui/core'
import { GradientButton, PaperFeedbackMessage } from 'components'
import { useTranslation } from 'react-i18next'
import StepContainer from '../StepContainer'
import TextBlock from '../TextBlock'
import HealthCheckItem from '../HealthCheckItem'

const useStyles = makeStyles((theme) => ({
  btnWrapper: {
    display: 'inline-block',
  },
  list: {
    marginBottom: theme.spacing(2),
  },
}))

const StepHealthCheck = ({ currentStep, healthCheck, performHealthCheck, presencePage }) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const handleHealthCheck = () => {
    performHealthCheck()
  }
  const healthCheckHasItems = healthCheck.data && healthCheck.data.healthcheck.length > 0
  const healthCheckHasError =
    healthCheckHasItems &&
    healthCheck.data.healthcheck.find((item) => item.status.toLowerCase() !== 'ok')

  const andTranslated = i18n.language === 'da' ? 'og' : 'and'

  return (
    <>
      <StepContainer step={currentStep} requiredStep={3} stepLabel={t('Wizard_00awef')}>
        <TextBlock>
          {t('Wizard_345345', {
            steps: presencePage ? `1 ${andTranslated} 2` : `1,2 ${andTranslated} 3`,
          })}
        </TextBlock>
        <TextBlock inputMargin={!healthCheckHasItems && !healthCheckHasError}>
          {t('Wizard_91234')}
        </TextBlock>
        {healthCheckHasError && <TextBlock inputMargin>{t('Wizard_003456')}</TextBlock>}
        {healthCheckHasItems > 0 && (
          <List className={classes.list}>
            {healthCheck.data.healthcheck.map((item) => (
              <HealthCheckItem
                key={item.name}
                name={item.name}
                status={item.status}
                message={item.message}
              />
            ))}
          </List>
        )}
        {healthCheck.error && (
          <PaperFeedbackMessage type="error" message={healthCheck.error.statusText} />
        )}
        <div className={classes.btnWrapper}>
          <GradientButton loading={healthCheck.loading} onClick={handleHealthCheck}>
            {healthCheckHasItems ? t('Wizard_4567') : t('Wizard_00awef')}
          </GradientButton>
        </div>
      </StepContainer>
    </>
  )
}

export default memo(StepHealthCheck)
