import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Switch } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ErrorOutline as ErrorIcon } from '@material-ui/icons'
import { DrawerSection } from 'components'

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
  alertMsg: {
    fontSize: 14,
    lineHeight: '22px',
    marginTop: -1,
  },
}))

const DisallowText = ({ text }) => {
  const textArr = Array.isArray(text) ? [...text] : [text]
  return textArr.map((txt, index) => {
    return index > 0 ? ` ${txt}` : txt
  })
}

const FormToggleSwitch = ({ isToggled, disallowToggle, disallowToggleText, onToggle, title }) => {
  const classes = useStyles()

  return (
    <>
      <DrawerSection
        title={title}
        toolbar={
          disallowToggle ? (
            <ErrorIcon className={classes.warning} />
          ) : (
            <Switch edge="end" checked={isToggled} onChange={onToggle} color="primary" />
          )
        }
      />
      <DrawerSection>
        {disallowToggle && disallowToggleText && (
          <Alert severity="warning" classes={{ message: classes.alertMsg }}>
            <DisallowText text={disallowToggleText} />
          </Alert>
        )}
      </DrawerSection>
    </>
  )
}

export default FormToggleSwitch
