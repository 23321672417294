import React, { useState, useEffect } from 'react'
import { DrawerSection } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Grid,
  List,
  Card,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
} from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import DrawerButtons from '../DrawerButtons'

const useStyles = makeStyles((theme) => ({
  message: {
    paddingBottom: theme.spacing(3),
  },
  root: {
    margin: 'auto',
  },
  list: {
    height: 300,
    backgroundColor: theme.palette.primary.light,
    overflow: 'auto',
    padding: 0,
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  title: {
    paddingBottom: theme.spacing(1.4),
  },
  card: {
    boxShadow: 'none',
  },
}))

const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1)
}

const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1)
}

const union = (a, b) => {
  return [...a, ...not(b, a)]
}

const GroupNumbersTransferList = ({
  loading,
  backLink,
  setGroupNumbers,
  customerGroupNumbers,
  subscriptionGroupNumbers,
  activeMobileDisplay,
  activeLandlineDisplay,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [checked, setChecked] = useState([])
  const [availableGroupNumbers, setAvailableGroupNumbers] = useState([])
  const [currentGroupNumbers, setCurrentGroupNumbers] = useState(subscriptionGroupNumbers)

  const availableGroupNumbersChecked = intersection(checked, availableGroupNumbers)
  const currentGroupNumbersChecked = intersection(checked, currentGroupNumbers)

  useEffect(() => {
    let filterGroupNumbers = []
    const ids = subscriptionGroupNumbers.map((currentNumber) => currentNumber.ani_id)
    filterGroupNumbers = customerGroupNumbers.filter((availableNumber) => {
      return !ids.includes(availableNumber.ani_id)
    })
    setAvailableGroupNumbers(filterGroupNumbers)
  }, [])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const numberOfChecked = (items) => intersection(checked, items).length

  const handleToggleAll = (items) => () => {
    const newItems = items.filter(
      (item) =>
        activeMobileDisplay &&
        item.number !== activeMobileDisplay.number &&
        activeLandlineDisplay &&
        item.number !== activeLandlineDisplay.number
    )
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, newItems))
    } else {
      setChecked(union(checked, newItems))
    }
  }

  const handleCheckedRight = () => {
    setCurrentGroupNumbers(currentGroupNumbers.concat(availableGroupNumbersChecked))
    setAvailableGroupNumbers(not(availableGroupNumbers, availableGroupNumbersChecked))
    setChecked(not(checked, availableGroupNumbersChecked))
  }

  const handleCheckedLeft = () => {
    setAvailableGroupNumbers(availableGroupNumbers.concat(currentGroupNumbersChecked))
    setCurrentGroupNumbers(not(currentGroupNumbers, currentGroupNumbersChecked))
    setChecked(not(checked, currentGroupNumbersChecked))
  }

  const handleSetGroupNumbers = () => {
    const anis = currentGroupNumbers.map((number) => number.ani_id)
    setGroupNumbers(anis)
  }

  const transferList = (title, items) => (
    <>
      <Typography className={classes.title}>{title}</Typography>
      <Card className={classes.card}>
        <List className={classes.list} component="div">
          <ListItem button onClick={handleToggleAll(items)}>
            <ListItemIcon>
              <Checkbox
                checked={numberOfChecked(items) === items.length && items.length !== 0}
                indeterminate={
                  numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                }
                disabled={items.length === 0}
                color="primary"
              />
            </ListItemIcon>
            <ListItemText
              primary={t('Drawer_groupNumber', { from: numberOfChecked(items), to: items.length })}
            />
          </ListItem>
          <Divider />
          {items.map((item) => {
            const landlineInUse = Boolean(
              activeLandlineDisplay && item.number === activeLandlineDisplay.number
            )
            const mobileInUse = Boolean(
              activeMobileDisplay && item.number === activeMobileDisplay.number
            )
            return (
              <ListItem
                key={item.ani_id}
                button
                onClick={handleToggle(item)}
                disabled={landlineInUse || mobileInUse}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    color="primary"
                    disabled={landlineInUse || mobileInUse}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`+${item.prefix} ${item.number}`}
                  secondary={
                    (landlineInUse && t('Drawer_in_use_landline')) ||
                    (mobileInUse && t('Drawer_in_use_mobile'))
                  }
                />
              </ListItem>
            )
          })}
          {items.length === 0 && (
            <ListItem>
              <ListItemText primary={t('Drawer_not_avail')} />
            </ListItem>
          )}
          <ListItem />
        </List>
      </Card>
    </>
  )

  return (
    <>
      <DrawerSection title={t('Group_number_title')}>
        <Typography className={classes.message}>{t('Group_numbers_desc')}</Typography>
        <Grid container justify="center" alignItems="center" className={classes.root}>
          <Grid item lg={5} sm={5} xl={5} xs={12}>
            {transferList(t('Group_number_yours'), availableGroupNumbers)}
          </Grid>
          <Grid item lg={2} sm={2} xl={2} xs={12}>
            <Grid container direction="column" alignItems="center">
              <Button
                color="primary"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={availableGroupNumbersChecked.length === 0}
                aria-label="add selected numbers to the pool of available numbers"
              >
                <ChevronRight />
              </Button>
              <Button
                color="primary"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={currentGroupNumbersChecked.length === 0}
                aria-label="remove selected numbers from pool of available numbers"
              >
                <ChevronLeft />
              </Button>
            </Grid>
          </Grid>
          <Grid item lg={5} sm={5} xl={5} xs={12}>
            {transferList(t('Group_numbers_choosable'), currentGroupNumbers)}
          </Grid>
        </Grid>
      </DrawerSection>
      <DrawerButtons
        backLink={backLink}
        submitText={t('Saveandupdate')}
        loading={loading}
        disabled={subscriptionGroupNumbers === currentGroupNumbers}
        onSubmit={handleSetGroupNumbers}
      />
    </>
  )
}

export default GroupNumbersTransferList
