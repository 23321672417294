export const OCINVITES_FAILURE = 'OCINVITES_FAILURE'
export const OCINVITES_BEGIN = 'OCINVITES_BEGIN'
export const OCINVITES_SUCCESS = 'OCINVITES_SUCCESS'

export interface ParamsProps {
  customerId: string
}

interface FetchIntNumbersBegin extends ParamsProps {
  type: typeof OCINVITES_BEGIN
}

interface FetchIntNumbersFailure {
  type: typeof OCINVITES_FAILURE
  // eslint-disable-next-line
  error: any
}

export interface FetchIntNumbersSuccess {
  type: typeof OCINVITES_SUCCESS
  // eslint-disable-next-line
  data: any
}

export type IntNumbersActions =
  | FetchIntNumbersBegin
  | FetchIntNumbersFailure
  | FetchIntNumbersSuccess

export interface IntNumbersState extends ParamsProps {
  loading: boolean
  // eslint-disable-next-line
  data?: any
  // eslint-disable-next-line
  error?: Error
}
