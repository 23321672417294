import { getUser } from 'services/sweb/user'

export const FETCH_FAILURE = 'userProfilePhoto/FETCH_FAILURE'
export const FETCH_BEGIN = 'userProfilePhoto/FETCH_BEGIN'
export const FETCH_SUCCESS = 'userProfilePhoto/FETCH_SUCCESS'

export const fetchUserProfilePhotoFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchUserProfilePhotoBegin = ({ azureId }) => {
  return {
    type: FETCH_BEGIN,
    azureId,
  }
}

export const fetchUserProfilePhotoSuccess = ({ data, azureId }) => {
  return {
    type: FETCH_SUCCESS,
    data,
    azureId,
  }
}

export const fetchUserProfilePhoto = ({ customerId, azureId }) => {
  return async (dispatch) => {
    dispatch(fetchUserProfilePhotoBegin({ azureId }))
    const json = {
      azureIds: [azureId],
    }
    try {
      const response = await getUser.profilePhoto({
        customerId,
        json,
      })
      dispatch(fetchUserProfilePhotoSuccess({ azureId, data: response.data }))
    } catch (err) {
      dispatch(fetchUserProfilePhotoFailure(err.response))
    }
  }
}
