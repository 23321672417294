import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DrawerWrapper, PaperLoader, PaperContent, Paper } from 'components'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { getCustomer, createCustomer } from 'services/sweb/customer'
import OrderForm from '../OrderForm'

const OrderFormContainer = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const [pricedServices, setPricedServices] = useState(null)
  const customer = useSelector((state) => state.customer)

  const closeDrawer = () => {
    history.push(`/${match.params.customer_id}/organisation/skuffesimkort`)
  }

  const getPricedServices = () => {
    getCustomer
      .pricedServices({ customerId: match.params.customer_id })
      .then((response) => {
        setPricedServices(response.data)
        setLoading(false)
      })
      .catch((err) => {
        err.response.json().then((res) =>
          enqueueSnackbar(
            t('Organization_unassignedsimcards_avail_fetch_error', { error: res.error.text }),
            {
              variant: 'error',
            }
          )
        )
        setLoading(false)
        closeDrawer()
      })
  }

  const orderUnassignedSimcards = (json) => {
    setLoading(true)
    createCustomer
      .orderUnassignedSimcards({ customerId: match.params.customer_id, json })
      .then(() => {
        enqueueSnackbar(t('Organization_unassignedsimcards_order_success'), {
          variant: 'success',
        })
        closeDrawer()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  useEffect(() => {
    getPricedServices()
  }, [])

  useEffect(() => {
    if (pricedServices && !pricedServices.allowed_to_order_from_sim_service) {
      enqueueSnackbar(t('Organization_unassignedsimcards_not_allowed'), {
        variant: 'info',
      })
      closeDrawer()
    }
  }, [pricedServices])

  let content = <PaperLoader />

  if (pricedServices && pricedServices.allowed_to_order_from_sim_service) {
    content = (
      <OrderForm
        loading={loading}
        orderUnassignedSimcards={orderUnassignedSimcards}
        backLink={closeDrawer}
        address={customer.data.address}
        simcards={pricedServices.priced_sim_services}
      />
    )
  }

  return (
    <DrawerWrapper closeDrawer={closeDrawer}>
      <Paper>
        <PaperContent>{content}</PaperContent>
      </Paper>
    </DrawerWrapper>
  )
}

export default OrderFormContainer
