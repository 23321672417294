import React, { useState, useEffect, ReactChild } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import { Typography, Avatar, Link, Stepper, Step, StepLabel, Button } from '@material-ui/core'
import { CloudContainer, Paper, PaperContent, PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import * as authenticate from 'services/sweb/authentication'

import {
  PersonAdd as PersonAddIcon,
  ErrorOutline as ErrorOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@material-ui/icons'
import { createSubscription } from 'services/sweb/subscription'
import { IMG_FLAG_GB, IMG_FLAG_DK, IMG_MICROSOFT_LOGO } from 'constants/cdn'
import { MS_AUTH_REDIRECTED_OUTSIDE_TEAMS_CALLBACK_URI } from 'constants/app'
import { authViaWindowOpen } from 'services/msauth'
import UserCreated from './components/UserCreated'
import UserProfile from './components/UserProfile'
import UserPincode from './components/UserPincode'
import { getInviteStatus, getMicrosoftLoginStatus } from './helpers'

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    padding: 0,
    marginBottom: '1.5rem',
  },
  paper: {
    width: '100%',
    minHeight: 500,
    margin: '5.5rem auto 1rem',
    padding: '2rem',
    '@media (min-width: 960px)': {
      width: '700px',
    },
    '& > div': {
      width: '100%',
    },
    '& form': {
      textAlign: 'center',
      margin: '2rem 0 1rem',
      '&>div': {
        margin: '.5rem 0 2rem',
        width: '300px',
      },
      '& >p': {
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    },
    '& button, & a': {
      marginTop: '2rem',
    },
  },
  supportLink: {
    color: theme.palette.text.secondary,
  },
  footerText: {
    marginTop: '1rem',
    fontStyle: 'italic',
    maxWidth: '80%',
  },
  avatar: {
    margin: '0 auto 1rem',
    height: 80,
    width: 80,
    '&>svg': {
      height: 40,
      width: 40,
    },
  },
  link: {
    margin: ' 2rem auto 0',
    display: 'block',
    border: ' 2px solid black',
    borderRadius: '8px',
    width: '100%',
    textAlign: 'center',
    padding: 10,
    color: theme.palette.common.black,
    fontWeight: 'bold',
    transition: 'background 1s',
    '@media (min-width: 960px)': {
      width: '50%',
    },
    '&:hover': {
      textDecoration: 'none',
      background: theme.palette.primary.light,
    },
  },
  success: {
    background: theme.palette.primary.light,
  },
  userCreated: {
    background: theme.palette.success.light,
    '&>svg': {
      color: theme.palette.success.dark,
    },
  },
  error: {
    background: theme.palette.error.light,
  },

  loading: {
    background: 'transparent',
  },
  container: {
    textAlign: 'center',
    '& >a': {
      color: 'white',
      marginRight: '1rem',
    },
  },
  langButton: {
    border: 'none',
    cursor: 'pointer',
    background: 'none',
  },
  langImage: {
    borderRadius: '50%',
    verticalAlign: 'middle',
  },
  loginEmail: {
    fontSize: '18px',
    color: theme.palette.primary.main,
    marginTop: '0.5rem',
    fontWeight: 'normal',
  },
  buttonLarge: {
    padding: '.5rem 3rem',
  },
}))

interface InvitedUserData {
  customerId?: string
  sendtTo?: string
}

const InvitePage = ({ history, match }) => {
  const { t, i18n } = useTranslation()
  const { uuid } = match.params
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [status, setStatus] = useState('initial start')
  const [pincode, setPincode] = useState('')
  const [invitedUserData, setInvitedUserData] = useState<InvitedUserData>({})
  useEffect(() => {
    if (!uuid || uuid.length !== 36) {
      setStatus('incorrect url')
    } else {
      setStatus('correct url')
    }
  }, [])

  const authWindowOpen = (email: string) => {
    return authViaWindowOpen({ userEmail: email }).then(({ code }) => {
      setStatus('initial start')
      return createSubscription
        .createOCUser({
          uuid,
          pincode,
          json: {
            auth_code: code,
            redirect_uri: MS_AUTH_REDIRECTED_OUTSIDE_TEAMS_CALLBACK_URI,
          },
        })
        .then(({ data }) => {
          const MSLoginstatus: any = getMicrosoftLoginStatus(data.identityStatus.statusCode)

          if (data.identityStatus.statusCode === 0) {
            authenticate.addBearer(data.token.access_token)
          }
          setStatus(MSLoginstatus)
          enqueueSnackbar(t(`OC_invite_${MSLoginstatus.replaceAll(' ', '_')}`), {
            variant: MSLoginstatus === 'user created' ? 'success' : 'error',
          })
        })
        .catch((err) => {
          setStatus('error')
          err.response.json().then((res) => {
            enqueueSnackbar(`${res.error.text}, ${t('OC_invite_error')}`, { variant: 'error' })
          })
        })
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setStatus('loading')
    createSubscription
      .verifyOCUser({ uuid, pincode })
      .then(({ data }) => {
        if (data?.statusCode === 0) {
          setInvitedUserData(data.invite)
          authWindowOpen(data?.invite?.sendtTo)
        }
        setStatus(getInviteStatus(data?.statusCode))
      })
      .catch(() => {
        setStatus('error')
        enqueueSnackbar(t('OC_invite_error'), { variant: 'error' })
      })
  }

  const getContent = (inviteStatus: string) => {
    switch (inviteStatus) {
      case 'initial start':
        return {
          icon: <PaperLoader />,
          title: t('OC_invite_loading'),
          statusClass: classes.loading,
        }
      case 'incorrect url':
        return {
          title: t('OC_invite_wrong_url'),
          content: (
            <Button
              color="primary"
              className={classes.buttonLarge}
              href="https://support.sky.tdc.dk/hc/da/requests/new"
              variant="outlined"
            >
              {t('Layout_contact')}
            </Button>
          ),
        }
      case 'no user':
        return {
          title: t('OC_invite_no_user'),
          content: (
            <Button
              color="primary"
              className={classes.buttonLarge}
              variant="outlined"
              onClick={() => setStatus('correct url')}
            >
              {t('OC_invite_try_again')}
            </Button>
          ),
        }
      case 'already completed':
        return {
          icon: <CheckCircleOutlineIcon />,
          title: t('OC_invite_already_used'),
          statusClass: classes.userCreated,
          content: '',
        }
      case 'wrong user':
        return {
          title: t('OC_invite_wrong_user'),
        }
      case 'user not found':
        return {
          title: t('OC_invite_user_not_found'),
        }
      case 'not synced to support':
        return {
          title: t('OC_invite_customer_account_not_synced_to_support'),
        }
      case 'is cancelled':
        return {
          title: t('OC_invite_is_cancelled'),
        }
      case 'consent withdrawn':
        return {
          title: t('OC_invite_consent_withdrawn'),
        }
      case 'customer_account_not_ready':
        return {
          title: t('OC_invite_customer_account_not_ready'),
        }
      case 'user profile':
        return {
          icon: (
            <img src={IMG_MICROSOFT_LOGO} width="30px" height="30px" alt="Log in with Microsoft" />
          ),
          statusClass: classes.success,
          title: (
            <>
              <div>{t('Login_logon_microsoft')}</div>
              <div className={classes.loginEmail}>{invitedUserData?.sendtTo}</div>
            </>
          ),
          content: (
            <UserProfile
              userData={invitedUserData}
              handleAuthLogin={() => authWindowOpen(invitedUserData.sendtTo)}
            />
          ),
        }
      case 'loading':
        return {
          icon: <PaperLoader />,
          title: t('OC_invite_looking_for_user'),
          statusClass: classes.loading,
        }
      case 'user created':
        return {
          icon: <CheckCircleOutlineIcon />,
          title: t('OC_invite_user_created'),
          statusClass: classes.userCreated,
          content: <UserCreated history={history} customerId={invitedUserData?.customerId} />,
        }
      case 'correct url':
        return {
          icon: <PersonAddIcon color="primary" />,
          title: t('OC_invite_landing_page_title'),
          statusClass: classes.success,
          content: (
            <UserPincode
              onSubmit={onSubmit}
              setPincode={setPincode}
              disabled={pincode.length !== 5}
            />
          ),
        }
      default:
        return {
          title: t('OC_invite_error'),
        }
    }
  }

  const {
    icon = <ErrorOutlineIcon color="error" />,
    title,
    content = '',
    statusClass = classes.error,
  } = getContent(status)
  const isDanish = i18n.language === 'da'
  const handleChangeLanguage = () => {
    const useLang = isDanish ? 'en' : 'da'
    i18n.changeLanguage(useLang)
  }

  // eslint-disable-next-line no-nested-ternary
  const progress = status === 'user profile' ? 1 : status === 'user created' ? 2 : 0

  return (
    <CloudContainer>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Stepper className={classes.stepper} activeStep={progress}>
            {[
              t('OC_invite_landing_page_stepper_pincode'),
              t('Login_logon_microsoft'),
              t('OC_invite_landing_page_stepper_user_created'),
            ].map((label, index) => {
              const stepProps = {}
              const labelProps: { optional?: ReactChild } = {}
              if (index === 1) {
                labelProps.optional = (
                  <Typography variant="caption" color="primary">
                    {invitedUserData?.sendtTo}
                  </Typography>
                )
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <PaperContent>
            <Avatar className={`${classes.avatar} ${statusClass}`}>{icon}</Avatar>
            <Typography variant="h3" align="center">
              {title}
            </Typography>
            {content}
          </PaperContent>
          <Typography variant="body1" align="center" className={classes.supportLink} gutterBottom>
            {t('OC_invite_landing_page_contact')}
            <br />
            <Link href="https://support.sky.tdc.dk/hc/da/requests/new" target="_blank">
              TDC Erhverv Cloud Support.
            </Link>
          </Typography>
        </Paper>
        <Link href="https://tdc.dk/cookiepolitik" target="_blank">
          {t('OC_invite_cookie_policy')}
        </Link>
        <Link href="https://tdc.dk/persondatameddelelse" target="_blank">
          {t('OC_invite_person_data_message')}
        </Link>
        <button
          id="btn-change-language" // test suite uses this id to select and click
          type="button"
          onClick={handleChangeLanguage}
          className={classes.langButton}
        >
          <img
            width="16px"
            height="16px"
            src={isDanish ? IMG_FLAG_GB : IMG_FLAG_DK}
            alt={isDanish ? 'Change to English language' : 'Skift til dansk'}
            className={classes.langImage}
          />
        </button>
      </div>
    </CloudContainer>
  )
}

export default InvitePage
