import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, CircularProgress, Checkbox } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import useInputStyles from 'theme/hooks/useInputStyles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  option: {
    width: '100%',
    display: 'block',
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
  },
  startAdornment: {
    marginRight: 10,
  },
  endAdornment: {
    float: 'right',
    marginLeft: 10,
    marginRight: -2,
  },
  checkbox: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(),
  },
  container: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    right: 38,
    top: 19,
  },
}))

/*
  options = [
    {
      label*: shows as label,
      startAdornment: shows before label to the left (e.g. country flag),
      endAdornment: shows aligned right after label (e.g. price),
      group: if set, groups similar entries,
      disabled: disables option,
    }
    ...
  ]
  - Value must match obj
  - getDisabledOption={(option) => option.disabled} or other func on component to disable options
  - groupBy={(option) => option.group} on component to group options
  */

const SkySelect = ({
  options,
  white = true,
  withMargin = true,
  required = false,
  error = false,
  label,
  isMultiselect = false,
  loading,
  helperText,
  loadingText,
  noOptionsText,
  getOptionSelected,
  ...props
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const inputStyles = useInputStyles()

  const root = classNames({
    [inputStyles.root]: true,
    [inputStyles.white]: white,
  })

  return (
    <div className={withMargin ? inputStyles.withMargin : ''}>
      <Autocomplete
        {...props}
        multiple={isMultiselect}
        loading={loading}
        options={options}
        disableCloseOnSelect={isMultiselect}
        getOptionSelected={getOptionSelected}
        blurOnSelect={!isMultiselect}
        autoHighlight
        classes={{
          option: classes.option,
        }}
        ChipProps={{
          size: 'small',
        }}
        loadingText={loadingText || t('Router_sertharcfh')}
        closeText={t('Organization_orders_drawer_close')}
        getOptionLabel={(option) =>
          option?.formattedPhoneNumber ? option.formattedPhoneNumber : option.label
        }
        noOptionsText={noOptionsText || t('Router_ser3tharch')}
        renderOption={(option, { selected }) => (
          <>
            {isMultiselect && (
              <Checkbox
                color="primary"
                size="small"
                className={classes.checkbox}
                checked={selected}
              />
            )}
            {option.startAdornment && (
              <span className={classes.endAdornment}>{option.startAdornment}</span>
            )}
            {option.label}
            {option.endAdornment && (
              <span className={classes.endAdornment}>{option.endAdornment}</span>
            )}
          </>
        )}
        renderInput={(params) => (
          <div className={classes.container}>
            <TextField
              {...params}
              fullWidth
              error={error}
              required={required}
              label={label}
              helperText={helperText}
              variant="filled"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                classes: { root, focused: inputStyles.focused, error: inputStyles.error },
              }}
            />
            {loading && <CircularProgress size={20} className={classes.loading} />}
          </div>
        )}
      />
    </div>
  )
}

export default SkySelect
