import React from 'react'
import classNames from 'classnames'
import { Toolbar } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    height: 66,
    width: '100%',
    top: 0,
    zIndex: theme.zIndex.appBar,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  static: {
    position: 'static',
  },
  fixed: {
    position: 'fixed',
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
    display: 'flex',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  spaceEvenly: {
    justifyContent: 'space-evenly',
  },
}))

interface Props {
  children?: JSX.Element | JSX.Element[]
  position?: 'fixed' | 'static'
  justify?: 'space-between' | 'flex-start' | 'flex-end' | 'space-evenly'
  className?: string
  paddingLeft?: number
  paddingRight?: number
  zIndex?: number
}

const AppBar = ({
  children,
  className,
  position = 'fixed',
  paddingLeft,
  paddingRight,
  justify = 'space-between',
  zIndex = 1200,
}: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.fixed]: position === 'fixed',
      [classes.static]: position === 'static',
    },
    className
  )

  const toolbarClassName = classNames([classes.toolbar], {
    [classes.spaceBetween]: justify === 'space-between',
    [classes.flexStart]: justify === 'flex-start',
    [classes.flexEnd]: justify === 'flex-end',
    [classes.spaceEvenly]: justify === 'space-evenly',
  })

  return (
    <div className={rootClassName} style={{ zIndex }}>
      <Toolbar className={toolbarClassName} style={{ paddingLeft, paddingRight }} disableGutters>
        {children}
      </Toolbar>
    </div>
  )
}

export default AppBar
