/**
 * USAGE
 *
 * Register the wanted clients by chaining .add to the registerClient
 *  `.add((name<string>, options<object>))`
 *    Registers the client, where options has `prefixUrl` and `{ headers: { foo: 'bar', ...} }`
 *
 *  `.export()`
 *    returns the client objects for exporting, on execute it removes client bearers from storage, if not resolved with .add from registerClient.
 *    it resolves by checking a defined prefix + the name given to .add
 *
 * Clients exposes
 *   a instance of `ky.create`, this leverages all the nice functionalities from "ky",
 *   fx `client.get('pancakes')`, where .get it exposed from "ky" (`https://github.com/sindresorhus/ky`)
 *
 *  Besides from that, client also have the following "custom" methods assigned to it
 *  `.addBearer({ storage: 'localStorage/sessionStorage', value: 'foobar' })`
 *    - Adds the bearer to the storage of kind with the client name and a prefix, requests made with the following client
 *      now automatically add the { authentication: Bearer foobar } to the header of its requests
 *
 *  `.withoutBearer()`
 *    - Performs the request with out using header { authentication: Bearer foobar }
 *
 *  `.removeBearer()`
 *    - Completly removes the Bearer from storage and the requests followed hereby
 *
 *  `.getBearer()`
 *    - Returns the added bearer and its name in local/sessionStorage from the client.
 *    - ex: client.getBearer() // > { value: 'value of bearer', 'name in local/sessionStorage'}
 */

import { SWEB_API_URI } from '../../constants/configurable'
import registerClient from './registerClient'

const clients = registerClient
  .add('sweb', { prefixUrl: SWEB_API_URI })
  .add('partyweb', { prefixUrl: SWEB_API_URI })

// .add() -> to add another one
// eslint-disable-next-line import/prefer-default-export
export const { sweb, partyweb } = clients.export()
