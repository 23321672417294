import React from 'react'
import classNames from 'classnames'
import { Link, Route, Switch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Typography, Grid } from '@material-ui/core'
import { PaperHeader, PaperContent } from 'components'
import { useTranslation } from 'react-i18next'
import SubscriptionsTable from '../SubscriptionsTable'
import ExtraServicesTable from '../ExtraServicesTable'
import MobileDetails from '../MobileDetails'
import LandlineDetails from '../LandlineDetails'
import ReferencesTable from '../ReferencesTable'

const RouterLinkTab = (props) => {
  return (
    <span>
      <Tab component={Link} {...props} />
    </span>
  )
}

const useStyles = makeStyles((theme) => ({
    header: {
    marginBottom: theme.spacing(3),
  },
  number: {
    paddingTop: theme.spacing(0.5),
    marginBottom: theme.spacing(),
    fontWeight: 700,
    width: '100%',
    paddingLeft: 18,
    textAlign: 'left',
  },
  subscriptionFees: {
    '&:before': {
      background: theme.palette.background.light,
    },
  },
  mobileFees: {
    '&:before': {
      background: theme.palette.primary.main,
    },
  },
  fixedLineFees: {
    '&:before': {
      background: theme.palette.background.orange,
    },
  },
  extraServiceFees: {
    '&:before': {
      background: theme.palette.background.pink,
    },
  },
  tableContainer: {
    paddingBottom: theme.spacing(6),
    minHeight: '600px',
  },
  noWrap: {
    whiteSpace: 'nowrap',
    position: 'relative',
    paddingLeft: 18,
    width: '100%',
    textAlign: 'left',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 10,
      transform: 'translateY(-50%)',
      height: 5,
      borderRadius: 16,
      top: '50%',
      left: 0,
    },
  },
}))

const TabSwitch = React.memo(({ path, customerId, invoiceId }) => {
  return (
    <Switch>
      <Route
        render={() => <SubscriptionsTable invoiceId={invoiceId} customerId={customerId} />}
        exact
        path={`${path}/abonnementer`}
      />
      <Route
        render={() => <ExtraServicesTable invoiceId={invoiceId} customerId={customerId} />}
        exact
        path={`${path}/ekstra-services`}
      />
      <Route
        render={() => {
          return <MobileDetails invoiceId={invoiceId} customerId={customerId} />
        }}
        exact
        path={`${path}/mobil`}
      />
      <Route
        render={() => <LandlineDetails invoiceId={invoiceId} customerId={customerId} />}
        exact
        path={`${path}/fastnet`}
      />
      <Route
        render={() => <ReferencesTable invoiceId={invoiceId} customerId={customerId} />}
        exact
        path={`${path}/referencer`}
      />
    </Switch>
  )
})

const DetailsTabs = ({
  pathname,
  path,
  customerId,
  invoiceId,
  subscriptionFees,
  mobileFees,
  fixedLineFees,
  extraServiceFees,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <PaperHeader noDivider className={classes.header}>
        <Grid container>
          <Grid item md={3}></Grid>
          <Tabs
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            value={pathname}
            scrollButtons="auto"
          >
            <RouterLinkTab
              wrapped
              label={
                <>
                  <Typography
                    display="block"
                    variant="body2"
                    color="textSecondary"
                    className={classNames([classes.noWrap], {
                      [classes.subscriptionFees]: true,
                    })}
                  >
                    {t('Invoice_subs')}
                  </Typography>
                  <Typography display="block" variant="body1" className={classes.number}>
                    {subscriptionFees}
                  </Typography>
                </>
              }
              to={`/${customerId}/faktura/${invoiceId}/abonnementer`}
              value={`/${customerId}/faktura/${invoiceId}/abonnementer`}
            />
            <RouterLinkTab
              wrapped
              label={
                <>
                  <Typography
                    display="block"
                    variant="body2"
                    color="textSecondary"
                    className={classNames([classes.noWrap], {
                      [classes.mobileFees]: true,
                    })}
                  >
                    {t('Invoice_mobile')}
                  </Typography>
                  <Typography display="block" variant="body1" className={classes.number}>
                    {mobileFees}
                  </Typography>
                </>
              }
              to={`/${customerId}/faktura/${invoiceId}/mobil`}
              value={`/${customerId}/faktura/${invoiceId}/mobil`}
            />
            <RouterLinkTab
              wrapped
              label={
                <>
                  <Typography
                    display="block"
                    variant="body2"
                    color="textSecondary"
                    className={classNames([classes.noWrap], {
                      [classes.fixedLineFees]: true,
                    })}
                  >
                    {t('Invoice_landline')}
                  </Typography>
                  <Typography display="block" variant="body1" className={classes.number}>
                    {fixedLineFees}
                  </Typography>
                </>
              }
              to={`/${customerId}/faktura/${invoiceId}/fastnet`}
              value={`/${customerId}/faktura/${invoiceId}/fastnet`}
            />
            <RouterLinkTab
              wrapped
              label={
                <>
                  <Typography
                    display="block"
                    variant="body2"
                    color="textSecondary"
                    className={classNames([classes.noWrap], {
                      [classes.extraServiceFees]: true,
                    })}
                  >
                    {t('Invoice_extraServiceFees')}
                  </Typography>
                  <Typography display="block" variant="body1" className={classes.number}>
                    {extraServiceFees}
                  </Typography>
                </>
              }
              to={`/${customerId}/faktura/${invoiceId}/ekstra-services`}
              value={`/${customerId}/faktura/${invoiceId}/ekstra-services`}
            />
            <RouterLinkTab
              wrapped
              label={
                <>
                  <Typography
                    display="block"
                    variant="body1"
                    color="textSecondary"
                    className={classes.number}
                  >
                    {t('Invoice_refs')}
                  </Typography>
                </>
              }
              to={`/${customerId}/faktura/${invoiceId}/referencer`}
              value={`/${customerId}/faktura/${invoiceId}/referencer`}
            />
          </Tabs>
        </Grid>
      </PaperHeader>
      <PaperContent noPadding className={classes.tableContainer}>
        <TabSwitch path={path} customerId={customerId} invoiceId={invoiceId} />
      </PaperContent>
    </>
  )
}

export default DetailsTabs
