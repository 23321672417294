import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'

interface LinearProgressBarProps {
  progress: number
  title: string
}

const useStyles = makeStyles((theme) => ({
  progressLabel: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '1rem',
    '& + span': {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: '500',
      float: 'right',
    },
  },
}))

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    marginTop: 10,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress)

const LinearProgressBar = ({ progress, title }: LinearProgressBarProps) => {
  const classes = useStyles()

  if (progress === 0) {
    return null
  }
  return (
    <>
      {progress < 100 && (
        <>
          <span className={classes.progressLabel}>{title}</span>
          <span>{`${progress}%`}</span>
          <BorderLinearProgress variant="determinate" value={progress} />
        </>
      )}
    </>
  )
}

export default LinearProgressBar
