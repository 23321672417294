import React, { useState, useEffect } from 'react'
import {
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
  ListItemIcon,
  Switch,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { InfoOutlined as InfoIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons'
import { format } from 'date-fns'
import CustomDialog from 'components/CustomDialog'
import { useTranslation } from 'react-i18next'
import { SkyDatePicker } from 'components'
import { formatPrice } from '../PresenceActivation'
import PresenceInfoModalContent from './PresenceInfoModalContent'

const useStyles = makeStyles((theme) => ({
  listItem: {
    maxHeight: 62,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    marginTop: 1,
  },
  active: {
    color: theme.palette.success.main,
  },
  inactive: {
    color: theme.palette.error.light,
  },
  expired: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
  },
  activeIcon: {
    color: theme.palette.success.main,
  },
}))

const formatDate = (date) => {
  const convertToDate = new Date(date)
  return format(convertToDate, 'dd-MM-yyyy')
}

const initialDialog = {
  open: false,
  title: null,
  message: null,
  callback: null,
  btnText: null,
}

const PresenceItem = ({ subscription, loading, disablePresence }) => {
  const {
    customer_name: customerName,
    start_date: startDate,
    end_date: endDate,
    product_name: productName,
    price,
    active = false,
  } = subscription || {}

  const { t } = useTranslation()
  const classes = useStyles()
  const [dialog, setDialog] = useState(initialDialog)
  const [date, setDate] = useState(null)

  const presencePrice = formatPrice(price)

  useEffect(() => {
    setDialog((prev) => ({ ...prev, ...(date && { callback: handleDisablePresence }) }))
  }, [date])

  const handleCloseDialog = () => {
    setDate(null)
    setDialog(initialDialog)
  }
  const handleDisablePresence = () => {
    const json = {
      stop_on: format(date, 'yyyy-MM-dd'),
    }
    setDialog(initialDialog)
    disablePresence(json)
  }
  const openDeactivateDialog = () => {
    setDialog({
      open: true,
      title: t('Wizard_10f', { productName }),
      message: t('Wizard_0034565', { productName }),
      btnText: t('Wizard_deactivate_btn'),
    })
  }

  const openInfoDialog = () => {
    setDialog({
      open: true,
      contentPadding: false,
      maxWidth: 'xs',
      closeButtonText: t('Wizard_00tyuj'),
      title: t('Wizard_sertgh'),
      children: PresenceInfoModalContent,
    })
  }

  const formattedStartDate = formatDate(startDate)
  const formattedEndDate = formatDate(endDate)

  return (
    <>
      <ListItem className={classes.listItem} disabled={!subscription.active}>
        <ListItemIcon>
          <CheckCircleIcon className={classes[subscription.active ? 'activeIcon' : 'inactive']} />
        </ListItemIcon>
        <ListItemText
          primary={customerName}
          secondary={
            <>
              {endDate ? (
                <>
                  {t('Organization_customer_activeFrom', { from: formattedStartDate })} -
                  <span className={classes.expired}> {formattedEndDate}</span>
                </>
              ) : (
                t('Organization_customer_activeFrom', { from: formattedStartDate })
              )}
              <span style={{ display: 'block' }}>{presencePrice}</span>
            </>
          }
        />
        <ListItemSecondaryAction>
          {!endDate && (
            <Tooltip title={t('Wizard_10f', { productName })} placement="top">
              <Switch edge="end" checked onChange={openDeactivateDialog} color="primary" />
            </Tooltip>
          )}
          <Tooltip title={t('Wizard_9sth')} placement="top">
            <IconButton edge="end" disabled={loading} onClick={openInfoDialog}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      {dialog.open && (
        <CustomDialog
          maxWidth={dialog.maxWidth}
          contentPadding={dialog.contentPadding}
          closeButtonText={dialog.closeButtonText}
          isOpen={dialog.open}
          onConfirm={dialog.callback}
          onCancel={handleCloseDialog}
          title={dialog.title}
          contentText={dialog.message}
          confirmButtonText={dialog.btnText}
        >
          {!dialog.children && (
            <SkyDatePicker
              label={t('Wizard_disabledateto')}
              value={date}
              disablePast
              disabled={loading}
              onChange={(date) => setDate(date)}
            />
          )}
          {dialog.children && (
            <dialog.children
              customerName={customerName}
              startDate={formattedStartDate}
              endDate={endDate && formattedEndDate}
              productName={productName}
              price={presencePrice}
            />
          )}
        </CustomDialog>
      )}
    </>
  )
}

export default PresenceItem
