import { getSubscription } from 'services/sweb/subscription'
export const FETCH_FAILURE = 'presenceProduct/FETCH_FAILURE'
export const FETCH_BEGIN = 'presenceProduct/FETCH_BEGIN'
export const FETCH_SUCCESS = 'presenceProduct/FETCH_SUCCESS'

export const fetchPresenceProductFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchPresenceProductBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchPresenceProductSuccess = ({ customerId, data }) => {
  return {
    type: FETCH_SUCCESS,
    customerId,
    data,
  }
}

export const fetchPresenceProduct = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchPresenceProductBegin({ customerId }))
    try {
      const response = await getSubscription.presenceProduct({
        customerId,
      })
      dispatch(fetchPresenceProductSuccess({ customerId, data: response.data }))
    } catch (err) {
      dispatch(fetchPresenceProductFailure(err.response))
    }
  }
}
