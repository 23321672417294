import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'intNumberVoxboneStock/FETCH_FAILURE'
export const FETCH_BEGIN = 'intNumberVoxboneStock/FETCH_BEGIN'
export const FETCH_SUCCESS = 'intNumberVoxboneStock/FETCH_SUCCESS'

export const fetchIntNumberVoxboneStockFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchIntNumberVoxboneStockBegin = ({
  customerId,
  e164,
  pagesize,
  pagenumber,
  countryCode,
}) => {
  return {
    type: FETCH_BEGIN,
    customerId,
    e164,
    pagesize,
    pagenumber,
    countryCode,
  }
}

export const fetchIntNumberVoxboneStockSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchIntNumberVoxboneStock = ({
  customerId,
  e164,
  pagesize,
  pagenumber,
  countryCode,
}) => {
  return async (dispatch) => {
    dispatch(
      fetchIntNumberVoxboneStockBegin({ customerId, e164, pagesize, pagenumber, countryCode })
    )
    try {
      const response = await getCustomer.intNumbersVoxboneStock({
        customerId,
        e164,
        pagesize,
        pagenumber,
        countryCode,
      })
      dispatch(fetchIntNumberVoxboneStockSuccess(response.data))
    } catch (err) {
      dispatch(fetchIntNumberVoxboneStockFailure(err.response))
    }
  }
}
