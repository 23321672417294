import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'landlinePrivateNumber/FETCH_FAILURE'
export const FETCH_BEGIN = 'landlinePrivateNumber/FETCH_BEGIN'
export const FETCH_SUCCESS = 'landlinePrivateNumber/FETCH_SUCCESS'

export const fetchLandlinePrivateNumberFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchLandlinePrivateNumberBegin = ({ landlineNumber }) => {
  return {
    type: FETCH_BEGIN,
    landlineNumber,
  }
}

export const fetchLandlinePrivateNumberSuccess = ({ landlineNumber, data }) => {
  return {
    type: FETCH_SUCCESS,
    landlineNumber,
    data,
  }
}

export const fetchLandlinePrivateNumber = ({ customerId, landlineNumber }) => {
  return async (dispatch) => {
    dispatch(fetchLandlinePrivateNumberBegin({ landlineNumber }))
    try {
      const response = await getSubscription.landlinePrivateNumber({
        customerId,
        landlineNumber,
      })
      dispatch(fetchLandlinePrivateNumberSuccess({ landlineNumber, data: response.data }))
    } catch (err) {
      dispatch(fetchLandlinePrivateNumberFailure(err.response))
    }
  }
}
