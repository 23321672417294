import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { SkySelect, SkyTextField, Alert } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'

const BulkformStep = ({
  subscriptionProducts,
  setValue,
  loading,
  abbType: globalAbbType,
  bulkAmount,
  receiptEmail,
  errors,
}) => {
  const { t } = useTranslation()

  const availableSubscriptionTypes = subscriptionProducts.map(
    ({ productName, monthlyPrice, productTypeId }) => {
      return {
        label: productName,
        price: monthlyPrice,
        value: productTypeId,
      }
    }
  )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ marginTop: 24 }} gutterBottom>
          <strong>{t('SharedForm')}</strong>
        </Typography>
        <Typography color="textSecondary">{t('SharedBulkFormDescription')}</Typography>
      </Grid>

      <Grid item xs={6}>
        <SkySelect
          label={t('Drawer_price_sub')}
          required
          name="abbType"
          disableClearable
          value={availableSubscriptionTypes.find((x) => x.value === globalAbbType) || null}
          options={availableSubscriptionTypes}
          withMargin={false}
          disabled={loading}
          onChange={(name, abbType) => setValue(name, abbType.value)}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          renderOption={(option) => (
            <>
              <Typography>{option.label}</Typography>
              <Typography variant="body2" color="textSecondary">
                DKK {option.price}
              </Typography>
            </>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4" style={{ marginTop: 24 }} gutterBottom>
          <strong>{t('BulkNumbers')}</strong>
        </Typography>
        <Typography color="textSecondary">{t('BulkAmountDescription')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <SkyTextField
          onChange={(name, val: number) => setValue(name, val)}
          value={bulkAmount || ''}
          type="number"
          name="bulkAmount"
          error={bulkAmount <= 0 || bulkAmount > 20}
          label={t('OC_create_numbers_bulk_input')}
          helperText="The number should be between 1 and 20"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ marginTop: 24 }} gutterBottom>
          <strong>{t('OC_create_numbers_receipt_email_input')}</strong>
        </Typography>
        <Typography color="textSecondary">{t('OC_create_numbers_receipt_email_desc')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <SkyTextField
          required
          onChange={(name, val: number) => setValue(name, val)}
          value={receiptEmail || ''}
          type="email"
          name="receiptEmail"
          error={!receiptEmail}
          label={t('Receipt email')}
          helperText={!receiptEmail ? 'Email is required' : ''}
        />
      </Grid>
      <Grid item xs={12}>
        {errors.bulkAmount && <Alert type="error" message={t('OC_invite_error')} />}
      </Grid>
    </Grid>
  )
}

export default BulkformStep
