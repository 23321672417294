import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'guardApp/FETCH_FAILURE'
export const FETCH_BEGIN = 'guardApp/FETCH_BEGIN'
export const FETCH_SUCCESS = 'guardApp/FETCH_SUCCESS'

export const fetchGuardAppFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchGuardAppBegin = ({ abbId }) => {
  return {
    type: FETCH_BEGIN,
    abbId,
  }
}

export const fetchGuardAppSuccess = ({ abbId, data }) => {
  return {
    type: FETCH_SUCCESS,
    abbId,
    data,
  }
}

export const fetchGuardApp = ({ customerId, abbId }) => {
  return async (dispatch) => {
    dispatch(fetchGuardAppBegin({ customerId, abbId }))
    try {
      const response = await getSubscription.guardApp({
        customerId,
        abbId,
      })
      dispatch(fetchGuardAppSuccess({ abbId, data: response.data }))
    } catch (err) {
      dispatch(fetchGuardAppFailure(err.response))
    }
  }
}
