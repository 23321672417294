import React from 'react'
import { Paper, PaperHeader, PaperTitle, PaperContent, PaperFooter } from '@skytdc/mui/components'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import { ArrowRightRounded as ArrowRightIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  setMaxHeight: {
    height: '100%',
  },
  text: {
    paddingBottom: theme.spacing(3),
  },
  icon: {
    marginTop: '-14px',
    width: '90px',
    height: '90px',
    color: theme.palette.text.primary,
  },
}))

const CloudAdoptionWidget = ({ changePage }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Paper className={classes.setMaxHeight}>
      <PaperHeader noDivider>
        <PaperTitle title="Cloud Adoption" />
      </PaperHeader>
      <PaperContent>
        <Typography variant="body1" gutterBottom>
          {t('Dashboard_cloud_adoption_description')}
        </Typography>
        <Typography variant="body1">{t('Dashboard_cloud_adoption_description_two')}</Typography>
      </PaperContent>
      <PaperFooter alignRight>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={() => changePage('cloud-adoption')}
        >
          {t('Dashboard_general_read_more')} <ArrowRightIcon />
        </Button>
      </PaperFooter>
    </Paper>
  )
}

export default CloudAdoptionWidget
