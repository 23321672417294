import client from '../client'

export const eInvoice = ({ customerId, json }) => {
  return client.put(`customer/${customerId}/einvoice`, { json }).json()
}

export const single = ({ customerId }) => {
  return client.get(`customer/${customerId}/extended`).json()
}

export const huntgroup = ({ customerId, json }) => {
  return client.put(`cirpack/${customerId}`, { json }).json()
}

export const adGroupFilters = ({ customerId, json }) => {
  return client.post(`teams/${customerId}/filter-groups`, { json }).json()
}
export const changeAddonProductSubscription = ({ customerId, internationalnumber, json }) => {
  return client
    .post(`internationalnumbers/${customerId}/${internationalnumber}/change-subscription`, {
      json,
    })
    .json()
}

export const azureAppSecret = ({ customerId, json }) => {
  return client.put(`teams/${customerId}/update-app-secret`, { json }).json()
}
export const presenceAppSecret = ({ customerId, json }) => {
  return client.put(`teams/${customerId}/update-presence-secret`, { json }).json()
}

export const azureAdminSecret = ({ customerId, json }) => {
  return client.put(`teams/${customerId}/update-admin-secret`, { json }).json()
}

export const dataUsageEmails = ({ customerId, json }) => {
  return client.put(`customer/${customerId}/data`, { json }).json()
}
