import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Chip,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
} from '@material-ui/icons'
import Avatar from 'components/UserWithAvatar/Avatar'

const useStyles = makeStyles((theme) => ({
  andHover: {
    borderRadius: 4,
  },
  userName: {
    fontWeight: 700,
  },
  email: {
    color: theme.palette.primary.main,
  },
  multiline: {
    marginTop: '2px',
  },
  chip: {
    marginRight: theme.spacing(0.4),
  },
  label: {
    fontSize: '10px',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  chipContainer: {
    textAlign: 'right',
  },
}))

const UserListItemWithRole = ({
  name,
  email,
  azureId,
  avatar = null,
  availableRoles,
  currentRoles,
  customerId,
  loading,
  addRole,
  removeRole,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  const generateLabel = (role) => {
    if (role === 'customer_admin') return t('Organization_roles_customer_admin')
    if (role === 'customer_role_admin') return t('Organization_roles_customer_role_admin')
    if (role === 'invoice') return t('Organization_roles_invoice')
    if (role === 'api_user') return t('Organization_roles_api')
    if (role === 'mobile_forward') return t('Organization_roles_mobile_forward')
    if (role === 'mobile_huntgroup_admin') return t('Organization_roles_mobile_callgroups')
    return role
  }

  const generateDescription = (role) => {
    if (role === 'customer_admin') return t('Organization_roles_customer_admin_desc')
    if (role === 'customer_role_admin') return t('Organization_roles_customer_role_admin_desc')
    if (role === 'invoice') return t('Organization_roles_invoice_desc')
    if (role === 'api_user') return t('Organization_roles_api_desc')
    if (role === 'mobile_forward') return t('Organization_roles_mobile_forward_desc')
    if (role === 'mobile_huntgroup_admin') return t('Organization_roles_mobile_callgroups_desc')
    return role
  }

  const currentRolesOnCustomer =
    (currentRoles &&
      currentRoles.length > 0 &&
      currentRoles[0].authorizations &&
      currentRoles[0].authorizations.filter((auth) => auth.customerid === Number(customerId))) ||
    []

  const handleExpanded = () => {
    setExpanded(!expanded)
  }

  const handleRole = (event) => {
    if (event.target.checked) {
      addRole(azureId, event.target.value)
    } else {
      removeRole(azureId, event.target.value)
    }
  }

  return (
    <>
      <ListItem className={classes.andHover} button onClick={handleExpanded}>
        <ListItemAvatar>
          <Avatar name={name} avatar={avatar} showSubButNoTeamsWarning={false} inChange={false} />
        </ListItemAvatar>
        <ListItemText
          classes={{
            primary: classes.userName,
            secondary: classes.email,
          }}
          className={classes.multiline}
          primaryTypographyProps={{ noWrap: true, component: 'p' }}
          secondaryTypographyProps={{ noWrap: true }}
          primary={name}
          secondary={email || t('Organization_roles_noemail')}
        />
        <div className={classes.chipContainer}>
          {currentRolesOnCustomer.map((role) => {
            return (
              <Chip
                label={generateLabel(role.role)}
                className={classes.chip}
                key={role.role}
                size="small"
                classes={{ labelSmall: classes.label }}
              />
            )
          })}
        </div>
        {expanded ? (
          <ArrowUpIcon className={classes.icon} />
        ) : (
          <ArrowDownIcon className={classes.icon} />
        )}
      </ListItem>
      {expanded &&
        availableRoles.map((role) => {
          const isRoleActive = currentRolesOnCustomer.find((currRole) => {
            return role.role === currRole.role
          })
          return (
            <ListItem dense key={role.role}>
              <ListItemIcon>
                <Checkbox
                  checked={Boolean(isRoleActive)}
                  color="primary"
                  value={role.role}
                  onChange={handleRole}
                  disabled={loading}
                />
              </ListItemIcon>
              <ListItemText
                primary={generateLabel(role.role)}
                secondary={generateDescription(role.role)}
              />
            </ListItem>
          )
        })}
    </>
  )
}

export default UserListItemWithRole
