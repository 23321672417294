import client from '../client'

export const partyInviteSignUp = ({ partyId, json }) => {
  return client.post(`party/signUp/${partyId}`, { json }).json()
}
export const partyInviteUpdate = ({ partyId, json }) => {
  return client.put(`party/update/${partyId}`, { json }).json()
}
export const partyInviteCancel = ({ partyId }) => {
  return client.delete(`party/cancel/${partyId}`).json()
}
export const getParties = () => {
  return client.get('party/getParties').json()
}

export const getSignups = ({ partyId }) => {
  return client.get(`party/admin/${partyId}/signups`).json()
}

export const receivedWristband = ({ partyId, employeeId, json }) => {
  return client.put(`party/admin/${partyId}/${employeeId}/set-wristband`, { json }).json()
}
