import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { IMG_BG_ICON_ILLUSTRATION, IMG_TDC_LOGO_BUSSINESS_WHITE } from 'constants/cdn'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${IMG_BG_ICON_ILLUSTRATION})`,
    backgroundPosition: 'left top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    paddingBottom: '5rem',
  },
  setMaxHeight: {
    backgroundImage: `url(${IMG_TDC_LOGO_BUSSINESS_WHITE})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '1.5rem 1.5rem',
    height: '100vh',
  },
}))

const CloudContainer = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid
        className={classes.setMaxHeight}
        container
        justify="center"
        direction="row"
        alignItems="center"
      >
        {children}
      </Grid>
    </div>
  )
}

export default CloudContainer
