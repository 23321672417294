import { getValidation } from 'services/sweb/validation'

export const FETCH_FAILURE = 'generateExtensions/FETCH_FAILURE'
export const FETCH_BEGIN = 'generateExtensions/FETCH_BEGIN'
export const FETCH_SUCCESS = 'generateExtensions/FETCH_SUCCESS'

export const fetchGenerateExtensionsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchGenerateExtensionsBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchGenerateExtensionsSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchGenerateExtensions = ({ customerId, phoneNumber }) => {
  return async (dispatch) => {
    dispatch(fetchGenerateExtensionsBegin())
    try {
      const response = await getValidation.generateExtensions({
        customerId,
        phoneNumber,
      })
      dispatch(fetchGenerateExtensionsSuccess(response.data))
    } catch (err) {
      dispatch(fetchGenerateExtensionsFailure(err.response))
    }
  }
}
