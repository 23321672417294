import React, { useEffect } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSelector, useDispatch, batch } from 'react-redux'
import { Alert } from '@material-ui/lab'
import { fetchPortfolio } from 'state/portfolio/actions'
import { PaperLoader } from 'components'
import { Slide, Link } from '@material-ui/core'
import { useTranslation, Trans } from 'react-i18next'
import FormContainer from './FormContainer'

const CreateNoneAdUser = ({ customerId, handleClose }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const portfolio = useSelector((state) => state.portfolio.data)
  const nonAdBusinessMobile =
    portfolio?.base_product?.non_ad_business_mobile?.product_details.length === 1 &&
    portfolio?.base_product?.non_ad_business_mobile?.warnings.length === 0

  const supportLink = `https://support.sky.tdc.dk/hc/${
    i18n.language === 'da' ? 'da' : 'en-us'
  }/requests/new`

  useEffect(() => {
    changeDocumentTitle('Create a new user with a mobile subscription', ' - ', ':applicaitonName')
    batch(() => {
      dispatch(fetchPortfolio({ customerId }))
    })
  }, [])

  if (portfolio) {
    return (
      <Slide direction="left" in>
        <div>
          <Alert severity="warning">{t('Organization_orders_drawer_disclaimer')}</Alert>
          <br />
          {!nonAdBusinessMobile && (
            <Alert severity="error">
              <Trans i18nKey="ContactUs">
                <Link href={supportLink} target="_blank">
                  {supportLink}
                </Link>
              </Trans>
            </Alert>
          )}
          {nonAdBusinessMobile && (
            <FormContainer
              customerId={customerId}
              handleClose={handleClose}
              mobilePackages={portfolio.mobile_packages}
              baseProducts={portfolio.base_product?.non_ad_business_mobile}
              supportLink={supportLink}
            />
          )}
        </div>
      </Slide>
    )
  }
  return <PaperLoader />
}

export default CreateNoneAdUser
