import React, { useEffect, useState } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { makeStyles } from '@material-ui/core/styles'
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Slide,
  Typography,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Button,
} from '@material-ui/core'
import { DrawerSection } from 'components'
import { useTranslation } from 'react-i18next'
import CancelAllSubscriptionsContainer from './components/CancelAllSubscriptionsContainer'
import SidegradeContainer from './components/SidegradeContainer'
import CancelConfirmation from './components/CancelConfirmation'

const useStyles = makeStyles((theme) => ({
  stepper: {
    paddingLeft: theme.spacing(0.5),
  },
  stepLabel: {
    marginTop: 0,
    marginBottom: 0,
  },
  checkbox: {
    width: '100%',
  },
  message: {
    paddingBottom: theme.spacing(),
  },
  helperText: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}))

const CancelSubscription = ({ user, subscription, customerId, changeRoute, title }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const hasAzureId = Boolean(user?.azureId)

  const [step, setStep] = useState(hasAzureId ? 0 : 1)

  const hasMobile = Boolean(subscription.mobile_number)
  const hasTeams = Boolean(subscription.landline_number)
  const mustCancelMobile = Boolean(hasMobile && !hasTeams)
  const mustCancelTeams = Boolean(hasTeams && !hasMobile)

  const [cancelTeams, setCancelTeams] = useState(mustCancelTeams)
  const [cancelMobile, setCancelMobile] = useState(mustCancelMobile)
  const [createMissing, setCreateMissing] = useState(false)

  const stepIsValid = cancelTeams || cancelMobile || !hasAzureId
  const showCancelAllSubsContainer = Boolean(
    !hasAzureId ||
      (mustCancelMobile && !createMissing) ||
      (mustCancelTeams && !createMissing) ||
      (cancelMobile && cancelTeams)
  )

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
  }, [])

  const handleNextStep = () => setStep(step + 1)
  const handlePreviousStep = () => setStep(step - 1)
  const handleCancelTeams = (event, value) => setCancelTeams(value)
  const handleCancelMobile = (event, value) => setCancelMobile(value)
  const handleCreateMissing = (event, value) => setCreateMissing(value)

  return (
    <Slide in direction="left">
      <Stepper activeStep={step} orientation="vertical" className={classes.stepper}>
        <Step>
          <StepLabel>
            <DrawerSection title={t('Drawer_cancel_title')} className={classes.stepLabel} />
          </StepLabel>
          <StepContent>
            <DrawerSection>
              {hasMobile && hasTeams ? (
                <Typography className={classes.message}>{t('Drawer_cancel_choose')}</Typography>
              ) : (
                <Typography className={classes.message}>
                  {t('Drawer_cancel_choose_desc')}
                </Typography>
              )}
              {hasTeams && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cancelTeams}
                      onChange={handleCancelTeams}
                      disabled={mustCancelTeams}
                      color="primary"
                    />
                  }
                  className={classes.checkbox}
                  label={t('Drawer_cancel_teams')}
                />
              )}
              {hasMobile && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cancelMobile}
                      onChange={handleCancelMobile}
                      disabled={mustCancelMobile}
                      color="primary"
                    />
                  }
                  className={classes.checkbox}
                  label={t('Drawer_cancel_mobile')}
                />
              )}
              {Boolean(mustCancelTeams || mustCancelMobile) && (
                <FormHelperText className={classes.helperText}>
                  {mustCancelTeams
                    ? t('Drawer_cancel_must_cancel_teams')
                    : t('Drawer_cancel_must_cancel_mobile')}
                </FormHelperText>
              )}
              {!(hasMobile && hasTeams) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createMissing}
                      onChange={handleCreateMissing}
                      color="primary"
                    />
                  }
                  className={classes.checkbox}
                  label={
                    mustCancelTeams
                      ? t('Drawer_cancel_create_mobile')
                      : t('Drawer_cancel_create_teams')
                  }
                />
              )}
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                disabled={!stepIsValid}
                onClick={handleNextStep}
                className={classes.button}
              >
                {t('Drawer_cancel_next')}
              </Button>
            </DrawerSection>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>
            <DrawerSection title={t('Drawer_cancel_fill_out_form')} className={classes.stepLabel} />
          </StepLabel>
          <StepContent>
            <DrawerSection>
              {showCancelAllSubsContainer && (
                <CancelAllSubscriptionsContainer
                  user={user}
                  subscription={subscription}
                  customerId={customerId}
                  goBack={handlePreviousStep}
                  changeRoute={changeRoute}
                />
              )}
              {!showCancelAllSubsContainer && createMissing && (
                <SidegradeContainer
                  user={user}
                  subscription={subscription}
                  customerId={customerId}
                  goBack={handlePreviousStep}
                  handleClose={changeRoute}
                  hasTeams={hasTeams} // whether to show Teams or mobile sidegrade form
                />
              )}
              {!showCancelAllSubsContainer && !createMissing && (
                <CancelConfirmation
                  abbId={subscription.abb_id}
                  customerId={customerId}
                  goBack={handlePreviousStep}
                  handleClose={changeRoute}
                  cancelTeams={cancelTeams}
                />
              )}
            </DrawerSection>
          </StepContent>
        </Step>
      </Stepper>
    </Slide>
  )
}

export default CancelSubscription
