import React, { useState } from 'react'
import { updateSubscription } from 'services/sweb/subscription'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import { Slide, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DrawerButtons from '../../../components/DrawerButtons'
import MobileForm from '../../../components/MobileForm'
import LandlineForm from '../../../components/LandlineForm'
import DisplayPrice from '../../../components/DisplayPrice'
import { formatSubscriptionOrder } from '../../../helpers/subscription'

const useStyles = makeStyles((theme) => ({
  message: {
    paddingBottom: theme.spacing(3),
  },
}))

const FormContainer = ({
  user,
  abbId,
  handleClose,
  customerId,
  mobilePackages,
  goBack,
  baseProducts,
  hasTeams,
  currentBaseSubscription,
  currentMobilePackage,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const me = useSelector((state) => state.me)
  const { enqueueSnackbar } = useSnackbar()

  const initialLandlineForm = {
    landlineNumber: null,
    extension: null,
  }

  const initialMobileForm = {
    mobileNumber: null,
    mobilePackage: null,
    simcard: null,
    importMobileNumber: user.teamsMobileNumber || '',
    importSigner: user.email || '',
    datasim: null,
    alternativeSimcardAddress: '',
  }

  const [landlineForm, setLandlineForm] = useState({ isValid: false, ...initialLandlineForm })
  const [mobileForm, setMobileForm] = useState({ isValid: false, ...initialMobileForm })
  const [loading, setLoading] = useState(false)

  const handleSetLandlineForm = (form) => {
    setLandlineForm({ isValid: false, ...initialLandlineForm, ...form })
  }

  const handleSetMobileForm = (form) => {
    setMobileForm({ isValid: false, ...initialMobileForm, ...form })
  }

  const isInvalidForms = () => {
    if (!hasTeams && !landlineForm.isValid) return true
    if (hasTeams && !mobileForm.isValid) return true
    return false
  }

  const submitIsDisabled = isInvalidForms()

  const submitSubscription = () => {
    setLoading(true)
    const { email: receiptEmail } = me.data.user
    const json = formatSubscriptionOrder({
      withLandline: !hasTeams,
      withMobile: hasTeams,
      withLandlineCancelAction: hasTeams && 'keepnumber',
      withMobileCancelAction: !hasTeams && 'keepnumber',
      receiptEmail,
      mobile: mobileForm,
      landline: landlineForm,
    })
    updateSubscription
      .updateSingle({ customerId, abbId, json })
      .then(() => {
        setLoading(false)
        enqueueSnackbar(t('Drawer_cancel_update_success'), {
          variant: 'success',
        })
        handleClose()
      })
      .catch(() => {
        setLoading(false)
        enqueueSnackbar(t('Downgrade_error_404'), {
          variant: 'warning',
        })
      })
  }

  // to cover the need for mixing "Teams Cloud Voice" and "Teams Cloud Voice inkl. flatrate", we allow max two configured landline_only products. If Danish number, Teams Cloud Voice inkl. flatrate price is showed
  const internationalLandlinePrice = baseProducts.landline_only.product_details.find(
    (x) => !x.prefers_non_international
  )
  const nonInternationalLandlinePrice = baseProducts.landline_only.product_details.find(
    (x) => x.prefers_non_international
  )
  const intNumberSelectedAndPriceExists =
    landlineForm.landlineNumber &&
    landlineForm.landlineNumber.value.country &&
    internationalLandlinePrice &&
    internationalLandlinePrice.price

  const landlineOnlyBasePrice =
    intNumberSelectedAndPriceExists ||
    (nonInternationalLandlinePrice && nonInternationalLandlinePrice.price) ||
    (internationalLandlinePrice && internationalLandlinePrice.price) ||
    0

  const baseSubscriptionPrice = hasTeams
    ? baseProducts.mobile_only.product_details[0].price
    : landlineOnlyBasePrice

  const upgradeForm = hasTeams ? (
    <MobileForm
      initialForm={initialMobileForm}
      mobilePackages={mobilePackages.available_packages}
      onChange={handleSetMobileForm}
      processingOrder={loading}
      customerId={customerId}
      disableImport
    />
  ) : (
    <LandlineForm
      initialForm={initialLandlineForm}
      oneNumberType="classic"
      onChange={handleSetLandlineForm}
      processingOrder={loading}
      customerId={customerId}
    />
  )

  return (
    <Slide direction="left" in>
      <div>
        <Typography className={classes.message}>
          {hasTeams ? t('Drawer_cancel_update_teams') : t('Drawer_cancel_update_mobile')}
        </Typography>
        {upgradeForm}
        <DisplayPrice
          totalPricePrefix={t('Drawer_cancel_new_price_per_month')}
          mobile={{
            enabled: hasTeams,
            price: baseSubscriptionPrice,
            name: 'Drawer_price_mobile_sub',
            oldProduct: {
              label: t('Drawer_cancel_teams_title'),
              price: currentBaseSubscription.price,
            },
          }}
          landline={{
            enabled: !hasTeams,
            price: baseSubscriptionPrice,
            name: 'Drawer_price_teams',
            oldProduct: {
              label: t('Drawer_cancel_mobile_title'),
              price: currentBaseSubscription.price,
            },
          }}
          mobilePackage={
            hasTeams
              ? mobileForm.mobilePackage && {
                  name: mobileForm.mobilePackage.label,
                  price: mobileForm.mobilePackage.value.monthly_price,
                }
              : currentMobilePackage && {
                  name: t('Drawer_cancel_no_mobile_package'),
                  price: 0,
                  oldProduct: {
                    label: currentMobilePackage.abb_name,
                    price: currentMobilePackage.price,
                  },
                }
          }
        />
        <DrawerButtons
          loading={loading}
          backLink={goBack}
          submitText={t('Drawer_cancel_and_create')}
          onSubmit={submitSubscription}
          disabled={submitIsDisabled}
        />
      </div>
    </Slide>
  )
}

export default FormContainer
