import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  inline: {
    display: 'inline-flex',
    paddingLeft: theme.spacing(3),
    verticalAlign: 'middle',
  },
  ctaContainer: {
    display: 'flex',
  },
}))

const PageTitle = ({ title, className, children, toggleComponent }) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.container]: true,
    },
    className
  )

  return (
    <Grid container justify="space-between" alignItems="center" className={rootClassName}>
      <Typography variant="h1" display="inline">
        <strong>{title}</strong>
        <div className={classes.inline}>{toggleComponent}</div>
      </Typography>
      {children && <div className={classes.ctaContainer}>{children}</div>}
    </Grid>
  )
}

export default PageTitle
