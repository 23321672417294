import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'landlineDisplayNumber/FETCH_FAILURE'
export const FETCH_BEGIN = 'landlineDisplayNumber/FETCH_BEGIN'
export const FETCH_SUCCESS = 'landlineDisplayNumber/FETCH_SUCCESS'

export const fetchLandlineDisplayNumberFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchLandlineDisplayNumberBegin = ({ landlineNumber }) => {
  return {
    type: FETCH_BEGIN,
    landlineNumber,
  }
}

export const fetchLandlineDisplayNumberSuccess = ({ landlineNumber, data }) => {
  return {
    type: FETCH_SUCCESS,
    landlineNumber,
    data,
  }
}

export const fetchLandlineDisplayNumber = ({ customerId, landlineNumber }) => {
  return async (dispatch) => {
    dispatch(fetchLandlineDisplayNumberBegin({ landlineNumber }))
    try {
      const response = await getSubscription.displayNumber({
        customerId,
        number: landlineNumber,
      })
      dispatch(fetchLandlineDisplayNumberSuccess({ landlineNumber, data: response.data }))
    } catch (err) {
      dispatch(fetchLandlineDisplayNumberFailure(err.response))
    }
  }
}
