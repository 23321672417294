import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TableCellListItem } from 'components'
import SubscriptionMoreMenu from 'views/Drawer/components/SubscriptionMoreMenu'
import StatusCell from './components/StatusCell'

const useStyles = makeStyles((theme) => ({
  disableState: {
    backgroundColor: theme.palette.warning.light,
  },
}))

const NumberTableRow = ({
  country,
  numberWithCountryCode,
  hasInconsistentCustomerIds,
  comments,
  product,
  changeRoute,
  subscription,
}) => {
  const classes = useStyles()

  return (
    <TableRow {...(subscription.endDate && { classes: { root: classes.disableState } })}>
      <TableCell padding="none">
        <StatusCell
          countryName={country.name}
          countryCode={country.code}
          hasInconsistentCustomerIds={hasInconsistentCustomerIds}
          numberWithCountryCode={numberWithCountryCode}
        />
      </TableCell>
      <TableCell padding="none">
        <TableCellListItem primary={product} />
      </TableCell>
      <TableCell padding="none">
        <TableCellListItem secondary={<i>{comments}</i>} />
      </TableCell>
      <TableCell padding="none">
        {!subscription?.endDate && (
          <SubscriptionMoreMenu changeRoute={changeRoute} subscriptionId={subscription.id} />
        )}
      </TableCell>
    </TableRow>
  )
}

export default NumberTableRow
