import React, { useState, useEffect, memo } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grow,
  Collapse,
  Box,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(5),
  },
  primary: {
    fontSize: 20,
  },
  avatarContainer: {
    minWidth: 52,
  },
  avatar: {
    width: 34,
    height: 34,
    color: theme.palette.common.white,
  },
  notFinal: {
    backgroundColor: theme.palette.primary.main,
  },
  finalStep: {
    backgroundColor: theme.palette.success.main,
  },
  stepContent: {
    marginLeft: theme.spacing(6.5),
  },
}))

const StepContainer = ({ step, requiredStep, stepLabel, finalStep, children }) => {
  const classes = useStyles()
  const show = step >= requiredStep

  return (
    <Collapse in={show}>
      <Box className={classes.root}>
        <List>
          <ListItem disableGutters>
            <ListItemAvatar className={classes.avatarContainer}>
              <Grow in={show}>
                <Avatar
                  className={classNames([classes.avatar], {
                    [classes.notFinal]: !finalStep,
                    [classes.finalStep]: finalStep,
                  })}
                >
                  {requiredStep + 1}
                </Avatar>
              </Grow>
            </ListItemAvatar>
            <ListItemText
              primary={stepLabel}
              classes={{
                primary: classes.primary,
              }}
            />
          </ListItem>
        </List>
        <Box className={classes.stepContent}>{children}</Box>
      </Box>
    </Collapse>
  )
}

export default memo(StepContainer)
