import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { SkyTextField } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton, Collapse } from '@material-ui/core'
import { EditOutlined as EditIcon, Close as CloseIcon } from '@material-ui/icons'
import useDebounce from 'utils/hooks/useDebounce'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.6),
  },
  typography: {
    fontSize: 12,
    marginTop: -12,
    marginLeft: 14,
    display: 'block',
    color: theme.palette.text.secondary,
  },
  editingClosed: {
    marginBottom: 14,
  },
  spinner: {
    marginLeft: theme.spacing(),
    verticalAlign: 'text-top',
  },
  icon: {
    width: '16px',
    height: '16px',
  },
  iconButton: {
    marginTop: '-3px',
  },
  inputContainer: {
    marginTop: 14,
  },
}))

const AlternativeSimcardAddress = ({ customerAddress, address, onChange }) => {
  const [showEditing, setShowEditing] = useState(false)
  const { t } = useTranslation()
  const [fieldAddress, setFieldAddress] = useState(address)
  const classes = useStyles()
  const concAddress =
    customerAddress.recipient &&
    customerAddress.streetAddress &&
    customerAddress.city &&
    customerAddress.zip
      ? `${customerAddress.recipient}, ${customerAddress.streetAddress}, ${customerAddress.city}, ${customerAddress.zip}`
      : ''

  const [debouncedFieldAddress] = useDebounce(fieldAddress, 700)

  useEffect(() => {
    onChange(fieldAddress)
  }, [debouncedFieldAddress])

  const handleSetFieldAddress = (event) => {
    const val = event.target.value
    setFieldAddress(val)
  }

  return (
    <>
      <Typography
        className={classNames([classes.typography], {
          [classes.editingClosed]: !showEditing,
        })}
        component="span"
        color="inherit"
      >
        {concAddress &&
          !fieldAddress &&
          t('Drawer_sim_order_alt_address', {
            address: `${customerAddress.recipient}, ${customerAddress.streetAddress}, ${customerAddress.city}, ${customerAddress.zip}`,
          })}
        {!concAddress &&
          !fieldAddress &&
          t('Drawer_sim_order_alt_address', {
            address: 'jeres firmaadresse.',
          })}
        {fieldAddress &&
          fieldAddress.length > 0 &&
          t('Drawer_sim_order_alt_address', { address: fieldAddress })}

        <IconButton
          size="small"
          className={classes.iconButton}
          onClick={() => setShowEditing(!showEditing)}
        >
          {!showEditing && <EditIcon className={classes.icon} fontSize="inherit" />}
          {showEditing && <CloseIcon className={classes.icon} fontSize="inherit" />}
        </IconButton>
      </Typography>
      <Collapse in={showEditing}>
        <div className={showEditing ? classes.inputContainer : ''}>
          <SkyTextField
            autoFocus
            onBlur={() => setShowEditing(false)}
            defaultValue={address}
            label={t('Drawer_sim_order_alt_title')}
            onChange={handleSetFieldAddress}
            helperText={t('Drawer_sim_order_alt_desc')}
          />
        </div>
      </Collapse>
    </>
  )
}

export default AlternativeSimcardAddress
