import React, { useEffect } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { fetchPortfolio } from 'state/portfolio/actions'
import { fetchLicenseDetails } from 'state/licenseDetails/actions'
import { PaperLoader } from 'components'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DrawerButtons from '../../../components/DrawerButtons'
import FormContainer from '../FormContainer'

const SidegradeContainer = ({ user, subscription, customerId, handleClose, goBack, hasTeams }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const stored = useSelector((state) => ({
    portfolio: state.portfolio,
    licenseDetails: state.licenseDetails,
  }))

  const [portfolio, licenseDetails] = [stored.portfolio.data, stored.licenseDetails.data]

  useEffect(() => {
    batch(() => {
      dispatch(fetchPortfolio({ customerId }))
      dispatch(fetchLicenseDetails({ customerId, azureId: user.azureId }))
    })
  }, [])

  const hasData =
    stored.licenseDetails.azureId === user.azureId &&
    portfolio &&
    licenseDetails &&
    !stored.portfolio.loading &&
    !stored.licenseDetails.loading
  const userCanVoiceEnable = licenseDetails && licenseDetails.allowVoiceEnable
  const portfolioSupportsMobile = Boolean(
    portfolio && portfolio.base_product.mobile_only.product_details.length > 0
  )
  const portfolioSupportsLandline = Boolean(
    portfolio && portfolio.base_product.landline_only.product_details.length > 0
  )
  const portfolioSupportsUpgrade = Boolean(
    (hasTeams && portfolioSupportsMobile) || (!hasTeams && portfolioSupportsLandline)
  )

  if (hasData && portfolioSupportsUpgrade) {
    return (
      <FormContainer
        user={user}
        customerId={customerId}
        abbId={subscription.abb_id}
        handleClose={handleClose}
        goBack={goBack}
        hasTeams={hasTeams}
        mobilePackages={portfolio.mobile_packages}
        baseProducts={portfolio.base_product}
        currentBaseSubscription={subscription.invoice?.base_subscription}
        currentMobilePackage={subscription.mobile_package && subscription.mobile_package?.current}
      />
    )
  }

  if (hasData && !portfolioSupportsUpgrade) {
    return (
      <>
        <Typography>
          {t('Drawer_cancel_misconfig', {
            product: hasTeams ? 'et mobilabonnement' : 'Teams-telefoni',
          })}
        </Typography>
        <DrawerButtons backLink={goBack} withSubmitButton={false} />
      </>
    )
  }

  if (hasData && portfolioSupportsUpgrade && !hasTeams && !userCanVoiceEnable) {
    return (
      <>
        <Typography>
          {t('Drawer_cancel_missing_license', {
            userName: (user.name.trim().length > 1 && user.name) || 'Brugeren',
          })}
        </Typography>
        <DrawerButtons backLink={goBack} withSubmitButton={false} />
      </>
    )
  }

  if (hasData && portfolioSupportsUpgrade && !user.azureId) {
    return (
      <>
        <Typography>
          {t('Drawer_cancel_no_ad_connection', {
            userName: (user.name.trim().length > 1 && user.name) || 'Brugeren',
          })}
        </Typography>
        <DrawerButtons backLink={goBack} withSubmitButton={false} />
      </>
    )
  }

  return <PaperLoader />
}

export default SidegradeContainer
