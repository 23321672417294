import React, { memo } from 'react'
import { SmartphoneOutlined as MobileIcon, ShowChart as ShowChartIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'
import { phoneNumberFormat, convertKbToGb } from 'utils/helpers'
import MobilePackage from './MobilePackage'
import Simcards from './Simcards'

const Mobile = ({
  mobileNumber,
  mobilePackage,
  abbId,
  address,
  userEmail,
  userName,
  dataUsages,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <DrawerListItem
        icon={<MobileIcon />}
        primaryText={t('Organization_orders_drawer_mobile')}
        secondaryText={phoneNumberFormat(mobileNumber)}
      />
      {dataUsages?.data && (
        <DrawerListItem
          icon={<ShowChartIcon />}
          primaryText={t('Organization_orders_drawer_mobile_data_usages')}
          secondaryText={convertKbToGb(dataUsages?.data?.dataUsed, 2)}
          isLoading={dataUsages?.loading}
        />
      )}
      <MobilePackage mobilePackage={mobilePackage} />
      <Simcards abbId={abbId} address={address} userEmail={userEmail} userName={userName} />
    </>
  )
}

export default memo(Mobile)
