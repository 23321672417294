import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as authenticate from 'services/sweb/authentication'
import { authViaWindowOpen } from 'services/msauth'
import { fetchMe } from 'state/me/actions'
import { Redirect } from 'react-router-dom'
import classNames from 'classnames'
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
} from '@material-ui/core'
// import { Alert } from '@material-ui/lab'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Paper, PaperContent, PaperLoader } from 'components'
import { IMG_BG_ICON_ILLUSTRATION, IMG_MICROSOFT_LOGO, IMG_TDC_LOGO_BUSSINESS } from 'constants/cdn'
import {
  MS_AUTH_REDIRECTED_OUTSIDE_TEAMS_CALLBACK_URI,
  MS_AUTH_REDIRECTED_INSIDE_TEAMS_CALLBACK_URI,
} from 'constants/app'
import { teams } from 'services/appOpts'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from 'views/Layout/components/LanguageSwitcher'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
  },
  cloudsBg: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundImage: `url(${IMG_BG_ICON_ILLUSTRATION})`,
    backgroundPosition: '0 0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    animation: `$fadeCloudsBg 0.6s`,
  },
  '@keyframes fadeCloudsBg': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  page: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  setMaxHeight: {
    height: '100%',
  },
  grid: {
    width: '100%',
  },
  microsoftButton: {
    marginTop: theme.spacing(1),
    border: '1px solid #2f2f2f',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  microsoftButtonText: {
    color: '#2f2f2f',
    fontSize: '16px',
  },
  zendeskButton: {
    marginTop: theme.spacing(2),
    border: '1px solid #03363d',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  zendeskButtonText: {
    color: '#03363d',
    fontSize: 16,
  },
  mobileMargin: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  desktopMargin: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  logo: {
    marginLeft: -6,
    marginBottom: 16,
  },
  redirectMessage: {
    padding: 0,
    marginTop: '2rem',
  },
}))

const obtainMsAuthRedirectUri = () => {
  if (teams.isInTeams) return MS_AUTH_REDIRECTED_INSIDE_TEAMS_CALLBACK_URI
  return MS_AUTH_REDIRECTED_OUTSIDE_TEAMS_CALLBACK_URI
}

const exchangeMsOAuthCodeToSwebToken = ({ code }) => {
  const redirectUri = obtainMsAuthRedirectUri()
  return authenticate
    .msAuthenticate({ code, redirectUri })
    .then(({ data }) => authenticate.addBearer(data.access_token))
}

const resolveErrOauthResponseTxt = (err) => {
  return new Promise((resolve) => {
    const { response } = err
    if (response && response.status === 401) {
      return response.json().then((j) => {
        // retrieve payload json from the response
        const jText = j.error.text || ''
        resolve(jText)
      })
    }
    // eslint-disable-next-line no-console
    console.error('Unexpected oauth error occured', err) // niche console for desperate debugging
    if (!response) {
      // error was not caused from a http request, thus must be caught from microsoft authentication
      return resolve('Login_microsoft_error')
    }
    return resolve('Login_unknown_error')
  })
}

const Login = ({ location }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const { me } = useSelector((state) => state)
  const locationState = location.state || {}
  const [errOauthTxt, setErrOauthTxt] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const redirectToReferred = locationState && locationState.from && locationState.from !== '/'

  const handleExchangeMsOAuthCodeToSwebToken = (code) => {
    setIsLoading(true)
    exchangeMsOAuthCodeToSwebToken({ code })
      .then(() => dispatch(fetchMe()))
      .catch((oauthCodeErrResp) => {
        setIsLoading(false)
        resolveErrOauthResponseTxt(oauthCodeErrResp).then((errTxt) => {
          setErrOauthTxt(t(errTxt))
        })
      })
  }

  useEffect(() => {
    changeDocumentTitle(t('Login_logon'), ' ', ':applicationName')
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')

    if (code && me.isAuthenticated === false) {
      handleExchangeMsOAuthCodeToSwebToken(code)
    }
  }, [])

  const popAuthWindow = () => {
    authViaWindowOpen().then(({ code }) => handleExchangeMsOAuthCodeToSwebToken(code))
  }

  if (me.isAuthenticated) {
    const [activeSubscription] = me.data.activeSubscriptions
    const [firstAuth, ...restAuth] = me.data.authorizations
    const hasMultipleCustomers = me.data.user.role === 'admin' || restAuth.length > 0
    const dashboardOrEndUser =
      firstAuth && firstAuth.customer_id
        ? `/${firstAuth.customer_id}/dashboard`
        : (activeSubscription &&
            activeSubscription.customerId &&
            `/${activeSubscription.customerId}/min-side`) ||
          '/'
    const defaultPathname = hasMultipleCustomers ? '/' : dashboardOrEndUser

    const redirectTo = {
      pathname: redirectToReferred
        ? (dashboardOrEndUser.includes('/min-side') && defaultPathname) || locationState.from
        : defaultPathname,
    }
    return <Redirect to={redirectTo} />
  }

  const err = me.err || errOauthTxt
  const loading = me.loading || isLoading

  return (
    <div className={classes.root}>
      <div className={classes.cloudsBg} />
      <div className={classes.page}>
        <Grid
          className={classes.setMaxHeight}
          container
          justify="center"
          direction="row"
          alignItems="center"
        >
          <Grid item lg={3} sm={10} md={5} className={classes.grid}>
            <Paper>
              <PaperContent
                className={classNames([classes.contentBox], {
                  [classes.mobileMargin]: isMobile,
                  [classes.desktopMargin]: !isMobile,
                })}
              >
                {loading ? <PaperLoader /> : null}
                {!loading && (
                  <>
                    <Typography
                      gutterBottom
                      style={{ display: 'flex', justifyContent: 'space-between ' }}
                    >
                      <img
                        width="110px"
                        height="35px"
                        src={IMG_TDC_LOGO_BUSSINESS}
                        alt="TDC Erhverv logo"
                        className={classes.logo}
                      />
                      <LanguageSwitcher />
                    </Typography>
                    <Typography gutterBottom variant="h3">
                      {t('Login_logon_selfservice')}
                    </Typography>
                    {err ? (
                      <Typography variant="body1" color="error">
                        {errOauthTxt}
                      </Typography>
                    ) : (
                      <Typography variant="body1">{t('Login_logon_selfservice_desc')}</Typography>
                    )}
                    <List>
                      <ListItem button className={classes.microsoftButton} onClick={popAuthWindow}>
                        <ListItemIcon>
                          <img
                            src={IMG_MICROSOFT_LOGO}
                            width="20px"
                            height="20px"
                            alt="Log in with Microsoft"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={t('Login_logon_microsoft')}
                          classes={{
                            primary: classes.microsoftButtonText,
                          }}
                        />
                      </ListItem>
                      <Link
                        variant="caption"
                        href="https://support.sky.tdc.dk/hc/da/articles/360006467539-S%C3%A5dan-logger-du-ind-i-TDC-Erhverv-Teams-og-kommer-i-gang"
                        target="_blank"
                      >
                        {t('Login_logon_microsoft_desc')}
                      </Link>
                    </List>
                  </>
                )}
              </PaperContent>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Login
