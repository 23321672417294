import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Alert, AlertTitle, AlertProps } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  gutters: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  verticalPadding: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  withMargin: {
    marginBottom: theme.spacing(2),
  },
}))

interface Props {
  className?: string
  type: AlertProps['severity']
  disablePadding?: boolean
  disableGutters?: boolean
  withMargin?: boolean
  title?: string
  message?: string
  children?: JSX.Element | JSX.Element[]
}

const AlertWrapper = ({
  className,
  type = 'info',
  disablePadding = true,
  disableGutters = true,
  withMargin = false,
  title,
  message,
  children,
}: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.gutters]: !disableGutters,
      [classes.verticalPadding]: !disablePadding,
      [classes.withMargin]: withMargin,
    },
    className
  )

  return (
    <Alert severity={type} className={rootClassName}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {message || children}
    </Alert>
  )
}

export default AlertWrapper
