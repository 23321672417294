import React from 'react'

/**
 * USAGE
 *  import useCopyToClipboard from ...
 *
 *  const MyComponent = () => {
 *    const [copyFn, CopyPlaceolder] = useCopyToClipboard()
 *    const copyToClipboard = ({ value }) => copyFn({ value })
 *    return (
 *      <>
 *        <CopyPlaceolder />
 *        <button onClick={copyToClipboard}>Copy</button>
 *      </>
 *    )
 *  }
 */

const useCopyToClipboard = () => {
  const dateNow = Date.now()
  const placeholderId = `copy2clipboardtxt${dateNow}`

  const copyFn = ({ value = '' }): string => {
    const textareaEl = document.createElement('textarea')
    const placeholderEl = document.getElementById(placeholderId) as HTMLElement
    placeholderEl.appendChild(textareaEl)
    textareaEl.value = value
    textareaEl.select() // doesnt select ... :/ -- works on browser console. dammit
    document.execCommand('copy')
    textareaEl.remove()
    return value
  }

  const CopyPlaceholder = (): React.ReactElement => {
    return <div id={placeholderId} />
  }

  return { copyFn, CopyPlaceholder }
}

export default useCopyToClipboard
