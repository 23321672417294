import client from '../client'

export const mobilePackage = ({ customerId, mobileNumber, id }) => {
  return client.post(`mobile/${customerId}/${mobileNumber}/flatrate-package/${id}`).json()
}

export const reportUnassignedSimcardMissing = ({ customerId, id }) => {
  return client.post(`simcard/${customerId}/lost/${id}`).json()
}

export const blockSimcard = ({ customerId, phoneNumber }) => {
  return client.post(`mobile/${customerId}/${phoneNumber}/suspend`).json()
}

export const blockAndOrderEndUserSimcard = ({ abbId, json }) => {
  return client.post(`simcard/user/${abbId}`, { json }).json()
}

export const unblockSimcard = ({ customerId, phoneNumber }) => {
  return client.post(`mobile/${customerId}/${phoneNumber}/unsuspend`).json()
}

export const updateMobileCallForwarding = ({ customerId, mobileNumber, json }) => {
  return client.put(`mobile/${customerId}/${mobileNumber}/forward-to`, { json }).json()
}

export const setDisplayNumber = ({ customerId, phoneNumber, displayNumber }) => {
  return client.post(`display-number/${customerId}/${phoneNumber}/display/${displayNumber}`).json()
}

export const setInternationalDisplayNumber = ({ customerId, phoneNumber, json }) => {
  return client
    .post(`display-number/${customerId}/${phoneNumber}/international-display`, { json })
    .json()
}

export const setGroupNumbers = ({ customerId, abbId, json }) => {
  return client.post(`display-number/${customerId}/${abbId}/shared-numbers`, { json }).json()
}

export const setGroupNumbersBulk = ({ customerId, abbId, json }) => {
  return client.post(`display-number/${customerId}/${abbId}/shared-numbers`, { json })
}

export const setPrivateNumber = ({ customerId, phoneNumber, json }) => {
  return client.put(`cirpack/${customerId}/${phoneNumber}/private-number`, { json }).json()
}

export const transferSubscription = ({ customerId, abbId, targetCustomerId }) => {
  return client.post(`subscriptions/move/${customerId}/${abbId}/${targetCustomerId}`).json()
}

export const transferSubscriptionSupportTicket = ({ customerId, abbId, json }) => {
  return client.post(`subscriptions/move-alert/${customerId}/${abbId}`, { json }).json()
}

export const updateSingle = ({ customerId, abbId, json }) => {
  return client.post(`subscriptions/teams/change/${customerId}/${abbId}`, { json }).json()
}
export const suspendSim = (usersubId) => {
  return client.post(`mobile/user/${usersubId}/suspend-sim`).json()
}
export const unsuspendSim = (usersubId) => {
  return client.post(`mobile/user/${usersubId}/unsuspend-sim`).json()
}

export const contactMarkFavorite = (contactIds: string[]) => {
  return client.post('users/contacts/favorite/mark', { json: { contactIds } }).json()
}
export const contactUnMarkFavorite = (contactIds: string[]) => {
  return client.post('users/contacts/favorite/unmark', { json: { contactIds } }).json()
}
export const displayNumberSetActive = (phoneNumber: string, targetNumber: string) => {
  return client.put(`users/displayNumber/setActive/${phoneNumber}/${targetNumber}`).json()
}
export const displayNumberSetWeeklySchedule = (phoneNumber: string, json) => {
  return client.put(`users/displayNumber/weeklySchedule/${phoneNumber}`, { json }).json()
}
