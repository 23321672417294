import { getScript, addScript } from './helpers/injection'

const d = Date.now()
const s = {
  id: 'lib-usabilla',
  src: `/assets/js/usabilla.snippet.js?${d}`,
}

const getButtonEl = (): HTMLElement => {
  return document.querySelector('.usabilla_live_button_container')
}

const setShow = (show: boolean) => {
  const el = getButtonEl()
  if (el) {
    el.style.display = show ? 'block' : 'none'
  }
}

export const showFeedbackButton = () => {
  const sEl = getScript(s.id)
  if (!sEl) {
    addScript(s.id, s.src)
  }
  setShow(true)
}

export const hideFeedbackButton = () => {
  setShow(false)
}
