import React from 'react'
import classNames from 'classnames'
import { InputBase } from '@material-ui/core'
import { makeStyles, Theme, fade } from '@material-ui/core/styles'
import { Search as SearchIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    height: 36,
    display: 'flex',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    width: '100%',
    '&:hover': {
      backgroundColor: fade(theme.palette.background.default, 0.65),
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    color: theme.palette.text.secondary,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    fontSize: theme.typography.body1.fontSize,
    padding: '0px 0px 0px 56px',
    height: 36,
    width: '100%',
  },
}))

interface Props {
  placeholder: string
  onChange: (search: string) => void
  value: string
  className?: string
  loading: boolean
}

const UtilityInput = ({ loading = false, placeholder, onChange, value, className }: Props) => {
  const classes = useStyles()

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const rootClassName = classNames([classes.search], className)

  return (
    <div className={rootClassName}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        disabled={loading}
        fullWidth
        value={value}
        placeholder={placeholder}
        type="text"
        onChange={handleSearch}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': placeholder }}
      />
    </div>
  )
}

export default UtilityInput
