const black = '#1A1A1A'
const white = '#FFFFFF'

export const primaryLight = '#233CED'

export default {
  common: {
    black,
    white,
    neutral: '#E6E7E8',
    muted: '#949698',
  },
  primary: {
    contrastText: white,
    main: '#0000bf',
    light: '#ECF5FB',
    dark: '#000078',
  },
  secondary: {
    contrastText: white,
    main: '#949698',
    light: '#BDBEBF',
    dark: '#3C3C3C',
  },
  success: {
    contrastText: white,
    main: '#1b998b',
    light: '#EAF4F2',
    dark: '#03675B',
  },
  info: {
    contrastText: white,
    main: '#59b8eb',
    light: '#ECF5FB',
    dark: '#1A6C9E',
  },
  warning: {
    contrastText: white,
    main: '#ffcf42',
    light: '#FEFAEC',
    dark: '#c84600',
  },
  error: {
    contrastText: white,
    main: '#be1450',
    light: '#F8DFDF',
    dark: '#921038',
  },
  text: {
    primary: '#1A1A1A',
    secondary: '#949698',
    disabled: '#BDBEBF',
  },
  background: {
    default: '#f1f2f3',
    light: '#64D0DA',
    dark: '#3C3C3C',
    paper: white,
    orange: '#FE912A',
    pink: '#E348A5',
  },
  border: '#E6E7E8',
  divider: '#E6E7E8',
}
