import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import useDebounce from 'utils/hooks/useDebounce'
import { searchCrossCustomers } from 'services/sweb/customer/getCustomer'
import { DropdownDivider } from '@skytdc/mui/components'
import { makeStyles, Theme, fade } from '@material-ui/core/styles'
import {
  InputBase,
  Popper,
  Paper,
  CircularProgress,
  Grow,
  MenuList,
  ClickAwayListener,
  IconButton,
  Typography,
  ListItem,
  ListItemText,
} from '@material-ui/core'

import { Search as SearchIcon, CloseRounded as CloseIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    height: 36,
    display: 'flex',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    marginRight: 'auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.background.default, 0.65),
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    color: theme.palette.text.secondary,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    fontSize: theme.typography.body1.fontSize,
    padding: '0px 0px 0px 56px',
    height: 36,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '22ch',
      },
    },
  },
  expandIcon: {
    marginRight: theme.spacing(1.5),
  },
  searchPaper: {
    position: 'absolute',
    width: '100%',
    bottom: -22,
  },
  endAdornment: {
    width: 32,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  popper: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
  },
  popperPaper: {
    boxShadow:
      '0px 5px 4px -3px rgb(0 0 0 / 8%), 0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 9%)',
  },
}))

interface Props {
  changeRoute: (route: string) => void
  customerId?: string
}

const SearchDropdown = ({ changeRoute }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const inputRef = useRef(null)

  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [searchProcessing, setSearchProcessing] = useState(false)
  const [open, setOpen] = useState(false)
  const [delayedSearchTerm] = useDebounce(searchTerm, 500)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const searchTermLengthOk = searchTerm && searchTerm.length >= 3

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    if (selectedIndex > 0) setSelectedIndex(0)
  }, [open])

  useEffect(() => {
    if (searchTermLengthOk) {
      setSearchProcessing(true)
      searchCrossCustomers({ term: delayedSearchTerm })
        .then((response: { data?: [] }) => {
          setSearchProcessing(false)
          setSelectedIndex(0)
          setSearchResults([...(response?.data || [])].slice(0, 6))
          if (!open) {
            setOpen(true)
          }
        })
        .catch(() => {
          setSearchProcessing(false)
          setSearchResults([])
        })
    }
  }, [delayedSearchTerm])

  const handleOpen = () => setOpen(true)

  const handleClose = (
    event: React.MouseEvent<EventTarget> | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (inputRef.current && inputRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const handleClearInput = () => {
    setSearchProcessing(false)
    setSearchTerm('')
  }

  const handleGoToUser = (index?: number) => {
    setOpen(false)
    setSearchTerm('')

    const sIdx = index || selectedIndex
    const user = searchResults && sIdx >= 0 ? searchResults[index || selectedIndex] : undefined

    if (user) {
      changeRoute(`/${user.customer_id}/brugere/${user.abb_id}`)
    }
  }

  const handleKeyPress = (key: string, event: KeyboardEvent) => {
    if (open) {
      switch (key) {
        case 'up':
          event.preventDefault()
          if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1)
          } else {
            setSelectedIndex(searchResults.length - 1)
          }
          break
        case 'down':
          event.preventDefault()
          if (selectedIndex < searchResults.length - 1) {
            setSelectedIndex(selectedIndex + 1)
          } else {
            setSelectedIndex(0)
          }
          break
        case 'enter':
          handleGoToUser()
          break
        default:
      }
    }
  }

  return (
    <div className={classes.search}>
      <KeyboardEventHandler
        handleKeys={['up', 'down', 'enter']}
        handleFocusableElements
        onKeyEvent={(key, event) => handleKeyPress(key, event)}
      />
      <div className={classes.searchIcon}>
        {searchProcessing ? <CircularProgress size={18} /> : <SearchIcon />}
      </div>
      <InputBase
        fullWidth
        value={searchTerm}
        placeholder={t('Layout_search')}
        onChange={handleSearch}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': t('Layout_search_desc') }}
        ref={inputRef}
        onFocus={handleOpen}
        // onBlur={() => setOpen(false)}
        endAdornment={
          <div className={classes.endAdornment}>
            {searchTermLengthOk && (
              <IconButton size="small" onClick={handleClearInput}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        }
      />
      <Popper
        open={open}
        anchorEl={inputRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center bottom' }}
            timeout={{
              enter: 300,
              exit: 0,
            }}
          >
            <Paper elevation={0} className={classes.popperPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={false}>
                  {searchResults && searchResults.length > 0 ? (
                    searchResults.map((x, index) => {
                      return (
                        <div key={x.abb_id}>
                          <ListItem
                            alignItems="flex-start"
                            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                              event.preventDefault()
                              handleGoToUser(index)
                            }}
                            button
                            className={classes.listItem}
                            selected={selectedIndex === index}
                          >
                            <ListItemText
                              primary={
                                <>
                                  <strong>
                                    {x.first_name} {x.last_name}
                                  </strong>
                                </>
                              }
                              secondary={
                                <>
                                  <Typography
                                    component="span"
                                    display="block"
                                    variant="body2"
                                    color="textPrimary"
                                    gutterBottom
                                    noWrap
                                  >
                                    {x.email}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    display="block"
                                    variant="body2"
                                    color="textSecondary"
                                    noWrap
                                  >
                                    - {t('Dashboard_invoice_mobile')}: {x.mobile_number}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    display="block"
                                    variant="body2"
                                    color="textSecondary"
                                    noWrap
                                  >
                                    - {t('Dashboard_invoice_landline')}:{' '}
                                    {x.international_number || x.landline_number}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    display="block"
                                    variant="body2"
                                    color="textSecondary"
                                    noWrap
                                  >
                                    - {t('Organization_unassignedsimcards_customer_id')}:{' '}
                                    {x.customer_id}
                                  </Typography>
                                </>
                              }
                            />
                          </ListItem>
                          {index < 5 && <DropdownDivider withGutters />}
                        </div>
                      )
                    })
                  ) : (
                    <Typography style={{ padding: 8, paddingLeft: 16 }} color="textSecondary">
                      {searchProcessing && searchTermLengthOk && t('Layout_is_searching')}
                      {!searchProcessing && !searchTermLengthOk && t('Layout_search_desc')}
                      {!searchProcessing &&
                        searchTermLengthOk &&
                        searchResults &&
                        searchResults.length === 0 &&
                        t('Layout_search_noresult')}
                    </Typography>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default SearchDropdown
