import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isFuture } from 'date-fns'
import { useHistory, useLocation } from 'react-router-dom'
import { SidebarMenu, AppBar } from '@skytdc/mui/components'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { MenuRounded as MenuIcon } from '@material-ui/icons'

import { IMG_TDC_LOGO_BUSSINESS } from 'constants/cdn'

import NavLinks from './components/NavLinks'
import OrganizationSelect from './components/OrganizationSelect'
import UserSelect from './components/UserSelect'
import SearchDropdown from './components/SearchDropdown'
import GoLiveAlert from './components/GoLiveAlert'
import SidebarBottomLinks from './components/SidebarBottomLinks'

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    maxWidth: 1600,
    margin: 'auto',
    paddingTop: 120,
    paddingLeft: 338,
    padding: theme.spacing(8),
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 112,
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
      paddingTop: 100,
    },
  },
  sidebar: {
    marginTop: 66,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(9.5),
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      height: '100%',
    },
  },
  logo: {
    marginLeft: theme.spacing(3),
    width: 100,
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  rightTopbarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
  },
  menuIcon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}))

interface Props {
  children?: any
  hideLayout?: boolean
}

const Layout = ({ children, hideLayout }: Props) => {
  const classes = useStyles()
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'), { noSsr: true })
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'), { noSsr: true })
  const { push } = useHistory()
  const { pathname: currentUrl } = useLocation()

  const customer = useSelector((state) => state.customer)
  const me = useSelector((state) => state.me)

  const isAdmin = me.data?.user?.role === 'admin'

  const [sidebarOpen, setSidebarOpen] = useState(Boolean(!isMobile))

  const slim = isTablet && !isMobile
  const handleClose = () => setSidebarOpen(false)
  const changeRoute = (route: string) => push(route)

  const goLiveDateMissingOrInFuture = Boolean(
    !customer.data?.go_live_date || isFuture(new Date(customer.data?.go_live_date))
  )

  if (hideLayout) return children

  return (
    <>
      <AppBar>
        <div className={classes.rightTopbarContainer}>
          {isMobile && (
            <IconButton onClick={() => setSidebarOpen(!sidebarOpen)} className={classes.menuIcon}>
              <MenuIcon />
            </IconButton>
          )}
          <img src={IMG_TDC_LOGO_BUSSINESS} alt="TDC Erhverv" className={classes.logo} />
        </div>
        <div className={classes.rightTopbarContainer}>
          <SearchDropdown changeRoute={changeRoute} />
          <UserSelect me={me.data} customerId={customer.data.customer_id} />
        </div>
      </AppBar>
      <SidebarMenu
        justify="space-between"
        onClose={handleClose}
        open={sidebarOpen}
        isMobile={isMobile}
        width={slim ? 73 : 270}
        className={classes.sidebar}
      >
        <div>
          <OrganizationSelect
            customerName={customer.data.name}
            customerId={customer.data.customer_id}
            changeRoute={changeRoute}
            canAccessOverview={
              isAdmin || (me.data?.authorizations && me.data.authorizations.length > 1)
            }
            unionName={
              me.data?.user?.role !== 'admin' &&
              me.data?.authorizations &&
              me.data?.authorizations.length > 0
                ? `• ${
                    me.data.authorizations.find((x) => x.customer_id === customer.data.customer_id)
                      ?.union_name
                  }`
                : ''
            }
            slim={slim}
          />
          <NavLinks
            changeRoute={changeRoute}
            currentUrl={currentUrl}
            customerId={customer.data.customer_id}
            slim={slim}
          />
        </div>
        <div>
          <SidebarBottomLinks
            changeRoute={changeRoute}
            currentUrl={currentUrl}
            customerId={customer.data.customer_id}
            slim={slim}
            isAdmin={isAdmin}
          />
        </div>
      </SidebarMenu>
      <main className={classes.content}>
        {isAdmin && goLiveDateMissingOrInFuture && (
          <GoLiveAlert goLiveDate={customer.data.go_live_date} />
        )}
        {children}
      </main>
    </>
  )
}

export default Layout
