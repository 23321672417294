import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  absoluteProgressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  white: {
    color: '#fff',
  },
}))

const PaperLoader = ({ className = '', absolute = false, color = 'primary', ...rest }) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.progressWrapper]: !absolute,
      [classes.primary]: color === 'primary',
      [classes.white]: color === 'white',
    },
    className
  )

  return (
    <div {...rest} className={rootClassName}>
      <CircularProgress color="inherit" />
    </div>
  )
}

export default PaperLoader
