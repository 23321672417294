import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'dataPoolUsage/FETCH_FAILURE'
export const FETCH_BEGIN = 'dataPoolUsage/FETCH_BEGIN'
export const FETCH_SUCCESS = 'dataPoolUsage/FETCH_SUCCESS'

export const fetchDataPoolUsageFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchDataPoolUsageBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchDataPoolUsageSuccess = ({ data }) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchDataPoolUsage = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchDataPoolUsageBegin())
    try {
      const response = await getSubscription.dataPoolUsage({
        customerId,
      })
      dispatch(fetchDataPoolUsageSuccess({ data: response.data }))
    } catch (err) {
      dispatch(fetchDataPoolUsageFailure(err.response))
    }
  }
}
