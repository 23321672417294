import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInvoiceLandlineSummary } from 'state/invoiceLandlineSummary/actions'
import { fetchInvoiceLandlineNumberSummary } from 'state/invoiceLandlineNumberSummary/actions'
import { fetchInvoiceLandlineIntSummary } from 'state/invoiceLandlineIntSummary/actions'
import { fetchInvoiceReferences } from 'state/invoiceReferences/actions'
import { fetchInvoiceNumberReferences } from 'state/invoiceNumberReferences/actions'
import { fetchInvoiceLandlineSpecifications } from 'state/invoiceLandlineSpecifications/actions'
import { Paper, PaperHeader, PaperTitle, PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import SpecificationRadios from '../SpecificationRadios'
import UsageCategoryTable from '../UsageCategoryTable'
import NumberSummaryTable from '../NumberSummaryTable'
import AbroadCallsTable from '../AbroadCallsTable'
import SpecificationsTable from '../UsageCategoryTable/SpecificationsTable'

const LandlineDetails = ({ invoiceId, customerId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [prevSelectedLocationTypes, setPrevSelectedLocationTypes] = useState(null)
  const [specificationType, setSpecificationType] = useState('summary')
  const invoiceLandlineSummary = useSelector((state) => state.invoiceLandlineSummary)
  const invoiceLandlineNumberSummary = useSelector((state) => state.invoiceLandlineNumberSummary)
  const invoiceLandlineIntSummary = useSelector((state) => state.invoiceLandlineIntSummary)
  const invoiceLandlineSpecifications = useSelector((state) => state.invoiceLandlineSpecifications)
  const invoiceNumberReferences = useSelector((state) => state.invoiceNumberReferences)
  const invoiceReferences = useSelector((state) => state.invoiceReferences)

  useEffect(() => {
    dispatch(fetchInvoiceNumberReferences({ customerId }))
    dispatch(fetchInvoiceReferences({ customerId }))
    if (
      invoiceLandlineSpecifications.locationtype &&
      invoiceLandlineSpecifications.invoiceId &&
      invoiceLandlineSpecifications.invoiceId === invoiceId
    ) {
      setPrevSelectedLocationTypes(invoiceLandlineSpecifications.locationtype)
    }
  }, [])

  useEffect(() => {
    if (specificationType === 'category') {
      dispatch(fetchInvoiceLandlineSummary({ customerId, invoiceId }))
    }
    if (specificationType === 'summary') {
      dispatch(fetchInvoiceLandlineNumberSummary({ customerId, invoiceId }))
    }
    if (specificationType === 'abroad') {
      dispatch(fetchInvoiceLandlineIntSummary({ customerId, invoiceId }))
    }
  }, [specificationType])

  let specificationContent

  const handleFetchSpecifications = (locationTypes) => {
    dispatch(
      fetchInvoiceLandlineSpecifications({
        customerId,
        invoiceId,
        locationtype: locationTypes.join(),
        startdate: invoiceLandlineSummary.data.invoice.billing_from,
        enddate: invoiceLandlineSummary.data.invoice.billing_to,
      })
    )
  }

  if (specificationType === 'category') {
    specificationContent = (
      <>
        <UsageCategoryTable
          data={invoiceLandlineSummary}
          invoiceId={invoiceId}
          fetchSpecifications={handleFetchSpecifications}
          prevLocationTypes={prevSelectedLocationTypes}
        />
        {invoiceLandlineSpecifications.data && !invoiceLandlineSpecifications.loading && (
          <>
            <Paper>
              <PaperHeader noDivider>
                <PaperTitle title={t('Invoice_specss')} />
              </PaperHeader>
            </Paper>
            <SpecificationsTable data={invoiceLandlineSpecifications} invoiceId={invoiceId} />
          </>
        )}
        {invoiceLandlineSpecifications.loading && <PaperLoader />}
      </>
    )
  }

  if (specificationType === 'summary') {
    specificationContent = (
      <>
        {invoiceLandlineNumberSummary.data && invoiceNumberReferences.data && invoiceReferences ? (
          <NumberSummaryTable
            data={invoiceLandlineNumberSummary}
            invoiceId={invoiceId}
            existingRefs={invoiceNumberReferences}
            references={invoiceReferences}
          />
        ) : (
          <PaperLoader />
        )}
      </>
    )
  }

  if (specificationType === 'abroad') {
    specificationContent = (
      <AbroadCallsTable data={invoiceLandlineIntSummary} invoiceId={invoiceId} type="landline" />
    )
  }

  return (
    <>
      <SpecificationRadios
        specificationType={specificationType}
        setSpecificationType={setSpecificationType}
      />
      {specificationContent}
    </>
  )
}

export default LandlineDetails
