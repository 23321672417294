import { getCustomer } from 'services/sweb/customer'
import {
  OCINVITES_FAILURE,
  OCINVITES_BEGIN,
  OCINVITES_SUCCESS,
  ParamsProps,
  IntNumbersActions,
  IntNumbersState,
} from './model'

export const fetchOCInvitesFailure = (error: IntNumbersState['error']): IntNumbersActions => {
  return {
    type: OCINVITES_FAILURE,
    error,
  }
}

export const fetchOCInvitesBegin = ({ customerId }: ParamsProps): IntNumbersActions => {
  return {
    type: OCINVITES_BEGIN,
    customerId,
  }
}

export const fetchOCInvitesSuccess = (data: IntNumbersState['data']): IntNumbersActions => {
  return {
    type: OCINVITES_SUCCESS,
    data,
  }
}

export const fetchOCInvites = ({ customerId }: ParamsProps) => {
  return async (dispatch) => {
    dispatch(fetchOCInvitesBegin({ customerId }))
    try {
      const response = await getCustomer.OCInvites({
        customerId,
      })

      dispatch(fetchOCInvitesSuccess((response as any).data))
    } catch (err) {
      dispatch(fetchOCInvitesFailure(err.response))
    }
  }
}
