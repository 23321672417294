import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'availableInternationalNumbers/FETCH_FAILURE'
export const FETCH_BEGIN = 'availableInternationalNumbers/FETCH_BEGIN'
export const FETCH_SUCCESS = 'availableInternationalNumbers/FETCH_SUCCESS'

export const fetchAvailableInternationalNumbersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAvailableInternationalNumbersBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchAvailableInternationalNumbersSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchAvailableInternationalNumbers = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchAvailableInternationalNumbersBegin({ customerId }))
    try {
      const response = await getCustomer.availableInternationalNumbers({
        customerId,
      })
      dispatch(fetchAvailableInternationalNumbersSuccess(response.data))
    } catch (err) {
      dispatch(fetchAvailableInternationalNumbersFailure(err.response))
    }
  }
}
