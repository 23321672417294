import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ShoppingBasketOutlined as ProductIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import ReferencePopper from 'views/Invoice/components/ReferencePopper'
import { useTranslation } from 'react-i18next'

const PricePerMonth = ({ invoice, abbId, mobilePackageName, customerId, disabled }) => {
  const { t } = useTranslation()
  const [currentRef, setCurrentRef] = useState(null)
  const invoiceReferences = useSelector((state) => state.invoiceReferences)
  const invoiceSubscriptions = useSelector((state) => state.invoiceSubscriptions)

  useEffect(() => {
    if (
      invoiceReferences.data &&
      invoiceReferences.data.items.length > 0 &&
      invoiceSubscriptions.data &&
      invoiceSubscriptions.data.items.length > 0
    ) {
      const findSubscription = invoiceSubscriptions.data.items.find(
        (sub) => sub.subscription_id === abbId
      )
      if (findSubscription && findSubscription.reference_id) {
        const findReference = invoiceReferences.data.items.find(
          (ref) => ref.reference_id === findSubscription.reference_id
        )
        setCurrentRef(findReference.reference_name)
      }
    }
  }, [invoiceReferences.data, invoiceSubscriptions.data])

  return (
    <>
      {invoice && invoice.base_subscription && (
        <DrawerListItem
          icon={<ProductIcon />}
          primaryText={t('Drawer_user_base_sub')}
          isLoading={Boolean(invoiceSubscriptions.loading || invoiceReferences.loading)}
          isDisabled={disabled}
          secondaryText={
            <>
              {invoice.base_subscription.product_type_name}
              {invoiceReferences.data && invoiceSubscriptions.data && (
                <ReferencePopper
                  value={currentRef}
                  updateValue={setCurrentRef}
                  showRefLink={false}
                  secondaryTextVersion
                  data={{ customerId, subscriptionId: abbId }}
                  availableReferences={invoiceReferences.data.items}
                />
              )}
            </>
          }
          secondaryActionText={`${invoice.base_subscription.price},-`}
        />
      )}
      {invoice &&
        invoice.add_ons &&
        invoice.add_ons.length > 0 &&
        invoice.add_ons.map((addon, index) => {
          return (
            <DrawerListItem
              // eslint-disable-next-line
              key={`${addon.product_type_id}-${index}`}
              icon={<ProductIcon />}
              primaryText={
                (mobilePackageName === addon.product_type_name && t('Drawer_price_mobile')) ||
                'Addon'
              }
              secondaryText={addon.product_type_name}
              isDisabled={disabled}
              secondaryActionText={`${addon.price.toLocaleString('da-DK')},-`}
            />
          )
        })}
      {invoice && invoice.monthly_price >= 0 && (
        <DrawerListItem
          isDisabled={disabled}
          secondaryActionText={t('Drawer_user_ppm', {
            amount: invoice.monthly_price.toLocaleString('da-DK'),
          })}
        />
      )}
    </>
  )
}

export default memo(PricePerMonth)
