import React, { useState, useEffect } from 'react'
import Fuse from 'fuse.js'
import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, Typography, TableRow, TableCell } from '@material-ui/core'
import { EnhancedTablePagination, Search, Paper, PaperContent, PaperToolbar } from 'components'
import { useTranslation } from 'react-i18next'
import SimcardTableRow from '../SimcardTableRow'
import SimcardTableHead from '../SimcardTableHead'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: theme.spacing(3),
  },
  paper: {
    overflow: 'auto',
  },
  tableContainer: {
    minWidth: '965px',
  },
}))

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const SimcardTable = ({ apiData, loading, reportSimcardMissing }) => {
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('icc')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { t } = useTranslation()
  const [filteredData, setFilteredData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  const searchOptions = {
    shouldSort: true,
    threshold: 0.1,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 2,
    keys: ['customer_id', 'icc', 'union_id', 'pin_code', 'puk_code'],
  }

  useEffect(() => {
    setFilteredData(apiData)
  }, [apiData])

  const filterData = (searchTerm) => {
    setPage(0)
    const fuse = new Fuse(apiData, searchOptions)
    const result = fuse.search(searchTerm)
    setFilteredData(searchTerm.length > 0 ? result : apiData)
  }
  const handleSelectAllRows = () => {
    if (selectedRows.length > 0 && selectedRows.length < filteredData.length) {
      setSelectedRows([])
    } else if (selectedRows.length === filteredData.length) {
      setSelectedRows([])
    } else if (selectedRows.length === 0) {
      setSelectedRows(filteredData)
    }
  }
  const handleSelectSingleRow = (row) => {
    const rowExists = selectedRows.find((sRow) => sRow.id === row.id)
    if (rowExists) {
      let newSelected = []
      const idx = selectedRows.findIndex((x) => x.id === row.id)
      if (idx === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1))
      } else if (idx === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1))
      } else if (idx > 0) {
        newSelected = newSelected.concat(selectedRows.slice(0, idx), selectedRows.slice(idx + 1))
      }
      setSelectedRows(newSelected)
    } else {
      setSelectedRows(selectedRows.concat(row))
    }
  }

  return (
    <Paper className={classes.paper}>
      <PaperToolbar className={classes.toolbar}>
        <Search
          filter={filterData}
          placeholder={t('Organization_unassignedsimcards_search')}
          autoFocus
        />
      </PaperToolbar>
      <PaperContent noPadding className={classes.tableContainer}>
        <Table size="small">
          <SimcardTableHead
            selectedRows={selectedRows}
            rowCount={apiData.length}
            handleSelectAllRows={handleSelectAllRows}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
          <TableBody>
            {filteredData.length > 0 ? (
              stableSort(filteredData, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((simcard) => {
                  const isRowSelected = selectedRows.findIndex((selectedRow) => {
                    return selectedRow.id === simcard.id
                  })
                  return (
                    <SimcardTableRow
                      key={simcard.id}
                      simcard={simcard}
                      loading={loading}
                      reportSimcardMissing={reportSimcardMissing}
                      handleSelectSingleRow={handleSelectSingleRow}
                      isRowSelected={Boolean(isRowSelected >= 0)}
                    />
                  )
                })
            ) : (
              <TableRow>
                <TableCell colSpan={8}>
                  <Typography align="center">
                    {t('Organization_unassignedsimcards_noresult')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <EnhancedTablePagination
          arrayLength={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          entriesPerPageText={t('Organization_unassignedsimcards_perpage')}
        />
      </PaperContent>
    </Paper>
  )
}

export default SimcardTable
