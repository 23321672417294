import React, { useState } from 'react'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

interface SubscriptionMoreMenuProps {
  changeRoute: (url: string) => void
  anchorText?: string
  subscriptionId?: string
}

const SubscriptionMoreMenu = ({
  changeRoute,
  anchorText,
  subscriptionId,
}: SubscriptionMoreMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { t } = useTranslation()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleGoToCancelSubscription = () => {
    handleClose()
    changeRoute(subscriptionId || 'nedlaeg-abonnementer')
  }

  return (
    <>
      <IconButton edge="end" aria-label="Subscription choices" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      {anchorEl && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleGoToCancelSubscription}>
            {anchorText || t('Drawer_cancel_anchor')}
          </MenuItem>
        </Menu>
      )}
    </>
  )
}

export default SubscriptionMoreMenu
