import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInternationalNumbersV2 } from 'state/internationalNumbersV2/actions'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useTranslation } from 'react-i18next'
import { Paper, PaperContent, Alert } from '@skytdc/mui/components'
import NumbersTable from './components/NumbersTable'

interface Props extends RouteComponentProps {
  customerId: string
}

const AllNumbers = ({ customerId }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const internationalNumbers = useSelector((state) => state.internationalNumbersV2)
  const customerIdsMatch = customerId === internationalNumbers.customerId

  useEffect(() => {
    changeDocumentTitle(t('AllIntNumbers'), ' - ', ':applicationName')
    dispatch(fetchInternationalNumbersV2({ customerId }))
  }, [])

  const mappedNumbers =
    customerIdsMatch && internationalNumbers.data
      ? internationalNumbers.data.map((x) => {
          const isMediationMissing = Boolean(x.productVersion === 2 && !x.mediation)
          const hasValidReference = Boolean(x.references.length === 1)
          const customerIdsMatch =
            customerId === x.subscription.customerId &&
            customerId === x.references[0].ani.customerId &&
            customerId === x.references[0].referencedSubscriptions[0].customerId
          const hasConsistentCustomerIds =
            (customerIdsMatch && x.productVersion === 1) ||
            (customerIdsMatch &&
              x.productVersion === 2 &&
              x.mediation &&
              x.mediation.customerId === customerId)

          return {
            ...x,
            numberWithCountryCode: `+${x.country.prefix} ${x.inumber}`,
            countryName: x.country.name,
            comments: x.subscription.extraInfo || '',
            // the object 'mediation' is missing for v1. If it is also missing for a v2 number, then flag the number with an error
            isMediationMissing,
            // if more than 1, check if endDates overlap TODO
            hasValidReference,
            // 'customerId' is shown three places: subscription.customerId, references.ani.customerId, references.referencedSubscriptions.customerId, and mediation.customerId. If they don't match then flag the number with an error
            hasInconsistentCustomerIds: !hasConsistentCustomerIds,
            hasErrors: isMediationMissing || !hasValidReference || !hasConsistentCustomerIds,
          }
        })
      : []

  if (internationalNumbers.error && internationalNumbers.error.statusText) {
    return (
      <Paper style={{ maxWidth: 600 }}>
        <PaperContent>
          <Alert type="error" message={internationalNumbers.error.statusText} />
        </PaperContent>
      </Paper>
    )
  }

  return <NumbersTable numbers={mappedNumbers} loading={internationalNumbers.loading} />
}

export default AllNumbers
