import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceMobileNumberSummary/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceMobileNumberSummary/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceMobileNumberSummary/FETCH_SUCCESS'

export const fetchInvoiceMobileNumberSummaryFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceMobileNumberSummaryBegin = ({ invoiceId }) => {
  return {
    type: FETCH_BEGIN,
    invoiceId,
  }
}

export const fetchInvoiceMobileNumberSummarySuccess = ({ invoiceId, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    invoiceId,
  }
}

export const fetchInvoiceMobileNumberSummary = ({ customerId, invoiceId }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceMobileNumberSummaryBegin({ invoiceId }))
    const type = 'mobile'
    try {
      const response = await getInvoice.summaryByPhonenumber({ customerId, invoiceId, type })
      dispatch(fetchInvoiceMobileNumberSummarySuccess({ invoiceId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceMobileNumberSummaryFailure(err.response))
    }
  }
}
