import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, Grid, Grow, Link, IconButton } from '@material-ui/core'
import { ChevronLeftRounded as LeftIcon } from '@material-ui/icons'
import { IMG_TDC_LOGO_BUSSINESS } from 'constants/cdn'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from 'views/Layout/components/LanguageSwitcher'
import TopbarStepper from '../TopbarStepper'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    height: 63,
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: theme.zIndex.appBar,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.08), 0px 4px 5px 0px rgba(0, 0, 0, 0), 0px 1px 10px 0px rgba(0, 0, 0, 0)',
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
  },
  logoAndText: {
    width: 104,
    textAlign: 'right',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    border: 'none',
    background: 'none',
  },
}))

const Topbar = ({ showSteps, currentStep, goBack, presencePage }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        {goBack && (
          <IconButton edge="start" onClick={goBack}>
            <LeftIcon />
          </IconButton>
        )}
        <img src={IMG_TDC_LOGO_BUSSINESS} alt="TDC Erhverv logo" className={classes.logoAndText} />
        <Grid container justify="center">
          <Grid item lg={6} md={8} sm={12} xs={12}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Grow in={showSteps}>
                <Grid item xs={12}>
                  <TopbarStepper showSteps={showSteps} currentStep={currentStep} />
                </Grid>
              </Grow>
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          className={classes.logoAndText}
        >
          <LanguageSwitcher />
          <Link
            href={
              presencePage
                ? 'https://support.sky.tdc.dk/hc/da/articles/4411820998290'
                : 'https://support.sky.tdc.dk/hc/da/articles/360009774700-S%C3%A5dan-udfylder-du-opstarts-wizard-en-i-Teams-Selvbetjening'
            }
            target="_blank"
          >
            {t('Drawer_mypage_e')}
          </Link>
        </div>
      </Toolbar>
    </div>
  )
}

export default Topbar
