import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceExtraServicesDetails/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceExtraServicesDetails/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceExtraServicesDetails/FETCH_SUCCESS'

export const fetchInvoiceExtraServicesDetailsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceExtraServicesDetailsBegin = ({ invoiceId }) => {
  return {
    type: FETCH_BEGIN,
    invoiceId,
  }
}

export const fetchInvoiceExtraServicesDetailsSuccess = ({ invoiceId, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    invoiceId,
  }
}

export const fetchInvoiceExtraServicesDetails = ({ customerId, invoiceId }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceExtraServicesDetailsBegin({ invoiceId }))
    try {
      const response = await getInvoice.extraServicesDetails({ customerId, invoiceId })
      dispatch(fetchInvoiceExtraServicesDetailsSuccess({ invoiceId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceExtraServicesDetailsFailure(err.response))
    }
  }
}
