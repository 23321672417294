import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { AddCircleRounded as AddIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import Layout from 'views/Layout'
import { PageTitle, PageTabs } from '@skytdc/mui/components'
import AssignedNumbers from './AssignedNumbers'
import AllNumbers from './AllNumbers'
import Orders from './Orders'

const getCurrentTabValue = (currentPath: string) => {
  if (currentPath.includes('tildelte-numre')) return 'tildelte-numre'
  if (currentPath.includes('domaener')) return 'domaener'
  if (currentPath.includes('kapacitetsgrupper')) return 'kapacitetsgrupper'
  if (currentPath.includes('alle-numre')) return 'alle-numre'
  if (currentPath.includes('ordrer')) return 'ordrer'
  return 'tildelte-numre'
}

const LandelineNumbers = ({ history, match }) => {
  const { t } = useTranslation()

  const { customer_id: customerId } = match.params

  // for now, only show when standing on assigned numbers
  const routeIsAssignedNumbers = match.url.includes('/tildelte-numre')

  const handleCreate = () => history.push(`${match.url}/opret`)

  const handleChangePage = (newPage: string) => {
    history.push(`/${customerId}/operator-connect/numre/${newPage}`)
  }

  return (
    <Layout>
      <div style={{ maxWidth: 1000, margin: 'auto' }}>
        <PageTitle title={t('Numbers')}>
          {routeIsAssignedNumbers && (
            <Button
              variant="contained"
              disableElevation
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleCreate}
            >
              {t('Organization_huntgroups_create_label')}
            </Button>
          )}
        </PageTitle>
        <PageTabs
          currentValue={getCurrentTabValue(match.url)}
          handleChange={handleChangePage}
          withMargin
          tabs={[
            {
              value: 'tildelte-numre',
              label: t('AssignedIntNumbers'),
              disabled: false,
            },
            {
              value: 'ordrer',
              label: t('BuaOrders'),
              disabled: false,
            },
          ]}
        />
        <Switch>
          <Route
            render={(props) => <AssignedNumbers {...props} customerId={customerId} />}
            exact
            path={`/${customerId}/operator-connect/numre/tildelte-numre/:action?`}
          />
          <Route
            render={(props) => <AllNumbers {...props} customerId={customerId} />}
            exact
            path={`/${customerId}/operator-connect/numre/alle-numre/:action?`}
          />
          <Route
            render={(props) => <Orders {...props} customerId={customerId} />}
            exact
            path={`/${customerId}/operator-connect/numre/ordrer/:action?`}
          />
          <Redirect to={`/${customerId}/operator-connect/numre/tildelte-numre`} />
        </Switch>
      </div>
    </Layout>
  )
}

export default LandelineNumbers
