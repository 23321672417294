import { getCustomer } from 'services/sweb/customer'
import {
  OCUSERS_FAILURE,
  OCUSERS_BEGIN,
  OCUSERS_SUCCESS,
  ParamsProps,
  IntNumbersActions,
  IntNumbersState,
} from './model'

export const fetchOCUsersFailure = (error: IntNumbersState['error']): IntNumbersActions => {
  return {
    type: OCUSERS_FAILURE,
    error,
  }
}

export const fetchOCUsersBegin = ({ customerId }: ParamsProps): IntNumbersActions => {
  return {
    type: OCUSERS_BEGIN,
    customerId,
  }
}

export const fetchOCUsersSuccess = (data: IntNumbersState['data']): IntNumbersActions => {
  return {
    type: OCUSERS_SUCCESS,
    data,
  }
}

export const fetchOCUsers = ({ customerId }: ParamsProps) => {
  return async (dispatch) => {
    dispatch(fetchOCUsersBegin({ customerId }))
    try {
      const response = await getCustomer.OCUsers({
        customerId,
      })

      dispatch(fetchOCUsersSuccess((response as any).data))
    } catch (err) {
      dispatch(fetchOCUsersFailure(err.response))
    }
  }
}
