import React from 'react'
import { NavLink } from '@skytdc/mui/components'
import { List, ListSubheader, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  CodeRounded as CodeIcon,
  PublicRounded as PublicIcon,
  SettingsEthernet as SettingsEthernetIcon,
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  listSubheader: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    marginTop: theme.spacing(2),
    lineHeight: '40px',
  },
}))

interface Props {
  changeRoute: (route: string) => void
  currentUrl: string
  customerId: string
  slim: boolean
  isAdmin: boolean
}

const SidebarBottomLinks = ({ changeRoute, currentUrl, customerId, slim, isAdmin }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const broadworks = useSelector((state) => state.customer?.data?.customer_properties?.broadworks)

  return (
    isAdmin && (
      <>
        <List
          component="nav"
          disablePadding
          aria-label="Main Menu"
          subheader={
            slim ? undefined : (
              <ListSubheader component="div" className={classes.listSubheader}>
                {t('Internal_tools')}
              </ListSubheader>
            )
          }
        >
          <>
            <NavLink
              primaryText="T.R.U.S.T. scripts"
              isActive={currentUrl.includes('/trust-scripts')}
              url={`/${customerId}/trust-scripts`}
              icon={<CodeIcon />}
              changeRoute={changeRoute}
              isMobile={slim}
              tooltip={slim ? 'T.R.U.S.T. scripts' : ''}
            />
            <NavLink
              primaryText={t('Addons_int_numbers_title')}
              isActive={currentUrl.includes('/internationale-numre')}
              url={`/${customerId}/internationale-numre`}
              icon={<PublicIcon />}
              changeRoute={changeRoute}
              isMobile={slim}
              tooltip={slim ? t('Addons_int_numbers_title') : ''}
            />
          </>
        </List>
        <List
          component="nav"
          disablePadding
          aria-label="Main Menu"
          subheader={
            slim ? undefined : (
              <ListSubheader component="div" className={classes.listSubheader}>
                Routing
              </ListSubheader>
            )
          }
        >
          <ListItem>
            <ListItemIcon>
              <SettingsEthernetIcon />
            </ListItemIcon>
            <ListItemText primary={broadworks ? 'Broadworks' : 'Cirpack'} />
          </ListItem>
        </List>
      </>
    )
  )
}

export default SidebarBottomLinks
