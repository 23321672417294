import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { SimCardOutlined as SimcardIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'

const Simcards = ({ currentUrl, disabled }) => {
  const { t } = useTranslation()
  const simcards = useSelector((state) => state.simcards)

  const createLabel = () => {
    let label = t('Drawer_user_no_datashare')
    if (simcards.data.the_rest.length > 0) {
      label =
        simcards.data.the_rest.length > 1
          ? t('Drawer_user_datashare_amount', { amount: simcards.data.the_rest.length })
          : simcards.data.the_rest[0].icc
    }
    return label
  }

  return (
    <>
      <DrawerListItem
        icon={<SimcardIcon />}
        primaryText={t('Drawer_simcard_simcard')}
        isLoading={Boolean(!simcards.data)}
        isDisabled={disabled}
        secondaryText={
          simcards.data && !simcards.error ? simcards.data.primary.icc : t('Drawer_user_err')
        }
        link={`${currentUrl}/simkort`}
        linkAnchor={t('Drawer_user_anchor')}
      />
      <DrawerListItem
        icon={<SimcardIcon />}
        primaryText={t('Drawer_simcard_datashare')}
        secondaryText={simcards.data && !simcards.error ? createLabel() : t('Drawer_user_err')}
        isLoading={Boolean(!simcards.data)}
        isDisabled={disabled || (simcards.data && simcards.data.the_rest.length === 0)}
        link={`${currentUrl}/simkort`}
      />
    </>
  )
}

export default memo(Simcards)
