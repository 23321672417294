import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'voxboneVoiceUri/FETCH_FAILURE'
export const FETCH_BEGIN = 'voxboneVoiceUri/FETCH_BEGIN'
export const FETCH_SUCCESS = 'voxboneVoiceUri/FETCH_SUCCESS'

export const fetchVoxboneVoiceUriFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchVoxboneVoiceUriBegin = ({ customerId, id }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
    id,
  }
}

export const fetchVoxboneVoiceUriSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchVoxboneVoiceUri = ({ customerId, id }) => {
  return async (dispatch) => {
    dispatch(fetchVoxboneVoiceUriBegin({ customerId, id: id || undefined }))
    try {
      const response = await getCustomer.intNumbersVoxboneVoiceUri({
        customerId,
        id,
        pagenumber: 0,
        pagesize: 10000,
      })
      dispatch(fetchVoxboneVoiceUriSuccess(response.data))
    } catch (err) {
      dispatch(fetchVoxboneVoiceUriFailure(err.response))
    }
  }
}
