export default {
  paper: {
    boxShadow: 'none',
    // marginBottom: '48px',
  },
  responsiveScrollFullHeight: {
    // maxHeight: '680px',
    marginBottom: '-2px',
    overflow: 'hidden',
  },
}
