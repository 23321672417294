import { getUser } from 'services/sweb/user'

export const FETCH_FAILURE = 'admins/FETCH_FAILURE'
export const FETCH_BEGIN = 'admins/FETCH_BEGIN'
export const FETCH_SUCCESS = 'admins/FETCH_SUCCESS'

export const fetchAdminFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAdminBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchAdminSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchAdmin = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchAdminBegin())
    try {
      const response = await getUser.allAdmins({
        customerId,
      })
      dispatch(fetchAdminSuccess(response.data))
    } catch (err) {
      dispatch(fetchAdminFailure(err.response))
    }
  }
}
