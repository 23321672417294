import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'azureAppSecrets/FETCH_FAILURE'
export const FETCH_BEGIN = 'azureAppSecrets/FETCH_BEGIN'
export const FETCH_SUCCESS = 'azureAppSecrets/FETCH_SUCCESS'

export const fetchAzureAppSecretsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAzureAppSecretsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchAzureAppSecretsSuccess = ({ customerId, data }) => {
  return {
    type: FETCH_SUCCESS,
    customerId,
    data,
  }
}

export const fetchAzureAppSecrets = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchAzureAppSecretsBegin({ customerId }))
    try {
      const response = await getCustomer.azureAppSecret({
        customerId,
      })
      dispatch(fetchAzureAppSecretsSuccess({ customerId, data: response.data }))
    } catch (err) {
      dispatch(fetchAzureAppSecretsFailure(err.response))
    }
  }
}
