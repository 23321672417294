import client from '../client'

export const azureAppSecret = ({ customerId, json }) => {
  return client.post(`teams/${customerId}/create-app-secret`, { json }).json()
}

export const presenceAppSecret = ({ customerId, json }) => {
  return client.post(`teams/${customerId}/create-presence-secret`, { json }).json()
}

export const azureAdminSecret = ({ customerId, json }) => {
  return client.post(`teams/${customerId}/create-admin-secret`, { json }).json()
}

export const orderUnassignedSimcards = ({ customerId, json }) => {
  return client.post(`simcard/${customerId}/purchase`, { json }).json()
}

export const orderHardware = ({ customerId, json }) => {
  return client.post(`tickets/${customerId}/order-hardware`, { json }).json()
}

export const callMeTicket = ({ customerId, json }) => {
  return client.post(`tickets/${customerId}/order-callback`, { json }).json()
}

export const huntgroup = ({ customerId, json }) => {
  return client.post(`cirpack/${customerId}`, { json }).json()
}

export const executePsWebScript = ({ customerId, json }) => {
  return client.post(`teams-psweb/execute-script/${customerId}`, { json, timeout: false }).json()
}

export const intNumbers = ({ customerId, json }) => {
  return client
    .post(`internationalnumbers/${customerId}/config-order`, { json, timeout: false })
    .json()
}

export const portLandlineNumbers = ({ customerId, json }) => {
  return client.post(`subscriptions/${customerId}/landline-numbers`, { json }).json()
}

export const OCOrderNumber = ({ customerId, json }) => {
  return client.post(`operator-connect/orders/${customerId}`, { json, timeout: false }).json()
}
