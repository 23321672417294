import React from 'react'
import { UserWithAvatar, MuiDataTable } from 'components'
import { convertKbToGb, phoneNumberFormat } from 'utils/helpers'

const options = {
  search: false,
  print: false,
  viewColumns: false,
  pagination: false,
  rowHover: false,
  sort: false,
  download: false,
  filter: false,
  searchOpen: false,
  selectableRows: 'none',
}
const sharedColumnOptions = {
  filter: false,
  sort: false,
  searchable: false,
}

const TopSpendersTable = ({ data }) => {
  const tableData = data?.map(({ sum, anr, ...otherData }) => {
    return {
      user: {
        ...otherData,
      },
      sum: convertKbToGb(sum),
      anr: phoneNumberFormat(anr),
    }
  })

  const columns = [
    {
      name: 'user',
      label: 'User',
      align: 'left',
      options: {
        ...sharedColumnOptions,
        customBodyRender: ({ firstName, lastName, email, requiresAzureLink }) => (
          <UserWithAvatar
            name={`${lastName}, ${firstName}`}
            email={email}
            requiresAzureLink={requiresAzureLink}
          />
        ),
      },
    },
    {
      name: 'anr',
      label: 'Mobile',
      align: 'left',
      options: {
        ...sharedColumnOptions,
      },
    },
    {
      name: 'sum',
      label: 'Data usage',
      align: 'left',
      options: {
        ...sharedColumnOptions,
      },
    },
  ]

  return <MuiDataTable options={options} data={tableData} columns={columns} />
}

export default TopSpendersTable
