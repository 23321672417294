import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, Grid } from '@material-ui/core'
import { IMG_TDC_LOGO_BUSSINESS } from 'constants/cdn'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    height: 63,
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: theme.zIndex.appBar,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.08), 0px 4px 5px 0px rgba(0, 0, 0, 0), 0px 1px 10px 0px rgba(0, 0, 0, 0)',
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
  },
  logoAndText: {
    width: 108,
    textAlign: 'right',
  },
  button: {
    border: 'none',
    background: 'none',
  },
}))

const Logo = () => {
  const classes = useStyles()
  return <img src={IMG_TDC_LOGO_BUSSINESS} alt="TDC Erhverv logo" className={classes.logoAndText} />
}

const AppBar = ({ middle, right, left, logoRedirect }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        {logoRedirect ? (
          <button onClick={logoRedirect} type="button" className={classes.button}>
            <Logo />
          </button>
        ) : (
          <Logo />
        )}
        {left && left}
        {middle && (
          <Grid container justify="center">
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Grid item xs={12}>
                  {middle}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {right && right}
      </Toolbar>
    </div>
  )
}

export default AppBar
