import React, { useEffect } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { Typography, Slide } from '@material-ui/core'
import { DrawerSection } from 'components'
import { useTranslation } from 'react-i18next'
import DrawerButtons from '../components/DrawerButtons'

const MissingAzureId = ({ user, changeRoute, title }) => {
  const { t } = useTranslation()
  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
  }, [])

  const cancelSubscriptionRedirect = () => {
    changeRoute('nedlaeg-abonnementer')
  }

  const goBack = () => {
    changeRoute()
  }

  return (
    <Slide direction="left" in>
      <div>
        <DrawerSection title={t('Drawer_azure_cant_find')}>
          <Typography>
            {t('Drawer_azure_cant_find_desc', {
              name: (user.name && user.name.length > 2) || t('Thisuser'),
            })}
          </Typography>
        </DrawerSection>
        <DrawerButtons
          backLink={goBack}
          onSubmit={cancelSubscriptionRedirect}
          submitText={t('Drawer_azure_cant_find_title')}
        />
      </div>
    </Slide>
  )
}

export default MissingAzureId
