import React, { useState, useEffect } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSelector, shallowEqual, useDispatch, batch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { createSubscription } from 'services/sweb/subscription'
import { fetchUnassignedSimcards } from 'state/unassignedSimcards/actions'
import { fetchSubscription } from 'state/subscription/actions'
import { fetchAdmin } from 'state/admins/actions'
import { fetchSimcardOrders } from 'state/simcardOrders/actions'
import { PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import OrderSimcardForm from './OrderSimcardForm'

const OrderSimcard = ({ user, subscription, customerId, changeRoute, title }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const stored = useSelector(
    (state) => ({
      customer: state.customer,
      unassignedSimcards: state.unassignedSimcards,
      simcardOrders: state.simcardOrders,
      meEmail: state.me.data?.user?.email,
      admins: state.admins,
    }),
    shallowEqual
  )
  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    batch(() => {
      dispatch(fetchSubscription({ customerId, abbId: subscription.abb_id }))
      dispatch(fetchUnassignedSimcards({ customerId }))
      dispatch(fetchAdmin({ customerId }))
      dispatch(
        fetchSimcardOrders({
          customerId,
          mobileNumber: subscription.mobile_number,
        })
      )
    })
  }, [])

  useEffect(() => {
    if (
      stored.simcardOrders.mobileNumber === subscription.mobile_number &&
      !stored.simcardOrders.loading &&
      stored.simcardOrders.data &&
      stored.simcardOrders.data.new_simcard
    ) {
      enqueueSnackbar(t('Drawer_simcard_active_order'), {
        variant: 'info',
      })
      changeRoute()
    }
  }, [stored.simcardOrders])

  const orderSimcard = (json) => {
    setLoading(true)
    createSubscription
      .orderSimcard({ customerId, mobileNumber: subscription.mobile_number, json })
      .then(() => {
        enqueueSnackbar(
          t(json.sim_order.simtype === 8 ? 'Drawer_esim_success' : 'Drawer_simcard_success'),
          {
            variant: 'success',
          }
        )
        changeRoute()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }
  const simcardOrdersResolved = Boolean(
    stored.simcardOrders.error === undefined ||
      stored.simcardOrders.data ||
      stored.simcardOrders.data === undefined
  )

  let content = <PaperLoader />

  if (simcardOrdersResolved && stored.unassignedSimcards.data) {
    content = (
      <OrderSimcardForm
        loading={loading}
        admins={stored.admins}
        noneAd={subscription?.abb_base_type === 798}
        orderSimcard={orderSimcard}
        backLink={changeRoute}
        address={stored.customer.data.address}
        unassignedSimcards={stored.unassignedSimcards.data}
        recipient={user.requiresAzureLink ? user.email : stored.meEmail || ''}
        attention={user.name}
      />
    )
  }

  return content
}

export default OrderSimcard
