import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { UserWithAvatar } from 'components'
import { Delete as DeleteIcon } from '@material-ui/icons'

const NumberTableRow = ({ firstName, lastName, email, azureUserId, openDeleteDialog }) => {
  const name = firstName ? `${firstName} ${lastName}` : azureUserId
  return (
    <TableRow>
      <TableCell>
        <UserWithAvatar name={name} email={email} />
      </TableCell>
      <TableCell
        onClick={openDeleteDialog}
        padding="none"
        align="right"
        style={{ paddingRight: 8, maxWidth: 50, width: 50 }}
      >
        <IconButton>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default NumberTableRow
