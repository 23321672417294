import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { updateSubscription } from 'services/sweb/subscription'
import { fetchUnassignedSimcards } from 'state/unassignedSimcards/actions'
import { PaperLoader, PaperContent, PaperFeedbackMessage, FloatingButton, Paper } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import SimcardTable from './components/SimcardTable'
import OrderFormContainer from './components/OrderFormContainer'

const useStyles = makeStyles((theme) => ({
  intro: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

const UnassignedSimcards = ({ match, history }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const unassignedSimcards = useSelector((state) => state.unassignedSimcards)

  const openDrawer = () => {
    history.push(`${match.url}/bestil`)
  }

  useEffect(() => {
    dispatch(fetchUnassignedSimcards({ customerId: match.params.customer_id }))
  }, [])

  const reportSimcardMissing = (id) => {
    setLoading(id)
    updateSubscription
      .reportUnassignedSimcardMissing({ customerId: match.params.customer_id, id })
      .then(() => {
        enqueueSnackbar(t('Organization_unassignedsimcards_blocked'), { variant: 'success' })
        dispatch(fetchUnassignedSimcards({ customerId: match.params.customer_id }))
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  let content = <PaperLoader />

  if (unassignedSimcards.error && unassignedSimcards.error.statusText) {
    content = (
      <PaperFeedbackMessage
        message={unassignedSimcards.error.statusText}
        type="error"
        style={{ marginTop: 24 }}
        withMargin
        disableGutters={false}
      />
    )
  }

  if (unassignedSimcards.data && !unassignedSimcards.data.reserved_sim_cards) {
    content = (
      <PaperFeedbackMessage
        message={t('Organization_unassignedsimcards_locked')}
        style={{ marginTop: 24 }}
        withMargin
        disableGutters={false}
      />
    )
  }

  if (unassignedSimcards.data && unassignedSimcards.data.reserved_sim_cards) {
    content = (
      <>
        {unassignedSimcards.data.reserved_sim_cards.length > 0 ? (
          <SimcardTable
            apiData={unassignedSimcards.data.reserved_sim_cards}
            loading={loading}
            reportSimcardMissing={reportSimcardMissing}
          />
        ) : (
          <PaperFeedbackMessage
            message={t('Organization_unassignedsimcards_none')}
            withMargin
            style={{ marginTop: 24 }}
            disableGutters={false}
          />
        )}
        <FloatingButton tooltip={t('Organization_unassignedsimcards_order')} onClick={openDrawer} />
        <Route
          component={OrderFormContainer}
          exact
          path="/:customer_id/organisation/skuffesimkort/bestil"
        />
      </>
    )
  }

  return (
    <Paper>
      <PaperContent noPadding>
        <Typography variant="body1" className={classes.intro}>
          {t('Organization_unassignedsimcards_description')}
        </Typography>
        {content}
      </PaperContent>
    </Paper>
  )
}

export default UnassignedSimcards
