import utilize, { ConnT } from './utilize'
import { getBearerNames, cleanStoredBearersExcept } from './util/helpers'

type DoRet = {
  add: (name: string, connection: ConnT) => DoRet
  export: () => { [k: string]: ReturnType<typeof utilize> }
}

const doReturn = (returning = {}) => ({ ...returning })

export default (() => {
  let exportedOnce = false
  const addObj = {}

  const exportFn = () => {
    const addObjKeys = Object.keys(addObj)
    if (exportedOnce) {
      throw new Error('".export()" may only occur once')
    }
    if (addObjKeys.length > 0 === false) {
      throw new Error('".add()" must be called atleast once before export')
    }
    const names = Object.keys(addObj)
    const bearerNames = getBearerNames(names)
    cleanStoredBearersExcept(bearerNames)
    exportedOnce = true
    return doReturn(addObj)
  }

  const addFn = (name: string, connection: ConnT) => {
    const addObjKeys = Object.keys(addObj)
    if (addObjKeys.includes(name)) {
      throw new Error(`".add()" having "${name}" does already exist`)
    }
    const addItem = utilize(name, connection)
    Object.assign(addObj, { [name]: addItem })
    return doReturn({ add: addFn, export: exportFn }) as DoRet
  }

  return doReturn({ add: addFn, export: exportFn }) as DoRet
})()
