import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
  },
}))

interface Props {
  className?: string
  children: JSX.Element | JSX.Element[]
}

const PaperToolbar = ({ className, children }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
    },
    className
  )

  return <div className={rootClassName}>{children}</div>
}

export default PaperToolbar
