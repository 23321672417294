import palette from '../palette'

export default {
  root: {
    marginBottom: '16px',
  },
  icon: {
    '&:hover': {
      color: palette.text.secondary,
    },
  },
  actions: {
    flex: '0 0 auto',
    marginLeft: '16px',
  },
}
