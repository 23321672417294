import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DialogActions, Button } from '@material-ui/core'
import { GradientButton } from 'components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginBottom: theme.spacing(4),
  },
}))

const DrawerButtons = ({
  withSubmitButton = true,
  withBackButton = true,
  backLink,
  disabled,
  onSubmit,
  backText = '',
  submitText,
  loading = false,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleBackLink = () => {
    if (backLink) backLink()
  }

  return (
    <DialogActions className={classes.buttons}>
      {withBackButton && (
        <Button color="primary" onClick={handleBackLink} disabled={Boolean(loading)}>
          {backText || t('Drawer_buttons_back')}
        </Button>
      )}
      {withSubmitButton && (
        <GradientButton onClick={onSubmit} disabled={disabled} loading={loading}>
          {submitText}
        </GradientButton>
      )}
    </DialogActions>
  )
}

export default DrawerButtons
