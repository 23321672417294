import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'azureDomains/FETCH_FAILURE'
export const FETCH_BEGIN = 'azureDomains/FETCH_BEGIN'
export const FETCH_SUCCESS = 'azureDomains/FETCH_SUCCESS'

export const fetchAzureDomainsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAzureDomainsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchAzureDomainsSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchAzureDomains = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchAzureDomainsBegin({ customerId }))
    try {
      const response = await getCustomer.azureDomains({
        customerId,
      })
      dispatch(fetchAzureDomainsSuccess(response.data))
    } catch (err) {
      dispatch(fetchAzureDomainsFailure(err.response))
    }
  }
}
