import { getMe } from 'services/sweb/me'

export const FETCH_CLEAN = 'ME/FETCH_CLEAN'
export const FETCH_FAILURE = 'ME/FETCH_FAILURE'
export const FETCH_BEGIN = 'ME/FETCH_BEGIN'
export const FETCH_SUCCESS = 'ME/FETCH_SUCCESS'

export const fetchMeClean = () => {
  return {
    type: FETCH_CLEAN,
  }
}

export const fetchMeFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchMeBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchMeSuccess = (me) => {
  return {
    type: FETCH_SUCCESS,
    payload: { me: me.data },
  }
}

export const cleanMe = () => (dispatch) => {
  dispatch(fetchMeClean())
}

export const fetchMe = () => {
  return async (dispatch) => {
    dispatch(fetchMeBegin())
    try {
      const result = await getMe()
      dispatch(fetchMeSuccess(result))
    } catch (err) {
      dispatch(fetchMeFailure(err.response))
    }
  }
}
