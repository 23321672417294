import React from 'react'
import classNames from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  paddingLeft: {
    paddingLeft: `${theme.spacing()}px !important`,
  },
  tableHeadCell: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  tr: {
    backgroundColor: 'transparent',
    borderBottom: 0,
  },
  noPaddingLeft: {
    paddingLeft: 0,
  },
  sortingActive: {
    color: `${theme.palette.primary.main} !important`,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
}))

export type Order = 'asc' | 'desc'

export interface HeadCell {
  disablePadding: boolean
  id: string
  label?: string
  numeric: boolean
  paddingLeft: boolean
  sortable: boolean
}

export interface TableHeadProps {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void
  order?: Order
  orderBy?: string
  utilityIcon?: JSX.Element
  headCells: HeadCell[]
}

const EnhancedTableHead = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
  utilityIcon,
}: TableHeadProps) => {
  const classes = useStyles()

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    return onRequestSort && onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow className={classes.tr}>
        {utilityIcon && (
          <TableCell padding="none" style={{ width: 10 }}>
            {utilityIcon}
          </TableCell>
        )}
        {headCells.map((headCell, index) => {
          const sortingActive = orderBy === headCell.id
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              className={classNames(classes.tableHeadCell, {
                [classes.paddingLeft]: headCell.paddingLeft,
                [classes.noPaddingLeft]: index === 0 && utilityIcon,
              })}
              sortDirection={sortingActive ? order : false}
            >
              {headCell.sortable ? (
                <TableSortLabel
                  classes={{
                    active: classes.sortingActive,
                    icon: classes.sortingActive,
                  }}
                  active={sortingActive}
                  direction={sortingActive ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
