import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List } from '@material-ui/core'
import { UtilityInput } from '@skytdc/mui/components'
import { KeyboardArrowRightRounded as RightIcon } from '@material-ui/icons'
import { UserWithAvatar } from 'components'
import { useTranslation } from 'react-i18next'
import matchSorter from 'match-sorter'

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    paddingTop: theme.spacing(0.4),
    paddingBottom: 0,
    padding: theme.spacing(2),
  },
  userContainer: {
    maxHeight: '143px',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  chevronRight: {
    color: theme.palette.text.secondary,
  },
}))

const UserSearch = ({ users, action, setAction, changePage }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [searchTerm, setSearchTerm] = useState('')

  const goToUser = (userId) => {
    changePage(`brugere/${userId}/${action}`)
    setAction(null)
  }

  const searchedUsers = matchSorter(users, searchTerm, {
    keys: ['name', 'email', 'mobileNumber', 'teamsDisplayNumber', 'azureId', 'userId'],
  })

  return (
    <>
      <div className={classes.searchContainer}>
        <UtilityInput
          onChange={(value: string) => setSearchTerm(value)}
          value={searchTerm}
          placeholder={t('Organization_roles_user_search')}
        />
      </div>
      <List className={classes.userContainer}>
        {searchedUsers.map((user) => {
          return (
            <UserWithAvatar
              onClick={() => goToUser(user.certainId)}
              key={user.certainId}
              name={user.name}
              email={user.email}
              isButton
              avatar={user.avatar}
            >
              <RightIcon edge="end" className={classes.chevronRight} />
            </UserWithAvatar>
          )
        })}
      </List>
    </>
  )
}

export default UserSearch
