import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'huntgroup/FETCH_FAILURE'
export const FETCH_BEGIN = 'huntgroup/FETCH_BEGIN'
export const FETCH_SUCCESS = 'huntgroup/FETCH_SUCCESS'

export const fetchHuntgroupFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchHuntgroupBegin = ({ number }) => {
  return {
    type: FETCH_BEGIN,
    number,
  }
}

export const fetchHuntgroupuccess = ({ number, data }) => {
  return {
    type: FETCH_SUCCESS,
    number,
    data,
  }
}

export const fetchHuntgroup = ({ customerId, number }) => {
  return async (dispatch) => {
    dispatch(fetchHuntgroupBegin({ number }))
    try {
      const response = await getCustomer.huntgroup({
        customerId,
        number,
      })
      dispatch(fetchHuntgroupuccess({ number, data: response.data }))
    } catch (err) {
      dispatch(fetchHuntgroupFailure(err.response))
    }
  }
}
