import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'skyAppContacts/FETCH_FAILURE'
export const FETCH_BEGIN = 'skyAppContacts/FETCH_BEGIN'
export const FETCH_SUCCESS = 'skyAppContacts/FETCH_SUCCESS'

export const fetchSkyAppContactsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchSkyAppContactsBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchSkyAppContactsSuccess = ({ data }) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchSkyAppContacts = () => {
  return async (dispatch) => {
    dispatch(fetchSkyAppContactsBegin())
    try {
      const response = await getSubscription.contacts()
      dispatch(fetchSkyAppContactsSuccess({ data: response.data }))
    } catch (err) {
      dispatch(fetchSkyAppContactsFailure(err.response))
    }
  }
}
