import React, { useState } from 'react'
import { GradientButton } from 'components'
import { useTranslation, Trans } from 'react-i18next'
import StepContainer from '../StepContainer'
import TextBlock from '../TextBlock'
import SecretPlaceholder from '../SecretPlaceholder'
import AdminUserModal from '../AdminUserModal'
import SecretsContainer from '../SecretsContainer'

const AdminUser = ({
  tenant,
  customerId,
  fetchSecrets,
  deleteSecret,
  currentStep,
  setCurrentStep,
  loading,
  secret,
}) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const openAdminUserModal = () => {
    if (!loading) setModalOpen(true)
  }

  const handleCloseDialog = () => {
    setModalOpen(false)
  }

  const handleSetCurrentStep = () => {
    setCurrentStep(currentStep + 1)
  }

  return (
    <>
      <StepContainer step={currentStep} requiredStep={2} stepLabel={t('Wizard_00shtr')}>
        <TextBlock>{t('Wizard_feawerga')}</TextBlock>
        <TextBlock>
          <Trans i18nKey="Wizard_00esg" />
        </TextBlock>
        <TextBlock>{t('Wizard3544532_')}</TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Wizard_gesr9')}</li>
            <li>{t('Wizard_serg00')}</li>
          </ul>
        </TextBlock>
        <TextBlock inputMargin>
          <Trans i18nKey="Wizardjydt_" components={[<strong>Tilføj</strong>]} />
        </TextBlock>
        <SecretsContainer
          loading={loading}
          secret={secret}
          deleteSecret={deleteSecret}
          type="admin"
        />
        <SecretPlaceholder onClick={openAdminUserModal} hasSecrets={Boolean(secret)} />
        <GradientButton disabled={currentStep > 2 || !secret} onClick={handleSetCurrentStep}>
          {t('Hardware_next')}
        </GradientButton>
      </StepContainer>
      {modalOpen && (
        <AdminUserModal
          isOpen
          closeDialog={handleCloseDialog}
          tenant={tenant}
          hasSecrets={Boolean(secret)}
          customerId={customerId}
          fetchSecrets={fetchSecrets}
        />
      )}
    </>
  )
}

export default AdminUser
