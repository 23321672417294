import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, ButtonGroup } from '@material-ui/core'
import { Paper, GradientButton } from 'components'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { fetchPresenceProduct } from 'state/presenceProduct/actions'

const useStyles = makeStyles((theme) => ({
  intro: {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    padding: theme.spacing(3),
  },
}))

const AzureConnection = ({ history, match }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const availablePresenceProduct = useSelector(
    (state) => state.presenceProduct.data?.available_presence_product
  )

  useEffect(() => {
    dispatch(fetchPresenceProduct({ customerId: match.params.customer_id }))
  }, [])

  const goToSetup = (page: string) => {
    history.push(`/${match.params.customer_id}/${page}`)
  }

  return (
    <Paper>
      <Typography variant="body1" className={classes.intro}>
        <Trans i18nKey="Organization_azure_description" />
      </Typography>
      <ButtonGroup disableElevation variant="outlined">
        <GradientButton className={classes.button} onClick={() => goToSetup('setup')}>
          {t('Organization_azure_cta')}
        </GradientButton>
        <GradientButton
          disabled={!availablePresenceProduct}
          className={classes.button}
          onClick={() => goToSetup('presence-setup')}
        >
          {t('Organization_presence_cta')}
        </GradientButton>
      </ButtonGroup>
    </Paper>
  )
}

export default AzureConnection
