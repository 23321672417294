import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.border}`,
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px',
  },
  noPadding: {
    padding: 0,
  },
  noDivider: {
    borderTop: 'none',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const PaperFooter = (props) => {
  const classes = useStyles()
  const { className, noDivider, alignRight, noPadding, children, ...rest } = props

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noDivider]: noDivider,
      [classes.alignRight]: alignRight,
      [classes.noPadding]: noPadding,
    },
    className
  )

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}

export default PaperFooter
