import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'dataUsages/FETCH_FAILURE'
export const FETCH_BEGIN = 'dataUsages/FETCH_BEGIN'
export const FETCH_SUCCESS = 'dataUsages/FETCH_SUCCESS'

export const fetchDataUsagesFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchDataUsagesBegin = ({ abbId }) => {
  return {
    type: FETCH_BEGIN,
    abbId,
  }
}

export const fetchDataUsagesSuccess = ({ data, abbId }) => {
  return {
    type: FETCH_SUCCESS,
    abbId,
    data,
  }
}

export const fetchDataUsages = ({ customerId, abbId }) => {
  return async (dispatch) => {
    dispatch(fetchDataUsagesBegin({ abbId }))
    try {
      const response = await getSubscription.dataUsages({
        customerId,
        abbId,
      })
      dispatch(fetchDataUsagesSuccess({ abbId, data: (response as { data }).data }))
    } catch (err) {
      dispatch(fetchDataUsagesFailure(err.response))
    }
  }
}
