import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'subscriptionCount/FETCH_FAILURE'
export const FETCH_BEGIN = 'subscriptionCount/FETCH_BEGIN'
export const FETCH_SUCCESS = 'subscriptionCount/FETCH_SUCCESS'

export const fetchSubscriptionCountFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchSubscriptionCountBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchSubscriptionCountSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchSubscriptionCount = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchSubscriptionCountBegin({ customerId }))
    try {
      const response = await getCustomer.subscriptionCount({ customerId })
      dispatch(fetchSubscriptionCountSuccess(response.data))
    } catch (err) {
      dispatch(fetchSubscriptionCountFailure(err.response))
    }
  }
}
