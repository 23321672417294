import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch, batch } from 'react-redux'
import { fetchUsers } from 'state/users/actions'
import { fetchDataPool } from 'state/dataPool/actions'
import { fetchAdGroupFilters } from 'state/adGroupFilters/actions'
import { fetchAvailableLandlineNumbers } from 'state/availableLandlineNumbers/actions'
import { fetchAvailableInternationalNumbers } from 'state/availableInternationalNumbers/actions'
import { fetchUnassignedSimcards } from 'state/unassignedSimcards/actions'
import { fetchInvoice } from 'state/invoice/actions'
import { fetchSubscriptionCount } from 'state/subscriptionCount/actions'
import { fetchZendeskTickets } from 'state/zendeskTickets/actions'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { Grid } from '@material-ui/core'
import { PageTitle } from '@skytdc/mui/components'
import hasRoles from 'utils/hasRoles'
import { useTranslation } from 'react-i18next'
import Layout from '../Layout'
import UsersWidget from './components/UsersWidget'
import InvoiceWidget from './components/InvoiceWidget'
import DataPool from './components/DataPool'
import MostUsedFunctions from './components/MostUsedFunctions'
import Subscriptions from './components/Subscriptions'
import StockWidget from './components/StockWidget'
import ZendeskTickets from './components/ZendeskTickets'
import CloudAdoptionWidget from './components/CloudAdoptionWidget'

const Dashboard = ({ title, match, history }) => {
  const { params } = match
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const stored = useSelector(
    (state) => ({
      me: state.me,
      customer: state.customer,
      broadworks: state.customer.data?.customer_properties?.broadworks,
      users: state.users,
      dataPool: state.dataPool,
      hasDataPool: state.customer.data?.has_datapool,
      landline: state.availableLandlineNumbers,
      intNumbers: state.availableInternationalNumbers,
      unassignedSimcards: state.unassignedSimcards,
      invoice: state.invoice,
      subscriptionCount: state.subscriptionCount,
      zendeskTickets: state.zendeskTickets,
      adGroupFilters: state.adGroupFilters,
      ski: state.customer?.data?.customer_properties?.ski,
    }),
    shallowEqual
  )

  const showInvoiceWidget = hasRoles(stored.me.data, params.customer_id, ['invoice'])
  const showWidgets = hasRoles(stored.me.data, params.customer_id, [
    'customer_role_admin',
    'customer_admin',
  ])
  const usersCustomerIdsMatch = Boolean(stored.users.customerId === params.customer_id)
  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    if (showWidgets) {
      if (stored.hasDataPool) {
        dispatch(fetchDataPool({ customerId: params.customer_id }))
      }
      if (!usersCustomerIdsMatch || (usersCustomerIdsMatch && !stored.users.loading)) {
        dispatch(fetchUsers({ customerId: params.customer_id }))
      }
      batch(() => {
        dispatch(
          fetchAvailableLandlineNumbers({
            customerId: params.customer_id,
            oneNumberType: null,
          })
        )
        dispatch(fetchAvailableInternationalNumbers({ customerId: params.customer_id }))
        dispatch(fetchUnassignedSimcards({ customerId: params.customer_id }))
        dispatch(fetchSubscriptionCount({ customerId: params.customer_id }))
        dispatch(fetchZendeskTickets({ customerId: params.customer_id }))
        dispatch(fetchAdGroupFilters({ customerId: params.customer_id }))
      })
    }
    if (showInvoiceWidget) {
      dispatch(fetchInvoice({ customerId: params.customer_id }))
    }
  }, [])

  const changePage = (page) => {
    history.push(`/${params.customer_id}/${page}`)
  }

  return (
    <Layout>
      <PageTitle title={title} />
      <Grid container spacing={4}>
        {showWidgets && (
          <>
            <Grid item lg={stored.broadworks ? 6 : 4} sm={6} xs={12}>
              <UsersWidget
                users={stored.users}
                groupFilters={stored.adGroupFilters && stored.adGroupFilters.data}
                changePage={changePage}
              />
            </Grid>
            {!stored.broadworks && (
              <Grid item lg={4} sm={6} xs={12}>
                <MostUsedFunctions users={stored.users} changePage={changePage} />
              </Grid>
            )}
            <Grid item lg={stored.broadworks ? 6 : 4} sm={stored.broadworks ? 6 : 12} xs={12}>
              <StockWidget
                broadworks={stored.broadworks}
                landlineNumbers={stored.landline}
                intNumbers={stored.intNumbers}
                unassignedSimcards={stored.unassignedSimcards}
                intNumbersEnabled={Boolean(stored.customer.data.internation_numbers)}
              />
            </Grid>
            <Grid item lg={8} sm={12} xl={8} xs={12}>
              <Subscriptions subscriptionCount={stored.subscriptionCount} changePage={changePage} />
            </Grid>
          </>
        )}
        <Grid item lg={4} xs={12}>
          {stored.hasDataPool && (
            <DataPool
              title={t('Data_pool_title')}
              dataUsed={stored.dataPool?.data}
              customerId={params.customer_id}
            />
          )}
          {showInvoiceWidget && (
            <InvoiceWidget ski={stored.ski} invoice={stored.invoice} changePage={changePage} />
          )}
        </Grid>
        {showWidgets && (
          <>
            <Grid item lg={4} sm={12} xl={4} xs={12}>
              <CloudAdoptionWidget changePage={changePage} />
            </Grid>
            <Grid item lg={8} sm={12} xl={8} xs={12}>
              <ZendeskTickets tickets={stored.zendeskTickets} />
            </Grid>
          </>
        )}
      </Grid>
    </Layout>
  )
}

export default Dashboard
