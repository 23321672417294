import client from '../client'

export const getMe = () => {
  return client.get('users/me', { timeout: false }).json()
}

// following endpoints fetches subscription information about the logged in user (me)
export const subscription = ({ abbId }) => {
  return client.get(`users/subscription/${abbId}`).json()
}

export const profileImage = ({ abbId, size = '96x96' }) => {
  return client.get(`teams/profile-photo/${abbId}?size=${size}`).json()
}
export const licenseDetails = ({ abbId }) => {
  return client.get(`teams/users/${abbId}/license-details`).json()
}
export const simcards = ({ abbId }) => {
  return client.get(`mobile/user/${abbId}/simcards`).json()
}

// returns 204 if no orders, 404 if no subscription
export const simcardOrders = ({ abbId }) => {
  return client.get(`simcard/user/${abbId}`).json()
}

export const customer = ({ abbId }) => {
  return client.get(`customer/user/${abbId}/extended`).json()
}
