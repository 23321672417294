import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'presenceHealthCheck/FETCH_FAILURE'
export const FETCH_BEGIN = 'presenceHealthCheck/FETCH_BEGIN'
export const FETCH_SUCCESS = 'presenceHealthCheck/FETCH_SUCCESS'

export const fetchPresenceHealthCheckFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchPresenceHealthCheckBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchPresenceHealthCheckSuccess = (data, customerId) => {
  return {
    type: FETCH_SUCCESS,
    data,
    customerId,
  }
}

export const fetchPresenceHealthCheck = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchPresenceHealthCheckBegin({ customerId }))
    try {
      const response = await getCustomer.presenceHealthCheck({
        customerId,
      })
      dispatch(fetchPresenceHealthCheckSuccess(response.data, customerId))
    } catch (err) {
      dispatch(fetchPresenceHealthCheckFailure(err.response))
    }
  }
}
