import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { fetchEndUserSimcards } from 'state/endUserSimcards/actions'
import { fetchEndUserSimcardOrders } from 'state/endUserSimcardOrders/actions'
import { CustomDialog, GradientButton } from 'components'

import {
  SimCardOutlined as SimcardIcon,
  KeyboardArrowDown as NotCollapsedIcon,
  KeyboardArrowUp as CollapsedIcon,
  Code as CodeIcon,
  PowerSettingsNew as StatusIcon,
  InfoOutlined as InfoIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Collapse, Tooltip, Typography } from '@material-ui/core'
import DrawerListItem from 'components/DrawerListItem'
import useCopyToClipboard from 'theme/hooks/useCopyToClipboard'
import { useSnackbar } from 'notistack'
import ActiveSimcardOrder from 'views/Drawer/components/ActiveSimcardOrder'
import { updateSubscription } from 'services/sweb/subscription'
import { useTranslation } from 'react-i18next'
import DatashareSimcards from './DatashareSimcards'
import Esim from './Esim'

const useStyles = makeStyles((theme) => ({
  message: {
    paddingBottom: theme.spacing(2),
  },
  listOpen: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
  },
  infoIcon: {
    width: 15,
    height: 15,
    color: 'inherit',
    verticalAlign: 'text-top',
    marginTop: 1,
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
}))

const Simcards = ({ abbId }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [simcardsCollapsed, setSimcardsCollapsed] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const simcards = useSelector((state) => state.endUserSimcards)
  const simcardOrders = useSelector((state) => state.endUserSimcardOrders)
  const { copyFn, CopyPlaceholder } = useCopyToClipboard()

  const copyToClipboard = ({ value = '', appendMessage = '' }) => {
    copyFn({ value })
    enqueueSnackbar(`${t('Drawer_mypage_copy', { value })}${appendMessage}`)
  }

  useEffect(() => {
    batch(() => {
      dispatch(fetchEndUserSimcards({ abbId }))
      dispatch(fetchEndUserSimcardOrders({ abbId }))
    })
  }, [])

  const createLabel = () => {
    let label = t('Drawer_user_no_datashare')
    if (simcards.data.the_rest?.length > 0) {
      label =
        simcards.data.the_rest?.length > 1
          ? t('Drawer_user_datashare_amount', { number: simcards.data.the_rest?.length })
          : simcards.data.the_rest[0].icc
    }
    return label
  }

  const showSimcardData = simcards.data && !simcards.error
  const hasDataShareSimcards = showSimcardData && simcards.data.the_rest?.length > 0
  const simcardOrdersResolved = simcardOrders.error === undefined || simcardOrders.data

  const toggleDataShareSimcardInformation = () => {
    if (hasDataShareSimcards) {
      setSimcardsCollapsed(!simcardsCollapsed)
    }
  }

  const handleCloseModal = () => {
    if (!loading) setModalOpen(false)
  }

  const blockAndOrderSimcard = () => {
    setLoading(true)
    updateSubscription[simcards.data.primary.suspended ? 'unsuspendSim' : 'suspendSim'](abbId)
      .then(() => {
        enqueueSnackbar(
          t(
            simcards.data.primary.suspended
              ? 'Drawer_mypage_unblock_and_orer'
              : 'Drawer_mypage_block_and_orer'
          ),
          {
            variant: 'success',
          }
        )
        batch(() => {
          dispatch(fetchEndUserSimcards({ abbId }))
          dispatch(fetchEndUserSimcardOrders({ abbId }))
        })
        setModalOpen(false)
        setLoading(false)
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  return (
    <>
      <CopyPlaceholder />
      <DrawerListItem
        isButton
        icon={<SimcardIcon />}
        primaryText={t('Drawer_mypage_icc')}
        isLoading={Boolean(!simcards.data)}
        secondaryText={
          showSimcardData ? simcards.data.primary.icc : t('Drawer_landline_another_error')
        }
        linkAnchor={showSimcardData ? t('Drawer_mypage_copy_iicc') : ''}
        onClick={() => copyToClipboard({ value: simcards.data.primary.icc })}
      />
      {showSimcardData && (
        <>
          <DrawerListItem
            isButton
            icon={<StatusIcon />}
            primaryText={t('Drawer_mypage_simcard_status')}
            isLoading={Boolean(!simcards.data)}
            secondaryText={
              simcards.data.primary.suspended
                ? t('Drawer_mypage_blocked_awaiting')
                : t('Drawer_mypage_active')
            }
            linkAnchor={t(
              simcards.data.primary.suspended ? 'Drawer_mypage_unblock' : 'Drawer_mypage_block'
            )}
            onClick={() => setModalOpen(true)}
          />
          <DrawerListItem
            isButton
            icon={<CodeIcon />}
            primaryText={
              <>
                {t('Drawer_mypage_pin')}{' '}
                <Tooltip title={t('Drawer_mypage_pin_tooltip')}>
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </>
            }
            isLoading={Boolean(!simcards.data)}
            secondaryText={simcards.data.primary.pin_code}
            linkAnchor={t('Drawer_mypage_copy_pin')}
            onClick={() =>
              copyToClipboard({
                value: simcards.data.primary.pin_code,
                appendMessage: t('Drawer_mypage_pin_tooltip'),
              })
            }
          />
          <DrawerListItem
            isButton
            icon={<CodeIcon />}
            primaryText={t('Drawer_mypage_puk')}
            isLoading={Boolean(!simcards.data)}
            secondaryText={simcards.data.primary.puk_code}
            linkAnchor={t('Drawer_mypage_copy_puk')}
            onClick={() => copyToClipboard({ value: simcards.data.primary.puk_code })}
          />
        </>
      )}
      <DrawerListItem
        isButton={hasDataShareSimcards}
        icon={<SimcardIcon />}
        primaryText={t('Drawer_simcard_datashare')}
        secondaryText={
          simcards.data && !simcards.error ? createLabel() : t('Dashboard_stock_some_error')
        }
        isLoading={Boolean(!simcards.data)}
        onClick={toggleDataShareSimcardInformation}
        className={simcardsCollapsed ? classes.listOpen : ''}
      >
        {hasDataShareSimcards && (
          <>
            {t('Drawer_mypage_show_datashare')}
            <IconButton onClick={toggleDataShareSimcardInformation}>
              {simcardsCollapsed ? <CollapsedIcon /> : <NotCollapsedIcon />}
            </IconButton>
          </>
        )}
      </DrawerListItem>
      {hasDataShareSimcards && (
        <Collapse in={simcardsCollapsed}>
          <div>
            <DatashareSimcards simcards={simcards.data.the_rest} />
          </div>
        </Collapse>
      )}

      {simcardOrdersResolved && simcardOrders.data && simcardOrders.data.new_simcard && (
        <ActiveSimcardOrder order={simcardOrders.data} />
      )}
      <Esim
        qrCodeValue={simcards.data?.primary?.esimActivationCode}
        newEsimOrder={{
          activationCode: simcardOrders.data?.activation_code,
          identifier: simcardOrders.data?.identifier,
          loading: simcardOrders.loading,
        }}
      />
      {modalOpen && (
        <CustomDialog
          isOpen={modalOpen}
          onConfirm={
            <GradientButton onClick={blockAndOrderSimcard} loading={loading} color="inherit">
              {t(simcards.data.primary.suspended ? 'Drawer_mypage_unblock' : 'Drawer_mypage_awef')}
            </GradientButton>
          }
          onCancel={handleCloseModal}
          title={t('Drawer_mypage_rusure')}
          maxWidth="xs"
          contentText={
            <Typography className={classes.message}>
              {t(simcards.data.primary.suspended ? 'Drawer_mypage_info' : 'Drawer_mypage_warning')}
            </Typography>
          }
          confirmButtonText={t('Drawer_mypage_awef')}
        />
      )}
    </>
  )
}

export default memo(Simcards)
