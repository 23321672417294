import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'mobileDisplayNumber/FETCH_FAILURE'
export const FETCH_BEGIN = 'mobileDisplayNumber/FETCH_BEGIN'
export const FETCH_SUCCESS = 'mobileDisplayNumber/FETCH_SUCCESS'

export const fetchMobileDisplayNumberFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchMobileDisplayNumberBegin = ({ mobileNumber }) => {
  return {
    type: FETCH_BEGIN,
    mobileNumber,
  }
}

export const fetchMobileDisplayNumberSuccess = ({ mobileNumber, data }) => {
  return {
    type: FETCH_SUCCESS,
    mobileNumber,
    data,
  }
}

export const fetchMobileDisplayNumber = ({ customerId, mobileNumber }) => {
  return async (dispatch) => {
    dispatch(fetchMobileDisplayNumberBegin({ mobileNumber }))
    try {
      const response = await getSubscription.displayNumber({
        customerId,
        number: mobileNumber,
      })
      dispatch(fetchMobileDisplayNumberSuccess({ mobileNumber, data: response.data }))
    } catch (err) {
      dispatch(fetchMobileDisplayNumberFailure(err.response))
    }
  }
}
