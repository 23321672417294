import client from '../client'

export const subscriptionDetails = ({ customerId, invoiceId }) => {
  return client.get(`invoice/${customerId}/${invoiceId}/subscriptions/miscellaneous`).json()
}

export const extraInfoAndReference = ({ customerId, json }) => {
  return client.put(`invoice/${customerId}/subscription`, { json }).json()
}

export const numberExtraInfoAndReference = ({ customerId, json }) => {
  return client.put(`invoice/${customerId}/activenumber/updatepluslink`, { json }).json()
}

// {"items":[{"ani":{"ani":145308,"description":"CC hmm"},"reference":{"description":"","reference_name":"akiphone5","reference_id":4101}}]}
