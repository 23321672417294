import client from '../client'

export const subscription = ({ customerId, abbId }) => {
  return client.get(`users/${customerId}/subscriptions/${abbId}/`).json()
}

export const earliestCloseDate = ({ customerId, abbId }) => {
  return client.get(`subscriptions/${customerId}/${abbId}/earliest-close-date`).json()
}

export const showNumberInSkyapp = ({ customerId, mobileNumber }) => {
  return client.get(`users/${customerId}/oneapp/${mobileNumber}/contactinfo`).json()
}

export const mobileCallForward = ({ customerId, mobileNumber }) => {
  return client.get(`mobile/${customerId}/${mobileNumber}/forward-to`).json()
}

export const availableMobilePackages = ({ customerId, abbType }) => {
  return client
    .get(`user-services/${customerId}/flatrate-packages/3?subscription_type=${abbType}`)
    .json()
}

export const displayNumber = ({ customerId, number }) => {
  return client.get(`display-number/${customerId}/${number}/display-number`).json()
}

export const simcards = ({ customerId, mobileNumber }) => {
  return client.get(`mobile/${customerId}/${mobileNumber}/simcards`).json()
}

export const simcardOrders = ({ customerId, mobileNumber }) => {
  return client.get(`simcard/${customerId}/${mobileNumber}`).json()
}
export const dataPool = ({ customerId }) => {
  return client.get(`mobile/${customerId}/pool`).json()
}
export const dataPoolUsage = ({ customerId }) => {
  return client.get(`mobile/${customerId}/data-usage`).json()
}
export const dataPoolTopSpenders = ({ customerId }) => {
  return client.get(`mobile/${customerId}/top-data-spenders`).json()
}
export const presenceProduct = ({ customerId }) => {
  return client.get(`teams/${customerId}/presence-product`).json()
}

export const landlinePrivateNumber = ({ customerId, landlineNumber }) => {
  return client.get(`cirpack/${customerId}/${landlineNumber}/private-number`).json()
}

export const guardApp = ({ customerId, abbId }) => {
  return client.get(`subscriptions/${customerId}/${abbId}/safe-profile`).json()
}

export const sharedNumbers = ({ customerId, abbId }) => {
  return client.get(`display-number/${customerId}/${abbId}/shared-numbers`).json()
}

export const transferSubscription = ({ customerId, abbId }) => {
  return client.get(`subscriptions/move/${customerId}/${abbId}`).json()
}
export const dataUsages = ({ customerId, abbId }) => {
  return client.get(`users/${customerId}/${abbId}/data-usage`).json()
}
export const myDataUsages = ({ customerId, abbId }) => {
  return client.get(`users/${customerId}/${abbId}/my-data-usage`).json()
}
export const contacts = () => {
  return client.get('users/contacts/get', { timeout: false }).json()
}
export const huntgroups = (abbId: string) => {
  return client.get(`users/huntgroups/${abbId}`).json()
}

export const huntgroupLogin = (abbId: string, huntgroupId: string) => {
  return client.get(`users/huntgroups/${abbId}/login/${huntgroupId}`).json()
}
export const huntgroupLogout = (abbId: string, huntgroupId: string) => {
  return client.get(`users/huntgroups/${abbId}/logout/${huntgroupId}`).json()
}

export const displayNumberActive = (phoneNumber: string) => {
  return client.get(`users/displayNumber/active/${phoneNumber}`).json()
}
export const displayNumberAvailable = (phoneNumber: string) => {
  return client.get(`users/displayNumber/available/${phoneNumber}`).json()
}
export const displayNumberWeeklySchedule = (phoneNumber: string) => {
  return client.get(`users/displayNumber/weeklySchedule/${phoneNumber}`).json()
}

export const addons = ({ customerId, abbId }) => {
  return client
    .get(`${customerId}/${abbId}/addons/subscriptions`)
    .json()
    .then((response: { data: any }) => {
      if (response?.data && response.data.products && response.data.products.length > 0) {
        response.data.addons = []
        response.data.products.forEach((category) => {
          if (category.addon_products.length > 0) {
            category.addon_products.forEach((addon) => {
              response.data.addons.push({
                id: addon.product_id,
                name: addon.product_name,
                price: addon.price,
                category: addon.category,
                description:
                  (addon.product_id === 528 && 'Fri tale fra DK til EU') ||
                  (addon.product_id === 460 && 'Besvar kald fra køer og meget mere') ||
                  '',
                requirement:
                  (addon.product_id === 528 && 'Kræver mobilabonnement') ||
                  (addon.product_id === 460 && 'Kræver Teams-telefoni') ||
                  '',
                active: Boolean(
                  response.data.subscriptions.find(
                    (activeAddon) => activeAddon.product_id === addon.product_id
                  )
                ),
                subscription: response.data.subscriptions.find(
                  (activeAddon) => activeAddon.product_id === addon.product_id
                ),
              })
            })
          }
        })
      } else {
        response.data.addons = []
      }
      return response
    })
}
