import React from 'react'
import { PaperFeedbackMessage, PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import TableWithFilteredTotal from '../../components/TableWithFilteredTotal'

const SpecificationsTable = ({ data, invoiceId }) => {
  const { t } = useTranslation()
  const invoiceIdsMatch = Boolean(data.invoiceId === invoiceId)

  const columns = [
    {
      name: 'anr',
      label: t('Invoice_from_number'),
      align: 'left',
      options: {
        sortDirection: 'asc',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'bnr',
      label: t('Invoice_receiver'),
      align: 'left',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: t('Invoice_description'),
      align: 'left',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'start_date',
      label: t('Invoice_datetime'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'volumen.formatted',
      label: 'Volume',
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'duration.formatted',
      label: t('Invoice_duration'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value === '00:00:00') return null
          return value
        },
      },
    },
  ]

  if (data.error && invoiceIdsMatch) {
    return (
      <PaperFeedbackMessage
        message={t('Invoice_error', { error: data.error.statusText })}
        type="error"
        withMargin
      />
    )
  }

  if (data.data && data.data.items.length === 0 && invoiceIdsMatch) {
    return <PaperFeedbackMessage message={t('Invoice_no_occurences')} withMargin />
  }

  if (data.data && data.data.items.length > 0 && invoiceIdsMatch) {
    const totalPrice = data.data.items
      .map((item) => item.unparsed_charge)
      .reduce((calculatedPrice, currentPrice) => calculatedPrice + currentPrice)

    return (
      <TableWithFilteredTotal
        items={data.data.items}
        firstTableColumns={columns}
        totalPrice={totalPrice}
      />
    )
  }

  return <PaperLoader />
}

const hasDataFromCurrentInvoice = (prev) => {
  return Boolean(prev.data.data !== null && prev.invoiceId === prev.data.invoiceId)
}

export default React.memo(SpecificationsTable, hasDataFromCurrentInvoice)
