import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Tooltip } from '@material-ui/core'
import { Input as InputIcon } from '@material-ui/icons'
import * as authenticate from 'services/sweb/authentication'
import { cleanMe } from 'state/me/actions'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  signOutButton: {
    padding: 7,
    marginRight: 6,
  },
  icon: {
    height: 18,
    width: 18,
  },
}))

const SignOut = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const signOutClick = () => {
    authenticate.removeBearer()
    dispatch(cleanMe())
  }

  return (
    <Tooltip title={t('Layout_logout')} arrow>
      <IconButton edge="end" onClick={signOutClick} className={classes.signOutButton}>
        <InputIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  )
}

export default SignOut
