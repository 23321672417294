import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'mobileCallForwarding/FETCH_FAILURE'
export const FETCH_BEGIN = 'mobileCallForwarding/FETCH_BEGIN'
export const FETCH_SUCCESS = 'mobileCallForwarding/FETCH_SUCCESS'

export const fetchMobileCallForwardingFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchMobileCallForwardingBegin = ({ mobileNumber }) => {
  return {
    type: FETCH_BEGIN,
    mobileNumber,
  }
}

export const fetchMobileCallForwardingSuccess = ({ mobileNumber, data }) => {
  return {
    type: FETCH_SUCCESS,
    mobileNumber,
    data,
  }
}

export const fetchMobileCallForwarding = ({ customerId, mobileNumber }) => {
  return async (dispatch) => {
    dispatch(fetchMobileCallForwardingBegin({ mobileNumber }))
    try {
      const response = await getSubscription.mobileCallForward({
        customerId,
        mobileNumber,
      })
      dispatch(fetchMobileCallForwardingSuccess({ mobileNumber, data: response.data }))
    } catch (err) {
      dispatch(fetchMobileCallForwardingFailure(err.response))
    }
  }
}
