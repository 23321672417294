import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useSelector, useDispatch, batch } from 'react-redux'
import { fetchDataPoolUsage } from 'state/dataPoolUsage/actions'
import { fetchdataPoolTopSpenders } from 'state/dataPoolTopSpenders/actions'
import { convertKbToGb, getMonth } from 'utils/helpers'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { PageTitle, Paper, PaperContent, PaperHeader, PaperTitle } from '@skytdc/mui/components'
import { Line } from 'react-chartjs-2'
import { Tabs, Tab, Grid, CircularProgress } from '@material-ui/core'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useTranslation } from 'react-i18next'
import { DonutChartWithText } from 'components'
import Layout from '../Layout'
import TopSpendersTable from './TopSpendersTable'

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginBottom: 24,
  },
  paperContent: {
    padding: 8,
  },
  loadingSpinner: {
    margin: '32px auto 64px',
  },
}))

const options = (dataUsed, t) => {
  return {
    animation: false,
    responsive: true,
    plugins: {
      tooltip: {
        padding: 12,
        callbacks: {
          label: ({ dataIndex }) => {
            const { dkeuDataUsage, dkeuDataPool } = dataUsed[dataIndex] || {}
            return t('DataPool_overview_tooltip', {
              dataUsage: convertKbToGb(dkeuDataUsage),
              dataPool: convertKbToGb(dkeuDataPool),
            })
          },
        },
      },
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: t('DataPool_overview_title'),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
    },
  }
}

interface MonthlyData {
  dataUsagePercent: string
  dkeuDataPool: string
  dkeuDataUsage: string
  yearMonth: string
}

const DataPoolUsage = ({ title, match, history }) => {
  const [value, setValue] = React.useState(0)
  const [monthlyData, setMonthlyData] = React.useState<MonthlyData>()
  const { params } = match
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const stored = useSelector(
    (state) => ({
      hasDataPool: state.customer.data?.has_datapool,
      dataPoolUsage: state.dataPoolUsage,
      dataPoolTopSpenders: state.dataPoolTopSpenders,
    }),
    shallowEqual
  )
  const { dataUsagePercent, dkeuDataPool, dkeuDataUsage, yearMonth } = monthlyData || {}
  const dataPoolUsage = stored.dataPoolUsage.data
  const dataUsage = dataPoolUsage?.map((data) => data.dataUsagePercent)

  const getYearMonth = (date: string, liveDate: boolean) => {
    const [year, month] = date?.split('-')

    return liveDate
      ? `${year}-${month}`
      : `${month === '01' ? year - 1 : year}-${month === '01' ? '12' : month - 1}`
  }
  const labels = dataPoolUsage?.map(({ date }, i) =>
    t(getMonth(getYearMonth(date, i === dataPoolUsage.length - 1)))
  )

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const data = {
    labels,
    datasets: [
      {
        label: t('Data_pool_title'),
        data: dataUsage,
        borderColor: '#0000bf',
        backgroundColor: '#0000bf',
      },
    ],
  }

  useEffect(() => {
    changeDocumentTitle(t(title), ' ', ':applicationName')
    if (stored.hasDataPool) {
      batch(() => {
        dispatch(fetchDataPoolUsage({ customerId: params.customer_id }))
        dispatch(fetchdataPoolTopSpenders({ customerId: params.customer_id }))
      })
    }
  }, [])

  if (!stored.hasDataPool) {
    history.push(`/${params.customer_id}/dashboard`)
  }

  const topDataSeperatedInMonth = useMemo(() => {
    const { TopDataSpendersOutputLists } = stored.dataPoolTopSpenders.data || {}
    if (!TopDataSpendersOutputLists) return {}
    const allTopDataSeperatedInMonth = TopDataSpendersOutputLists?.reduce(
      (acc, { TopDataSpendersOutput }, i) => {
        if (!TopDataSpendersOutput.length) return acc
        const date = getYearMonth(
          TopDataSpendersOutput[0]?.date,
          i === TopDataSpendersOutputLists.length - 1
        )

        acc[date] = [...TopDataSpendersOutput].sort((a, b) => Number(b.sum) - Number(a.sum))
        return acc
      },
      {}
    )

    return allTopDataSeperatedInMonth
  }, [stored.dataPoolTopSpenders.data])
  return (
    <Layout>
      <PageTitle title={t(title)} />
      <Paper className={classes.tabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label={t('DataPool_top_spenders_all_months')} onClick={() => setMonthlyData(null)} />
          {dataPoolUsage?.map(({ date, ...otherProps }, i) => {
            const yearMonth = getYearMonth(date, i === dataPoolUsage?.length - 1)
            return (
              <Tab
                key={i}
                label={t(getMonth(yearMonth))}
                onClick={() =>
                  setMonthlyData({
                    yearMonth,
                    ...otherProps,
                  })
                }
              />
            )
          })}
        </Tabs>
      </Paper>

      {monthlyData ? (
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Paper>
              <PaperHeader>
                <PaperTitle title={t('Data_pool_title')} />
              </PaperHeader>
              <PaperContent className={classes.paperContent}>
                <DonutChartWithText
                  chartData={[dataUsagePercent, 100 - dataUsagePercent]}
                  backgroundColor={[theme.palette.primary.main, theme.palette.common.neutral]}
                  title={`${convertKbToGb(dkeuDataUsage, 2)}`}
                  subTitle={`${t('Data_pool_out_of')} ${convertKbToGb(dkeuDataPool)}`}
                  textInsideDonut={`${dataUsagePercent}%`}
                />
              </PaperContent>
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper>
              <PaperHeader>
                <PaperTitle title={t('DataPool_top_spenders_title')} />
              </PaperHeader>
              {stored.dataPoolTopSpenders.loading ? (
                <CircularProgress size={40} className={classes.loadingSpinner} />
              ) : (
                <TopSpendersTable data={topDataSeperatedInMonth[yearMonth]} />
              )}
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Paper>
          <PaperContent>
            <Line data={data} options={options(dataPoolUsage, t)} />
          </PaperContent>
        </Paper>
      )}
    </Layout>
  )
}

export default DataPoolUsage
