import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, TextField } from '@material-ui/core'
import { CloudContainer, Paper, PaperContent } from 'components'

const useStyles = makeStyles(() => ({
  paper: {
    width: '440px',
    margin: '1.5rem auto',
  },
  form: {
    textAlign: 'center',
    margin: '2rem 0 1rem',
  },
  textField: {
    margin: '.5rem 0 2rem',
    width: '300px',
    fontSize: '22px',
  },
  footerText: {
    marginTop: '1rem',
    fontStyle: 'italic',
    maxWidth: '80%',
  },
}))

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const ErrorTest = () => {
  const classes = useStyles()
  const query = useQuery()
  const boomOnLoad = query.get('boom!')
  const initialInputValue = boomOnLoad !== null ? 'boom!' : ''
  const [inputValue, setInputValue] = useState(initialInputValue)

  useEffect(() => {
    if (inputValue.toLowerCase() === 'boom!') {
      throw new Error('💥 BOOM! - this boom was intended for testing. just ignore it')
    }
  }, [inputValue])

  return (
    <CloudContainer>
      <Paper className={classes.paper}>
        <PaperContent>
          <Typography variant="h3" align="center" gutterBottom>
            Testing page for error handling
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            such as ErrorBoundary, Sentry, etc.
          </Typography>
          <form className={classes.form} noValidate autoComplete="off">
            <TextField
              className={classes.textField}
              label="Field that goes BOOM!"
              variant="filled"
              onChange={(e) => setInputValue(e.target.value)}
              helperText='Type "BOOM!" for error'
            />
            <Typography variant="body1" align="left">
              Note. if you want to test with an imediate BOOM! you can append query
              <code> ?boom! </code> to the browsers address bar. <br />
              Useful for testing error that occured upon rendering as oppose to user interacting
              with page
            </Typography>
            <Typography
              className={classes.footerText}
              variant="body2"
              align="left"
              style={{ marginTop: '1rem', fontStyle: 'italic', maxWidth: '80%' }}
            >
              This page will not be accessable in production and will require authentication
            </Typography>
          </form>
        </PaperContent>
      </Paper>
    </CloudContainer>
  )
}

export default ErrorTest
