import React from 'react'
import { Grid } from '@material-ui/core'
import { Paper, PaperContent } from 'components'
import InnerContent from './InnerContent'

const Guides = () => {
  return (
    <Grid container justify="center">
      <Grid item lg={6} sm={8} xs={12}>
        <Paper>
          <PaperContent>
            <InnerContent />
          </PaperContent>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Guides
