import promisesTeams, { getDefaultTeamsProps } from './promises/teams'

export const teams = {
  // the object to mutate and eventually import "teams" opts from
  ...getDefaultTeamsProps(),
}

const optsPromiseArr = [
  ...promisesTeams(teams),
  // ...add more if you like
]

export default new Promise((resolve, reject) => {
  Promise.all(optsPromiseArr)
    .then(() => {
      resolve({ teams })
    })
    .catch((err) => {
      reject(err)
    })
})
