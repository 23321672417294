import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export default ({ to = '', snackbar: { message, options } }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  enqueueSnackbar(t(message), options)
  return <Redirect to={to} />
}
