import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { deleteUser } from 'services/sweb/user'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Paper, PaperContent, Alert } from '@skytdc/mui/components'
import { createSubscription } from 'services/sweb/subscription'
import { useSnackbar } from 'notistack'

import {
  List,
  ListItemText,
  ListItem,
  Avatar,
  ListItemAvatar,
  Typography,
  InputAdornment,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core'
import {
  DeleteForever as DeleteForeverIcon,
  MailOutline as MailOutlineIcon,
  Check as CheckIcon,
} from '@material-ui/icons'
import CustomDialog, { DialogProps } from 'components/CustomDialog'
import UsersTable from './components/UsersTable'

interface Props extends RouteComponentProps {
  customerId: string
  createNewUser?: { dialogOpen: boolean; handleDialogState: () => void }
  oCUserData: { loading: boolean; data: any; error: any; fetchUsers: () => void }
}

const useStyles = makeStyles((theme) => ({
  deleteAvatar: {
    background: theme.palette.error.main,
  },
  successAvatar: {
    background: theme.palette.success.main,
  },
  createUser: {
    width: '100%',
    marginTop: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '1rem',
    justifyContent: ' flex-end',
    '&>button': {
      marginLeft: 8,
    },
  },
}))

const OCAdmins = ({ customerId, createNewUser, oCUserData }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [dialog, setDialog] = useState<DialogProps>({})
  const [fetchingError, setFetchingError] = useState<boolean>(false)
  const [sendingInvite, setSendingInvite] = useState<boolean>(false)
  const [createUserError, setCreateUserError] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()
  const { loading, data, error, fetchUsers } = oCUserData || {}

  useEffect(() => {
    changeDocumentTitle(t('OC_organisation_title'), ' - ', ':applicationName')
  }, [])

  const onCancel = () => {
    setDialog({})

    if (createNewUser.dialogOpen) {
      createNewUser.handleDialogState()
    }
  }

  const createNewUserSuccessDialog = (email: string) => {
    setDialog({
      isOpen: true,
      title: t('OC_admin_add_new_user_success_title'),
      children: (
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar className={classes.successAvatar}>
                <CheckIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('OC_admin_add_new_user_success_desc')}
              secondary={
                <Typography variant="body1" color="primary">
                  {email}
                </Typography>
              }
            />
          </ListItem>
        </List>
      ),
      // eslint-disable-next-line no-use-before-define
      onConfirm: createNewUserDialog,
      confirmButtonText: t('OC_admin_add_new_user'),
      onCancel,
    })
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    const email = new FormData(e.target).get('email') as string
    const userExist = data.admins.some(({ email: userEmail }) => userEmail === email)
    if (!email || !/\S+@\S+\.\S+/.test(email) || userExist) {
      setCreateUserError(userExist ? 'User already exist' : 'Invalid email')
    } else {
      setSendingInvite(true)
      setCreateUserError('')
      createSubscription
        .inviteOCUser({ customerId, json: { sendToEmail: email } })
        .then((content: any) => {
          setSendingInvite(false)

          enqueueSnackbar(`Brugeren har nu modtaget en invitationsmail`, {
            variant: 'success',
          })
          createNewUserSuccessDialog(content.data.sendtTo)
        })
        .catch((err) => {
          setSendingInvite(false)
          err.response.json().then((res) => {
            setCreateUserError(res.error.text || 'Ikke valid email')
            enqueueSnackbar(`${res.error.text}, prøv igen`, {
              variant: 'error',
            })
          })
        })
    }
  }

  const createNewUserDialog = () => {
    setDialog({
      isOpen: true,
      title: t('OC_admin_add_new_user'),
      children: (
        <>
          <form onSubmit={onSubmit} noValidate autoComplete="off">
            <TextField
              error={Boolean(createUserError)}
              name="email"
              type="email"
              helperText={createUserError}
              className={classes.createUser}
              disabled={sendingInvite}
              id="input-with-icon-textfield "
              label={t('OC_admin_add_new_user_input_label')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.buttonContainer}>
              <Button onClick={onCancel} color="primary">
                {t('Wizard_00tyuj')}
              </Button>

              <Button variant="contained" type="submit" color="primary" disabled={sendingInvite}>
                {sendingInvite ? (
                  <>
                    <CircularProgress color="secondary" size={18} style={{ marginRight: 8 }} />
                    {t('OC_admin_invite_sending')}
                  </>
                ) : (
                  t('Organization_huntgroups_create_label')
                )}
              </Button>
            </div>
          </form>
        </>
      ),
      hideButtons: true,
      onCancel,
    })
  }

  useEffect(() => {
    if (createNewUser.dialogOpen) {
      createNewUserDialog()
    }
  }, [sendingInvite, createNewUser.dialogOpen, createUserError])

  if (!fetchingError && error) {
    setFetchingError(true)
  }

  const handleDeleteUser = (azureTenantId, azureUserId) => {
    deleteUser.OCRole({ customerId, azureTenantId, azureUserId }).then(() => {
      fetchUsers()
      onCancel()
    })
  }

  const openDeleteDialog = (email: string, azureUserId: string, azureTenantId: string) => {
    setDialog({
      isOpen: true,
      title: t('OC_admin_delete_title'),
      children: (
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar className={classes.deleteAvatar}>
                <DeleteForeverIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('OC_admin_delete')}
              secondary={
                <Typography variant="body1" color="primary">
                  {email || azureUserId}
                </Typography>
              }
            />
          </ListItem>
        </List>
      ),
      onConfirm: () => handleDeleteUser(azureTenantId, azureUserId),
      confirmButtonText: t('Wizard_9awef'),
      onCancel,
    })
  }

  if (fetchingError) {
    return (
      <Paper style={{ maxWidth: 600 }}>
        <PaperContent>
          <Alert type="error" message={t('Dashboard_stock_some_error')} />
        </PaperContent>
      </Paper>
    )
  }

  return (
    <>
      <UsersTable
        users={data?.admins || []}
        loading={loading}
        openDeleteDialog={openDeleteDialog}
      />
      {dialog.isOpen && (
        <CustomDialog
          isOpen={dialog.isOpen}
          onConfirm={dialog.onConfirm}
          onCancel={dialog.onCancel}
          hideButtons={dialog.hideButtons}
          title={dialog.title}
          confirmButtonText={dialog.confirmButtonText}
          closeButtonText={t('Wizard_00tyuj')}
        >
          {dialog.children}
        </CustomDialog>
      )}
    </>
  )
}

export default OCAdmins
