import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Tooltip } from '@material-ui/core'
import {
  LinkOff as LinkOffIcon,
  Update as UpdateIcon,
  CloudOff as CloudOffIcon,
  DeleteSweepOutlined as DeleteIcon,
} from '@material-ui/icons'
import getInitials from 'utils/getInitials'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  default: { backgroundColor: theme.palette.info.main },
}))

const UserAvatar = ({
  name,
  avatar,
  showSubButNoTeamsWarning,
  requiresAzureLink,
  endDate,
  inChange,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  if (endDate) {
    return (
      <Tooltip
        placement="right"
        title={t('Router_sawferch', {
          date: format(new Date(endDate), 'dd-MM-yyyy'),
        })}
      >
        <Avatar className={classes.error}>
          <DeleteIcon />
        </Avatar>
      </Tooltip>
    )
  }
  if (showSubButNoTeamsWarning) {
    return (
      <Tooltip
        placement="right"
        title={t(requiresAzureLink ? 'Router_serthdfsarch' : 'Router_serthdfplain')}
      >
        <Avatar className={requiresAzureLink && classes.error}>
          {requiresAzureLink ? <LinkOffIcon /> : <CloudOffIcon />}
        </Avatar>
      </Tooltip>
    )
  }
  if (!showSubButNoTeamsWarning && inChange) {
    return (
      <Tooltip placement="right" title={t('Router_sesdfartharch')}>
        <Avatar className={classes.warning}>
          <UpdateIcon />
        </Avatar>
      </Tooltip>
    )
  }
  if (!showSubButNoTeamsWarning && !inChange && avatar) {
    return <Avatar alt={name} src={avatar} />
  }
  return <Avatar className={classes.default}>{getInitials(name)}</Avatar>
}

export default UserAvatar
