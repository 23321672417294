import React, { memo } from 'react'
import {
  Paper,
  PaperHeader,
  PaperTitle,
  PaperContent,
  PaperFooter,
  PaperCaption,
  PaperLoader,
  PaperToolbar,
  PaperFeedbackMessage,
  MuiDataTable,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Link } from '@material-ui/core'
import { ArrowRightRounded as ArrowRightIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import StatusChip from './StatusChip'

const useStyles = makeStyles((theme) => ({
  setMaxHeight: {
    height: '100%',
    marginBottom: 0,
  },
  table: {
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: 'none !important',
  },
}))

const ZendeskTickets = ({ tickets }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  let content = <PaperLoader />

  const columns = [
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <StatusChip status={value} />,
      },
    },
    {
      name: 'subject',
      label: t('Dashboard_tickets_subject'),
      align: 'left',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'created_at',
      label: t('Dashboard_tickets_created'),
      align: 'right',
      options: {
        sortDirection: 'asc',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'updated_at',
      label: t('Dashboard_tickets_last_activity'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'id',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
        searchable: false,
        download: false,
      },
    },
  ]

  const options = {
    search: false,
    sort: false,
    filter: Boolean(tickets.data && tickets.data.tickets.length > 4),
    searchOpen: false,
    print: false,
    viewColumns: false,
    download: false,
    rowHover: true,
    pagination: Boolean(tickets.data && tickets.data.tickets.length > 4),
    responsive: 'scrollFullHeight',
    rowsPerPage: 5,
    selectableRows: 'none',
    onRowClick: (rowData) => {
      const ticketId = rowData[4]
      const linkToZendesk = `https://support.sky.tdc.dk/hc/da/requests/${ticketId}`
      window.open(linkToZendesk, '_blank')
    },
  }

  if (tickets.error && tickets.error.statusText) {
    content = (
      <PaperFeedbackMessage
        type="error"
        message={tickets.error.statusText}
        disableGutters={false}
      />
    )
  }

  if (tickets.data && tickets.data.tickets.length === 0) {
    content = (
      <PaperFeedbackMessage message={t('Dashboard_tickets_no_tickets')} disableGutters={false} />
    )
  }

  if (tickets.data && tickets.data.tickets.length > 0) {
    content = <MuiDataTable data={tickets.data.tickets} options={options} columns={columns} />
  }

  return (
    <Paper className={classes.setMaxHeight}>
      <PaperHeader noDivider>
        <PaperTitle title={t('Dashboard_tickets_title')} />
        <PaperToolbar>
          <Button
            color="primary"
            size="small"
            variant="text"
            component={Link}
            href="https://support.sky.tdc.dk/hc/da/requests/new"
            target="_blank"
            className={classes.link}
          >
            {t('Create_ticket')}
          </Button>
        </PaperToolbar>
      </PaperHeader>
      {!tickets.loading && <PaperCaption content={t('Dashboard_tickets_desc')} />}
      <PaperContent noPadding className={classes.table}>
        {content}
      </PaperContent>
      <PaperFooter
        alignRight
        noDivider={Boolean(
          tickets.data && tickets.data.tickets && tickets.data.tickets.length === 0
        )}
      >
        <Button
          color="primary"
          size="small"
          variant="text"
          component={Link}
          href="https://support.sky.tdc.dk/hc/da/requests"
          target="_blank"
          className={classes.link}
        >
          {t('Dashboard_tickets_goto')} <ArrowRightIcon />
        </Button>
      </PaperFooter>
    </Paper>
  )
}

export default memo(ZendeskTickets)
