import React, { memo } from 'react'
import { Paper, PaperContent, DrawerSection } from 'components'
import { useTranslation } from 'react-i18next'
import { phoneNumberFormat } from 'utils/helpers'
import TopContainer from '../TopContainer'
import BaseInformation from '../BaseInformation'
import Mobile from '../Mobile'
import Landline from '../Landline'
import AddonsAndFeatures from '../AddonsAndFeatures'
import NoSubscriptionMessage from '../NoSubscriptionMessage'

const EndUserContainer = ({ subscription, customerName, customerId, address, dataUsages }) => {
  const {
    teams_user_output: teamsUserOutput,
    first_name: firstName,
    last_name: lastName,
    email,
    abb_id: abbId,
    mobile_package: mobilePackage,
    mobile_number: mobileNumber,
    landline_number: landlineNumber,
    extension,
  } = subscription
  const { t } = useTranslation()
  const userName = teamsUserOutput?.displayName || `${firstName} ${lastName}`
  const getEmail = teamsUserOutput?.mail || email || ''
  const { jobTitle = '', department, id: azureId } = teamsUserOutput || {}
  const hasCurrentMobilePackage = mobilePackage && mobilePackage.current
  const mobilePackageLabel = hasCurrentMobilePackage && hasCurrentMobilePackage.abb_name
  const hasActiveAddons = hasCurrentMobilePackage && hasCurrentMobilePackage.is_works
  const includesMusic = hasCurrentMobilePackage?.includes_music
  return (
    <Paper>
      <PaperContent>
        <TopContainer
          name={userName}
          email={getEmail}
          abbId={abbId}
          mobileNumber={phoneNumberFormat(mobileNumber)}
          landlineNumber={phoneNumberFormat(landlineNumber)}
          mobilePackageLabel={mobilePackageLabel}
          customerId={customerId}
        />
        <DrawerSection title={t('Drawer_mypage_about_you')} />
        <BaseInformation
          customerName={customerName}
          customerId={customerId}
          jobTitle={jobTitle === 'null' ? null : jobTitle}
          department={department === 'null' ? null : department}
          azureId={azureId}
          abbId={abbId}
        />
        <DrawerSection title={t('Drawer_user_titlemobile')} />
        {mobileNumber ? (
          <Mobile
            mobileNumber={mobileNumber}
            mobilePackage={mobilePackage}
            abbId={abbId}
            userName={userName}
            userEmail={getEmail}
            address={address}
            dataUsages={dataUsages}
          />
        ) : (
          <NoSubscriptionMessage
            title={t('Drawer_mypage_no_mobile')}
            description={t('Drawer_mypage_mobile_contact')}
          />
        )}
        <DrawerSection title={t('Drawer_cancel_teams_title')} />
        {landlineNumber ? (
          <Landline landlineNumber={landlineNumber} extension={extension} />
        ) : (
          <NoSubscriptionMessage
            title={t('Drawer_mypage_no_teams')}
            description={t('Drawer_mypage_teams_contact')}
          />
        )}
        <DrawerSection title={t('Drawer_user_addons_title')} />
        <AddonsAndFeatures isActive={hasActiveAddons} includesMusic={includesMusic} />
      </PaperContent>
    </Paper>
  )
}

export default memo(EndUserContainer)
