import client from '../client'

// eslint-disable-next-line
export const role = ({ customerId, azureTenantId, azureId, roleName }) => {
  return client
    .delete(
      `authorization/${customerId}/teams/${azureTenantId}/${azureId}/${roleName}/authorizations`
    )
    .json()
}
export const OCRole = ({ customerId, azureTenantId, azureUserId }) => {
  return client
    .delete(`operator-connect/admins/${customerId}/${azureTenantId}/${azureUserId}`)
    .json()
}
export const OCInvite = ({ customerId, uuid }) => {
  return client.delete(`operator-connect/invite/${customerId}/${uuid}`).json()
}
