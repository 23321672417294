import React, { memo, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { fetchEndUserSubscription } from 'state/endUserSubscription/actions'
import { fetchMyDataUsages } from 'state/myDataUsages/actions'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { teams } from 'services/appOpts'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { LoadingSpinner, PageTitle } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'
import EndUserTopbar from './components/EndUserTopbar'
import Layout from '../Layout'
import EndUserContainer from './components/EndUserContainer'

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
  },
}))

const EndUser = ({ history }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const stored = useSelector(
    (state) => ({
      me: state.me,
      customer: state.customer,
      endUserSubscription: state.endUserSubscription,
      myDataUsages: state.myDataUsages,
    }),
    shallowEqual
  )

  const { isInTeams } = teams

  const isActualEndUser = Boolean(
    stored.me.data.user.role !== 'admin' && stored.me.data.authorizations.length === 0
  )
  const [subscription] = stored.me.data.activeSubscriptions
  const abbId = subscription && subscription.subId
  const { name: customerName, customer_id: customerId, address } = stored.customer.data
  const getMyDataUsages = Boolean(stored.endUserSubscription.data?.mobile_ani_id) && abbId

  useEffect(() => {
    if (abbId) {
      dispatch(fetchEndUserSubscription({ abbId }))
      changeDocumentTitle(t('Drawer_mypage_title'), ' - ', ':applicationName')
    } else {
      enqueueSnackbar(t('Drawer_awefwaef'), {
        variant: 'info',
      })
      history.push('/')
    }
  }, [])

  useEffect(() => {
    if (getMyDataUsages) {
      dispatch(fetchMyDataUsages({ customerId, abbId }))
    }
  }, [getMyDataUsages])

  let content = <LoadingSpinner />

  if (stored.endUserSubscription.error && stored.endUserSubscription.error.statusText) {
    content = (
      <Typography color="error" variant="h2" align="center">
        {stored.endUserSubscription.error.statusText}
      </Typography>
    )
  }

  if (stored.endUserSubscription.data) {
    content = (
      <EndUserContainer
        subscription={stored.endUserSubscription.data}
        customerName={customerName}
        customerId={customerId}
        address={address}
        dataUsages={stored.myDataUsages}
      />
    )
  }

  return (
    <Layout hideLayout={isActualEndUser}>
      {isActualEndUser && !isInTeams && <EndUserTopbar />}
      <Grid container justify="center" className={isActualEndUser ? classes.margin : ''}>
        <Grid item lg={6} sm={8} xs={12}>
          <PageTitle title={t('Organization_customer_select_mypage')} />
          {content}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default memo(EndUser)
