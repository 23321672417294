import React, { useEffect } from 'react'
import { fetchEndUserLicenseDetails } from 'state/endUserLicenseDetails/actions'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Tooltip } from '@material-ui/core'
import { CloudOutlined as LicenseIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.background.default,
    marginRight: theme.spacing(),
    padding: theme.spacing(),
    paddingBottom: '2px',
    paddingTop: '2px',
    borderRadius: '3px',
    fontSize: '10px',
    '&:hover': {
      backgroundColor: '#eaeaea',
      cursor: 'pointer',
    },
  },
}))

const OfficeItem = ({ abbId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const licenseDetails = useSelector((state) => state.endUserLicenseDetails)

  useEffect(() => {
    dispatch(fetchEndUserLicenseDetails({ abbId }))
  }, [])

  return (
    <DrawerListItem
      icon={<LicenseIcon />}
      isLoading={Boolean(!licenseDetails.data)}
      primaryText={t('Drawer_user_azure_licenses')}
      secondaryText={
        licenseDetails.data && (
          <>
            {licenseDetails.data.isTeamsLicensed && (
              <Tooltip title={t('Drawer_mypage_teams')}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="span"
                  className={classes.badge}
                >
                  Teams
                </Typography>
              </Tooltip>
            )}
            {licenseDetails.data.allowVoiceEnable && (
              <Tooltip title={t('Drawer_mypage_teams_telephony')}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="span"
                  className={classes.badge}
                >
                  Phone System
                </Typography>
              </Tooltip>
            )}
            {licenseDetails.data.hasAudioConferencing && (
              <Tooltip title={t('Drawer_mypage_audio_conf')}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="span"
                  className={classes.badge}
                >
                  Audio Conferencing
                </Typography>
              </Tooltip>
            )}
            {licenseDetails.data.licenseCategory && (
              <Tooltip title={t('Drawer_mypage_cat')}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="span"
                  className={classes.badge}
                >
                  {licenseDetails.data.licenseCategory}
                </Typography>
              </Tooltip>
            )}
            {!licenseDetails.loading && licenseDetails.error && t('Dashboard_stock_some_error')}
            {!licenseDetails.data.isTeamsLicensed &&
              !licenseDetails.data.allowVoiceEnable &&
              !licenseDetails.data.licenseCategory &&
              t('Drawer_mypage_no_licenses')}
          </>
        )
      }
    />
  )
}

export default React.memo(OfficeItem)
