import React, { Suspense } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import { PaperLoader, Paper, PaperContent, CloudContainer } from 'components'
import { useTranslation } from 'react-i18next'
import { IMG_404 } from 'constants/cdn'

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: 620,
    width: '100%',
    margin: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    fontSize: 50,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}))

const NotFound = ({ backToOverview }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Paper>
      <PaperContent>
        <img src={IMG_404} alt="404-side" className={classes.image} />
        <Typography variant="h3" align="center" gutterBottom>
          {t('404_header')}
        </Typography>
        <Typography variant="h6" align="center">
          {t('404_description')}
        </Typography>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={backToOverview} color="primary">
            {t('404_backButton')}
          </Button>
        </div>
      </PaperContent>
    </Paper>
  )
}

const NotFoundHOC = ({ history }) => {
  const backToOverview = () => {
    history.push('/')
  }
  return (
    <CloudContainer>
      <Suspense fallback={PaperLoader}>
        <NotFound backToOverview={backToOverview} />
      </Suspense>
    </CloudContainer>
  )
}

export default NotFoundHOC
