import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserProfilePhoto } from 'state/userProfilePhoto/actions'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
  Button,
  Grow,
} from '@material-ui/core'
import {
  ChevronLeft as ChevronLeftIcon,
  PersonOutlined as UserIcon,
  PersonAdd as PersonAddIcon,
} from '@material-ui/icons'
import getInitials from 'utils/getInitials'
import { useTranslation } from 'react-i18next'
import { Alert } from '@skytdc/mui/components'
import SubscriptionMoreMenu from '../SubscriptionMoreMenu'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '84px',
    height: '84px',
    fontSize: '30px',
    marginRight: theme.spacing(),
    backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
  },
  userName: {
    fontSize: '30px',
    lineHeight: '32px',
  },
  email: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    marginTop: theme.spacing(0.6),
    paddingBottom: theme.spacing(0.6),
    paddingLeft: '2px',
  },
  listItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  listItemNav: {
    paddingTop: theme.spacing(),
    padding: '0px',
  },
  list: {
    padding: '0px',
  },
  edgeEndIcon: {
    marginTop: '10px',
  },
  avatarContainer: {
    minWidth: 92,
    minHeight: 84,
  },
  loader: {
    marginLeft: theme.spacing(3.5),
    marginTop: theme.spacing(3.5),
  },
  avatarIcon: {
    height: 44,
    width: 44,
  },
  secondaryAction: {
    top: '58%',
  },
  syncEmailButton: {
    width: '100%',
  },
}))

const DrawerHeader = ({
  name,
  email,
  azureId,
  changeRoute,
  routeIsUser,
  customerId,
  createUser,
  requiresAzureLink,
  emailNotInSync,
  updateEmail,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const userProfilePhoto = useSelector((state) => state.userProfilePhoto)
  const hasAzureId = Boolean(azureId)
  const azureIdsMatch = Boolean(hasAzureId && azureId === userProfilePhoto.azureId)
  const hasProfilePhoto =
    userProfilePhoto &&
    userProfilePhoto.data &&
    userProfilePhoto.data.responses[0] &&
    userProfilePhoto.data.responses[0].profilePhoto

  useEffect(() => {
    if (hasAzureId) {
      dispatch(fetchUserProfilePhoto({ customerId, azureId }))
    }
  }, [])

  let profilePhoto = <CircularProgress size={28} className={classes.loader} />

  if (azureIdsMatch && hasProfilePhoto) {
    profilePhoto = (
      <Grow in>
        <Avatar
          alt={name}
          src={`data:image/jpg;base64,${userProfilePhoto.data.responses[0].profilePhoto}`}
          className={classes.avatar}
        />
      </Grow>
    )
  }

  if (
    !hasAzureId ||
    (azureIdsMatch && !userProfilePhoto.loading && !hasProfilePhoto) ||
    userProfilePhoto.error
  ) {
    profilePhoto = (
      <Grow in>
        <Avatar className={classes.avatar}>
          {(createUser ? (
            <PersonAddIcon className={classes.avatarIcon} />
          ) : (
            name.length > 2 && getInitials(name)
          )) || <UserIcon className={classes.avatarIcon} />}
        </Avatar>
      </Grow>
    )
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItemNav}>
        <ListItemIcon>
          <IconButton edge="start" onClick={() => changeRoute()}>
            <ChevronLeftIcon />
          </IconButton>
        </ListItemIcon>
        <ListItemText
          secondary={routeIsUser ? t('Drawer_header_users') : t('Drawer_buttons_back')}
        />
        {!createUser && routeIsUser && (
          <ListItemSecondaryAction className={classes.secondaryAction}>
            <SubscriptionMoreMenu changeRoute={changeRoute} />
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <ListItem className={classes.listItem} disableGutters>
        <ListItemAvatar className={classes.avatarContainer}>{profilePhoto}</ListItemAvatar>
        <ListItemText
          classes={{
            primary: classes.userName,
            secondary: classes.email,
          }}
          primaryTypographyProps={{ component: 'p' }}
          secondaryTypographyProps={{ noWrap: true }}
          primary={(name.length > 2 && name) || t('Drawer_header_no_name')}
          {...(!createUser && {
            secondary:
              (requiresAzureLink && email) || (requiresAzureLink && t('Drawer_header_no_email')),
          })}
        />
      </ListItem>
      {emailNotInSync && (
        <Alert type="warning" title={t('Drawer_header_email_not_in_sync_title')}>
          <Button
            variant="outlined"
            color="primary"
            onClick={updateEmail}
            className={classes.syncEmailButton}
          >
            {t('Drawer_header_email_not_in_sync_button')}
          </Button>
        </Alert>
      )}
    </List>
  )
}

export default React.memo(DrawerHeader)
