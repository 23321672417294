import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PaperLoader } from 'components'
import { getBearer } from 'services/partyweb/authentication'
import { fetchPartyMe } from '../state/partyMe/actions'
import PartyAuthenticated, { mustAuthenticate } from './PartyAuthenticated'
import { hideFeedbackButton, showFeedbackButton } from '../utils/htmlscript/usabilla'

const removeTrailingSlashFromPath = (history, pathname) => {
  const pathWithoutTrailingSlash = pathname.substr(0, pathname.length - 1)
  return history.replace(pathWithoutTrailingSlash)
}

const PartyRouter = ({ history, location }) => {
  const dispatch = useDispatch()
  const me = useSelector((state) => state.partyMe)

  const meInvokedOnce = me.data !== null && me.error !== null
  const bearer = getBearer()
  const hasBearer = Boolean(bearer && bearer.value)

  useEffect(() => {
    const isPathWithTrailingSlash = location.pathname.endsWith('/')
    if (isPathWithTrailingSlash) {
      removeTrailingSlashFromPath(history, location.pathname)
    }
    if (hasBearer && meInvokedOnce === false) {
      dispatch(fetchPartyMe())
    }
  }, [])

  const isNotAuthenticated = (!hasBearer && me.isAuthenticated === false) || me.error

  if (isNotAuthenticated) {
    hideFeedbackButton()
    return mustAuthenticate({ location })
  }

  if (!me.data) return <PaperLoader />

  showFeedbackButton()

  // if (isInTeams && allowedPublicTeamsRoutes(location.pathname) === false) {
  //   setLastVisitedPath(location.pathname)
  // }
  return <PartyAuthenticated me={me.data} />
}

export default withRouter(PartyRouter)
