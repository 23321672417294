import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.border}`,
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
    display: 'flex',
    height: '64px',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'relative',
  },
  noDivider: {
    borderBottom: 'none',
  },
  noPadding: {
    padding: 0,
  },
}))

const PaperHeader = (props) => {
  const classes = useStyles()
  const { className, noDivider, noPadding, children, ...rest } = props

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noDivider]: noDivider,
      [classes.noPadding]: noPadding,
    },
    className
  )

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}

export default PaperHeader
