import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import Avatar from './Avatar'

interface UserWithAvatarProps {
  name?: string
  email?: string
  avatar?: string
  children?: React.ReactNode
  onClick?: () => void
  isButton?: boolean
  outsideTable?: boolean
  isMobile?: boolean
  showSubButNoTeamsWarning?: boolean
  inChange?: boolean
  endDate?: string
  requiresAzureLink?: string
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    minWidth: '52px',
  },
  userName: {
    fontWeight: 700,
  },
  email: {
    color: theme.palette.primary.main,
  },
  itemText: {
    marginTop: '5px',
  },
  outsideTable: {
    marginTop: '-5px',
    marginBottom: '0px',
  },
  removePadding: {
    paddingLeft: '0px',
  },
  isMobile: {
    marginLeft: '-12px',
  },
  isButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
  },
}))

const UserWithAvatar = ({
  name,
  email,
  avatar,
  children,
  onClick,
  isButton = false,
  outsideTable = false,
  isMobile = false,
  showSubButNoTeamsWarning = false,
  inChange = false,
  endDate,
  requiresAzureLink,
}: UserWithAvatarProps) => {
  const classes = useStyles()

  return (
    <ListItem
      className={classNames({
        [classes.removePadding]: !isButton,
        [classes.isButton]: isButton,
      })}
      button={isButton}
      onClick={onClick}
    >
      <ListItemAvatar
        className={classNames([classes.avatar], {
          [classes.isMobile]: isMobile,
        })}
      >
        <Avatar
          name={name}
          avatar={avatar}
          requiresAzureLink={requiresAzureLink}
          showSubButNoTeamsWarning={showSubButNoTeamsWarning}
          inChange={inChange}
          endDate={endDate}
        />
      </ListItemAvatar>
      <ListItemText
        classes={{
          primary: classes.userName,
          secondary: classes.email,
          root: classNames({
            [classes.outsideTable]: outsideTable,
            [classes.itemText]: !outsideTable,
          }),
        }}
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
        primary={name}
        secondary={email || ''}
      />
      {children}
    </ListItem>
  )
}

export default UserWithAvatar
