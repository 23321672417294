import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { DialogActions, Button, Collapse, Link, Switch } from '@material-ui/core'
import { GradientButton, DrawerSection, SkyTextField, DrawerListItem } from 'components'
import { useSnackbar } from 'notistack'
import { createSubscription } from 'services/sweb/subscription'
import { useTranslation } from 'react-i18next'
import { Alert } from '@skytdc/mui/components'
import { Email as EmailIcon } from '@material-ui/icons'
import FormToggleSwitch from './FormToggleSwitch'
import MobileForm from '../components/MobileForm'
import LandlineForm from '../components/LandlineForm'
import DisplayPrice from '../components/DisplayPrice'
import { formatSubscriptionOrder } from '../helpers/subscription'

const findBaseProductPrice = ({ productDetails = [], licenseCategory }) => {
  const result = productDetails.find((pd) => {
    return pd.license_requirements === null || licenseCategory
  })
  return result ? result.price : 0
}

const FormContainer = ({
  user,
  handleClose,
  customerId,
  licenseDetails,
  mobilePackages,
  baseProducts,
}) => {
  const me = useSelector((state) => state.me)
  const broadworks = useSelector((state) => state.customer?.data?.customer_properties?.broadworks)
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [comment, setComment] = useState('')
  const initialLandlineForm = {
    landlineNumber: null,
    extension: null,
  }
  const initialMobileForm = {
    mobileNumber: null,
    mobilePackage: null,
    simcard: null,
    importMobileNumber: user.teamsMobileNumber || '',
    importSigner: user.email || '',
    datasim: null,
    alternativeSimcardAddress: '',
  }

  const [processingOrder, setProcessingOrder] = useState(false)
  const [processingOrderError, setProcessingOrderError] = useState(false)
  const [receiveWelcomeEmail, setReceiveWelcomeEmail] = useState(true)
  const { allowVoiceEnable, isTeamsLicensed, licenseCategory } = licenseDetails

  const configuredLandlineProducts = baseProducts.landline_only.product_details
  const landlineHasWarnings = baseProducts.mobile_only.warnings.length > 0
  const hasLandlinePrerequisites = allowVoiceEnable && isTeamsLicensed && !landlineHasWarnings
  const landlineIsAvailable =
    hasLandlinePrerequisites &&
    configuredLandlineProducts.length > 0 &&
    configuredLandlineProducts.length <= 2
  const mobileProductDetails = baseProducts.mobile_only.product_details.length
  const mobileHasWarnings = baseProducts.mobile_only.warnings.length > 0
  const mobileIsAvailable = mobileProductDetails === 1 && mobileHasWarnings === false

  const mobileDisallowTextArr = [t('Drawer_create_sub_mobile_disallow')]

  const mobileOnlyBasePrice = findBaseProductPrice({
    productDetails: baseProducts.mobile_only.product_details,
    licenseCategory,
  })

  const mobileAndLandlineBasePrice = findBaseProductPrice({
    productDetails: baseProducts.mobile_and_landline.product_details,
    licenseCategory,
  })

  const landlineDisallowTextArr = []
  if (landlineIsAvailable === false) {
    if (configuredLandlineProducts.length > 2 || configuredLandlineProducts.length === 0)
      landlineDisallowTextArr.push(t('Drawer_create_sub_landline_disallow'))
    if (
      configuredLandlineProducts.length > 0 &&
      configuredLandlineProducts.length <= 2 &&
      !allowVoiceEnable
    )
      landlineDisallowTextArr.push(t('Drawer_create_sub_insufficient_license', { name: user.name }))
    if (
      configuredLandlineProducts.length > 0 &&
      configuredLandlineProducts.length <= 2 &&
      mobileIsAvailable
    )
      landlineDisallowTextArr.push(t('Drawer_create_sub_create_mobil', { name: user.name }))
  }

  const [landlineEnabled, setLandlineEnabled] = useState(allowVoiceEnable)
  const handleToggleLandlineForm = () => {
    setLandlineEnabled(!landlineEnabled)
  }
  const toggleWelcomeMailChecked = () => {
    setReceiveWelcomeEmail((prev) => !prev)
  }

  const mobileAndLandlineAvailable = baseProducts.mobile_and_landline.product_details.length === 1

  const [landlineForm, setLandlineForm] = useState({ isValid: false, ...initialLandlineForm })
  const [mobileForm, setMobileForm] = useState({ isValid: false, ...initialMobileForm })

  const handleSetLandlineForm = (form) => {
    setLandlineForm({ ...initialLandlineForm, ...form })
  }

  const handleSetMobileForm = (form) => {
    setMobileForm({ ...initialMobileForm, ...form })
  }

  const [mobileEnabled, setMobileEnabled] = useState(mobileIsAvailable)
  const handleToggleMobileForm = () => {
    setMobileEnabled(!mobileEnabled)
  }

  const isInvalidForms = () => {
    if (!landlineEnabled && !mobileEnabled) return true
    if (landlineEnabled && !landlineForm.isValid) return true
    if (landlineEnabled && landlineForm.isValid && !landlineForm.extension?.localExtension)
      return true
    if (mobileEnabled && !mobileForm.isValid) return true
    return false
  }
  const submitIsDisabled = isInvalidForms()
  const submitSubscription = () => {
    setProcessingOrder(true)
    const { azureId: teamsUserId } = user
    const { email: receiptEmail } = me.data.user
    const json = formatSubscriptionOrder({
      withLandline: landlineEnabled,
      withMobile: mobileEnabled,
      teamsUserId,
      receiptEmail,
      mobile: mobileForm,
      landline: landlineForm,
      comment,
      receiveWelcomeEmail,
    })
    createSubscription
      .single({ customerId, json })
      .then(() => {
        setProcessingOrder(false)
        enqueueSnackbar(t('Drawer_create_sub_success'), {
          variant: 'success',
        })
        handleClose()
      })
      .catch((e) => {
        setProcessingOrderError(true)
        setProcessingOrder(false)
      })
  }

  const showLandline =
    landlineIsAvailable ||
    (!landlineIsAvailable &&
      mobileAndLandlineAvailable &&
      mobileEnabled &&
      allowVoiceEnable &&
      isTeamsLicensed)
  const showMobile =
    mobileIsAvailable || (!mobileIsAvailable && mobileAndLandlineAvailable && landlineEnabled)

  // to cover the need for mixing "Teams Cloud Voice" and "Teams Cloud Voice inkl. flatrate", we allow max two configured landline_only products. If Danish number, Teams Cloud Voice inkl. flatrate price is showed
  const internationalLandlinePrice = baseProducts.landline_only.product_details.find(
    (x) => !x.prefers_non_international
  )
  const nonInternationalLandlinePrice = baseProducts.landline_only.product_details.find(
    (x) => x.prefers_non_international
  )
  const intNumberSelectedAndPriceExists =
    landlineForm.landlineNumber &&
    landlineForm.landlineNumber.value.country &&
    internationalLandlinePrice &&
    internationalLandlinePrice.price

  const landlineOnlyBasePrice =
    intNumberSelectedAndPriceExists ||
    (nonInternationalLandlinePrice && nonInternationalLandlinePrice.price) ||
    (internationalLandlinePrice && internationalLandlinePrice.price) ||
    0

  const handleCommentChange = (e) => setComment(e.target.value)
  const supportLink = `https://support.sky.tdc.dk/hc/${
    i18n.language === 'da' ? 'da' : 'en-us'
  }/requests/new`

  return (
    <>
      <FormToggleSwitch
        isToggled={landlineEnabled}
        onToggle={handleToggleLandlineForm}
        title={t('Drawer_create_sub_teams_title')}
        disallowToggle={!showLandline || processingOrder}
        disallowToggleText={processingOrder ? '' : landlineDisallowTextArr}
      />
      {showLandline && (
        <Collapse in={landlineEnabled}>
          <DrawerSection>
            <LandlineForm
              initialForm={initialLandlineForm}
              oneNumberType={mobileEnabled ? 'classic' : null}
              onChange={handleSetLandlineForm}
              processingOrder={processingOrder}
              customerId={customerId}
              azureId={user.azureId}
            />
          </DrawerSection>
        </Collapse>
      )}

      {!broadworks && (
        <FormToggleSwitch
          isToggled={mobileEnabled}
          onToggle={handleToggleMobileForm}
          title={t('Drawer_create_sub_mobile_title')}
          disallowToggle={!showMobile || processingOrder}
          disallowToggleText={processingOrder ? '' : mobileDisallowTextArr}
        />
      )}
      {showMobile && (
        <Collapse in={mobileEnabled}>
          <DrawerSection>
            <MobileForm
              initialForm={initialMobileForm}
              mobilePackages={mobilePackages.available_packages}
              onChange={handleSetMobileForm}
              processingOrder={processingOrder}
              customerId={customerId}
              azureId={user.azureId}
            />
          </DrawerSection>
        </Collapse>
      )}

      <DrawerListItem
        icon={<EmailIcon />}
        tooltip={t('Drawer_receive_welcome_mail_tooltip')}
        primaryText={t('Drawer_receive_welcome_mail')}
      >
        <Switch
          color="primary"
          checked={receiveWelcomeEmail}
          onChange={toggleWelcomeMailChecked}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </DrawerListItem>
      <DrawerSection title={t('Drawer_create_sub_ppm')}>
        <DisplayPrice
          mobile={{
            enabled: mobileEnabled && !landlineEnabled,
            price: mobileOnlyBasePrice,
            name: 'Drawer_price_mobile_sub',
          }}
          landline={{
            enabled: landlineEnabled && !mobileEnabled,
            price: landlineOnlyBasePrice,
            name: 'Drawer_price_teams',
          }}
          mobileAndLandline={{
            enabled: Boolean(mobileEnabled && landlineEnabled),
            price: mobileAndLandlineBasePrice,
            name: 'Drawer_price_teams_and_mobile',
          }}
          mobilePackage={
            mobileForm.mobilePackage && {
              name: mobileForm.mobilePackage.label,
              price: mobileForm.mobilePackage.value.monthly_price,
            }
          }
        />
      </DrawerSection>
      <DrawerSection title={t('Order_comment')}>
        <SkyTextField
          multiline
          value={comment}
          onChange={handleCommentChange}
          label={t('Order_comment')}
        />
      </DrawerSection>

      {mobileForm?.simcard?.value === 'esim' && <Alert type="warning">{t('Esim_warning')}</Alert>}
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {t('Drawer_create_sub_cancel')}
        </Button>
        <GradientButton
          onClick={submitSubscription}
          disabled={submitIsDisabled}
          loading={processingOrder}
        >
          {t('Drawer_create_sub_create')}
        </GradientButton>
      </DialogActions>
      {processingOrderError && (
        <Alert type="error">
          {t('ContactSupport')}
          <Link href={supportLink} target="_blank">
            {supportLink}
          </Link>
        </Alert>
      )}
    </>
  )
}

export default FormContainer
