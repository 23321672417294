import React from 'react'
import { PaperFeedbackMessage } from 'components'
import { useTranslation } from 'react-i18next'

const NumberInUseBox = ({ details, isDeleted, user }) => {
  const { t } = useTranslation()

  const message = (
    <>
      {t('AzureNumberInUse')}
      <ul>
        <li>
          <strong>{t('AzureNumberInUseUser')}</strong> {user}
        </li>
        <li>
          <strong>Status:</strong>{' '}
          {isDeleted ? t('AzureNumberInUseDeleted') : t('AzureNumberInUseNotDeleted')}
        </li>
        <li>
          <strong>{t('AzureNumberInUseMoreInfo')}</strong> {details}
        </li>
      </ul>
      {t('AzureNumberInUseChooseNew')}
    </>
  )

  return <PaperFeedbackMessage message={message} type="error" />
}

export default NumberInUseBox
