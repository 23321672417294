import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Tooltip,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
  MenuList,
  MenuItem,
  Link,
} from '@material-ui/core'
import { HelpOutline as HelpIcon } from '@material-ui/icons'
import { CustomDialog } from 'components'
import OperationalStatus from 'views/Support/OperationalStatus/InnerContent'
import ContactUs from 'views/Support/ContactUs/InnerContent'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.success.main,
  },
  iconButton: {
    padding: 4,
    marginRight: 6,
    backgroundColor: theme.palette.success.light,
  },
  paper: {
    minWidth: 220,
  },
  underlineHover: {
    textDecoration: 'none !important',
  },
}))

const SupportMenu = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [modal, setModal] = useState({
    open: false,
    type: '',
  })
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const openModal = (type) => {
    setOpen(false)
    setModal({
      open: true,
      type,
    })
  }

  const handleCloseModal = () => {
    setModal({ ...modal, open: false })
  }

  return (
    <>
      <Tooltip title={t('Drawer_mypage_e')} arrow>
        <IconButton
          className={classes.iconButton}
          onClick={handleToggle}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
        >
          <HelpIcon className={classes.icon} />
        </IconButton>
      </Tooltip>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper className={classes.paper} elevation={8}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <Link
                    href="https://support.sky.tdc.dk/hc/da"
                    target="_blank"
                    className={classes.underlineHover}
                  >
                    <MenuItem onClick={handleClose}>{t('Drawer_mypage_f')}</MenuItem>
                  </Link>
                  <MenuItem onClick={() => openModal('driftstatus')}>
                    {t('Drawer_mypage_a')}
                  </MenuItem>
                  <Link
                    href="https://tdc.us7.list-manage.com/subscribe/post?u=340b8f443ab67e4f4df1dde71&id=437e109c9c"
                    target="_blank"
                    className={classes.underlineHover}
                  >
                    <MenuItem onClick={handleClose}>{t('Drawer_mypage_g')}</MenuItem>
                  </Link>
                  <MenuItem onClick={() => openModal('kontakt-os')}>
                    {t('Drawer_mypage_j')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <CustomDialog
        isOpen={modal.open}
        onCancel={handleCloseModal}
        closeButtonText={t('Organization_orders_drawer_close')}
        maxWidth={modal.type === 'driftstatus' ? 'md' : 'sm'}
        title={modal.type === 'driftstatus' ? t('Drawer_mypage_a') : t('Drawer_mypage_j')}
      >
        {modal.type === 'driftstatus' ? <OperationalStatus /> : <ContactUs />}
      </CustomDialog>
    </>
  )
}

export default SupportMenu
