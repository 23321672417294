import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceReferenceNames/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceReferenceNames/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceReferenceNames/FETCH_SUCCESS'

export const fetchInvoiceReferenceNamesFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceReferenceNamesBegin = ({ customerId, invoiceId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
    invoiceId,
  }
}

export const fetchInvoiceReferenceNamesSuccess = ({ customerId, invoiceId, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    customerId,
    invoiceId,
  }
}

export const fetchInvoiceReferenceNames = ({ customerId, invoiceId }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceReferenceNamesBegin({ customerId }))
    try {
      const response = await getInvoice.referenceNames({
        customerId,
        invoiceId,
      })
      dispatch(fetchInvoiceReferenceNamesSuccess({ customerId, invoiceId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceReferenceNamesFailure(err.response))
    }
  }
}
