import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceReferences/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceReferences/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceReferences/FETCH_SUCCESS'

export const fetchInvoiceReferencesFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceReferencesBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchInvoiceReferencesSuccess = ({ customerId, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    customerId,
  }
}

export const fetchInvoiceReferences = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceReferencesBegin({ customerId }))
    try {
      const response = await getInvoice.references({
        customerId,
      })
      dispatch(fetchInvoiceReferencesSuccess({ customerId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceReferencesFailure(err.response))
    }
  }
}
