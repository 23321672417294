import React from 'react'
import classNames from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  gutters: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
}))

interface Props {
  withGutters?: boolean
}

const DropdownDivider = ({ withGutters }: Props) => {
  const classes = useStyles()

  const dividerClassname = classNames([classes.divider], {
    [classes.gutters]: withGutters,
  })

  return <Divider className={dividerClassname} />
}

export default DropdownDivider
