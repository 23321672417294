import React from 'react'
import { useSnackbar } from 'notistack'
import DrawerListItem from 'components/DrawerListItem'
import { List } from '@material-ui/core'
import useCopyToClipboard from 'theme/hooks/useCopyToClipboard'
import { useTranslation } from 'react-i18next'

const SecretInfoModalContent = ({
  login,
  clientId,
  notAfter,
  notBefore,
  name,
  unionId,
  tenantId,
  tenantName,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { copyFn, CopyPlaceholder } = useCopyToClipboard()

  const copyToClipboard = (value) => {
    copyFn({ value })
    enqueueSnackbar(t('Drawer_mypage_copy', { value }))
  }

  const dateString = t('Invoice_period', { from: notBefore, to: notAfter })

  return (
    <List>
      <CopyPlaceholder />
      <DrawerListItem
        primaryText={(login && 'E-mail') || 'Client ID'}
        secondaryText={login || clientId}
        secondaryActionButton={t('Wizard_00srht')}
        onSecondaryActionButton={() => copyToClipboard(clientId || login)}
      />
      <DrawerListItem
        primaryText={t('Invoice_duration')}
        secondaryText={dateString}
        secondaryActionButton={t('Wizard_00srht')}
        onSecondaryActionButton={() => copyToClipboard(dateString)}
      />
      <DrawerListItem
        primaryText={t('Organization_huntgroups_td_1')}
        secondaryText={name}
        secondaryActionButton={t('Wizard_00srht')}
        onSecondaryActionButton={() => copyToClipboard(name)}
      />
      <DrawerListItem
        primaryText={t('Wizard_aswfed')}
        secondaryText={tenantName}
        secondaryActionButton={t('Wizard_00srht')}
        onSecondaryActionButton={() => copyToClipboard(tenantName)}
      />
      <DrawerListItem
        primaryText="Tenant ID"
        secondaryText={tenantId}
        secondaryActionButton={t('Wizard_00srht')}
        onSecondaryActionButton={() => copyToClipboard(tenantId)}
      />
      <DrawerListItem
        primaryText={t('Wizard_9awefawe')}
        secondaryText={unionId}
        secondaryActionButton={t('Wizard_00srht')}
        onSecondaryActionButton={() => copyToClipboard(unionId)}
      />
    </List>
  )
}

export default SecretInfoModalContent
