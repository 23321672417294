import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'availableRoles/FETCH_FAILURE'
export const FETCH_BEGIN = 'availableRoles/FETCH_BEGIN'
export const FETCH_SUCCESS = 'availableRoles/FETCH_SUCCESS'

export const fetchAvailableRolesFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAvailableRolesBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchAvailableRolesSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchAvailableRoles = () => {
  return async (dispatch) => {
    dispatch(fetchAvailableRolesBegin())
    try {
      const response = await getCustomer.availableRoles()
      dispatch(fetchAvailableRolesSuccess(response.data))
    } catch (err) {
      dispatch(fetchAvailableRolesFailure(err.response))
    }
  }
}
