import React from 'react'
import classNames from 'classnames'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.03)',
  },
}))

interface Props {
  children: JSX.Element | JSX.Element[]
  className?: string
  style?: React.CSSProperties
}

const CustomPaper = ({ children, className, style }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
    },
    className
  )

  return (
    <Paper className={rootClassName} elevation={0} component="div" square={false} style={style}>
      {children}
    </Paper>
  )
}

export default CustomPaper
