import React from 'react'
import {
  Paper,
  PaperHeader,
  PaperTitle,
  PaperContent,
  PaperFooter,
  LoadingSpinner,
  Alert,
} from '@skytdc/mui/components'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { ArrowRightRounded as ArrowRightIcon } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
  },
  dropdownButton: {
    marginRight: -theme.spacing(2),
  },
  subTableContainer: {
    [theme.breakpoints.between('lg', 'xl')]: {
      minHeight: '480px',
    },
    marginTop: '-8ch',
  },
  subTable: {
    marginTop: '4.5ch',
    '& thead': {
      '& th': {
        padding: '0 2ch',
      },
    },
    '& tbody': {
      '& td': {
        fontSize: '.85rem',
        padding: '0 2ch',
      },
      '& tr:last-child': {
        borderBottom: 0,
        marginBottom: '2ch',
      },
    },
  },
}))

const Subscriptions = ({ subscriptionCount, changePage }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const subscriptions = Object.keys(subscriptionCount.data || {})
    .map((key) => {
      const subs = subscriptionCount.data[key]
      return {
        category: key,
        total: Number(subs.reduce((agg, curr) => agg + curr.count, 0)),
        subList: [...subs],
      }
    })
    .sort((a, b) => (a.total > b.total ? -1 : 1))

  let content = <LoadingSpinner />

  if (subscriptionCount.error && subscriptionCount.error.statusText) {
    content = <Alert message={subscriptionCount.error.statusText} type="error" />
  }

  if (subscriptions.length > 0) {
    content = (
      <div className={classes.subTableContainer}>
        {subscriptions.map((sub) => {
          return (
            <Table key={sub.category} className={classes.subTable}>
              <TableHead>
                <TableRow>
                  <TableCell>{sub.category}</TableCell>
                  <TableCell align="right">{sub.total}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sub.subList.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="right">{row.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        })}
      </div>
    )
  }

  if (!subscriptionCount.loading && subscriptions.length === 0) {
    content = <Alert message={t('BaseSubscriptionsDesc')} disableGutters={false} />
  }

  return (
    <Paper className={classes.paper}>
      <PaperHeader noDivider>
        <PaperTitle title={t('Dashboard_invoice_subscriptions')} />
      </PaperHeader>
      <PaperContent>{content}</PaperContent>
      {subscriptions.length > 0 && (
        <PaperFooter alignRight>
          <Button color="primary" size="small" variant="text" onClick={() => changePage('brugere')}>
            {t('Dashboard_general_see_all')} <ArrowRightIcon />
          </Button>
        </PaperFooter>
      )}
    </Paper>
  )
}

export default Subscriptions
