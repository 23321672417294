import { fade, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(['border-color', 'box-shadow', 'background-color'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&$focused': {
      backgroundColor: theme.palette.common.white,
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    '&$disabled': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none !important',
    },
  },
  white: {
    backgroundColor: '#fff',
  },
  focused: {},
  error: {
    boxShadow: `${fade(theme.palette.error.main, 0.25)} 0 0 0 2px !important`,
    borderColor: `${theme.palette.error.main} !important`,
  },
  disabled: {
    backgroundColor: theme.palette.primary.light,
  },
  withMargin: {
    marginBottom: theme.spacing(2),
  },
}))
