import React from 'react'
import { List, ListItem, ListItemText, ListItemIcon, Link } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderRadius: theme.spacing(0.5),
  },
  buttonText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
  },
}))

interface Props {
  onClick: () => void
  text: string
  image: string
  helpText?: string
  helpLink?: string
  disabled?: boolean
}

const LoginProviderButton = ({ onClick, text, image, helpText, helpLink, disabled }: Props) => {
  const classes = useStyles()

  return (
    <List>
      <ListItem button className={classes.button} onClick={onClick} disabled={disabled}>
        <ListItemIcon>
          <img src={image} alt={text} style={{ width: 22 }} />
        </ListItemIcon>
        <ListItemText
          primary={text}
          classes={{
            primary: classes.buttonText,
          }}
        />
      </ListItem>
      {helpLink && helpText && (
        <Link variant="caption" href={helpLink} target="_blank">
          {helpText}
        </Link>
      )}
    </List>
  )
}

export default LoginProviderButton
