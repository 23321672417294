import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import useDebounce from 'utils/hooks/useDebounce'

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    marginRight: 'auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    color: theme.palette.text.secondary,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    fontSize: theme.typography.body1.fontSize,
    padding: '10px 8px 10px 56px',
    width: '100%',
  },
}))

const Search = ({
  filter: onChange,
  placeholder,
  value,
  autoFocus = true,
  className,
  disabled,
  debounce = 0,
}) => {
  const classes = useStyles()
  const [searchTerm, setSearchTerm] = useState(value || '')
  const [debouncedSearchTerm] = useDebounce(searchTerm, debounce)

  useEffect(() => {
    if (value !== undefined && searchTerm !== value) setSearchTerm(value)
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    if (value !== searchTerm) onChange(searchTerm)
    // eslint-disable-next-line
  }, [debouncedSearchTerm])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div className={`${classes.search} ${className}`}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        fullWidth
        value={searchTerm}
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleSearch}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': placeholder }}
      />
    </div>
  )
}

export default Search
