import React from 'react'
import { LoadingSpinner } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'
import Router from './routes/Router'
import NotifyWhenOffline from './utils/components/NotifyWenOffline'
import { createTitleOptions } from './routes/utils/changeDocumentTitle/store'
import './i18n'
import ErrorBoundary from './utils/components/ErrorBoundary'

createTitleOptions(
  [':applicationName', 'TDC Erhverv Selvbetjening'],
  [':customerName', null]
  // registered keys in which later can be set or and used with "changeDocumentTitle"
  // ie changeDocumentTitle('Welcome to ', ':applicationName') result in window.document title being "Welcome to Selvbetjening"
  // ':customerName' is being reserved for later use. For more info refer to the "changeDocumentTitle" test
)

const App = () => {
  const { t, ready } = useTranslation()

  if (!ready) return <LoadingSpinner />

  return (
    <ErrorBoundary>
      <NotifyWhenOffline message={t('NoInternet')} />
      <Router />
    </ErrorBoundary>
  )
}

export default App
