import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Button, CircularProgress } from '@material-ui/core'

// interms of upgrading style to new CVI, gradient button was disregarded
// instead it renders like a primary button. in future release component should be removed or renamed

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.common.white,
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const GradientButton = (props) => {
  const classes = useStyles()
  const {
    children,
    loading = false,
    onClick,
    disabled = false,
    className,
    fullWidth = false,
  } = props

  const rootClassName = classNames(
    {
      [classes.wrapper]: true,
    },
    className
  )

  return (
    <div className={rootClassName}>
      <Button
        variant="contained"
        color="primary"
        disabled={disabled || loading}
        disableElevation
        onClick={onClick}
        type="submit"
        fullWidth={fullWidth}
        classes={{
          label: classes.label,
        }}
      >
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}

export default GradientButton
