import React, { useEffect } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { isBefore } from 'date-fns'
import { fetchInvoiceSubscriptionDetails } from 'state/invoiceSubscriptionDetails/actions'
import { fetchInvoiceReferences } from 'state/invoiceReferences/actions'
import { fetchInvoiceSubscriptions } from 'state/invoiceSubscriptions/actions'
import { PaperFeedbackMessage, PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import TableWithFilteredTotal from '../../components/TableWithFilteredTotal'
import ExtraInfoInput from '../../components/ExtraInfoInput'
import UserNameTableCell from '../../components/UserNameTableCell'
import ReferencePopper from '../../components/ReferencePopper'

const mergeData = (invoiceSubscriptions, subscriptions, references) => {
  return invoiceSubscriptions.items.map((sub) => {
    const getUpdatedData =
      subscriptions.items.length > 0 &&
      subscriptions.items.find((updatedSub) => sub.subscription_id === updatedSub.subscription_id)
    const getReference =
      references.items.length > 0 &&
      references.items.find((ref) => sub.reference_id === ref.reference_id)
    const data = {
      updatedSubData: getUpdatedData,
      reference: getReference,
      customerId: invoiceSubscriptions.invoice.customer_id,
      snapshotExtraInfo: sub.extra_info,
      subscriptionId: sub.subscription_id,
    }
    // Check if subscription has end date before invoice period ends.
    // If so and no user_name, we can conclude that user has been cancelled and show
    // If not and no user_name, it must be a company subscription
    const subscriptionIsCancelled = isBefore(
      new Date(sub.end_date),
      new Date(invoiceSubscriptions.invoice.end_date)
    )
    const hasUserName = Boolean(getUpdatedData && getUpdatedData.user_name.trim().length > 0)
    const associationColumn =
      (hasUserName && getUpdatedData.user_name) ||
      (Boolean(!subscriptionIsCancelled && !hasUserName) && 'Organisation') ||
      (Boolean(subscriptionIsCancelled && !hasUserName) && 'Nedlagt')
    return {
      name: associationColumn || '',
      description: sub.description,
      extra_info: (getUpdatedData && getUpdatedData.extra_info) || '',
      reference_name: (getReference && getReference.reference_name) || '',
      start_date: sub.start_date,
      end_date: sub.end_date,
      data,
      unparsed_charge: sub.unparsed_charge,
    }
  })
}

const SubscriptionTable = ({ invoiceId, customerId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const invoiceSubscriptionDetails = useSelector((state) => state.invoiceSubscriptionDetails)
  const invoiceReferences = useSelector((state) => state.invoiceReferences)
  const invoiceSubscriptions = useSelector((state) => state.invoiceSubscriptions)
  const invoiceIdsMatch = invoiceSubscriptionDetails.invoiceId === invoiceId

  useEffect(() => {
    batch(() => {
      dispatch(fetchInvoiceSubscriptionDetails({ customerId, invoiceId }))
      dispatch(fetchInvoiceReferences({ customerId }))
      dispatch(fetchInvoiceSubscriptions({ customerId }))
    })
  }, [])

  const columns = [
    {
      name: 'description',
      label: t('Drawer_price_sub'),
      align: 'left',
      options: {
        sortDirection: 'asc',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: t('Invoice_association'),
      align: 'left',
      options: {
        hint: t('Invoice_association_desc'),
        filter: true,
        sort: true,
        customBodyRender: (value) => <UserNameTableCell value={value} />,
      },
    },
    {
      name: 'extra_info',
      label: t('Invoice_remark'),
      align: 'left',
      options: {
        hint: t('Invoice_remark_desc'),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <ExtraInfoInput value={value} updateValue={updateValue} data={tableMeta.rowData[6]} />
        ),
      },
    },
    {
      name: 'reference_name',
      label: t('Invoice_reff'),
      align: 'left',
      options: {
        hint: t('Invoice_ref_desc'),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          invoiceReferences.loading ? null : (
            <ReferencePopper
              value={value}
              updateValue={updateValue}
              data={tableMeta.rowData[6]}
              availableReferences={invoiceReferences.data.items}
            />
          ),
      },
    },
    {
      name: 'start_date',
      label: t('Invoice_startdate'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'end_date',
      label: t('Invoice_enddate'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'data',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
        searchable: false,
        download: false,
      },
    },
  ]

  if (invoiceSubscriptionDetails.error && invoiceIdsMatch) {
    return (
      <PaperFeedbackMessage
        message={t('Invoice_error', { error: invoiceSubscriptionDetails.error.statusText })}
        type="error"
        withMargin
      />
    )
  }

  if (
    invoiceSubscriptionDetails.data &&
    invoiceSubscriptionDetails.data.items.length === 0 &&
    invoiceIdsMatch
  ) {
    return <PaperFeedbackMessage message={t('Invoice_no_occurences')} withMargin />
  }

  if (
    invoiceSubscriptionDetails.data &&
    invoiceSubscriptionDetails.data.items.length > 0 &&
    invoiceSubscriptions.data &&
    invoiceReferences.data &&
    invoiceIdsMatch
  ) {
    const dataWithRefAndExtraInfo = mergeData(
      invoiceSubscriptionDetails.data,
      invoiceSubscriptions.data,
      invoiceReferences.data
    )
    return (
      <TableWithFilteredTotal
        items={dataWithRefAndExtraInfo}
        firstTableColumns={columns}
        totalPrice={invoiceSubscriptionDetails.data.invoice.subscription_fees}
      />
    )
  }

  return <PaperLoader />
}

export default React.memo(SubscriptionTable)
