import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  wizardTextBlock: {
    marginBottom: theme.spacing(2),
  },
  inputMargin: {
    marginBottom: theme.spacing(2.5),
    display: 'block',
  },
}))

const TextBlock = ({ children, inputMargin = false, component = 'p' }) => {
  const classes = useStyles()

  const root = classNames({
    [classes.wizardTextBlock]: true,
    [classes.inputMargin]: inputMargin,
  })

  return (
    <Typography variant="body1" className={root} component={component}>
      {children}
    </Typography>
  )
}

export default TextBlock
