import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Switch, FormControlLabel } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
  },
  button: {
    fontSize: '12px',
    textTransform: 'none',
    marginRight: -theme.spacing(),
  },
}))

const ListSwitch = ({
  isChecked,
  onChange = null,
  value,
  isDisabled = false,
  label,
  size = 'medium',
}) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      value="start"
      control={
        <Switch
          value={value}
          color="primary"
          edge="end"
          size={size}
          checked={isChecked}
          disabled={isDisabled}
        />
      }
      label={label}
      labelPlacement="start"
      onChange={onChange}
      classes={{
        label: classes.label,
      }}
    />
  )
}

export default ListSwitch
