import React from 'react'
import { TableCellListItem } from '@skytdc/mui/components'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ListItemAvatar, Avatar } from '@material-ui/core'
import { ErrorRounded as ErrorIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  listItemAvatar: {
    minWidth: 44,
  },
  avatar: {
    width: 22,
    height: 22,
    marginLeft: theme.spacing(),
    backgroundColor: theme.palette.warning.main,
  },
  listItem: {
    marginLeft: theme.spacing(),
  },
}))

const StatusCell = ({ hasErrors, primarySbc, backupSbc }) => {
  const classes = useStyles()

  return (
    <TableCellListItem
      primary={primarySbc}
      secondary={hasErrors ? 'Opsætningsfejl' : backupSbc}
      className={classes.listItem}
    >
      {hasErrors && (
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar className={classes.avatar}>
            <ErrorIcon />
          </Avatar>
        </ListItemAvatar>
      )}
    </TableCellListItem>
  )
}

export default StatusCell
