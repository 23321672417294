import { createSubscription } from 'services/partyweb/subscription'

export const FETCH_FAILURE = 'PARTYALLPARTIES/FETCH_FAILURE'
export const FETCH_BEGIN = 'PARTYALLPARTIES/FETCH_BEGIN'
export const FETCH_SUCCESS = 'PARTYALLPARTIES/FETCH_SUCCESS'

export const fetchAllPartiesFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAllPartiesBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchAllPartiesSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchAllParties = () => {
  return async (dispatch) => {
    dispatch(fetchAllPartiesBegin())
    try {
      const response = await createSubscription.getParties()
      dispatch(fetchAllPartiesSuccess(response.data))
    } catch (err) {
      dispatch(fetchAllPartiesFailure(err.response))
    }
  }
}
