import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceMobileSpecifications/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceMobileSpecifications/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceMobileSpecifications/FETCH_SUCCESS'

export const fetchInvoiceMobileSpecificationsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceMobileSpecificationsBegin = ({ invoiceId, locationtype }) => {
  return {
    type: FETCH_BEGIN,
    invoiceId,
    locationtype,
  }
}

export const fetchInvoiceMobileSpecificationsSuccess = ({ invoiceId, locationtype, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    invoiceId,
    locationtype,
  }
}

export const fetchInvoiceMobileSpecifications = ({
  customerId,
  invoiceId,
  startdate,
  enddate,
  locationtype,
}) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceMobileSpecificationsBegin({ invoiceId, locationtype }))
    try {
      const response = await getInvoice.categorySpecifications({
        customerId,
        invoiceId,
        locationtype,
        startdate,
        enddate,
      })
      dispatch(fetchInvoiceMobileSpecificationsSuccess({ invoiceId, locationtype, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceMobileSpecificationsFailure(err.response))
    }
  }
}
