import React from 'react'
import classNames from 'classnames'
import { TableCellListItem } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { ListItemAvatar, Avatar } from '@material-ui/core'
import {
  DoneRounded as SuccessIcon,
  PauseRounded as PausedIcon,
  ErrorRounded as ErrorIcon,
  QueryBuilderRounded as ProcessingIcon,
  CancelScheduleSendRounded as CancelledIcon,
} from '@material-ui/icons'
import { formatTimestamp, formatStatus } from 'views/Admin/InternationalNumbers/helpers'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  listItemAvatar: {
    minWidth: 52,
  },
  avatar: {
    width: 32,
    height: 32,
    marginLeft: theme.spacing(),
  },
  cancelled: {
    backgroundColor: theme.palette.warning.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  processing: {
    backgroundColor: theme.palette.primary.main,
  },
  paused: {
    backgroundColor: theme.palette.text.secondary,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
}))

const StatusCell = ({ resultId, resultText, inserted }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const avatarClassName = classNames([classes.avatar], {
    [classes.success]: resultId === 1,
    [classes.processing]: resultId === 2,
    [classes.cancelled]: resultId === 3,
    [classes.paused]: resultId === 4,
    [classes.error]: resultId > 4,
  })

  return (
    <TableCellListItem
      primary={t(formatStatus(resultId) || resultText)}
      secondary={formatTimestamp(inserted)}
    >
      <ListItemAvatar className={classes.listItemAvatar}>
        <Avatar className={avatarClassName}>
          {resultId === 1 && <SuccessIcon />}
          {resultId === 2 && <ProcessingIcon />}
          {resultId === 3 && <CancelledIcon />}
          {resultId === 4 && <PausedIcon />}
          {resultId > 4 && <ErrorIcon />}
        </Avatar>
      </ListItemAvatar>
    </TableCellListItem>
  )
}

export default StatusCell
