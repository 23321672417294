const valueFromWindow = (name: string, allowEmpty?: boolean) => {
  const val = window[name]
  if (typeof val !== 'string') {
    throw new TypeError(`configuration error: "${name}" must be string`)
  }
  if (val.length > 0 === false && !allowEmpty) {
    throw new TypeError(`configuration error: "${name}" must not be empty`)
  }
  return val
}

export const ENV = valueFromWindow('SSFAPP_ENV')
export const SWEB_API_ENV = valueFromWindow('SSFAPP_SWEB_API_ENV')
export const SWEB_API_URI = valueFromWindow('SSFAPP_SWEB_API_URI')
export const MS_AUTH_CLIENT_ID = valueFromWindow('SSFAPP_MS_AUTH_CLIENT_ID')
export const PARTY_MS_AUTH_CLIENT_ID = valueFromWindow('PARTY_MS_AUTH_CLIENT_ID')
export const SENTRY_DSN = valueFromWindow('SSFAPP_SENTRY_DSN')
