import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Business as BusinessIcon, PersonOutlined as UserIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    alignItems: 'center',
    display: 'inline-flex',
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(),
  },
}))

const UserNameTableCell = ({ value }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  if (value === 'Nedlagt') {
    return (
      <Typography variant="body2" component="i" color="textSecondary">
        {t('Invoice_cancelled')}
      </Typography>
    )
  }
  if (value === 'Organisation') {
    return (
      <Typography variant="inherit" color="inherit" className={classes.iconWrapper}>
        <BusinessIcon className={classes.icon} /> {t('Invoice_org')}
      </Typography>
    )
  }
  return (
    <Typography variant="inherit" color="inherit" className={classes.iconWrapper}>
      <UserIcon className={classes.icon} /> {value}
    </Typography>
  )
}

export default UserNameTableCell
