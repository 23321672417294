import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  ShoppingBasketOutlined as ProductIcon,
} from '@material-ui/icons'
import FeatureListUnitSum from 'components/FeatureListUnitSum'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  displayPriceListUnitSum: {
    marginTop: theme.spacing(0),
  },
}))

const DisplayPrice = ({ className = '', units = [], prefix }) => {
  const classes = useStyles()
  const classNameStr = classNames(className, classes.displayPriceListUnitSum)
  return (
    <FeatureListUnitSum
      className={classNameStr}
      units={units}
      totalUnitProps={{ prefix }}
      withTotalSum={Boolean(true)}
    />
  )
}

const DisplayPriceContainer = ({
  className = '',
  totalPricePrefix = '',
  mobile = { enabled: false, price: 0, name: 'Drawer_price_mobile_sub' },
  landline = { enabled: false, price: 0, name: 'Drawer_price_teams' },
  mobileAndLandline = { enabled: false, price: 0, name: 'Drawer_price_teams_and_mobile' },
  mobilePackage = { name: '', price: 0 },
}) => {
  const { t } = useTranslation()
  const stripNullFilter = (f) => f

  const baseItem = { icon: <ProductIcon />, category: t('Drawer_price_sub') }

  const mobilePackageItem = mobilePackage
    ? {
        ...mobilePackage,
        icon: (mobilePackage as { oldProduct?: any }).oldProduct ? <RemoveIcon /> : <AddIcon />,
        category: t('Drawer_price_mobile'),
        name: mobilePackage.name,
        value: mobilePackage.price,
      }
    : null

  let items = [mobilePackageItem].filter(stripNullFilter)

  if (mobileAndLandline.enabled) {
    const mobileAndLandlineItem = {
      ...baseItem,
      ...mobileAndLandline,
      name: t(mobileAndLandline.name),
      value: mobileAndLandline.price,
    }
    items = [mobileAndLandlineItem, ...items]
  }

  if (mobile.enabled) {
    const mobileItem = {
      ...baseItem,
      ...mobile,
      name: t(mobile.name),
      value: mobile.price,
    }
    items = [mobileItem, ...items]
  }

  if (landline.enabled) {
    const landlineItem = {
      ...baseItem,
      ...landline,
      name: t(landline.name),
      value: landline.price,
    }
    items = [landlineItem]
  }

  if (items.length > 0) {
    return (
      <DisplayPrice
        className={className}
        units={items}
        prefix={totalPricePrefix || t('Drawer_price_per_month')}
      />
    )
  }

  return null
}

export default DisplayPriceContainer
