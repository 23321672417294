import React from 'react'
import Guides from '../views/Support/Guides'
import Changelog from '../views/Support/Changelog'
import OperationalStatus from '../views/Support/OperationalStatus'
import ContactUs from '../views/Support/ContactUs'

// yesyes, sry, will fix
export const allowedPublicTeamsRoutes = (match) => {
  if (
    match === '/guides' ||
    match === '/nyt-i-selvbetjening' ||
    match === '/driftstatus' ||
    match === '/kontakt-os'
  ) {
    return true
  }
  return false
}

export default ({ match }) => {
  switch (match) {
    case '/guides':
      return <Guides />
    case '/nyt-i-selvbetjening':
      return <Changelog />
    case '/driftstatus':
      return <OperationalStatus />
    case '/kontakt-os':
      return <ContactUs />
    default:
      return null
  }
}
