import React from 'react'
import {
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Button,
  Tooltip,
  Badge,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons'
import useButtonStyles from '../../hooks/useButtonStyle'

const useStyles = makeStyles((theme) => ({
  listItem: {
    minHeight: '63px',
    '&:hover': {
      borderRadius: 4,
    },
  },
  ctaButton: {
    fontSize: theme.typography.body2.fontSize,
  },
  chevron: {
    color: theme.palette.text.secondary,
  },
}))

interface Props {
  loading?: boolean
  disabled?: boolean
  tooltip?: string
  icon?: JSX.Element
  primary?: string
  secondary?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  actionText?: string
  secondaryAction?: 'button' | 'text' | 'nav' | 'custom'
  type?: 'neutral' | 'warning' | 'success' | 'error'
  customComponent?: JSX.Element
}

interface ListItemProps extends Props {
  children: JSX.Element
}

const ConditionalButtonListItem = ({
  children,
  onClick,
  disabled,
  loading,
  secondaryAction,
}: ListItemProps) => {
  const classes = useStyles()
  return onClick && secondaryAction !== 'button' ? (
    <ListItem
      button
      disabled={Boolean(disabled || loading)}
      className={classes.listItem}
      onClick={onClick}
    >
      {children}
    </ListItem>
  ) : (
    <ListItem disabled={Boolean(disabled || loading)} className={classes.listItem}>
      {children}
    </ListItem>
  )
}

const CustomListItem = ({
  loading = false,
  disabled = false,
  onClick,
  actionText,
  secondaryAction = 'nav',
  customComponent,
  tooltip = '',
  icon,
  primary,
  secondary,
  type = 'neutral',
}: Props) => {
  const classes = useStyles()
  const buttons = useButtonStyles()

  return (
    <ConditionalButtonListItem
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      secondaryAction={secondaryAction}
    >
      <>
        {icon && (
          <Tooltip placement="left" title={tooltip || ''} arrow>
            <ListItemAvatar>
              <Avatar className={buttons[type]}>
                <Badge color="primary" variant="dot" invisible={Boolean(!tooltip) || loading}>
                  {loading ? <CircularProgress size={18} /> : icon}
                </Badge>
              </Avatar>
            </ListItemAvatar>
          </Tooltip>
        )}
        {(primary || secondary) && (
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            secondaryTypographyProps={{ noWrap: true }}
            primary={primary || ''}
            secondary={loading ? 'Arbejder...' : secondary || ''}
          />
        )}
        {secondaryAction === 'nav' && (
          <>
            <Typography variant="body2" color="textSecondary" display="inline" noWrap>
              {actionText}
            </Typography>
            <ChevronRightIcon className={classes.chevron} />
          </>
        )}
        {secondaryAction === 'text' && (
          <ListItemSecondaryAction>
            <Typography variant="body1" color="primary" display="inline">
              {actionText}
            </Typography>
          </ListItemSecondaryAction>
        )}
        {secondaryAction === 'button' && (
          <ListItemSecondaryAction>
            <Button
              color="primary"
              size="small"
              className={classes.ctaButton}
              onClick={onClick}
              component="div"
            >
              {actionText}
            </Button>
          </ListItemSecondaryAction>
        )}
        {secondaryAction === 'custom' && customComponent && (
          <ListItemSecondaryAction>{customComponent}</ListItemSecondaryAction>
        )}
      </>
    </ConditionalButtonListItem>
  )
}

export default CustomListItem
