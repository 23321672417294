import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { TableRow, TableCell, IconButton, Avatar } from '@material-ui/core'
import { TableCellListItem } from 'components'
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Info as InfoIcon,
  ErrorOutline as ErrorOutlineIcon,
  Send as SendIcon,
  Drafts as DraftsIcon,
  ContactMail as ContactMailIcon,
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  status: {
    padding: '0.5rem 1rem',

    '& >div:first-of-type': {
      marginRight: '0.5rem',

      '& +div >p': {
        textTransform: 'capitalize',
      },
    },
  },
  sendTo: {
    color: theme.palette.primary.main,
  },
  cancled: {
    background: theme.palette.error.main,
  },
  completed: {
    background: theme.palette.success.main,
  },
  created: {
    background: theme.palette.secondary.main,
  },
  opened: {
    background: theme.palette.background.orange,
  },
  sendt: {
    background: theme.palette.info.main,
  },
}))

const statusIcon = (status: string) => {
  switch (status) {
    case 'cancled':
      return <ErrorOutlineIcon />
    case 'completed':
      return <CheckCircleOutlineIcon />
    case 'created':
      return <ContactMailIcon />
    case 'opened':
      return <DraftsIcon />
    case 'sendt':
      return <SendIcon />
    default:
      return null
  }
}

const NumberTableRow = ({ status, sendtTo, sendDateTime, openInviteDialog }) => {
  const classes = useStyles()
  const [date] = sendDateTime?.split(' ') || ' '
  return (
    <TableRow>
      <TableCell>
        <TableCellListItem secondary={status} className={classes.status}>
          <Avatar className={classes[status]}>{statusIcon(status)}</Avatar>
        </TableCellListItem>
      </TableCell>
      <TableCell>{date}</TableCell>
      <TableCell className={classes.sendTo}>{sendtTo}</TableCell>
      <TableCell padding="none" align="right" style={{ paddingRight: 8, maxWidth: 50, width: 50 }}>
        <IconButton onClick={openInviteDialog}>
          <InfoIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default NumberTableRow
