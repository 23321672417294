import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { TableCellListItem } from '@skytdc/mui/components'
import CapacityCell from './components/CapacityCell'

const CapacityGroupTableRow = ({ amountOfDidsMapped, description, maximumCapacity, id }) => {
  return (
    <TableRow hover>
      <TableCell>
        <TableCellListItem primary={description} secondary={id} disableGutters />
      </TableCell>
      <TableCell padding="none">
        <CapacityCell amount={amountOfDidsMapped} totalAmount={maximumCapacity} />
      </TableCell>
    </TableRow>
  )
}

export default CapacityGroupTableRow
