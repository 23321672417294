import React, { useState } from 'react'
import { TableBody, TableRow, TableCell, Table, Button } from '@material-ui/core'
import { CachedRounded as UpdateIcon } from '@material-ui/icons'
import {
  TableHead,
  TablePagination,
  LoadingTableRow,
  Paper,
  PaperContent,
  UtilityInput,
  PaperToolbar,
  LinearProgressBar,
} from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'
import matchSorter from 'match-sorter'
import OrdersTableRow from '../OrdersTableRow'

const OrdersTable = ({ orders, loading, openOrder, progress, updateOrders }) => {
  const { t } = useTranslation()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchQuery, setSearchQuery] = useState('')

  const headCells = [
    {
      id: 'status',
      label: 'Status',
      numeric: false,
      disablePadding: false,
      paddingLeft: false,
      sortable: false,
    },
    {
      id: 'intNumber',
      label: t('Drawer_mobile_forwarding_number'),
      numeric: false,
      disablePadding: false,
      paddingLeft: true,
      sortable: false,
    },
    {
      id: 'orderedBy',
      label: t('Organization_orders_orderedby'),
      numeric: false,
      disablePadding: false,
      paddingLeft: true,
      sortable: false,
    },
  ]

  const handleSetRowsPerPage = (newRows) => setRowsPerPage(newRows)

  const handleSetPage = (newPage) => setPage(newPage)

  const searchedOrders = matchSorter(orders, searchQuery, {
    keys: ['orderInfo.nationalNumber', 'orderInfo.productName', 'orderId', 'orderedby'],
    sorter: (rankedItems) => {
      return rankedItems
    },
    baseSort: (a, b) => {
      return a.index < b.index ? -1 : 1
    },
  })

  const handleSearch = (query) => {
    setPage(0)
    setSearchQuery(query)
  }

  const totalCount = orders.length

  return (
    <>
      <Paper>
        <PaperContent>
          <PaperToolbar>
            <UtilityInput
              onChange={handleSearch}
              value={searchQuery}
              placeholder={t('BuaSearch')}
            />
            <Button
              onClick={updateOrders.event}
              color="primary"
              disabled={updateOrders.disable}
              startIcon={<UpdateIcon />}
              style={{ marginLeft: 16 }}
            >
              {t('Organization_huntgroups_members_update_label')}
            </Button>
          </PaperToolbar>
        </PaperContent>
      </Paper>
      <div style={{ overflow: 'auto', marginTop: 16, width: '100%' }}>
        <LinearProgressBar
          title={t('Organization_avail_numbers_ordre_loading')}
          progress={progress}
        />
        <Table aria-labelledby="orders table" size="small">
          <TableHead headCells={headCells} utilityIcon={null} />
          <TableBody>
            {loading ? (
              <LoadingTableRow />
            ) : (
              searchedOrders
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((x) => {
                  return <OrdersTableRow key={x.orderId} {...x} openOrder={openOrder} />
                })
            )}
            {!loading && searchedOrders.length === 0 && (
              <TableRow>
                <TableCell colSpan={8}>{t('Organization_unassignedsimcards_noresult')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPage={rowsPerPage}
        page={page}
        totalCount={orders.length}
        setPage={handleSetPage}
        setRowsPerPage={handleSetRowsPerPage}
        showingFromTo={t('ShowingFromTo', {
          from: page * rowsPerPage + 1,
          to:
            page * rowsPerPage + rowsPerPage > totalCount
              ? totalCount
              : page * rowsPerPage + rowsPerPage,
          totalCount,
        })}
        perPage={t('PerPage')}
      />
    </>
  )
}

export default OrdersTable
