import palette from '../palette'

export default {
  root: {
    backgroundColor: palette.common.white,
    borderBottom: `2px solid ${palette.background.default}`,
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    '&$selected': {
      backgroundColor: palette.primary.light,
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: palette.primary.light,
        cursor: 'pointer',
      },
    },
  },
}
