import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  highlight: {
    fontWeight: 700,
  },
}))

const UnparsedChargeTableCell = ({ value }) => {
  const classes = useStyles()

  return (
    <Typography variant="inherit" className={classes.highlight} color="primary">
      {value.toLocaleString('da-DK', { minimumFractionDigits: 2 })}
    </Typography>
  )
}

export default UnparsedChargeTableCell
