import React from 'react'
import {
  LanguageOutlined as FreeTalkIcon,
  PermPhoneMsgOutlined as SupportIcon,
} from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import ListSwitch from 'components/ListSwitch'
import { useTranslation } from 'react-i18next'

const Addons = ({ addons, toggleAddon, loading, disabled }) => {
  const { t } = useTranslation()

  return (
    <>
      {addons.data &&
        addons.data.addons.length > 0 &&
        addons.data.addons.map((addon) => {
          return (
            <DrawerListItem
              key={addon.id}
              isLoading={Boolean(Number(addon.id) === Number(loading))}
              icon={addon.id === 528 ? <FreeTalkIcon /> : <SupportIcon />}
              primaryText={addon.name}
              secondaryText={addon.description || addon.name}
              isDisabled={disabled}
            >
              <ListSwitch
                value={addon.id}
                isChecked={Boolean(addon.active)}
                isDisabled={Boolean(loading || disabled)}
                onChange={toggleAddon}
                label={t('Drawer_user_activate_ppm', { price: addon.price })}
              />
            </DrawerListItem>
          )
        })}
    </>
  )
}

export default Addons
