import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { ListItem, ListItemText, Grid, List } from '@material-ui/core'
import { DonutChart } from 'components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    '& >li >div >span': {
      color: theme.palette.secondary.main,
      fontWeight: 700,
      '&+p': {
        fontWeight: 700,
        color: 'black',
      },
    },
  },
  firstListItem: {
    '&>span': {
      marginBottom: theme.spacing(2),
      fontSize: 15,
      '&+p': {
        fontSize: 38,
      },
    },
  },
  listItem: {
    '&>div': {
      paddingRight: 16,
      '&>span': {
        fontSize: 13,
        '&+p': {
          fontSize: 14,
        },
      },
    },
  },
  chartWrapper: {
    margin: '0 auto',
  },
}))

const GeneralInvoiceData = ({
  subscriptionFees,
  mobileFees,
  fixedLineFees,
  extraServiceFees,
  totalFees,
  billingDate,
  billingPeriod,
  ocr,
  title,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Grid container>
      <Grid item sx={12} md={3}>
        <DonutChart
          className={classes.chartWrapper}
          size={200}
          cutout="85%"
          data={[subscriptionFees, mobileFees, fixedLineFees, extraServiceFees]}
          backgroundColor={[
            theme.palette.background.light,
            theme.palette.primary.main,
            theme.palette.background.orange,
            theme.palette.background.pink,
          ]}
        />
      </Grid>
      <Grid item>
        <List className={classes.list}>
          <ListItem>
            <ListItemText
              className={classes.firstListItem}
              primary={title}
              secondary={totalFees.toLocaleString('da-DK')}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText primary={t('Invoice_period_td')} secondary={billingPeriod} />
            <ListItemText primary={t('Invoice_billingdate')} secondary={billingDate} />
            <ListItemText primary={t('Invoice_ocr')} secondary={ocr} />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}

export default GeneralInvoiceData
