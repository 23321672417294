import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    paddingTop: '0px !important',
    marginTop: -theme.spacing(2),
  },
  avatar: {
    width: '22px',
    height: '22px',
    marginRight: '6px',
    fontSize: '11px',
    backgroundColor: theme.palette.background.default,
    color: '#192228',
  },
  btnActive: {
    backgroundColor: 'rgba(0, 75, 146, 0.08)',
  },
}))

const RoleChip = ({ isActive, setActive, amount }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Button
        size="small"
        className={classNames({
          [classes.btnActive]: isActive,
        })}
        onClick={setActive}
        color="primary"
      >
        <Avatar className={classes.avatar}>{amount}</Avatar> {t('Has_roles')}
      </Button>
    </div>
  )
}

export default RoleChip
