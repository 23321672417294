import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'simcards/FETCH_FAILURE'
export const FETCH_BEGIN = 'simcards/FETCH_BEGIN'
export const FETCH_SUCCESS = 'simcards/FETCH_SUCCESS'

export const fetchSimcardsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchSimcardsBegin = ({ mobileNumber }) => {
  return {
    type: FETCH_BEGIN,
    mobileNumber,
  }
}

export const fetchSimcardsSuccess = ({ mobileNumber, data }) => {
  return {
    type: FETCH_SUCCESS,
    mobileNumber,
    data,
  }
}

export const fetchSimcards = ({ customerId, mobileNumber }) => {
  return async (dispatch) => {
    dispatch(fetchSimcardsBegin({ mobileNumber }))
    try {
      const response = await getSubscription.simcards({
        customerId,
        mobileNumber,
      })
      dispatch(fetchSimcardsSuccess({ mobileNumber, data: response.data }))
    } catch (err) {
      dispatch(fetchSimcardsFailure(err.response))
    }
  }
}
