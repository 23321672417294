import React from 'react'
import { Tooltip, ListItemIcon, IconButton } from '@material-ui/core'
import { CloudDownloadOutlined as DownloadIcon } from '@material-ui/icons'
import { getInvoice } from 'services/sweb/invoice'
import { useTranslation } from 'react-i18next'

const DownloadPdfIconButton = ({ invoiceId, customerId, canDownloadPdf }) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={canDownloadPdf ? t('Invoice_pdf') : t('Invoice_pdf_no')}>
      <ListItemIcon>
        <IconButton
          href={getInvoice.downloadPdf({ customerId, invoiceId })}
          target="_blank"
          disabled={!canDownloadPdf}
        >
          <DownloadIcon />
        </IconButton>
      </ListItemIcon>
    </Tooltip>
  )
}

export default DownloadPdfIconButton
