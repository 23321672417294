import React from 'react'
import { PaperFeedbackMessage, PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import TableWithFilteredTotal from '../../components/TableWithFilteredTotal'
import ExpandedNumberTable from './ExpandedNumberTable'
import UserNameTableCell from '../../components/UserNameTableCell'
import ExtraInfoInputNumbers from '../../components/ExtraInfoInputNumbers'
import ReferencePopperNumbers from '../../components/ReferencePopperNumbers'

const mergeData = (numbers, existingRefs, references) => {
  return numbers.items.map((number) => {
    const getUpdatedData =
      existingRefs.length > 0 &&
      existingRefs.find((refAndExtraInfo) => refAndExtraInfo.anr === number.anr)
    const getReference =
      references.length > 0 &&
      getUpdatedData &&
      references.find((ref) => getUpdatedData.reference_id === ref.reference_id)
    const data = {
      updatedSubData: getUpdatedData,
      reference: getReference,
      customerId: numbers.invoice.customer_id,
      snapshotExtraInfo: (getUpdatedData && getUpdatedData.description) || '',
      anr: number.anr,
    }
    const hasUserName = Boolean(
      getUpdatedData &&
        getUpdatedData &&
        getUpdatedData.user_name &&
        getUpdatedData.user_name.trim().length > 0
    )

    return {
      anr: number.anr,
      user_name: (hasUserName && getUpdatedData && getUpdatedData.user_name) || 'Organisation',
      extra_info: number.description || '',
      reference_name: (getReference && getReference.reference_name) || '',
      number_calls: number.number_calls,
      duration: number.duration.formatted,
      start_date: number.start_date,
      end_date: number.end_date,
      data,
      unparsed_charge: number.unparsed_charge,
    }
  })
}

const NumberSummaryTable = ({ data, invoiceId, existingRefs, references }) => {
  const { t } = useTranslation()
  const invoiceIdsMatch = Boolean(data.invoiceId === invoiceId)

  const handleRenderExpandableRow = ([anr]) => (
    <ExpandedNumberTable anr={anr} invoiceId={invoiceId} />
  )
  const options = {
    expandableRows: true,
    renderExpandableRow: handleRenderExpandableRow,
  }

  let columns = [
    {
      name: 'anr',
      label: t('Organization_huntgroups_td_2'),
      align: 'left',
      options: {
        sortDirection: 'asc',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'user_name',
      label: t('Invoice_association'),
      align: 'left',
      options: {
        hint: t('Invoice_association_desc'),
        filter: true,
        sort: true,
        customBodyRender: (value) => <UserNameTableCell value={value} />,
      },
    },
    {
      name: 'extra_info',
      label: t('Invoice_remark'),
      align: 'left',
      options: {
        hint: t('Invoice_remark_desc'),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <ExtraInfoInputNumbers
            value={value}
            updateValue={updateValue}
            data={tableMeta.rowData[7]}
          />
        ),
      },
    },
    {
      name: 'reference_name',
      label: t('Invoice_reff'),
      align: 'left',
      options: {
        hint: t('Invoice_ref_desc'),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          references.loading ? null : (
            <ReferencePopperNumbers
              value={value}
              updateValue={updateValue}
              data={tableMeta.rowData[7]}
              availableReferences={references.data.items}
            />
          ),
      },
    },
    {
      name: 'number_calls',
      label: t('Invoice_calls'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'duration',
      label: t('Invoice_duration'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'volumen.formatted',
      label: t('Invoice_volume'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'data',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
        searchable: false,
        download: false,
      },
    },
  ]

  if (data.error && invoiceIdsMatch) {
    return (
      <PaperFeedbackMessage
        message={t('Invoice_error', { error: data.error.statusText })}
        type="error"
        withMargin
      />
    )
  }

  if (data.data && data.data.items.length === 0 && invoiceIdsMatch) {
    return <PaperFeedbackMessage message={t('Invoice_no_occurences')} withMargin />
  }

  if (
    data.data &&
    data.data.items.length > 0 &&
    existingRefs.data &&
    references.data &&
    invoiceIdsMatch
  ) {
    const totalPrice = data.data.items
      .map((item) => item.unparsed_charge)
      .reduce((calculatedPrice, currentPrice) => calculatedPrice + currentPrice)

    const mergedData = mergeData(data.data, existingRefs.data.items, references.data.items)

    return (
      <TableWithFilteredTotal
        items={mergedData}
        firstTableColumns={columns}
        totalPrice={totalPrice}
        otherOptions={options}
      />
    )
  }

  return <PaperLoader />
}

export default NumberSummaryTable
