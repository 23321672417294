import React, { useState, useEffect, memo } from 'react'
import { InputAdornment, Link } from '@material-ui/core'
import { SkyTextField, GradientButton } from 'components'
import { Alert } from '@skytdc/mui/components'
import useDebounce from 'utils/hooks/useDebounce'
import { useTranslation, Trans } from 'react-i18next'
import StepContainer from '../StepContainer'
import TextBlock from '../TextBlock'

const StepOneWelcome = ({
  onChange,
  currentStep,
  setCurrentStep,
  loading,
  initialTenantId,
  lockFromEditing,
  initialTenantName,
  isPresence,
}) => {
  const { t } = useTranslation()
  const [tenant, setTenant] = useState({
    name: '',
    id: '',
  })
  const [debouncedTenant] = useDebounce(tenant, 700)

  useEffect(() => {
    const removeOnMicrosoft = initialTenantName && initialTenantName.replace('.onmicrosoft.com', '')
    setTenant({ id: initialTenantId, name: removeOnMicrosoft })
  }, [initialTenantId, initialTenantName])

  useEffect(() => {
    onChange(tenant)
  }, [debouncedTenant])

  const handleSetTenant = (event) => {
    setTenant({ ...tenant, [event.target.name]: event.target.value })
  }

  const handleSetCurrentStep = () => {
    setCurrentStep(currentStep + 1)
  }

  const nameHasError = /[^A-Za-z0-9]+/.test(tenant.name)
  const idHasError = /[^A-Za-z0-9-]+/.test(tenant.id)
  const hasValues = Boolean(tenant.name.length > 0 && tenant.id.length > 2)

  return (
    <StepContainer
      step={currentStep}
      requiredStep={0}
      stepLabel={t(isPresence ? 'Wizard_00658766' : 'Wizard_0065876')}
    >
      {isPresence ? (
        <TextBlock>
          <Trans i18nKey="Wizard_intro_presence" />
        </TextBlock>
      ) : (
        <>
          <TextBlock>{t('Wizard_awefawef')}</TextBlock>
          <TextBlock>
            <Link
              href="https://support.sky.tdc.dk/hc/da/articles/360009774700-S%C3%A5dan-udfylder-du-opstarts-wizard-en-i-Teams-Selvbetjening"
              target="_blank"
            >
              {t('Wizard_9rthg')}
            </Link>
          </TextBlock>
          <TextBlock>
            <Trans
              i18nKey="Wizard_0wef0awef"
              components={[<strong>Custom Primary Domain Name</strong>, <strong>Tenant ID</strong>]}
            />
            <Link href="https://portal.azure.com" target="_blank">
              https://portal.azure.com
            </Link>
            .
          </TextBlock>
          <TextBlock component="span" inputMargin>
            <ul>
              <li>
                <Trans
                  i18nKey="Wizard_32452345"
                  components={[
                    <strong>Custom Primary Domain Name</strong>,
                    <strong>Azure Active Directory</strong>,
                    <strong>Custom Domain Names</strong>,
                    <strong>onmicrosoft.com</strong>,
                    <strong>tdc.onmicrosoft.com</strong>,
                    <strong>onmicrosoft.com</strong>,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="Wizard_00sthr"
                  components={[
                    <strong>Tenant ID</strong>,
                    <strong>Azure Active Directory</strong>,
                    <strong>Properties</strong>,
                    <strong>Tenant ID</strong>,
                  ]}
                />
              </li>
            </ul>
          </TextBlock>
          <Alert message={t('AzureLockedFromEditing')} withMargin />
        </>
      )}
      <SkyTextField
        white
        inputProps={{
          endAdornment: <InputAdornment position="end">.onmicrosoft.com</InputAdornment>,
        }}
        value={tenant.name}
        name="name"
        onChange={handleSetTenant}
        disabled={loading || lockFromEditing}
        label="Custom Primary Domain Name"
        error={nameHasError}
        helperText={nameHasError && t('Wizard_sthr')}
      />
      <SkyTextField
        white
        value={tenant.id}
        name="id"
        onChange={handleSetTenant}
        disabled={loading || lockFromEditing}
        label="Tenant ID"
        error={idHasError}
        helperText={idHasError && t('Wizard_9trhs')}
      />
      <GradientButton
        disabled={!hasValues || nameHasError || idHasError || currentStep > 0}
        onClick={handleSetCurrentStep}
      >
        {t('Hardware_next')}
      </GradientButton>
    </StepContainer>
  )
}

export default memo(StepOneWelcome)
