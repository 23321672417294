import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Alert } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { updateCustomer } from 'services/sweb/customer'
import { fetchDataUsageWarningEmails } from 'state/dataUsageWarningEmails/actions'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import {
  Paper,
  PaperLoader,
  PaperFeedbackMessage,
  FloatingButton,
  CustomDialog,
  SkyTextField,
} from 'components'
import { useTranslation } from 'react-i18next'
import { EmailOutlined as EmailIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    paddingTop: 0,
    maxWidth: 475,
    marginBottom: theme.spacing(5),
  },
  section: {
    paddingTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  actions: {
    marginRight: -theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1.5),
  },
  list: {
    marginTop: -theme.spacing(),
  },
}))

const DataUsageWarning = ({ match }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [addEmail, setAddEmail] = useState('')
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const dataUsageWarningEmails = useSelector((state) => state.dataUsageWarningEmails)

  useEffect(() => {
    dispatch(fetchDataUsageWarningEmails({ customerId: match.params.customer_id }))
  }, [])

  const handleOpenDialog = () => setDialogOpen(true)

  const handleCloseDialog = () => setDialogOpen(false)

  const handleOnChange = (event) => {
    setAddEmail(event.target.value)
  }

  const updateDataUsageEmails = (event) => {
    event.preventDefault()
    setLoading(true)
    const json = {
      email_addresses: [...dataUsageWarningEmails.data, { email_address: addEmail }],
    }
    updateCustomer
      .dataUsageEmails({ customerId: match.params.customer_id, json })
      .then(() => {
        enqueueSnackbar(t('DataUsageSuccess'), {
          variant: 'success',
        })
        dispatch(fetchDataUsageWarningEmails({ customerId: match.params.customer_id }))
        setLoading(false)
        setDialogOpen(false)
        setAddEmail('')
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  const removeEmail = (email) => {
    setLoading(true)
    const json = {
      email_addresses: dataUsageWarningEmails.data.filter((x) => x.email_address !== email),
    }
    updateCustomer
      .dataUsageEmails({ customerId: match.params.customer_id, json })
      .then(() => {
        enqueueSnackbar(t('DataUsageEmailRemoved'), {
          variant: 'success',
        })
        dispatch(fetchDataUsageWarningEmails({ customerId: match.params.customer_id }))
        setLoading(false)
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  let content = <PaperLoader />

  if (dataUsageWarningEmails.error) {
    content = (
      <PaperFeedbackMessage
        message={dataUsageWarningEmails.error.statusText}
        type="error"
        withMargin
        disableGutters={false}
      />
    )
  }

  if (dataUsageWarningEmails.data) {
    content = (
      <>
        {dataUsageWarningEmails.data.length > 0 ? (
          <List className={classes.list}>
            {dataUsageWarningEmails.data.map((x) => {
              return (
                <ListItem key={x.email_address} button>
                  <EmailIcon className={classes.icon} />
                  <ListItemText primary={x.email_address} />
                  <ListItemSecondaryAction>
                    <Button
                      onClick={() => removeEmail(x.email_address)}
                      color="primary"
                      size="small"
                    >
                      {t('Remove')}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        ) : (
          <Alert severity="info">{t('DataUsageNoEmails')}</Alert>
        )}
        <FloatingButton
          tooltip={t('Wizard_add')}
          size="small"
          onClick={handleOpenDialog}
          className={classes.button}
        />
        <CustomDialog
          isOpen={dialogOpen}
          onConfirm={updateDataUsageEmails}
          onCancel={handleCloseDialog}
          title="Tilføj e-mail"
          withDialogActions={false}
        >
          <form onSubmit={updateDataUsageEmails} style={{ marginTop: 4 }}>
            <SkyTextField
              label={t('DataUsageTypeEmail')}
              value={addEmail}
              type="email"
              onChange={handleOnChange}
              disabled={loading}
              required
              autoFocus
            />
            <DialogActions className={classes.actions}>
              <Button onClick={handleCloseDialog} color="primary" className={classes.button}>
                {t('Drawer_create_sub_cancel')}
              </Button>
              <Button color="primary" className={classes.button} disabled={loading} type="submit">
                {t('Wizard_add')}
              </Button>
            </DialogActions>
          </form>
        </CustomDialog>
      </>
    )
  }

  return (
    <Paper>
      <div className={classes.container}>
        <Typography variant="body1" gutterBottom>
          <strong>{t('DataUsageWarning')}</strong>
        </Typography>
        <Typography variant="body1">{t('DataUsageDescription')}</Typography>
        <div className={classes.section}>{content}</div>
      </div>
    </Paper>
  )
}

export default DataUsageWarning
