import React from 'react'
import classNames from 'classnames'
import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    cursor: 'pointer',
    height: 46,
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.shortest,
    }),
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      '& $listItemText': {
        color: theme.palette.primary.main,
      },
      '& $listItemIcon': {
        color: theme.palette.primary.main,
      },
      textDecoration: 'none',
    },
    '& + &': {
      marginTop: theme.spacing(0.5),
    },
  },
  activeListItem: {
    backgroundColor: theme.palette.primary.light,
    '& $listItemText': {
      color: theme.palette.primary.main,
    },
    '& $listItemIcon': {
      color: theme.palette.primary.main,
    },
  },
  listItemIcon: {
    marginRight: 0,
    alignItems: 'center',
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.shortest,
    }),
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  listItemText: {
    color: theme.palette.text.primary,
  },
}))

interface Props {
  isActive: boolean
  url: string
  changeRoute?: (url: string) => void
  icon: JSX.Element
  tooltip?: string
  disabled?: boolean
  primaryText: string
  secondaryText?: string
  secondaryAction?: JSX.Element
  isMobile: boolean
}

const NavLink = ({
  isActive,
  url,
  icon,
  tooltip = '',
  disabled = false,
  primaryText,
  secondaryText,
  secondaryAction,
  changeRoute,
  isMobile,
}: Props) => {
  const classes = useStyles()

  const listItemClasses = classNames([classes.listItem], {
    [classes.activeListItem]: isActive,
  })

  return (
    <Tooltip title={tooltip} placement="right" arrow>
      <ListItem
        disabled={disabled}
        className={listItemClasses}
        onClick={() => (changeRoute ? changeRoute(url) : window.open(url, '_blank'))}
      >
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        {!isMobile && (
          <>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={primaryText}
              secondary={secondaryText}
            />
            {secondaryAction}
          </>
        )}
      </ListItem>
    </Tooltip>
  )
}

export default NavLink
