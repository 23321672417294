import * as microsoftTeams from '@microsoft/teams-js'
import queryString from 'query-string'
import { MS_AUTH_CLIENT_ID, PARTY_MS_AUTH_CLIENT_ID } from 'constants/configurable'
import {
  MS_AUTH_REDIRECTED_LOCALSTORAGE_KEY_PREFIX,
  MS_AUTH_REDIRECTED_OUTSIDE_TEAMS_CALLBACK_URI,
  MS_AUTH_REDIRECTED_INSIDE_TEAMS_INVOKE_URI,
  MS_AUTH_REDIRECTED_INSIDE_TEAMS_CALLBACK_URI,
  MS_AUTH_REDIRECTED_PARTY_OUTSIDE_TEAMS_CALLBACK_URI,
} from 'constants/app'

microsoftTeams.initialize()

const redirectedStorageKeyArr = [
  `${MS_AUTH_REDIRECTED_LOCALSTORAGE_KEY_PREFIX}OUTSIDE_TEAMS.CALLBACK`,
]

const windowOpenAttrStr = () => {
  const winLeft = window.screenLeft || window.screenX
  const initialHeight = 433
  const initialWidth = 420
  const { outerHeight, outerWidth } = window
  const winHeight = outerHeight < initialHeight ? outerHeight : initialHeight
  const winWidth = outerWidth < initialWidth ? outerWidth : initialWidth
  const calcLeft = winLeft / 2 - winWidth
  const calcTop = outerHeight / 2 - winHeight / 2
  return `toolbar=no, location=yes, status=no, menubar=no, scrollbars=yes, top=${calcTop}, left=${calcLeft}, width=${winWidth}, height=${winHeight}`
}

const getRedirectedStorageKey = (lsKey) => {
  const k = `${MS_AUTH_REDIRECTED_LOCALSTORAGE_KEY_PREFIX}${lsKey}`
  const idx = redirectedStorageKeyArr.indexOf(k)
  const val = redirectedStorageKeyArr[idx]
  if (!val) {
    throw new TypeError('storage does not supply the given key')
  }
  return k
}

const cleanRedirectedStorage = (rmLsKeys = redirectedStorageKeyArr) => {
  const lsItemKeys = Object.keys(window.localStorage)
  lsItemKeys.forEach((lsKey) => {
    if (rmLsKeys.includes(lsKey)) {
      window.localStorage.removeItem(lsKey)
    }
  })
}

cleanRedirectedStorage()

const obtainRedirectUri = (insideTeams = false, partyAccess = false) => {
  if (!insideTeams && partyAccess) {
    return MS_AUTH_REDIRECTED_PARTY_OUTSIDE_TEAMS_CALLBACK_URI
  }
  if (!partyAccess && insideTeams) return MS_AUTH_REDIRECTED_INSIDE_TEAMS_CALLBACK_URI
  return MS_AUTH_REDIRECTED_OUTSIDE_TEAMS_CALLBACK_URI
}

const createAuthSrcFromMsContext = (ctx) => {
  const tenant = ctx?.tid || 'common'
  const loginParams =
    ctx && ctx.loginHint ? `&login_hint=${ctx.loginHint}` : '&prompt=select_account'
  const redirectUri = obtainRedirectUri(ctx.insideTeams, ctx.partyAccess)
  const authSrc = `https://login.microsoftonline.com/${tenant}/oauth2/authorize?client_id=${
    ctx.partyAccess ? PARTY_MS_AUTH_CLIENT_ID : MS_AUTH_CLIENT_ID
  }&scope=openid+User.Read&response_type=code&response_mode=fragment&resource=https%3A%2F%2Fgraph.microsoft.com%2F&redirect_uri=${redirectUri}${loginParams}`
  return authSrc
}

const createAttemptAuthViaIframe = (authSrc) => {
  const elemId = 'MSAUTHIFRAMEELEM'
  const currAuthIframeElem = document.getElementById(elemId)
  if (currAuthIframeElem) {
    currAuthIframeElem.remove()
  }
  const authIframe = document.createElement('iframe')
  authIframe.setAttribute('id', 'authIframe')
  authIframe.setAttribute(
    'sandbox',
    'allow-forms allow-modals allow-popups allow-pointer-lock allow-scripts allow-same-origin allow-top-navigation'
  )
  authIframe.setAttribute('src', authSrc)
  authIframe.setAttribute('height', 0)
  authIframe.setAttribute('style', 'border: 0;')
  const authElem = document.body.appendChild(authIframe)
  return new Promise((resolve, reject) => {
    authElem.onload = () => {
      const contentW = authIframe.contentWindow || authIframe.contentDocument
      try {
        const locationSearch = contentW.location.search
        const res = queryString.parse(locationSearch)
        if (!res.code) reject(res)
        resolve(res)
      } catch (err) {
        reject(err)
      }
      authElem.remove()
    }
  })
}

const isBrowserSafari = () => {
  const vendor = window.navigator.vendor || ''
  const vendorStr = vendor.toLowerCase()
  return vendorStr.includes('apple')
  // for iPhone users running chrome, vendor would be recognized as apple
  // which means those users will be treated as if they were using safari, stuff will not be broken though just another experience.
}

export const solveMsTeamsContext = (ctx = {}) => {
  return new Promise((resolve) => {
    // unless used inside teams, the method "getContext" never fires
    // this function will always execute and awaits to see if context can resolve, and resolves with the result
    const base = { insideTeams: false, ...ctx }
    microsoftTeams.getContext((context) => {
      Object.assign(base, { insideTeams: true }, context, ctx) // provide ctx to overwrite given parts of context
    })
    setTimeout(() => {
      resolve(base)
    }, 200)
  })
}

let authViaWindowOpenTimer
const windowAuthentication = (authSrc) => (resolve, reject) => {
  clearInterval(authViaWindowOpenTimer)
  const storageKey = getRedirectedStorageKey('OUTSIDE_TEAMS.CALLBACK')

  window.open(authSrc, 'MSAUTHOUTSIDETEAMSWINDOW', windowOpenAttrStr())
  const timerMS = 750 // 0.75 sec

  authViaWindowOpenTimer = setInterval(() => {
    const storageResultJsonStr = window.localStorage.getItem(storageKey)
    if (storageResultJsonStr) {
      const result = JSON.parse(storageResultJsonStr)
      clearInterval(authViaWindowOpenTimer)
      window.localStorage.removeItem(storageKey)
      if (!result.code) reject(result)
      resolve(result)
    }
  }, timerMS)
}

const authOutsideTeams = (resolve, reject, userEmail, partyAccess) => {
  const authSrc = createAuthSrcFromMsContext({ tid: 'common', loginHint: userEmail, partyAccess })
  const isSafari = isBrowserSafari()
  if (isSafari) {
    /*
      Safari (Apple’s ITP 2.0) is having security policies about prompting window and redirection, it results in when authentication redirects back, the code query paramater is stripped.
      Therefor instead redirect within the current window for Safari users.
    */
    window.location.replace(authSrc)
  } else {
    windowAuthentication(authSrc)(resolve, reject)
  }
}

const authInsideTeams = (resolve, reject) => {
  microsoftTeams.authentication.authenticate({
    url: MS_AUTH_REDIRECTED_INSIDE_TEAMS_INVOKE_URI,
    width: 600,
    height: 535,
    successCallback: (result) => {
      resolve(result)
    },
    failureCallback: (err) => {
      reject(err)
    },
  })
}

export const authViaWindowOpen = ({ partyAccess = false, userEmail = '' } = {}) => {
  cleanRedirectedStorage()
  return new Promise((resolve, reject) => {
    solveMsTeamsContext({ tid: 'common' }).then((ctx) => {
      if (ctx.insideTeams === false) {
        authOutsideTeams(resolve, reject, userEmail, partyAccess)
      } else {
        authInsideTeams(resolve, reject)
      }
    })
  })
}

export const attemptSilentAuthViaIframe = () => {
  cleanRedirectedStorage()
  return solveMsTeamsContext().then((ctx) => {
    const authSrc = createAuthSrcFromMsContext(ctx)
    return createAttemptAuthViaIframe(authSrc)
  })
}
