import client from '../client'

export const all = () => {
  return client.get('customer', { timeout: false }).json()
}

export const teamsUsage = ({ customerId }) => {
  return client.get(`teams/${customerId}/teams-user-activity?period=180`).json()
}

export const single = ({ customerId }) => {
  return client.get(`customer/${customerId}/extended`).json()
}

export const portfolio = ({ customerId }) => {
  return client.get(`products/${customerId}/portfolio`).json()
}

export const availableLandlineNumbers = ({ customerId, oneNumberType }) => {
  return client
    .get(
      `user-services/${customerId}/landline-numbers${
        oneNumberType ? `?onenumber_type=${oneNumberType}` : ''
      }`
    )
    .json()
}

export const availableInternationalNumbers = ({ customerId, oneNumberType }) => {
  return client
    .get(
      `user-services/${customerId}/international-numbers${
        oneNumberType ? `?onenumber_type=${oneNumberType}` : ''
      }`
    )
    .json()
}

export const availableAtRestMobileNumbers = ({ customerId }) => {
  return client.get(`user-services/${customerId}/at-rest-numbers/3`).json()
}

export const availableReleaseMobileNumbers = ({ customerId }) => {
  return client.get(`user-services/${customerId}/available-numbers/3`).json()
}

export const availableUnassignedSimcards = ({ customerId }) => {
  return client.get(`simcard/${customerId}/reserved`).json()
}

export const subscriptionCount = ({ customerId }) => {
  return client.get(`products/${customerId}/count`).json()
}

export const sharedNumbers = ({ customerId }) => {
  return client.get(`display-number/${customerId}/shared-numbers`).json()
}

export const searchCrossCustomers = ({ term }) => {
  return client.get(`customer/search`, { searchParams: { term } }).json()
}

export const azureHealthCheck = ({ customerId }) => {
  const timeout = 45000
  return client.get(`teams-psweb/health-check/${customerId}`, { timeout }).json()
}

export const azureAppSecret = ({ customerId }) => {
  return client.get(`teams/${customerId}/app-secret`).json()
}
export const presenceAppSecret = ({ customerId }) => {
  return client.get(`teams/${customerId}/presence-secret`).json()
}

export const presenceHealthCheck = ({ customerId }) => {
  const timeout = 45000
  return client.get(`teams-psweb/health-check-presence/${customerId}`, { timeout }).json()
}

export const azureAdminSecret = ({ customerId }) => {
  return client.get(`teams/${customerId}/admin-secret`).json()
}

export const availableRoles = () => {
  return client.get(`authorization/publicly-available-roles`).json()
}

export const authorizations = ({ customerId }) => {
  return client.get(`authorization/authorizations/${customerId}`, { timeout: false }).json()
}

export const eInvoices = ({ customerId }) => {
  return client.get(`customer/${customerId}/einvoice`).json()
}

export const pricedServices = ({ customerId }) => {
  return client.get(`simcard/${customerId}/priced-services`).json()
}

// 669 fetches the "Teams Mobil Ringegruppe" subscription for this customer id
export const huntgroupSubscription = ({ customerId, subId = 669 }) => {
  return client.get(`user-services/${customerId}/subscriptiontypes/${subId}`).json()
}

export const huntgroups = ({ customerId }) => {
  return client.get(`cirpack/${customerId}`).json()
}

export const huntgroup = ({ customerId, number }) => {
  return client.get(`cirpack/${customerId}/${number}`, { timeout: false }).json()
}

export const availableHuntgroupNumbers = ({ customerId }) => {
  return client.get(`cirpack/${customerId}/numbers`).json()
}

export const huntgroupCallPatterns = () => {
  return client.get(`cirpack/rules`).json()
}

export const availableHuntgroupMembers = ({ customerId, number }) => {
  return client.get(`cirpack/${customerId}/${number}/potential`).json()
}

export const orderOverview = ({ customerId }) => {
  return client.get(`customer/${customerId}/orders`).json()
}

export const orderDetailsOverview = ({ customerId }) => {
  return client.get(`customer/${customerId}/order_details`).json()
}

export const order = ({ customerId, type, orderId }) => {
  return client.get(`customer/${customerId}/orders/${type}/${orderId}`).json()
}

export const adGroups = ({ customerId }) => {
  return client.get(`teams/${customerId}/groups`, { timeout: false }).json()
}

export const adGroupFilters = ({ customerId }) => {
  return client.get(`teams/${customerId}/filter-groups`, { timeout: false }).json()
}

export const psWebScripts = ({ customerId }) => {
  return client.get(`teams-psweb/list-scripts/${customerId}`).json()
}

export const dataUsageEmails = ({ customerId }) => {
  return client.get(`customer/${customerId}/data`).json()
}

export const buaOrders = ({ customerId }) => {
  return client
    .get(`teams/orders`, {
      searchParams: { dataPairs: `customer_id,${customerId}` },
    })
    .json()
}

export const internationalNumbersV2 = ({ customerId, pageSize, pageNumber }) => {
  return client
    .get(`internationalnumbers/${customerId}/international-numbers-b`, {
      searchParams: {
        pagesize: pageSize || '',
        pagenumber: pageNumber,
      },
      timeout: false,
    })
    .json()
}

export const azureDomains = ({ customerId }) => {
  return client
    .get(`internationalnumbers/${customerId}/azure-domains`, { timeout: false }) // ?testMode=true
    .json()
}

export const intNumberMediation = ({ customerId, e164 /* , pagesize, pagenumber */ }) => {
  return client
    .get(`internationalnumbers/${customerId}/mediation`, {
      searchParams: {
        e164,
        pagenumber: 0,
        pagesize: 1000,
        customerIdFilter: customerId,
      },
    })
    .json()
}

export const intNumbersVoxbone = ({ customerId, e164 /* , pagesize, pagenumber */ }) => {
  return client
    .get(`internationalnumbers/${customerId}/voxbone`, {
      searchParams: {
        e164,
        // pagenumber: 0,
        // pagesize: 10,
        customerid: customerId,
      },
    })
    .json()
}

export const intNumbersVoxboneVoiceUri = ({ customerId, id, pagesize, pagenumber }) => {
  return client
    .get(`internationalnumbers/${customerId}/voxbone/voiceuri`, {
      searchParams: {
        id,
        pagenumber,
        pagesize,
        customerid: customerId,
      },
    })
    .json()
}

export const intNumbersVoxboneCapacityGroup = ({
  customerId,
  id,
  pageNumber = 0,
  pageSize = 100,
}) => {
  return client
    .get(`internationalnumbers/${customerId}/voxbone/capacitygroup`, {
      searchParams: {
        ...(id && { id }),
        pagenumber: pageNumber,
        pagesize: pageSize,
      },
    })
    .json()
}

export const intNumberOrders = ({ customerId, pageNumber = 0, pageSize = 500 }) => {
  return client
    .get(`internationalnumbers/${customerId}/config-order`, {
      searchParams: {
        pagenumber: pageNumber,
        pagesize: pageSize,
      },
      timeout: false,
    })
    .json()
}

export const intNumberVoxboneCountries = ({ customerId }) => {
  return client
    .get(`internationalnumbers/${customerId}/voxbone/country`, {
      searchParams: {
        customerid: customerId,
      },
    })
    .json()
}

export const intNumberBillingCountries = ({ customerId }) => {
  return client
    .get(`internationalnumbers/${customerId}/country`, {
      searchParams: {
        customerid: customerId,
        pagesize: 1000,
      },
    })
    .json()
}

export const intNumbersVoxboneStock = ({
  customerId,
  e164 = '',
  pagesize,
  pagenumber,
  countryCode = '',
}) => {
  return client
    .get(`internationalnumbers/${customerId}/voxbone`, {
      searchParams: {
        e164,
        pagenumber,
        pagesize,
        customerid: customerId,
        countrycode: countryCode,
      },
    })
    .json()
}

export const intNumberAddonProducts = ({ customerId }) => {
  return client.get(`internationalnumbers/${customerId}/products`).json()
}

export const externalProviders = () => {
  return client.get(`user-services/external-operators`).json()
}

// Operator connect endpoints
export const OCLandlineNumbers = ({ customerId, pageSize, pageNumber }) => {
  return client
    .get(`operator-connect/subscriptions/${customerId}`, {
      searchParams: {
        pagesize: pageSize || '',
        pagenumber: pageNumber,
      },
      timeout: false,
    })
    .json()
}

export const OCLandlineNumberOrders = ({ customerId, pageSize, pageNumber }) => {
  return client
    .get(`operator-connect/orders/${customerId}`, {
      searchParams: {
        pagesize: pageSize || '',
        pagenumber: pageNumber,
      },
      timeout: false,
    })
    .json()
}
export const OCUsers = ({ customerId }) => {
  return client
    .get(`operator-connect/admins/${customerId}`, {
      timeout: false,
    })
    .json()
}
export const OCInvites = ({ customerId }) => {
  return client
    .get(`operator-connect/invite/${customerId}`, {
      timeout: false,
    })
    .json()
}
export const oCNumbersProducts = ({ customerId }) => {
  return client
    .get(`operator-connect/products/${customerId}`, {
      timeout: false,
    })
    .json()
}

export const OCNumbers = ({ customerId, e164 = '', pagesize, pagenumber }) => {
  return client
    .get(`operator-connect/numbers/${customerId}`, {
      searchParams: {
        e164,
        pagenumber,
        pagesize,
        customerid: customerId,
      },
    })
    .json()
}
