import React, { memo } from 'react'
import { DrawerListItem, DrawerSection } from 'components'
import {
  SimCardOutlined as SimcardIcon,
  CalendarTodayOutlined as CalendarIcon,
  PersonOutlined as PersonIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    padding: theme.spacing(2),
  },
  message: {
    paddingTop: 0,
    padding: theme.spacing(3),
  },
}))

const formatDate = (date) => {
  const dateObj = new Date(date)
  const month = dateObj.getUTCMonth() + 1
  const day = dateObj.getUTCDate()
  const year = dateObj.getUTCFullYear()
  return `${day}-${month}-${year}`
}

const ActiveSimcardOrder = ({ order }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <DrawerSection title={t('Drawer_sim_order_title')}>
        <Typography>{t('Drawer_sim_order_has_order')}</Typography>
      </DrawerSection>
      <DrawerListItem
        icon={<SimcardIcon />}
        primaryText={t('Drawer_sim_order_icc')}
        secondaryText={order.new_simcard.icc}
        secondaryActionButton={t('Drawer_sim_order_copy')}
      />
      <DrawerListItem
        icon={<PersonIcon />}
        primaryText={t('Organization_orders_orderedby')}
        secondaryText={order.status.changedBy}
      />
      <DrawerListItem
        icon={<CalendarIcon />}
        primaryText={t('Organization_orders_order_date')}
        secondaryText={formatDate(order.status.changeDate)}
      />
      {order.ticket_id && (
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            href={`https://support.sky.tdc.dk/hc/da/requests/${order.ticket_id}`}
            target="_blank"
          >
            {t('Drawer_sim_order_go_to_support')}
          </Button>
        </div>
      )}
    </>
  )
}

export default memo(ActiveSimcardOrder)
