import React from 'react'
import { Switch, Route } from 'react-router-dom'
import NotFound from '../views/NotFound'
import InvitePage from '../views/OC/InvitePage'

export default React.memo(() => {
  return (
    <Switch>
      <Route path="/service/operator-connect/invite/:uuid?" component={InvitePage} />
      <Route component={NotFound} />
    </Switch>
  )
})
