import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'billingCountries/FETCH_FAILURE'
export const FETCH_BEGIN = 'billingCountries/FETCH_BEGIN'
export const FETCH_SUCCESS = 'billingCountries/FETCH_SUCCESS'

export const fetchBillingCountriesFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchBillingCountriesBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchBillingCountriesSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchBillingCountries = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchBillingCountriesBegin({ customerId }))
    try {
      const response = await getCustomer.intNumberBillingCountries({
        customerId,
      })
      dispatch(fetchBillingCountriesSuccess(response.data))
    } catch (err) {
      dispatch(fetchBillingCountriesFailure(err.response))
    }
  }
}
