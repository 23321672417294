import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.divider,
    width: 184,
    boxShadow: '-10px 0px 10px 2px #000000',
    overflow: 'hidden',
    marginTop: -2,
    height: '100vh',
    position: 'fixed',
    padding: theme.spacing(),
  },
}))

const SubSidebar = ({ children }) => {
  const classes = useStyles()
  return <Box className={classes.root}>{children}</Box>
}

export default SubSidebar
