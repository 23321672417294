import React from 'react'
import { DialogWrapper } from 'components'
import { useTranslation } from 'react-i18next'
import SetGroupNumbers from './components/SetGroupNumbers'
import SetDisplayNumber from './components/SetDisplayNumber'

const BulkActionDialog = ({
  selectedUsers,
  open,
  handleClose,
  action,
  title,
  description,
  customerId,
}) => {
  const { t } = useTranslation()

  return (
    <DialogWrapper open={open} title={t(title)} description={t(description)} onClose={handleClose}>
      {action === 'set-available-group-numbers' && (
        <SetGroupNumbers
          selectedUsers={selectedUsers}
          customerId={customerId}
          handleClose={handleClose}
        />
      )}
      {action === 'set-display-number' && (
        <SetDisplayNumber
          selectedUsers={selectedUsers}
          customerId={customerId}
          handleClose={handleClose}
        />
      )}
    </DialogWrapper>
  )
}

export default BulkActionDialog
