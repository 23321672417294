import palette from '../palette'

export default {
  container: {
    listStyleType: 'none',
  },
  button: {
    '&:hover': {
      backgroundColor: palette.background.default,
    },
  },
  /* gutters: {
    paddingLeft: 8,
    paddingRight: 8,
  }, */
}
