import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'displayNumberWeeklySchedule/FETCH_FAILURE'
export const FETCH_BEGIN = 'displayNumberWeeklySchedule/FETCH_BEGIN'
export const FETCH_SUCCESS = 'displayNumberWeeklySchedule/FETCH_SUCCESS'

export const fetchDisplayNumberWeeklyScheduleFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchDisplayNumberWeeklyScheduleBegin = ({ number }) => {
  return {
    type: FETCH_BEGIN,
    number,
  }
}

export const fetchDisplayNumberWeeklyScheduleSuccess = ({ number, data }) => {
  return {
    type: FETCH_SUCCESS,
    number,
    data,
  }
}

export const fetchDisplayNumberWeeklySchedule = (number: string) => {
  return async (dispatch) => {
    dispatch(fetchDisplayNumberWeeklyScheduleBegin({ number }))
    try {
      const response = await getSubscription.displayNumberWeeklySchedule(number)
      dispatch(fetchDisplayNumberWeeklyScheduleSuccess({ number, data: response.data }))
    } catch (err) {
      dispatch(fetchDisplayNumberWeeklyScheduleFailure(err.response))
    }
  }
}
