import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'state/reducers'
import { fetchOCUsers } from 'state/oCUsers/actions'
import { AddCircleRounded as AddIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import Layout from 'views/Layout'
import { PageTitle, PageTabs } from '@skytdc/mui/components'
import OCAdmins from './OCAdmins'
import OCAdminsInvites from './OCAdminsInvites'

const getCurrentTabValue = (currentPath: string) => {
  if (currentPath.includes('admins')) return 'admins'
  if (currentPath.includes('brugere-invitationer')) return 'brugere-invitationer'
  return 'admins'
}

const OCUsers = ({ history, match }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { customer_id: customerId } = match.params
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { loading, data, error } = useSelector((state: AppState) => state.oCUsers)

  const disableCreateButton = !data || data?.consentStatus?.statusCode > 0

  const fetchUsers = () => {
    dispatch(fetchOCUsers({ customerId }))
  }
  useEffect(() => {
    fetchUsers()
  }, [])

  // for now, only show when standing on assigned numbers
  const routeIsAssignedNumbers = match.url.includes('/admins')

  const handleChangePage = (newPage: string) => {
    history.push(`/${customerId}/operator-connect/brugere/${newPage}`)
  }

  const handleDialogState = () => {
    setDialogOpen((prevState) => !prevState)
  }
  const oCUserData = { loading, data, error, fetchUsers }
  return (
    <Layout>
      <div style={{ maxWidth: 1000, margin: 'auto' }}>
        <PageTitle title={t('Invoice_org')}>
          {routeIsAssignedNumbers && (
            <Button
              variant="contained"
              disableElevation
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleDialogState}
              disabled={disableCreateButton}
            >
              {t('OC_add_newUser')}
            </Button>
          )}
        </PageTitle>
        <PageTabs
          currentValue={getCurrentTabValue(match.url)}
          handleChange={handleChangePage}
          withMargin
          tabs={[
            {
              value: 'admins',
              label: 'Admins',
              disabled: false,
            },
            {
              value: 'brugere-invitationer',
              label: t('OC_invited_users'),
              disabled: false,
            },
          ]}
        />
        <Switch>
          <Route
            render={(props) => (
              <OCAdmins
                {...props}
                customerId={customerId}
                createNewUser={{ dialogOpen, handleDialogState }}
                oCUserData={oCUserData}
              />
            )}
            exact
            path={`/${customerId}/operator-connect/brugere/admins`}
          />
          <Route
            render={(props) => <OCAdminsInvites {...props} customerId={customerId} />}
            exact
            path={`/${customerId}/operator-connect/brugere/brugere-invitationer`}
          />
          <Redirect to={`/${customerId}/operator-connect/brugere/admins`} />
        </Switch>
      </div>
    </Layout>
  )
}

export default OCUsers
