import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceReferenceSpecifications/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceReferenceSpecifications/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceReferenceSpecifications/FETCH_SUCCESS'

export const fetchInvoiceReferenceSpecificationsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceReferenceSpecificationsBegin = ({ invoiceId, referenceId }) => {
  return {
    type: FETCH_BEGIN,
    referenceId,
    invoiceId,
  }
}

export const fetchInvoiceReferenceSpecificationsSuccess = ({
  referenceId,
  invoiceId,
  response,
}) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    referenceId,
    invoiceId,
  }
}

export const fetchInvoiceReferenceSpecifications = ({ customerId, invoiceId, referenceId }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceReferenceSpecificationsBegin({ referenceId }))
    try {
      const response = await getInvoice.referenceSpecifications({
        customerId,
        referenceId,
        invoiceId,
      })
      dispatch(fetchInvoiceReferenceSpecificationsSuccess({ referenceId, invoiceId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceReferenceSpecificationsFailure(err.response))
    }
  }
}
