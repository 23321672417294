import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Button,
  List,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Badge,
  Tooltip,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  PeopleRounded as PeopleIcon,
  InfoRounded as NotificationsIcon,
  ArrowRightRounded as ArrowRightIcon,
  FilterListRounded as FilterIcon,
} from '@material-ui/icons'
import { Paper, PaperContent, PaperFooter, LoadingSpinner, Alert } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  setMaxHeight: {
    height: '100%',
  },
  container: {
    padding: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  usersLength: {
    marginTop: theme.spacing(),
    fontSize: '75px',
  },
  listItem: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  listItemFooter: {
    paddingLeft: '0px',
  },
  notificationsIcon: {
    color: theme.palette.primary.main,
  },
  footer: {
    display: 'flex',
  },
  link: {
    textDecoration: 'none',
    marginLeft: 'auto',
  },
  avatar: {
    width: '48px',
    height: '48px',
    backgroundColor: '#fff',
    overflow: 'visible',
  },
  icon: {
    fontSize: '36px',
    width: '36px',
    height: '36px',
    color: theme.palette.text.secondary,
  },
  badge: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    top: 4,
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const UsersWidget = ({ users, groupFilters, changePage }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  let content = <LoadingSpinner />

  if (users.error && users.error.statusText) {
    content =
      users.error.status === 500 ? (
        <>
          <Alert type="error" className={classes.alert} message={t('CheckSetupWizardError')} />
          <Button color="primary" variant="outlined" fullWidth onClick={() => changePage('setup')}>
            {t('Organization_azure_cta')}
          </Button>
        </>
      ) : (
        <Typography color="error" variant="body1" className={classes.alert}>
          {users.error.statusText}
        </Typography>
      )
  }

  if (users.data) {
    content = (
      <List style={{ paddingTop: 0 }}>
        <ListItem className={classes.listItem}>
          <ListItemAvatar>
            <Typography variant="h1" className={classes.usersLength}>
              {users.data.length}
            </Typography>
          </ListItemAvatar>
          <ListItemSecondaryAction>
            <Avatar className={classes.avatar}>
              <Tooltip
                title={
                  (groupFilters && groupFilters.length > 0 && t('Dashboard_users_amount')) || ''
                }
              >
                <Badge
                  badgeContent={<FilterIcon />}
                  color="primary"
                  classes={{ badge: classes.badge }}
                  invisible={!groupFilters || groupFilters.length === 0}
                >
                  <PeopleIcon className={classes.icon} />
                </Badge>
              </Tooltip>
            </Avatar>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    )
  }

  const noSubCount = users.data && users.data.filter((user) => !user.hasSubscription).length

  return (
    <Paper className={classes.setMaxHeight}>
      <PaperContent noPadding className={classes.container}>
        <Typography className={classes.title} variant="body2">
          {t('Dashboard_users_title')}
        </Typography>
        {content}
      </PaperContent>
      {users.data && users.data.length > 0 && (
        <PaperFooter className={classes.footer}>
          {noSubCount > 0 && (
            <ListItem dense className={classes.listItemFooter} disableGutters>
              <ListItemIcon>
                <NotificationsIcon className={classes.notificationsIcon} />
              </ListItemIcon>
              <ListItemText primary={t('Dashboard_users_needs_sub', { amount: noSubCount })} />
            </ListItem>
          )}
          <Link to={`/${users.customerId}/brugere`} className={classes.link}>
            <Button color="primary" size="small" variant="text">
              {noSubCount > 0 ? t('Dashboard_users_assign') : t('Dashboard_general_see_all')}{' '}
              <ArrowRightIcon />
            </Button>
          </Link>
        </PaperFooter>
      )}
    </Paper>
  )
}

export default UsersWidget
