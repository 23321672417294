import React, { useState } from 'react'
import { Link } from '@material-ui/core'
import { GradientButton } from 'components'
import { useTranslation, Trans } from 'react-i18next'
import StepContainer from '../StepContainer'
import TextBlock from '../TextBlock'
import SecretPlaceholder from '../SecretPlaceholder'
import AppSecretModal from '../AppSecretModal'
import SecretsContainer from '../SecretsContainer'

const DaemonApp = ({
  tenant,
  customerId,
  fetchSecrets,
  deleteSecret,
  currentStep,
  setCurrentStep,
  loading,
  secret,
  presencePage,
}) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const openAppSecretModal = () => {
    if (!loading) setModalOpen(true)
  }

  const handleCloseDialog = () => {
    setModalOpen(false)
  }

  const handleSetCurrentStep = () => {
    setCurrentStep(currentStep + 1)
  }

  return (
    <>
      <StepContainer
        step={currentStep}
        requiredStep={presencePage ? 2 : 1}
        stepLabel={t(presencePage ? 'Wizard_00fawep' : 'Wizard_00fawe')}
      >
        <TextBlock>
          {t('Wizard_tgrhd')}
          <Link href="https://portal.azure.com" target="_blank">
            https://portal.azure.com
          </Link>
          <Trans i18nKey="Wizard_9dfgh" components={[<strong>App registrations</strong>]} />
          <Link
            href={
              presencePage
                ? 'https://support.sky.tdc.dk/hc/da/articles/4411820998290'
                : 'https://support.sky.tdc.dk/hc/da/articles/360009774700-S%C3%A5dan-udfylder-du-opstarts-wizard-en-i-Teams-Selvbetjening'
            }
            target="_blank"
          >
            {t(presencePage ? 'Wizard_00afwerp' : 'Wizard_00afwer')}
          </Link>
        </TextBlock>
        <TextBlock inputMargin>
          <Trans
            i18nKey="Wizard_agr"
            components={[
              <strong>Tilføj</strong>,
              { productName: presencePage ? 'Telephony Presence-app' : 'Azure AD app' },
            ]}
          />
        </TextBlock>

        <SecretsContainer
          loading={loading}
          secret={secret}
          deleteSecret={deleteSecret}
          type="app"
        />
        <SecretPlaceholder onClick={openAppSecretModal} hasSecrets={Boolean(secret)} />
        <GradientButton disabled={currentStep > 1 || !secret} onClick={handleSetCurrentStep}>
          {t('Hardware_next')}
        </GradientButton>
      </StepContainer>
      {modalOpen && (
        <AppSecretModal
          isOpen
          closeDialog={handleCloseDialog}
          tenant={tenant}
          hasSecrets={Boolean(secret)}
          customerId={customerId}
          fetchSecrets={fetchSecrets}
          presencePage={presencePage}
        />
      )}
    </>
  )
}

export default DaemonApp
