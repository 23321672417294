import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInvoiceReferenceNames } from 'state/invoiceReferenceNames/actions'
import { PaperFeedbackMessage, PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import TableWithFilteredTotal from '../../components/TableWithFilteredTotal'
import ExpandedReferenceTable from './ExpandedReferenceTable'

const ReferencesTable = ({ invoiceId, customerId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const invoiceReferenceNames = useSelector((state) => state.invoiceReferenceNames)

  useEffect(() => {
    dispatch(fetchInvoiceReferenceNames({ customerId, invoiceId }))
  }, [])

  const handleRenderExpandableRow = ([referenceId]) => (
    <ExpandedReferenceTable
      referenceId={referenceId}
      invoiceId={invoiceId}
      customerId={customerId}
    />
  )

  const options = {
    expandableRows: true,
    renderExpandableRow: handleRenderExpandableRow,
  }

  const columns = [
    {
      name: 'reference_id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
        searchable: false,
        download: false,
      },
    },
    {
      name: 'reference_name',
      label: t('Invoice_reff'),
      align: 'left',
      options: {
        sortDirection: 'asc',
        filter: true,
        sort: true,
      },
    },
  ]

  if (invoiceReferenceNames.error && !invoiceReferenceNames.loading) {
    return (
      <PaperFeedbackMessage
        message={t('Invoice_error', { error: invoiceReferenceNames.error.statusText })}
        type="error"
        withMargin
      />
    )
  }

  if (invoiceReferenceNames.data && invoiceReferenceNames.data.items.length === 0) {
    return <PaperFeedbackMessage message={t('Invoice_no_occurences')} withMargin />
  }

  if (invoiceReferenceNames.data && invoiceReferenceNames.data.items.length > 0) {
    const totalPrice = invoiceReferenceNames.data.items
      .map((item) => item.unparsed_charge)
      .reduce((calculatedPrice, currentPrice) => calculatedPrice + currentPrice)
    return (
      <TableWithFilteredTotal
        items={invoiceReferenceNames.data.items}
        firstTableColumns={columns}
        totalPrice={totalPrice}
        otherOptions={options}
      />
    )
  }

  return <PaperLoader />
}

export default ReferencesTable
