import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInvoiceExtraServicesDetails } from 'state/invoiceExtraServicesDetails/actions'
import { PaperFeedbackMessage, PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import TableWithFilteredTotal from '../../components/TableWithFilteredTotal'

const columns = [
  {
    name: 'description',
    label: 'Service',
    align: 'left',
    options: {
      sortDirection: 'asc',
      filter: true,
      sort: true,
    },
  },
]

const options = {
  viewColumns: false,
}

const ExtraServicesTable = ({ invoiceId, customerId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { invoiceExtraServicesDetails } = useSelector((state) => state)
  const invoiceIdsMatch = invoiceExtraServicesDetails.invoiceId === invoiceId

  useEffect(() => {
    dispatch(fetchInvoiceExtraServicesDetails({ customerId, invoiceId }))
  }, [])

  if (invoiceExtraServicesDetails.error && invoiceIdsMatch) {
    return (
      <PaperFeedbackMessage
        message={t('Invoice_error', { error: invoiceExtraServicesDetails.error.statusText })}
        type="error"
        withMargin
      />
    )
  }

  if (
    invoiceExtraServicesDetails.data &&
    invoiceExtraServicesDetails.data.items.length === 0 &&
    invoiceIdsMatch
  ) {
    return <PaperFeedbackMessage message={t('Invoice_no_occurences')} withMargin />
  }

  if (
    invoiceExtraServicesDetails.data &&
    invoiceExtraServicesDetails.data.items.length > 0 &&
    invoiceIdsMatch
  ) {
    return (
      <TableWithFilteredTotal
        items={invoiceExtraServicesDetails.data.items}
        firstTableColumns={columns}
        otherOptions={options}
        totalPrice={invoiceExtraServicesDetails.data.invoice.extra_service_fees}
      />
    )
  }

  return <PaperLoader />
}

export default React.memo(ExtraServicesTable)
