import React from 'react'
import { Alert } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'

interface Props {
  totalCount: number
  eligibleCount: number
  messageAppend?: string
  withMargin?: boolean
}

const EligibleCount = ({ totalCount, eligibleCount, messageAppend, withMargin = true }: Props) => {
  const { t } = useTranslation()

  return (
    <div style={{ marginTop: withMargin ? 24 : 0 }}>
      <Alert
        withMargin
        message={`${t('BulkUpdateEligibleCount', { totalCount, eligibleCount })} ${messageAppend ||
          ''}`}
        type="info"
      />
    </div>
  )
}

export default EligibleCount
