import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'addons/FETCH_FAILURE'
export const FETCH_BEGIN = 'addons/FETCH_BEGIN'
export const FETCH_SUCCESS = 'addons/FETCH_SUCCESS'

export const fetchAddonsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAddonsBegin = ({ abbId }) => {
  return {
    type: FETCH_BEGIN,
    abbId,
  }
}

export const fetchAddonsSuccess = ({ data, abbId }) => {
  return {
    type: FETCH_SUCCESS,
    abbId,
    data,
  }
}

export const fetchAddons = ({ customerId, abbId }) => {
  return async (dispatch) => {
    dispatch(fetchAddonsBegin({ abbId }))
    try {
      const response = await getSubscription.addons({
        customerId,
        abbId,
      })
      dispatch(fetchAddonsSuccess({ abbId, data: response.data }))
    } catch (err) {
      dispatch(fetchAddonsFailure(err.response))
    }
  }
}
