import React, { memo } from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  Link,
  Button,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import QRCode from 'react-qr-code'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import useCopyToClipboard from 'theme/hooks/useCopyToClipboard'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 4,
  },
  box: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  esimBox: {
    backgroundColor: 'white',
    borderRadius: 4,
    padding: theme.spacing(2),
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
    },
    maxWidth: 142,
  },
  activationsCode: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  loading: {
    margin: '2rem auto',
    display: 'block',
  },
}))

interface EsimProps {
  qrCodeValue?: string
  newEsimOrder: { activationCode: string; identifier: string; loading?: boolean }
}

const Esim = ({ qrCodeValue, newEsimOrder }: EsimProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { copyFn, CopyPlaceholder } = useCopyToClipboard()
  const { enqueueSnackbar } = useSnackbar()

  const copyToClipboard = (value: string) => {
    copyFn({ value })
    enqueueSnackbar(t('Drawer_mypage_copy', { value }))
  }

  const activationsLink = `https://selvbetjening.sky.tdc.dk/service/#/activate/simcard/${newEsimOrder?.identifier}`

  if (newEsimOrder?.loading) {
    return <CircularProgress size={32} className={classes.loading} />
  }

  if (!qrCodeValue && !newEsimOrder?.activationCode) {
    return <></>
  }

  return (
    <Box className={classes.container}>
      {newEsimOrder?.identifier ? (
        <>
          <Typography variant="body1" display="block" gutterBottom>
            <strong>{t('EndUserActivationTitle')}</strong>
          </Typography>
          <Box className={classes.box}>
            <List>
              {[
                <Link key="link" href={activationsLink} target="_blank">
                  {t('ActivateNewEsim')}
                </Link>,
                <span key="activationCode">
                  {t('ActivateNewEsimCode')}
                  <Tooltip
                    title={t('Drawer_mypage_click_to_copy', { value: newEsimOrder.activationCode })}
                    aria-label="copy"
                  >
                    <Button onClick={() => copyToClipboard(newEsimOrder.activationCode)}>
                      <CopyPlaceholder />
                      <span className={classes.activationsCode}>{newEsimOrder.activationCode}</span>
                    </Button>
                  </Tooltip>
                </span>,
              ].map((desc: React.ReactElement, i: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={`bullet-${i}`}>
                  <ListItemAvatar>
                    <Avatar>{i + 1}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={desc} />
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="body1" display="block" gutterBottom>
            <strong>{t('EndUserEsimTitle')}</strong>
          </Typography>
          <Box className={classes.box}>
            <List>
              {[
                t('EndUserEsimFirstStep'),
                t('EndUserEsimSecondStep'),
                t('EndUserEsimThirdStep'),
              ].map((desc: string, i: number) => (
                <ListItem key={desc}>
                  <ListItemAvatar>
                    <Avatar>{i + 1}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={desc} />
                </ListItem>
              ))}
            </List>
            <Box className={classes.esimBox}>
              {qrCodeValue && <QRCode value={qrCodeValue} size={110} />}
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default memo(Esim)
