import { getUser } from 'services/sweb/user'

export const FETCH_FAILURE = 'users/FETCH_FAILURE'
export const FETCH_BEGIN = 'users/FETCH_BEGIN'
export const FETCH_SUCCESS = 'users/FETCH_SUCCESS'

export const fetchUsersFailure = (error, customerId) => {
  return {
    type: FETCH_FAILURE,
    customerId,
    payload: { error },
  }
}

export const fetchUsersBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchUsersSuccess = ({ response, customerId }) => {
  return {
    type: FETCH_SUCCESS,
    customerId,
    payload: { users: response.data.users },
  }
}

export const fetchUsers = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchUsersBegin({ customerId }))
    try {
      const response = await getUser.all({ customerId })
      dispatch(fetchUsersSuccess({ response, customerId }))
    } catch (err) {
      dispatch(fetchUsersFailure(err.response, customerId))
    }
  }
}
