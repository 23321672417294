import React from 'react'
import classNames from 'classnames'
import { ListItem, List, ListItemAvatar, ListItemText, Avatar, Tooltip } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { KeyboardArrowDown as ChevronDownIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  isButton: {
    borderRadius: 4,
    '&:hover': {
      '& $primary': {
        color: theme.palette.primary.main,
      },
    },
  },
  removeHover: {
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
  },
  dense: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  squareAvatar: {
    width: 38,
    height: 38,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.warning.main,
  },
  roundAvatar: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.success.main,
  },
  listItemAvatar: {
    minWidth: 48,
  },
  primary: {
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
}))

interface Props {
  primaryText?: string
  secondaryText?: string
  avatarChar?: string
  shape?: 'square' | 'round'
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  dense?: boolean
  hasChevron?: boolean
  className?: string
  tooltip?: string
}

const AvatarListItem = ({
  primaryText,
  secondaryText,
  onClick,
  avatarChar,
  shape = 'square',
  dense = false,
  hasChevron = true,
  className,
  tooltip,
}: Props) => {
  const classes = useStyles()

  const createAvatarChar = avatarChar || primaryText?.charAt(0)

  const listItemClassName = classNames(
    {
      [classes.isButton]: Boolean(onClick),
      [classes.removeHover]: Boolean(!onClick),
      [classes.dense]: dense,
    },
    className
  )

  return (
    <List disablePadding>
      <Tooltip title={tooltip || ''} placement="right" arrow>
        <ListItem className={listItemClassName} button onClick={onClick}>
          <ListItemAvatar className={classes.listItemAvatar}>
            <Avatar className={shape === 'square' ? classes.squareAvatar : classes.roundAvatar}>
              {createAvatarChar}
            </Avatar>
          </ListItemAvatar>
          {primaryText && (
            <ListItemText
              primary={primaryText}
              secondary={secondaryText}
              primaryTypographyProps={{
                noWrap: true,
              }}
              secondaryTypographyProps={{
                noWrap: true,
              }}
              classes={{
                primary: classes.primary,
              }}
            />
          )}
          {hasChevron && <ChevronDownIcon color="secondary" />}
        </ListItem>
      </Tooltip>
    </List>
  )
}

export default AvatarListItem
