import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Button } from '@material-ui/core'
// import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    paddingTop: '0px !important',
    marginTop: -theme.spacing(2),
  },
  avatar: {
    width: 'auto',
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    height: '22px',
    marginRight: '6px',
    fontSize: '11px',
    backgroundColor: theme.palette.background.default,
    color: '#192228',
  },
  btnActive: {
    backgroundColor: 'rgba(0, 75, 146, 0.08)',
  },
}))

const SortingChips = ({ activeFilters, setActiveFilters, numbers, setPage }) => {
  // const { t } = useTranslation()
  const classes = useStyles()

  const handleSetActiveFilter = (key, value) => {
    const newFilters = { ...activeFilters }
    if ({}.hasOwnProperty.call(newFilters, key)) {
      delete newFilters[key]
    } else {
      newFilters[key] = value
    }
    setPage(0)
    setActiveFilters(newFilters)
  }

  const sortingChips = [
    {
      label: 'Version 1',
      count: numbers.filter((number) => number.productVersion === 1).length,
      filterKey: 'productVersion',
      filterValue: 1,
    },
    {
      label: 'Version 2',
      count: numbers.filter((number) => number.productVersion === 2).length,
      filterKey: 'productVersion',
      filterValue: 2,
    },
    {
      label: 'Voxbone',
      count: numbers.filter((number) => number.provider === 'Voxbone').length,
      filterKey: 'provider',
      filterValue: 'Voxbone',
    },
  ]

  return (
    <div className={classes.root}>
      {sortingChips.map((chip) => {
        if (chip.count > 0) {
          return (
            <Button
              key={chip.label}
              size="small"
              className={classNames({
                [classes.btnActive]: {}.hasOwnProperty.call(activeFilters, chip.filterKey),
              })}
              onClick={() => handleSetActiveFilter(chip.filterKey, chip.filterValue)}
              color="primary"
            >
              <Avatar className={classes.avatar}>{chip.count}</Avatar> {chip.label}
            </Button>
          )
        }
        return null
      })}
    </div>
  )
}

export default SortingChips
