import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchVoxboneCapacityGroup } from 'state/intNumberCapacityGroup/actions'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useTranslation } from 'react-i18next'
import { Paper, PaperContent, Alert } from '@skytdc/mui/components'
import Table from './components/Table'

interface Props extends RouteComponentProps {
  customerId: string
}

const CapacityGroups = ({ customerId }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loading, data, pageNumber, pageSize, error } = useSelector(
    (state) => state.intNumberCapacityGroup
  )

  const { totalResultSize } = data?.pageing || {}

  const maxPageNumberReached = pageNumber <= (Math.ceil(totalResultSize / pageSize) - 1 || 0)

  useEffect(() => {
    if (
      (!loading && !pageNumber && !error) ||
      (!loading && !error && data?.data.length < totalResultSize && maxPageNumberReached)
    ) {
      dispatch(fetchVoxboneCapacityGroup({ customerId, id: '', pageNumber, pageSize }))
    }
  }, [!loading])

  useEffect(() => {
    changeDocumentTitle(t('CapacityGroups'), ' - ', ':applicationName')
  }, [])

  if (error && error.statusText) {
    return (
      <Paper>
        <PaperContent>
          <Alert type="error" message={error.statusText} />
        </PaperContent>
      </Paper>
    )
  }

  return (
    <Table
      capacityGroups={data?.data.filter((x) => x.validForThisCustomer) || []}
      loading={loading}
    />
  )
}

export default CapacityGroups
