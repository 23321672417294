import {
  OCLANDLINE_NUMBER_ORDERS_FAILURE,
  OCLANDLINE_NUMBER_ORDERS_BEGIN,
  OCLANDLINE_NUMBER_ORDERS_SUCCESS,
  IntNumbersState,
} from './model'

const initialState: IntNumbersState = {
  loading: false,
  customerId: null,
  data: null,
  error: null,
  pageNumber: 0,
  pageSize: 50,
  order: 'asc',
  orderby: 'inumber',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OCLANDLINE_NUMBER_ORDERS_BEGIN:
      return {
        ...state,
        ...action,
        loading: true,
        customerId: action.customerId,
        error: null,
        data: action.customerId === state.customerId ? state.data : null,
      }

    case OCLANDLINE_NUMBER_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data:
          state.data && action.data.pageing.currentPageNumber > 0
            ? {
                pageing: action.data.pageing,
                data: action.data.data.concat(state.data.data),
              }
            : action.data,
        pageNumber: action.data.pageing.currentPageNumber > 0 ? state.pageNumber + 1 : 1,
      }

    case OCLANDLINE_NUMBER_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: null,
      }

    default:
      return state
  }
}
