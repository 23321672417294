import React, { useState } from 'react'
import {
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { InfoOutlined as InfoIcon, DeleteOutline as DeleteIcon } from '@material-ui/icons'
import { format } from 'date-fns'
import CustomDialog from 'components/CustomDialog'
import { useTranslation } from 'react-i18next'
import SecretInfoModalContent from './SecretInfoModalContent'

const useStyles = makeStyles((theme) => ({
  listItem: {
    maxHeight: 62,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    marginTop: 1,
  },
  active: {
    color: theme.palette.success.main,
  },
  inactive: {
    color: theme.palette.warning.main,
  },
  expired: {
    color: theme.palette.error.main,
  },
}))

const formatDate = (date) => {
  const convertToDate = new Date(date)
  return format(convertToDate, 'dd-MM-yyyy')
}

const initialDialog = {
  open: false,
  title: null,
  message: null,
  callback: null,
  btnText: null,
}

const SecretItem = ({
  state,
  login,
  clientId,
  notAfter,
  unionId,
  tenantName,
  tenantId,
  notBefore,
  name,
  loading,
  deleteSecret,
  disabled,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [dialog, setDialog] = useState(initialDialog)

  const handleCloseDialog = () => {
    setDialog(initialDialog)
  }

  const handleDeleteSecret = () => {
    const json = {
      secretName: name,
    }
    setDialog(initialDialog)
    deleteSecret(json)
  }

  const openDeleteDialog = () => {
    setDialog({
      open: true,
      title: t('Wizard_9f'),
      message: t('Wizard_00345'),
      callback: handleDeleteSecret,
      btnText: t('Wizard_9awef'),
    })
  }

  const openInfoDialog = () => {
    setDialog({
      open: true,
      contentPadding: false,
      maxWidth: 'xs',
      closeButtonText: t('Wizard_00tyuj'),
      title: t('Wizard_sertgh'),
      children: SecretInfoModalContent,
    })
  }

  const formattedNotBefore = formatDate(notBefore)
  const formattedNotAfter = formatDate(notAfter)

  return (
    <>
      <ListItem className={classes.listItem} disabled={disabled}>
        <ListItemText
          primary={login || clientId}
          secondary={t('Invoice_period', { from: formattedNotBefore, to: formattedNotAfter })}
        />
        <ListItemSecondaryAction>
          {deleteSecret && (
            <Tooltip title={t('Wizard_9awef')} placement="top">
              <IconButton edge="end" disabled={loading} onClick={openDeleteDialog}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t('Wizard_9sth')} placement="top">
            <IconButton edge="end" disabled={loading} onClick={openInfoDialog}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      {dialog.open && (
        <CustomDialog
          maxWidth={dialog.maxWidth}
          contentPadding={dialog.contentPadding}
          closeButtonText={dialog.closeButtonText}
          isOpen={dialog.open}
          onConfirm={dialog.callback}
          onCancel={handleCloseDialog}
          title={dialog.title}
          contentText={dialog.message}
          confirmButtonText={dialog.btnText}
        >
          {dialog.children && (
            <dialog.children
              state={state}
              login={login}
              tenantName={tenantName}
              tenantId={tenantId}
              unionId={unionId}
              clientId={clientId}
              notAfter={formattedNotAfter}
              notBefore={formattedNotBefore}
              name={name}
            />
          )}
        </CustomDialog>
      )}
    </>
  )
}

export default SecretItem
