export default {
  root: {
    backgroundColor: '#fff',
  },
  title: {
    display: 'none',
  },
  resetLink: {
    fontSize: '14px',
    marginLeft: '-5px',
  },
  checkboxFormControlLabel: {
    fontSize: '14px',
    marginLeft: '0px',
    marginRight: '16px',
  },
}
