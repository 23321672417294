import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Tooltip } from '@material-ui/core'
import { LockOutlined as LockIcon } from '@material-ui/icons'
import { Paper, PaperHeader, PageTitle } from '@skytdc/mui/components'
import { Link, Route, Redirect, Switch } from 'react-router-dom'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import hasRoles from 'utils/hasRoles'
import { useTranslation } from 'react-i18next'
import Layout from '../Layout'
import Numbers from './Numbers'
import Roles from './Roles'
import UnassignedSimcards from './UnassignedSimcards'
import MobileHuntgroups from './MobileHuntgroups'
import AzureConnection from './AzureConnection'
import DeliveryAddress from './DeliveryAddress'
import Notifications from './Notifications'
import Orders from './Orders'

const RouterLinkTab = (props) => {
  return (
    <span>
      <Tab component={Link} {...props} />
    </span>
  )
}

const useStyles = makeStyles((theme) => ({
  disabledTab: {
    color: '#bfbfbf',
    minHeight: '50px',
    whiteSpace: 'nowrap',
  },
  wrapper: {
    color: '#bfbfbf',
    marginBottom: '0px !important',
    marginTop: '4px',
    flexDirection: 'row',
    '& > *:first-child': {
      marginBottom: '0px !important',
    },
    whiteSpace: 'nowrap',
  },
  whiteSpace: {
    whiteSpace: 'nowrap',
  },
  labelIcon: {
    color: '#bfbfbf',
    paddingTop: '0px',
  },
  paperHeader: {
    marginBottom: theme.spacing(3),
  },
}))

const Company = ({ title, location, match }) => {
  const classes = useStyles()
  const customer = useSelector((state) => state.customer)
  const me = useSelector((state) => state.me)
  const { t } = useTranslation()

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
  }, [])

  const reservedSimcardsActivated = customer.data.customer_properties.display_reserved_sim_cards
  const isCustomerRoleAdmin = hasRoles(me.data, match.params.customer_id, ['customer_role_admin'])
  const mobileHuntgroupsActivated = customer.data.mobile_huntgroups_teams
  const broadworks = customer?.data?.customer_properties?.broadworks

  if (location.pathname === `/${match.params.customer_id}/organisation`) {
    return <Redirect to={`/${match.params.customer_id}/organisation/ledige-numre`} />
  }

  return (
    <Layout>
      <PageTitle title={t('Organization_title')} />
      <Paper>
        <PaperHeader noDivider className={classes.paperHeader}>
          <Tabs
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            value={location.pathname}
            scrollButtons="auto"
          >
            <RouterLinkTab
              label={t('Router_asdasdasd')}
              classes={{
                wrapper: classes.whiteSpace,
              }}
              to={`/${match.params.customer_id}/organisation/azure-forbindelse`}
              value={`/${match.params.customer_id}/organisation/azure-forbindelse`}
            />
            <RouterLinkTab
              label={t('Organization_avail_numbers')}
              classes={{
                wrapper: classes.whiteSpace,
              }}
              to={
                match.params.requested_action
                  ? `/${match.params.customer_id}/organisation/ledige-numre/indporter-numre`
                  : `/${match.params.customer_id}/organisation/ledige-numre`
              }
              value={
                match.params.requested_action
                  ? `/${match.params.customer_id}/organisation/ledige-numre/indporter-numre`
                  : `/${match.params.customer_id}/organisation/ledige-numre`
              }
            />
            {!broadworks &&
              (reservedSimcardsActivated ? (
                <RouterLinkTab
                  classes={{
                    wrapper: classes.whiteSpace,
                  }}
                  label={t('Organization_unassigned_sim')}
                  to={
                    match.params.requested_action
                      ? `/${match.params.customer_id}/organisation/skuffesimkort/bestil`
                      : `/${match.params.customer_id}/organisation/skuffesimkort`
                  }
                  value={
                    match.params.requested_action
                      ? `/${match.params.customer_id}/organisation/skuffesimkort/bestil`
                      : `/${match.params.customer_id}/organisation/skuffesimkort`
                  }
                />
              ) : (
                <Tooltip title={t('Organization_unassigned_sim_not_activated')}>
                  <Tab
                    icon={<LockIcon className={classes.labelIcon} />}
                    label={t('Organization_unassigned_sim')}
                    classes={{
                      wrapper: classes.wrapper,
                      labelIcon: classes.labelIcon,
                    }}
                    className={classes.disabledTab}
                    wrapped={false}
                  />
                </Tooltip>
              ))}
            {!broadworks &&
              (mobileHuntgroupsActivated ? (
                <RouterLinkTab
                  label={t('Organization_huntgroups')}
                  classes={{
                    wrapper: classes.whiteSpace,
                  }}
                  to={
                    match.params.requested_action
                      ? `/${match.params.customer_id}/organisation/mobile-ringegrupper/${match.params.requested_action}`
                      : `/${match.params.customer_id}/organisation/mobile-ringegrupper`
                  }
                  value={
                    match.params.requested_action
                      ? `/${match.params.customer_id}/organisation/mobile-ringegrupper/${match.params.requested_action}`
                      : `/${match.params.customer_id}/organisation/mobile-ringegrupper`
                  }
                />
              ) : (
                <Tooltip title={t('Organization_huntgroups_not_activated')}>
                  <Tab
                    icon={<LockIcon className={classes.labelIcon} />}
                    label={t('Organization_huntgroups')}
                    classes={{
                      wrapper: classes.wrapper,
                      labelIcon: classes.labelIcon,
                    }}
                    className={classes.disabledTab}
                    wrapped={false}
                  />
                </Tooltip>
              ))}
            {isCustomerRoleAdmin && (
              <RouterLinkTab
                classes={{
                  wrapper: classes.whiteSpace,
                }}
                label={t('Organization_admin_roles')}
                to={`/${match.params.customer_id}/organisation/administratorroller`}
                value={`/${match.params.customer_id}/organisation/administratorroller`}
              />
            )}
            <RouterLinkTab
              classes={{
                wrapper: classes.whiteSpace,
              }}
              label={t('Organization_delivery_address')}
              to={`/${match.params.customer_id}/organisation/leveringsadresse`}
              value={`/${match.params.customer_id}/organisation/leveringsadresse`}
            />
            <RouterLinkTab
              label={t('Organization_order_overview')}
              classes={{
                wrapper: classes.whiteSpace,
              }}
              to={
                match.params.requested_action
                  ? `/${match.params.customer_id}/organisation/ordrer/${match.params.requested_action}`
                  : `/${match.params.customer_id}/organisation/ordrer`
              }
              value={
                match.params.requested_action
                  ? `/${match.params.customer_id}/organisation/ordrer/${match.params.requested_action}`
                  : `/${match.params.customer_id}/organisation/ordrer`
              }
            />
            {!broadworks && (
              <RouterLinkTab
                label={t('Notifications')}
                classes={{
                  wrapper: classes.whiteSpace,
                }}
                to={`/${match.params.customer_id}/organisation/notifikationer`}
                value={`/${match.params.customer_id}/organisation/notifikationer`}
              />
            )}
          </Tabs>
        </PaperHeader>
        <Switch>
          <Route component={Numbers} exact path={`${match.path}/ledige-numre/:requested_action?`} />
          {!broadworks && reservedSimcardsActivated && (
            <Route
              component={UnassignedSimcards}
              exact
              path={`${match.path}/skuffesimkort/:requested_action?`}
            />
          )}
          {!broadworks && mobileHuntgroupsActivated && (
            <Route
              component={MobileHuntgroups}
              exact
              path={`${match.path}/mobile-ringegrupper/:requested_action?`}
            />
          )}
          <Route component={AzureConnection} exact path={`${match.path}/azure-forbindelse`} />
          <Route component={Roles} exact path={`${match.path}/administratorroller`} />
          <Route component={DeliveryAddress} exact path={`${match.path}/leveringsadresse`} />
          <Route component={Orders} exact path={`${match.path}/ordrer/:requested_action?`} />
          {!broadworks && (
            <Route component={Notifications} exact path={`${match.path}/notifikationer`} />
          )}
          <Redirect to={`/${match.params.customer_id}/organisation`} />
        </Switch>
      </Paper>
    </Layout>
  )
}

export default Company
