import { getCustomer } from 'services/sweb/customer'
import {
  OCLANDLINE_NUMBER_ORDERS_FAILURE,
  OCLANDLINE_NUMBER_ORDERS_BEGIN,
  OCLANDLINE_NUMBER_ORDERS_SUCCESS,
  ParamsProps,
  IntNumbersActions,
  IntNumbersState,
} from './model'

export const fetchOCLandlineNumberOrdersFailure = (
  error: IntNumbersState['error']
): IntNumbersActions => {
  return {
    type: OCLANDLINE_NUMBER_ORDERS_FAILURE,
    error,
  }
}

export const fetchOCLandlineNumberOrdersBegin = ({
  customerId,
}: ParamsProps): IntNumbersActions => {
  return {
    type: OCLANDLINE_NUMBER_ORDERS_BEGIN,
    customerId,
  }
}

export const fetchOCLandlineNumberOrdersSuccess = (
  data: IntNumbersState['data']
): IntNumbersActions => {
  return {
    type: OCLANDLINE_NUMBER_ORDERS_SUCCESS,
    data,
  }
}

export const fetchOCLandlineNumberOrders = ({ customerId, pageSize, pageNumber }: ParamsProps) => {
  return async (dispatch: any) => {
    dispatch(fetchOCLandlineNumberOrdersBegin({ customerId }))
    try {
      const response: any = await getCustomer.OCLandlineNumberOrders({
        customerId,
        pageSize,
        pageNumber,
      })

      dispatch(fetchOCLandlineNumberOrdersSuccess(response.data))
    } catch (err) {
      dispatch(fetchOCLandlineNumberOrdersFailure(err.response))
    }
  }
}
