import React from 'react'
import Dashboard from '../views/Dashboard'
import DataPoolUsage from '../views/DataPoolUsage'
import Users from '../views/Users'
import Company from '../views/Company'
import CloudAdoption from '../views/CloudAdoption'
import redirectWithSnackbar from './utils/redirectWithSnackbar'
import Addons from '../views/Addons'
import Invoice from '../views/Invoice'
import OrderHardware from '../views/OrderHardware'
import SetupWizard from '../views/SetupWizard'
import Changelog from '../views/Support/Changelog'
import OperationalStatus from '../views/Support/OperationalStatus'
import ContactUs from '../views/Support/ContactUs'
import EndUser from '../views/EndUser'
import SkyApp from '../views/SkyApp'
import TrustTool from '../views/Admin/TrustTool'
import InternationalNumbers from '../views/Admin/InternationalNumbers'
import LandlineNumbers from '../views/OC/LandlineNumbers'
import OCUsers from '../views/OC/OCUsers'

const handleCustomerRoute = (Component) => ({ title }) => (routeProps) => {
  return <Component {...routeProps} title={title} />
}

const handleUnauthorizedCustomerRoute = (fallbackRoute) => () => () => {
  const snackbar = {
    message: 'Router_missing_role',
    options: { variant: 'warning' },
  }
  return redirectWithSnackbar({ to: fallbackRoute, snackbar })
}

const customerIdPathPrefix = ':customer_id([0-9]{6,8})' // any number with length between 6 and 8

export default [
  {
    key: 'ViewDashboard',
    path: `/${customerIdPathPrefix}/dashboard`,
    title: 'Dashboard',
    requiresOneOf: {
      role: ['admin'],
      roles: ['customer_role_admin', 'customer_admin', 'invoice'],
    },
    component: handleCustomerRoute(Dashboard),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewDataPool',
    path: `/${customerIdPathPrefix}/data-pool-usage`,
    title: 'Data_pool_title',
    requiresOneOf: {
      role: ['admin'],
      roles: ['customer_role_admin', 'customer_admin'],
    },
    component: handleCustomerRoute(DataPoolUsage),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewUsers',
    path: `/${customerIdPathPrefix}/brugere/:user_id?/:requested_action?`,
    title: 'Drawer_header_users',
    requiresOneOf: {
      role: ['admin'],
      roles: ['customer_role_admin', 'customer_admin'],
    },
    component: handleCustomerRoute(Users),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewCompany',
    path: `/${customerIdPathPrefix}/organisation/:view?/:requested_action?`,
    title: 'Invoice_org',
    requiresOneOf: {
      role: ['admin'],
      roles: ['customer_role_admin', 'customer_admin'],
    },
    component: handleCustomerRoute(Company),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewCloudAdoption',
    path: `/${customerIdPathPrefix}/cloud-adoption`,
    title: 'Cloud Adoption',
    requiresOneOf: {
      role: ['admin'],
      roles: ['customer_role_admin', 'customer_admin'],
    },
    component: handleCustomerRoute(CloudAdoption),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewAddons',
    path: `/${customerIdPathPrefix}/addons-og-services`,
    title: 'Layout_addons',
    requiresOneOf: {
      role: ['admin'],
      roles: ['customer_role_admin', 'customer_admin'],
    },
    component: handleCustomerRoute(Addons),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  // {
  //   key: 'ViewOrderHardWare',
  //   path: `/${customerIdPathPrefix}/bestil-hardware`,
  //   title: 'Layout_hardware',
  //   requiresOneOf: {
  //     role: ['admin'],
  //     roles: ['customer_role_admin', 'customer_admin'],
  //   },
  //   component: handleCustomerRoute(OrderHardware),
  //   fallback: handleUnauthorizedCustomerRoute('/'),
  // },
  {
    key: 'ViewInvoice',
    path: `/${customerIdPathPrefix}/faktura/:invoice?/:details?`,
    title: 'Changelog_5678',
    requiresOneOf: {
      role: ['admin'],
      roles: ['invoice', 'oc_admin'],
    },
    component: handleCustomerRoute(Invoice),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewContactUs',
    path: `/${customerIdPathPrefix}/kontakt-os`,
    title: 'Changelogdhgf_',
    requiresOneOf: {
      role: ['admin'],
      roles: ['customer_role_admin', 'customer_admin', 'invoice'],
    },
    component: handleCustomerRoute(ContactUs),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewOperationalStatus',
    path: `/${customerIdPathPrefix}/driftstatus`,
    title: 'Drawer_mypage_a',
    requiresOneOf: {
      role: ['admin'],
      roles: ['customer_role_admin', 'customer_admin', 'invoice'],
    },
    component: handleCustomerRoute(OperationalStatus),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewNewFeatures',
    path: `/${customerIdPathPrefix}/nyt-i-selvbetjening`,
    title: 'Layout_news',
    requiresOneOf: {
      role: ['admin'],
      roles: ['customer_role_admin', 'customer_admin', 'invoice'],
    },
    component: handleCustomerRoute(Changelog),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewSetupWizard',
    path: `/${customerIdPathPrefix}/setup`,
    title: 'Router_asdasdasd',
    requiresOneOf: {
      role: ['admin'],
      roles: ['customer_role_admin', 'customer_admin'],
    },
    component: handleCustomerRoute(SetupWizard),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewPresenceSetupWizard',
    path: `/${customerIdPathPrefix}/presence-setup`,
    title: 'Router_asdasdasd',
    requiresOneOf: {
      role: ['admin'],
      roles: ['customer_role_admin', 'customer_admin'],
    },
    component: handleCustomerRoute(SetupWizard),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewEndUserPage',
    path: `/${customerIdPathPrefix}/min-side`,
    title: 'Organization_customer_select_mypage',
    requiresOneOf: {
      role: ['end_user', 'admin'],
      roles: ['customer_role_admin', 'customer_admin', 'invoice'],
    },
    component: handleCustomerRoute(EndUser),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'SkyApp',
    path: `/${customerIdPathPrefix}/skyapp`,
    title: 'Organization_customer_select_mypage',
    requiresOneOf: {
      role: ['end_user', 'admin'],
      roles: ['customer_role_admin', 'customer_admin', 'invoice'],
    },
    component: handleCustomerRoute(SkyApp),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewTrustToolPage',
    path: `/${customerIdPathPrefix}/trust-scripts`,
    title: 'Organization_customer_select_mypage',
    requiresOneOf: {
      role: ['admin'],
      roles: ['internal_admin_of_doom'],
    },
    component: handleCustomerRoute(TrustTool),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewInternationalNumbersPage',
    path: `/${customerIdPathPrefix}/internationale-numre/:page?/:action?`,
    title: 'Addons_int_numbers_title',
    requiresOneOf: {
      role: ['admin'],
      roles: ['internal_admin_of_doom'],
    },
    component: handleCustomerRoute(InternationalNumbers),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewOCNumbersPage',
    path: `/${customerIdPathPrefix}/operator-connect/numre/:page?/:action?`,
    title: 'Addons_int_numbers_title',
    requiresOneOf: {
      role: ['admin'],
      roles: ['oc_admin'],
    },
    component: handleCustomerRoute(LandlineNumbers),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
  {
    key: 'ViewOCUsersPage',
    path: `/${customerIdPathPrefix}/operator-connect/brugere/:page?/`,
    title: 'Drawer_header_users',
    requiresOneOf: {
      role: ['admin'],
      roles: ['oc_admin'],
    },
    component: handleCustomerRoute(OCUsers),
    fallback: handleUnauthorizedCustomerRoute('/'),
  },
]
