import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, CircularProgress, IconButton, Collapse } from '@material-ui/core'
import { fetchGenerateExtensions } from 'state/generateExtensions/actions'
import { fetchValidateExtension } from 'state/validateExtension/actions'
import { EditOutlined as EditIcon, Close as CloseIcon } from '@material-ui/icons'
import { SkyTextField } from 'components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  typography: {
    fontSize: 12,
    marginTop: -theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    display: 'block',
  },
  spinner: {
    marginLeft: theme.spacing(),
    verticalAlign: 'text-top',
  },
  icon: {
    width: '16px',
    height: '16px',
  },
  iconButton: {
    marginTop: '-3px',
    marginLeft: theme.spacing(0.5),
  },
}))

const ExtensionSelect = ({ onExtension, extension, landlineNumber, disabled }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const stored = useSelector(
    (state) => ({
      customer: state.customer,
      extensions: state.generateExtensions,
      validateExtension: state.validateExtension,
    }),
    shallowEqual
  )

  const [showEditing, setShowEditing] = useState(false)
  // const [extension, setExtension] = useState(null))
  const [customExtension, setCustomExtension] = useState('')
  const classes = useStyles()

  const handleSetCustomExtension = (event) => {
    if (event.target.value.length <= 4) setCustomExtension(event.target.value)
  }

  const firstExtension =
    stored.extensions.data && stored.extensions.data.find((ext) => ext.warning_in_use === null)

  const landlineOrIntNumber = landlineNumber.value.country
    ? landlineNumber.value.ddi.number
    : landlineNumber.value.number

  useEffect(() => {
    // if number has country, use DDI to fetch extension (int number)
    if (landlineOrIntNumber) {
      dispatch(
        fetchGenerateExtensions({
          customerId: stored.customer.customerId,
          phoneNumber: landlineOrIntNumber,
        })
      )
    }
  }, [landlineNumber])

  useEffect(() => {
    if (firstExtension) {
      onExtension({ ...firstExtension })
    }
  }, [stored.extensions.data])

  useEffect(() => {
    if (customExtension.length === 3 || customExtension.length === 4) {
      dispatch(
        fetchValidateExtension({
          customerId: stored.customer.customerId,
          phoneNumber: landlineOrIntNumber,
          extension: customExtension,
        })
      )
    } else {
      onExtension({ ...firstExtension })
    }
  }, [customExtension])

  useEffect(() => {
    if (
      stored.validateExtension &&
      stored.validateExtension.data &&
      stored.validateExtension.data.is_valid
    ) {
      onExtension({ localExtension: customExtension })
    }
  }, [stored.validateExtension])

  const extensionLoading =
    stored.extensions.loading ||
    stored.validateExtension.loading === true ||
    (landlineNumber && !extension)

  return (
    <>
      <Typography
        className={classes.typography}
        component="span"
        color={extension && extension.localExtension ? 'secondary' : 'error'}
      >
        <span>
          {landlineNumber && extension && extension.localExtension && t('Drawer_extension')}
        </span>
        <span>{(extension && extension.localExtension) || t('Drawer_extension_error_msg')}</span>
        <span>
          {extensionLoading && <CircularProgress size={14} className={classes.spinner} />}
        </span>
        <span>
          {!extensionLoading && (
            <IconButton
              size="small"
              className={classes.iconButton}
              onClick={() => setShowEditing(!showEditing)}
            >
              {!showEditing && <EditIcon className={classes.icon} fontSize="inherit" />}
              {showEditing && <CloseIcon className={classes.icon} fontSize="inherit" />}
            </IconButton>
          )}
        </span>
      </Typography>
      <Collapse in={showEditing}>
        <SkyTextField
          autoFocus
          onBlur={() => setShowEditing(false)}
          value={customExtension}
          disabled={disabled}
          label={t('Drawer_extension_type')}
          onChange={handleSetCustomExtension}
          helperText={
            stored.validateExtension &&
            stored.validateExtension.data &&
            !stored.validateExtension.data.is_valid
              ? t('Drawer_extension_not_avail')
              : t('Drawer_extension_info_msg')
          }
          error={
            stored.validateExtension &&
            stored.validateExtension.data &&
            !stored.validateExtension.data.is_valid
          }
        />
      </Collapse>
    </>
  )
}

export default ExtensionSelect
