import React, { Fragment, useState } from 'react'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const TeamsQuickMenu = ({ popUserAction }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  const handlePopUserAction = (event, action) => {
    event.stopPropagation()
    setAnchorEl(null)
    popUserAction(event, action)
  }
  return (
    <>
      <IconButton edge="end" aria-label="Edit landline subscription" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      {anchorEl && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={(event) => handlePopUserAction(event, 'fastnet-nummervisning')}>
            {t('Users_fsdr')}
          </MenuItem>
        </Menu>
      )}
    </>
  )
}

export default TeamsQuickMenu
