export default {
  cellStacked: {
    fontSize: '12px !important',
    height: '36px !important',
    borderBottom: '0px',
    fontWeight: 700,
  },
  responsiveStacked: {
    fontSize: '12px !important',
    height: '36px !important',
    borderBottom: '0px',
  },
}
