import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { phoneNumberFormat } from 'utils/helpers'

import {
  Checkbox,
  Typography,
  Grid,
  Tooltip,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import { ClearRounded as ClearIcon } from '@material-ui/icons'
import { PaperToolbar, TablePagination, LoadingSpinner } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'
import { Search } from 'components'
import NumberListItem from '../components/NumberListItem'
import CountrySelect from '../components/CountrySelect'

const useStyles = makeStyles((theme: Theme) => ({
  dot: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  globalCheckbox: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  releasedNumber: {
    color: theme.palette.primary.main,
  },
}))

const SelectNumbersStep = ({
  fetchStockNumbers,
  pagenumber,
  pagesize,
  countryCode,
  countryList,
  numbers,
  releasedNumbers,
  selectedNumbers,
  loading,
  totalCount,
  toggleSelectNumber,
  selectAllNumbers,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSearchChange = (value: string) => {
    fetchStockNumbers({ e164: value?.replace(/\s/g, ''), pagenumber: 0 })
  }

  const handleChangePage = (newPage: number) => {
    fetchStockNumbers({ pagenumber: newPage })
  }

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    fetchStockNumbers({
      pagesize: newRowsPerPage,
      pagenumber: 0,
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={9}>
        <PaperToolbar>
          <Tooltip placement="right" title={t('SelectAll')} arrow>
            <Checkbox
              className={classes.globalCheckbox}
              color="primary"
              disabled={loading}
              checked={false}
              onClick={selectAllNumbers}
            />
          </Tooltip>
          <Search
            filter={handleSearchChange}
            debounce={750}
            placeholder={t('IntNumbersSearch')}
            autoFocus={false}
            disabled={loading}
          />
          <CountrySelect
            countryList={countryList}
            countryCode={countryCode}
            fetchStockNumbers={fetchStockNumbers}
          />
        </PaperToolbar>
        <div style={{ overflow: 'auto', marginTop: 24, marginBottom: 24, width: '100%' }}>
          {numbers &&
            numbers.map((number) => {
              const { voxbone } = number
              const getCountryCodeAndNumber = PhoneNumberUtil.getInstance().parseAndKeepRawInput(
                voxbone.e164,
                voxbone.countryCode
              )
              const formattedNumber = `+${getCountryCodeAndNumber.getCountryCode()} ${phoneNumberFormat(
                `${getCountryCodeAndNumber.getNationalNumber()}`
              )}`
              const releasedNumber = releasedNumbers.includes(voxbone.voiceUriId)
              const isSelected = selectedNumbers.find((x) => x.didId === voxbone.didId)
              const isInUse = Boolean(
                (!releasedNumber && voxbone.voiceUriId) ||
                  (voxbone.mediation && voxbone.mediation.length > 0)
              )
              const country = countryList.find((x) => x.countryCodeA3 === voxbone.countryCode)
              return (
                <NumberListItem
                  key={voxbone.didId}
                  selected={Boolean(isSelected)}
                  disabled={loading}
                  inUse={isInUse}
                  primary={formattedNumber}
                  loading={loading}
                  secondary={
                    <>
                      {country?.name || 'No country'}
                      <span className={classes.dot}>•</span>
                      {voxbone.cityName || 'No city'}
                      <span className={classes.dot}>•</span>Area code {voxbone.areaCode}
                      {releasedNumber && (
                        <>
                          <span className={classes.dot}>-</span>
                          <span className={classes.releasedNumber}> {t('OverwrittenNumber')}</span>
                        </>
                      )}
                    </>
                  }
                  onClick={(e) => toggleSelectNumber(e, { ...voxbone, formattedNumber })}
                />
              )
            })}
          {loading && numbers.length === 0 && <LoadingSpinner />}
          {!loading && numbers.length === 0 && (
            <Typography align="center">{t('Organization_unassignedsimcards_noresult')}</Typography>
          )}
        </div>
        <TablePagination
          rowsPerPage={pagesize}
          page={pagenumber}
          rowsPerPageOptions={[10, 25, 100]}
          totalCount={totalCount}
          setPage={handleChangePage}
          setRowsPerPage={handleChangeRowsPerPage}
          showingFromTo={t('ShowingFromTo', {
            from: pagenumber * pagesize + 1,
            to:
              pagenumber * pagesize + pagesize > totalCount
                ? totalCount
                : pagenumber * pagesize + pagesize,
            totalCount,
          })}
          perPage={t('PerPage')}
        />
      </Grid>
      <Grid item sm={12} md={3}>
        {selectedNumbers.map((x) => {
          const country = countryList.find((y) => y.countryCodeA3 === x.countryCode)
          return (
            <ListItem dense key={x.didId}>
              <ListItemText primary={x.formattedNumber} secondary={country?.name || 'No country'} />
              <ListItemSecondaryAction>
                <IconButton size="small" onClick={(e) => toggleSelectNumber(e, x)}>
                  <ClearIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default SelectNumbersStep
