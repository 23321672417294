import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Tooltip } from '@material-ui/core'
import { CloudOutlined as LicenseIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.background.default,
    marginRight: theme.spacing(),
    padding: theme.spacing(),
    paddingBottom: '2px',
    paddingTop: '2px',
    borderRadius: '3px',
    fontSize: '10px',
    '&:hover': {
      backgroundColor: '#eaeaea',
      cursor: 'pointer',
    },
  },
}))

const OfficeItem = ({ disabled }) => {
  const classes = useStyles()
  const licenseDetails = useSelector((state) => state.licenseDetails)
  const { t } = useTranslation()

  return (
    <DrawerListItem
      icon={<LicenseIcon />}
      isLoading={Boolean(!licenseDetails.data)}
      primaryText={t('Drawer_user_azure_licenses')}
      isDisabled={disabled}
      secondaryText={
        licenseDetails.data && (
          <>
            {licenseDetails.data.isTeamsLicensed && (
              <Tooltip title={t('Drawer_user_teams_desc_two')}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="span"
                  className={classes.badge}
                >
                  Teams
                </Typography>
              </Tooltip>
            )}
            {licenseDetails.data.allowVoiceEnable && (
              <Tooltip title={t('Denne bruger har mulighed for at få Teams-telefoni')}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="span"
                  className={classes.badge}
                >
                  Phone System
                </Typography>
              </Tooltip>
            )}
            {licenseDetails.data.hasAudioConferencing && (
              <Tooltip title={t('Drawer_mypage_conf')}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="span"
                  className={classes.badge}
                >
                  Audio Conferencing
                </Typography>
              </Tooltip>
            )}
            {licenseDetails.data.licenseCategory && (
              <Tooltip title={t('Drawer_mypage_license_cat')}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="span"
                  className={classes.badge}
                >
                  {licenseDetails.data.licenseCategory}
                </Typography>
              </Tooltip>
            )}
            {!licenseDetails.data.isTeamsLicensed &&
              !licenseDetails.data.allowVoiceEnable &&
              !licenseDetails.data.licenseCategory &&
              t('Drawer_mypage_no_licenses')}
          </>
        )
      }
    />
  )
}

export default React.memo(OfficeItem)
