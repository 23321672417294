import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { fetchInvoiceReferenceSpecifications } from 'state/invoiceReferenceSpecifications/actions'
import { PaperLoader, PaperFeedbackMessage } from 'components'
import { useTranslation } from 'react-i18next'
import TableWithFilteredTotal from '../../components/TableWithFilteredTotal'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: 400,
    backgroundColor: theme.palette.primary.light,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    '& div': {
      background: theme.palette.primary.light,
    },
    '& div:first-child div:nth-child(3)': {
      // Table
      maxHeight: 300,
      overflow: 'auto',
    },
    '& div:first-child div:nth-child(3) td': {
      // tds in first table
      borderBottom: '2px solid #fff',
    },
    '& div:first-child div:nth-child(3) th': {
      // ths in first table
      borderBottom: '2px solid #fff',
    },
    '& div:first-child table tbody tr:first-child td': {
      // td in pagination
      borderBottom: '2px solid #fff',
    },
    '& div:first-child div:first-child div:first-child div:first-child div': {
      // Search bar
      background: '#fff',
    },
  },
}))

const ExpandedReferenceTable = ({ invoiceId, referenceId, customerId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const invoiceReferenceSpecifications = useSelector(
    (state) => state.invoiceReferenceSpecifications
  )
  const idsMatch =
    invoiceReferenceSpecifications.invoiceId === invoiceId &&
    invoiceReferenceSpecifications.referenceId === referenceId

  const columns = [
    {
      name: 'description',
      label: t('Invoice_description'),
      align: 'left',
      options: {
        sortDirection: 'asc',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'extra_info',
      label: 'Type',
      align: 'left',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'user_name',
      label: t('Invoice_association'),
      align: 'left',
      options: {
        filter: true,
        sort: true,
      },
    },
  ]

  useEffect(() => {
    let mounted = true
    if (mounted) {
      dispatch(fetchInvoiceReferenceSpecifications({ customerId, invoiceId, referenceId }))
    }
    return () => {
      mounted = false
    }
  }, [])

  let content = <PaperLoader />

  if (invoiceReferenceSpecifications.error && idsMatch) {
    content = (
      <PaperFeedbackMessage type="error" message={t('Dashboard_stock_some_error')} withMargin />
    )
  }

  if (
    invoiceReferenceSpecifications.data &&
    invoiceReferenceSpecifications.data.items.length === 0 &&
    idsMatch
  ) {
    content = <PaperFeedbackMessage message={t('Invoice_no_occurences')} withMargin />
  }

  if (
    invoiceReferenceSpecifications.data &&
    invoiceReferenceSpecifications.data.items.length > 0 &&
    idsMatch
  ) {
    content = (
      <TableWithFilteredTotal
        items={invoiceReferenceSpecifications.data.items.map((item) => {
          return {
            ...item,
            user_name: item.user_name || t('Invoice_dunno'),
          }
        })}
        firstTableColumns={columns}
        totalPrice={invoiceReferenceSpecifications.data.aggregated.charges}
      />
    )
  }

  return (
    <tr>
      <td colSpan="10" className={classes.tableContainer}>
        {content}
      </td>
    </tr>
  )
}

export default React.memo(ExpandedReferenceTable)
