import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    paddingTop: theme.spacing(),
    marginRight: theme.spacing(),
    color: theme.palette.text.secondary,
  },
  subtitle: {
    fontWeight: 400,
    marginLeft: theme.spacing(),
    color: theme.palette.text.secondary,
  },
}))

const PaperTitle = (props) => {
  const classes = useStyles()
  const { className, icon, title, subtitle, caption, ...rest } = props

  const rootClassName = classNames(classes.root, className)

  return (
    <div {...rest} className={rootClassName}>
      {icon && <span className={classes.icon}>{icon}</span>}
      {title && (
        <Typography variant="h5">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography className={classes.subtitle} variant="subtitle2">
          {subtitle}
        </Typography>
      )}
    </div>
  )
}

export default PaperTitle
