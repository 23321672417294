import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
}))

const DropdownDivider = () => {
  const classes = useStyles()

  return <Divider className={classes.divider} />
}

export default DropdownDivider
