import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'skyAppHuntgroups/FETCH_FAILURE'
export const FETCH_BEGIN = 'skyAppHuntgroups/FETCH_BEGIN'
export const FETCH_SUCCESS = 'skyAppHuntgroups/FETCH_SUCCESS'

export const fetchSkyAppHuntgroupsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchSkyAppHuntgroupsBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchSkyAppHuntgroupsSuccess = ({ data }) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchSkyAppHuntgroups = (abbId: string) => {
  return async (dispatch) => {
    dispatch(fetchSkyAppHuntgroupsBegin())
    try {
      const response = await getSubscription.huntgroups(abbId)
      dispatch(fetchSkyAppHuntgroupsSuccess({ data: response.data }))
    } catch (err) {
      dispatch(fetchSkyAppHuntgroupsFailure(err.response))
    }
  }
}
