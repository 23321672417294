import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { createCustomer } from 'services/sweb/customer'
import { fetchOCNumbers } from 'state/oCNumbers/actions'
import { fetchOCLandlineNumberOrders } from 'state/oCLandlineNumberOrders/actions'

import { fetchOCNumbersProducts } from 'state/oCNumbersProducts/actions'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Alert, SkyTextField } from '@skytdc/mui/components'
import { Button, LinearProgress, Switch, FormControlLabel } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { DialogWrapper, GradientButton } from 'components'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSnackbar } from 'notistack'
import SelectNumbersStep from './SelectNumbersStep'
import FormStep from './FormStep'
import BulkFormStep from './BulkFormStep'

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: -theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(),
  },
  releaseButton: {
    float: 'right',
    marginBottom: theme.spacing(),
  },
  progressContainer: {
    marginTop: theme.spacing(3),
  },
  progressBar: {
    height: 10,
  },
  popoverContent: {
    textTransform: 'capitalize',
    padding: '10px 20px',
  },
  openPopover: {
    background: theme.palette.background.default,
  },
  label: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
  },
  bulkInput: {
    margin: '1rem',
    maxWidth: 400,
  },
}))

const selectedNumberExtraValues = {
  useGlobalForm: true,
  hasBeenProvisioned: false,
  abbType: '',
}

interface Props extends RouteComponentProps {
  customerId: string
  redirectUrl: string
  history: any
  userEmail: string
}

const ProvisionDialog = ({ customerId, redirectUrl, history, userEmail }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { pageSize } = useSelector((state) => state.oCLandlineNumberOrders)
  const stockNumbers = useSelector((state) => state.oCNumbers)
  const subscriptionProducts = useSelector((state) => state.oCNumbersProducts)
  const [open, setOpen] = useState(false)
  const [bulk, setBulk] = useState<boolean>(false)
  const [step, setStep] = useState(0)
  const [progress, setProgress] = useState(0)

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => history.push(redirectUrl))
  }

  const goToOrders = () => {
    setOpen(false)
    setTimeout(() => history.push(`/${customerId}/operator-connect/numre/ordrer`))
  }

  const handleBack = () => setStep(0)
  const shortenString = (string = '', maxCharacters = 20) =>
    string?.length > maxCharacters ? string?.substring(0, maxCharacters) : string
  const handleFetchStockNumbers = (props) => {
    const fetchParams = {
      customerId,
      pagesize: 'pagesize' in props ? props.pagesize : stockNumbers.pagesize,
      pagenumber: 'pagenumber' in props ? props.pagenumber : stockNumbers.pagenumber,
      e164: 'e164' in props ? props.e164 : stockNumbers.e164,
    }
    dispatch(fetchOCNumbers(fetchParams))
  }
  useEffect(() => {
    setOpen(true)
    changeDocumentTitle(t('OC_numbers_create'), ' - ', ':applicationName')
    handleFetchStockNumbers({
      pagesize: 10,
      pagenumber: 0,
      e164: '',
    })
    dispatch(fetchOCNumbersProducts({ customerId }))
  }, [])

  const { setFieldValue, handleSubmit, values, isSubmitting, errors, setErrors } = useFormik({
    initialValues: {
      selectedNumbers: [],
      abbType: '',
      bulkAmount: null,
      receiptEmail: userEmail,
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      if (step === 0) {
        setStep(1)
      } else {
        let prog = 0
        if (bulk) {
          createCustomer
            .OCOrderNumber({
              customerId,
              json: {
                productTypeId: payload.abbType,
                releaseNumberAmount: payload.bulkAmount,
              },
            })
            .then(() => {
              setProgress(1)
              enqueueSnackbar(t('IntNumberProvSuccess'), { variant: 'success' })
              goToOrders()
            })
            .catch((err) => {
              setErrors({ bulkAmount: 'bug' })
              setProgress(0)
              enqueueSnackbar(t('OC_invite_error'), {
                variant: 'error',
              })
            })
        } else {
          const promises = payload.selectedNumbers
            .filter((x) => !x.hasBeenProvisioned)
            .map((x) => {
              return createCustomer
                .OCOrderNumber({
                  customerId,
                  json: {
                    productTypeId: x.useGlobalForm ? payload.abbType : x.abbType,
                    chosenNumberId: x.id,
                    receiptEmail: payload.receiptEmail,
                  },
                })
                .then((response: { status: string; data: any }) => {
                  return { ...response, number: x.e164 }
                })
                .catch((err) => {
                  return err.response.json().then((decodedError) => {
                    const errorString = decodedError.error.items
                      .map((errString) => errString.key)
                      .join(' ')
                    return { error: errorString, number: x.e164 }
                  })
                })
                .finally(() => {
                  prog += 1
                  setProgress(prog)
                })
            })
          const response = await Promise.all(promises)
          const findErrors = response.filter((x) => !x.status)
          if (findErrors.length > 0) {
            enqueueSnackbar(t('IntNumberProvErrorMessage', { amount: findErrors.length }), {
              variant: 'error',
            })
            const newNumbers = values.selectedNumbers.map((x) => {
              const hasError = Boolean(findErrors.find((y) => y.number === x.e164))
              return {
                ...x,
                hasBeenProvisioned: !hasError,
                useGlobalForm: hasError ? x.useGlobalForm : true,
              }
            })
            setFieldValue('selectedNumbers', newNumbers)
            setErrors({ selectedNumbers: findErrors })
            setProgress(0)
          } else {
            dispatch(fetchOCLandlineNumberOrders({ customerId, pageNumber: 0, pageSize }))

            enqueueSnackbar(t('IntNumberProvSuccess'), { variant: 'success' })
            goToOrders()
          }
        }
      }
    },
  })

  const handleToggleSelectNumber = (e, number) => {
    const existsAlready = values.selectedNumbers.find(
      (x) => x.formattedNumber === number.formattedNumber
    )
    if (existsAlready) {
      setFieldValue(
        'selectedNumbers',
        values.selectedNumbers.filter((x) => x.formattedNumber !== number.formattedNumber)
      )
    } else {
      setFieldValue('selectedNumbers', [
        ...values.selectedNumbers,
        {
          ...number,
          ...selectedNumberExtraValues,
          comment: shortenString(number.cityName) || `...`,
        },
      ])
    }
  }

  const handleSelectAllNumbers = () => {
    const existingIds = values.selectedNumbers.map((x) => x.formattedNumber)
    const newNumbers = stockNumbers.data?.data
      .filter(
        (x) =>
          existingIds.indexOf(x.voxbone.didId) < 0 &&
          x.mediation.length === 0 &&
          !x.voxbone.voiceUriId
      )
      .map((x) => {
        return {
          ...x.voxbone,
          ...selectedNumberExtraValues,
          comment: shortenString(x.voxbone.cityName) || `...`,
        }
      })
    setFieldValue('selectedNumbers', [...values.selectedNumbers, ...newNumbers])
  }
  let content
  if (step === 0) {
    content = bulk ? (
      <div className={classes.bulkInput}>
        <SkyTextField
          value={values.bulkAmount || ''}
          onChange={(name, val: number) => setFieldValue(name, val)}
          type="number"
          name="bulkAmount"
          label={t('OC_create_numbers_bulk_input')}
          error={values.bulkAmount && (values.bulkAmount <= 0 || values.bulkAmount > 20)}
          helperText="The number should be between 1 and 20"
        />
      </div>
    ) : (
      <SelectNumbersStep
        fetchStockNumbers={handleFetchStockNumbers}
        pagenumber={stockNumbers.pagenumber}
        pagesize={stockNumbers.pagesize}
        selectedNumbers={values.selectedNumbers}
        numbers={stockNumbers.data?.data ? stockNumbers.data?.data : []}
        loading={stockNumbers.loading}
        totalCount={stockNumbers.data?.pageing.totalResultSize || 0}
        toggleSelectNumber={handleToggleSelectNumber}
        selectAllNumbers={handleSelectAllNumbers}
      />
    )
  } else {
    content = bulk ? (
      <BulkFormStep
        loading={isSubmitting}
        subscriptionProducts={subscriptionProducts.data?.products || []}
        setValue={setFieldValue}
        errors={errors}
        {...values}
      />
    ) : (
      <FormStep
        subscriptionProducts={subscriptionProducts.data?.products || []}
        toggleSelectNumber={handleToggleSelectNumber}
        setValue={setFieldValue}
        loading={isSubmitting}
        errors={errors}
        {...values}
      />
    )
  }

  if (stockNumbers.error && stockNumbers.error.statusText) {
    content = <Alert type="error" message={stockNumbers.error.statusText} />
  }

  if (isSubmitting) {
    content = (
      <div className={classes.progressContainer}>
        <LinearProgress
          className={classes.progressBar}
          variant="determinate"
          value={(progress / (bulk ? 1 : values.selectedNumbers.length)) * 100}
        />
      </div>
    )
  }

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title={t('OC_createIntNumbers')}
      description={
        step === 0
          ? t(bulk ? 'CreateBulkIntNumbersStepOneDesc' : 'CreateIntNumbersStepOneDesc')
          : t(bulk ? 'CreateBulkIntNumbersStepTwoDesc' : 'CreateIntNumbersStepTwoDesc')
      }
      maxWidth="md"
    >
      {step === 0 && (
        <FormControlLabel
          control={
            <Switch
              edge="end"
              checked={bulk}
              onChange={(e) => setBulk(e.target.checked)}
              color="primary"
            />
          }
          labelPlacement="start"
          label="Bulk"
          classes={{
            label: classes.label,
          }}
        />
      )}
      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault()
          handleSubmit()
        }}
      >
        <div style={{ paddingTop: 1, paddingBottom: 24 }}>{content}</div>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            className={classes.cancelButton}
            disabled={isSubmitting}
            onClick={step === 0 ? handleClose : handleBack}
          >
            {step === 0 ? t('Drawer_create_sub_cancel') : t('Invoice_back')}
          </Button>
          <GradientButton
            disabled={
              (values.selectedNumbers.length === 0 && !bulk) ||
              (bulk && (values.bulkAmount <= 0 || values.bulkAmount > 20))
            }
            loading={isSubmitting}
          >
            {step === 0 ? t('Hardware_next') : t('Organization_unassignedsimcards_order_cta')}
          </GradientButton>
        </div>
      </form>
    </DialogWrapper>
  )
}

export default ProvisionDialog
