import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as authenticate from 'services/partyweb/authentication'
import { cleanMe } from 'state/partyMe/actions'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import { Link } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { IMG_PARTY_LIGHT_BULBS } from 'constants/cdn'

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: '#fffa1e',
    },
  },
  root: {
    flexGrow: 1,
  },
  content: {
    maxWidth: 1200,
    margin: 'auto',
    paddingTop: 60,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundImage: `url(${IMG_PARTY_LIGHT_BULBS})`,
    backgroundRepeat: 'repeat-x',
    backgroundSize: 'contain',
    backgroundPosition: 'top',
    [theme.breakpoints.up('md')]: {
      paddingTop: 120,
      padding: theme.spacing(8),
    },
  },
  appBar: { background: 'white', display: 'block' },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  iconColor: {
    color: '#fffa1e',
  },
  title: {
    flexGrow: 1,
  },
  toolBar: {
    justifyContent: 'right',
    maxWidth: 1200,
    margin: '0 auto',
    paddingRight: 0,
  },
  link: {
    textDecoration: 'none',
  },
  name: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
}))

export default function Layout({ children }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { user } = useSelector((state) => state.partyMe?.data || {})

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const signOutClick = () => {
    authenticate.removeBearer()
    dispatch(cleanMe())
  }

  const name = user && `${user.fornavn} ${user.efternavn}`

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolBar}>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className={classes.iconColor}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {user.admin && (
                <div>
                  <Link className={classes.link} to="/party/admin">
                    <MenuItem>Admin</MenuItem>
                  </Link>
                  <Link className={classes.link} to="/party">
                    <MenuItem>My parties</MenuItem>
                  </Link>
                </div>
              )}
              <MenuItem onClick={signOutClick}>Log ud</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        {name && (
          <Typography className={classes.name} variant="h1">
            Hello {user?.fornavn} {user?.efternavn}
          </Typography>
        )}
        {children}
      </main>
    </div>
  )
}
