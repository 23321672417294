export default (name?: string, len = 2) => {
  if (typeof name !== 'string') {
    return '?'
  }
  return name
    .split(' ')
    .reduce((acc, curr) => acc + curr.charAt(0), '')
    .substr(0, len)
    .toUpperCase()
}
