import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'buaOrders/FETCH_FAILURE'
export const FETCH_BEGIN = 'buaOrders/FETCH_BEGIN'
export const FETCH_SUCCESS = 'buaOrders/FETCH_SUCCESS'

export const fetchBuaOrdersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchBuaOrdersBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchBuaOrdersSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchBuaOrders = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchBuaOrdersBegin({ customerId }))
    try {
      const response = await getCustomer.buaOrders({
        customerId,
      })
      dispatch(fetchBuaOrdersSuccess(response.data))
    } catch (err) {
      dispatch(fetchBuaOrdersFailure(err.response))
    }
  }
}
