import React, { memo } from 'react'
import {
  LocalPhoneOutlined as LandlineIcon,
  // Phonelink as TeamsIcon,
  DialpadOutlined as ExtensionIcon,
} from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'
import { phoneNumberFormat } from 'utils/helpers'
import DisplayNumber from './DisplayNumber'

const Landline = ({
  landlineNumber,
  extension,
  currentUrl,
  disabled,
  disableDisplayNumber,
  broadworks,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <DrawerListItem
        icon={<LandlineIcon />}
        primaryText={t('Organization_avail_numbers_landline')}
        secondaryText={phoneNumberFormat(landlineNumber)}
        isDisabled={disabled}
      />
      <DrawerListItem
        icon={<ExtensionIcon />}
        primaryText={t('Drawer_user_extension')}
        secondaryText={extension}
        isDisabled={disabled}
      />
      {!disableDisplayNumber && (
        <DisplayNumber currentUrl={currentUrl} disabled={disabled || broadworks} />
      )}
    </>
  )
}

export default memo(Landline)

/*
<DrawerListItem
  icon={<TeamsIcon />}
  primaryText="Samtidig ringning"
  secondaryText="Teams-klient og mobil ringer samtidig"
  link="Insert link here"
  linkAnchor="Skift"
/>
*/
