import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, List } from '@material-ui/core'
import {
  PhoneRounded as PhoneIcon,
  PublicRounded as MapIcon,
  SimCardRounded as SimcardIcon,
} from '@material-ui/icons'
import { Paper, PaperContent } from '@skytdc/mui/components'
import { DrawerListItem } from 'components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  setMaxHeight: {
    height: '100%',
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}))

const StockWidget = ({
  landlineNumbers,
  intNumbers,
  unassignedSimcards,
  intNumbersEnabled,
  broadworks,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Paper className={classes.setMaxHeight}>
      <PaperContent noPadding>
        <Typography className={classes.title} variant="body2">
          {t('Dashboard_stock_title')}
        </Typography>
        <List>
          <DrawerListItem
            icon={<PhoneIcon />}
            primaryText={t('Dashboard_stock_landline')}
            secondaryText={
              landlineNumbers.error
                ? t('Dashboard_stock_some_error')
                : t('Dashboard_stock_landline_desc')
            }
            isLoading={!landlineNumbers.data && !landlineNumbers.error}
            link={`/${
              landlineNumbers.customerId || ''
            }/organisation/ledige-numre?type=Fastnetnummer`}
            linkAnchor={`${(landlineNumbers.data && landlineNumbers.data.length) || '0'}`}
          />
          {!broadworks && (
            <>
              <DrawerListItem
                icon={<MapIcon />}
                primaryText={t('Dashboard_stock_int')}
                secondaryText={
                  intNumbers.error
                    ? t('Dashboard_stock_some_error')
                    : (intNumbersEnabled && t('Dashboard_stock_int_desc')) ||
                      t('Dashboard_stock_int_must_activate')
                }
                isLoading={!intNumbers.data && !intNumbers.error}
                link={
                  intNumbersEnabled
                    ? `/${intNumbers.customerId || ''}/organisation/ledige-numre?type=international`
                    : `/${intNumbers.customerId || ''}/addons-og-services`
                }
                linkAnchor={
                  intNumbersEnabled
                    ? `${intNumbers.data && intNumbers.data.length}`
                    : t('Dashboard_stock_see_more')
                }
              />

              <DrawerListItem
                icon={<SimcardIcon />}
                primaryText={t('Dashboard_stock_unassigned_sim')}
                secondaryText={
                  unassignedSimcards.error
                    ? t('Dashboard_stock_some_error')
                    : (unassignedSimcards.data &&
                        unassignedSimcards.data.display_reserved_sim_cards &&
                        t('Dashboard_stock_unassigned_sim_desc')) ||
                      t('Dashboard_stock_unassigned_sim_must_activate')
                }
                isLoading={!unassignedSimcards.data && !unassignedSimcards.error}
                link={
                  unassignedSimcards.data && unassignedSimcards.data.display_reserved_sim_cards
                    ? `/${landlineNumbers.customerId || ''}/organisation/skuffesimkort`
                    : `/${landlineNumbers.customerId || ''}/addons-og-services`
                }
                linkAnchor={
                  unassignedSimcards.data && unassignedSimcards.data.display_reserved_sim_cards
                    ? `${
                        unassignedSimcards.data && unassignedSimcards.data.reserved_sim_cards.length
                      }`
                    : ' '
                }
              />
            </>
          )}
        </List>
      </PaperContent>
    </Paper>
  )
}

export default StockWidget
