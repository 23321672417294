import React from 'react'
import { NavLink } from '@skytdc/mui/components'
import { List, ListSubheader } from '@material-ui/core'
import {
  DashboardRounded as DashboardIcon,
  PeopleAltRounded as UsersIcon,
  BusinessRounded as CompanyIcon,
  CloudRounded as CloudAdoptionIcon,
  AccountBalanceWalletRounded as InvoiceIcon,
  LayersRounded as AddonsIcon,
  AddShoppingCartRounded as HardwareIcon,
  DataUsageRounded as DataUsageRoundedIcon,
  Phone as PhoneIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

interface Props {
  changeRoute: (route: string) => void
  currentUrl: string
  customerId: string
  slim: boolean
}

const NavLinks = ({ changeRoute, currentUrl, customerId, slim }: Props) => {
  const { t } = useTranslation()

  const stored = useSelector((state) => ({
    customer: state.customer,
    hasDataPool: state.customer.data?.has_datapool,
    me: state.me,
  }))

  const pickedCustomer = stored.me.data?.authorizations.find(
    ({ customer_id }) => customer_id === customerId
  )

  const operatorConnectEnabled = stored.customer.data.operator_connect_enabled
  const showOC =
    operatorConnectEnabled &&
    pickedCustomer?.roles.length === 1 &&
    pickedCustomer?.roles.includes('oc_admin')

  return (
    <List component="nav" disablePadding aria-label="Main Menu">
      {!showOC && (
        <>
          <NavLink
            primaryText="Dashboard"
            isActive={currentUrl.includes('/dashboard')}
            url={`/${customerId}/dashboard`}
            icon={<DashboardIcon />}
            changeRoute={changeRoute}
            isMobile={slim}
            tooltip={slim ? 'Dashboard' : ''}
          />
          <NavLink
            primaryText={t('Drawer_header_users')}
            isActive={currentUrl.includes(`${customerId}/brugere`)}
            url={`/${customerId}/brugere`}
            icon={<UsersIcon />}
            changeRoute={changeRoute}
            isMobile={slim}
            tooltip={slim ? t('Drawer_header_users') : ''}
          />
          <NavLink
            primaryText={t('Invoice_org')}
            isActive={currentUrl.includes('/organisation')}
            url={`/${customerId}/organisation/ledige-numre`}
            icon={<CompanyIcon />}
            changeRoute={changeRoute}
            isMobile={slim}
            tooltip={slim ? t('Invoice_org') : ''}
          />
        </>
      )}
      {stored.hasDataPool && (
        <NavLink
          primaryText={t('Data_pool_title')}
          isActive={currentUrl.includes('/data-pool-usage')}
          url={`/${customerId}/data-pool-usage`}
          icon={<DataUsageRoundedIcon />}
          changeRoute={changeRoute}
          isMobile={slim}
          tooltip={slim ? t('Data_pool_title') : ''}
        />
      )}
      <NavLink
        primaryText={t('Invoice_title')}
        isActive={currentUrl.includes('/faktura')}
        url={`/${customerId}/faktura`}
        icon={<InvoiceIcon />}
        changeRoute={changeRoute}
        isMobile={slim}
        tooltip={slim ? t('Invoice_title') : ''}
      />
      {!showOC && (
        <>
          <NavLink
            primaryText="Cloud adoption"
            isActive={currentUrl.includes('/cloud-adoption')}
            url={`/${customerId}/cloud-adoption`}
            icon={<CloudAdoptionIcon />}
            changeRoute={changeRoute}
            isMobile={slim}
            tooltip={slim ? 'Cloud adoption' : ''}
          />
          <NavLink
            primaryText={t('Layout_addons')}
            isActive={currentUrl.includes('/addons-og-services')}
            url={`/${customerId}/addons-og-services`}
            icon={<AddonsIcon />}
            changeRoute={changeRoute}
            isMobile={slim}
            tooltip={slim ? t('Layout_addons') : ''}
          />
        </>
      )}
      {!stored.customer?.data?.customer_properties?.broadworks && !showOC && (
        <NavLink
          primaryText={t('Layout_hardware')}
          isActive={currentUrl.includes('/bestil-hardware')}
          url="https://www.tdcerhvervscenter.dk/log-ind"
          icon={<HardwareIcon />}
          isMobile={slim}
          tooltip={slim ? t('Layout_hardware') : ''}
        />
      )}
      {!showOC && operatorConnectEnabled && (
        <ListSubheader>{t('OC_navLinksSubHeader')}</ListSubheader>
      )}
      {operatorConnectEnabled && (
        <>
          <NavLink
            primaryText={t('Numbers')}
            isActive={currentUrl.includes('/operator-connect/numre')}
            url={`/${customerId}/operator-connect/numre`}
            icon={<PhoneIcon />}
            changeRoute={changeRoute}
            isMobile={slim}
            tooltip={slim ? t('Numbers') : ''}
          />
          <NavLink
            primaryText={t('Invoice_org')}
            isActive={currentUrl.includes('/operator-connect/brugere')}
            url={`/${customerId}/operator-connect/brugere`}
            icon={<SettingsIcon />}
            changeRoute={changeRoute}
            isMobile={slim}
            tooltip={slim ? t('Invoice_org') : ''}
          />
        </>
      )}
    </List>
  )
}

export default NavLinks
