import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, ListItemSecondaryAction, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  selectedRows: {
    fontSize: theme.typography.h5.fontSize,
  },
  listItemText: {
    paddingLeft: '10px',
  },
  toolbarList: {
    marginLeft: '-10px',
    padding: '0px',
  },
  dropdownButton: {
    marginRight: theme.spacing(),
  },
}))

const SelectedRowsActions = ({ selectedRows, reportMissing }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <List className={classes.toolbarList}>
        <ListItem disableGutters dense>
          <ListItemText
            className={classes.listItemText}
            classes={{
              primary: classes.selectedRows,
            }}
            primary={t('Organization_unassignedsimcards_chosen', { amount: selectedRows.length })}
          />
        </ListItem>
        <ListItemSecondaryAction>
          <Button
            className={classes.dropdownButton}
            size="small"
            variant="contained"
            color="primary"
            onClick={reportMissing}
          >
            {t('Organization_unassignedsimcards_report_lost')}
          </Button>
        </ListItemSecondaryAction>
      </List>
    </>
  )
}

export default SelectedRowsActions
