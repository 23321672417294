import { simcards } from 'services/sweb/me'

export const FETCH_FAILURE = 'endUserSimcards/FETCH_FAILURE'
export const FETCH_BEGIN = 'endUserSimcards/FETCH_BEGIN'
export const FETCH_SUCCESS = 'endUserSimcards/FETCH_SUCCESS'

export const fetchEndUserSimcardsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchEndUserSimcardsBegin = ({ abbId }) => {
  return {
    type: FETCH_BEGIN,
    abbId,
  }
}

export const fetchEndUserSimcardsSuccess = ({ data, abbId }) => {
  return {
    type: FETCH_SUCCESS,
    data,
    abbId,
  }
}

export const fetchEndUserSimcards = ({ abbId }) => {
  return async (dispatch) => {
    dispatch(fetchEndUserSimcardsBegin({ abbId }))
    try {
      const response = await simcards({
        abbId,
      })
      dispatch(fetchEndUserSimcardsSuccess({ abbId, data: response.data }))
    } catch (err) {
      dispatch(fetchEndUserSimcardsFailure(err.response))
    }
  }
}
