import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  option: {},
  container: {
    minWidth: 200,
  },
  inputRoot: {
    paddingTop: '5px !important',
    paddingBottom: '6px !important',
    borderRadius: theme.shape.borderRadius,
  },
  showClear: {
    visibility: 'visible',
  },
  hideClear: {
    visibility: 'hidden',
  },
}))

const CountrySelect = ({ countryCode, countryList, fetchStockNumbers }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSetValue = (_event, value) => {
    fetchStockNumbers({
      pagenumber: 0,
      countryCode: value ? value.countryCodeA3 : '',
    })
  }

  const findValue = countryCode
    ? countryList.find((x) => x.countryCodeA3 === countryCode) || null
    : null

  return (
    <Autocomplete
      options={countryList || []}
      // eslint-disable-next-line
      getOptionSelected={(option: any, value: any) => option.countryCodeA3 === value.countryCodeA3}
      value={findValue}
      blurOnSelect
      onChange={handleSetValue}
      classes={{
        option: classes.option,
        clearIndicator: findValue ? classes.showClear : classes.hideClear,
      }}
      closeText={t('Organization_orders_drawer_close')}
      getOptionLabel={(option) => `${option.name} (+${option.phoneCode})`}
      noOptionsText={t('Router_ser3tharch')}
      renderOption={(option) => (
        <>
          {option.name} (+{option.phoneCode})
        </>
      )}
      renderInput={(params) => (
        <div className={classes.container}>
          <TextField
            {...params}
            fullWidth
            size="small"
            placeholder={t('SelectCountry')}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              classes: {
                root: classes.inputRoot,
              },
            }}
          />
        </div>
      )}
    />
  )
}

export default CountrySelect
