import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'adGroupFilters/FETCH_FAILURE'
export const FETCH_BEGIN = 'adGroupFilters/FETCH_BEGIN'
export const FETCH_SUCCESS = 'adGroupFilters/FETCH_SUCCESS'

export const fetchAdGroupFiltersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAdGroupFiltersBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchAdGroupFiltersSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchAdGroupFilters = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchAdGroupFiltersBegin({ customerId }))
    try {
      const response = await getCustomer.adGroupFilters({
        customerId,
      })
      dispatch(fetchAdGroupFiltersSuccess(response.data))
    } catch (err) {
      dispatch(fetchAdGroupFiltersFailure(err.response))
    }
  }
}
