import React from 'react'
import { TableRow, TableCell, Checkbox, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons'
import { UserWithAvatar } from 'components'
import MobileTableCell from '../MobileTableCell'
import TeamsTableCell from '../TeamsTableCell'
import InvoiceTableCell from '../InvoiceTableCell'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  removePadding: {
    padding: '0px',
  },
  firstCell: {
    paddingLeft: theme.spacing(3),
  },
  isMobile: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
}))

const SingleUserTableRow = ({
  user,
  handleSelectSingleUser,
  isUserSelected,
  history,
  location,
}) => {
  const classes = useStyles()
  const blockUserEditing = Boolean(user.endDate || user.inChange || !user.requiresAzureLink)

  const popUserAction = (event, requestedAction) => {
    event.stopPropagation()
    // TODO replace with inline link where needed <Link to={location => `${location.pathname}${user.certainId}/${requestedAction}`} /> when next RR version is released
    const action =
      requestedAction === 'opret-abonnement' && user.abbId ? 'opgrader-abonnement' : requestedAction
    history.push({
      pathname: `${location.pathname}/${user.certainId}/${action}`,
    })
  }
  const openUserDrawer = (event) => {
    event.stopPropagation()
    history.push({
      pathname: `${location.pathname}/${user.certainId}`,
    })
  }
  const selectSingleUser = (event) => {
    event.stopPropagation()
    handleSelectSingleUser(user)
  }
  return (
    <TableRow hover selected={isUserSelected} onClick={openUserDrawer}>
      <TableCell padding="none">
        <div className={classes.checkbox}>
          <Checkbox color="primary" checked={isUserSelected} onClick={selectSingleUser} />
        </div>
      </TableCell>
      <TableCell padding="none" style={{ maxWidth: 300 }}>
        <UserWithAvatar
          name={user.name}
          email={user.requiresAzureLink && user.email}
          avatar={user.avatar}
          requiresAzureLink={user.requiresAzureLink}
          showSubButNoTeamsWarning={Boolean(user.userId && !user.azureId)}
          inChange={Boolean(user.inChange)}
          endDate={user.endDate}
        />
      </TableCell>
      <TableCell align="left" padding="none">
        <MobileTableCell
          simCards={user.simCards}
          mobileNumber={user.mobileNumber}
          mobilePackage={user.mobilePackage}
          popUserAction={popUserAction}
          disableButton={blockUserEditing}
          mobileOneNumberShadowNumber={user.mobileOneNumberShadowNumber}
        />
      </TableCell>
      <TableCell align="left" padding="none">
        <TeamsTableCell
          landlineNumber={user.teamsDisplayNumber}
          abbType={user.abbType}
          popUserAction={popUserAction}
          disableButton={blockUserEditing}
          mobileOneNumberShadowNumber={user.mobileOneNumberShadowNumber}
        />
      </TableCell>
      <TableCell align="right" padding="none">
        <InvoiceTableCell invoice={user.invoice} monthlyPrice={user.monthlyPrice} />
      </TableCell>
      <TableCell align="center" padding="none">
        <IconButton
          onClick={(event) => openUserDrawer(event)}
          variant="text"
          disabled={blockUserEditing}
        >
          <ChevronRightIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default SingleUserTableRow
