import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { PaperLoader } from 'components'
import MissingAzureId from 'views/Drawer/MissingAzureId'
import CreateSubscription from 'views/Drawer/CreateSubscription'
import Simcards from 'views/Drawer/Simcards'
import OrderSimcard from 'views/Drawer/OrderSimcard'
import MobileDisplayNumber from 'views/Drawer/MobileDisplayNumber'
import LandlineDisplayNumber from 'views/Drawer/LandlineDisplayNumber'
import MobileCallForwarding from 'views/Drawer/MobileCallForwarding'
import UpgradeSubscription from 'views/Drawer/UpgradeSubscription'
import ChangeMobilePackage from 'views/Drawer/ChangeMobilePackage'
// import TransferSubscription from 'views/Drawer/TransferSubscription'
import CancelSubscription from 'views/Drawer/CancelSubscription'
import NotFound from 'views/Drawer/NotFound'
import { useTranslation } from 'react-i18next'

const routes = (user, subscription) => {
  const { t } = useTranslation()
  const name = (user.name.length > 2 && user.name) || t('Router_this_user')
  const titleName = (user.name.length > 2 && ` - ${user.name}`) || ''
  return [
    {
      action: 'opret-abonnement',
      title: `${t('Organization_orders_type_create')}${titleName}`,
      component: CreateSubscription,
      notAllowed: [
        {
          condition: Boolean(!user.azureId),
          redirectAction: 'manglende-azure-id',
          redirectMessage: '',
        },
        {
          condition: Boolean(subscription),
          redirectAction: 'opgrader-abonnement',
          redirectMessage: '',
        },
        {
          condition: Boolean(subscription && subscription.in_change),
          redirectAction: '',
          redirectMessage: t('Router_1', { name }),
        },
      ],
    },
    {
      action: 'opgrader-abonnement',
      title: `${t('Router_asdf')}${titleName}`,
      component: UpgradeSubscription,
      notAllowed: [
        {
          condition: Boolean(!user.azureId),
          redirectAction: 'manglende-azure-id',
          redirectMessage: '',
        },
        {
          condition: Boolean(!subscription),
          redirectAction: 'opret-abonnement',
          redirectMessage: '',
        },
        {
          condition: Boolean(subscription && subscription.in_change),
          redirectAction: '',
          redirectMessage: t('Router_1', { name }),
        },
        {
          condition: Boolean(subscription && !subscription.editable_status.editable),
          redirectAction: '',
          redirectMessage: t('Router_1', { name }),
        },
        {
          condition: Boolean(
            subscription && subscription.mobile_number && subscription.landline_number
          ),
          redirectAction: '',
          redirectMessage: t('Router_2', { name }),
        },
        {
          condition: Boolean(subscription && subscription.mobile_onenumber_shadownumber),
          redirectAction: '',
          redirectMessage: t('HasMobileOneNumberError'),
        },
      ],
    },
    {
      action: 'mobil-viderestilling',
      title: `${t('Organization_roles_mobile_forward')}${titleName}`,
      component: MobileCallForwarding,
      notAllowed: [
        {
          condition: Boolean(!subscription),
          redirectAction: 'opret-abonnement',
          redirectMessage: t('Router_3', { name }),
        },
        {
          condition: Boolean(subscription && !subscription.mobile_number),
          redirectAction: 'opgrader-abonnement',
          redirectMessage: t('Router_4', { name }),
        },
        {
          condition: Boolean(subscription && subscription.mobile_onenumber_shadownumber),
          redirectAction: '',
          redirectMessage: t('HasMobileOneNumberError'),
        },
      ],
    },
    {
      action: 'mobil-nummervisning',
      title: `${t('Drawer_display_title')}${titleName}`,
      component: MobileDisplayNumber,
      notAllowed: [
        {
          condition: Boolean(!subscription),
          redirectAction: 'opret-abonnement',
          redirectMessage: t('Router_3', { name }),
        },
        {
          condition: Boolean(subscription && !subscription.mobile_number),
          redirectAction: 'opgrader-abonnement',
          redirectMessage: t('Router_4', { name }),
        },
        {
          condition: Boolean(subscription && subscription.mobile_onenumber_shadownumber),
          redirectAction: '',
          redirectMessage: t('HasMobileOneNumberError'),
        },
      ],
    },
    {
      action: 'fastnet-nummervisning',
      title: `${t('Drawer_landline_title')}${titleName}`,
      component: LandlineDisplayNumber,
      notAllowed: [
        {
          condition: Boolean(!subscription),
          redirectAction: 'opret-abonnement',
          redirectMessage: t('Router_3', { name }),
        },
        {
          condition: Boolean(subscription && !subscription.landline_number),
          redirectAction: 'opgrader-abonnement',
          redirectMessage: t('Router_5', { name }),
        },
        {
          condition: Boolean(subscription && subscription.mobile_onenumber_shadownumber),
          redirectAction: '',
          redirectMessage: t('HasMobileOneNumberError'),
        },
      ],
    },
    /* {
      action: 'overfoer-abonnement',
      title: 'Overfoer abonnement',
      component: TransferSubscription,
      notAllowed: [
        {
          condition: Boolean(!subscription),
          redirectAction: 'opret-abonnement',
          redirectMessage: `${name} har intet abonnement. Vil du oprette et?`,
        },
      ],
    }, */
    {
      action: 'simkort',
      title: `${t('Drawer_simcard_simcard')}${titleName}`,
      component: Simcards,
      notAllowed: [
        {
          condition: Boolean(!subscription),
          redirectAction: 'opret-abonnement',
          redirectMessage: t('Router_3', { name }),
        },
        {
          condition: Boolean(subscription && !subscription.mobile_number),
          redirectAction: 'opgrader-abonnement',
          redirectMessage: t('Router_4', { name }),
        },
      ],
    },
    {
      action: 'bestil-simkort',
      title: `${t('Drawer_simcard_order_title')}${titleName}`,
      component: OrderSimcard,
      notAllowed: [
        {
          condition: Boolean(!subscription),
          redirectAction: 'opret-abonnement',
          redirectMessage: t('Router_3', { name }),
        },
        {
          condition: Boolean(subscription && !subscription.mobile_number),
          redirectAction: 'opgrader-abonnement',
          redirectMessage: t('Router_4', { name }),
        },
      ],
    },
    {
      action: 'skift-mobilpakke',
      title: `${t('Dashboard_most_used_mobile')}${titleName}`,
      component: ChangeMobilePackage,
      notAllowed: [
        {
          condition: Boolean(!subscription),
          redirectAction: 'opret-abonnement',
          redirectMessage: t('Router_3', { name }),
        },
        {
          condition: Boolean(subscription && !subscription.mobile_number),
          redirectAction: 'opgrader-abonnement',
          redirectMessage: t('Router_4', { name }),
        },
        {
          condition: Boolean(
            subscription &&
              subscription.mobile_package &&
              subscription.mobile_package.current &&
              !subscription.mobile_package.current.modifiable
          ),
          redirectAction: '',
          redirectMessage: t('Router_76'),
        },
      ],
    },
    {
      action: 'nedlaeg-abonnementer',
      title: `${t('Router_asdasd')}${titleName}`,
      component: CancelSubscription,
      notAllowed: [
        {
          condition: Boolean(!subscription),
          redirectAction: 'opret-abonnement',
          redirectMessage: t('Router_3', { name }),
        },
        {
          condition: Boolean(
            (subscription && !subscription.editable) || (subscription && subscription.in_change)
          ),
          redirectAction: '',
          redirectMessage: t('Router_1', { name }),
        },
        {
          condition: Boolean(subscription && subscription.mobile_onenumber_shadownumber),
          redirectAction: '',
          redirectMessage: t('HasMobileOneNumberError'),
        },
      ],
    },
    {
      action: 'manglende-azure-id',
      title: `${t('Router_sfd')}${titleName}`,
      component: MissingAzureId,
      notAllowed: [
        {
          condition: Boolean(user.azureId),
          redirectAction: '',
          redirectMessage: t('Router_7', { name }),
        },
      ],
    },
  ]
}

const UserActionRoutes = ({
  user,
  userBaseUrl,
  requestedAction,
  handleClose,
  customerId,
  changeRoute,
  subscription,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const getRoutes = routes(user, subscription)
  const requestedRoute = getRoutes.find((r) => requestedAction === r.action)
  const isNotAllowed = requestedRoute && requestedRoute.notAllowed.find((r) => r.condition === true)

  useEffect(() => {
    if (subscription && subscription.end_date) {
      enqueueSnackbar(t('Router_88'), {
        variant: 'warning',
      })
      changeRoute()
    }
    if (subscription && subscription.in_change) {
      enqueueSnackbar(t('Router_99'), {
        variant: 'warning',
      })
      changeRoute()
    }
    if (isNotAllowed) {
      if (isNotAllowed.redirectMessage.length > 0) {
        enqueueSnackbar(isNotAllowed.redirectMessage, { variant: 'warning' })
      }
      changeRoute(isNotAllowed.redirectAction)
    }
  }, [requestedAction])

  if (requestedRoute && !isNotAllowed) {
    return (
      <Route
        path={`${userBaseUrl}/${requestedRoute.action}`}
        exact
        render={(props) => (
          <requestedRoute.component
            {...props}
            user={user}
            subscription={subscription}
            customerId={customerId}
            title={requestedRoute.title}
            changeRoute={changeRoute}
            handleClose={handleClose}
          />
        )}
      />
    )
  }

  if (!requestedRoute) {
    return <Route path="*" component={NotFound} />
  }

  return <PaperLoader />
}

export default React.memo(UserActionRoutes)
