import React, { useState, useEffect } from 'react'
import { Typography, Grid } from '@material-ui/core'
import useDebounce from 'utils/hooks/useDebounce'
import { SkySelect, SkyTextField, Alert } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'

const NumberForm = ({
  abbType,
  availableSubscriptionTypes,
  comment,
  updateNumber,
  loading,
  index,
  error,
}) => {
  const { t } = useTranslation()
  const [internalComment, setInternalComment] = useState(comment)
  const [debouncedComment] = useDebounce(internalComment, 250)

  useEffect(() => {
    updateNumber('comment', internalComment, index)
  }, [debouncedComment])

  return (
    <div style={{ margin: 16 }}>
      <Grid container spacing={2}>
        {error && (
          <Grid item xs={12}>
            <Alert type="error" message={error.error} />
          </Grid>
        )}
        <Grid item xs={6}>
          <SkySelect
            label={t('Drawer_price_sub')}
            required
            name="abbType"
            disableClearable
            value={availableSubscriptionTypes.find((x) => x.value === abbType) || null}
            options={availableSubscriptionTypes}
            withMargin={false}
            disabled={loading}
            onChange={(name, abbType) => updateNumber(name, abbType.value, index)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.value === value.value}
            renderOption={(option) => (
              <>
                <Typography>{option.label}</Typography>
                <Typography variant="body2" color="textSecondary">
                  DKK {option.price} <span style={{ marginLeft: '1.5ch' }}>ID {option.value}</span>
                </Typography>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <SkyTextField
            withMargin={false}
            disabled={loading}
            value={internalComment}
            label={t('Invoice_description')}
            onChange={(_name, val) => {
              if (typeof val === 'string' && val.length <= 20) {
                setInternalComment(val)
              }
            }}
            helperText={t('CommentDescription')}
            name="comment"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default NumberForm
