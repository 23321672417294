import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch, batch } from 'react-redux'
import { fetchAvailableAtRestMobileNumbers } from 'state/availableAtRestMobileNumbers/actions'
import { fetchAvailableInternationalNumbers } from 'state/availableInternationalNumbers/actions'
import { fetchAvailableLandlineNumbers } from 'state/availableLandlineNumbers/actions'
import {
  Paper,
  PaperContent,
  PaperLoader,
  MuiDataTable,
  PaperFeedbackMessage,
  FloatingButton,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PortDialog from './PortDialog'
import { phoneNumberFormat } from 'utils/helpers'

const useStyles = makeStyles((theme) => ({
  intro: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  link: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  portButton: {
    marginTop: theme.spacing(2),
  },
}))

const Numbers = ({ match, history }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const stored = useSelector(
    (state) => ({
      landline: state.availableLandlineNumbers,
      intNumbers: state.availableInternationalNumbers,
      mobile: state.availableAtRestMobileNumbers,
    }),
    shallowEqual
  )
  const queryParams = new URLSearchParams(history.location.search)
  const { landline, intNumbers, mobile } = stored

  const urlFilters = () => {
    const type = queryParams.get('type')
    const availableNumbers = type === 'international'

    if (availableNumbers || type === t('Organization_avail_numbers_landline')) {
      return {
        options: {
          filterList: [
            (availableNumbers && t('Organization_avail_numbers_int')) ||
              t('Organization_avail_numbers_landline'),
          ],
        },
      }
    }
  }

  const columns = [
    {
      name: 'number',
      label: t('Organization_avail_numbers_td1'),
      align: 'left',
    },
    {
      name: 'type',
      label: t('Organization_avail_numbers_td2'),
      align: 'left',
      ...urlFilters(),
    },
    {
      name: 'country',
      label: t('Organization_avail_numbers_td3'),
      align: 'left',
    },
  ]

  const options = {
    searchOpen: true,
    searchPlaceholder: t('Organization_avail_numbers_search'),
    selectableRows: 'none',
    rowHover: false,
    viewColumns: false,
    onFilterChange: (changedColumn, filterList) => {
      // const filter = `?${changedColumn}` || ''
      // history.push(`/${match.params.customer_id}/organisation/ledige-numre${filter}`)
    },
  }

  const openPortDialog = () =>
    history.push(`/${match.params.customer_id}/organisation/ledige-numre/indporter-numre`)

  useEffect(() => {
    batch(() => {
      dispatch(
        fetchAvailableLandlineNumbers({ customerId: match.params.customer_id, oneNumberType: null })
      )
      dispatch(fetchAvailableAtRestMobileNumbers({ customerId: match.params.customer_id }))
      dispatch(fetchAvailableInternationalNumbers({ customerId: match.params.customer_id }))
    })
  }, [])

  let content = <PaperLoader />

  if (landline.error || mobile.error || intNumbers.error) {
    content = (
      <PaperFeedbackMessage
        message="An error occurred"
        type="error"
        withMargin
        disableGutters={false}
      />
    )
  }

  if (landline.data && mobile.data && intNumbers.data) {
    const combinedNumbers = [...landline.data, ...mobile.data, ...intNumbers.data].map((number) => {
      if (!number.country && !number.operator)
        Object.assign(number, {
          number: `+45 ${phoneNumberFormat(number.number)}`,
          type: t('Organization_avail_numbers_landline'),
          country: t('Organization_avail_numbers_denmark'),
        })
      if (!number.country && number.operator)
        Object.assign(number, {
          number: `+45 ${phoneNumberFormat(number.number)}`,
          type: t('Organization_avail_numbers_resting'),
          country: t('Organization_avail_numbers_denmark'),
        })
      if (number.country && number.country.country_name)
        Object.assign(number, {
          type: t('Organization_avail_numbers_int'),
          number: `+${number.country.country_prefix} ${phoneNumberFormat(number.number)}`,
          country: number.country.country_name,
        })
      return number
    })
    content =
      combinedNumbers.length > 0 ? (
        <MuiDataTable data={combinedNumbers} options={options} columns={columns} />
      ) : (
        <PaperFeedbackMessage
          message={t('Organization_avail_numbers_no_avail')}
          withMargin
          disableGutters={false}
        />
      )
  }

  return (
    <>
      <Paper>
        <PaperContent noPadding>
          <Typography variant="body1" className={classes.intro}>
            {t('Organization_avail_numbers_description')}
          </Typography>
          <Typography variant="body1" className={classes.link} onClick={openPortDialog}>
            Ønsker du at indportere fastnetnumre? Klik her.
          </Typography>
          {content}
        </PaperContent>
      </Paper>
      <FloatingButton
        tooltip={t('PortNumbers')}
        className={classes.portButton}
        onClick={openPortDialog}
      />
      <Route
        render={(props) => <PortDialog {...props} customerId={match.params.customer_id} />}
        exact
        path="/:customer_id/organisation/ledige-numre/indporter-numre"
      />
    </>
  )
}

export default Numbers
