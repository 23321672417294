import { profileImage } from 'services/sweb/me'

export const FETCH_FAILURE = 'endUserProfileImage/FETCH_FAILURE'
export const FETCH_BEGIN = 'endUserProfileImage/FETCH_BEGIN'
export const FETCH_SUCCESS = 'endUserProfileImage/FETCH_SUCCESS'

export const fetchEndUserProfileImageFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchEndUserProfileImageBegin = ({ abbId }) => {
  return {
    type: FETCH_BEGIN,
    abbId,
  }
}

export const fetchEndUserProfileImageSuccess = ({ data, abbId }) => {
  return {
    type: FETCH_SUCCESS,
    data,
    abbId,
  }
}

export const fetchEndUserProfileImage = ({ abbId }) => {
  return async (dispatch) => {
    dispatch(fetchEndUserProfileImageBegin({ abbId }))
    try {
      const response = await profileImage({
        abbId,
      })
      dispatch(fetchEndUserProfileImageSuccess({ abbId, data: response.data }))
    } catch (err) {
      dispatch(fetchEndUserProfileImageFailure(err.response))
    }
  }
}
