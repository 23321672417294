import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { PhoneForwardedOutlined as CallForwardingIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'

const CallForwarding = ({ currentUrl, disabled }) => {
  const { t } = useTranslation()
  const mobileCallForwarding = useSelector((state) => state.mobileCallForwarding)

  const createLabel = () => {
    let label = t('Drawer_user_disabled')
    if (mobileCallForwarding.data && mobileCallForwarding.data.callforward_enabled) {
      label = `${
        mobileCallForwarding.data.unconditional
          ? t('Drawer_user_all_enabled')
          : t('Drawer_user_cond_enabled')
      } +${mobileCallForwarding.data.prefix} ${mobileCallForwarding.data.forward_to}`
    }
    return label
  }

  return (
    <DrawerListItem
      icon={<CallForwardingIcon />}
      primaryText={t('Drawer_user_forwarding')}
      isLoading={Boolean(!mobileCallForwarding.data)}
      isDisabled={disabled}
      secondaryText={
        mobileCallForwarding.data && !mobileCallForwarding.error
          ? createLabel()
          : t('Drawer_user_err')
      }
      link={`${currentUrl}/mobil-viderestilling`}
      linkAnchor={mobileCallForwarding.error ? t('Drawer_user_see_more') : t('Drawer_user_skift')}
    />
  )
}

export default memo(CallForwarding)
