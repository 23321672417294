import { getTitleOption } from './store'

export default (...argStrings) => {
  const titleStringArr = argStrings.map((key) => {
    if (typeof key !== 'string') {
      TypeError('document title accepts only arguments in form of strings')
    }
    const titleOptionValue = getTitleOption(key)
    return titleOptionValue || key
  })
  const titleStr = titleStringArr.join('')
  window.document.title = titleStr
}
