import React from 'react'
import { LoadingSpinner, Alert } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'
import InvoiceWidgetContainer from './InvoiceWidgetContainer'
import LatestInvoice from './InvoiceWidgetLatestInvoice'

const InvoiceWidget = ({ invoice, changePage, ski }) => {
  const { t } = useTranslation()
  const [firstInvoice] = (invoice.data && invoice.data.invoices) || []
  const title = t('Dashboard_invoice_latest')
  const subTitle = ski ? t('Invoice_subTitle_Quarter_Spent') : t('Invoice_subTitle')
  let content = <LoadingSpinner />

  if (firstInvoice) {
    content = <LatestInvoice invoice={firstInvoice} subTitle={subTitle} changePage={changePage} />
  }
  if (invoice.data && invoice.data.invoices.length === 0) {
    content = <Alert disableGutters={false} message={t('Dashboard_invoice_none')} withMargin />
  }

  if (invoice.error) {
    content = (
      <Alert type="error" disableGutters={false} message={invoice.error.statusText} withMargin />
    )
  }

  return (
    <InvoiceWidgetContainer
      title={title}
      content={content}
      showFooter={Boolean(invoice.data && invoice.data.invoices.length > 0)}
      customerId={invoice.customerId}
    />
  )
}

export default InvoiceWidget
