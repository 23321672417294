import React, { useEffect } from 'react'
import { ShoppingBasketOutlined as ProductIcon } from '@material-ui/icons'
import DrawerListItem from '../DrawerListItem'

const defaultUnitProps = { prefix: '', suffix: ',-', locale: 'da-DK' }

const FeatureListUnitTotalSum = ({
  value = 0,
  prefix = defaultUnitProps.prefix,
  suffix = defaultUnitProps.suffix,
  locale = defaultUnitProps.locale,
}) => {
  const numberStr = value.toLocaleString(locale)
  const numberText = `${prefix}${numberStr}${suffix}`
  return <DrawerListItem secondaryActionText={numberText} disableGutters />
}

const FeatureListUnitSumItem = ({
  featureListItemProps = {},
  icon = <ProductIcon />,
  category = '',
  name = '',
  oldProduct = {},
  value,
  prefix = defaultUnitProps.prefix,
  suffix = defaultUnitProps.suffix,
  locale = defaultUnitProps.locale,
}) => {
  const valueLocaleStr = value.toLocaleString(locale)
  const priceText = `${prefix}${valueLocaleStr}${suffix}`

  return (
    <DrawerListItem
      {...featureListItemProps}
      icon={icon}
      primaryText={category}
      secondaryText={
        oldProduct.label ? (
          <>
            <span style={{ textDecoration: 'line-through' }}>{oldProduct.label}</span> {name}
          </>
        ) : (
          name
        )
      }
      secondaryActionText={
        oldProduct.price ? (
          <>
            <span style={{ textDecoration: 'line-through', color: '#66788A' }}>
              {oldProduct.price}
            </span>
            {'  '}
            {priceText}
          </>
        ) : (
          priceText
        )
      }
      disableGutters
    />
  )
}

const FeatureListUnitSum = ({
  featureListItemProps = {},
  featureListItemTotalProps = {},
  units = [],
  unitProps = { ...defaultUnitProps },
  totalUnitProps = { ...defaultUnitProps },
  withTotalSum = true,
  onTotalSum,
}) => {
  const totalSumReducer = (total, curr) => Number(total) + Number(curr)
  const totalSum = units.map((item) => item.value).reduce(totalSumReducer, 0)

  useEffect(() => {
    if (typeof onTotalSum === 'function') {
      onTotalSum(totalSum)
    }
  }, [totalSum])

  return (
    <>
      {units.map((item, idx) => {
        const k = `${idx}${item.price}`
        return (
          <FeatureListUnitSumItem
            key={k}
            featureListItemProps={featureListItemProps}
            icon={item.icon}
            category={item.category}
            name={item.name}
            value={item.value}
            oldProduct={item.oldProduct}
            {...unitProps}
          />
        )
      })}
      {withTotalSum && (
        <FeatureListUnitTotalSum
          {...featureListItemTotalProps}
          {...totalUnitProps}
          value={totalSum}
        />
      )}
    </>
  )
}

export default FeatureListUnitSum
