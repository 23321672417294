import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  noContentPadding: {
    padding: '0px !important',
  },
  title: {
    fontWeight: 700,
    fontSize: 22,
    marginTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  contentPadding: {
    paddingTop: '8px !important',
  },
}))

interface DialogWrapperProps {
  open: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  onClose: () => void
  title: string
  description?: string
  buttons?: JSX.Element
  withDivider?: boolean
  contentPadding?: boolean
  children?: JSX.Element | JSX.Element[]
  className?: string
  paperClassName?: string
  contentClassName?: string
}

const DialogWrapper = ({
  open,
  maxWidth = 'sm',
  onClose,
  title,
  description,
  buttons,
  withDivider = false,
  contentPadding = true,
  children,
  className,
  paperClassName,
  contentClassName,
}: DialogWrapperProps) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={maxWidth}
      onClose={onClose}
      className={className}
      classes={{ paper: paperClassName }}
    >
      {title && (
        <DialogTitle disableTypography className={classes.title}>
          {title}
        </DialogTitle>
      )}
      <DialogContent
        dividers={withDivider}
        className={`${
          contentPadding ? classes.contentPadding : classes.noContentPadding
        } ${contentClassName || ''}`}
      >
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
      </DialogContent>
      {buttons && <DialogActions>{buttons}</DialogActions>}
    </Dialog>
  )
}

export default DialogWrapper
