import React from 'react'
import classNames from 'classnames'
import { CircularProgress } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  progressWrapper: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  white: {
    color: theme.palette.common.white,
  },
}))

interface Props {
  className?: string
  color?: 'primary' | 'white'
  size?: number
}

const PaperLoader = ({ className, color = 'primary', size = 36 }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.progressWrapper]: true,
      [classes.primary]: color === 'primary',
      [classes.white]: color === 'white',
    },
    className
  )

  return (
    <div className={rootClassName}>
      <CircularProgress color="inherit" size={size} />
    </div>
  )
}

export default PaperLoader
