import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceLandlineIntSummary/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceLandlineIntSummary/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceLandlineIntSummary/FETCH_SUCCESS'

export const fetchInvoiceLandlineIntSummaryFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceLandlineIntSummaryBegin = ({ invoiceId }) => {
  return {
    type: FETCH_BEGIN,
    invoiceId,
  }
}

export const fetchInvoiceLandlineIntSummarySuccess = ({ invoiceId, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    invoiceId,
  }
}

export const fetchInvoiceLandlineIntSummary = ({ customerId, invoiceId }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceLandlineIntSummaryBegin({ invoiceId }))
    const type = 'fixed'
    try {
      const response = await getInvoice.internationalSummary({ customerId, invoiceId, type })
      dispatch(fetchInvoiceLandlineIntSummarySuccess({ invoiceId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceLandlineIntSummaryFailure(err.response))
    }
  }
}
