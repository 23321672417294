import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'showNumberSkyapp/FETCH_FAILURE'
export const FETCH_BEGIN = 'showNumberSkyapp/FETCH_BEGIN'
export const FETCH_SUCCESS = 'showNumberSkyapp/FETCH_SUCCESS'

export const fetchShowNumberSkyappFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchShowNumberSkyappBegin = ({ mobileNumber }) => {
  return {
    type: FETCH_BEGIN,
    mobileNumber,
  }
}

export const fetchShowNumberSkyappSuccess = ({ mobileNumber, data }) => {
  return {
    type: FETCH_SUCCESS,
    mobileNumber,
    data,
  }
}

export const fetchShowNumberSkyapp = ({ customerId, mobileNumber }) => {
  return async (dispatch) => {
    dispatch(fetchShowNumberSkyappBegin({ mobileNumber }))
    try {
      const response = await getSubscription.showNumberInSkyapp({
        customerId,
        mobileNumber,
      })
      dispatch(fetchShowNumberSkyappSuccess({ mobileNumber, data: response.data }))
    } catch (err) {
      dispatch(fetchShowNumberSkyappFailure(err.response))
    }
  }
}
