import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Typography, Grid, Button, Collapse, Tooltip, Badge } from '@material-ui/core'
import {
  ExpandLessRounded as ExpandLessIcon,
  ExpandMoreRounded as ExpandMoreIcon,
  CheckCircleRounded as CheckIcon,
  ErrorRounded as ErrorIcon,
} from '@material-ui/icons'
import { SkyDatePicker } from 'components'
import { SkySelect, Alert } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'
import NumberListItem from '../components/NumberListItem'
import NumberForm from '../components/NumberForm'

const useStyles = makeStyles((theme: Theme) => ({
  dot: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  globalCheckbox: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  success: {
    color: theme.palette.success.main,
  },
}))

const FormStep = ({
  countryList,
  capacityGroups,
  azureDomains,
  selectedNumbers,
  toggleSelectNumber,
  abbType: globalAbbType,
  billingStartDate: globalBillingStartDate,
  azureDomain: globalAzureDomain,
  capacityGroup: globalCapacityGroup,
  subscriptionProducts,
  setValue,
  loading,
  minDate,
  errors,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const updateSelectedNumber = (property, value, index) => {
    const newNumbers = [...selectedNumbers]
    newNumbers[index] = {
      ...newNumbers[index],
      [property]: value,
    }
    setValue('selectedNumbers', newNumbers)
  }

  const handleToggleGlobalForm = (index) => {
    const newNumbers = [...selectedNumbers]
    // insert default values on toggle time
    newNumbers[index] = newNumbers[index].useGlobalForm
      ? {
          ...newNumbers[index],
          useGlobalForm: false,
          abbType: newNumbers[index].abbType || globalAbbType || '',
          billingStartDate: newNumbers[index].billingStartDate || globalBillingStartDate || '',
          azureDomain: newNumbers[index].azureDomain || globalAzureDomain || '',
          capacityGroup: newNumbers[index].capacityGroup || globalCapacityGroup || '',
        }
      : {
          ...newNumbers[index],
          useGlobalForm: true,
        }
    setValue('selectedNumbers', newNumbers)
  }

  const globalFormDisabled = !selectedNumbers.find((x) => x.useGlobalForm)

  const availableSubscriptionTypes = subscriptionProducts.map((product) => {
    return {
      label: product.name,
      price: product.price,
      value: product.productTypeId,
    }
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ marginTop: 24 }} gutterBottom>
          <strong>{t('SharedForm')}</strong>
        </Typography>
        <Typography color="textSecondary">{t('SharedFormDescription')}</Typography>
      </Grid>
      {globalFormDisabled ? (
        <Grid item xs={12}>
          <Alert message={t('SharedFormDisabled')} />
        </Grid>
      ) : (
        <>
          <Grid item xs={6}>
            <SkySelect
              label={t('Drawer_price_sub')}
              required
              name="abbType"
              disableClearable
              value={availableSubscriptionTypes.find((x) => x.value === globalAbbType) || null}
              options={availableSubscriptionTypes}
              withMargin={false}
              disabled={loading}
              onChange={(name, abbType) => setValue(name, abbType.value)}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) => option.value === value.value}
              renderOption={(option) => (
                <>
                  <Typography>{option.label}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    DKK {option.price}{' '}
                    <span style={{ marginLeft: '1.5ch' }}>ID {option.value}</span>
                  </Typography>
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <SkyDatePicker
              label={t('BillingStartDate')}
              value={globalBillingStartDate || undefined}
              disablePast
              onChange={(date) => setValue('billingStartDate', date)}
              withMargin={false}
              disabled={loading}
              minDate={minDate}
            />
          </Grid>
          <Grid item xs={6}>
            <SkySelect
              label={t('Domain')}
              required
              disableClearable
              name="azureDomain"
              value={azureDomains.data?.find((x) => x.primarySBC === globalAzureDomain) || null}
              options={azureDomains.data?.filter((x) => x.canBeProvisioned && !x.errors) || []}
              withMargin={false}
              disabled={loading}
              loading={azureDomains.loading}
              onChange={(name, domain) => setValue(name, domain.primarySBC)}
              getOptionLabel={(option) => option.primarySBC}
              getOptionSelected={(option, value) => option.primarySBC === value.primarySBC}
              renderOption={(option) => (
                <>
                  <Typography style={{ marginBottom: '0.3rem' }}>{option.primarySBC}</Typography>
                  <div style={{ marginBottom: '0.4rem' }}>
                    <Typography variant="caption" style={{ display: 'block', fontWeight: 'bold' }}>
                      Backup SBC
                    </Typography>
                    <Typography variant="caption">
                      {option.backupSBC ? option.backupSBC : 'Ikke angivet'}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="caption" style={{ display: 'block', fontWeight: 'bold' }}>
                      Online Voice Route ({option.type})
                    </Typography>
                    <Typography variant="caption">
                      {option.onlineVoiceRouteDescription
                        ? option.onlineVoiceRouteDescription
                        : 'Ikke angivet'}
                    </Typography>
                  </div>
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <SkySelect
              label={t('CapacityGroup')}
              required
              name="capacityGroup"
              disableClearable
              value={capacityGroups.find((x) => x.id === globalCapacityGroup) || null}
              options={capacityGroups.filter((x) => x.validForThisCustomer)}
              withMargin={false}
              disabled={loading}
              loading={capacityGroups.length === 0}
              onChange={(name, capacityGroup) => setValue(name, capacityGroup.id)}
              getOptionLabel={(option) => option.description}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => (
                <>
                  <Typography>{option.description}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {option.amountOfDidsMapped}/{option.maximumCapacity}
                  </Typography>
                </>
              )}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Typography variant="h4" style={{ marginTop: 24 }} gutterBottom>
          <strong>{t('SelectedNumbers')}</strong>
        </Typography>
        <Typography color="textSecondary">{t('SelectedNumbersDescription')}</Typography>
      </Grid>

      <Grid item xs={12}>
        {selectedNumbers &&
          selectedNumbers.map((number, index) => {
            const country = countryList.find((x) => x.countryCodeA3 === number.countryCode)
            const hasError = errors?.selectedNumbers?.find((x) => x.number === number.e164)

            return (
              <div key={number.didId}>
                <NumberListItem
                  key={number.didId}
                  selected={!number.useGlobalForm}
                  disabled={loading || number.hasBeenProvisioned}
                  primary={number.formattedNumber}
                  loading={loading}
                  withCheckbox={false}
                  startIcon={
                    number.hasBeenProvisioned ? (
                      <CheckIcon className={classes.success} />
                    ) : (
                      (Boolean(hasError) && (
                        <Tooltip title={hasError.error}>
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )) ||
                      undefined
                    )
                  }
                  endIcon={
                    number.useGlobalForm ? (
                      !number.hasBeenProvisioned && <ExpandMoreIcon />
                    ) : (
                      <ExpandLessIcon />
                    )
                  }
                  action={
                    !number.hasBeenProvisioned && (
                      <Button
                        color="primary"
                        size="small"
                        onClick={(e) => toggleSelectNumber(e, number)}
                      >
                        {t('Remove')}
                      </Button>
                    )
                  }
                  secondary={
                    <>
                      {country?.name || 'No country'}
                      <span className={classes.dot}>•</span>
                      {number.cityName || 'No city'}
                      <span className={classes.dot}>•</span>Area code {number.areaCode}
                    </>
                  }
                  onClick={() => handleToggleGlobalForm(index)}
                />
                <Collapse in={!number.useGlobalForm} mountOnEnter unmountOnExit>
                  <NumberForm
                    {...number}
                    updateNumber={updateSelectedNumber}
                    capacityGroups={capacityGroups}
                    azureDomains={azureDomains?.data || []}
                    availableSubscriptionTypes={availableSubscriptionTypes}
                    loading={loading}
                    index={index}
                    minDate={minDate}
                    error={hasError}
                  />
                </Collapse>
              </div>
            )
          })}
      </Grid>
    </Grid>
  )
}

export default FormStep
