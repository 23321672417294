import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IMG_FLAG_GB, IMG_FLAG_DK } from 'constants/cdn'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  langButton: {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'inherit',
    display: 'flex',
    marginRight: 8,
  },
  langImage: {
    borderRadius: '50%',
  },
}))

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  const classes = useStyles()
  const isDanish = i18n.language === 'da'
  const handleChangeLanguage = () => {
    const useLang = isDanish ? 'en' : 'da'
    i18n.changeLanguage(useLang)
  }

  return (
    <>
      <button
        id="btn-change-language" // test suite uses this id to select and click
        type="button"
        onClick={handleChangeLanguage}
        className={classes.langButton}
      >
        <img
          width="16px"
          height="16px"
          src={isDanish ? IMG_FLAG_GB : IMG_FLAG_DK}
          alt={isDanish ? 'Change to English language' : 'Skift til dansk'}
          className={classes.langImage}
        />
      </button>
    </>
  )
}

export default memo(LanguageSwitcher)
