import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Link } from '@material-ui/core'
import { Paper } from 'components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  intro: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  address: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  addressContainer: {
    marginBottom: theme.spacing(5),
  },
  link: {
    textDecoration: 'none',
  },
}))

const DeliveryAddress = () => {
  const classes = useStyles()
  const customer = useSelector((state) => state.customer)
  const { address } = customer.data
  const { t } = useTranslation()

  return (
    <Paper>
      <Typography variant="body1" className={classes.intro}>
        {t('Organization_address_description')}
      </Typography>
      <div className={classes.addressContainer}>
        <Typography variant="body1" className={classes.address} gutterBottom>
          <strong>{t('Organization_address_your_address')}</strong>
        </Typography>
        {address.recipient && (
          <Typography variant="body1" gutterBottom className={classes.address}>
            {address.recipient}
          </Typography>
        )}
        {address.streetAddress && (
          <Typography variant="body1" gutterBottom className={classes.address}>
            {address.streetAddress}
          </Typography>
        )}
        {address.city && address.zip && (
          <Typography variant="body1" gutterBottom className={classes.address}>
            {address.zip} {address.city}
          </Typography>
        )}
        {address.att && (
          <Typography variant="body1" gutterBottom className={classes.address}>
            Att. {address.att}
          </Typography>
        )}
        <Typography variant="body2" color="textSecondary" gutterBottom className={classes.intro}>
          {t('Organization_address_change')}{' '}
          <Link href="https://support.sky.tdc.dk/hc/da/requests/new" target="_blank">
            {t('Organization_address_change_anchor')}
          </Link>
        </Typography>
      </div>
    </Paper>
  )
}

export default DeliveryAddress
