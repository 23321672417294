export const IMG_TDC_LOGO_PLAIN = 'https://cdn.cirque-udv.dk/assets/img/tdc_logo.svg'
export const IMG_TDC_LOGO_BUSSINESS_WHITE =
  'https://cdn.cirque-udv.dk/assets/img/tdc_logo_white.svg.svg'
export const IMG_TDC_LOGO_BUSSINESS = 'https://cdn.cirque-udv.dk/tdc/v1/img/tdc-erhverv-logo.svg'
export const IMG_MICROSOFT_LOGO = 'https://cdn.cirque-udv.dk/assets/img/microsoft_logo.svg'
export const IMG_BG_ICON_ILLUSTRATION =
  'https://cdn.cirque-udv.dk/tdc/v1/img/tdc-wallpaper-icon-illustration-bg.svg'
export const IMG_FLAG_GB = 'https://cdn.cirque-udv.dk/assets/img/flag-gb.png'
export const IMG_FLAG_DK = 'https://cdn.cirque-udv.dk/assets/img/flag-dk.png'
export const IMG_LANGUAGE_DA = 'https://cdn.cirque-udv.dk/assets/img/da.gif'
export const IMG_FIREWORKS = 'https://cdn.cirque-udv.dk/assets/img/fireworks.gif'
export const IMG_HARDWARE_BUSYLIGHT_ALPHA =
  'https://cdn.cirque-udv.dk/assets/img/busylight_alpha.jpg'
export const IMG_HARDWARE_BUSYLIGHT_OMEGA =
  'https://cdn.cirque-udv.dk/assets/img/busylight_omega.jpg'
export const IMG_HARDWARE_COMPETELLA_KEYBOARD =
  'https://cdn.cirque-udv.dk/assets/img/competella_keyboard.jpg'
export const IMG_HARDWARE_JABRA_ENGAGE_50 = 'https://cdn.cirque-udv.dk/assets/img/engage_50.jpg'
export const IMG_HARDWARE_JABRA_ENGAGE_65 = 'https://cdn.cirque-udv.dk/assets/img/engage_65.jpg'
export const IMG_HARDWARE_JABRA_ENGAGE_75 = 'https://cdn.cirque-udv.dk/assets/img/engage_75.jpg'
export const IMG_HARDWARE_JABRA_EVOLVE_65_UC =
  'https://cdn.cirque-udv.dk/assets/img/jabra-evolve-65t-uc.jpg'
export const IMG_HARDWARE_JABRA_EVOLVE_65_DUO =
  'https://cdn.cirque-udv.dk/assets/img/jabra_evolve_65_duo.jpg'
export const IMG_HARDWARE_JABRA_EVOLVE_65_MONO =
  'https://cdn.cirque-udv.dk/assets/img/jabra_evolve_65_mono.jpg'
export const IMG_HARDWARE_JABRA_EVOLVE_75 =
  'https://cdn.cirque-udv.dk/assets/img/jabra_evolve_75.jpg'
export const IMG_HARDWARE_JABRA_SPEAK_510 =
  'https://cdn.cirque-udv.dk/assets/img/jabra_speak_510.jpg'
export const IMG_HARDWARE_JABRA_SPEAK_710 =
  'https://cdn.cirque-udv.dk/assets/img/jabra_speak_710.jpg'
export const IMG_HARDWARE_JABRA_SPEAK_810 =
  'https://cdn.cirque-udv.dk/assets/img/jabra_speak_810.jpg'
export const IMG_HARDWARE_POLYCOM_EAGLE_EYE =
  'https://cdn.cirque-udv.dk/assets/img/polycom_eagleeye.jpg'
export const IMG_HARDWARE_POLYCOM_REALPRESENCE =
  'https://cdn.cirque-udv.dk/assets/img/polycom_realpresence.jpg'
export const IMG_HARDWARE_POLYCOM_VVX_401 =
  'https://cdn.cirque-udv.dk/assets/img/polycom_vvx401.jpg'
export const IMG_HARDWARE_POLYCOM_VVX_501 =
  'https://cdn.cirque-udv.dk/assets/img/polycom_vvx501.jpg'
export const IMG_HARDWARE_POLYCOM_VVX_601 =
  'https://cdn.cirque-udv.dk/assets/img/polycom_vvx601.jpg'
export const IMG_HARDWARE_POLYCOM_PSU = 'https://cdn.cirque-udv.dk/assets/img/psu_polycom.jpg'
export const IMG_HARDWARE_PLANTRONICS_SAVI_W420M =
  'https://cdn.cirque-udv.dk/assets/img/savi_w420m.jpg'
export const IMG_HARDWARE_PLANTRONICS_VOYAGER_FOCUS =
  'https://cdn.cirque-udv.dk/assets/img/voyagerfocusuc.jpg'
export const IMG_HARDWARE_PLANTRONICS_VOYAGER_LEGEND =
  'https://cdn.cirque-udv.dk/assets/img/voyagerlegenduc.jpg'
export const IMG_404 = 'https://cdn.cirque-udv.dk/assets/img/404-min.png'

export const IMG_HARDWARE_JABRA_EVOLVE_2_STEREO =
  'https://cdn.cirque-udv.dk/assets/img/jabra-evolve-2-stereo-min.png'
export const IMG_HARDWARE_LENOVO_THINKSMARTVIEW =
  'https://cdn.cirque-udv.dk/assets/img/lenovothinksmartview-min.png'
export const IMG_HARDWARE_POLY_CCX_600 =
  'https://cdn.cirque-udv.dk/assets/img/poly_ccx_600_business_media_phone-min.jpg'
export const IMG_HARDWARE_POLY_CCX_500 = 'https://cdn.cirque-udv.dk/assets/img/poly_ccx-500-min.jpg'
export const IMG_HARDWARE_POLY_CCX_400 =
  'https://cdn.cirque-udv.dk/assets/img/poly-ccx-400-business-media-phone_1-min.jpg'
export const IMG_HARDWARE_JABRA_EVOLVE_2_MONO =
  'https://cdn.cirque-udv.dk/assets/img/jabra-evolve-2-mono-min.jpg'
export const IMG_HARDWARE_JABRA_PANACAST_MS =
  'https://cdn.cirque-udv.dk/assets/img/jabra-panacast-ms-min.jpg'
export const IMG_HARDWARE_LENOVO_THINKSMART_HUB_500 =
  'https://cdn.cirque-udv.dk/assets/img/lenovothinksmarthub500-min.jpg'
export const IMG_SOMMER_NISSE = 'https://cdn.cirque-udv.dk/assets/img/sommernisse.jpg'
export const IMG_PARTY_LIGHT_BULBS = 'https://cdn.cirque-udv.dk/assets/img/partylightbulbs.jpg'
export const IMG_PARTY_PEOPLE = 'https://cdn.cirque-udv.dk/assets/img/part-people.jpg'
