import React, { useState } from 'react'
import { GradientButton } from 'components'
import { useTranslation, Trans } from 'react-i18next'
import StepContainer from '../StepContainer'
import TextBlock from '../TextBlock'
import SecretPlaceholder from '../SecretPlaceholder'
import AdminUserModal from '../AdminUserModal'
import SecretsContainer from '../SecretsContainer'

const AdminUserLocked = ({ currentStep, setCurrentStep, loading, secret }) => {
  const { t } = useTranslation()

  const handleSetCurrentStep = () => {
    setCurrentStep(currentStep + 1)
  }

  return (
    <>
      <StepContainer step={currentStep} requiredStep={1} stepLabel={t('Wizard_00shtrs')}>
        <TextBlock>{t('Wizard35445321_')}</TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Wizard_gesr9')}</li>
            <li>{t('Wizard_serg00')}</li>
          </ul>
        </TextBlock>
        <SecretsContainer
          loading={loading}
          secret={secret}
          deleteSecret={false}
          type="admin"
          disabled
        />
        <GradientButton disabled={currentStep > 1 || !secret} onClick={handleSetCurrentStep}>
          {t('Hardware_next')}
        </GradientButton>
      </StepContainer>
    </>
  )
}

export default AdminUserLocked
