import * as microsoftTeams from '@microsoft/teams-js'

microsoftTeams.initialize()

export const getDefaultTeamsProps = () => {
  return { isInTeams: false, context: {} }
}
type TeamsProps = ReturnType<typeof getDefaultTeamsProps>

const isFramedByOtherSource = () => {
  // in situations calling window.parent.location can lead to
  // "DOMException: Blocked a frame with origin "x" from accessing a cross-origin frame. so we wrap it in try catch

  try {
    return (
      window.navigator.userAgent.includes('Teams') ||
      window.name === 'embedded-page-container' ||
      window.name === 'extension-tab-frame'
    )
  } catch {
    return true
  }
}

const contextResolver = (teamsProps: TeamsProps) =>
  new Promise((resolve: (value?: unknown) => void) => {
    const timeToResolve = 10000
    // unless used inside teams, the method "getContext" from "microsoftTeams" never fires
    // if the getContext doesnt fire after given timeToResolve. then resolve promise (no change to teamsProps)

    if (isFramedByOtherSource() === false) {
      // if note iframed conclude not being in teams
      return resolve()
    }

    const resolveTimeoutFn = setTimeout(() => {
      /* eslint-disable-next-line no-console */
      console.log(
        `self service detected to be in frame, however microsoftTeams couldn't get context after ${timeToResolve}ms. assuming not in teams`
      )
      resolve()
    }, timeToResolve)

    microsoftTeams.getContext((context) => {
      /* eslint-disable-next-line no-console */
      console.log('microsoftTeams succesfully "getContext". self service is in teams')
      clearTimeout(resolveTimeoutFn)
      Object.assign(teamsProps, { isInTeams: true, context })
      resolve()
    })
  })

export default (teamsProps: TeamsProps) => {
  return [
    contextResolver(teamsProps),
    // add more if you like...
  ]
}
