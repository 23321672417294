import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ListItemText, List, ListItem, ListItemIcon } from '@material-ui/core'
import DonutChart from '../DonutChart'

const useStyles = makeStyles((theme) => ({
  list: {
    paddingBottom: 32,
  },
  invoiceNumber: {
    top: 'calc(50% + 3px)',
    left: '50%',
    transform: ' translate(-50%, -50%)',
    position: 'absolute',
    fontFamily: '"TDC", sans-serif',
    fontSize: 32,
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  chartText: {
    marginRight: 16,
    position: 'relative',
    height: (props) => props.size,
  },
  title: {
    fontSize: 21,
    fontWeight: 'bold',
    lineHeight: '2rem',
  },
}))

interface DonutChartWithTextProps {
  chartData: number[]
  backgroundColor: string[]
  textInsideDonut?: string
  title?: string | number
  subTitle?: string
  size?: number
}

const DonutChartWithText = ({
  chartData,
  backgroundColor,
  textInsideDonut,
  title,
  subTitle,
  size = 150,
}: DonutChartWithTextProps) => {
  const classes = useStyles({ size })
  const formattedTitle = typeof title === 'number' ? title.toLocaleString('da-DK') : title

  return (
    <List disablePadding className={classes.list}>
      <ListItem>
        <ListItemIcon className={classes.chartText}>
          {textInsideDonut && <div className={classes.invoiceNumber}>{textInsideDonut}</div>}
          <DonutChart data={chartData} size={size} backgroundColor={backgroundColor} />
        </ListItemIcon>
        {formattedTitle && (
          <ListItemText
            primary={<div className={classes.title}>{formattedTitle}</div>}
            secondary={subTitle}
          />
        )}
      </ListItem>
    </List>
  )
}

export default DonutChartWithText
