import React, { useEffect, useCallback } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useDispatch, batch, useSelector } from 'react-redux'
import { fetchAddons } from 'state/addons/actions'
import { fetchDataUsages } from 'state/dataUsages/actions'
import { fetchMobileDisplayNumber } from 'state/mobileDisplayNumber/actions'
import { fetchLandlineDisplayNumber } from 'state/landlineDisplayNumber/actions'
import { fetchLandlinePrivateNumber } from 'state/landlinePrivateNumber/actions'
import { fetchInvoiceReferences } from 'state/invoiceReferences/actions'
import { fetchInvoiceSubscriptions } from 'state/invoiceSubscriptions/actions'
import { fetchSimcards } from 'state/simcards/actions'
import { fetchMobileCallForwarding } from 'state/mobileCallForwarding/actions'
import { fetchShowNumberSkyapp } from 'state/showNumberSkyapp/actions'
import { fetchLicenseDetails } from 'state/licenseDetails/actions'
import { Slide } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DrawerSection } from 'components'
import CallToActionListItem from 'components/CallToActionListItem'
import { useTranslation } from 'react-i18next'
import BaseInformation from './components/BaseInformation'
import Mobile from './components/Mobile'
import Landline from './components/Landline'
import AddonsAndFeatures from './components/AddonsAndFeatures'
import StaticMobileAddons from './components/AddonsAndFeatures/StaticMobileAddons'
import PricePerMonth from './components/PricePerMonth'
import SubscriptionMoreMenu from '../components/SubscriptionMoreMenu'

const User = ({ user, subscription, customerId, currentUrl, changeRoute }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const broadworks = useSelector((state) => state.customer?.data?.customer_properties?.broadworks)
  const dataUsages = useSelector((state) => state.dataUsages)
  const titleName = (user.name.length > 2 && user.name) || 'Bruger'
  const hasEndDate = Boolean(subscription && subscription.end_date)
  const hasMobilePackage = Boolean(
    subscription && subscription.mobile_package && subscription.mobile_package.current
  )
  const includesMusic = subscription?.mobile_package?.current?.includes_music
  const hasIntNumberVersionTwo = Boolean(
    subscription &&
      subscription.international_number_product_version &&
      subscription.international_number_product_version === 2
  )

  useEffect(() => {
    changeDocumentTitle(titleName, ' - ', ':customerName', ' - ', ':applicationName')
    if (subscription) {
      batch(() => {
        dispatch(fetchDataUsages({ customerId, abbId: subscription.abb_id }))
        dispatch(fetchAddons({ customerId, abbId: subscription.abb_id }))
        dispatch(fetchInvoiceReferences({ customerId }))
        dispatch(fetchInvoiceSubscriptions({ customerId }))
      })
    }
    if (subscription && subscription.mobile_number) {
      batch(() => {
        dispatch(fetchSimcards({ customerId, mobileNumber: subscription.mobile_number }))
        dispatch(
          fetchMobileCallForwarding({
            customerId,
            mobileNumber: subscription.mobile_number,
          })
        )
        dispatch(
          fetchMobileDisplayNumber({
            customerId,
            mobileNumber: subscription.mobile_number,
          })
        )

        dispatch(
          fetchShowNumberSkyapp({
            customerId,
            mobileNumber: subscription.mobile_number,
          })
        )
      })
    }
    if (subscription && subscription.landline_number) {
      batch(() => {
        dispatch(
          fetchLandlineDisplayNumber({
            customerId,
            landlineNumber: subscription.landline_number,
          })
        )
        dispatch(
          fetchLandlinePrivateNumber({
            customerId,
            landlineNumber: subscription.landline_number,
          })
        )
      })
    }
    if (user.azureId) {
      dispatch(fetchLicenseDetails({ customerId, azureId: user.azureId }))
    }
  }, [])

  const refetchAddons = useCallback(
    () => dispatch(fetchAddons({ customerId, abbId: subscription.abb_id })),
    [dispatch]
  )

  const refetchShowNumberSkyapp = useCallback(
    () =>
      dispatch(
        fetchShowNumberSkyapp({
          customerId,
          mobileNumber: subscription.mobile_number,
        })
      ),
    [dispatch]
  )

  const showLandlineNumber = Boolean(
    (subscription && subscription.landline_number) ||
      (subscription && subscription.international_number) ||
      (subscription && subscription.mobile_onenumber_shadownumber)
  )

  return (
    <Slide direction="left" in>
      <div>
        {hasEndDate && (
          <Alert severity="warning">
            {t('Drawer_user_in_change', {
              name: (user.name.length > 1 && user.name) || t('Drawer_user_the_subscription'),
              date: subscription.end_date,
            })}
          </Alert>
        )}
        <DrawerSection title={t('Drawer_user_title')} />
        <BaseInformation
          jobTitle={user.jobTitle}
          department={user.department}
          azureId={user.azureId}
          currentUrl={currentUrl}
          disabled={hasEndDate}
        />
        {!broadworks && (
          <>
            <DrawerSection
              title={t('Drawer_user_titlemobile')}
              toolbar={
                subscription &&
                subscription.mobile_number &&
                !hasEndDate && (
                  <SubscriptionMoreMenu
                    anchorText={t('Drawer_cancel_label')}
                    changeRoute={changeRoute}
                  />
                )
              }
            />
            {subscription && subscription.mobile_number ? (
              <Mobile
                mobileNumber={subscription.mobile_number}
                mobilePackage={subscription.mobile_package}
                dataUsages={dataUsages}
                allowsDisplayNumber={
                  subscription.allows_display_number && !subscription.mobile_onenumber_shadownumber
                }
                allowsForwarding={!subscription.mobile_onenumber_shadownumber}
                currentUrl={currentUrl}
                disabled={hasEndDate}
              />
            ) : (
              <CallToActionListItem
                description={t('Drawer_user_mobile_desc', { name: user.name })}
                ctaText={user.azureId ? t('Drawer_user_create_mobile') : t('Drawer_user_azure_err')}
                link={user.azureId ? `${currentUrl}/opret-abonnement` : ''}
                isDisabled={!user.azureId || hasEndDate || (subscription && !subscription.editable)}
              />
            )}
          </>
        )}
        <DrawerSection
          title={t('Drawer_cancel_teams_title')}
          toolbar={
            subscription &&
            subscription.landline_number &&
            !hasEndDate && (
              <SubscriptionMoreMenu
                anchorText={t('Drawer_cancel_label')}
                changeRoute={changeRoute}
              />
            )
          }
        />
        {showLandlineNumber ? (
          <Landline
            landlineNumber={
              subscription.international_number && subscription.international_number_prefix
                ? `+${subscription.international_number_prefix} ${subscription.international_number}`
                : subscription.landline_number ||
                  `${subscription.mobile_onenumber_shadownumber} (skyggenummer)`
            }
            extension={
              subscription.extension || subscription.mobile_onenumber_shadownumber_extension
            }
            currentUrl={currentUrl}
            disabled={hasEndDate}
            disableDisplayNumber={Boolean(
              hasIntNumberVersionTwo || subscription.mobile_onenumber_shadownumber
            )}
            broadworks={broadworks}
          />
        ) : (
          <CallToActionListItem
            headline={t('Drawer_cancel_teams_title')}
            description={t('Drawer_user_teams_desc')}
            ctaText={user.azureId ? t('Drawer_create_sub_teams_title') : t('Drawer_user_teams_err')}
            link={user.azureId ? `${currentUrl}/opret-abonnement` : ''}
            isDisabled={!user.azureId || hasEndDate || (subscription && !subscription.editable)}
          />
        )}
        {(subscription || (!subscription && !broadworks)) && (
          <DrawerSection title={t('Drawer_user_addons_title')} />
        )}
        {subscription ? (
          <AddonsAndFeatures
            refetchAddons={refetchAddons}
            refetchShowNumberSkyapp={refetchShowNumberSkyapp}
            allowsFSecure={subscription.allows_fsecure_safe}
            mobilePackageIsWorks={hasMobilePackage && subscription.mobile_package.current.is_works}
            hasMobileOneNumber={Boolean(
              subscription.mobile_number && subscription.mobile_onenumber
            )}
            customerId={customerId}
            abbId={subscription.abb_id}
            mobileNumber={subscription.mobile_number}
            disabled={hasEndDate}
            includesMusic={includesMusic}
          />
        ) : (
          !broadworks && <StaticMobileAddons isActive={false} includesMusic={false} />
        )}
        {subscription && (
          <>
            <DrawerSection title={t('Drawer_create_sub_ppm')} />
            <PricePerMonth
              invoice={subscription && subscription.invoice}
              abbId={subscription && subscription.abb_id}
              customerId={customerId}
              mobilePackageName={hasMobilePackage && subscription.mobile_package.current.abb_name}
              disabled={hasEndDate}
            />
          </>
        )}
      </div>
    </Slide>
  )
}

export default User
