import React from 'react'

const InnerContent = () => {
  return (
    <iframe
      title="Driftstatus"
      src="https://status.sky.tdc.dk/"
      width="100%"
      height="750"
      frameBorder="0"
    />
  )
}

export default InnerContent
