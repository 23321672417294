import { isItObject } from './helpers'

export const checkHeaders = (headers = {}) => {
  if (isItObject(headers) === false) {
    throw new TypeError('"headers" must be object')
  }
}

export const checkConnection = (connection = {}) => {
  if (isItObject(connection) === false) {
    throw new TypeError('"connect" must be object')
  }
}

const allowedStorageArr = ['localStorage', 'sessionStorage']
export const checkStorage = (storage) => {
  if (typeof storage !== 'string') {
    throw new TypeError('"storage" must be (string)')
  }
  if (allowedStorageArr.includes(storage) === false) {
    throw new TypeError(`"storage" must be either "localStorage" or "sessionStorage"`)
  }
}

export const checkName = (name) => {
  const regx = /^[a-zA-Z0-9]*$/
  if (typeof name !== 'string') {
    throw new TypeError('"name" must be (string)')
  }
  if (name.length > 0 === false) {
    throw new TypeError('"name" must have atleast "1" character')
  }
  if (regx.test(name) === false) {
    throw new TypeError(`"name" failed to match "${regx}"`)
  }
}
