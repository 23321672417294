import React, { useState, useEffect } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { SkyDatePicker } from 'components'
import useDebounce from 'utils/hooks/useDebounce'
import { SkySelect, SkyTextField, Alert } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'

const NumberForm = ({
  capacityGroups,
  azureDomains,
  abbType,
  billingStartDate,
  azureDomain,
  capacityGroup,
  availableSubscriptionTypes,
  comment,
  updateNumber,
  loading,
  index,
  minDate,
  error,
}) => {
  const { t } = useTranslation()
  const [internalComment, setInternalComment] = useState(comment)
  const [debouncedComment] = useDebounce(internalComment, 250)

  useEffect(() => {
    updateNumber('comment', internalComment, index)
  }, [debouncedComment])

  return (
    <div style={{ margin: 16 }}>
      <Grid container spacing={2}>
        {error && (
          <Grid item xs={12}>
            <Alert type="error" message={error.error} />
          </Grid>
        )}
        <Grid item xs={6}>
          <SkySelect
            label={t('Drawer_price_sub')}
            required
            name="abbType"
            disableClearable
            value={availableSubscriptionTypes.find((x) => x.value === abbType) || null}
            options={availableSubscriptionTypes}
            withMargin={false}
            disabled={loading}
            onChange={(name, abbType) => updateNumber(name, abbType.value, index)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.value === value.value}
            renderOption={(option) => (
              <>
                <Typography>{option.label}</Typography>
                <Typography variant="body2" color="textSecondary">
                  DKK {option.price} <span style={{ marginLeft: '1.5ch' }}>ID {option.value}</span>
                </Typography>
              </>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <SkyDatePicker
            label={t('BillingStartDate')}
            value={billingStartDate || undefined}
            disablePast
            onChange={(date) => updateNumber('billingStartDate', date, index)}
            withMargin={false}
            disabled={loading}
            minDate={minDate}
          />
        </Grid>
        <Grid item xs={6}>
          <SkySelect
            label={t('Domain')}
            required
            disableClearable
            name="azureDomain"
            value={azureDomains.data?.find((x) => x.primarySBC === azureDomain) || null}
            options={azureDomains.data?.filter((x) => x.canBeProvisioned && !x.errors) || []}
            withMargin={false}
            disabled={loading}
            loading={azureDomains.loading}
            onChange={(name, domain) => updateNumber(name, domain.primarySBC, index)}
            getOptionLabel={(option) => option.primarySBC}
            getOptionSelected={(option, value) => option.primarySBC === value.primarySBC}
            renderOption={(option) => (
              <>
                <Typography>{option.primarySBC}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {option.backupSBC}
                </Typography>
              </>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <SkySelect
            label={t('CapacityGroup')}
            required
            name="capacityGroup"
            disableClearable
            value={capacityGroups.find((x) => x.id === capacityGroup) || null}
            options={capacityGroups.filter((x) => x.validForThisCustomer)}
            withMargin={false}
            disabled={loading}
            loading={capacityGroups.length === 0}
            onChange={(name, val) => updateNumber(name, val.id, index)}
            getOptionLabel={(option) => option.description}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(option) => (
              <>
                <Typography>{option.description}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {option.amountOfDidsMapped}/{option.maximumCapacity}
                </Typography>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <SkyTextField
            withMargin={false}
            disabled={loading}
            value={internalComment}
            label={t('Invoice_description')}
            onChange={(_name, val) => {
              if (typeof val === 'string' && val.length <= 20) {
                setInternalComment(val)
              }
            }}
            helperText={t('CommentDescription')}
            name="comment"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default NumberForm
