import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
  },
}))

const PaperToolbar = (props) => {
  const classes = useStyles()
  const { className, children, ...rest } = props

  const rootClassName = classNames(
    {
      [classes.root]: true,
    },
    className
  )

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}

export default PaperToolbar
