import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ListItem, ListItemText } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  primary: {
    fontWeight: 700,
    fontSize: theme.typography.body1.fontSize,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginTop: 1,
  },
  smallPrimary: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  setMaxHeight: {
    maxHeight: 39,
  },
  secondary: {
    paddingTop: 2,
    lineHeight: '19px',
  },
  gutters: {
    paddingLeft: 8,
  },
}))

interface TableCellListItemProps {
  primary?: string | React.ReactNode
  secondary?: string | React.ReactNode
  onClick?: () => void
  children?: React.ReactNode
  disableGutters?: boolean
  className?: string | string[]
  small?: boolean
}

const TableCellListItem = ({
  primary,
  secondary,
  onClick,
  children,
  disableGutters = false,
  className,
  small = false,
  ...rest
}: TableCellListItemProps) => {
  const classes = useStyles()

  const listItemProps = {
    classes: {
      primary: small ? classes.smallPrimary : classes.primary,
      secondary: classes.secondary,
    },
    primaryTypographyProps: {
      noWrap: true,
    },
    secondaryTypographyProps: {
      noWrap: true,
    },
  }

  const ListItemTextComponent = (
    <ListItemText
      className={classes.setMaxHeight}
      primary={primary}
      secondary={secondary}
      {...listItemProps}
    />
  )

  return onClick ? (
    <ListItem
      dense
      disableGutters={disableGutters}
      className={className}
      button
      onClick={onClick}
      classes={{ gutters: classes.gutters }}
      {...rest}
    >
      {children}
      {ListItemTextComponent}
    </ListItem>
  ) : (
    <ListItem
      dense
      disableGutters={disableGutters}
      className={className}
      classes={{ gutters: classes.gutters }}
      {...rest}
    >
      {children}
      {ListItemTextComponent}
    </ListItem>
  )
}

export default TableCellListItem
