import React from 'react'
import classNames from 'classnames'
import { Avatar, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    paddingTop: '0px !important',
    marginTop: -theme.spacing(2),
  },
  avatar: {
    width: 'auto',
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    height: '22px',
    marginRight: '6px',
    fontSize: '11px',
    backgroundColor: theme.palette.background.default,
    color: '#192228',
  },
  btnActive: {
    backgroundColor: 'rgba(0, 75, 146, 0.08)',
  },
}))

const SortingChips = ({ activeFilters, setActiveFilters, filteredUsers, setPage }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const broadworks = useSelector((state) => state.customer.data?.customer_properties?.broadworks)
  const handleSetActiveFilter = (key, value) => {
    const newFilters = { ...activeFilters }
    if ({}.hasOwnProperty.call(newFilters, key)) {
      delete newFilters[key]
    } else {
      newFilters[key] = value
    }
    setPage(0)
    setActiveFilters(newFilters)
  }

  const sortingChips = [
    {
      label: t('Users_filter1'),
      count: filteredUsers.filter((user) => !user.hasSubscription).length,
      filterKey: 'hasSubscription',
      filterValue: false,
    },
    {
      ...(!broadworks && {
        label: t('Users_filter2'),
        count: filteredUsers.filter((user) => !user.hasMobilePackage).length,
        filterKey: 'hasMobilePackage',
        filterValue: false,
      }),
    },
    {
      label: t('Users_filter3'),
      count: filteredUsers.filter((user) => !user.hasTeamsTelephony).length,
      filterKey: 'hasTeamsTelephony',
      filterValue: false,
    },
    {
      label: t('Users_filter4'),
      count: filteredUsers.filter((user) => user.inChange).length,
      filterKey: 'inChange',
      filterValue: true,
    },
    {
      label: t('Users_filter56'),
      count: filteredUsers.filter((user) => user.linkWarning).length,
      filterKey: 'linkWarning',
      filterValue: true,
    },
  ]

  return (
    <div className={classes.root}>
      {sortingChips.map((chip) => {
        if (chip.count > 0) {
          return (
            <Button
              key={chip.label}
              size="small"
              className={classNames({
                [classes.btnActive]: {}.hasOwnProperty.call(activeFilters, chip.filterKey),
              })}
              onClick={() => handleSetActiveFilter(chip.filterKey, chip.filterValue)}
              color="primary"
            >
              <Avatar className={classes.avatar}>{chip.count}</Avatar> {chip.label}
            </Button>
          )
        }
        return null
      })}
    </div>
  )
}

export default SortingChips
