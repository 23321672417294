/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CircularProgress,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import CallIcon from '@material-ui/icons/Call'
import GetAppIcon from '@material-ui/icons/GetApp'
import Group from '@material-ui/icons/Group'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import ScheduleIcon from '@material-ui/icons/Schedule'

import { fetchSkyAppContacts } from 'state/skyAppContacts/actions'
import { updateSubscription } from 'services/sweb/subscription'
import { useSelector, useDispatch } from 'react-redux'
import { MuiDataTable } from 'components'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { IMG_BG_ICON_ILLUSTRATION, IMG_TDC_LOGO_BUSSINESS_WHITE } from 'constants/cdn'
import UserSelect from 'views/Layout/components/UserSelect'

import Huntgroups from './Huntgroups'
import DisplayNumber from './DisplayNumber'

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundImage: `url(${IMG_BG_ICON_ILLUSTRATION})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.palette.primary.main,
    },
    '[role="presentation"]': {
      border: '1px solid red,',
    },
  },
  wrapper: {
    maxWidth: 1024,
    margin: '0 auto',
    height: '100%',
  },
  title: {
    margin: '2rem 0 3rem',
  },
  contactPhoneNumber: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
    '&>*': {
      display: 'table-cell',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '&>strong': {
      fontSize: '1rem',
    },
    '&:last-of-type': {
      marginTop: '0.5rem',
    },
  },
  topBar: {
    color: 'white',
    padding: '1.5rem 1rem 0',
  },
  container: {
    position: 'relative',
    height: '100%',
    background: 'white',
    paddingBottom: 60,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: -50,
      zIndex: -1,
      width: '100%',
      height: 50,
      borderRadius: '30px/50%',
      background: 'white',
      transform: 'translateY(25px)',
      boxShadow: '0 0px 30px 0 #000000',
    },

    '&>div:first-child': {
      margin: '0 auto',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
    },
  },
  downloadLink: {
    position: 'absolute',
    width: '50%',
    top: 58,
    right: 16,
    zIndex: 1,
    backgroundColor: 'white',
    '&:hover': {
      background: 'white',
    },
  },
  favorites: {
    position: 'absolute',
    top: 58,
    left: 16,
    zIndex: 1,
    backgroundColor: 'white',
  },
  bottomNavigation: {
    position: 'fixed',
    zIndex: 999999999999,
    bottom: 0,
    width: '100%',
    maxWidth: 1024,
    right: 0,
    borderTop: `2px solid ${theme.palette.primary.light}`,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  appBar: {
    background: theme.palette.primary.dark,
  },
  contactInfo: {
    marginBottom: '0.5rem',
  },
  langImg: {
    width: 18,
    height: 18,
  },
  starIcon: {
    color: theme.palette.primary.main,
  },
  favoriteSelected: {
    background: theme.palette.primary.main,
    color: 'white',

    '&:hover': {
      background: theme.palette.primary.main,
      color: 'white',
    },
  },
}))

const SkyApp = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const [value, setValue] = useState('contacts')
  const [downloadLink, setDownloadLink] = useState(null)
  const [favoriteContacts, setFavoriteContacts] = useState([])
  const [showFavorites, setShowFavorites] = useState(false)
  const [disableFavoriteButtons, setDisableFavoriteButtons] = useState([])

  const [allContacts, setAllContacts] = useState(new Map())

  const contacts = useSelector((s) => s.skyAppContacts)
  const me = useSelector((state) => state.me)
  const customer = useSelector((state) => state.customer)

  const contactsExist = contacts.data?.contacts?.length > 0

  const getAllContactsListDownloadLink = async () => {
    const contactsData = contacts?.data?.contacts || []

    let bodyText = ''

    // eslint-disable-next-line no-restricted-syntax
    for (const data of contactsData) {
      const { firstName, lastName } = data.name || {}
      const { countryCode = '', nationalNumber = '' } = data.phoneNumbers?.[0] || {}
      const mobileNumber = countryCode ? `+${countryCode} ${nationalNumber}` : nationalNumber

      bodyText += `\nBEGIN:VCARD\nVERSION:3.0\nFN;CHARSET=UTF-8:${firstName} ${lastName}\nN;CHARSET=UTF-8:${lastName};${firstName};;;\nEMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${data.email}\nTEL;TYPE=WORK,VOICE:${mobileNumber}\nREV:2022-12-07T07:50:49.410Z\nEND:VCARD`
    }

    const fileName = 'contacts.vcf'
    const file = new File([new Blob([bodyText])], fileName)
    const fileUrl = URL.createObjectURL(file)

    setDownloadLink({ fileUrl, fileName })
  }

  useEffect(() => {
    dispatch(fetchSkyAppContacts())
  }, [])

  useEffect(() => {
    const lastChosenTab = localStorage.getItem('skyapp-tab')
    if (lastChosenTab) {
      setValue(lastChosenTab)
    }
  }, [])

  useEffect(() => {
    if (value) {
      localStorage.setItem('skyapp-tab', value)
    }
  }, [value])

  useEffect(() => {
    if (!contacts.loading && contactsExist) {
      getAllContactsListDownloadLink()
      setAllContacts(
        new Map(
          contacts.data?.contacts.map((data) => [data.id, { ...data, favorite: data?.favorite }])
        )
      )
    }
  }, [contacts])

  useEffect(() => {
    if (allContacts.size > 0) {
      const findAllFavorites = [...allContacts.values()]?.filter(({ favorite }) => favorite)
      setFavoriteContacts(findAllFavorites)
    }
  }, [allContacts])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    setValue(newValue)
  }
  const getMobileNumber = (numbers) => {
    if (!numbers) return ''
    const mobileNumber = numbers.filter(({ phoneType }) => phoneType === 'MOBILE_PHONE')

    const { countryCode, nationalNumber } = mobileNumber[0] ||
      numbers[0] || { countryCode: '', nationalNumber: '' }
    return nationalNumber && `+${countryCode} ${nationalNumber}`
  }

  const handleFavorite = (favorite: boolean, id: string) => {
    setDisableFavoriteButtons((prev) => [...prev, id])
    updateSubscription[favorite ? 'contactUnMarkFavorite' : 'contactMarkFavorite']([id]).then(
      () => {
        setAllContacts(
          new Map(allContacts.set(id, { ...allContacts.get(id), favorite: !favorite }))
        )
        setDisableFavoriteButtons((prev) => {
          const removeId = prev.filter((favoriteId) => favoriteId !== id)
          return removeId
        })
      }
    )
  }

  const columns = [
    {
      name: 'favorite',
      label: t('Skyapp_column_favorite'),
      align: 'left',
      width: 100,
      options: {
        customBodyRender: (favorite, { rowData }) => {
          const id = rowData[3]
          const disable = disableFavoriteButtons.includes(id)
          return (
            <IconButton disabled={disable} onClick={() => handleFavorite(favorite, id)}>
              {disable ? (
                <CircularProgress size={20} />
              ) : favorite ? (
                <StarIcon className={classes.starIcon} />
              ) : (
                <StarBorderIcon />
              )}
            </IconButton>
          )
        },
        filter: false,
      },
    },
    {
      name: 'name',
      label: t('Skyapp_column_name'),
      align: 'left',
      options: {
        customBodyRender: (name, { rowData }) => {
          return (
            <>
              <Box className={classes.contactPhoneNumber}>
                <span className={classes.contactInfo}>{name}</span>
              </Box>
              <Box className={classes.contactPhoneNumber}>
                <strong>{getMobileNumber(rowData[2])}</strong>
              </Box>
            </>
          )
        },
      },
    },
    {
      name: 'phoneNumbers',
      label: ' ',
      align: 'right',
      options: {
        customBodyRender: (numbers) => {
          return (
            <CallIcon
              onClick={() => {
                window.location.href = `tel:${getMobileNumber(numbers)}`
              }}
            />
          )
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ]

  const options = {
    searchOpen: true,
    filter: false,
    searchPlaceholder: t('Skyapp_search'),
    searchInAllTable: true,
    download: false,
    selectableRows: 'none',
    rowHover: true,
    viewColumns: false,
    rowsPerPage: 100,
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.topBar}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <img height="50" src={IMG_TDC_LOGO_BUSSINESS_WHITE} alt="TDC Erhverv logo" />
          <UserSelect me={me.data} customerId={customer.data.customer_id} />
        </Box>
        <Typography variant="h1" color="inherit" className={classes.title}>
          {t(`Skyapp_title_${value}`)}
        </Typography>
      </div>
      <div className={classes.container}>
        {value === 'contacts' && (
          <>
            {contacts?.loading ? (
              <CircularProgress />
            ) : (
              <Box pb={15}>
                {downloadLink && (
                  <Button
                    startIcon={<GetAppIcon />}
                    className={classes.downloadLink}
                    variant="outlined"
                    color="primary"
                    href={downloadLink.fileUrl}
                    download={downloadLink.fileName}
                  >
                    {t('Skyapp_contacts_download_link')}
                  </Button>
                )}
                {contactsExist && (
                  <Button
                    startIcon={<StarIcon />}
                    className={`${classes.favorites} ${
                      showFavorites ? classes.favoriteSelected : ''
                    }`}
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowFavorites(!showFavorites)}
                  >
                    {t('Skyapp_see_favorites')}
                  </Button>
                )}
                <MuiDataTable
                  options={options}
                  data={showFavorites ? favoriteContacts : [...allContacts.values()]}
                  columns={columns}
                />
              </Box>
            )}
          </>
        )}
        {value === 'huntgroups' && <Huntgroups />}
        {value === 'displaynumber' && <DisplayNumber />}
      </div>
      <BottomNavigation
        className={classes.bottomNavigation}
        value={value}
        onChange={handleChange}
        showLabels
      >
        <BottomNavigationAction
          label={t('Skyapp_title_contacts')}
          value="contacts"
          icon={<ContactPhoneIcon />}
        />
        <BottomNavigationAction
          label={t('Skyapp_title_huntgroups')}
          value="huntgroups"
          icon={<Group />}
        />
        <BottomNavigationAction
          label={t('Skyapp_title_displaynumber')}
          value="displaynumber"
          icon={<ScheduleIcon />}
        />
      </BottomNavigation>
    </div>
  )
}

export default SkyApp
