import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, ListItemIcon, IconButton } from '@material-ui/core'
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: '0px',
  },
  list: {
    marginTop: -theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

const BackButton = ({ backLink }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <ListItemIcon>
          <IconButton edge="start" onClick={backLink}>
            <ChevronLeftIcon />
          </IconButton>
        </ListItemIcon>
        <ListItemText secondary={t('Invoice_back')} />
      </ListItem>
    </List>
  )
}

export default BackButton
