import client from '../client'

export const role = ({ customerId, azureTenantId, azureId, roleName }) => {
  return client
    .post(
      `authorization/${customerId}/teams/${azureTenantId}/${azureId}/${roleName}/authorizations`
    )
    .json()
}

export const test = ({ customerId }) => {
  return client.get(`teams/${customerId}/list-secrets`).json()
}
export const OCResendInvite = ({ customerId, json }) => {
  return client.put(`operator-connect/invite/${customerId}`, { json }).json()
}

export const updateTeamEmail = ({ customerId, json }) => {
  return client.put(`teams/${customerId}/sync-azure-email`, { json }).json()
}
