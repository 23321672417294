import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { format } from 'date-fns'
import { getSubscription, deleteSubscription } from 'services/sweb/subscription'
import { useSnackbar } from 'notistack'

import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { makeStyles } from '@material-ui/core/styles'
import {
  SkyTextField,
  SkyDatePicker,
  DrawerSection,
  GradientButton,
  CustomDialog,
} from 'components'

import { SwipeableDrawer, Button, Typography, DialogActions, Slide } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '90%',
    maxWidth: '660px',
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflowX: 'hidden',
    zIndex: 1600,
  },
  message: {
    paddingBottom: theme.spacing(3),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  buttons: {
    marginBottom: theme.spacing(4),
  },
}))

const schema = yup.object().shape({
  emailReceipt: yup
    .string()
    .email()
    .required(),
  date: yup.date().required(),
})

const CancelSubscriptionDrawer = ({ customerId, userEmail, subscription, title, history }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [error, setError] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [earliestCloseDate, setEarliestCloseDate] = useState<string>('')
  const [openDrawer, setOpenDrawer] = useState<boolean>(true)
  const [dialog, setDialog] = useState({
    open: false,
    title: null,
    message: null,
    callback: null,
  })

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    getSubscription
      .earliestCloseDate({ customerId, abbId: subscription.id })
      .then(({ data: { date } = { date: '' } }: { data: { date: string; timestamp?: number } }) => {
        setEarliestCloseDate(date)
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
      })
  }, [])

  const handleDrawerClose = () => {
    setOpenDrawer(false)
    history.push({
      pathname: `/${customerId}/operator-connect/numre/tildelte-numre`,
      state: null,
    })
  }

  const handleCloseDialog = () => {
    setError('')
    setDialog({
      ...dialog,
      open: false,
    })
  }

  const cancelSubscription = (emailReceipt, date) => {
    setSubmitting(true)
    const json = {
      date: format(date, 'dd-MM-yyyy'),
      emailReceipt,
      abbId: subscription?.id,
    }
    deleteSubscription
      .cancelSubscription({
        customerId,
        abbId: json.abbId,
        date: json.date,
        emailReceipt: json.emailReceipt,
        landlineCancelAction: 'ondate',
      })
      .then(() => {
        setSubmitting(false)
        enqueueSnackbar(t('Drawer_cancel_cancel_success', { date: json.date }), {
          variant: 'success',
        })
        handleDrawerClose()
      })
      .catch((err) => {
        setSubmitting(false)
        setError(t('OC_invite_error'))
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
      })
  }

  const onSubmit = (values: { date: Date; emailReceipt: string }) => {
    const { date, emailReceipt } = values
    setDialog({
      open: true,
      title: t('Drawer_cancel_all_title'),
      message: t('Drawer_cancel_all_description', { date: format(date, 'dd-MM-yyyy') }),
      callback: () => cancelSubscription(emailReceipt, date),
    })
  }

  return (
    <SwipeableDrawer
      open={openDrawer}
      onClose={handleDrawerClose}
      onOpen={() => ({})}
      anchor="right"
      classes={{
        paper: classes.drawer,
      }}
    >
      <Slide in direction="left">
        <div>
          <DrawerSection title={t('Drawer_cancel_fill_out_form')} />
          <Alert severity="warning" className={classes.alert}>
            <Typography gutterBottom>{t('Drawer_cancel_all_text_one')}</Typography>
            <Typography component="span" className={classes.message}>
              <ul>
                <li>{t('Drawer_cancel_all_text_warning')}</li>
                <li>{t('Drawer_cancel_all_text_two')}</li>
                <li>{t('Drawer_cancel_all_text_three', { date: earliestCloseDate })}</li>
                <li>{t('Drawer_cancel_all_text_four')}</li>
              </ul>
            </Typography>
          </Alert>
          <Formik
            initialValues={{ emailReceipt: userEmail, date: new Date() }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, setFieldValue, errors, touched }) => (
              <Form>
                <SkyTextField
                  name="emailReceipt"
                  label={t('Drawer_cancel_input_email')}
                  value={values.emailReceipt}
                  onChange={handleChange}
                  white
                  error={touched.emailReceipt && Boolean(errors.emailReceipt)}
                  helperText={touched.emailReceipt && errors.emailReceipt && t('Not_valid_email')}
                />
                <SkyDatePicker
                  name="date"
                  label={t('Drawer_cancel_input_cancel_date')}
                  disablePast
                  value={values.date}
                  onChange={(date) => setFieldValue('date', date)}
                  white
                />
                <DialogActions className={classes.buttons}>
                  <Button color="primary" onClick={handleDrawerClose}>
                    {t('Drawer_buttons_back')}
                  </Button>
                  <GradientButton type="submit">{t('Confirm')}</GradientButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
          <CustomDialog
            isOpen={dialog.open}
            onConfirm={dialog.callback}
            onCancel={handleCloseDialog}
            title={dialog.title}
            contentText={dialog.message}
            confirmButtonText={t('Confirm')}
            confirmDisabled={submitting}
            submitting={submitting}
          >
            {error && (
              <Alert severity="error" className={classes.alert}>
                {error}
              </Alert>
            )}
          </CustomDialog>
        </div>
      </Slide>
    </SwipeableDrawer>
  )
}

export default CancelSubscriptionDrawer
