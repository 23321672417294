import { getCustomer } from 'services/sweb/customer'
import { phoneNumberFormat } from 'utils/helpers'

export const FETCH_FAILURE = 'landlineNumbersCombined/FETCH_FAILURE'
export const FETCH_BEGIN = 'landlineNumbersCombined/FETCH_BEGIN'
export const FETCH_SUCCESS = 'landlineNumbersCombined/FETCH_SUCCESS'

export const fetchLandlineNumbersCombinedFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchLandlineNumbersCombinedBegin = ({ customerId, oneNumberType }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
    oneNumberType,
  }
}

export const fetchLandlineNumbersCombinedSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchLandlineNumbersCombined = ({ customerId, oneNumberType }) => {
  return async (dispatch) => {
    dispatch(fetchLandlineNumbersCombinedBegin({ customerId, oneNumberType }))
    try {
      const landlineResponse = await getCustomer.availableLandlineNumbers({
        customerId,
        oneNumberType,
      })
      const internationalResponse = await getCustomer.availableInternationalNumbers({
        customerId,
        oneNumberType,
      })

      const landlineNumbers =
        (landlineResponse.data &&
          landlineResponse.data.length > 0 &&
          landlineResponse.data.map((number) => {
            return {
              value: number,
              group: 'AvailableLandlineNumber',
              label: phoneNumberFormat(number.number),
            }
          })) ||
        []

      const internationalNumbers =
        (internationalResponse.data &&
          internationalResponse.data.length > 0 &&
          internationalResponse.data.map((number) => {
            return {
              value: number,
              group: 'AvailableIntNumbers',
              label: `+${number.country.country_prefix} ${phoneNumberFormat(number.number)} (${
                number.country.country_name
              }${number.description ? ` - "${number.description}"` : ''})`,
            }
          })) ||
        []

      const optionGroups = [...landlineNumbers, ...internationalNumbers]
      dispatch(fetchLandlineNumbersCombinedSuccess(optionGroups))
    } catch (err) {
      dispatch(fetchLandlineNumbersCombinedFailure(err.response))
    }
  }
}
