import React from 'react'
import classNames from 'classnames'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Phonelink as PhonelinkIcon, PhonelinkOff as PhonelinkOffIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import TeamsQuickMenu from './TeamsQuickMenu'

const useStyles = makeStyles((theme) => ({
  activeColor: {
    color: theme.palette.success.main,
  },
  inactiveColor: {
    color: theme.palette.warning.main,
  },
  removePadding: {
    paddingLeft: 0,
  },
  heading: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
  },
  root: {
    marginTop: '3px',
  },
  button: {
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'none',
  },
  isMobile: {
    marginRight: theme.spacing(2),
  },
  isButton: {
    marginLeft: -theme.spacing(1),
  },
}))

const TeamsTableCell = ({ landlineNumber, abbType, popUserAction, disableButton, mobileOneNumberShadowNumber }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const showLandlineNumber = landlineNumber || mobileOneNumberShadowNumber && `${mobileOneNumberShadowNumber} (skyggenummer)` || null

  return (
    <ListItem className={classes.removePadding}>
      <ListItemIcon>
        {showLandlineNumber ? (
          <PhonelinkIcon className={classes.activeColor} />
        ) : (
            <PhonelinkOffIcon className={classes.inactiveColor} />
          )}
      </ListItemIcon>
      <ListItemText
        className={classNames({
          [classes.isButton]: !showLandlineNumber,
        })}
        classes={{
          primary: classes.heading,
          root: classes.root,
        }}
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
        primary={showLandlineNumber}
        secondary={
          showLandlineNumber ? (
            abbType
          ) : (
              <Button
                disabled={disableButton}
                color="primary"
                className={classes.button}
                onClick={(event) => popUserAction(event, 'opret-abonnement')}
              >
                {t('Drawer_create_sub_teams_title')}
              </Button>
            )
        }
      />
      {showLandlineNumber && !disableButton && !mobileOneNumberShadowNumber && (
        <ListItemSecondaryAction>
          <TeamsQuickMenu popUserAction={popUserAction} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}

export default TeamsTableCell
