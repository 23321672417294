import React, { useState } from 'react'
import { Menu, MenuItem, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  label: {
    justifyContent: 'space-between',
  },
  menuList: {
    minWidth: 232,
  },
}))

interface Props {
  label: string | JSX.Element
  parentMenuOpen: boolean
  children: JSX.Element | JSX.Element[]
}

const NestedMenuItem = ({ label, parentMenuOpen, children }: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <Box
      onMouseEnter={(event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
      }}
      onMouseLeave={() => {
        setAnchorEl(null)
      }}
    >
      <MenuItem className={classes.label}>{label}</MenuItem>
      <Menu
        style={{ pointerEvents: 'none' }}
        classes={{ list: classes.menuList }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(parentMenuOpen && anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        <div style={{ pointerEvents: 'auto' }}>{children}</div>
      </Menu>
    </Box>
  )
}

export default NestedMenuItem
