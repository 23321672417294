import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
  },
  textContainer: {
    paddingBottom: -theme.spacing(3),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
}))

const InvoiceIntroduction = ({ ski }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.textContainer}>
      <Typography className={classes.title} gutterBottom>
        {t('Invoice_intro')}
      </Typography>
      <Typography className={classes.text}>
        {`${t('Invoice_desc')} ${ski ? t('Invoice_quarter_desc') : t('Invoice_month_desc')}`}
      </Typography>
    </div>
  )
}

export default InvoiceIntroduction
