const dkkCurrencyStr2Num = (currency = '') => {
  // converts the string input from invoice to number to be able to math
  const currencyStr = currency // ex. input '1.234,56'
    .toString() // ensure availability for use of replace method
    .replace(/\./g, '') // regex for all occurencies
    .replace(/,/g, '.')

  return parseFloat(currencyStr) // ex. output 1234.56
}

const invoiceTotalCost = (...numbers) => numbers.reduce((total, curr) => total + curr, 0)

// eslint-disable-next-line import/prefer-default-export
export const formatAll = (response) => {
  const invoices = response.data.map((invoice) => {
    const fees = {
      subscriptionFees: dkkCurrencyStr2Num(invoice.subscription_fees),
      mobileFees: dkkCurrencyStr2Num(invoice.mobile_fees),
      fixedLineFees: dkkCurrencyStr2Num(invoice.fixed_line_fees),
      extraServiceFees: dkkCurrencyStr2Num(invoice.extra_service_fees),
    }
    const totalFees = invoiceTotalCost(
      fees.subscriptionFees,
      fees.mobileFees,
      fees.fixedLineFees,
      fees.extraServiceFees
    )
    return { ...invoice, ...fees, totalFees }
  })

  const invoiceFees = invoices.reduce((agg, curr) => {
    return {
      subscriptionFees: agg.subscriptionFees || 0 + curr.subscriptionFees,
      mobileFees: parseFloat(agg.mobileFees || 0 + curr.mobileFees),
      fixedLineFees: parseFloat(agg.fixedLineFees || 0 + curr.fixedLineFees),
      extraServiceFees: parseFloat(agg.extraServiceFees || 0 + curr.extraServiceFees),
      totalFees: parseFloat(agg.totalFees || 0 + curr.totalFees),
    }
  }, {})

  const data = { invoiceFees, invoices }
  return { data }
}
