import client from '../client'

export const validatePhoneNumber = (
  input: { customerId: string; phoneNumber: string; prefix: string | number; azureId?: string },
  ddi?: { number: string }
) => {
  /**
   * when ddi is sent along it means that the number to validate is productVersion1
   * in that case. the validation should use the ddi.number instead of number and prefix as "+45" or not present in query.
   */
  const { customerId, phoneNumber, prefix, azureId } = input

  type SearchParams = {
    number: string
    prefix?: string | number
    azureId?: string
  }

  const searchParams: SearchParams = !ddi
    ? { number: phoneNumber, prefix: `+${prefix}` }
    : { number: ddi.number }

  if (azureId) {
    searchParams.azureId = azureId
  }

  const retry = 5 // https://github.com/sindresorhus/ky#retry
  const timeout = false // 45000
  // exists-in-tenant is for teams users only an equivelant endpoint exists for rest is exists-in-cloud
  return client
    .get(`validate/exists-in-tenant/${customerId}`, { searchParams, retry, timeout })
    .json()
}

export const validateExtension = ({ customerId, extension, phoneNumber }) => {
  return client
    .get(`validate/abbr-dialing/${customerId}?abbr-dialing=${extension}&anr=${phoneNumber}`)
    .json()
}

export const generateExtensions = ({ customerId, phoneNumber }) => {
  return client.get(`validate/generate-extension-teams/${phoneNumber}/${customerId}`).json()
}

export const validateImportNumber = ({ customerId, phoneNumber }) => {
  return client.get(`validate/import-mobile3/${customerId}?number=${phoneNumber}`).json()
}

export const validateEmail = ({ customerId, email }) => {
  const searchParams = { email }
  return client.get(`validate/${customerId}/email`, { searchParams }).json()
}

export const validateHuntgroupExtension = ({ customerId, number, extension }) => {
  return client.get(`cirpack/${customerId}/${number}/abbreviated/${extension}`).json()
}
