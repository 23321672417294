import { useState, useEffect } from 'react'

const useDebounce = (inputValue = '', timer = 555) => {
  const [outputValue, setOutputValue] = useState(inputValue)
  useEffect(() => {
    const timeoutFn = setTimeout(() => {
      setOutputValue(inputValue)
    }, timer)
    return () => {
      clearTimeout(timeoutFn)
    }
  }, [inputValue])
  return [outputValue]
}

export default useDebounce
