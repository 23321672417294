import { OCINVITES_FAILURE, OCINVITES_BEGIN, OCINVITES_SUCCESS, IntNumbersState } from './model'

const initialState: IntNumbersState = {
  loading: false,
  customerId: null,
  data: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OCINVITES_BEGIN:
      return {
        ...state,
        ...action,
        loading: true,
        customerId: action.customerId,
        error: null,
        data: action.customerId === state.customerId ? state.data : null,
      }

    case OCINVITES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      }

    case OCINVITES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: null,
      }

    default:
      return state
  }
}
