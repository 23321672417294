import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { fetchVoxboneCapacityGroup } from 'state/intNumberCapacityGroup/actions'
import { makeStyles } from '@material-ui/core/styles'
import {
  CenterFocusStrongRounded as DotIcon,
  DoneRounded as SuccessIcon,
  PauseRounded as PausedIcon,
  ErrorRounded as ErrorIcon,
  QueryBuilderRounded as ProcessingIcon,
  CancelScheduleSendRounded as CancelledIcon,
} from '@material-ui/icons'
import { SwipeableDrawer } from '@material-ui/core'
import { Alert, DrawerHeader, DrawerTopBar } from '@skytdc/mui/components'
import { DrawerListItem, DrawerSection } from 'components'
import { useTranslation } from 'react-i18next'
import { formatTimestamp, formatStatus } from 'views/Admin/InternationalNumbers/helpers'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '90%',
    maxWidth: '660px',
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflowX: 'hidden',
    zIndex: 1600,
  },
  innerDrawer: {
    marginBottom: theme.spacing(24),
  },
  alert: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  cancelled: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  processing: {
    color: theme.palette.primary.main,
  },
  paused: {
    color: theme.palette.text.secondary,
  },
  error: {
    color: theme.palette.error.main,
  },
}))

const OrderDrawer = ({
  history,
  customerId,
  orderInfo: { country, nationalNumber },
  resultId,
  resultText,
  inserted,
  orderId,
  customerid,
  orderedby,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

  const [open, setOpen] = useState(false)

  const { loading, data, pageNumber, pageSize, error } = useSelector(
    (state) => state.intNumberCapacityGroup
  )

  const { totalResultSize } = data?.pageing || {}

  const maxPageNumberReached = pageNumber <= (Math.ceil(totalResultSize / pageSize) - 1 || 0)

  useEffect(() => {
    if (
      (!loading && !pageNumber && !error) ||
      (!loading && !error && data?.data.length < totalResultSize && maxPageNumberReached)
    ) {
      dispatch(fetchVoxboneCapacityGroup({ customerId, id: '', pageNumber, pageSize }))
    }
  }, [!loading])

  // eslint-disable-next-line
  const handleOnOpen = () => {}

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      history.push({
        pathname: `/${customerId}/operator-connect/numre/ordrer`,
        state: null,
      })
    }, 300)
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  const iconClassName = classNames({
    [classes.success]: resultId === 1,
    [classes.processing]: resultId === 2,
    [classes.cancelled]: resultId === 3,
    [classes.paused]: resultId === 4,
    [classes.error]: resultId > 4,
  })

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={handleOnOpen}
        onClose={handleClose}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        classes={{
          paper: classes.drawer,
        }}
        transitionDuration={375}
      >
        <div className={classes.innerDrawer}>
          <DrawerTopBar goBack={handleClose} backAnchor={t('Organization_orders_drawer_close')} />
          <DrawerHeader
            primary={nationalNumber}
            secondary={t(formatStatus(resultId))}
            withMargin
            size="large"
            icon={
              (resultId === 1 && <SuccessIcon className={iconClassName} />) ||
              (resultId === 2 && <ProcessingIcon className={iconClassName} />) ||
              (resultId === 3 && <CancelledIcon className={iconClassName} />) ||
              (resultId === 4 && <PausedIcon className={iconClassName} />) ||
              (resultId > 4 && <ErrorIcon className={iconClassName} />)
            }
          />

          <DrawerSection title={t('OrderDetails')} />
          <Alert type="info" message={`Result: ${resultText}`} className={classes.alert} />
          <DrawerListItem
            icon={<DotIcon />}
            primaryText={nationalNumber}
            secondaryText={t('Organization_huntgroups_td_2')}
          />
          <DrawerListItem
            icon={<DotIcon />}
            primaryText={country.name}
            secondaryText={t('Organization_avail_numbers_td3')}
          />
          <DrawerListItem
            icon={<DotIcon />}
            primaryText={orderedby}
            secondaryText={t('Organization_orders_orderedby')}
          />
          <DrawerListItem
            icon={<DotIcon />}
            primaryText={formatTimestamp(inserted)}
            secondaryText={t('Organization_orders_order_date')}
          />
          <DrawerListItem
            icon={<DotIcon />}
            primaryText={customerid}
            secondaryText={t('Organization_unassignedsimcards_customer_id')}
          />
          <DrawerListItem icon={<DotIcon />} primaryText={orderId} secondaryText="Order ID" />
        </div>
      </SwipeableDrawer>
    </div>
  )
}

export default OrderDrawer
