import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'intNumberAddonProducts/FETCH_FAILURE'
export const FETCH_BEGIN = 'intNumberAddonProducts/FETCH_BEGIN'
export const FETCH_SUCCESS = 'intNumberAddonProducts/FETCH_SUCCESS'

export const fetchIntNumberAddonProductsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchIntNumberAddonProductsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchIntNumberAddonProductsSuccess = ({ customerId, data }) => {
  return {
    type: FETCH_SUCCESS,
    customerId,
    data,
  }
}

export const fetchIntNumberAddonProducts = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchIntNumberAddonProductsBegin({ customerId }))
    try {
      const response = await getCustomer.intNumberAddonProducts({ customerId })
      dispatch(fetchIntNumberAddonProductsSuccess({ customerId, data: response.data }))
    } catch (err) {
      dispatch(fetchIntNumberAddonProductsFailure(err.response))
    }
  }
}
