import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List } from '@material-ui/core'
import { GradientButton, PaperFeedbackMessage } from 'components'
import { useTranslation } from 'react-i18next'
import StepContainer from '../StepContainer'
import TextBlock from '../TextBlock'
import PresenceItem from '../PresenceItem'

const useStyles = makeStyles((theme) => ({
  btnWrapper: {
    display: 'inline-block',
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  price: {
    fontSize: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
}))
export const formatPrice = (price) => `${price === '0.0' || price === '0.00' ? '0' : price || 0},-`

const PresenceActivation = ({
  currentStep,
  presenceProduct,
  fetchPresenceProduct,
  disablePresence,
  activatePresence,
  loading,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    available_presence_product: availablePresenceProduct,
    current_presence_subscription: currentPresenceSubscription,
    other_non_ended_presence_subscriptions: otherNonEndedPresenceSubscriptions,
  } = presenceProduct.data || {}
  const { customer_name: customerName } = currentPresenceSubscription || {}
  const { product_name: productName, price } = availablePresenceProduct || {}

  return (
    <>
      <StepContainer
        step={currentStep}
        requiredStep={4}
        finalStep={true}
        stepLabel={t('Wizard_presence_title', { productName })}
      >
        <TextBlock>{t('Wizard_3453456', { productName })}</TextBlock>
        {presenceProduct.error && (
          <PaperFeedbackMessage type="error" message={presenceProduct.error.statusText} />
        )}
        {customerName && (
          <List className={classes.list}>
            {[
              { ...currentPresenceSubscription, active: true },
              ...otherNonEndedPresenceSubscriptions,
            ].map((subscription, i) => (
              <PresenceItem
                key={i}
                subscription={subscription}
                loading={loading}
                disablePresence={disablePresence}
              />
            ))}
          </List>
        )}

        {(!presenceProduct.data?.current_presence_subscription ||
          presenceProduct.data?.current_presence_subscription.end_date) && (
          <div className={classes.btnWrapper}>
            <div className={classes.price}>{formatPrice(price)}</div>
            <GradientButton loading={presenceProduct.loading} onClick={activatePresence}>
              {t('Organization_customer_activate', {
                productName,
              })}
            </GradientButton>
          </div>
        )}
      </StepContainer>
    </>
  )
}

export default memo(PresenceActivation)
