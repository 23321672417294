import React from 'react'
import classNames from 'classnames'
import { TextField, CircularProgress, TextFieldProps } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { FilledInputProps } from '@material-ui/core/FilledInput'
import useInputStyle from '../../hooks/useInputStyle'

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    width: '100%',
    display: 'block',
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
  },
  startAdornment: {
    marginRight: 10,
  },
  endAdornment: {
    float: 'right',
    marginLeft: 10,
    marginRight: -2,
  },
  checkbox: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(),
  },
  container: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    right: 38,
    top: 18,
  },
  groupLabel: {
    fontSize: 12,
    fontWeight: 600,
  },
}))

interface Props {
  // eslint-disable-next-line
  options: any
  white?: boolean
  // eslint-disable-next-line
  onChange: (name: string, value: any) => void
  name: string
  disableClearable?: boolean
  // eslint-disable-next-line
  value: any
  isMultiselect?: boolean
  withMargin?: boolean
  required?: boolean
  disabled?: boolean
  loading?: boolean
  error?: boolean
  label: string
  helperText?: string
  loadingText?: string
  noOptionsText?: string
  // eslint-disable-next-line
  getOptionSelected?: any
  // eslint-disable-next-line
  renderOption?: any
  // eslint-disable-next-line
  groupBy?: any
  // eslint-disable-next-line
  getOptionLabel?: any
  // eslint-disable-next-line
  getOptionDisabled?: any
  // eslint-disable-next-line
  renderGroup?: any
}

const SkySelect = ({
  options,
  value,
  white = true,
  name,
  onChange,
  withMargin = true,
  required = false,
  error = false,
  disableClearable = true,
  label,
  loading = false,
  disabled = false,
  groupBy,
  renderOption,
  renderGroup,
  helperText,
  getOptionDisabled,
  getOptionSelected,
  getOptionLabel,
}: Props) => {
  const classes = useStyles()
  const inputStyles = useInputStyle()

  const root = classNames({
    [inputStyles.root]: true,
    [inputStyles.white]: white,
  })

  // eslint-disable-next-line
  const handleOnChange = (_event: React.ChangeEvent<Record<string, unknown>>, val: any) => {
    onChange(name, val)
  }

  return (
    <div className={withMargin ? inputStyles.withMargin : ''}>
      <Autocomplete
        value={value}
        options={options}
        loading={loading}
        disabled={disabled || loading}
        disableClearable={disableClearable}
        groupBy={groupBy}
        // eslint-disable-next-line
        onChange={handleOnChange}
        classes={{
          option: classes.option,
          groupLabel: classes.groupLabel,
        }}
        loadingText="Arbejder..."
        renderGroup={renderGroup}
        autoComplete={false}
        getOptionSelected={getOptionSelected}
        noOptionsText="Ingen muligheder"
        closeText="Luk"
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        renderOption={renderOption}
        renderInput={(params: TextFieldProps) => (
          <div className={classes.container}>
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              error={error}
              name={name}
              required={required}
              label={label}
              helperText={helperText}
              variant="filled"
              /* inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }} */
              InputProps={
                {
                  ...params.InputProps,
                  disableUnderline: true,
                  autoComplete: 'new-password',
                  classes: {
                    root,
                    focused: inputStyles.focused,
                    error: inputStyles.error,
                    disabled: inputStyles.disabled,
                  },
                } as Partial<FilledInputProps>
              }
            />
            {loading && <CircularProgress size={20} className={classes.loading} />}
          </div>
        )}
      />
    </div>
  )
}

export default SkySelect
