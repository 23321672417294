import React from 'react'
import { Paper, PaperHeader, PaperTitle, PaperFooter } from '@skytdc/mui/components'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { DonutChartWithText } from 'components'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ArrowRightRounded as ArrowRightIcon } from '@material-ui/icons'
import { convertKbToGb } from 'utils/helpers'

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    marginLeft: 'auto',
  },
  paper: {
    marginBottom: 32,
    '& + div': {
      height: 'auto',
    },
  },
}))

const DataPool = ({ title, dataUsed, customerId }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyles()

  const dataUsedProcent = dataUsed?.data_usage_percent || 0

  return (
    <Paper className={classes.paper}>
      <PaperHeader noDivider>
        <PaperTitle title={title} />
      </PaperHeader>
      <DonutChartWithText
        chartData={[dataUsedProcent, 100 - dataUsedProcent]}
        backgroundColor={[theme.palette.primary.main, theme.palette.common.neutral]}
        title={convertKbToGb(dataUsed?.dkeu_data_usage, 2)}
        subTitle={`${t('Data_pool_out_of')} ${convertKbToGb(dataUsed?.dkeu_data_pool, 0)}`}
        textInsideDonut={`${dataUsedProcent}%`}
      />
      <PaperFooter alignRight>
        <Link to={`/${customerId}/data-pool-usage`} className={classes.link}>
          <Button color="primary" size="small" variant="text">
            {t('Dashboard_general_see_all')} <ArrowRightIcon />
          </Button>
        </Link>
      </PaperFooter>
    </Paper>
  )
}

export default DataPool
