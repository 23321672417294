import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  noContentPadding: {
    padding: 0,
  },
  title: {
    fontWeight: 700,
    fontSize: 22,
    marginTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
}))

interface Props {
  open: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg'
  children?: JSX.Element | JSX.Element[]
  title: string
  buttons?: JSX.Element
  closeButtonText?: string
  withDivider?: boolean
  description?: string
  onClose: (value: string) => void
  contentPadding?: boolean
}

const DialogWrapper = ({
  open,
  maxWidth = 'sm',
  onClose,
  title,
  description,
  buttons,
  withDivider = false,
  contentPadding = true,
  children,
}: Props) => {
  const classes = useStyles()

  return (
    <Dialog open={open} fullWidth maxWidth={maxWidth} onClose={onClose}>
      <DialogTitle disableTypography className={classes.title}>
        {title}
      </DialogTitle>
      <DialogContent
        dividers={withDivider}
        className={contentPadding ? '' : classes.noContentPadding}
      >
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
      </DialogContent>
      {buttons && <DialogActions>{buttons}</DialogActions>}
    </Dialog>
  )
}

export default DialogWrapper
