import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'availableLandlineNumbers/FETCH_FAILURE'
export const FETCH_BEGIN = 'availableLandlineNumbers/FETCH_BEGIN'
export const FETCH_SUCCESS = 'availableLandlineNumbers/FETCH_SUCCESS'

export const fetchAvailableLandlineNumbersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAvailableLandlineNumbersBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchAvailableLandlineNumbersSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchAvailableLandlineNumbers = ({ customerId, oneNumberType }) => {
  return async (dispatch) => {
    dispatch(fetchAvailableLandlineNumbersBegin({ customerId }))
    try {
      const response = await getCustomer.availableLandlineNumbers({
        customerId,
        oneNumberType,
      })
      dispatch(fetchAvailableLandlineNumbersSuccess(response.data))
    } catch (err) {
      dispatch(fetchAvailableLandlineNumbersFailure(err.response))
    }
  }
}
