export const OCLANDLINE_NUMBERS_FAILURE = 'OCLANDLINE_NUMBERS_FAILURE'
export const OCLANDLINE_NUMBERS_BEGIN = 'OCLANDLINE_NUMBERS_BEGIN'
export const OCLANDLINE_NUMBERS_SUCCESS = 'OCLANDLINE_NUMBERS_SUCCESS'

export interface ParamsProps {
  pageSize?: number
  pageNumber?: number
  order?: string
  orderby?: string
  customerId: string
}

interface FetchIntNumbersBegin extends ParamsProps {
  type: typeof OCLANDLINE_NUMBERS_BEGIN
}

interface FetchIntNumbersFailure {
  type: typeof OCLANDLINE_NUMBERS_FAILURE
  // eslint-disable-next-line
  error: any
}

export interface FetchIntNumbersSuccess {
  type: typeof OCLANDLINE_NUMBERS_SUCCESS
  // eslint-disable-next-line
  data: any
}

export type IntNumbersActions =
  | FetchIntNumbersBegin
  | FetchIntNumbersFailure
  | FetchIntNumbersSuccess

export interface IntNumbersState extends ParamsProps {
  loading: boolean
  // eslint-disable-next-line
  data?: any
  // eslint-disable-next-line
  error?: Error
}
