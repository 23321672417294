import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'portfolio/FETCH_FAILURE'
export const FETCH_BEGIN = 'portfolio/FETCH_BEGIN'
export const FETCH_SUCCESS = 'portfolio/FETCH_SUCCESS'

export const fetchPortfolioFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchPortfolioBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchPortfolioSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchPortfolio = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchPortfolioBegin({ customerId }))
    try {
      const response = await getCustomer.portfolio({ customerId })
      dispatch(fetchPortfolioSuccess(response.data))
    } catch (err) {
      dispatch(fetchPortfolioFailure(err.response))
    }
  }
}
