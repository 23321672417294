import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { fetchHuntgroups } from 'state/huntgroups/actions'
import { useSelector, useDispatch } from 'react-redux'
import {
  Paper,
  PaperContent,
  PaperLoader,
  PaperFeedbackMessage,
  FloatingButton,
  MuiDataTable,
} from 'components'
import hasRoles from 'utils/hasRoles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import EditDrawer from './components/EditDrawer'
import CreateDrawer from './components/CreateDrawer'

const useStyles = makeStyles((theme) => ({
  intro: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  tableCellWithIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}))

const MobileHuntgroups = ({ match, history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { customer_id: customerId } = match.params
  const me = useSelector((state) => state.me)
  const huntgroups = useSelector((state) => state.huntgroups)
  const customer = useSelector((state) => state.customer)
  const { t } = useTranslation()

  const customerIsMisconfigured = Boolean(
    customer.data.mobile_huntgroups_s4b && customer.data.mobile_huntgroups_teams
  )

  const columns = [
    {
      name: 'group_name',
      label: t('Organization_huntgroups_td_1'),
      align: 'left',
      options: {
        sortDirection: 'asc',
      },
    },
    {
      name: 'group_number',
      label: t('Organization_huntgroups_td_2'),
      align: 'left',
    },
    {
      name: '',
      label: '',
      align: 'right',
      options: {
        customBodyRender: () => {
          return (
            <div className={classes.tableCellWithIcon}>
              <ChevronRightIcon className={classes.icon} />
            </div>
          )
        },
      },
    },
  ]

  const options = {
    searchOpen: true,
    searchPlaceholder: t('Organization_huntgroups_search'),
    selectableRows: 'none',
    rowHover: true,
    viewColumns: false,
    rowsPerPage: 15,
    onRowClick: (rowData, rowMeta) => {
      const findHuntgroup = huntgroups.data.find((grp, index) => index === rowMeta.dataIndex)
      history.push(
        `/${match.params.customer_id}/organisation/mobile-ringegrupper/${findHuntgroup.group_number}`
      )
    },
  }

  const canAccessMobileHuntgroups = hasRoles(me.data, match.params.customer_id, [
    'mobile_huntgroup_admin',
  ])

  const routeIsHuntgroups = Boolean(match.url.endsWith('/mobile-ringegrupper'))

  useEffect(() => {
    if (!huntgroups.data && canAccessMobileHuntgroups) {
      dispatch(fetchHuntgroups({ customerId }))
    }
  }, [])

  useEffect(() => {
    if (routeIsHuntgroups && huntgroups.data && !huntgroups.loading && canAccessMobileHuntgroups) {
      dispatch(fetchHuntgroups({ customerId }))
    }
  }, [match])

  const openDrawer = () => {
    history.push(`/${match.params.customer_id}/organisation/mobile-ringegrupper/opret`)
  }

  let content = <PaperLoader />

  if (!canAccessMobileHuntgroups) {
    content = (
      <PaperFeedbackMessage
        type="error"
        withMargin
        disableGutters={false}
        message={t('Organization_huntgroups_missing_role')}
      />
    )
  }

  if (customerIsMisconfigured) {
    content = (
      <PaperFeedbackMessage
        type="error"
        withMargin
        disableGutters={false}
        message={t('Organization_huntgroups_misconfiguration')}
      />
    )
  }

  if (huntgroups.error) {
    content = (
      <PaperFeedbackMessage
        type="error"
        withMargin
        disableGutters={false}
        message={t('Organization_huntgroups_error', { error: huntgroups.error.statusText })}
      />
    )
  }

  if (huntgroups.data && !customerIsMisconfigured && canAccessMobileHuntgroups) {
    content = (
      <>
        {huntgroups.data.length > 0 ? (
          <>
            <Typography variant="body1" className={classes.intro}>
              {t('Organization_huntgroups_title')}
            </Typography>
            <MuiDataTable data={huntgroups.data} options={options} columns={columns} />
          </>
        ) : (
          <PaperFeedbackMessage
            message={t('Organization_huntgroups_no_huntgroups')}
            withMargin
            disableGutters={false}
          />
        )}
        <FloatingButton tooltip={t('Organization_huntgroups_tooltip')} onClick={openDrawer} />
        <Switch>
          {huntgroups.data.map((huntgroup) => {
            return (
              <Route
                key={huntgroup.group_number}
                render={(props) => <EditDrawer huntgroup={huntgroup} {...props} />}
                exact
                path={`/:customer_id/organisation/mobile-ringegrupper/${huntgroup.group_number}`}
              />
            )
          })}
          <Route
            render={(props) => <CreateDrawer {...props} />}
            exact
            path="/:customer_id/organisation/mobile-ringegrupper/opret"
          />
        </Switch>
      </>
    )
  }

  return (
    <Paper>
      <PaperContent noPadding>
        <Typography variant="body1" className={classes.intro}>
          {t('Organization_huntgroups_description')}
        </Typography>
        {content}
      </PaperContent>
    </Paper>
  )
}

export default MobileHuntgroups
