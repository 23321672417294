import client from '../client'

export const secret = ({ customerId, json }) => {
  return client.delete(`teams/${customerId}/delete-secret`, { json }).json()
}

export const huntgroup = ({ customerId, number }) => {
  return client.delete(`cirpack/${customerId}/${number}`).json()
}

export const adGroupFilters = ({ customerId, json }) => {
  return client.delete(`teams/${customerId}/filter-groups`, { json }).json()
}

// Operator connect endpoints
