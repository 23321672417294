import React from 'react'
import { ContactPhoneOutlined as ShowSkyAppNumberIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import ListSwitch from 'components/ListSwitch'
import { useTranslation } from 'react-i18next'

const CallTransferSkyapp = ({ showNumberSkyapp, toggleShowNumberInSkyapp, loading, disabled }) => {
  const { t } = useTranslation()
  return (
    <DrawerListItem
      tooltip={t('Drawer_user_show_number')}
      icon={<ShowSkyAppNumberIcon />}
      isLoading={loading || showNumberSkyapp.loading}
      primaryText={t('Drawer_user_show_number_title')}
      secondaryText={t('Drawer_user_show_or_hide')}
      isDisabled={disabled}
    >
      {showNumberSkyapp.data && (
        <ListSwitch
          isChecked={Boolean(showNumberSkyapp.data.is_public)}
          onChange={toggleShowNumberInSkyapp}
          isDisabled={Boolean(loading || disabled)}
          label={
            showNumberSkyapp.data.is_public ? t('Drawer_user_activated') : t('Drawer_user_activate')
          }
        />
      )}
    </DrawerListItem>
  )
}

export default CallTransferSkyapp
