import React, { memo } from 'react'
import { SmartphoneOutlined as MobileIcon, ShowChart as ShowChartIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'
import { phoneNumberFormat, convertKbToGb } from 'utils/helpers'
import MobilePackage from './MobilePackage'
import DisplayNumber from './DisplayNumber'
import CallForwarding from './CallForwarding'
import Simcards from './Simcards'

const Mobile = ({
  mobileNumber,
  mobilePackage,
  dataUsages,
  allowsDisplayNumber,
  currentUrl,
  disabled,
  allowsForwarding,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <DrawerListItem
        icon={<MobileIcon />}
        primaryText={t('Organization_orders_drawer_mobile')}
        secondaryText={phoneNumberFormat(mobileNumber)}
        isDisabled={disabled}
      />
      <DrawerListItem
        icon={<ShowChartIcon />}
        primaryText={t('Organization_orders_drawer_mobile_data_usages')}
        secondaryText={convertKbToGb(dataUsages?.data?.dataUsed, 2)}
        isDisabled={disabled}
        isLoading={dataUsages?.loading}
      />
      <MobilePackage mobilePackage={mobilePackage} currentUrl={currentUrl} disabled={disabled} />
      {allowsDisplayNumber && <DisplayNumber currentUrl={currentUrl} disabled={disabled} />}
      {allowsForwarding && <CallForwarding currentUrl={currentUrl} disabled={disabled} />}
      <Simcards currentUrl={currentUrl} disabled={disabled} />
    </>
  )
}

export default memo(Mobile)
