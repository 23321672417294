import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'subscription/FETCH_FAILURE'
export const FETCH_BEGIN = 'subscription/FETCH_BEGIN'
export const FETCH_SUCCESS = 'subscription/FETCH_SUCCESS'

export const fetchSubscriptionFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchSubscriptionBegin = ({ abbId }) => {
  return {
    type: FETCH_BEGIN,
    abbId,
  }
}

export const fetchSubscriptionSuccess = ({ data, abbId }) => {
  return {
    type: FETCH_SUCCESS,
    data,
    abbId,
  }
}

export const fetchSubscription = ({ customerId, abbId }) => {
  return async (dispatch) => {
    dispatch(fetchSubscriptionBegin({ abbId }))
    try {
      const response = await getSubscription.subscription({
        customerId,
        abbId,
      })
      dispatch(fetchSubscriptionSuccess({ abbId, data: response.data }))
    } catch (err) {
      dispatch(fetchSubscriptionFailure(err.response))
    }
  }
}
