import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { fetchPartyMe } from 'state/partyMe/actions'
import { useSnackbar } from 'notistack'
import { Paper, PaperHeader, PaperTitle, PaperContent, SkySelect } from '@skytdc/mui/components'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { fetchTransportOptions } from 'state/partyTransport/actions'
import { DialogWrapper } from 'components'
import { IMG_PARTY_PEOPLE } from 'constants/cdn'

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  Radio,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'

import InfoIcon from '@material-ui/icons/Info'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'
import FastfoodIcon from '@material-ui/icons/Fastfood'

import PartyModeIcon from '@material-ui/icons/PartyMode'
import FormGroup from '@material-ui/core/FormGroup'
import { createSubscription } from 'services/partyweb/subscription'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import QA from './Q&A'

import PartyCard from '../components/PartyCard'
import Layout from '../components/Layout'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const useStyles = makeStyles((theme: Theme) => ({
  options: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      '&>div': {
        marginRight: 16,
      },
    },
  },
  addressContainer: {
    maxWidth: 300,
    borderRadius: 8,
    border: `3px solid ${theme.palette.primary.main}`,
    padding: 16,
    textAlign: 'center',
    margin: '16px auto 32px',
    '&>h3': {
      marginTop: 0,
    },
  },
  foodContainer: {
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
    '& ul': {
      fontSize: 16,

      '&>li': {
        marginBottom: 8,
      },
    },
  },
  tableContact: {
    '&> div': {
      marginBottom: 8,
    },
  },
}))

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export default function MyParties({ history, match }) {
  const partyId = parseFloat(match.path?.split('/')[3])

  const dispatch = useDispatch()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { parties, foodOptions: foodPreference, transportationOutput } = useSelector(
    (state) => state.partyMe?.data || {}
  )
  const partyTransportOptions = useSelector((state) => state.partyTransport)
  const [value, setValue] = useState(0)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const { t } = useTranslation()
  const { attending, id: partyNumber, location } = parties.find(({ id }) => id === partyId) || {}
  const [attendingParty, setAttendingParty] = useState(attending)

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search)
    const tab = queryParams.get('tab')
    setValue(Number(tab))
    changeDocumentTitle('Parties for Nuuday employees')
  }, [])

  useEffect(() => {
    dispatch(fetchTransportOptions({ partyId }))
  }, [partyId])

  const onSubmit = async (values: any) => {
    const { transportId, ...foodOptions } = values

    const json = {
      foodOptions,
      transportation: transportId,
    }

    await createSubscription[attending ? 'partyInviteUpdate' : 'partyInviteSignUp']({
      partyId,
      json,
    })
      .then(() => {
        dispatch(fetchPartyMe())
        enqueueSnackbar(`You're now signed up`, { variant: 'success' })
      })
      .catch((err) => {
        err.response.json().then((res) => {
          enqueueSnackbar(`${res.error.text}`, { variant: 'error' })
        })
      })
  }

  const cancelInvite = () => {
    createSubscription.partyInviteCancel({ partyId }).then(() => {
      dispatch(fetchPartyMe())
      setOpenDeleteDialog(false)
      enqueueSnackbar(`You are now unsubscribed from the party`, { variant: 'success' })
    })
  }
  const expired = location?.includes('Expired')
  const transportOptions = [
    { id: null, location: 'Coming by myself' },
    ...(partyTransportOptions.data?.transportOptions || []),
  ]

  const busTransport =
    location === 'Øst'
      ? [
          {
            from: (
              <>
                <div>
                  <b>Nuuday, Teglholmsgade 1 </b>
                </div>
                <br />
                (The bus leaves from Vester Teglgade)
              </>
            ),
            to: 'Docken',
            time: '16.50',
            contact: (
              <div className={classes.tableContact}>
                <div>Henrik Ellesøe</div>
                <div>helsh@yousee.dk</div>
                20 20 91 12
              </div>
            ),
          },
          {
            from: (
              <>
                <div>
                  <b>TELMORE Roskilde</b>
                </div>
                <br />
                Gullandsstræde 2, Roskilde
              </>
            ),

            to: 'Docken',
            time: '16.45',
            contact: (
              <div className={classes.tableContact}>
                <div>Elvan Kinisiz</div>
                <div>ekin@telmore.dk</div>
                61 47 24 06
              </div>
            ),
          },
          {
            from: (
              <>
                <div>
                  <b>HIPER</b>
                </div>
                <br />
                Adelgade 15,Copenhagen K
              </>
            ),
            to: 'Docken',
            time: '17.20',

            contact: (
              <div className={classes.tableContact}>
                <div>Sisse Hansen</div>
                <div>sih@hiper.dk</div>
                51 37 28 32
              </div>
            ),
          },
          {
            from: (
              <>
                <div>
                  <b>RELATEL</b>
                </div>
                <br />
                Teglværksgade 18, Copenhagen Ø
              </>
            ),
            to: 'Docken',
            time: '17.20',
            contact: (
              <div className={classes.tableContact}>
                <div>Aisu</div>
                <div>asi@relatel.dk</div>
                71 99 99 69
              </div>
            ),
          },
          {
            from: <b>Docken</b>,
            to: 'Nordhavn Station',
            time: '00.15',
            contact: '',
          },
        ]
      : [
          {
            from: (
              <>
                <div>
                  <b>Nuuday, Sletvej 30 </b>
                </div>
                <br />
                8310 Tranbjerg J
              </>
            ),
            to: 'Savværket ',
            time: '17.15',
            contact: (
              <div className={classes.tableContact}>
                <div>Lotte Bøckman Basse </div>
                20144301
              </div>
            ),
          },
          {
            from: (
              <>
                <div>
                  <b>YouSee Sønderborg </b>
                </div>
                <br />
                Ellegaardsvej 23A , 6400 Sønderborg
              </>
            ),

            to: 'Savværket',
            time: '15.30',
            contact: (
              <div className={classes.tableContact}>
                <div>Kia Gram Horrmann </div>
                20820551
              </div>
            ),
          },
          {
            from: (
              <>
                <div>
                  <b>YouSee Kolding</b>
                </div>
                <br />
                Rutebilstation, Mazantigade 12-14, 6000 Kolding
              </>
            ),
            to: 'Savværket ',
            time: '16.40',

            contact: (
              <div className={classes.tableContact}>
                <div>Lise Jul Hansen </div>
                27394700
              </div>
            ),
          },
          {
            from: (
              <>
                <div>
                  <b>Nuuday Odense </b>
                </div>
                <br />
                Klingenberg 16, uden for Handelsbanken, 5000 Odense C
              </>
            ),
            to: 'Savværket',
            time: '16.00',
            contact: (
              <div className={classes.tableContact}>
                <div>Jesper Christensen </div>
                51956351
              </div>
            ),
          },
          {
            from: <b>Savværket </b>,
            to: 'Nuuday, Sletvej 30, 8310 Tranbjerg J',
            time: '00.15',
            contact: '',
          },
          {
            from: <b>Savværket </b>,
            to: 'Århus C Banegårdspladsen',
            time: '00.15',
            contact: '',
          },
          {
            from: <b>Savværket </b>,
            to: 'YouSee Sønderborg, Ellegaardsvej 23A, 6400 Sønderbog',
            time: '00.15',
            contact: '',
          },
          {
            from: <b>Savværket </b>,
            to: 'YouSee Kolding, Rutebilstation, Mazantigade 12-14, 6000 Kolding',
            time: '00.15',
            contact: '',
          },
          {
            from: <b>Savværket </b>,
            to: 'Klingenberg 16, uden for Handelsbanken, 5000 Odense C',
            time: '00.15',
            contact: '',
          },
        ]

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper>
            <PaperHeader noDivider={false}>
              <PaperTitle title={t('Party_parties')} />
            </PaperHeader>
            <PaperContent>
              {parties?.map((content) => (
                <PartyCard
                  checked={content.id === partyId}
                  onClick={() => history.push(`/party/id/${content.id}`)}
                  key={content.id}
                  content={content}
                />
              ))}
            </PaperContent>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper>
            {!partyNumber ? (
              <h1>Choose a party</h1>
            ) : (
              <>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="on"
                  onChange={(e, newValue) => {
                    setValue(newValue)
                    history.push({
                      search: `?tab=${newValue}`,
                    })
                  }}
                  variant="scrollable"
                  aria-label="scrollable auto tabs"
                >
                  <Tab icon={<PartyModeIcon />} label={t('Party_signup')} />
                  <Tab icon={<InfoIcon />} label="Info" />
                  <Tab icon={<DirectionsBusIcon />} label="Transport" />
                  <Tab icon={<FastfoodIcon />} label="Food" />
                  <Tab icon={<QuestionAnswerIcon />} label="Q&A" />
                </Tabs>
                <TabPanel value={value} index={0}>
                  {attendingParty || expired ? (
                    <Box>
                      {!expired ? (
                        <Typography variant="h2">
                          Thank you for signing up for Nuuday ‘22 Party at
                          {location === 'Øst' ? ' Docken the 16th' : ' Savværket the 23rd '} of
                          September from 18.00 - 24.00
                          <Button variant="text" color="primary" onClick={() => setValue(1)}>
                            Read more
                          </Button>
                        </Typography>
                      ) : (
                        <>
                          <Typography variant="h2">
                            The registration deadline for the Nuuday 22 party has passed. Contact{' '}
                            <a href="mailto:assistants@nuuday.dk">assistants@nuuday.dk</a> for
                            questions
                          </Typography>
                        </>
                      )}
                      <img src={IMG_PARTY_PEOPLE} alt="Party people" style={{ width: '100%' }} />
                      {!expired && (
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            variant="outlined"
                            onClick={() => setOpenDeleteDialog(true)}
                            style={{ marginRight: 16 }}
                          >
                            Unsubscribe from the party
                          </Button>
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            onClick={() => setAttendingParty(false)}
                          >
                            Update your informations
                          </Button>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Formik
                      initialValues={{
                        comment: foodPreference?.comment || '',
                        diet: foodPreference?.diet || '',
                        allergies: foodPreference?.allergies || [],
                        transportId: transportationOutput?.id || null,
                      }}
                      onSubmit={onSubmit}
                    >
                      {({ values, handleChange, setFieldValue, isSubmitting }) => (
                        <Form>
                          <Box mb={2}>
                            <Typography variant="subtitle1">
                              {t('Party_transport_options')}
                            </Typography>
                          </Box>
                          <SkySelect
                            label={t('Party_transport_options')}
                            name="transportId"
                            disableClearable
                            value={
                              transportOptions.find((x) => x.id === values.transportId) || null
                            }
                            options={transportOptions}
                            withMargin={false}
                            disabled={location === 'Øst' || partyTransportOptions.loading}
                            loading={partyTransportOptions.loading}
                            onChange={(name, transport) => setFieldValue(name, transport.id)}
                            getOptionLabel={(option) => option.location}
                            getOptionSelected={(option, transport) => option.id === transport.id}
                            renderOption={(option) => {
                              const {
                                location: busLocation,
                                transportGoingTime,
                                transportLeavingTime,
                              } = option || {}
                              return (
                                <>
                                  <Typography>{busLocation}</Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {transportGoingTime && transportGoingTime !== '00:00' && (
                                      <>Going time {transportGoingTime}</>
                                    )}
                                    {transportLeavingTime && transportGoingTime !== '00:00' && (
                                      <span style={{ marginLeft: '1.5ch' }}>
                                        Leaving {transportLeavingTime}
                                      </span>
                                    )}
                                  </Typography>
                                </>
                              )
                            }}
                          />
                          <Box mt={2}>
                            <Typography variant="subtitle1">Food preferences</Typography>
                          </Box>
                          <Box mt={1} mb={2} className={classes.options}>
                            <RadioGroup
                              aria-label="diet"
                              name="diet"
                              onChange={handleChange}
                              value={values.diet}
                            >
                              {['pescetarian', 'vegetarian', 'vegan', 'none'].map(
                                (name: string) => (
                                  <FormControlLabel
                                    key={name}
                                    value={name === 'none' ? '' : name}
                                    control={<Radio color="primary" />}
                                    label={t(`Party_${name}`)}
                                  />
                                )
                              )}
                            </RadioGroup>
                            <FormGroup onChange={handleChange}>
                              {['lactose', 'gluten', 'nuts'].map((name) => (
                                <FormControlLabel
                                  key={name}
                                  control={
                                    <Checkbox
                                      name="allergies"
                                      value={name}
                                      color="primary"
                                      checked={values.allergies.includes(name)}
                                    />
                                  }
                                  label={t(`Party_${name}`)}
                                />
                              ))}
                            </FormGroup>
                          </Box>
                          <TextField
                            value={values.comment}
                            name="comment"
                            onChange={handleChange}
                            label={t('Party_comment')}
                          />
                          <Box mt={3} textAlign="right">
                            {attending && (
                              <Button
                                variant="outlined"
                                onClick={() => setOpenDeleteDialog(true)}
                                style={{ marginRight: 16 }}
                              >
                                Unsubscribe from the party
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              type="submit"
                              color="primary"
                              disabled={isSubmitting}
                            >
                              {attending ? 'Update info' : 'Sign up'}
                            </Button>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Typography component="div">
                    <h3>
                      We’ve all been waiting for this and now it’s finally here – The Nuuday ‘22
                      Party
                    </h3>
                    – and YOU are invited.
                    <br />
                    <br />
                    We will have an amazing summer party and it will be a night of delicious
                    festival-food, colorful drinks, and great music together with your friends and
                    colleagues. So, get ready for an entertaining and fun evening and share the
                    Nuuday festival feeling with your great colleagues!
                    <br />
                    <br />
                    The Nuuday ‘22 Party starts at <b>18.00</b> and ends at <b>24.00.</b>
                    <br />
                    <br />
                    <Box className={classes.addressContainer}>
                      <h3>
                        Friday, {location === 'Øst' ? '16th' : ' 23rd '} of September
                        <br />
                        18.00-24.00
                      </h3>
                      <b>
                        {location === 'Øst' ? (
                          <>
                            Docken <br /> Færgehavnsvej 35 <br /> 2150 København
                          </>
                        ) : (
                          <>
                            Savværket <br /> Søren Nymarks Vej 8A <br /> 8270 Højbjerg
                          </>
                        )}
                      </b>
                    </Box>
                    <b>Program</b>
                    {location === 'Øst' ? (
                      <>
                        <ul>
                          <li>18.00: Food stalls and bars with beer, water, soda, and wine open</li>
                          <li> 18.30: Jon James and Jakob Kjeldberg welcome </li>
                          <li>19.00: Lasse Skriver</li>
                          <li>20.00: The bars open for drinks</li>
                          <li> 20.15: Stine Bramsen with band</li>
                          <li> 21:15: Lord Siva </li>
                          <li> 22.30: Burhan G with band </li>
                          <li> 24.00: Thanks for tonight</li>
                          <li> 00.15: Buses run to Nordhavn Station </li>
                        </ul>
                        <i>DJ will play between live performances.</i>
                      </>
                    ) : (
                      <>
                        <ul>
                          <li>17.30 Food stalls and bars with beer, water, wine are open </li>
                          <li> 18.30 Welcome by Jacob Kjeldberg and Jon James </li>
                          <li>19.00: Lasse Skriver</li>
                          <li>20.00: The bars open for drinks</li>
                          <li> 20.15 Ericka Jane on stage </li>
                          <li> 21:15: Lord Siva </li>
                          <li> 22.30 Andreas Odbjerg on stage </li>
                          <li> 24.00: Thanks for tonight</li>
                          <li>
                            00.15 Bus departures to Odense, Kolding, Sønderborg and to Århus C
                            (Banegårdspladsen)
                          </li>
                        </ul>
                        <i>DJ will play between live performances.</i>
                      </>
                    )}
                    <br />
                    <br />
                    If you have any questions, please visit our Q&A. You are also more than welcome
                    to contact your manager, if you have any questions regarding the Nuuday ‘22
                    Party.
                    <br />
                    <br />
                    <b>See you there!</b>
                  </Typography>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {location === 'Øst' ? (
                    <>
                      <h3>Bus transport</h3>
                      <p>
                        Here you can see the departures if you have signed up for bus transport -
                        There is no catering on the buses, but you are welcome to take a can of beer
                        or water with you on the trip:
                      </p>

                      <TableContainer>
                        <Table aria-label="transport table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Departure from</TableCell>
                              <TableCell align="right">To</TableCell>
                              <TableCell align="right">Time</TableCell>
                              <TableCell align="right">Contact if you have any questions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {busTransport.map((row, i) => (
                              <TableRow key={i}>
                                <TableCell component="th" scope="row">
                                  {row.from}
                                </TableCell>
                                <TableCell align="right">{row.to}</TableCell>
                                <TableCell align="right">{row.time}</TableCell>
                                <TableCell align="right">{row.contact}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <p>
                        Please NOTE that all buses from Docken run to <b>Nordhavn Station</b>, where
                        it is possible to continue on by public transport on your own. Shuttle buses
                        from Docken to Nordhavn start approx. 23.20.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3>Bus transport</h3>
                      <p>
                        Here you can see the departures if you have signed up for bus transport .
                        There are sandwiches as well as beer and water in the buses on the way to
                        Savværket from Odense, Kolding and Sønderborg.
                      </p>
                      <b>
                        Shuttlebusses will be going to Sletvej and Aarhus C (Banegårdspladsen) from
                        23.00
                      </b>

                      <TableContainer>
                        <Table aria-label="transport table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Departure from</TableCell>
                              <TableCell align="right">To</TableCell>
                              <TableCell align="right">Time</TableCell>
                              <TableCell align="right">Contact if you have any questions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {busTransport.map((row, i) => (
                              <TableRow key={i}>
                                <TableCell component="th" scope="row">
                                  {row.from}
                                </TableCell>
                                <TableCell align="right">{row.to}</TableCell>
                                <TableCell align="right">{row.time}</TableCell>
                                <TableCell align="right">{row.contact}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <>
                    <h3>Menu</h3>
                    <div className={classes.foodContainer}>
                      <p>
                        There will be 4 festival food stalls with food, which will be easy to eat
                        standing or sitting:
                        <br />
                        Food stalls:
                        <br />
                      </p>
                      <ul>
                        <li>A delicious burger with everything</li>
                        <li> Taco shells with chicken</li>
                        <li> Thai dish with beef</li>
                        <li> Tartlets with chicken</li>
                      </ul>
                      <p>
                        We have informed all the chefs in advance about the comments we have
                        received via registration regarding allergens etc. and you just need to
                        contact the food stall in question regarding allergens
                      </p>
                    </div>
                    <br />
                    <h3>Drinks</h3>
                    <div className={classes.foodContainer}>
                      <p>
                        In the bars you can get beer, water, soft drinks and wine all evening, and
                        from 20.00 you can choose from 5 delicious drinks:
                        <br />
                      </p>
                      <ul>
                        <li>Gin & Tonic</li>
                        <li> Rum & Coke</li>
                        <li> Vodka & Juice</li>
                        <li> DarkNStormy</li>
                        <li> Gin Hass</li>
                      </ul>
                    </div>
                  </>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  {QA.map(({ title, description }, i) => (
                    <ExpansionPanel key={title}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel${i}a-header`}
                      >
                        <Typography variant="h5">{title}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography component="div">
                          {Array.isArray(description) ? (
                            <ul>
                              {description.map((text) => (
                                <li key={text}>{text}</li>
                              ))}
                            </ul>
                          ) : (
                            description
                          )}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                  <br />
                  <b>
                    If you have any further questions regarding the Nuuday ‘22 Party, please contact
                    your manager or send a mail to
                    <a href="mailto:assistants@nuuday.dk"> assistants@nuuday.dk</a>.
                  </b>
                </TabPanel>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      <DialogWrapper
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title="Are you sure you want to unsubscribe from the party"
        maxWidth="sm"
        buttons={
          <>
            <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={cancelInvite}>
              Unsubscribe
            </Button>
          </>
        }
      />
    </Layout>
  )
}
