import React, { useState } from 'react'
// eslint-disable-next-line import/no-unresolved
import { v4 as uuidv4 } from 'uuid'
import { createCustomer } from 'services/sweb/customer'
import { DrawerListItem, Search, PaperToolbar, EnhancedTablePagination } from 'components'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import matchSorter from 'match-sorter'
import { CSVLink } from 'react-csv'
import { DirectionsRunOutlined as RunIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import {
  Collapse,
  Typography,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    padding: '6px 24px 6px 16px !important',
    height: 36,
  },
  tableContainer: {
    maxWidth: '100%',
    overflow: 'auto',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
  },
  buttonLabel: {
    whiteSpace: 'nowrap',
  },
  toolbar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

const ScriptListItem = ({ primary, secondary, scriptName, customerId }) => {
  const classes = useStyles()
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { enqueueSnackbar } = useSnackbar()
  const [scriptResponse, setScriptResponse] = useState(null)
  const { t } = useTranslation()

  const handleRunScript = () => {
    setLoading(true)
    const json = {
      scriptName,
    }
    createCustomer
      .executePsWebScript({ customerId, json })
      .then((response) => {
        if (response.data.response && response.data.response.length > 0) {
          try {
            const parsedResponse = JSON.parse(response.data.response.replace(/(\r\n|\n|\r)/gm, ''))
            if (parsedResponse) {
              setScriptResponse(parsedResponse)
              enqueueSnackbar(t('Trust_success'), {
                variant: 'success',
              })
              setLoading(false)
            }
          } catch (err) {
            enqueueSnackbar(
              t('Trust_failure', { message: 'The script response could not be parsed' }),
              {
                variant: 'error',
              }
            )
            setLoading(false)
          }
        } else {
          // no response
          enqueueSnackbar(t('Trust_failure', { message: 'No parsable response' }), {
            variant: 'error',
          })
          setLoading(false)
        }
      })
      .catch(() => {
        enqueueSnackbar(t('Trust_failure', { message: 'Could not run script' }), {
          variant: 'error',
        })
        setLoading(false)
      })
  }
  const searchedResponse = matchSorter(
    Array.isArray(scriptResponse) ? scriptResponse : [],
    searchTerm,
    {
      keys: Array.isArray(scriptResponse) ? Object.keys(scriptResponse[0]) : [],
    }
  )

  const handleSetSearchTerm = (search) => {
    setSearchTerm(search)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value)
    setPage(0)
  }

  return (
    <>
      <DrawerListItem
        icon={<RunIcon />}
        primaryText={primary}
        isLoading={loading}
        isDisabled={loading}
        secondaryText={secondary}
        linkAnchor={t('Trust_run')}
        onClick={scriptResponse ? () => setScriptResponse(null) : handleRunScript}
        isButton
        selected={Boolean(scriptResponse)}
      />
      <Collapse in={Boolean(scriptResponse)}>
        {!Array.isArray(scriptResponse) &&
          typeof scriptResponse === 'object' &&
          scriptResponse !== null &&
          Object.keys(scriptResponse).map((key) => (
            <li key={uuidv4()}>
              <span>
                {key}: {scriptResponse[key]}
              </span>
            </li>
          ))}
        {Array.isArray(scriptResponse) && scriptResponse.length > 0 && (
          <div className={classes.tableContainer}>
            <PaperToolbar className={classes.toolbar}>
              <Search filter={handleSetSearchTerm} placeholder={t('Router_searche')} />
              <CSVLink data={scriptResponse} className={classes.link}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  classes={{ label: classes.buttonLabel }}
                >
                  Download CSV
                </Button>
              </CSVLink>
            </PaperToolbar>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {Object.keys(scriptResponse[0]).map((key) => {
                    return (
                      <TableCell key={uuidv4()} className={classes.tableCell}>
                        <Typography variant="body2" noWrap>
                          <strong>{key}</strong>
                        </Typography>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedResponse
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={uuidv4()}>
                      {Object.values(row).map((value) => {
                        return (
                          <TableCell key={uuidv4()} className={classes.tableCell}>
                            <Typography variant="body2" noWrap>
                              {String(value)}
                            </Typography>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <EnhancedTablePagination
              arrayLength={searchedResponse.length}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={handleChangePage}
              setRowsPerPage={handleChangeRowsPerPage}
              entriesPerPageText={t('Invoice_rowsperpage')}
            />
          </div>
        )}
      </Collapse>
    </>
  )
}

export default ScriptListItem
