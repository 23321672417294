import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Chip from '@material-ui/core/Chip'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'

import Typography from '@material-ui/core/Typography'
import { IMG_SOMMER_NISSE } from 'constants/cdn'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 32,
    textAlign: 'center',
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
    width: '100%',
  },
  card: {
    border: `1px solid ${theme.palette.primary.main} `,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  attendingChip: {
    margin: '8px 8px 0 auto',
  },
  eventDateChip: {
    marginTop: 16,
  },
}))

export default function PartyCard({ checked, content, onClick }) {
  const { address, title, attending, date } = content || {}
  const classes = useStyles()

  return (
    <Card className={`${classes.root} ${checked ? classes.card : ''}`} onClick={onClick}>
      <CardActionArea className={classes.content}>
        {attending && (
          <Chip className={classes.attendingChip} size="small" label="Attending" color="primary" />
        )}
        <CardMedia className={classes.media} image={IMG_SOMMER_NISSE} title="Party people" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {address}
          </Typography>
          <Chip
            color="primary"
            className={classes.eventDateChip}
            label={date}
            icon={<EventAvailableIcon />}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
