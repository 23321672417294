export default (me, customerId, roles) => {
  if (me.user.role === 'admin') return true
  const authorizationsOnCustomer = me.authorizations.find(
    (auth) => auth.customer_id === Number(customerId)
  )
  if (authorizationsOnCustomer) {
    // eslint-disable-next-line
    for (let i = 0; i < roles.length; i++) {
      // eslint-disable-next-line
      for (let j = 0; j < authorizationsOnCustomer.roles.length; j++) {
        if (roles[i] === authorizationsOnCustomer.roles[j]) {
          return true
        }
      }
    }
  }
  return false
}
