import React from 'react'
import classNames from 'classnames'
import { TextField, InputAdornment, CircularProgress } from '@material-ui/core'
import useInputStyles from 'theme/hooks/useInputStyles'

const SkyTextField = ({
  white = true,
  withMargin = true,
  fullWidth = true,
  inputProps = {},
  loading = false,
  className = '',
  startAdornment = '',
  ...rest
}) => {
  const classes = useInputStyles()

  const root = classNames({
    [classes.root]: true,
    [classes.white]: white,
    className,
  })

  return (
    <div className={withMargin ? classes.withMargin : ''}>
      <TextField
        fullWidth={fullWidth}
        InputProps={{
          startAdornment,
          endAdornment: loading ? (
            <InputAdornment position="end">
              <CircularProgress size={12} />
            </InputAdornment>
          ) : null,
          classes: { root, focused: classes.focused, error: classes.error },
          disableUnderline: true,
          ...inputProps,
        }}
        {...rest}
        variant="filled"
      />
    </div>
  )
}

export default SkyTextField
