import React from 'react'
import { useSnackbar } from 'notistack'
import DrawerListItem from 'components/DrawerListItem'
import { List } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  expired: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
  },
}))

const PresenceInfoModalContent = ({ customerName, startDate, endDate, productName, price }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <List>
      <DrawerListItem primaryText={t('Organization_customer_name')} secondaryText={customerName} />
      <DrawerListItem primaryText={t('Wizard_start_date')} secondaryText={startDate} />
      {endDate && (
        <DrawerListItem
          primaryText={t('Wizard_end_date')}
          secondaryText={<span className={classes.expired}>{endDate}</span>}
        />
      )}
      <DrawerListItem primaryText={t('Hardware_product')} secondaryText={productName} />
      <DrawerListItem primaryText={t('Invoice_price')} secondaryText={price} />
    </List>
  )
}

export default PresenceInfoModalContent
