import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { format } from 'date-fns'
import { Alert, Paper, PaperContent } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  paperContent: {
    margin: theme.spacing(0.5),
  },
  paper: {
    marginBottom: theme.spacing(4),
  },
}))

interface Props {
  goLiveDate: number
}

const GoLiveAlert = ({ goLiveDate }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <PaperContent noPadding className={classes.paperContent}>
        <Alert
          type="error"
          message={t('Layout_notlive', {
            goLiveDate: goLiveDate ? format(goLiveDate, 'dd-MM-yyyy') : 'missing',
          })}
        />
      </PaperContent>
    </Paper>
  )
}

export default GoLiveAlert
