import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'intNumberMediation/FETCH_FAILURE'
export const FETCH_BEGIN = 'intNumberMediation/FETCH_BEGIN'
export const FETCH_SUCCESS = 'intNumberMediation/FETCH_SUCCESS'

export const fetchIntNumberMediationFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchIntNumberMediationBegin = ({ customerId, e164 }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
    e164,
  }
}

export const fetchIntNumberMediationSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchIntNumberMediation = ({ customerId, e164 }) => {
  return async (dispatch) => {
    dispatch(fetchIntNumberMediationBegin({ customerId, e164 }))
    try {
      const response = await getCustomer.intNumberMediation({
        customerId,
        e164,
      })
      dispatch(fetchIntNumberMediationSuccess(response.data))
    } catch (err) {
      dispatch(fetchIntNumberMediationFailure(err.response))
    }
  }
}
