import React, { useState, memo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { createSubscription, deleteSubscription } from 'services/sweb/subscription'
import { useSnackbar } from 'notistack'
import CustomDialog from 'components/CustomDialog'
import { PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import Addons from './Addons'
import StaticMobileAddons from './StaticMobileAddons'
import ShowNumberSkyapp from './ShowNumberSkyapp'

const AddonsAndFeatures = ({
  refetchAddons,
  refetchShowNumberSkyapp,
  mobilePackageIsWorks,
  mobileNumber,
  hasMobileOneNumber,
  customerId,
  abbId,
  disabled,
  includesMusic,
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(null)
  const [dialog, setDialog] = useState({
    open: false,
    title: null,
    message: null,
    callback: null,
  })
  const stored = useSelector(
    (state) => ({
      mobileCallForwarding: state.mobileCallForwarding,
      addons: state.addons,
      showNumberSkyapp: state.showNumberSkyapp,
    }),
    shallowEqual
  )

  const handleOpenDialog = (newDialog) => {
    setDialog(newDialog)
  }
  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }
  const disableAddon = (addon) => {
    handleCloseDialog()
    setLoading(addon.id)
    deleteSubscription
      .addon({
        customerId,
        abbId,
        json: { addon_subscription_id: Number(addon.subscription.addon_subscription_id) },
      })
      .then(() => {
        refetchAddons()
        setLoading(null)
        enqueueSnackbar(t('Drawer_user_addon_deac', { addon: addon.name }), { variant: 'success' })
      })
      .catch((err) => {
        setLoading(null)
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
      })
  }
  const toggleAddon = (event, value) => {
    if (event.target.value && value) {
      setLoading(event.target.value)
      createSubscription
        .addon({
          customerId,
          abbId,
          json: { product_id: Number(event.target.value) },
        })
        .then(() => {
          refetchAddons()
          setLoading(null)
          enqueueSnackbar(t('Drawer_user_addon_act'), { variant: 'success' })
        })
        .catch((err) => {
          setLoading(null)
          err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        })
    } else {
      const chosenAddon = stored.addons.data.addons.find(
        (x) => Number(x.id) === Number(event.target.value)
      )
      if (chosenAddon && chosenAddon.subscription.modifiable.is_modifiable) {
        handleOpenDialog({
          open: true,
          title: t('Drawer_user_addon_deac_prompt', { addon: chosenAddon.name }),
          message: t('Drawer_user_addon_deac_desc'),
          callback: () => disableAddon(chosenAddon),
        })
      } else {
        enqueueSnackbar(t('Drawer_user_addon_in_change'), { variant: 'info' })
      }
    }
  }
  const toggleShowNumberInSkyapp = (event, value) => {
    setLoading('showNumberInSkyApp')
    createSubscription
      .showNumberInSkyapp({ customerId, mobileNumber, json: { is_public: value } })
      .then(() => {
        refetchShowNumberSkyapp()
        setLoading(null)
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(null)
      })
  }

  const showNumberResolved = Boolean(!mobileNumber || stored.showNumberSkyapp.data)
  const callTransferResolved = !hasMobileOneNumber

  let content = <PaperLoader />

  if (showNumberResolved && callTransferResolved && stored.addons.data) {
    content = (
      <>
        <Addons
          addons={stored.addons}
          toggleAddon={toggleAddon}
          loading={loading}
          disabled={disabled}
        />
        {!disabled && (
          <StaticMobileAddons isActive={mobilePackageIsWorks} includesMusic={includesMusic} />
        )}
        {mobileNumber && (
          <ShowNumberSkyapp
            showNumberSkyapp={stored.showNumberSkyapp}
            toggleShowNumberInSkyapp={toggleShowNumberInSkyapp}
            loading={loading === 'showNumberInSkyApp'}
            disabled={disabled}
          />
        )}
        <CustomDialog
          isOpen={dialog.open}
          onConfirm={dialog.callback}
          onCancel={handleCloseDialog}
          title={dialog.title}
          contentText={dialog.message}
        />
      </>
    )
  }

  return content
}

export default memo(AddonsAndFeatures)
