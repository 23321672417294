import React, { useState, useEffect } from 'react'
import { CircularProgress, Switch, Box, Typography } from '@material-ui/core'
import { getSubscription } from 'services/sweb/subscription'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { fetchSkyAppHuntgroups } from 'state/skyAppHuntgroups/actions'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@skytdc/mui/components'
import { DrawerSection } from 'components'

const useStyles = makeStyles(() => ({
  huntgroupContainer: {
    padding: '0 1rem',
    textAlign: 'center',
  },
  huntgroupInfo: {
    marginBottom: '2rem',
  },
}))

const Huntgroups = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const { subId } = useSelector((s) => s.me.data?.activeSubscriptions[0]) || {}
  const skyAppHuntgroups = useSelector((s) => s.skyAppHuntgroups) || {}

  const [selectedGroups, setSelectedGroups] = useState(new Map())

  useEffect(() => {
    if (subId && !skyAppHuntgroups.data) {
      dispatch(fetchSkyAppHuntgroups(subId))
    }
  }, [subId])

  useEffect(() => {
    if (skyAppHuntgroups.data?.huntgroups?.length) {
      setSelectedGroups(new Map(skyAppHuntgroups.data?.huntgroups.map((data) => [data.id, data])))
    }
  }, [skyAppHuntgroups])

  const switchOnchange = ({ id, name, loggedIn }) => {
    getSubscription[loggedIn ? 'huntgroupLogout' : 'huntgroupLogin'](subId, id)
      .then(() => {
        setSelectedGroups(new Map(selectedGroups.set(id, { id, name, loggedIn: !loggedIn })))
        enqueueSnackbar(t('Skyapp_huntgroups_group_activated'), {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar(t('Skyapp_huntgroups_group_failed'), {
          variant: 'error',
        })
      })
  }

  if (skyAppHuntgroups.loading) {
    return <CircularProgress />
  }

  return (
    <div className={classes.huntgroupContainer}>
      <DrawerSection title={t('Skyapp_huntgroups_title')} />
      <Alert type="info" message={t('Skyapp_huntgroups_info')} className={classes.huntgroupInfo} />
      {skyAppHuntgroups.data?.huntgroups?.length
        ? [...selectedGroups.values()].map((data) => (
            <Box
              key={data.id}
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="subtitle1" color={data.loggedIn ? 'primary' : 'textSecondary'}>
                {data.name}
              </Typography>
              <Switch
                edge="end"
                checked={data.loggedIn}
                onChange={() => switchOnchange(data)}
                color="primary"
              />
            </Box>
          ))
        : t('Skyapp_huntgroups_not_found')}
    </div>
  )
}

export default Huntgroups
