import { getUser } from 'services/sweb/user'

export const FETCH_FAILURE = 'licenseDetails/FETCH_FAILURE'
export const FETCH_BEGIN = 'licenseDetails/FETCH_BEGIN'
export const FETCH_SUCCESS = 'licenseDetails/FETCH_SUCCESS'

export const fetchLicenseDetailsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchLicenseDetailsBegin = ({ azureId }) => {
  return {
    type: FETCH_BEGIN,
    azureId,
  }
}

export const fetchLicenseDetailsSuccess = ({ azureId, data }) => {
  return {
    type: FETCH_SUCCESS,
    azureId,
    data,
  }
}

export const fetchLicenseDetails = ({ customerId, azureId }) => {
  return async (dispatch) => {
    dispatch(fetchLicenseDetailsBegin({ azureId }))
    try {
      const response = await getUser.licenseDetails({ customerId, azureId })
      dispatch(fetchLicenseDetailsSuccess({ azureId, data: response.data }))
    } catch (err) {
      dispatch(fetchLicenseDetailsFailure(err.response))
    }
  }
}
