import React, { useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { useDispatch, useSelector, shallowEqual, batch } from 'react-redux'
import { fetchAuthorizations } from 'state/authorizations/actions'
import { fetchAvailableRoles } from 'state/availableRoles/actions'
import { updateUser, deleteUser } from 'services/sweb/user'
import { useSnackbar } from 'notistack'
import { fetchTeamsUsers } from 'state/teamsUsers/actions'
import { format } from 'date-fns'
import AdGroupFilter from 'views/Users/components/AdGroupFilter'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, List } from '@material-ui/core'
import { PaperLoader, PaperToolbar, Search, PaperFeedbackMessage, GradientButton } from 'components'
import { useTranslation } from 'react-i18next'
import UserListItemWithRole from '../UserListItemWithRole'
import StepContainer from '../StepContainer'
import TextBlock from '../TextBlock'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
  },
  userContainer: {
    marginTop: theme.spacing(2),
    maxHeight: '450px',
    overflow: 'auto',
    marginBottom: theme.spacing(4),
  },
  roleContainer: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  headline: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
}))

const searchOptions = {
  shouldSort: true,
  threshold: 0.1,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: [
    'id',
    'mail',
    'givenName',
    'surname',
    'userPrincipalName',
    'mobilePhone',
    'jobTitle',
    'displayName',
  ],
}

const Roles = ({ customerId, goLiveDate, azureTenantId, currentStep, canGoBack, goBack }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [roleLoading, setRoleLoading] = useState(false)
  const [currentRoles, setCurrentRoles] = useState(null)
  const [sorted, setSorted] = useState(false)

  const stored = useSelector(
    (state) => ({
      authorizations: state.authorizations,
      availableRoles: state.availableRoles,
      teamsUsers: state.teamsUsers,
    }),
    shallowEqual
  )

  const filterUsers = (searchTerm) => {
    const fuse = new Fuse(users, searchOptions)
    const result = fuse.search(searchTerm)
    setFilteredUsers(searchTerm.length > 0 ? result : users)
  }

  const addRoleToUser = (azureId, roleName) => {
    setRoleLoading(true)
    updateUser
      .role({ customerId, azureTenantId, azureId, roleName })
      .then(() => {
        enqueueSnackbar(t('Organization_roles_success'), { variant: 'success' })
        setRoleLoading(false)
        dispatch(fetchAuthorizations({ customerId }))
      })
      .catch((err) => {
        err.response.json().then((res) =>
          enqueueSnackbar(t('Organization_roles_error', { error: res.error.text }), {
            variant: 'error',
          })
        )
        setRoleLoading(false)
      })
  }

  const removeRoleFromUser = (azureId, roleName) => {
    setRoleLoading(true)
    deleteUser
      .role({ customerId, azureTenantId, azureId, roleName })
      .then(() => {
        enqueueSnackbar(t('Organization_roles_removed'), { variant: 'success' })
        setRoleLoading(false)
        dispatch(fetchAuthorizations({ customerId }))
      })
      .catch((err) => {
        err.response.json().then((res) =>
          enqueueSnackbar(t('Organization_roles_remove_error', { error: res.error.text }), {
            variant: 'error',
          })
        )
        setRoleLoading(false)
      })
  }

  useEffect(() => {
    batch(() => {
      dispatch(fetchTeamsUsers({ customerId }))
      dispatch(fetchAuthorizations({ customerId }))
      dispatch(fetchAvailableRoles())
    })
  }, [])

  useEffect(() => {
    if (stored.teamsUsers.data) {
      setUsers(stored.teamsUsers.data)
      setFilteredUsers(stored.teamsUsers.data)
    }
  }, [stored.teamsUsers])

  useEffect(() => {
    if (
      !stored.authorizations.loading &&
      stored.authorizations.data &&
      stored.authorizations.data.length > 0
    ) {
      const getCustomer = stored.authorizations.data.find(
        (auth) => auth.customer.customer_id === Number(customerId)
      )
      const getUsersFromTenant =
        azureTenantId &&
        getCustomer &&
        getCustomer.user_authorizations.filter((user) => user.azure_tenant_id === azureTenantId)
      setCurrentRoles(getUsersFromTenant)
      setSorted(true)
    } else {
      setCurrentRoles([])
      setSorted(true)
    }
  }, [stored.authorizations])

  let content = <PaperLoader />

  if (!stored.teamsUsers.loading && stored.teamsUsers.error) {
    content = (
      <PaperFeedbackMessage
        type="error"
        withMargin
        message={t('Organization_roles_users_error', { error: stored.teamsUsers.error.statusText })}
      />
    )
  }

  if (!stored.authorizations.loading && stored.authorizations.error) {
    content = (
      <PaperFeedbackMessage
        type="error"
        message={t('Organization_roles_role_error', {
          error: stored.authorizations.error.statusText,
        })}
      />
    )
  }

  if (!stored.availableRoles.loading && stored.availableRoles.error) {
    content = (
      <PaperFeedbackMessage
        type="error"
        message={t('Organization_roles_avail_roles_error', {
          error: stored.availableRoles.error.statusText,
        })}
      />
    )
  }

  if (!stored.teamsUsers.loading && stored.teamsUsers.data && stored.teamsUsers.data.length === 0) {
    content = <PaperFeedbackMessage message={t('Organization_roles_users_amount', { amount: 0 })} />
  }

  if (users.length > 0 && stored.availableRoles.data && stored.authorizations.data) {
    content = (
      <>
        {currentRoles && currentRoles.length >= 0 ? (
          <>
            {stored.teamsUsers.loading && <PaperLoader />}
            <Typography variant="h5" className={classes.title} gutterBottom>
              {t('Organization_roles_users_amount', { amount: users.length })}
            </Typography>
            <PaperToolbar className={classes.toolbar}>
              <Search placeholder={t('Organization_roles_user_search')} filter={filterUsers} />
              <AdGroupFilter customerId={customerId} combinedUsers={false} />
            </PaperToolbar>
            <List className={classes.userContainer}>
              {filteredUsers.map((user) => {
                return (
                  <UserListItemWithRole
                    name={user.displayName}
                    email={user.mail}
                    key={user.id}
                    azureId={user.id}
                    availableRoles={stored.availableRoles.data}
                    customerId={customerId}
                    currentRoles={currentRoles.filter((auth) => {
                      return auth.azure_id === user.id
                    })}
                    loading={roleLoading || stored.authorizations.loading}
                    addRole={addRoleToUser}
                    removeRole={removeRoleFromUser}
                  />
                )
              })}
            </List>
          </>
        ) : (
          sorted && <PaperFeedbackMessage message={t('Organization_roles_need_role')} />
        )}
      </>
    )
  }

  return (
    <StepContainer
      step={currentStep}
      requiredStep={4}
      finalStep={true}
      stepLabel={t('Wizard_roles')}
    >
      <TextBlock>{t('Wizard_1')}</TextBlock>
      <TextBlock>
        <strong>{t('Wizard_2')}</strong>
      </TextBlock>
      <TextBlock component="span">
        <ul>
          <li>{goLiveDate ? `${format(goLiveDate, 'dd-MM-yyyy')}` : t('Wizard_3')}</li>
        </ul>
      </TextBlock>
      {canGoBack && <GradientButton onClick={goBack}>{t('Wizard_4')}</GradientButton>}
      <TextBlock>
        <strong className={classes.headline}>{t('Wizard_5')}</strong>
        {t('Wizard_6')}
      </TextBlock>
      <TextBlock>{t('Wizard_7')}</TextBlock>
      <TextBlock inputMargin>{t('Wizard_8')}</TextBlock>
      <AdGroupFilter customerId={customerId} combinedUsers={false} asButton />
      <div className={classes.roleContainer}>{content}</div>
    </StepContainer>
  )
}

export default Roles
