import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  TableRow,
  TableCell,
  IconButton,
  // Checkbox,
  Tooltip,
  Typography,
  Button,
} from '@material-ui/core'
import { MarkunreadMailboxOutlined as DeliveryIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  tableCellInner: {
    display: 'flex',
    alignItems: 'center',
  },
  tableCell: {
    borderRight: `1px solid ${theme.palette.background.default}`,
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}))

const SimcardTableRow = ({
  simcard,
  // handleSelectSingleRow,
  isRowSelected,
  reportSimcardMissing,
  loading,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <TableRow hover selected={isRowSelected}>
      {/* <TableCell padding="checkbox">
        <div className={classes.tableCellInner}>
          <Checkbox
            color="primary"
            checked={isRowSelected}
            onClick={() => handleSelectSingleRow(simcard)}
          />
        </div>
  </TableCell> */}
      <TableCell className={classes.tableCell} style={{ paddingLeft: 24 }}>
        {simcard.icc}
      </TableCell>
      <TableCell align="right" className={classes.tableCell}>
        {simcard.pin_code}
      </TableCell>
      <TableCell align="right" className={classes.tableCell}>
        {simcard.puk_code}
      </TableCell>
      <TableCell align="right" className={classes.tableCell}>
        {(simcard.union_id && 'Alle') || simcard.customer_id}
      </TableCell>
      <TableCell align="right" className={classes.tableCell}>
        {(simcard.order && simcard.order.ordered_date) ||
          t('Organization_unassignedsimcards_order_no_data')}
      </TableCell>
      <TableCell align="right" className={classes.tableCell}>
        <Tooltip
          placement="left"
          title={
            <>
              <Typography color="inherit" variant="inherit" display="block">
                {simcard.order && simcard.order.order_sent_to_recipient}
              </Typography>
              <Typography color="inherit" variant="inherit" display="block">
                {simcard.order && simcard.order.order_sent_to_address}
              </Typography>
              <Typography color="inherit" variant="inherit" display="block">
                {simcard.order && simcard.order.order_sent_to_zip}{' '}
                {simcard.order && simcard.order.order_sent_to_city}
              </Typography>
              <Typography color="inherit" variant="inherit" display="block">
                Att.: {simcard.order && simcard.order.order_sent_to_attention}
              </Typography>
              <Typography color="inherit" variant="inherit" display="block">
                {t('Organization_unassignedsimcards_order_number')}:{' '}
                {simcard.order && simcard.order.order_id}
              </Typography>
            </>
          }
          aria-label="See simcard delivery address"
        >
          <IconButton variant="text" edge="end">
            <DeliveryIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        <Button
          size="small"
          variant="text"
          color="primary"
          onClick={() => reportSimcardMissing(simcard.id)}
          disabled={loading === simcard.id}
        >
          {t('Organization_unassignedsimcards_report_lost')}
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default SimcardTableRow
