import { createSubscription } from 'services/partyweb/subscription'

export const FETCH_FAILURE = 'PARTYAllSignUpT/FETCH_FAILURE'
export const FETCH_BEGIN = 'PARTYAllSignUpT/FETCH_BEGIN'
export const FETCH_SUCCESS = 'PARTYAllSignUpT/FETCH_SUCCESS'

export const fetchAllSignUpsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAllSignUpsBegin = ({ partyId }) => {
  return {
    type: FETCH_BEGIN,
    partyId,
  }
}

export const fetchAllSignUpsSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchAllSignUps = ({ partyId }) => {
  return async (dispatch) => {
    dispatch(fetchAllSignUpsBegin({ partyId }))
    try {
      const response = await createSubscription.getSignups({
        partyId,
      })
      dispatch(fetchAllSignUpsSuccess(response.data))
    } catch (err) {
      dispatch(fetchAllSignUpsFailure(err.response))
    }
  }
}
