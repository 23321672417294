import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  removePadding: {
    padding: '0px',
    justifyContent: 'flex-end',
    marginRight: -theme.spacing(2),
  },
}))

const ListWrapper = ({ children }) => {
  const classes = useStyles()

  return (
    <List className={classes.removePadding}>
      <ListItem className={classes.removePadding}>{children}</ListItem>
    </List>
  )
}

export default ListWrapper
