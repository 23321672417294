import ky from 'ky-universal'

import { checkName, checkConnection, checkHeaders, checkStorage } from './util/checks'

import { objKeysToLowercase, getBearerName, getBearerFromStorage } from './util/helpers'

type Storage = {
  storage: 'localStorage' | 'sessionStorage'
  value?: string
}

export type ConnT = Readonly<{ prefixUrl: string; headers?: { [k: string]: string } }>

const addBearerFn = (name: string) => (s: Storage) => {
  const { storage, value } = s
  checkStorage(s.storage)
  const bearerName = getBearerName(name)
  window[storage].setItem(bearerName, value)
  return { storage, name }
}

const removeBearerFn = (name: string) => (s: Storage) => {
  const { storage } = s
  checkStorage(storage)
  const bearerName = getBearerName(name)
  window[storage].removeItem(bearerName)
  return { storage, name }
}

const getBearer = (name: string) => {
  const bearerValue = getBearerFromStorage(name)
  const bearerName = getBearerName(name)
  return { value: bearerValue, name: bearerName }
}

const connectFn = (name: string, invokedConnection: ConnT) => (options: {
  withBearer: boolean
}) => {
  const { prefixUrl, headers = {} } = invokedConnection
  Object.assign(
    // initial headers
    headers,
    objKeysToLowercase(headers) // headers are not case-sensitive, lower to fix
  )
  const hooks = {
    beforeRequest: [
      (opts) => {
        const bearer = getBearerFromStorage(name)
        if (options.withBearer === true && bearer) {
          opts.headers.set('authorization', `Bearer ${bearer}`)
        }
      },
    ],
  }
  return ky.create({ prefixUrl, headers, hooks })
}

const defaultConnection = () => {
  return {
    prefixUrl: null,
    headers: {},
  }
}

const defaultOptions = () => {
  return {
    withBearer: true,
  }
}

export default (name: string, connection: ConnT) => {
  checkName(name)
  checkConnection(connection)

  const defaultConnect = defaultConnection()
  const prefixUrl = connection.prefixUrl || defaultConnect.prefixUrl
  const headers = connection.headers || defaultConnect.headers

  checkHeaders(connection.headers)

  const invokedConnection = Object.freeze({ prefixUrl, headers })
  const selfConnectFn = connectFn(name, invokedConnection)
  const selfOptions = defaultOptions()
  const selfFn = () => {
    return {
      ...selfConnectFn(selfOptions),
      addBearer: ({ storage, value }) => {
        addBearerFn(name)({ storage, value })
        Object.assign(selfOptions, { withBearer: true })
        return selfFn()
      },
      removeBearer: ({ storage }) => {
        removeBearerFn(name)({ storage })
        Object.assign(selfOptions, { withBearer: false })
        return selfFn()
      },
      withoutBearer: () => {
        Object.assign(selfOptions, { withBearer: false })
        return selfFn()
      },
      getBearer: () => {
        return getBearer(name)
      },
    }
  }
  return selfFn()
}
