import React from 'react'
import classNames from 'classnames'
import { ListItem, List, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { KeyboardArrowDown as ChevronDownIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    cursor: 'pointer',
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(),
    paddingRight: 0,
    marginRight: 0,
    marginLeft: theme.spacing(),
  },
  removeHover: {
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'transparent',
      marginRight: theme.spacing(2),
    },
  },
  isButton: {
    '&:hover': {
      '& $primary': {
        color: theme.palette.primary.main,
      },
    },
  },
  listItemTextRoot: {
    flex: '0 1 auto',
    marginTop: theme.spacing(0.5),
    paddingRight: theme.spacing(0.3),
  },
  dense: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  roundAvatar: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.success.main,
  },
  listItemAvatar: {
    minWidth: 40,
  },
  primary: {
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  list: {
    marginRight: theme.spacing(2),
  },
}))

interface Props {
  primaryText: string
  secondaryText?: string
  showText?: boolean
  shape?: 'square' | 'round'
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  dense?: boolean
}

const UserAvatar = ({
  primaryText,
  secondaryText,
  onClick,
  dense = false,
  showText = true,
}: Props) => {
  const classes = useStyles()

  const avatarChar = primaryText.charAt(0)

  const listItemClassName = classNames([classes.listItem], {
    [classes.isButton]: Boolean(onClick),
    // [classes.removeHover]: Boolean(!onClick),
    [classes.dense]: dense,
  })

  return (
    <List disablePadding className={classes.list}>
      <ListItem className={listItemClassName} button onClick={onClick} disableGutters>
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar className={classes.roundAvatar}>{avatarChar}</Avatar>
        </ListItemAvatar>
        {showText && (
          <ListItemText
            primary={primaryText}
            secondary={secondaryText}
            primaryTypographyProps={{
              noWrap: true,
            }}
            secondaryTypographyProps={{
              noWrap: true,
            }}
            classes={{
              root: classes.listItemTextRoot,
              primary: classes.primary,
            }}
          />
        )}
        {Boolean(onClick) && <ChevronDownIcon color="secondary" />}
      </ListItem>
    </List>
  )
}

export default UserAvatar
