import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'oCNumbersProducts/FETCH_FAILURE'
export const FETCH_BEGIN = 'oCNumbersProducts/FETCH_BEGIN'
export const FETCH_SUCCESS = 'oCNumbersProducts/FETCH_SUCCESS'

export const fetchOCNumbersProductsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchOCNumbersProductsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchOCNumbersProductsSuccess = ({ customerId, data }) => {
  return {
    type: FETCH_SUCCESS,
    customerId,
    data,
  }
}

export const fetchOCNumbersProducts = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchOCNumbersProductsBegin({ customerId }))
    try {
      const response = await getCustomer.oCNumbersProducts({ customerId })
      dispatch(fetchOCNumbersProductsSuccess({ customerId, data: response.data }))
    } catch (err) {
      dispatch(fetchOCNumbersProductsFailure(err.response))
    }
  }
}
