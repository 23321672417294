import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'none',
  },
  noContentPadding: {
    padding: 0,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export interface DialogProps {
  isOpen: boolean
  title: string
  onCancel: () => void
  closeButtonText?: string
  children?: ReactNode
  onConfirm?: React.ReactChild | (() => void)
  contentText?: string
  maxWidth?: 'sm' | 'xs' | 'md' | 'lg' | 'xl'
  confirmButtonText?: string
  withDividers?: boolean
  confirmDisabled?: boolean
  contentPadding?: boolean
  dialogActions?: ReactNode
  withDialogActions?: boolean
  hideButtons?: boolean
  submitting?: boolean
}

const CustomDialog = (props: DialogProps) => {
  const {
    isOpen,
    children,
    onConfirm,
    title,
    contentText = '',
    maxWidth = 'sm',
    closeButtonText,
    confirmButtonText,
    withDividers = false,
    onCancel,
    hideButtons,
    confirmDisabled = false,
    contentPadding = true,
    dialogActions,
    withDialogActions = true,
    submitting = false,
    ...rest
  } = props
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Dialog open={isOpen} fullWidth maxWidth={maxWidth} {...rest} onClose={onCancel}>
      {title && <DialogTitle disableTypography>{title}</DialogTitle>}
      <DialogContent
        dividers={withDividers}
        className={contentPadding ? '' : classes.noContentPadding}
      >
        {contentText && <DialogContentText>{contentText}</DialogContentText>}
        {children}
      </DialogContent>

      {withDialogActions && (
        <DialogActions>
          {dialogActions || (
            <>
              {!hideButtons && (
                <Button onClick={onCancel} color="primary" className={classes.button}>
                  {closeButtonText || t('Drawer_create_sub_cancel')}
                </Button>
              )}
              {onConfirm && React.isValidElement(onConfirm) ? (
                onConfirm
              ) : (
                <Button
                  onClick={onConfirm as () => void}
                  color="primary"
                  className={classes.button}
                  disabled={confirmDisabled}
                >
                  {submitting && <CircularProgress size={18} className={classes.buttonProgress} />}
                  {confirmButtonText || t('Router_deac')}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default CustomDialog
