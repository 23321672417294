import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Tooltip,
  Typography,
  IconButton,
} from '@material-ui/core'
import { SimCardOutlined as MobileIcon, NoSimOutlined as NoMobileIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import MobileQuickMenu from './MobileQuickMenu'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  activeColor: {
    color: theme.palette.success.main,
  },
  inactiveColor: {
    color: theme.palette.warning.main,
  },
  removePadding: {
    paddingLeft: 0,
  },
  heading: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
  },
  root: {
    marginTop: '3px',
  },
  button: {
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'none',
  },
  secondary: {
    maxWidth: '84%',
  },
  isMobile: {
    marginRight: theme.spacing(2),
  },
  isButton: {
    marginLeft: -theme.spacing(1),
  },
}))

const MobileTableCell = ({
  mobileNumber,
  mobilePackage,
  simCards,
  isMobile,
  popUserAction,
  disableButton,
  mobileOneNumberShadowNumber,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const customer = useSelector((s) => s.customer)
  const broadworks = customer?.data?.customer_properties?.broadworks

  return (
    <ListItem className={classes.removePadding} disabled={broadworks}>
      <ListItemIcon
        className={classNames({
          [classes.isMobile]: isMobile,
        })}
      >
        {mobileNumber ? (
          <Tooltip
            placement="top"
            title={
              simCards.map((icc) => {
                return (
                  <Typography key={icc} color="inherit" variant="inherit" display="block">
                    - ICC: {icc}
                  </Typography>
                )
              }) || ''
            }
            aria-label="See sim card ICC numbers for this user"
          >
            <IconButton edge="start">
              <MobileIcon className={classes.activeColor} />
            </IconButton>
          </Tooltip>
        ) : (
          <NoMobileIcon className={classes.inactiveColor} />
        )}
      </ListItemIcon>
      <ListItemText
        className={classNames({
          [classes.isButton]: !mobileNumber,
        })}
        classes={{
          primary: classes.heading,
          secondary: classes.secondary,
          root: classes.root,
        }}
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
        primary={mobileNumber}
        secondary={
          (mobilePackage && mobilePackage.current && mobilePackage.current.abb_name) ||
          (!mobileNumber && (
            <Button
              color="primary"
              disabled={broadworks || disableButton}
              className={classes.button}
              onClick={(event) => popUserAction(event, 'opret-abonnement')}
            >
              {t('Drawer_create_sub_mobile_title')}
            </Button>
          ))
        }
      />
      {mobileNumber && !disableButton && (
        <ListItemSecondaryAction>
          <MobileQuickMenu
            popUserAction={popUserAction}
            mobileOneNumberShadowNumber={mobileOneNumberShadowNumber}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}

export default MobileTableCell
