import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch, shallowEqual, batch } from 'react-redux'
import { CircularProgress, Switch, Box, Typography } from '@material-ui/core'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { DrawerSection, SearchableSelect } from 'components'
import { Alert } from '@skytdc/mui/components'

import { useTranslation } from 'react-i18next'
import { updateSubscription } from 'services/sweb/subscription'
import { useSnackbar } from 'notistack'

import { fetchEndUserSubscription } from 'state/endUserSubscription/actions'
import { fetchDisplayNumberWeeklySchedule } from 'state/displayNumberWeeklySchedule/actions'
import { fetchDisplayNumberAvailable } from 'state/displayNumberAvailable/actions'
import { fetchDisplayNumberActive } from 'state/displayNumberActive/actions'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  displayNumberContainer: {
    padding: '0 1rem',
  },
  displayNumberRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 0',
    borderBottom: `1px solid ${theme.palette.primary.light}`,

    '&:last-of-type': {
      marginBottom: '5rem',
    },
  },
  infoMessage: {
    marginBottom: '1rem',
  },
  notFound: {
    textAlign: 'center',
  },
}))

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const DisplayNumber = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const didMountRef = useRef(false)

  const stored = useSelector(
    (state) => ({
      me: state.me,
      endUserSubscription: state.endUserSubscription,
      displayNumberAvailable: state.displayNumberAvailable,
      displayNumberActive: state.displayNumberActive,
      displayNumberWeeklySchedule: state.displayNumberWeeklySchedule,
    }),
    shallowEqual
  )

  const [selectedNumber, setSelectedNumber] = useState(null)
  const [updateWeeklySchedule, setUpdateWeeklySchedule] = useState(false)
  const [selectedTimes, setSelectedTimes] = useState<{ start: Date; end: Date }>({
    start: new Date(),
    end: null,
  })
  const [selectedDailies, setSelectedDailies] = useState<{ day: string }[]>([])

  const [subscription] = stored.me.data.activeSubscriptions
  const abbId = subscription && subscription.subId

  const mobileNumber = stored.endUserSubscription?.data?.mobile_number

  const formatTime = (date: Date) => {
    const time = new Intl.DateTimeFormat('da-DA', { hour: 'numeric', minute: 'numeric' }).format(
      date
    )
    const [hour, minute] = time.split('.')
    return { hour, minute }
  }

  const formatToDate = ({ hour, minute }) => {
    const date = new Date()

    date.setHours(hour)
    date.setMinutes(minute)

    return date
  }

  useEffect(() => {
    if (abbId) {
      if (!stored.endUserSubscription.data) {
        dispatch(fetchEndUserSubscription({ abbId }))
      }
    }
  }, [abbId])

  useEffect(() => {
    if (mobileNumber) {
      batch(() => {
        dispatch(fetchDisplayNumberWeeklySchedule(mobileNumber))
        dispatch(fetchDisplayNumberAvailable(mobileNumber))
        dispatch(fetchDisplayNumberActive(mobileNumber))
      })
    }
  }, [mobileNumber])

  useEffect(() => {
    if (stored.displayNumberActive.data?.number) {
      setSelectedNumber({
        value: stored.displayNumberActive.data?.number,
        label: stored.displayNumberActive.data?.number,
      })
    }
  }, [stored.displayNumberActive])

  useEffect(() => {
    if (stored.displayNumberWeeklySchedule.data) {
      const startTime = formatToDate(stored.displayNumberWeeklySchedule.data?.defaultEnableTime)
      const endTime = formatToDate(stored.displayNumberWeeklySchedule.data?.defaultDisableTime)
      setSelectedTimes({ start: startTime, end: endTime })
      setSelectedDailies(stored.displayNumberWeeklySchedule.data?.dailies)
    }
  }, [stored.displayNumberWeeklySchedule])

  const handleDateChange = (name: string, date: Date) => {
    setUpdateWeeklySchedule(true)

    setSelectedTimes((prev) => ({ ...prev, [name]: date }))
  }

  const setWeeklySchedule = () => {
    const startTime = formatTime(selectedTimes?.start)
    const endTime = formatTime(selectedTimes?.end)

    const json = {
      accountPhoneNumber: {
        countryCode: 45,
        nationalNumber: mobileNumber,
      },
      dailies: selectedDailies,
      defaultEnableTime: {
        hour: startTime.hour,
        minute: startTime.minute,
      },
      defaultDisableTime: {
        hour: endTime.hour,
        minute: endTime.minute,
      },
      defaultPhoneNumberEnabled: {
        countryCode: 45,
        nationalNumber: selectedNumber?.value,
      },
      defaultPhoneNumberDisabled: {
        countryCode: 45,
        nationalNumber: mobileNumber,
      },
      zoneId: 'Europe/Copenhagen',
    }

    updateSubscription
      .displayNumberSetWeeklySchedule(mobileNumber, json)
      .then(() => {
        setUpdateWeeklySchedule(false)
        enqueueSnackbar(t('Skyapp_huntgroups_updated_schedule'), {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar(t('Skyapp_huntgroups_updated_schedule_error'), {
          variant: 'error',
        })
      })
  }

  const switchOnchange = (weekDay: string, checked: boolean) => {
    setUpdateWeeklySchedule(true)
    if (checked) {
      setSelectedDailies((prev) => [...prev, { day: weekDay }])
    } else {
      setSelectedDailies((prev) => prev.filter(({ day }) => day !== weekDay))
    }
  }

  const disableWeeklySchedule =
    stored.displayNumberWeeklySchedule.loading || mobileNumber === selectedNumber?.value

  const endTimeIsLaterThanStart = selectedTimes?.end <= selectedTimes?.start

  useEffect(() => {
    if (
      didMountRef.current &&
      !disableWeeklySchedule &&
      !endTimeIsLaterThanStart &&
      updateWeeklySchedule
    ) {
      setWeeklySchedule()
    }
    didMountRef.current = true
  }, [selectedTimes, selectedDailies, selectedNumber, updateWeeklySchedule])

  const handleSelectedNumber = (option: { value: string; label: string }) => {
    setSelectedNumber(option)

    updateSubscription
      .displayNumberSetActive(mobileNumber, option.value)
      .then(() => {
        setUpdateWeeklySchedule(true)
      })
      .catch(() => {
        enqueueSnackbar(t('Skyapp_huntgroups_updated_schedule_error'), {
          variant: 'error',
        })
      })
  }

  if (
    stored.displayNumberWeeklySchedule?.loading ||
    stored.displayNumberAvailable?.loading ||
    stored.me?.loading ||
    stored.displayNumberActive?.loading ||
    stored.endUserSubscription?.loading
  ) {
    return <CircularProgress />
  }

  if (!mobileNumber || stored?.displayNumberAvailable?.data?.length < 2) {
    return <div className={classes.notFound}>Intet mobilenummer fundet </div>
  }

  return (
    <div className={classes.displayNumberContainer}>
      <DrawerSection title={t('Skyapp_display_title_number')} />
      <Alert
        type="info"
        message={t('Skyapp_huntgroups_helper_text')}
        className={classes.infoMessage}
      />
      <SearchableSelect
        label={t('Drawer_display_choose')}
        value={selectedNumber}
        onChange={(option) => {
          handleSelectedNumber(option)
        }}
        error={stored.displayNumberAvailable.error || stored.displayNumberActive.error}
        disabled={stored.displayNumberAvailable.loading || stored.displayNumberActive.loading}
        isLoading={stored.displayNumberAvailable.loading || stored.displayNumberActive.loading}
        options={[
          {
            label: t('Organization_orders_drawer_mobile'),
            options: [
              {
                label: mobileNumber,
                value: mobileNumber,
              },
            ],
          },
          {
            label: t('Drawer_display_group_numbers'),
            options:
              stored?.displayNumberAvailable?.data
                ?.map(
                  ({ phoneNumber, numberType }) =>
                    numberType !== 'PRIVATE_MOBILE' && {
                      label: phoneNumber.nationalNumber,
                      type: numberType,
                      value: phoneNumber.nationalNumber,
                    }
                )
                .filter(Boolean) || [],
          },
        ]}
      />
      <DrawerSection title={t('Skyapp_display_title_time')} />
      <Box className={classes.displayNumberRow}>
        <KeyboardTimePicker
          ampm={false}
          value={selectedTimes?.start}
          onChange={(date: Date) => handleDateChange('start', date)}
          label={t('Skyapp_display_start_time')}
          disabled={disableWeeklySchedule}
        />
        <KeyboardTimePicker
          ampm={false}
          value={selectedTimes?.end}
          onChange={(date: Date) => handleDateChange('end', date)}
          label={t('Skyapp_display_end_time')}
          disabled={disableWeeklySchedule}
          error={endTimeIsLaterThanStart}
        />
      </Box>
      {endTimeIsLaterThanStart && (
        <Alert type="error" message={t('Skyapp_display_end_before_start')} />
      )}
      <DrawerSection title={t('Skyapp_display_title_days')} />
      {days.map((weekDay) => {
        const checked = selectedDailies.some(({ day }) => day === weekDay)
        return (
          <Box key={`m-${weekDay}`} className={classes.displayNumberRow}>
            <Typography
              variant="subtitle2"
              color={checked && !disableWeeklySchedule ? 'primary' : 'textSecondary'}
            >
              {t(`Skyapp_display_number_${weekDay}`)}
            </Typography>
            <Switch
              edge="end"
              checked={checked}
              onChange={(e) => switchOnchange(weekDay, e.target.checked)}
              color="primary"
              disabled={disableWeeklySchedule}
            />
          </Box>
        )
      })}
    </div>
  )
}

export default DisplayNumber
