import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceSubscriptions/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceSubscriptions/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceSubscriptions/FETCH_SUCCESS'

export const fetchInvoiceSubscriptionsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceSubscriptionsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchInvoiceSubscriptionsSuccess = ({ customerId, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    customerId,
  }
}

export const fetchInvoiceSubscriptions = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceSubscriptionsBegin({ customerId }))
    try {
      const response = await getInvoice.subscriptions({
        customerId,
      })
      dispatch(fetchInvoiceSubscriptionsSuccess({ customerId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceSubscriptionsFailure(err.response))
    }
  }
}
