/**
  constants in which have been defined during build time and should in most cases be avoided as they will be hard bundled in the compiled code thus not "configurable".
  the build constants are defined via webpack (definePlugin 'https://webpack.js.org/plugins/define-plugin/')
 */

declare const SSFAPP_BUILD: {
  MODE: string
  PKG_NAME: string
  PKG_VERSION: string
  SENTRY_RELEASE: string
}

export const { MODE, PKG_NAME, PKG_VERSION, SENTRY_RELEASE } = SSFAPP_BUILD
