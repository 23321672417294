export default (statistics, features) => {
  if (!statistics) return
  const allData = []
  const orderedByMonths = statistics.reduce((acc, value) => {
    const yearMonth = value.reportDate.substring(0, 7)
    ;(acc[yearMonth] = acc[yearMonth] || []).push(value)
    return acc
  }, {})

  for (let { name, label, backgroundColor } of features) {
    const data = []
    for (const element of Object.values(orderedByMonths)) {
      const monthResult = element
        .map((val) => val[name])
        .reduce((acc, obj) => {
          return acc + obj
        })
      data.push(monthResult)
    }
    allData.push({
      labels: Object.keys(orderedByMonths),
      datasets: [{ label, data, backgroundColor }],
    })
  }
  return allData
}
