import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { TableHead, TableRow, TableCell, TableSortLabel, Hidden } from '@material-ui/core'
import SelectedRowsActions from './SelectedRowsActions'

const useStyles = makeStyles(() => ({
  checkbox: {
    marginLeft: '16px',
  },
  cell: {
    whiteSpace: 'nowrap',
  },
}))

const SimcardTableHead = (props) => {
  const classes = useStyles()
  const {
    // handleSelectAllRows,
    reportMissing,
    order,
    orderBy,
    selectedRows,
    // rowCount,
    setOrder,
    setOrderBy,
  } = props
  const { t } = useTranslation()

  const headerRows = [
    {
      id: 'icc',
      numeric: false,
      disablePadding: true,
      label: t('Organization_unassignedsimcards_icc'),
      hideOnMobile: false,
    },
    {
      id: 'pin_code',
      numeric: true,
      disablePadding: false,
      label: t('Organization_unassignedsimcards_pin'),
      hideOnMobile: false,
    },
    {
      id: 'puk_code',
      numeric: true,
      disablePadding: false,
      label: t('Organization_unassignedsimcards_puk'),
      hideOnMobile: false,
    },
    {
      id: 'customer_id',
      numeric: true,
      disablePadding: false,
      label: t('Organization_unassignedsimcards_customer_id'),
      hideOnMobile: false,
    },
    {
      id: 'order.ordered_date',
      numeric: true,
      disablePadding: false,
      label: t('Organization_unassignedsimcards_ordered_date'),
      hideOnMobile: false,
    },
    {
      id: 'order',
      numeric: true,
      disablePadding: false,
      label: t('Organization_unassignedsimcards_delivery_address'),
      hideOnMobile: false,
    },
  ]

  const handleRequestSort = (property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="none">
          <Checkbox
            color="primary"
            className={classes.checkbox}
            checked={selectedRows.length === rowCount}
            indeterminate={selectedRows.length > 0 && selectedRows.length < rowCount}
            onChange={handleSelectAllRows}
            inputProps={{
              'aria-label': 'Select all rows to perform bulk actions',
            }}
          />
          </TableCell> */}
        {selectedRows.length > 0 ? (
          <TableCell colSpan={9} padding="none">
            <SelectedRowsActions selectedRows={selectedRows} reportMissing={reportMissing} />
          </TableCell>
        ) : (
          headerRows.map((row) => (
            <Hidden xsDown={row.hideOnMobile} key={row.id}>
              <TableCell
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                className={classNames([classes.cell], {
                  [classes.firstCell]: row.firstCell,
                })}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={() => handleRequestSort(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            </Hidden>
          ))
        )}
        {selectedRows.length === 0 && <TableCell />}
      </TableRow>
    </TableHead>
  )
}

export default SimcardTableHead
