import React, { useState } from 'react'
import { createCustomer, updateCustomer } from 'services/sweb/customer'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { format } from 'date-fns'
import { GradientButton, SkyTextField, SkyDatePicker } from 'components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
  },
}))

const AppSecretModal = ({
  isOpen,
  closeDialog,
  tenant,
  customerId,
  fetchSecrets,
  hasSecrets,
  presencePage,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    clientId: '',
    clientSecret: '',
    notBefore: null,
    notAfter: null,
  })

  const handleSetForm = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleNotBeforeDate = (date) => {
    setForm({ ...form, notBefore: date })
  }

  const handleNotAfterDate = (date) => {
    setForm({ ...form, notAfter: date })
  }

  const handleAddSecret = () => {
    setLoading(true)
    if (hasSecrets) {
      const json = {
        clientId: form.clientId,
        clientSecret: form.clientSecret,
        notAfter: format(form.notAfter, 'yyyy-MM-dd'),
        tenantName: `${tenant.name}.onmicrosoft.com`,
        tenantId: tenant.id,
      }
      updateCustomer[presencePage ? 'presenceAppSecret' : 'azureAppSecret']({ customerId, json })
        .then(() => {
          setLoading(false)
          fetchSecrets()
          closeDialog()
        })
        .catch((err) => {
          err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
          setLoading(false)
        })
    } else {
      const json = {
        ...form,
        notBefore: format(form.notBefore, 'yyyy-MM-dd'),
        notAfter: format(form.notAfter, 'yyyy-MM-dd'),
        tenantName: `${tenant.name}.onmicrosoft.com`,
        tenantId: tenant.id,
      }
      createCustomer[presencePage ? 'presenceAppSecret' : 'azureAppSecret']({ customerId, json })
        .then(() => {
          setLoading(false)
          fetchSecrets()
          closeDialog()
        })
        .catch((err) => {
          err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
          setLoading(false)
        })
    }
  }

  const hasValues = Boolean(
    form.clientId.length > 0 && form.clientSecret.length > 2 && form.notAfter && form.notBefore
  )

  const hasReplaceValues = Boolean(
    form.clientId.length > 0 && form.clientSecret.length > 2 && form.notAfter
  )

  const disableButton = (hasSecrets && !hasReplaceValues) || (!hasSecrets && !hasValues)

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={closeDialog}>
      <DialogTitle disableTypography>{t('Wizard_app')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="secondary" className={classes.input}>
          {t('Wizard_app_desc')}
        </Typography>
        {hasSecrets && (
          <Alert severity="info" style={{ marginBottom: 16 }}>
            {t('Replace_message')}
          </Alert>
        )}
        <SkyTextField
          value={form.clientId}
          name="clientId"
          onChange={handleSetForm}
          disabled={loading}
          label="Client ID"
        />
        <SkyTextField
          value={form.clientSecret}
          name="clientSecret"
          onChange={handleSetForm}
          disabled={loading}
          label="Client Secret"
        />
        {!hasSecrets && (
          <SkyDatePicker
            label={t('Wizard_expiredatefrom')}
            value={form.notBefore}
            disablePast
            disabled={loading}
            onChange={handleNotBeforeDate}
          />
        )}
        <SkyDatePicker
          label={t('Wizard_expiredateto')}
          value={form.notAfter}
          disablePast
          disabled={loading}
          onChange={handleNotAfterDate}
        />
        <Alert severity="warning">
          {t('Wizard_secret_warning', {
            productName: presencePage ? 'Teams Telephony Presence' : 'Azure AD',
          })}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {t('Wizard_cancel')}
        </Button>
        <GradientButton onClick={handleAddSecret} loading={loading} disabled={disableButton}>
          {t('Wizard_add')}
        </GradientButton>
      </DialogActions>
    </Dialog>
  )
}

export default AppSecretModal
