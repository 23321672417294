import React, { useEffect } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSelector, useDispatch, batch } from 'react-redux'
import { fetchPortfolio } from 'state/portfolio/actions'
import { fetchLicenseDetails } from 'state/licenseDetails/actions'
import { PaperLoader } from 'components'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import FormContainer from './FormContainer'

const UpgradeSubscription = ({ user, subscription, customerId, handleClose, title }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const stored = useSelector((state) => ({
    portfolio: state.portfolio,
    licenseDetails: state.licenseDetails,
  }))

  const [portfolio, licenseDetails] = [stored.portfolio.data, stored.licenseDetails.data]

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    batch(() => {
      dispatch(fetchPortfolio({ customerId }))
      dispatch(fetchLicenseDetails({ customerId, azureId: user.azureId }))
    })
  }, [])

  const hasData =
    stored.licenseDetails.azureId === user.azureId &&
    portfolio &&
    licenseDetails &&
    !stored.portfolio.loading &&
    !stored.licenseDetails.loading
  const userCanVoiceEnable = licenseDetails && licenseDetails.allowVoiceEnable
  const hasMobileSubscription = Boolean(subscription.mobile_number)
  const portfolioSupportsUpgrade = Boolean(
    portfolio && portfolio.base_product.mobile_and_landline.product_details.length > 0
  )

  if (hasData && portfolioSupportsUpgrade && hasMobileSubscription && !userCanVoiceEnable) {
    return <Typography>{t('Drawer_upgrade_no_license', { name: user.name })}</Typography>
  }

  if (hasData && portfolioSupportsUpgrade) {
    return (
      <FormContainer
        user={user}
        customerId={customerId}
        abbId={subscription.abb_id}
        handleClose={handleClose}
        mobilePackages={portfolio.mobile_packages}
        baseProducts={portfolio.base_product}
        hasMobileSubscription={hasMobileSubscription}
        currentBaseSubscription={subscription.invoice.base_subscription}
        currentMobilePackage={subscription.mobile_package && subscription.mobile_package.current}
      />
    )
  }

  if (hasData && !portfolioSupportsUpgrade) {
    return <Typography>{t('Drawer_upgrade_misconfig')}</Typography>
  }

  return <PaperLoader />
}

export default UpgradeSubscription
