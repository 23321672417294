import React from 'react'
import { PaperFeedbackMessage, PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import TableWithFilteredTotal from '../../components/TableWithFilteredTotal'

const AbroadCallsTable = ({ data, invoiceId, type }) => {
  const { t } = useTranslation()
  const invoiceIdsMatch = Boolean(data.invoiceId === invoiceId)

  const columns = [
    {
      name: 'description',
      label: t('Organization_avail_numbers_td3'),
      align: 'left',
      options: {
        sortDirection: 'asc',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'number_calls',
      label: t('Invoice_calls'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'duration.formatted',
      label: t('Invoice_duration'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
  ]

  if (data.error && invoiceIdsMatch) {
    return (
      <PaperFeedbackMessage
        message={t('Invoice_error', { error: data.error.statusText })}
        type="error"
        withMargin
      />
    )
  }

  if (data.data && data.data.items.length === 0 && invoiceIdsMatch) {
    return <PaperFeedbackMessage message={t('Invoice_no_occurences')} withMargin />
  }

  if (data.data && data.data.items.length > 0 && invoiceIdsMatch) {
    return (
      <TableWithFilteredTotal
        items={data.data.items}
        firstTableColumns={columns}
        totalPrice={
          type === 'landline'
            ? data.data.invoice.international_fixed
            : data.data.invoice.international_mobile
        }
      />
    )
  }

  return <PaperLoader />
}

export default AbroadCallsTable
