import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Menu,
  MenuItem,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons'
import BulkActionDialog from '../BulkActionDialog'

const useStyles = makeStyles((theme) => ({
  selectedUsers: {
    fontSize: theme.typography.h5.fontSize,
  },
  listItemText: {
    paddingLeft: '10px',
  },
  toolbarList: {
    marginLeft: '-10px',
    padding: '0px',
  },
  dropdownButton: {
    marginLeft: theme.spacing(2),
  },
  tableCell: {
    paddingLeft: 0,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const initialBulkDialog = {
  open: false,
  title: '',
  description: '',
  action: '',
}

const getBulkDialogAction = (action): typeof initialBulkDialog => {
  switch (action) {
    case 'set-available-group-numbers':
      return {
        open: true,
        title: 'BulkUpdateGroupNumbersTitle',
        description: 'BulkUpdateGroupNumbersDescription',
        action,
      }
    case 'set-mobile-call-forwarding':
      return {
        open: true,
        title: 'Bulk_mobile_forwarding_title',
        description: 'Bulk_mobile_forwarding_desc',
        action,
      }
    case 'set-display-number':
      return {
        open: true,
        title: 'Bulk_set_display_number',
        description: 'Bulk_set_display_number_desc',
        action,
      }
    case 'change-mobile-package':
      return {
        open: true,
        title: 'Drawer_mobile_package_title',
        description: 'Skift maowiermwoei fmwaoei mfawieof ',
        action,
      }
    case 'cancel-subscription':
      return {
        open: true,
        title: 'Nedlæg abonnement',
        description: 'Skift maowiermwoei fmwaoei mfawieof ',
        action,
      }
    default:
      return initialBulkDialog
  }
}

const SelectedToolbar = ({ selectedUsers, totalCount, utilityIcon, customerId }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [bulkDialog, setBulkDialog] = useState<typeof initialBulkDialog | null>(null)

  const handleOpenActionMenu = (event) => setAnchorEl(event.currentTarget)

  const handleCloseActionMenu = () => setAnchorEl(null)

  const handleOpenBulkDialog = (action) => {
    setAnchorEl(null)
    setBulkDialog(getBulkDialogAction(action))
  }

  const handleCloseBulkDialog = () => setBulkDialog(initialBulkDialog)

  return (
    <>
      <TableHead>
        <TableRow>
          {utilityIcon && (
            <TableCell padding="none" style={{ width: 10 }}>
              {utilityIcon}
            </TableCell>
          )}
          <TableCell colSpan={5} className={classes.tableCell}>
            <div className={classes.container}>
              <Typography>
                <strong>
                  {selectedUsers.length}/{totalCount} {t('Selected')}
                </strong>
              </Typography>
              <Button
                className={classes.dropdownButton}
                variant="outlined"
                color="primary"
                onClick={handleOpenActionMenu}
                endIcon={<ArrowDropDownIcon />}
              >
                {t('Actions')}
              </Button>
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseActionMenu}>
        <MenuItem onClick={() => handleOpenBulkDialog('set-display-number')}>
          {t('Bulk_set_display_number')}
        </MenuItem>
        <MenuItem onClick={() => handleOpenBulkDialog('set-available-group-numbers')}>
          {t('BulkUpdateGroupNumbersTitle')}
        </MenuItem>
      </Menu>
      {bulkDialog && (
        <BulkActionDialog
          {...bulkDialog}
          handleClose={handleCloseBulkDialog}
          customerId={customerId}
          selectedUsers={selectedUsers}
        />
      )}
    </>
  )
}

export default SelectedToolbar
