import React, { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  text: {
    lineHeight: '22px',
    marginBottom: theme.spacing(2),
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    padding: theme.spacing(3),
  },
}))

const LinkComponent = forwardRef((props, ref) => <RouterLink {...props} innerRef={ref} />)

const CallToActionListItem = ({ description, link, ctaText, isDisabled = false }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography variant="body2" className={classes.text}>
        {description}
      </Typography>
      {ctaText && (
        <Button
          fullWidth
          component={link ? LinkComponent : 'div'}
          to={link}
          disabled={isDisabled}
          variant="outlined"
          color="primary"
        >
          {ctaText}
        </Button>
      )}
    </div>
  )
}

export default CallToActionListItem
