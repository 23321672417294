import palette from '../palette'

export default {
  root: {
    backgroundColor: palette.background.default,
    color: palette.text.secondary,
  },
  avatar: {
    backgroundColor: '#eaeaea',
    color: palette.text.secondary,
  },
  colorSecondary: {
    backgroundColor: '#bdbdbd',
    '&:hover, &:focus, &:active': {
      backgroundColor: '#bdbdbd',
    },
  },
  avatarColorSecondary: {
    backgroundColor: palette.primary.main,
    '&:focus, &:active': {
      backgroundColor: palette.primary.main,
    },
  },
  labelSmall: {
    paddingLeft: '12px',
    paddingRight: '14px',
    fontSize: '12px',
  },
  clickableColorSecondary: {
    '&:hover, &:focus, &:active': {
      backgroundColor: '#bdbdbd',
    },
  },
  deletable: {
    '&:focus': {
      backgroundColor: palette.primary.light,
    },
  },
  outlined: {
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
  },
}
