import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    margin: theme.spacing(3),
  },
  legend: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.6),
  },
}))

const SpecificationRadios = ({ specificationType, setSpecificationType }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleChange = (event) => {
    setSpecificationType(event.target.value)
  }

  return (
    <div>
      <FormControl component="fieldset" className={classes.radioGroup}>
        <FormLabel component="legend" className={classes.legend}>
          {t('Invoice_show')}
        </FormLabel>
        <RadioGroup
          aria-label="specifications"
          name="specificationtype"
          value={specificationType}
          onChange={handleChange}
        >
          <FormControlLabel
            value="summary"
            control={<Radio color="primary" />}
            label={t('Invoice_summary')}
          />
          <FormControlLabel
            value="category"
            control={<Radio color="primary" />}
            label={t('Invoice_categories')}
          />
          <FormControlLabel
            value="abroad"
            control={<Radio color="primary" />}
            label={t('Invoice_abroad')}
          />
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default SpecificationRadios
