import React from 'react'
import { TextField, Button, Typography } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'

const UserPincode = ({ onSubmit, disabled, setPincode }) => {
  const { t } = useTranslation()
  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <TextField
        name="code"
        required
        label={t('OC_invite_landing_page_input')}
        variant="outlined"
        onChange={(e) => setPincode(e.target.value)}
      />
      <Typography variant="body1">
        <Trans i18nKey="OC_invite_landing_page_desc" />
      </Typography>
      <Button type="submit" variant="contained" color="primary" disabled={disabled}>
        {t('OC_invite_landing_page_button')}
      </Button>
    </form>
  )
}

export default UserPincode
