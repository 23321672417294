import client from '../client'

export const addon = ({ customerId, abbId, json }) => {
  return client.post(`${customerId}/${abbId}/addons/subscriptions`, { json }).json()
}

export const showNumberInSkyapp = ({ customerId, mobileNumber, json }) => {
  return client.put(`users/${customerId}/oneapp/${mobileNumber}/contactinfo`, { json }).json()
}

export const activatePresence = ({ customerId }) => {
  return client.put(`teams/${customerId}/activate-presence`).json()
}

export const guardApp = ({ customerId, abbId }) => {
  return client.post(`subscriptions/${customerId}/${abbId}/safe-profile`).json()
}

export const orderSimcard = ({ customerId, mobileNumber, json }) => {
  return client.post(`simcard/${customerId}/${mobileNumber}`, { json }).json()
}

export const single = ({ customerId, json }) => {
  return client
    .post(`subscriptions/${customerId}/new-from-portfolio`, { json, timeout: false })
    .json()
}

export const singlePlainSubscription = ({ customerId, json }) => {
  return client.post(`subscriptions/${customerId}/plain-mobile`, { json }).json()
}
export const verifyOCUser = ({ uuid, pincode }) => {
  return client.get(`operator-connect/invite/${uuid}?pincode=${pincode}`).json()
}
export const createOCUser = ({ uuid, pincode, json }) => {
  return client
    .post(`authentication/invite/${uuid}/aad-authcode?pincode=${pincode}`, { json })
    .json()
}

export const inviteOCUser = ({ customerId, json }) => {
  return client.put(`operator-connect/invite/${customerId}`, { json }).json()
}
