import React from 'react'
import classNames from 'classnames'
import { Button, CircularProgress } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import useButtonStyle from '../../hooks/useButtonStyle'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  backButton: {
    marginRight: theme.spacing(0.6),
  },
  container: {
    display: 'flex',
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  flexCenter: {
    justifyContent: 'center',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
}))

export type Props = {
  loading?: boolean
  disabled?: boolean
  /** If no submit function, GradientButton will be hidden */
  submit?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** If no cancel/back function, button will be hidden */
  cancel?: (event: React.MouseEvent<HTMLButtonElement>) => void
  submitLabel?: string
  cancelLabel?: string
  /** Align buttons left, center or right */
  justify?: 'flex-start' | 'center' | 'flex-end'
}

const CallToActionButtons = ({
  loading = false,
  disabled = false,
  submit,
  cancel,
  submitLabel = 'Opret',
  cancelLabel = 'Tilbage',
  justify = 'flex-end',
}: Props) => {
  const buttons = useButtonStyle()
  const classes = useStyles()

  const containerClassName = classNames({
    [classes.container]: true,
    [classes.flexStart]: justify === 'flex-start',
    [classes.flexCenter]: justify === 'center',
    [classes.flexEnd]: justify === 'flex-end',
  })

  const showBackButton = Boolean(cancel && cancelLabel)

  return (
    <div className={containerClassName}>
      {showBackButton && (
        <div>
          <Button
            color="primary"
            disabled={loading}
            className={classes.backButton}
            onClick={cancel}
          >
            {cancelLabel}
          </Button>
        </div>
      )}
      {submitLabel && (
        <div className={classes.wrapper}>
          <Button
            className={buttons.gradient}
            disabled={disabled || loading}
            onClick={submit}
            variant="contained"
            type="submit"
          >
            {submitLabel}
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      )}
    </div>
  )
}

export default CallToActionButtons
