/**
 * gets existing script element from dom by its id
 */
export const getScript = (id: string): HTMLScriptElement => {
  return document.querySelector(`script#${id}`)
}

/**
 * appends script element to dom if not already exists
 */
export const addScript = (id: string, src: string, onload?: () => void) => {
  const currScriptElem = getScript(id)
  if (!currScriptElem) {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.id = id
    document.body.appendChild(s)
    if (onload) {
      s.addEventListener('load', onload)
    }
    s.src = src
  }
}

/**
 * removes script element from dom if script exists by its given id
 */
export const removeScript = (id: string) => {
  const sElem = getScript(id)
  if (sElem) {
    sElem.remove()
  }
}
