import React, { useEffect, useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { fetchTeamsUsage } from 'state/teamsUsage/actions'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { Alert } from '@material-ui/lab'
import chartData from './chartData'
import {
  PageTitle,
  Paper,
  PaperContent,
  PaperHeader,
  PaperTitle,
  LoadingSpinner,
} from '@skytdc/mui/components'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Link, Button } from '@material-ui/core'
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons'
import Layout from '../Layout'

const useStyles = makeStyles((theme) => ({
  setMaxHeight: {
    height: '100%',
  },
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  text: {
    paddingBottom: theme.spacing(2),
  },
  textBold: {
    fontWeight: 700,
  },
  textBoldPadding: {
    fontWeight: 700,
    paddingBottom: theme.spacing(2),
  },
  icon: {
    verticalAlign: 'middle',
    paddingBottom: '2px',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}))

const CloudAdoption = ({ title, match, history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const teamsUsage = useSelector((state) => state.teamsUsage)
  const chartsConfig = [
    { name: 'calls', label: t('CloudAdoption_teams_calls'), backgroundColor: '#1a6c9e' },
    { name: 'meetings', label: t('CloudAdoption_teams_meetings'), backgroundColor: '#59b8eb' },
    {
      name: 'privateChatMessages',
      label: t('CloudAdoption_private_messages'),
      backgroundColor: '#1b998b',
    },
    {
      name: 'teamsChatMessages',
      label: t('CloudAdoption_chat_messages'),
      backgroundColor: '#03675b',
    },
  ]
  const charts = useMemo(
    () => chartData(teamsUsage.data?.statistics, chartsConfig),
    [teamsUsage.data?.statistics]
  )

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    dispatch(fetchTeamsUsage({ customerId: match.params.customer_id }))
  }, [])

  let content = <LoadingSpinner />

  if (teamsUsage.error) {
    content =
      teamsUsage.error.status === 500 ? (
        <>
          <Alert severity="error" className={classes.alert}>
            {t('CheckSetupWizardError')}
          </Alert>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            onClick={() => history.push(`/${match.params.customer_id}/setup`)}
          >
            {t('Organization_azure_cta')}
          </Button>
        </>
      ) : (
        <Typography color="error" variant="body1" className={classes.alert}>
          {teamsUsage.error.statusText}
        </Typography>
      )
  }

  if (teamsUsage.data?.statistics) {
    content = teamsUsage.data.statistics.length ? (
      <>
        <div className={classes.text}>
          {charts?.map((data, i) => (
            <Bar
              style={{ marginBottom: 80 }}
              key={i}
              data={data}
              options={{
                scales: {
                  x: {
                    reverse: true,
                  },
                },
              }}
            />
          ))}
        </div>
      </>
    ) : (
      <div className={classes.text}>
        <Typography variant="body1" display="inline">
          {t('Teams_usage_no_data')}
        </Typography>
      </div>
    )
  }

  return (
    <Layout>
      <PageTitle title="Cloud Adoption" />
      <Grid container spacing={4}>
        <Grid item lg={4} sm={12} xl={4} xs={12}>
          <Paper className={classes.setMaxHeight}>
            <PaperHeader noDivider>
              <PaperTitle title={t('CloudAdoption_last_180_days')} />
            </PaperHeader>
            <PaperContent noPadding className={classes.container}>
              {content}
            </PaperContent>
          </Paper>
        </Grid>
        <Grid item lg={8} sm={12} xl={8} xs={12}>
          <Paper className={classes.marginBottom}>
            <PaperHeader noDivider>
              <PaperTitle title={t('CloudAdoption_education_title')} />
            </PaperHeader>
            <PaperContent noPadding className={classes.container}>
              <Typography variant="body1" className={classes.textBoldPadding}>
                {t('CloudAdoption_education_description_one')}
              </Typography>
              <Typography variant="body1" className={classes.text}>
                {t('CloudAdoption_education_description_two')}
              </Typography>
              <Typography variant="body1" className={classes.text}>
                {t('CloudAdoption_education_description_three')}
              </Typography>
              <Typography variant="body1" className={classes.text}>
                {t('CloudAdoption_write_us')}
                <Link href="mailto:support@sky.tdc.dk">support@sky.tdc.dk</Link>
                {t('CloudAdoption_write_us_end')}
              </Typography>
            </PaperContent>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default CloudAdoption
