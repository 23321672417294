import React, { useState, useEffect } from 'react'
import { SkySelect, DrawerMessage } from 'components'
import DrawerButtons from 'views/Drawer/components/DrawerButtons'
import { useTranslation } from 'react-i18next'
import callPatternDescriptions from '../../callPatternDescriptions'
import DraggableUserList from './DraggableUserList'

const formatUsers = (users) => {
  return users.map((user) => {
    return user.mobile_number
      ? {
        label: `${user.mobile_number} - ${user.first_name} ${user.last_name} <${user.email}>`,
        is_active: user.is_active,
        mobile_number: user.mobile_number,
      }
      : null
  })
}

const EditForm = ({
  loading,
  backLink,
  huntgroup,
  availableMembers,
  callPatterns,
  updateHuntgroup,
}) => {
  const { t } = useTranslation()
  const [rule, setRule] = useState(null)
  const [availMembers, setAvailMembers] = useState([])
  const [members, setMembers] = useState([])

  useEffect(() => {
    const findRuleInOptions = callPatterns.find((x) => x.rule === huntgroup.rule)
    setRule(
      findRuleInOptions
        ? {
          label: t(callPatternDescriptions[findRuleInOptions.rule].name),
          value: findRuleInOptions.rule,
        }
        : null
    )
    const formattedMembers = formatUsers(huntgroup.members)
    const formattedAvailableMembers = formatUsers(availableMembers)
    setMembers(formattedMembers)
    setAvailMembers([...formattedMembers, ...formattedAvailableMembers])
  }, [])

  const handleSetRule = (event, val) => {
    setRule(val)
  }

  const handleSetMembers = (event, val) => {
    if (members.length <= 20 && val.length > 0 && val.length < 20) {
      const newVal = [...val]
      newVal[newVal.length - 1] = {
        ...newVal[newVal.length - 1],
        is_active: false,
      }
      setMembers(newVal)
    }
  }

  const handleUpdateHuntgroup = () => {
    const chosenMembers = members.map((member) => {
      return {
        display: member.label,
        is_active: member.is_active,
        mobile_number: member.mobile_number,
      }
    })
    const json = {
      rule: rule.value,
      members: chosenMembers,
    }
    updateHuntgroup(json)
  }

  const callPatternOptions = callPatterns.map((x) => {
    return {
      label: t(callPatternDescriptions[x.rule].name),
      value: x.rule,
    }
  })

  const formIsValid = Boolean(rule && members.length >= 1)

  return (
    <>
      <DrawerMessage>{t('Organization_huntgroups_members_description')}</DrawerMessage>
      <DrawerMessage component="div" gutterBottom>
        <ul>
          {callPatterns.map((x) => {
            return (
              <li key={x.rule}>
                <strong>{t(callPatternDescriptions[x.rule].name)}: </strong>
                {t(callPatternDescriptions[x.rule].description)}
              </li>
            )
          })}
        </ul>
      </DrawerMessage>
      <SkySelect
        label={t('Organization_huntgroups_choose_pattern')}
        value={rule}
        onChange={handleSetRule}
        disabled={loading}
        options={callPatternOptions}
        disableClearable
        required
        getOptionSelected={(option, value) => option.value === value.value}
      />
      <SkySelect
        label={t('Organization_huntgroups_members_choose')}
        value={members}
        onChange={handleSetMembers}
        disabled={loading}
        options={availMembers}
        isMultiselect
        disableClearable
        getOptionSelected={(option, value) => option.mobile_number === value.mobile_number}
        helperText={members.length === 1 && 'Der skal min. være 1 medlem i ringegruppen'}
        renderTags={() => (
          <span style={{ marginLeft: '4px' }}>
            {members.length}
            {t('Organization_huntgroups_members_chosen')}
          </span>
        )}
      />
      {members.length > 0 && (
        <>
          <DrawerMessage>{t('Organization_huntgroups_members_description_two')}</DrawerMessage>
          <DraggableUserList
            loading={loading}
            members={members}
            setMembers={setMembers}
            canBeDeactivated={members.filter((x) => x.is_active).length > 1}
          />
        </>
      )}
      <DrawerButtons
        backLink={backLink}
        onSubmit={handleUpdateHuntgroup}
        submitText={t('Organization_huntgroups_members_update_label')}
        loading={loading}
        disabled={!formIsValid}
      />
    </>
  )
}

export default EditForm
