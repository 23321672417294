import React, { useState, useEffect } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSelector, useDispatch, batch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { updateSubscription, deleteSubscription } from 'services/sweb/subscription'
import { fetchMobileCallForwarding } from 'state/mobileCallForwarding/actions'
import { PaperLoader, PaperFeedbackMessage } from 'components'
import { useTranslation } from 'react-i18next'
import MobileCallForwardingForm from './MobileCallForwardingForm'

const MobileCallForwarding = ({ subscription, customerId, changeRoute, title }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const mobileCallForwarding = useSelector((state) => state.mobileCallForwarding)

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    batch(() => {
      dispatch(
        fetchMobileCallForwarding({
          customerId,
          mobileNumber: subscription.mobile_number,
        })
      )
    })
  }, [])

  const setForwardingOn = (json) => {
    setLoading(true)
    updateSubscription
      .updateMobileCallForwarding({
        customerId,
        mobileNumber: subscription.mobile_number,
        json,
      })
      .then(() => {
        enqueueSnackbar(t('Drawer_mobile_forwarding_success'), {
          variant: 'success',
        })
        changeRoute()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  const setForwardingOff = () => {
    setLoading(true)
    deleteSubscription
      .disableMobileCallForwarding({
        customerId,
        mobileNumber: subscription.mobile_number,
      })
      .then(() => {
        enqueueSnackbar(t('Drawer_mobile_forwarding_deactivate_success'), {
          variant: 'success',
        })
        changeRoute()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  let content = <PaperLoader />

  if (!mobileCallForwarding.loading && mobileCallForwarding.error) {
    return (
      <PaperFeedbackMessage
        withMargin
        message={mobileCallForwarding.error.statusText}
        type="error"
      />
    )
  }
  if (mobileCallForwarding.data) {
    content = (
      <MobileCallForwardingForm
        loading={loading}
        setForwardingOn={setForwardingOn}
        setForwardingOff={setForwardingOff}
        backLink={changeRoute}
        forwardingData={mobileCallForwarding.data}
        hasMobileOneNumber={Boolean(subscription.mobile_onenumber)}
      />
    )
  }
  return content
}

export default MobileCallForwarding
