import { getTransportOptions } from 'services/partyweb/transport'

export const FETCH_FAILURE = 'PARTYTRANSPORT/FETCH_FAILURE'
export const FETCH_BEGIN = 'PARTYTRANSPORT/FETCH_BEGIN'
export const FETCH_SUCCESS = 'PARTYTRANSPORT/FETCH_SUCCESS'

export const fetchTransportOptionsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchTransportOptionsBegin = ({ partyId }) => {
  return {
    type: FETCH_BEGIN,
    partyId,
  }
}

export const fetchTransportOptionsSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchTransportOptions = ({ partyId }) => {
  return async (dispatch) => {
    dispatch(fetchTransportOptionsBegin({ partyId }))
    try {
      const response = await getTransportOptions({
        partyId,
      })
      dispatch(fetchTransportOptionsSuccess(response.data))
    } catch (err) {
      dispatch(fetchTransportOptionsFailure(err.response))
    }
  }
}
