import { getValidation } from 'services/sweb/validation'

export const FETCH_FAILURE = 'validateExtension/FETCH_FAILURE'
export const FETCH_BEGIN = 'validateExtension/FETCH_BEGIN'
export const FETCH_SUCCESS = 'validateExtension/FETCH_SUCCESS'

export const fetchValidateExtensionFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchValidateExtensionBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchValidateExtensionSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchValidateExtension = ({ customerId, phoneNumber, extension }) => {
  return async (dispatch) => {
    dispatch(fetchValidateExtensionBegin())
    try {
      const response = await getValidation.validateExtension({
        customerId,
        phoneNumber,
        extension,
      })
      dispatch(fetchValidateExtensionSuccess(response.data))
    } catch (err) {
      dispatch(fetchValidateExtensionFailure(err.response))
    }
  }
}
