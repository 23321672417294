import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Tooltip,
  Badge,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Divider,
  Button,
} from '@material-ui/core'
import { fetchUsers } from 'state/users/actions'
import { fetchTeamsUsers } from 'state/teamsUsers/actions'
import { fetchAdGroupFilters } from 'state/adGroupFilters/actions'
import { fetchAvailableAdGroups } from 'state/availableAdGroups/actions'
import { FilterList as FilterIcon } from '@material-ui/icons'
import { useSelector, useDispatch, batch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Dialog from './Dialog'

const useStyles = makeStyles((theme) => ({
  activeIcon: {
    color: theme.palette.primary.main,
  },
  dot: {
    top: '12px',
    right: '8px',
  },
  avatar: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.light,
  },
}))

const AdGroupFilter = ({
  customerId,
  combinedUsers = true,
  asListItem = false,
  asButton = false,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [filterDialogOpen, setFilterDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const adGroupFilters = useSelector((state) => state.adGroupFilters)
  const availableAdGroups = useSelector((state) => state.availableAdGroups)
  const users = combinedUsers
    ? useSelector((state) => state.users)
    : useSelector((state) => state.teamsUsers)

  const getUnAvailableFilterGroups = () => {
    if (!adGroupFilters?.data || !availableAdGroups?.data) return
    const NoneExistingFilters = []
    for (const filterUsed of adGroupFilters?.data) {
      const filterAvailable = availableAdGroups?.data.find((e) => e.id == filterUsed.id)
      if (!filterAvailable) {
        NoneExistingFilters.push(filterUsed.id)
      }
    }

    return NoneExistingFilters
  }

  useEffect(() => {
    dispatch(fetchAdGroupFilters({ customerId }))
  }, [])

  useEffect(() => {
    if (filterDialogOpen) dispatch(fetchAvailableAdGroups({ customerId }))
  }, [filterDialogOpen])

  const refetch = () => {
    batch(() => {
      if (combinedUsers) {
        dispatch(fetchUsers({ customerId }))
      } else {
        dispatch(fetchTeamsUsers({ customerId }))
      }
      dispatch(fetchAdGroupFilters({ customerId }))
    })
  }

  const handleOpenDialog = () => {
    setFilterDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setFilterDialogOpen(false)
  }

  const filterTooltip =
    (adGroupFilters.data && adGroupFilters.data.length === 1 && t('Users_groupfilter')) ||
    (adGroupFilters.data &&
      adGroupFilters.data.length > 1 &&
      t('Users_groupfilters', { amount: adGroupFilters.data.length })) ||
    t('Users_asdf')

  return (
    <>
      {asListItem && (
        <List>
          <ListItem button onClick={handleOpenDialog}>
            <ListItemAvatar>
              <Badge
                badgeContent={(adGroupFilters.data && adGroupFilters.data.length) || 0}
                color="primary"
                invisible={
                  !adGroupFilters.data || (adGroupFilters.data && adGroupFilters.data.length === 0)
                }
                variant="dot"
                classes={{ badge: classes.dot }}
              >
                <Avatar className={classes.avatar}>
                  <FilterIcon />
                </Avatar>
              </Badge>
            </ListItemAvatar>
            <ListItemText primary={t('Users_sdf')} secondary={t('Users_324')} />
          </ListItem>
          <Divider />
        </List>
      )}
      {asButton && (
        <Button
          variant="outlined"
          onClick={handleOpenDialog}
          color="primary"
          startIcon={<FilterIcon />}
          fullWidth
        >
          {t('Users_awe')}
        </Button>
      )}
      {!asListItem && !asButton && (
        <Tooltip title={filterTooltip} aria-label={t('Users_sdf')} arrow>
          <Badge
            badgeContent={(adGroupFilters.data && adGroupFilters.data.length) || 0}
            color="primary"
            invisible={
              !adGroupFilters.data || (adGroupFilters.data && adGroupFilters.data.length === 0)
            }
            variant="dot"
            classes={{ badge: classes.dot }}
          >
            <IconButton
              variant="text"
              aria-label={t('Users_sdf')}
              onClick={handleOpenDialog}
              className={
                adGroupFilters.data && adGroupFilters.data.length > 0 ? classes.activeIcon : ''
              }
            >
              <FilterIcon />
            </IconButton>
          </Badge>
        </Tooltip>
      )}
      {filterDialogOpen && adGroupFilters.data && (
        <Dialog
          adGroupFilters={adGroupFilters}
          availableAdGroups={availableAdGroups}
          handleClose={handleCloseDialog}
          isOpen={filterDialogOpen}
          customerId={customerId}
          users={users}
          fetchUsers={refetch}
          getUnAvailableFilterGroups={getUnAvailableFilterGroups()}
        />
      )}
    </>
  )
}

export default AdGroupFilter
