import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 4,
  },
}))

const NoSubscriptionMessage = ({ title, description }) => {
  const classes = useStyles()
  return (
    <Box className={classes.box}>
      <Typography variant="body1" display="block" gutterBottom>
        <strong>{title}</strong>
      </Typography>
      <Typography variant="body2" display="block">
        {description}
      </Typography>
    </Box>
  )
}

export default NoSubscriptionMessage
