import React from 'react'
import { IconButton, Tooltip, Link } from '@material-ui/core'
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons'
import { SWEB_API_URI } from 'constants/configurable'
import sweb from 'services/sweb/client'
import { useTranslation } from 'react-i18next'

const DownloadCsv = ({ customerId }) => {
  const { t } = useTranslation()
  const { value: accessToken } = sweb.getBearer()
  const downloadCsvLink = `${SWEB_API_URI}/users/csv/${customerId}/userdata?access_token=${accessToken}`

  return (
    <>
      <Tooltip title={t('Users_asd')} aria-label={t('Users_asd')} arrow>
        <Link href={downloadCsvLink} target="_blank">
          <IconButton variant="text" aria-label={t('Users_asd')}>
            <CloudDownloadIcon />
          </IconButton>
        </Link>
      </Tooltip>
    </>
  )
}

export default DownloadCsv
