export const OCUSERS_FAILURE = 'OCUSERS_FAILURE'
export const OCUSERS_BEGIN = 'OCUSERS_BEGIN'
export const OCUSERS_SUCCESS = 'OCUSERS_SUCCESS'

export interface ParamsProps {
  customerId: string
}

interface FetchIntNumbersBegin extends ParamsProps {
  type: typeof OCUSERS_BEGIN
}

interface FetchIntNumbersFailure {
  type: typeof OCUSERS_FAILURE
  // eslint-disable-next-line
  error: any
}

export interface FetchIntNumbersSuccess {
  type: typeof OCUSERS_SUCCESS
  // eslint-disable-next-line
  data: any
}

export type IntNumbersActions =
  | FetchIntNumbersBegin
  | FetchIntNumbersFailure
  | FetchIntNumbersSuccess

export interface IntNumbersState extends ParamsProps {
  loading: boolean
  // eslint-disable-next-line
  data?: any
  // eslint-disable-next-line
  error?: Error
}
