import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchPsWebScripts } from 'state/psWebScripts/actions'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { Grid, Typography, List } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DrawerSection } from 'components'
import { LoadingSpinner, PageTitle, Paper, PaperContent } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'
import Layout from 'views/Layout'
import ScriptListItem from './components/ScriptListItem'

const TrustTool = ({ match }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const psWebScripts = useSelector((state) => state.psWebScripts)

  useEffect(() => {
    changeDocumentTitle('T.R.U.S.T scripts', ' - ', ':applicationName')
    dispatch(fetchPsWebScripts({ customerId: match.params.customer_id }))
  }, [])

  let content = <LoadingSpinner />

  if (psWebScripts.error && psWebScripts.error.statusText) {
    content = (
      <Typography color="error" variant="h2" align="center">
        {psWebScripts.error.statusText}
      </Typography>
    )
  }

  if (psWebScripts.data) {
    content = (
      <Paper>
        <PaperContent>
          <DrawerSection title="Teams Rollout Unified Specialised Tool">
            <Alert severity="info">{t('Trust_explanation')}</Alert>
          </DrawerSection>
          <List>
            {psWebScripts.data &&
              psWebScripts.data.runnableTeamsAdminScripts &&
              psWebScripts.data.runnableTeamsAdminScripts.map((x) => {
                return (
                  <ScriptListItem
                    key={x.name}
                    customerId={match.params.customer_id}
                    primary={x.caption}
                    secondary={x.description || ''}
                    scriptName={x.name}
                  />
                )
              })}
          </List>
        </PaperContent>
      </Paper>
    )
  }

  return (
    <Layout>
      <Grid container justify="center">
        <Grid item lg={8} sm={12} xs={12}>
          <PageTitle title="T.R.U.S.T scripts" />
          {content}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default TrustTool
