import React, { memo } from 'react'
import {
  LocalPhoneOutlined as LandlineIcon,
  DialpadOutlined as ExtensionIcon,
} from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'
import { phoneNumberFormat } from 'utils/helpers'

const Landline = ({ landlineNumber, extension }) => {
  const { t } = useTranslation()
  return (
    <>
      <DrawerListItem
        icon={<LandlineIcon />}
        primaryText={t('Organization_avail_numbers_landline')}
        secondaryText={phoneNumberFormat(landlineNumber)}
      />
      <DrawerListItem
        icon={<ExtensionIcon />}
        primaryText={t('Drawer_extension')}
        secondaryText={extension}
      />
    </>
  )
}

export default memo(Landline)
