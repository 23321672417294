import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { fetchIntNumberVoxbone } from 'state/intNumberVoxbone/actions'
import { fetchVoxboneVoiceUri } from 'state/intNumberVoiceUri/actions'
import { fetchVoxboneCapacityGroup } from 'state/intNumberCapacityGroup/actions'
import { fetchIntNumberAddonProducts } from 'state/intNumberAddonProducts/actions'
import { updateCustomer } from 'services/sweb/customer'
import { makeStyles } from '@material-ui/core/styles'
import {
  PublicRounded as NumberIcon,
  CenterFocusStrongRounded as DotIcon,
  // SettingsOutlined as RoleIcon,
} from '@material-ui/icons'
import { SwipeableDrawer, Typography, Button, Box, Avatar } from '@material-ui/core'
import {
  Alert,
  DrawerHeader,
  DrawerTopBar,
  DropdownDivider,
  SkySelect,
} from '@skytdc/mui/components'
import { DrawerListItem, DrawerSection } from 'components'
import { useTranslation } from 'react-i18next'
import * as flags from 'round-flags'
import { phoneNumberFormat } from 'utils/helpers'

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    marginRight: 16,
    '& + div': {
      flexBasis: '100%',
      '& + button': {
        height: 57,
        marginLeft: 8,
      },
    },
  },
  drawer: {
    width: '90%',
    maxWidth: '660px',
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflowX: 'hidden',
    zIndex: 1600,
  },
  innerDrawer: {
    marginBottom: theme.spacing(24),
  },
  alert: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
}))

const SingleNumberDrawer = ({ history, number, customerId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

  const intNumberVoxbone = useSelector((state) => state.intNumberVoxbone)
  const voiceUris = useSelector((state) => state.intNumberVoiceUri)
  const subscriptionProducts = useSelector((state) => state.intNumberAddonProducts)

  const numberMatchState = `+${number.country.prefix}${number.inumber}` === intNumberVoxbone.e164
  const [open, setOpen] = useState(false)
  const [subscriptionChangeSubmitting, setSubscriptionChangeSubmitting] = useState(false)
  const [subscriptionProductTypeId, setSubscriptionProductTypeId] = useState<string>(
    number.subscription.productTypeId
  )

  const { loading, data, pageNumber, pageSize, error } = useSelector(
    (state) => state.intNumberCapacityGroup
  )

  const { totalResultSize } = data?.pageing || {}

  const maxPageNumberReached = pageNumber <= (Math.ceil(totalResultSize / pageSize) - 1 || 0)

  useEffect(() => {
    if (
      (!loading && !pageNumber && !error) ||
      (!loading && !error && data?.data.length < totalResultSize && maxPageNumberReached)
    ) {
      dispatch(fetchVoxboneCapacityGroup({ customerId, id: '', pageNumber, pageSize }))
    }
  }, [!loading])

  // eslint-disable-next-line
  const handleOnOpen = () => {}

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      history.push({
        pathname: `/${customerId}/internationale-numre/tildelte-numre`,
        state: null,
      })
    }, 300)
  }

  useEffect(() => {
    setOpen(true)
    batch(() => {
      dispatch(
        fetchIntNumberVoxbone({ customerId, e164: `+${number.country.prefix}${number.inumber}` })
      )
      if (!subscriptionProducts.data) {
        dispatch(fetchIntNumberAddonProducts({ customerId }))
      }
    })
  }, [])

  const unresolved = !intNumberVoxbone.loading && !loading && !voiceUris.loading

  const voxboneNumber = numberMatchState && intNumberVoxbone.data?.data[0]
  const voiceUrisMatching = voxboneNumber?.voxbone?.voiceUriId === voiceUris.id
  const capacityGroup =
    voxboneNumber && data?.data.find((x) => x.id === voxboneNumber.voxbone.capacityGroupId)
  const voiceUri = voiceUrisMatching && voiceUris.data?.data[0]
  const noVoiceUriResolved = unresolved && !voiceUri
  const noCapacityGroupResolved = unresolved && !capacityGroup

  useEffect(() => {
    if (voxboneNumber?.voxbone?.e164) {
      dispatch(fetchVoxboneVoiceUri({ customerId, id: voxboneNumber.voxbone.voiceUriId }))
    }
  }, [voxboneNumber])
  const changeSubscription = () => {
    if (subscriptionProductTypeId) {
      setSubscriptionChangeSubmitting(true)
      const json = {
        subscription_info: {
          subscriptionId: number.subscription.id,
          productTypeId: subscriptionProductTypeId,
        },
      }

      updateCustomer
        .changeAddonProductSubscription({
          customerId,
          internationalnumber: number.country?.prefix + number.inumber,
          json,
        })
        .then(() => {
          setSubscriptionChangeSubmitting(false)
          enqueueSnackbar(t('Drawer_int_succes_change_sub'), {
            variant: 'success',
          })
        })
        .catch(() => {
          setSubscriptionChangeSubmitting(false)
          enqueueSnackbar(t('OC_invite_error'), { variant: 'error' })
        })
    }
  }

  const countryFlag = flags[number.country.code.toUpperCase()]
  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={handleOnOpen}
        onClose={handleClose}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        classes={{
          paper: classes.drawer,
        }}
        transitionDuration={375}
      >
        <div className={classes.innerDrawer}>
          <DrawerTopBar goBack={handleClose} backAnchor={t('Organization_orders_drawer_close')} />
          <DrawerHeader
            primary={`+${number?.country?.prefix} ${phoneNumberFormat(number.inumber)}`}
            secondary={number.countryName}
            withMargin
            size="large"
            icon={countryFlag ? <img src={countryFlag} alt={number.countryName} /> : <NumberIcon />}
          />

          <DrawerSection title={t('General')} />
          <DrawerListItem
            icon={<DotIcon />}
            primaryText={number.description}
            secondaryText={t('Invoice_description')}
          />
          <DrawerListItem
            icon={<DotIcon />}
            primaryText={`${number.productVersion} - ${
              number.productVersion === 1 ? t('TheOldSolution') : t('TheNewSolution')
            }`}
            secondaryText="Version"
          />
          <DrawerListItem
            icon={<DotIcon />}
            primaryText={number.provider}
            secondaryText={t('Provider')}
          />
          <DrawerListItem icon={<DotIcon />} primaryText={number.id} secondaryText="ID" />

          <DrawerSection title="Voice URI" />
          {noVoiceUriResolved ? (
            <Alert message="No voice URI found" withMargin className={classes.alert} />
          ) : (
            <>
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={voiceUri?.uri || <p />}
                secondaryText="Primary Voice URI"
                isLoading={voiceUris.loading || !voiceUrisMatching}
              />
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={voiceUri?.backupUri || <p />}
                secondaryText="Backup Voice URI"
                isLoading={voiceUris.loading || !voiceUrisMatching}
              />
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={voiceUri?.uriProtocol || <p />}
                secondaryText="Voice URI protocol"
                isLoading={voiceUris.loading || !voiceUrisMatching}
              />
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={voiceUri?.description || <p />}
                secondaryText={t('Invoice_description')}
                isLoading={voiceUris.loading || !voiceUrisMatching}
              />
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={voiceUri?.id || <p />}
                secondaryText="ID"
                isLoading={voiceUris.loading || !voiceUrisMatching}
              />
            </>
          )}

          <DrawerSection title={t('CapacityGroup')} />
          {noCapacityGroupResolved ? (
            <Alert message="No capacity group found" withMargin className={classes.alert} />
          ) : (
            <>
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={capacityGroup?.description || <p />}
                secondaryText={t('Organization_huntgroups_td_1')}
                isLoading={loading || !voxboneNumber}
              />
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={
                  `${capacityGroup?.amountOfDidsMapped}/${capacityGroup?.maximumCapacity}` || <p />
                }
                secondaryText={t('Capacity')}
                isLoading={loading || !voxboneNumber}
              />
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={capacityGroup?.id || <p />}
                secondaryText="ID"
                isLoading={loading || !voxboneNumber}
              />
            </>
          )}

          <DrawerSection title={t('Drawer_price_sub')} />

          {number.subscription ? (
            <>
              <Box display="flex" p="8px 0 16px 16px">
                <Avatar className={classes.avatar}>
                  <DotIcon />
                </Avatar>
                <SkySelect
                  label={t('Drawer_price_sub')}
                  name="transportId"
                  helperText={t('Drawer_int_helper_text')}
                  disableClearable
                  value={
                    subscriptionProducts.data?.products.find(
                      ({ productTypeId }) => productTypeId === subscriptionProductTypeId
                    ) || null
                  }
                  options={subscriptionProducts.data?.products || []}
                  withMargin={false}
                  disabled={subscriptionProducts.loading || subscriptionChangeSubmitting}
                  loading={subscriptionProducts.lading}
                  onChange={(name, { productTypeId }) =>
                    setSubscriptionProductTypeId(productTypeId)
                  }
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, transport) =>
                    option.productTypeId === transport.productTypeId
                  }
                  renderOption={({ productTypeId, price, name }) => {
                    return (
                      <>
                        <Typography>{name}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          <b>ProductTypeId:</b> {productTypeId}
                          <span style={{ marginLeft: '1.5ch' }}>
                            <b>{t('Price')}:</b> {price}
                          </span>
                        </Typography>
                      </>
                    )
                  }}
                />
                <Button
                  onClick={changeSubscription}
                  variant="contained"
                  color="primary"
                  disabled={
                    subscriptionProducts.loading ||
                    number.subscription.productTypeId === subscriptionProductTypeId ||
                    subscriptionChangeSubmitting
                  }
                >
                  {t('Drawer_user_skift')}
                </Button>
              </Box>

              <DrawerListItem
                icon={<DotIcon />}
                primaryText={number.subscription.customerId}
                secondaryText={t('Organization_unassignedsimcards_customer_id')}
              />
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={`${number.subscription.price} ${t('PerMonth')}`}
                secondaryText={t('Price')}
              />
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={number.subscription.startDate}
                secondaryText={t('Invoice_startdate')}
              />
              {number.subscription.endDate && (
                <DrawerListItem
                  icon={<DotIcon />}
                  primaryText={number.subscription.endDate}
                  secondaryText={t('Invoice_enddate')}
                />
              )}
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={number.subscription.comment}
                secondaryText={t('Organization_orders_drawer_comment')}
              />
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={number.subscription.extraInfo}
                secondaryText={t('Wizard_9sth')}
              />
              <DrawerListItem
                icon={<DotIcon />}
                primaryText={number.subscription.id}
                secondaryText="ID"
              />
            </>
          ) : (
            <Alert message="No addon subscription found" withMargin className={classes.alert} />
          )}

          <DrawerSection title="Mediation" />
          {voxboneNumber?.mediation && voxboneNumber.mediation.length > 0
            ? voxboneNumber.mediation.map((x, index) => {
                return (
                  <div key={x.id}>
                    <DrawerListItem
                      icon={<DotIcon />}
                      primaryText={`+${x.countryDialPrefix} ${x.intnumber}`}
                      secondaryText={t('Organization_huntgroups_td_2')}
                    />
                    <DrawerListItem
                      icon={<DotIcon />}
                      primaryText={x.originCountry}
                      secondaryText="Origin Country"
                    />
                    <DrawerListItem
                      icon={<DotIcon />}
                      primaryText={x.customerId}
                      secondaryText={t('Organization_unassignedsimcards_customer_id')}
                    />
                    <DrawerListItem
                      icon={<DotIcon />}
                      primaryText={x.customerPubDate}
                      secondaryText="Customer Pub Date (?)"
                    />
                    <DrawerListItem
                      icon={<DotIcon />}
                      primaryText={x.createDate}
                      secondaryText={t('CreationDate')}
                    />
                    {x.customerCancelDate && (
                      <DrawerListItem
                        icon={<DotIcon />}
                        primaryText={x.customerCancelDate}
                        secondaryText="CustomerCancelDate"
                      />
                    )}
                    {x.cancelDate && (
                      <DrawerListItem
                        icon={<DotIcon />}
                        primaryText={x.cancelDate}
                        secondaryText="CancelDate"
                      />
                    )}
                    <DrawerListItem
                      icon={<DotIcon />}
                      primaryText={x.customerText}
                      secondaryText={t('Organization_orders_drawer_comment')}
                    />
                    <DrawerListItem
                      icon={<DotIcon />}
                      primaryText={x.description}
                      secondaryText={t('Invoice_description')}
                    />
                    <DrawerListItem
                      icon={<DotIcon />}
                      primaryText={`${x.id} (Nummertype ID: ${x.numbertypeid})`}
                      secondaryText="ID"
                    />
                    {voxboneNumber.mediation.length > 1 &&
                      index < voxboneNumber.mediation.length - 1 && <DropdownDivider />}
                  </div>
                )
              })
            : Boolean(voxboneNumber) && (
                <Alert message="No mediation data found" withMargin className={classes.alert} />
              )}
        </div>
      </SwipeableDrawer>
    </div>
  )
}

export default SingleNumberDrawer
