import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { TableCell, TableSortLabel, Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  withTooltip: {
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:hover': {
      color: theme.palette.text.secondary,
      cursor: 'pointer',
    },
  },
}))

const MuiDataTableHead = ({
  sortDirection,
  index,
  isSortable,
  label,
  updateDirection,
  align = 'left',
  hint = '',
  width,
}) => {
  const classes = useStyles()
  return (
    <TableCell
      align={align}
      sortDirection={sortDirection === 'none' ? false : sortDirection}
      {...(width && {
        style: { maxWidth: width },
      })}
    >
      <Tooltip title={hint} aria-label={hint} placement="top">
        {isSortable ? (
          <TableSortLabel
            active={Boolean(sortDirection !== 'none')}
            direction={sortDirection === 'none' ? 'asc' : sortDirection}
            onClick={() => updateDirection(index)}
            className={classNames({
              [classes.withTooltip]: hint.length > 0,
            })}
          >
            {label}
          </TableSortLabel>
        ) : (
          <span
            className={classNames({
              [classes.withTooltip]: hint.length > 0,
            })}
          >
            {label}
          </span>
        )}
      </Tooltip>
    </TableCell>
  )
}

export default MuiDataTableHead
