import {
  OCLANDLINE_NUMBERS_FAILURE,
  OCLANDLINE_NUMBERS_BEGIN,
  OCLANDLINE_NUMBERS_SUCCESS,
  IntNumbersState,
} from './model'

const initialState: IntNumbersState = {
  loading: false,
  customerId: null,
  data: null,
  error: null,
  pageNumber: 0,
  pageSize: 50,
  order: 'asc',
  orderby: 'inumber',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OCLANDLINE_NUMBERS_BEGIN:
      return {
        ...state,
        ...action,
        loading: true,
        customerId: action.customerId,
        error: null,
        data: action.customerId === state.customerId ? state.data : null,
      }

    case OCLANDLINE_NUMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: state.data
          ? {
              pageing: action.data.pageing,
              data: action.data.data.concat(state.data.data),
            }
          : action.data,
        pageNumber: state.pageNumber + 1,
      }

    case OCLANDLINE_NUMBERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: null,
      }

    default:
      return state
  }
}
