import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceSubscriptionDetails/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceSubscriptionDetails/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceSubscriptionDetails/FETCH_SUCCESS'

export const fetchInvoiceSubscriptionDetailsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceSubscriptionDetailsBegin = ({ invoiceId }) => {
  return {
    type: FETCH_BEGIN,
    invoiceId,
  }
}

export const fetchInvoiceSubscriptionDetailsSuccess = ({ invoiceId, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    invoiceId,
  }
}

export const fetchInvoiceSubscriptionDetails = ({ customerId, invoiceId }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceSubscriptionDetailsBegin({ invoiceId }))
    try {
      const response = await getInvoice.subscriptionDetails({ customerId, invoiceId })
      dispatch(fetchInvoiceSubscriptionDetailsSuccess({ invoiceId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceSubscriptionDetailsFailure(err.response))
    }
  }
}
