import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

import { MODE } from 'constants/build'

type LangId = 'en' | 'da'

const production = MODE === 'production'
const cacheBust = Date.now()
const loadPathPrefix = production ? `/locales-cache${cacheBust}` : '/locales'

const currentLanguageStorageKey = 'currentLanguage'
const currentLanguage = localStorage.getItem(currentLanguageStorageKey) as LangId

const languages = ['da', 'en']

const options = {
  lng: languages.find((lng) => lng === currentLanguage) || 'da',
  fallbackLng: 'en',
  preload: languages,
  backend: {
    loadPath: `${loadPathPrefix}/{{lng}}.json`,
  },
  debug: false, // may set to !production, for debugging when not running in production
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: false, // lazyLoad out of the box, can be ticked on/off per usage if wanted
  },
}

i18n.on('languageChanged', (lang: LangId) => {
  localStorage.setItem(currentLanguageStorageKey, lang)
})

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(options)

export default i18n
