import client from '../client'

export const addon = ({ customerId, abbId, json }) => {
  return client.delete(`${customerId}/${abbId}/addons/subscriptions`, { json }).json()
}

export const stopPresence = ({ customerId, json }) => {
  return client.delete(`teams/${customerId}/stop-presence`, { json }).json()
}
export const guardApp = ({ customerId }) => {
  return client.delete(`subscriptions/${customerId}/safe-profile`).json()
}

export const disableMobileCallForwarding = ({ customerId, mobileNumber }) => {
  return client.post(`mobile/${customerId}/${mobileNumber}/forward-off`).json()
}

export const displayNumberDeleteWeeklySchedule = (phoneNumber: string) => {
  return client.delete(`users/displayNumber/weeklySchedule/${phoneNumber}`).json()
}

export const cancelSubscription = ({
  customerId,
  abbId,
  date,
  emailReceipt,
  mobileCancelAction = '',
  landlineCancelAction,
}) => {
  return client
    .delete(
      `subscriptions/${customerId}/${abbId}?date=${date}&emailReceipt=${emailReceipt}${
        mobileCancelAction ? `&mobile_cancel_action=${mobileCancelAction}` : ''
      }&landline_cancel_action=${landlineCancelAction}`
    )
    .json()
}
