import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  subText: {
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(3),
  },
}))

const EndUserPageLink = ({ history, subscription, customerName }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { customerId } = subscription

  const goToEndUserPage = () => {
    history.push(`/${customerId}/min-side`)
  }

  return (
    <div className={classes.container}>
      <Typography gutterBottom variant="h3">
        {t('Organization_customer_select_mypage')}
      </Typography>
      <Typography variant="body1" className={classes.subText}>
        {t('Organization_customer_select_mypagedescription')}
        {customerName &&
          `${' '}${t('Organization_customer_select_active_sub', { org: customerName })}`}
      </Typography>
      <Button variant="outlined" onClick={goToEndUserPage} color="primary">
        {t('Organization_customer_select_gotomypage')}
      </Button>
    </div>
  )
}

export default EndUserPageLink
