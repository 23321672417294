import React, { useState } from 'react'
import { DrawerSection, SkyTextField } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Slide,
  Switch,
  Collapse,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DrawerButtons from '../../components/DrawerButtons'

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    paddingTop: theme.spacing(2),
  },
  message: {
    paddingBottom: theme.spacing(3),
  },
}))

const MobileCallForwardingForm = ({
  loading,
  backLink,
  setForwardingOn,
  setForwardingOff,
  forwardingData,
  hasMobileOneNumber,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [showForm, setShowForm] = useState(forwardingData.callforward_enabled)
  const [forwardingNumber, setForwardingNumber] = useState(
    forwardingData.callforward_enabled ? forwardingData.forward_to : ''
  )
  const [countryCode, setCountryCode] = useState(
    forwardingData.callforward_enabled ? forwardingData.prefix : '45'
  )
  const [conditionalForwarding, setConditionalForwarding] = useState(
    forwardingData.callforward_enabled ? !forwardingData.unconditional : false
  )

  const handleForwardingNumber = (event) => {
    setForwardingNumber(event.target.value)
  }

  const handleCountryCode = (event) => {
    setCountryCode(event.target.value)
  }

  const handleConditionalForwarding = (event) => {
    setConditionalForwarding(event.target.checked)
  }

  const handleShowForm = (event) => {
    setShowForm(event.target.checked)
  }

  const handleForwarding = () => {
    if (showForm) {
      const json = {
        prefix: countryCode || '45',
        number: forwardingNumber,
        unconditional: !conditionalForwarding,
      }
      setForwardingOn(json)
    } else {
      setForwardingOff()
    }
  }

  return (
    <Slide in direction="left">
      <div>
        <DrawerSection title={t('Drawer_mobile_forwarding_title')}>
          {hasMobileOneNumber && (
            <Typography className={classes.message}>
              {t('Drawer_mobile_forwarding_has_mobile_onenumber')}
            </Typography>
          )}
          {!forwardingData.forward_is_possible && (
            <Typography className={classes.message}>
              {t('Drawer_mobile_forwarding_forward_not_possible')}
            </Typography>
          )}
          {/* eslint-disable-next-line */}
          {!hasMobileOneNumber && forwardingData.forward_is_possible && (
            <>
              <Typography className={classes.message}>
                {t('Drawer_mobile_forwarding_desc')}
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(showForm)}
                    onChange={handleShowForm}
                    value={showForm}
                    color="primary"
                    disabled={!forwardingData.forward_is_possible || hasMobileOneNumber || loading}
                  />
                }
                label={t('Drawer_mobile_forwarding_label')}
              />
              <Collapse in={showForm}>
                <Grid container className={classes.inputContainer}>
                  <Grid item lg={3} sm={4} xl={3} xs={4}>
                    <SkyTextField
                      label={t('Drawer_mobile_forwarding_country_code')}
                      value={countryCode}
                      onChange={handleCountryCode}
                      disabled={loading}
                      required
                    />
                  </Grid>
                  <Grid item lg={9} sm={8} xl={9} xs={8}>
                    <SkyTextField
                      label={t('Drawer_mobile_forwarding_number')}
                      value={forwardingNumber}
                      onChange={handleForwardingNumber}
                      disabled={loading}
                      required
                    />
                  </Grid>
                </Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(conditionalForwarding)}
                      onChange={handleConditionalForwarding}
                      disabled={loading}
                      value={conditionalForwarding}
                      color="primary"
                    />
                  }
                  label={t('Drawer_mobile_forwarding_cond_forwarding')}
                />
                <Typography variant="body2" color="textSecondary">
                  {t('Drawer_mobile_forwarding_cond_forwarding_desc')}
                </Typography>
                {countryCode.length >= 2 && countryCode !== '45' && (
                  <Typography variant="body2" color="textSecondary">
                    {t('Drawer_mobile_forwarding_cond_int_desc')}
                  </Typography>
                )}
              </Collapse>
            </>
          )}
        </DrawerSection>
        <DrawerButtons
          withSubmitButton={!hasMobileOneNumber && forwardingData.forward_is_possible}
          backLink={backLink}
          disabled={showForm && forwardingNumber.length < 8}
          onSubmit={handleForwarding}
          submitText={t('Drawer_mobile_forwarding_save')}
          loading={loading}
        />
      </div>
    </Slide>
  )
}

export default MobileCallForwardingForm
