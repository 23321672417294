import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  caption: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const PaperCaption = ({ content }) => {
  const classes = useStyles()
  return (
    <Typography variant="body1" className={classes.caption}>
      {content}
    </Typography>
  )
}

export default PaperCaption
