import { getCustomer } from 'services/sweb/customer'
import {
  INTERNATIONAL_NUMBERS_V2_FAILURE,
  INTERNATIONAL_NUMBERS_V2_BEGIN,
  INTERNATIONAL_NUMBERS_V2_SUCCESS,
  ParamsProps,
  IntNumbersActions,
  IntNumbersState,
} from './model'

export const fetchInternationalNumbersV2Failure = (
  error: IntNumbersState['error']
): IntNumbersActions => {
  return {
    type: INTERNATIONAL_NUMBERS_V2_FAILURE,
    error,
  }
}

export const fetchInternationalNumbersV2Begin = ({
  customerId,
}: ParamsProps): IntNumbersActions => {
  return {
    type: INTERNATIONAL_NUMBERS_V2_BEGIN,
    customerId,
  }
}

export const fetchInternationalNumbersV2Success = (
  data: IntNumbersState['data']
): IntNumbersActions => {
  return {
    type: INTERNATIONAL_NUMBERS_V2_SUCCESS,
    data,
  }
}

export const fetchInternationalNumbersV2 = ({ customerId, pageSize, pageNumber }: ParamsProps) => {
  return async (dispatch) => {
    dispatch(fetchInternationalNumbersV2Begin({ customerId }))
    try {
      const response = await getCustomer.internationalNumbersV2({
        customerId,
        pageSize,
        pageNumber,
      })
      dispatch(fetchInternationalNumbersV2Success(response.data))
    } catch (err) {
      dispatch(fetchInternationalNumbersV2Failure(err.response))
    }
  }
}
