import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch, batch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { fetchMobileNumbersCombined } from 'state/mobileNumbersCombined/actions'
import { fetchUnassignedSimcards } from 'state/unassignedSimcards/actions'
import { fetchValidateImportMobileNumber } from 'state/validateImportMobileNumber/actions'
import { getValidation } from 'services/sweb/validation'
import { Collapse, Button } from '@material-ui/core'
import { SkySelect, SkyTextField } from 'components'
import { useTranslation } from 'react-i18next'
import AlternativeSimcardAddress from '../AlternativeSimcardAddress'
import { validateMobileForm, formatSimcardOptions } from '../../helpers/subscription'
import NumberInUseBox from '../NumberInUseBox'

interface MobileFormProps {
  initialForm: any
  mobilePackages: any
  onChange: (form: any) => void
  processingOrder: any
  disableImport?: boolean
  azureId?: string
  noneAd?: boolean
}

const MobileForm = ({
  initialForm,
  mobilePackages,
  onChange,
  processingOrder,
  disableImport = false,
  azureId,
  noneAd,
}: MobileFormProps) => {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const stored = useSelector(
    (state) => ({
      customer: state.customer,
      mobileNumbers: state.mobileNumbersCombined,
      unassignedSimcards: state.unassignedSimcards,
      validateImportMobileNumber: state.validateImportMobileNumber,
    }),
    shallowEqual
  )

  const [inUseInformation, setInUseInformation] = useState(null)
  const [mobilePackage, setMobilePackage] = useState(initialForm.mobilePackage)
  const [mobileNumber, setMobileNumber] = useState(initialForm.mobileNumber)
  const [importMobileNumber, setImportMobileNumber] = useState(initialForm.importMobileNumber)
  const [importSigner, setImportSigner] = useState(initialForm.importSigner)
  const [simcard, setSimcard] = useState(initialForm.simcard)
  const [datasim, setDatasim] = useState(initialForm.datasim)
  const [alternativeSimcardAddress, setAlternativeSimcardAddress] = useState(
    initialForm.alternativeSimcardAddress
  )

  const handleGoToSetupWizard = () => {
    push(`/${stored.customer.customerId}/setup`)
  }

  useEffect(() => {
    const isValid = validateMobileForm({
      mobileNumber,
      importMobileNumber,
      mobilePackage,
      simcard,
    })
    onChange({
      isValid,
      mobilePackage,
      mobileNumber,
      importMobileNumber,
      importSigner,
      simcard,
      datasim,
      alternativeSimcardAddress,
    })
  }, [
    mobilePackage,
    mobileNumber,
    importMobileNumber,
    importSigner,
    simcard,
    datasim,
    alternativeSimcardAddress,
  ])

  useEffect(() => {
    batch(() => {
      dispatch(
        fetchMobileNumbersCombined({ customerId: stored.customer.customerId, disableImport })
      )
      dispatch(fetchUnassignedSimcards({ customerId: stored.customer.customerId }))
    })
  }, [])

  useEffect(() => {
    if (importMobileNumber && importMobileNumber.length === 8) {
      dispatch(
        fetchValidateImportMobileNumber({
          customerId: stored.customer.customerId,
          phoneNumber: importMobileNumber,
        })
      )
    }
  }, [importMobileNumber])

  const handleSetMobilePackage = (event, value) => {
    setMobilePackage(value)
  }

  const handleSetMobileNumber = (event, val) => {
    const { formattedPhoneNumber, ...otherProps } = val
    setMobileNumber({ ...otherProps, isValid: null })
    if (val.value === 'port' && simcard) {
      setSimcard(null)
    }
    const prefix = 45
    if (val.value && val.value.number) {
      getValidation
        .validatePhoneNumber({
          customerId: stored.customer.customerId,
          phoneNumber: val.value.number,
          prefix,
          azureId,
        })
        .then((response) => {
          setMobileNumber({ ...otherProps, isValid: !response.data.found })
          setInUseInformation(
            response.data.locationDetails && response.data.locationDetails.length > 0
              ? response.data.locationDetails
              : null
          )
        })
        .catch((err) => {
          if (err.response) {
            err.response.json().then((response) => {
              if (response.error.key === '_ServerError_') {
                enqueueSnackbar(t('CheckSetupWizardError'), {
                  variant: 'warning',
                  action: () => {
                    return (
                      <Button
                        fullWidth
                        disableElevation
                        color="secondary"
                        variant="contained"
                        onClick={handleGoToSetupWizard}
                      >
                        {t('GoToAzureIntegration')}
                      </Button>
                    )
                  },
                })
              }
            })
          }
          setMobileNumber({ ...otherProps, isValid: false })
          setInUseInformation(null)
        })
    }
  }

  const handleSetImportMobileNumber = (event) => {
    const val = event.target.value
    if (val.length <= 8) {
      setImportMobileNumber(val)
    }
    if (simcard) setSimcard(null)
  }

  const handleSetImportSigner = (event) => {
    const val = event.target.value
    setImportSigner(val)
  }

  const handleSetSimcard = (event, value) => {
    setSimcard(value)
  }

  const handleSetDatasim = (event, value) => {
    setDatasim(value)
  }

  const handleSetAlternativeSimcardAddress = (val) => {
    setAlternativeSimcardAddress(val)
  }

  const mobileNumbersError =
    !stored.mobileNumbers.loading &&
    stored.mobileNumbers.data &&
    stored.mobileNumbers.data.length === 0

  const selectedMobileNumberError =
    mobileNumber && mobileNumber.value && mobileNumber.isValid === false

  const importMobileNumberError =
    !stored.validateImportMobileNumber.loading &&
    stored.validateImportMobileNumber.error &&
    stored.validateImportMobileNumber.phoneNumber === importMobileNumber

  const mobilePackageOptions = mobilePackages
    .map((x) => {
      return {
        label: x.product_name,
        endAdornment: `${x.monthly_price},-`,
        value: x,
      }
    })
    .sort((a, b) => a.value.monthly_price - b.value.monthly_price)

  const selectedMobileNumberValidationPending =
    mobileNumber && mobileNumber.value && mobileNumber.value.ani_id && mobileNumber.isValid === null
  return (
    <>
      <SkySelect
        label={t('Drawer_create_choose_package')}
        required
        disableClearable
        value={mobilePackage}
        onChange={handleSetMobilePackage}
        options={mobilePackageOptions}
        error={mobilePackages.length === 0}
        disabled={processingOrder}
        helperText={mobilePackages.length === 0 && t('Drawer_create_no_mobile_packages')}
      />
      <SkySelect
        label={t('Drawer_create_choose_mobile_number')}
        value={mobileNumber}
        disableClearable
        required
        onChange={handleSetMobileNumber}
        options={
          (stored.mobileNumbers.data &&
            stored.mobileNumbers.data.length > 0 &&
            stored.mobileNumbers.data.map((x) => ({
              ...x,
              label: t(x.label),
              group: t(x.group),
              formattedPhoneNumber: t(x.label) + t(x.label)?.replace(/\s/g, ''),
            }))) ||
          []
        }
        loading={stored.mobileNumbers.loading || selectedMobileNumberValidationPending}
        error={mobileNumbersError || selectedMobileNumberError}
        groupBy={(option) => option.group}
        disabled={processingOrder || selectedMobileNumberValidationPending}
        helperText={mobileNumbersError && t('Drawer_create_no_mobile')}
      />
      {!selectedMobileNumberValidationPending &&
        inUseInformation &&
        inUseInformation.map((x) => {
          return (
            <NumberInUseBox
              key={x.details}
              details={x.details}
              user={x.user}
              isDeleted={x.isDeleted}
            />
          )
        })}
      <Collapse in={mobileNumber && mobileNumber.value === 'port'}>
        <SkyTextField
          label={t('TypeMobile')}
          value={importMobileNumber}
          type="number"
          onChange={handleSetImportMobileNumber}
          disabled={processingOrder}
          error={importMobileNumberError}
          helperText={importMobileNumberError && t('Drawer_create_cant_import')}
          required
        />
        <SkyTextField
          disabled={processingOrder}
          label={t('Drawer_create_signer_email')}
          value={importSigner}
          onChange={handleSetImportSigner}
          required
        />
      </Collapse>

      {Boolean(!mobileNumber || (mobileNumber && mobileNumber.value !== 'port')) && (
        <SkySelect
          label={t('Drawer_create_choose_simcard')}
          value={simcard}
          required
          disableClearable
          loading={stored.unassignedSimcards.loading}
          onChange={handleSetSimcard}
          disabled={processingOrder}
          options={
            stored.unassignedSimcards.data
              ? formatSimcardOptions(stored.unassignedSimcards.data, noneAd).map((x) => {
                  return {
                    ...x,
                    label: t(x.label),
                    group: t(x.group),
                  }
                })
              : []
          }
          groupBy={(option) => option.group}
          getOptionDisabled={(option) =>
            (option.value === 'activateExisting' && !mobileNumber) ||
            (option.value === 'activateExisting' &&
              mobileNumber &&
              mobileNumber.value.ani_id &&
              mobileNumber.value.ani_id < 0) ||
            (option.value === 'activateExisting' && mobileNumber && !mobileNumber.value.ani_id)
          }
        />
      )}
      {simcard && simcard.value === 'orderNew' && (
        <AlternativeSimcardAddress
          address={initialForm.alternativeSimcardAddress}
          customerAddress={stored.customer.data.address}
          onChange={handleSetAlternativeSimcardAddress}
        />
      )}
      <SkySelect
        label={t('Drawer_create_add_datashare')}
        value={datasim}
        onChange={handleSetDatasim}
        disabled={processingOrder}
        options={[
          {
            label: t('Drawer_datashare_select', { amount: 1 }),
            value: 1,
          },
          {
            label: t('Drawer_datashare_select', { amount: 2 }),
            value: 2,
          },
          {
            label: t('Drawer_datashare_select', { amount: 3 }),
            value: 3,
          },
        ]}
      />
    </>
  )
}

export default MobileForm
