import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'availableReleaseMobileNumbers/FETCH_FAILURE'
export const FETCH_BEGIN = 'availableReleaseMobileNumbers/FETCH_BEGIN'
export const FETCH_SUCCESS = 'availableReleaseMobileNumbers/FETCH_SUCCESS'

export const fetchAvailableReleaseMobileNumbersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAvailableReleaseMobileNumbersBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchAvailableReleaseMobileNumbersSuccess = ({ customerId, data }) => {
  return {
    type: FETCH_SUCCESS,
    customerId,
    data,
  }
}

export const fetchAvailableReleaseMobileNumbers = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchAvailableReleaseMobileNumbersBegin({ customerId }))
    try {
      const response = await getCustomer.availableReleaseMobileNumbers({
        customerId,
      })
      dispatch(fetchAvailableReleaseMobileNumbersSuccess({ customerId, data: response.data }))
    } catch (err) {
      dispatch(fetchAvailableReleaseMobileNumbersFailure(err.response))
    }
  }
}
