import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchPartyMe } from 'state/partyMe/actions'
import { Redirect } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import {
  Typography,
  Link,
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Box,
  Avatar,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import { Paper, PaperContent, PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import * as authenticate from 'services/partyweb/authentication'
import { IMG_MICROSOFT_LOGO, IMG_SOMMER_NISSE, IMG_PARTY_LIGHT_BULBS } from 'constants/cdn'
import { MS_AUTH_REDIRECTED_PARTY_OUTSIDE_TEAMS_CALLBACK_URI } from 'constants/app'
import { authViaWindowOpen } from 'services/msauth'

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: '#fffa1e',
    },
  },
  body: {
    backgroundImage: `url(${IMG_PARTY_LIGHT_BULBS})`,
    backgroundRepeat: 'repeat-x',
    backgroundSize: 'contain',
    backgroundPosition: 'top',
    width: '100vw',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  microsoftButton: {
    margin: '1rem auto 0',
    borderRadius: '4px',
    width: 300,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  paper: {
    width: '100%',
    minHeight: 500,
    margin: '5.5rem auto 1rem',
    padding: '2rem',
    '@media (min-width: 960px)': {
      maxWidth: '700px',
    },
    '& > div': {
      width: '100%',
    },
    '& form': {
      textAlign: 'center',
      margin: '2rem 0 1rem',
      '&>div': {
        margin: '.5rem 0 2rem',
        width: '300px',
      },
      '& >p': {
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    },
    '& button, & a': {
      marginTop: '2rem',
    },
  },
  supportLink: {
    color: theme.palette.text.secondary,
  },

  success: {
    background: theme.palette.primary.light,
  },
  container: {
    textAlign: 'center',
    '& >a': {
      marginRight: '1rem',
    },
  },
  langButton: {
    border: 'none',
    cursor: 'pointer',
    background: 'none',
  },
  langImage: {
    borderRadius: '50%',
    verticalAlign: 'middle',
  },
  avatar: {
    background: theme.palette.error.main,
    margin: '0 auto 2rem',
    height: 80,
    width: 80,
    '&>svg': {
      height: 40,
      width: 40,
    },
  },
  mail: {
    color: theme.palette.primary.main,
    margin: '1rem 0',
    display: 'inline-block',
  },
}))

const exchangeMsOAuthCodeToSwebToken = ({ code }) => {
  const redirectUri = MS_AUTH_REDIRECTED_PARTY_OUTSIDE_TEAMS_CALLBACK_URI
  return authenticate
    .msAuthenticate({ code, redirectUri })
    .then(({ data }) => authenticate.addBearer(data.access_token))
}

const Login = ({ location }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [status, setStatus] = useState<{
    code?: string
    description?: JSX.Element
    icon?: JSX.Element
  }>({})

  const [isLoading, setIsLoading] = useState(false)
  const { partyMe } = useSelector((state) => state)

  const handleExchangeMsOAuthCodeToSwebToken = (code) => {
    setIsLoading(true)
    exchangeMsOAuthCodeToSwebToken({ code })
      .then(() => dispatch(fetchPartyMe()))
      .catch(async (err) => {
        setIsLoading(false)
        if (err.response?.status === 401) {
          const { error } = await err.response.json()
          const mail = error?.items[0]?.key
          setStatus({
            code: '401',
            description: (
              <Typography variant="h4" align="center">
                Unfortunately this mail <br /> <span className={classes.mail}>{mail}</span>
                <br />
                is not invited to the party
              </Typography>
            ),
            icon: <WarningIcon />,
          })
          err.response.json().then((res) => {
            enqueueSnackbar(`${res.error.text}`, { variant: 'error' })
          })
        } else {
          setStatus({
            code: err.response?.status || '404',
            description: (
              <Typography variant="h4" align="center">
                Something went wrong, please try again
              </Typography>
            ),
            icon: <ErrorIcon />,
          })

          enqueueSnackbar(t('OC_invite_error'), { variant: 'error' })
        }
      })
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location?.search)
    const code = queryParams.get('code')
    if (code && partyMe.isAuthenticated === false) {
      handleExchangeMsOAuthCodeToSwebToken(code)
    }
  }, [])

  if (partyMe.isAuthenticated) {
    const getFirstParty = partyMe.data?.parties[0].id
    return (
      <Redirect
        to={{
          pathname: `/party/id/${getFirstParty || 0}`,
          search: location.search,
          state: { from: location.pathname },
        }}
      />
    )
  }

  const authWindowOpen = () => {
    authViaWindowOpen({ partyAccess: true }).then(({ code }) =>
      handleExchangeMsOAuthCodeToSwebToken(code)
    )
  }

  const loading = partyMe.loading || isLoading

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <PaperContent>
            {status.code ? (
              <Box>
                <Avatar className={classes.avatar}>{status.icon}</Avatar>
                {status.description}
              </Box>
            ) : (
              <>
                <Typography variant="h3" align="center" gutterBottom>
                  Welcome to the party sign up page
                </Typography>
                <img src={IMG_SOMMER_NISSE} height="250" alt="Log in with Microsoft" />

                <Typography variant="h4" align="center">
                  Use your work mail for sign-up
                </Typography>
                <List>
                  {loading ? (
                    <PaperLoader />
                  ) : (
                    <ListItem button onClick={authWindowOpen} className={classes.microsoftButton}>
                      <ListItemIcon>
                        <img
                          src={IMG_MICROSOFT_LOGO}
                          width="20px"
                          height="20px"
                          alt="Log in with Microsoft"
                        />
                      </ListItemIcon>
                      <ListItemText primary="Log in with Microsoft" />
                    </ListItem>
                  )}
                </List>
              </>
            )}
          </PaperContent>
          <Typography variant="body1" align="center" className={classes.supportLink} gutterBottom>
            If you have any questions, please feel free to contact
            <br />
            <Link href="mailto:assistants@nuuday.dk">Assistants@nuuday.dk</Link>
          </Typography>
        </Paper>
        <Link href="https://tdc.dk/cookiepolitik" target="_blank">
          Cookie policy
        </Link>
        <Link href="https://tdc.dk/persondatameddelelse" target="_blank">
          Personal data message
        </Link>
      </div>
    </div>
  )
}

export default Login
