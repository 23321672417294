import React from 'react'
import { StatusCircle } from 'components'
import { useTranslation } from 'react-i18next'

const StatusChip = ({ status }) => {
  const { t } = useTranslation()

  let label
  let color

  switch (status) {
    case 'NEW':
      label = t('Dashboard_tickets_chip_new')
      color = 'primary'
      break
    case 'OPEN':
      label = t('Dashboard_tickets_chip_open')
      color = 'danger'
      break
    case 'PENDING':
      label = t('Dashboard_tickets_chip_pending')
      color = 'warning'
      break
    case 'HOLD':
      label = t('Dashboard_tickets_chip_hold')
      color = 'neutral'
      break
    case 'SOLVED':
      label = t('Dashboard_tickets_chip_solved')
      color = 'success'
      break
    case 'CLOSED':
      label = t('Dashboard_tickets_chip_closed')
      color = 'neutral'
      break
    default:
      label = t('Dashboard_tickets_chip_new')
      color = 'primary'
  }

  return (
    <>
      <StatusCircle size="sm" className={color} />
      {label}
    </>
  )
}

export default StatusChip
