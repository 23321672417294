import React, { useEffect, useState } from 'react'
import { RouteComponentProps, Switch, Redirect, Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchOCLandlineNumberOrders } from 'state/oCLandlineNumberOrders/actions'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useTranslation } from 'react-i18next'
import { Paper, PaperContent, Alert } from '@skytdc/mui/components'
import OrdersTable from './components/OrdersTable'
import OrderDrawer from './components/OrderDrawer'

interface Props extends RouteComponentProps {
  customerId: string
  history: any
}

const Orders = ({ customerId, history }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [disableUpdateButton, setDisableUpdateButton] = useState<boolean>(true)
  const { loading, data, pageNumber, pageSize, error } = useSelector(
    (state) => state.oCLandlineNumberOrders
  )
  const { totalResultSize, isThereMoreData } = data?.pageing || {}
  const progress = Math.ceil((data?.data?.length * 100) / totalResultSize) || 0
  useEffect(() => {
    changeDocumentTitle(t('OC_numbers_orders'), ' - ', ':applicationName')
  }, [])

  useEffect(() => {
    if (
      (!loading && !pageNumber && !error) ||
      (!loading &&
        !error &&
        data?.data.length < totalResultSize &&
        pageNumber <= Math.ceil(totalResultSize / pageSize) - 1)
    ) {
      dispatch(fetchOCLandlineNumberOrders({ customerId, pageNumber, pageSize }))
    }
  }, [!loading])

  useEffect(() => {
    if (disableUpdateButton && data?.data.length && !isThereMoreData && !loading) {
      setDisableUpdateButton(false)
    }
  }, [!loading])

  const handleUpdateOrders = () => {
    setDisableUpdateButton(true)
    dispatch(fetchOCLandlineNumberOrders({ customerId, pageNumber: 0, pageSize }))
  }

  const handleOpenOrderDrawer = (orderId: string) => {
    history.push(`/${customerId}/operator-connect/numre/ordrer/${orderId}`)
  }

  if (error && error.statusText) {
    return (
      <Paper>
        <PaperContent>
          <Alert type="error" message={error.statusText} />
        </PaperContent>
      </Paper>
    )
  }

  return (
    <>
      <OrdersTable
        orders={data?.data || []}
        loading={
          (loading && !data?.data) ||
          (disableUpdateButton && loading && data?.data.length === totalResultSize)
        }
        openOrder={handleOpenOrderDrawer}
        progress={progress}
        updateOrders={{ event: handleUpdateOrders, disable: disableUpdateButton }}
      />
      <Switch>
        {data?.data.map((x) => {
          return (
            <Route
              key={x.orderId}
              render={(props) => <OrderDrawer {...props} {...x} customerId={customerId} />}
              exact
              path={`/${customerId}/operator-connect/numre/ordrer/${x.orderId}`}
            />
          )
        })}
        {data?.data && <Redirect to={`/${customerId}/operator-connect/numre/ordrer`} />}
      </Switch>
    </>
  )
}

export default Orders
