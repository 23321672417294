import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'availableHuntgroupMembers/FETCH_FAILURE'
export const FETCH_BEGIN = 'availableHuntgroupMembers/FETCH_BEGIN'
export const FETCH_SUCCESS = 'availableHuntgroupMembers/FETCH_SUCCESS'

export const fetchAvailableHuntgroupMembersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAvailableHuntgroupMembersBegin = ({ number }) => {
  return {
    type: FETCH_BEGIN,
    number,
  }
}

export const fetchAvailableHuntgroupMembersuccess = ({ number, data }) => {
  return {
    type: FETCH_SUCCESS,
    number,
    data,
  }
}

export const fetchAvailableHuntgroupMembers = ({ customerId, number }) => {
  return async (dispatch) => {
    dispatch(fetchAvailableHuntgroupMembersBegin({ number }))
    try {
      const response = await getCustomer.availableHuntgroupMembers({
        customerId,
        number,
      })
      dispatch(fetchAvailableHuntgroupMembersuccess({ number, data: response.data }))
    } catch (err) {
      dispatch(fetchAvailableHuntgroupMembersFailure(err.response))
    }
  }
}
