import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'dataUsageWarningEmails/FETCH_FAILURE'
export const FETCH_BEGIN = 'dataUsageWarningEmails/FETCH_BEGIN'
export const FETCH_SUCCESS = 'dataUsageWarningEmails/FETCH_SUCCESS'

export const fetchDataUsageWarningEmailsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchDataUsageWarningEmailsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchDataUsageWarningEmailsSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchDataUsageWarningEmails = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchDataUsageWarningEmailsBegin({ customerId }))
    try {
      const response = await getCustomer.dataUsageEmails({
        customerId,
      })
      dispatch(fetchDataUsageWarningEmailsSuccess(response.data))
    } catch (err) {
      dispatch(fetchDataUsageWarningEmailsFailure(err.response))
    }
  }
}
