import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { fetchInvoiceNumberSpecifications } from 'state/invoiceNumberSpecifications/actions'
import { PaperLoader, PaperFeedbackMessage } from 'components'
import { useTranslation } from 'react-i18next'
import TableWithFilteredTotal from '../../components/TableWithFilteredTotal'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: 400,
    backgroundColor: theme.palette.primary.light,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    '& div': {
      background: theme.palette.primary.light,
    },
    '& div:first-child div:nth-child(3)': {
      // Table
      maxHeight: 300,
      overflow: 'auto',
    },
    '& div:first-child div:nth-child(3) td': {
      // tds in first table
      borderBottom: '2px solid #fff',
    },
    '& div:first-child div:nth-child(3) th': {
      // ths in first table
      borderBottom: '2px solid #fff',
    },
    '& div:first-child table tbody tr:first-child td': {
      // td in pagination
      borderBottom: '2px solid #fff',
    },
    '& div:first-child div:first-child div:first-child div:first-child div': {
      // Search bar
      background: '#fff',
    },
  },
}))

const ExpandedNumberTable = ({ invoiceId, anr }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const { invoices } = useSelector((state) => state.invoice.data || {})
  const specifications = useSelector((state) => state.invoiceNumberSpecifications)
  const anrMatchesState = Boolean(specifications.anr && specifications.anr === anr)

  const columns = [
    {
      name: 'start_date',
      label: t('Invoice_date'),
      align: 'left',
      options: {
        sortDirection: 'asc',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'bnr',
      label: t('Invoice_receiver'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: t('Invoice_description'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'duration.formatted',
      label: t('Invoice_duration'),
      align: 'right',
      options: {
        filter: true,
        sort: true,
      },
    },
  ]

  useEffect(() => {
    let mounted = true
    if (invoices) {
      const currentInvoice = invoices.find((item) => item.billing_id === invoiceId)
      const {
        customer_id: customerId,
        billing_from: startdate,
        billing_to: enddate,
      } = currentInvoice
      if (mounted) {
        dispatch(
          fetchInvoiceNumberSpecifications({ customerId, invoiceId, anr, startdate, enddate })
        )
      }
    }
    return () => {
      mounted = false
    }
  }, [])

  let content = <PaperLoader />

  if (specifications.error && anrMatchesState) {
    content = (
      <PaperFeedbackMessage type="error" message={t('Dashboard_stock_some_error')} withMargin />
    )
  }

  if (specifications.data && specifications.data.items.length === 0 && anrMatchesState) {
    content = <PaperFeedbackMessage message={t('Invoice_no_occurences')} withMargin />
  }

  if (specifications.data && specifications.data.items.length > 0 && anrMatchesState) {
    content = (
      <TableWithFilteredTotal
        items={specifications.data.items}
        firstTableColumns={columns}
        totalPrice={specifications.data.aggregated.charges}
      />
    )
  }

  return (
    <tr>
      <td colSpan="10" className={classes.tableContainer}>
        {content}
      </td>
    </tr>
  )
}

export default React.memo(ExpandedNumberTable)
