import React from 'react'
import classNames from 'classnames'
import { Avatar, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import useButtonStyles from '../../hooks/useButtonStyle'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    transition: theme.transitions.create(['box-shadow', 'background-color'], {
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      cursor: 'pointer',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.03)',
    },
    height: 180,
    borderRadius: 10,
  },
  innerContainer: {
    width: 180,
  },
  avatar: {
    borderRadius: 10,
    marginBottom: theme.spacing(1.4),
  },
}))

interface Props {
  className?: string
  icon?: JSX.Element
  title: string
  color?: 'success' | 'warning' | 'primary' | 'error'
  description?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

const CategoryButton = ({
  className,
  icon,
  title,
  color = 'primary',
  description,
  onClick,
}: Props) => {
  const classes = useStyles()
  const buttons = useButtonStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
    },
    className
  )

  const avatarClassName = classNames({
    [classes.avatar]: true,
    [buttons.success]: color === 'success',
    [buttons.warning]: color === 'warning',
    [buttons.primary]: color === 'primary',
    [buttons.error]: color === 'error',
  })

  return (
    // eslint-disable-next-line
    <div className={rootClassName} onClick={onClick}>
      <div className={classes.innerContainer}>
        <Avatar variant="rounded" className={avatarClassName}>
          {icon}
        </Avatar>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          {description}
        </Typography>
      </div>
    </div>
  )
}

export default CategoryButton
