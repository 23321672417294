import { combineReducers } from 'redux'
import me from './me/reducers'
import admins from './admins/reducers'
import customer from './customer/reducers'
import customers from './customers/reducers'
import users from './users/reducers'
import invoice from './invoice/reducers'
import portfolio from './portfolio/reducers'
import licenseDetails from './licenseDetails/reducers'
import validateLandlineNumber from './validateLandlineNumber/reducers'
import validateMobileNumber from './validateMobileNumber/reducers'
import availableReleaseMobileNumbers from './availableReleaseMobileNumbers/reducers'
import availableLandlineNumbers from './availableLandlineNumbers/reducers'
import availableInternationalNumbers from './availableInternationalNumbers/reducers'
import availableAtRestMobileNumbers from './availableAtRestMobileNumbers/reducers'
import mobileNumbersCombined from './mobileNumbersCombined/reducers'
import landlineNumbersCombined from './landlineNumbersCombined/reducers'
import unassignedSimcards from './unassignedSimcards/reducers'
import generateExtensions from './generateExtensions/reducers'
import validateExtension from './validateExtension/reducers'
import validateImportMobileNumber from './validateImportMobileNumber/reducers'
import subscriptionCount from './subscriptionCount/reducers'
import zendeskTickets from './zendeskTickets/reducers'
import addons from './addons/reducers'
import subscription from './subscription/reducers'
import guardApp from './guardApp/reducers'
import showNumberSkyapp from './showNumberSkyapp/reducers'
import availableMobilePackages from './availableMobilePackages/reducers'
import mobilePackages from './mobilePackages/reducers'
import dataPool from './dataPool/reducers'
import dataUsages from './dataUsages/reducers'
import myDataUsages from './myDataUsages/reducers'
import dataPoolUsage from './dataPoolUsage/reducers'
import dataPoolTopSpenders from './dataPoolTopSpenders/reducers'
import simcards from './simcards/reducers'
import mobileCallForwarding from './mobileCallForwarding/reducers'
import mobileDisplayNumber from './mobileDisplayNumber/reducers'
import landlineDisplayNumber from './landlineDisplayNumber/reducers'
import landlinePrivateNumber from './landlinePrivateNumber/reducers'
import simcardOrders from './simcardOrders/reducers'
import subSharedNumbers from './subSharedNumbers/reducers'
import availableDisplayNumbers from './availableDisplayNumbers/reducers'
import azureAppSecrets from './azureAppSecrets/reducers'
import azureAdminSecrets from './azureAdminSecrets/reducers'
import azureHealthCheck from './azureHealthCheck/reducers'
import azureDomains from './azureDomains/reducers'
import eInvoice from './eInvoice/reducers'
import authorizations from './authorizations/reducers'
import availableRoles from './availableRoles/reducers'
import validateEmail from './validateEmail/reducers'
import teamsUsage from './teamsUsage/reducers'
import userProfilePhoto from './userProfilePhoto/reducers'
import invoiceSubscriptionDetails from './invoiceSubscriptionDetails/reducers'
import invoiceMobileSpecifications from './invoiceMobileSpecifications/reducers'
import invoiceLandlineSpecifications from './invoiceLandlineSpecifications/reducers'
import invoiceMobileSummary from './invoiceMobileSummary/reducers'
import invoiceMobileIntSummary from './invoiceMobileIntSummary/reducers'
import invoiceMobileNumberSummary from './invoiceMobileNumberSummary/reducers'
import invoiceLandlineSummary from './invoiceLandlineSummary/reducers'
import invoiceLandlineIntSummary from './invoiceLandlineIntSummary/reducers'
import invoiceLandlineNumberSummary from './invoiceLandlineNumberSummary/reducers'
import invoiceExtraServicesDetails from './invoiceExtraServicesDetails/reducers'
import invoiceNumberSpecifications from './invoiceNumberSpecifications/reducers'
import invoiceReferences from './invoiceReferences/reducers'
import invoiceSubscriptions from './invoiceSubscriptions/reducers'
import invoiceLocationTypes from './invoiceLocationTypes/reducers'
import invoiceNumberReferences from './invoiceNumberReferences/reducers'
import invoiceReferenceNames from './invoiceReferenceNames/reducers'
import invoiceReferenceSpecifications from './invoiceReferenceSpecifications/reducers'
import endUserSubscription from './endUserSubscription/reducers'
import endUserLicenseDetails from './endUserLicenseDetails/reducers'
import endUserProfileImage from './endUserProfileImage/reducers'
import endUserSimcards from './endUserSimcards/reducers'
import endUserSimcardOrders from './endUserSimcardOrders/reducers'
import huntgroups from './huntgroups/reducers'
import huntgroup from './huntgroup/reducers'
import huntgroupCallPatterns from './huntgroupCallPatterns/reducers'
import huntgroupSubscription from './huntgroupSubscription/reducers'
import availableHuntgroupMembers from './availableHuntgroupMembers/reducers'
import availableHuntgroupNumbers from './availableHuntgroupNumbers/reducers'
import orderOverview from './orderOverview/reducers'
import orderDetailsOverview from './orderDetailsOverview/reducers'
import order from './order/reducers'
import adGroupFilters from './adGroupFilters/reducers'
import availableAdGroups from './availableAdGroups/reducers'
import teamsUsers from './teamsUsers/reducers'
import psWebScripts from './psWebScripts/reducers'
import dataUsageWarningEmails from './dataUsageWarningEmails/reducers'
import buaOrders from './buaOrders/reducers'
import internationalNumbersV2 from './internationalNumbersV2/reducers'
import intNumberMediation from './intNumberMediation/reducers'
import intNumbersOrders from './intNumbersOrders/reducers'
import intNumberVoxbone from './intNumberVoxbone/reducers'
import intNumberCapacityGroup from './intNumberCapacityGroup/reducers'
import intNumberVoiceUri from './intNumberVoiceUri/reducers'
import intNumberCountryList from './intNumberCountryList/reducers'
import intNumberVoxboneStock from './intNumberVoxboneStock/reducers'
import intNumberAddonProducts from './intNumberAddonProducts/reducers'
import billingCountries from './billingCountries/reducers'
import externalProviders from './externalProviders/reducers'
import presenceAppSecrets from './presenceAppSecrets/reducers'
import presenceProduct from './presenceProduct/reducers'
import presenceHealthCheck from './presenceHealthCheck/reducers'
import oCLandlineNumbers from './oCLandlineNumbers/reducers'
import oCLandlineNumberOrders from './oCLandlineNumberOrders/reducers'
import oCUsers from './oCUsers/reducers'
import oCInvites from './oCInvites/reducers'
import oCNumbers from './oCNumbers/reducers'
import oCNumbersProducts from './oCNumbersProducts/reducers'
import partyMe from './partyMe/reducers'
import partyTransport from './partyTransport/reducers'
import partyAllParties from './partyAllParties/reducers'
import partyAllSignUps from './partyAllSignUps/reducers'
import skyAppContacts from './skyAppContacts/reducers'
import skyAppHuntgroups from './skyAppHuntgroups/reducers'
import displayNumberActive from './displayNumberActive/reducers'
import displayNumberAvailable from './displayNumberAvailable/reducers'
import displayNumberWeeklySchedule from './displayNumberWeeklySchedule/reducers'

const reducers = {
  me,
  admins,
  customer,
  customers,
  dataUsages,
  dataPool,
  dataPoolUsage,
  dataPoolTopSpenders,
  users,
  invoice,
  portfolio,
  licenseDetails,
  validateLandlineNumber,
  validateMobileNumber,
  availableLandlineNumbers,
  availableInternationalNumbers,
  availableAtRestMobileNumbers,
  availableReleaseMobileNumbers,
  mobileNumbersCombined,
  myDataUsages,
  landlineNumbersCombined,
  unassignedSimcards,
  generateExtensions,
  validateExtension,
  validateImportMobileNumber,
  subscriptionCount,
  zendeskTickets,
  addons,
  subscription,
  guardApp,
  showNumberSkyapp,
  availableMobilePackages,
  mobilePackages,
  simcards,
  mobileCallForwarding,
  mobileDisplayNumber,
  landlineDisplayNumber,
  landlinePrivateNumber,
  simcardOrders,
  subSharedNumbers,
  availableDisplayNumbers,
  azureAppSecrets,
  azureAdminSecrets,
  azureHealthCheck,
  azureDomains,
  eInvoice,
  authorizations,
  availableRoles,
  validateEmail,
  teamsUsage,
  userProfilePhoto,
  invoiceSubscriptionDetails,
  invoiceExtraServicesDetails,
  invoiceMobileSpecifications,
  invoiceLandlineSpecifications,
  invoiceMobileSummary,
  invoiceMobileIntSummary,
  invoiceMobileNumberSummary,
  invoiceLandlineSummary,
  invoiceLandlineIntSummary,
  invoiceLandlineNumberSummary,
  invoiceNumberSpecifications,
  invoiceReferences,
  invoiceSubscriptions,
  invoiceLocationTypes,
  invoiceNumberReferences,
  invoiceReferenceNames,
  invoiceReferenceSpecifications,
  endUserSubscription,
  endUserLicenseDetails,
  endUserProfileImage,
  endUserSimcards,
  endUserSimcardOrders,
  huntgroups,
  huntgroup,
  huntgroupSubscription,
  availableHuntgroupMembers,
  huntgroupCallPatterns,
  availableHuntgroupNumbers,
  orderOverview,
  orderDetailsOverview,
  order,
  adGroupFilters,
  availableAdGroups,
  teamsUsers,
  psWebScripts,
  dataUsageWarningEmails,
  buaOrders,
  intNumberVoxbone,
  internationalNumbersV2,
  intNumberMediation,
  intNumbersOrders,
  intNumberCapacityGroup,
  intNumberVoiceUri,
  intNumberCountryList,
  intNumberVoxboneStock,
  intNumberAddonProducts,
  billingCountries,
  externalProviders,
  presenceAppSecrets,
  presenceProduct,
  presenceHealthCheck,
  oCLandlineNumbers,
  oCLandlineNumberOrders,
  oCUsers,
  oCInvites,
  oCNumbers,
  oCNumbersProducts,
  partyMe,
  partyTransport,
  partyAllParties,
  partyAllSignUps,
  skyAppContacts,
  skyAppHuntgroups,
  displayNumberActive,
  displayNumberAvailable,
  displayNumberWeeklySchedule,
}

type GetReducerState<T> = {
  // eslint-disable-next-line
  [P in keyof T]: T[P] extends (...args: any[]) => infer Q ? Q : never
}

export type AppState = GetReducerState<typeof reducers>

export default combineReducers(reducers)
