import React from 'react'
import classNames from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    // borderTopLeftRadius: '2px',
    // borderTopRightRadius: '2px',
    display: 'flex',
    height: '64px',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'relative',
  },
  noDivider: {
    borderBottom: 'none',
  },
  noPadding: {
    padding: 0,
  },
}))

interface Props {
  className?: string
  noDivider?: boolean
  noPadding?: boolean
  children: JSX.Element
}

const PaperHeader = ({ className, noDivider = true, noPadding, children }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noDivider]: noDivider,
      [classes.noPadding]: noPadding,
    },
    className
  )

  return <div className={rootClassName}>{children}</div>
}

export default PaperHeader
