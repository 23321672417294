import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import InnerContent from './InnerContent'

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(6),
  },
}))

const Changelog = ({ title, match }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const customerId = match && match.params.customer_id
  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
  }, [])

  return (
    <Grid container justify="center">
      <Grid item lg={6} sm={8} xs={12} className={classes.grid}>
        <Grid container justify="center" alignItems="flex-start" direction="row">
          <InnerContent customerId={customerId} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Changelog
