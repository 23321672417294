import React, { useEffect, useState } from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchOCLandlineNumbers } from 'state/oCLandlineNumbers/actions'

import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useTranslation } from 'react-i18next'
import { Paper, PaperContent, Alert } from '@skytdc/mui/components'
import { AppState } from 'state/reducers'
import { phoneNumberFormat } from 'utils/helpers'
import NumbersTable from './components/NumbersTable'
import ProvisionDialog from '../ProvisionDialog'
import CancelSubscriptionDrawer from './components/CancelSubscriptionDrawer'

interface Props extends RouteComponentProps {
  customerId: string
  history: any
}

const AssignedNumbers = ({ customerId, history }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userEmail = useSelector((state) => state.me?.data?.user.email)

  const { loading, data, pageNumber, pageSize, error } = useSelector(
    (state: AppState) => state.oCLandlineNumbers
  )
  const { totalResultSize } = data?.pageing || {}
  const progress = Math.ceil((data?.data?.length * 100) / totalResultSize) || 0
  const [fetchingError, setFetchingError] = useState(false)

  const changeRoute = (action = '') => {
    return history.push(
      `/${customerId}/operator-connect/numre/tildelte-numre${action ? `/${action}` : ''}`
    )
  }

  useEffect(() => {
    changeDocumentTitle(t('OC_numbers_title'), ' - ', ':applicationName')
  }, [])

  useEffect(() => {
    if (
      (!loading && !pageNumber && !error) ||
      (!loading &&
        !error &&
        data?.data.length < totalResultSize &&
        pageNumber <= Math.ceil(totalResultSize / pageSize) - 1)
    ) {
      dispatch(fetchOCLandlineNumbers({ customerId, pageNumber, pageSize }))
    }
  }, [!loading])

  if (!fetchingError && error) {
    setFetchingError(true)
  }

  const mappedNumbers = data
    ? data.data.map((x) => {
        const customerIdsMatch = customerId === x.subscription?.customerId

        return {
          ...x,
          product: x.subscription.productName,
          numberWithCountryCode: `+${x.country.prefix} ${phoneNumberFormat(x.nationalNumber)}`,
          countryName: x.country.name,
          comments: x.subscription.extraInfo || '',
          hasInconsistentCustomerIds: !customerIdsMatch,
        }
      })
    : []

  if (fetchingError) {
    return (
      <Paper style={{ maxWidth: 600 }}>
        <PaperContent>
          <Alert type="error" message={t('Dashboard_stock_some_error')} />
        </PaperContent>
      </Paper>
    )
  }
  return (
    <>
      <NumbersTable
        numbers={mappedNumbers}
        progress={progress}
        loading={!data?.data}
        changeRoute={changeRoute}
      />
      <Switch>
        <Route
          render={(props) => (
            <ProvisionDialog
              {...props}
              redirectUrl={`/${customerId}/operator-connect/numre/tildelte-numre`}
              customerId={customerId}
              userEmail={userEmail}
            />
          )}
          exact
          path={`/${customerId}/operator-connect/numre/tildelte-numre/opret`}
        />
        {data?.data.map((x) => {
          return (
            <Route
              key={x.id}
              render={(props) => (
                <CancelSubscriptionDrawer
                  {...props}
                  {...x}
                  userEmail={userEmail}
                  customerId={customerId}
                />
              )}
              exact
              path={`/${customerId}/operator-connect/numre/tildelte-numre/${x.subscription.id}`}
            />
          )
        })}
        {data?.data && <Redirect to={`/${customerId}/operator-connect/numre/tildelte-numre`} />}
      </Switch>
    </>
  )
}

export default AssignedNumbers
