import { getCustomer } from 'services/sweb/customer'
import { customer } from 'services/sweb/me'

export const FETCH_CLEAN = 'customer/FETCH_CLEAN'
export const FETCH_FAILURE = 'customer/FETCH_FAILURE'
export const FETCH_BEGIN = 'customer/FETCH_BEGIN'
export const FETCH_SUCCESS = 'customer/FETCH_SUCCESS'

export const fetchCustomerClean = () => {
  return {
    type: FETCH_CLEAN,
  }
}
export const fetchCustomerFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchCustomerBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchCustomerSuccess = ({ customerId, data }) => {
  return {
    type: FETCH_SUCCESS,
    customerId,
    data,
  }
}

export const cleanCustomer = () => (dispatch) => {
  dispatch(fetchCustomerClean())
}

export const fetchCustomer = ({ customerId, asEndUser = false }) => {
  return async (dispatch) => {
    dispatch(fetchCustomerBegin())
    try {
      const response = asEndUser
        ? await customer({ abbId: customerId })
        : await getCustomer.single({ customerId })
      dispatch(fetchCustomerSuccess({ customerId, data: response.data }))
    } catch (err) {
      dispatch(fetchCustomerFailure(err.response))
    }
  }
}
