import { getMe } from 'services/partyweb/me'

export const FETCH_CLEAN = 'PARTYME/FETCH_CLEAN'
export const FETCH_FAILURE = 'PARTYME/FETCH_FAILURE'
export const FETCH_BEGIN = 'PARTYME/FETCH_BEGIN'
export const FETCH_SUCCESS = 'PARTYME/FETCH_SUCCESS'

export const fetchPartyMeClean = () => {
  return {
    type: FETCH_CLEAN,
  }
}

export const fetchPartyMeFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchPartyMeBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchPartyMeSuccess = (me) => {
  return {
    type: FETCH_SUCCESS,
    payload: { me: me.data },
  }
}

export const cleanMe = () => (dispatch) => {
  dispatch(fetchPartyMeClean())
}

export const fetchPartyMe = () => {
  return async (dispatch) => {
    dispatch(fetchPartyMeBegin())
    try {
      const result = await getMe()
      dispatch(fetchPartyMeSuccess(result))
    } catch (err) {
      dispatch(fetchPartyMeFailure(err.response))
    }
  }
}
