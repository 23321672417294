import React, { Fragment } from 'react'
import Select from 'react-select'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Typography, NoSsr, TextField, Paper, MenuItem, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    marginTop: -theme.spacing(),
    padding: theme.spacing(1, 2),
  },
  groupHeading: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  singleValue: {
    fontSize: 16,
  },
  priceValue: {
    fontSize: 16,
    position: 'absolute',
    right: 48,
  },
  optionPrice: {
    position: 'absolute',
    right: 20,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    paddingTop: theme.spacing(),
  },
  loadingSpinner: {
    marginRight: theme.spacing(2),
  },
}))

const GroupHeading = ({ selectProps, innerProps, children }) => {
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.groupHeading}
      variant="body2"
      {...innerProps}
    >
      {children}
    </Typography>
  )
}

const NoOptionsMessage = ({ selectProps, innerProps }) => {
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      Ingen resultater
    </Typography>
  )
}

const LoadingMessage = ({ selectProps, innerProps }) => {
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      Henter data...
    </Typography>
  )
}

const LoadingIndicator = ({ selectProps, innerProps }) => {
  return (
    <CircularProgress size={18} className={selectProps.classes.loadingSpinner} {...innerProps} />
  )
}

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />
}

const Control = ({ children, innerProps, innerRef, selectProps: { classes, TextFieldProps } }) => {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  )
}

const Option = ({
  selectProps,
  innerRef,
  isFocused,
  isSelected,
  isDisabled,
  innerProps,
  children,
}) => {
  return (
    <MenuItem
      ref={innerRef}
      selected={isFocused}
      disabled={isDisabled}
      component="div"
      style={{
        transition: 'none',
        fontSize: '14px',
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
      {selectProps.options
        .filter((x) => x.label === children)
        .map((x) => {
          if (x && x.value && x.value.monthly_price) {
            return (
              <Typography
                key={x.value.product_type_id}
                className={selectProps.classes.optionPrice}
                color="primary"
              >
                {x.value.monthly_price},- pr. måned
              </Typography>
            )
          }
          if (x && x.value && x.value.price) {
            return (
              <Typography key={x.label} className={selectProps.classes.optionPrice} color="primary">
                {x.value.price},-
              </Typography>
            )
          }
          if (x && x.value && x.value.one_time_charge_dkk) {
            return (
              <Typography key={x.label} className={selectProps.classes.optionPrice} color="primary">
                {x.value.one_time_charge_dkk},-
              </Typography>
            )
          }
          return null
        })}
    </MenuItem>
  )
}

const Placeholder = ({ selectProps, innerProps = {}, children }) => {
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  )
}

const SingleValue = ({ selectProps, innerProps, children }) => {
  return (
    <>
      <Typography className={selectProps.classes.singleValue} {...innerProps}>
        {children}
      </Typography>
      {selectProps.value.value && selectProps.value.value.monthly_price && (
        <Typography className={selectProps.classes.priceValue} {...innerProps} color="primary">
          {selectProps.value.value.monthly_price} kr./måned
        </Typography>
      )}
      {selectProps.value.value && selectProps.value.value.price && (
        <Typography className={selectProps.classes.priceValue} {...innerProps} color="primary">
          {selectProps.value.value.price},-
        </Typography>
      )}
      {selectProps.value.value && selectProps.value.value.one_time_charge_dkk && (
        <Typography className={selectProps.classes.priceValue} {...innerProps} color="primary">
          {selectProps.value.value.one_time_charge_dkk},-
        </Typography>
      )}
    </>
  )
}

const ValueContainer = ({ selectProps, children }) => {
  return <div className={selectProps.classes.valueContainer}>{children}</div>
}

const Menu = ({ selectProps, innerProps, children }) => {
  return (
    <Paper className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  LoadingMessage,
  LoadingIndicator,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  GroupHeading,
}

const SearchableSelect = ({
  onChange,
  label,
  placeholder = '',
  options = [],
  value,
  disabled = false,
  required = true,
  isLoading,
  helperText = '',
  error,
  isClearable = false,
  isSearchable = true,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  }

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          TextFieldProps={{
            error,
            disabled,
            label,
            helperText,
            InputLabelProps: value ? { shrink: true, required } : { required },
          }}
          placeholder={placeholder}
          options={options}
          components={components}
          value={value}
          onChange={onChange}
          isDisabled={disabled}
          required={required}
          isLoading={isLoading}
          isClearable={isClearable}
          isSearchable={isSearchable}
        />
      </NoSsr>
    </div>
  )
}

export default SearchableSelect
