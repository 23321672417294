import React, { ErrorInfo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import HomeIcon from '@material-ui/icons/Home'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CloudContainer, Paper, PaperContent } from 'components'

const useStyles = makeStyles(() => ({
  emojiFaces: {
    fontSize: '70px',
    textAlign: 'center',
    lineHeight: '1.5',
    '&::before': {
      display: 'block',
      content: '"\u{1F97A}"', // > '"🥺"',
      animation: `$emojiFacesAnim 2.5s infinite`,
    },
  },
  '@keyframes emojiFacesAnim': {
    '0%': {
      content: '"\u{1F97A}"', // > '"🥺"',
    },
    '100%': {
      content: '"\u{1F62D}"', // > '"😭"',
    },
  },
}))

const EmojiFaces = () => {
  const classes = useStyles()
  return <div className={classes.emojiFaces} />
}

type Props = {
  error: Error
  errorInfo: ErrorInfo
  handleReset: () => void
}

const Fallback = (props: Props) => {
  const { error, errorInfo, handleReset } = props
  const history = useHistory()
  const { t } = useTranslation()

  const navigateHome = () => {
    handleReset()
    history.push('/')
  }
  return (
    <CloudContainer>
      <Paper style={{ maxWidth: '450px' }}>
        <PaperContent>
          <Grid container direction="row" justify="space-between" alignItems="flex-end">
            <Grid item>
              <Button onClick={handleReset} color="primary" startIcon={<ChevronLeftIcon />}>
                {t('Back')}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={navigateHome} color="primary" endIcon={<HomeIcon />}>
                {t('Home')}
              </Button>
            </Grid>
          </Grid>
          <EmojiFaces />
          <Typography variant="h3" align="center" gutterBottom>
            {t('ErrorBoundaryHeader')}
          </Typography>
          <Typography variant="h6" align="center" style={{ margin: '1rem' }}>
            {t('ErrorBoundarySub')}
          </Typography>
          <Typography
            variant="caption"
            align="center"
            style={{ display: 'block', marginTop: '2rem' }}
          >
            {error && error.toString()}
          </Typography>
        </PaperContent>
      </Paper>
    </CloudContainer>
  )
  // "errorInfo.componentStack", a stack trace would be nice
  // code is minified when build makes it jibberish therefor left out for now
}

export default Fallback
