import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { Avatar, ListItem, ListItemAvatar } from '@material-ui/core'
import { DrawerListItem } from 'components'
import {
  PersonRounded as UserIcon,
  HelpRounded as HelpIcon,
  ExitToAppRounded as LogoutIcon,
  LanguageRounded as LanguageIcon,
  FeedbackRounded as FeedbackIcon,
} from '@material-ui/icons'
import { DropdownMenu, DropdownDivider } from '@skytdc/mui/components'
import * as authenticate from 'services/sweb/authentication'
import { cleanMe } from 'state/me/actions'
import { useTranslation } from 'react-i18next'
import { GB, DK } from 'round-flags'

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    paddingLeft: 0,
    width: 62,
  },
  avatarContainer: {
    borderRadius: '50%',
    border: `2px solid ${theme.palette.common.white}`,
    padding: 2,
    minWidth: 48,
    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.palette.primary.light,
    },
  },
  avatar: {
    color: theme.palette.text.primary,
  },
  langImg: {
    width: 18,
    height: 18,
  },
}))

interface Props {
  // eslint-disable-next-line
  me: any
  customerId: string
}

const UserSelect = ({ me, customerId }: Props) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const signOutClick = () => {
    setAnchorEl(null)
    authenticate.removeBearer()
    dispatch(cleanMe())
  }

  const isDanish = i18n.language === 'da'
  const handleChangeLanguage = () => {
    setAnchorEl(null)
    const useLang = isDanish ? 'en' : 'da'
    i18n.changeLanguage(useLang)
  }

  const handleGoToFeedback = () => {
    setAnchorEl(null)
    window.open('https://support.sky.tdc.dk/hc/da/requests/new', '_blank')
  }

  const handleGoToSupport = () => {
    setAnchorEl(null)
    window.open('https://support.sky.tdc.dk', '_blank')
  }

  const [userSubscription] = me.activeSubscriptions
  const userSubscriptionCustomerId = userSubscription && userSubscription.customerId
  const userSubscriptionCustomerIdsMatch = Number(userSubscriptionCustomerId) === Number(customerId)
  const myPageAnchor = userSubscription
    ? (userSubscriptionCustomerIdsMatch && t('Layout_seemypage')) || t('Layout_othercustomer')
    : t('Layout_nosub')

  return (
    <>
      <ListItem className={classes.listItem}>
        <ListItemAvatar className={classes.avatarContainer}>
          <Avatar onClick={handleClick} className={classes.avatar}>
            {me.user.email.charAt(0).toUpperCase()}
          </Avatar>
        </ListItemAvatar>
      </ListItem>
      <DropdownMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleClose}
        autoFocus={false}
        disableAutoFocusItem
      >
        <DrawerListItem
          primaryText={t('Organization_customer_select_mypage')}
          secondaryText={myPageAnchor}
          icon={<UserIcon />}
          link={userSubscription ? `/${userSubscriptionCustomerId}/min-side` : null}
          disableVerticalPadding
          isDisabled={!userSubscription}
        />
        <DropdownDivider />
        <DrawerListItem
          primaryText={t('Language')}
          secondaryText={isDanish ? 'Change to English' : 'Skift til dansk'}
          icon={<LanguageIcon />}
          isButton
          onClick={handleChangeLanguage}
          disableVerticalPadding
        >
          <img src={isDanish ? DK : GB} alt="Change language" className={classes.langImg} />
        </DrawerListItem>
        <DrawerListItem
          primaryText={t('GiveFeedback')}
          secondaryText={t('GiveFeedbackDesc')}
          icon={<FeedbackIcon />}
          onClick={handleGoToFeedback}
          isButton
          disableVerticalPadding
        />
        <DrawerListItem
          primaryText={t('SupportAndHelp')}
          icon={<HelpIcon />}
          onClick={handleGoToSupport}
          disableVerticalPadding
          isButton
        />
        <DropdownDivider />
        <DrawerListItem
          primaryText={t('Layout_logout')}
          icon={<LogoutIcon />}
          onClick={signOutClick}
          disableVerticalPadding
          isButton
        />
      </DropdownMenu>
    </>
  )
}

export default UserSelect
