let storeCreatedOnce = false
const initialTitleOptionStore = {}
const titleOptionStore = {}

export const createTitleOptions = (...titleOptionArgs) => {
  if (storeCreatedOnce === true) {
    throw new Error('document title options cannot be create more than once')
  }
  titleOptionArgs.forEach((option) => {
    if (Array.isArray(option) === false && option.length !== 2) {
      throw TypeError('title option argument must be an array consisting 2 elements [key, value]')
    }
    const [key, value] = option
    if (typeof key !== 'string') {
      throw TypeError('option "key" must be string')
    }
    if (typeof value !== 'string' && value !== null) {
      throw TypeError('option "value" must be string or null')
    }
    Object.assign(initialTitleOptionStore, { [key]: value })
    Object.assign(titleOptionStore, { [key]: value })
  })
  Object.freeze(initialTitleOptionStore)
  storeCreatedOnce = true
}

export const cleanTitleOptions = () => {
  Object.assign(titleOptionStore, initialTitleOptionStore)
}

export const getTitleOption = (key = '') => {
  return titleOptionStore[key]
}

export const setTitleOption = (key = '', value = '') => {
  if (typeof key !== 'string' || typeof value !== 'string') {
    throw TypeError('expected argument "key" and "value" to be of type string')
  }

  const existingTitleOption = getTitleOption(key)
  if (existingTitleOption !== null && typeof existingTitleOption !== 'string') {
    throw TypeError(`key "${key}" is not an existing title option`)
  }

  const assignOption = { [key]: value }
  Object.assign(titleOptionStore, assignOption)

  return { setTitleOption }
}
