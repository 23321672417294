import React, { useEffect, useState } from 'react'
import { MuiDataTable } from 'components'
import { useTranslation } from 'react-i18next'
import TableFooterWithTotal from '../TableFooterWithTotal'
import UnparsedChargeTableCell from '../UnparsedChargeTableCell'

const withColumns = (columns = [], modifiers = { columns: [], filterList: [] }) => {
  const result = columns.map((col, idx) => {
    const mod = {
      column: modifiers.columns[idx],
      filterList: modifiers.filterList[idx],
    }
    const filterList = mod.filterList && mod.filterList.length > 0 ? mod.filterList : null
    const { sortDirection, display } = mod.column || {}
    const options = { filterList }
    if (sortDirection !== undefined) options.sortDirection = sortDirection
    if (display !== undefined) options.display = display
    return {
      ...col,
      options: {
        ...col.options,
        ...options,
        filterList,
      },
    }
  })
  return result
}

const TableWithFilteredTotal = ({ items, firstTableColumns, totalPrice, otherOptions = {} }) => {
  const { t, i18n } = useTranslation()
  const [table, setTable] = useState({
    searchText: '',
    filteredPrice: totalPrice,
    columns: [],
    filterList: [],
    rowsExpanded: [],
  })

  const [tableColumns, setTableColumns] = useState([])

  useEffect(() => {
    const customBodyRenderFn = (value) => <UnparsedChargeTableCell value={value} />
    const columns = withColumns(
      [
        ...firstTableColumns,
        {
          name: 'unparsed_charge',
          label: t('Invoice_price'),
          align: 'right',
          options: {
            filter: true,
            sort: true,
            customBodyRender: customBodyRenderFn,
          },
        },
        {
          name: 'unparsed_charge',
          align: 'right',
          options: {
            filter: false,
            sort: false,
            display: 'excluded',
            searchable: false,
            download: false,
          },
        },
      ],
      { columns: table.columns, filterList: table.filterList }
    )
    setTableColumns(columns)
  }, [table, i18n.language])

  const calculateFilteredPrice = (displayData) => {
    if (!displayData.length > 0) return 0
    const priceColumnIndex = displayData[0].data.length - 1
    return displayData
      .map((item) => item.data[priceColumnIndex])
      .reduce((calculatedPrice, currentPrice) => calculatedPrice + currentPrice)
  }

  const onTableChangeCustomizations = (action, tableState) => {
    if (action === 'search' || action === 'filterChange') {
      const calculatedTotalPrice = calculateFilteredPrice(tableState.displayData)
      setTable({
        searchText: tableState.searchText,
        filteredPrice: calculatedTotalPrice,
        columns: tableState.columns,
        filterList: tableState.filterList,
        rowsExpanded: [],
      })
    } else if (action === 'expandRow') {
      let currentExpandedRows = tableState.expandedRows.data.map((item) => item.dataIndex)
      if (currentExpandedRows.length > 1) {
        currentExpandedRows = currentExpandedRows.splice(-1, 1)
      }
      setTable({
        ...table,
        rowsExpanded: currentExpandedRows,
      })
    }
  }

  const customFooterFn = (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
    return (
      <TableFooterWithTotal
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
        totalPrice={totalPrice}
        totalCount={items.length}
        filteredPrice={table.filteredPrice}
      />
    )
  }
  // if expandableRows: true is provided in otherOptions, only one row at a time can be expanded with custom fn here
  const options = {
    ...otherOptions,
    searchPlaceholder: t('Invoice_search'),
    rowsExpanded: table.rowsExpanded,
    expandableRowsOnClick: false,
    searchText: table.searchText,
    filter: true,
    rowHover: false,
    responsive: 'responsiveScroll',
    searchOpen: true,
    print: false,
    selectableRows: 'none',
    rowsPerPage: 15,
    onTableChange: onTableChangeCustomizations,
    customFooter: customFooterFn,
  }

  return <MuiDataTable options={options} data={items} columns={tableColumns} />
}

export default TableWithFilteredTotal
