import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'simcardOrders/FETCH_FAILURE'
export const FETCH_BEGIN = 'simcardOrders/FETCH_BEGIN'
export const FETCH_SUCCESS = 'simcardOrders/FETCH_SUCCESS'

export const fetchSimcardOrdersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchSimcardOrdersBegin = ({ mobileNumber }) => {
  return {
    type: FETCH_BEGIN,
    mobileNumber,
  }
}

export const fetchSimcardOrdersSuccess = ({ mobileNumber, data }) => {
  return {
    type: FETCH_SUCCESS,
    mobileNumber,
    data,
  }
}

export const fetchSimcardOrders = ({ customerId, mobileNumber }) => {
  return async (dispatch) => {
    dispatch(fetchSimcardOrdersBegin({ mobileNumber }))
    try {
      const response = await getSubscription.simcardOrders({
        customerId,
        mobileNumber,
      })
      dispatch(fetchSimcardOrdersSuccess({ mobileNumber, data: response.data }))
    } catch (err) {
      dispatch(fetchSimcardOrdersFailure(err.response))
    }
  }
}
