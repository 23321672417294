import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import InvoiceTooltip from './InvoiceTooltip'

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: '6px',
    padding: '0px',
    paddingLeft: '6px',
    paddingRight: '6px',
    marginRight: '-6px',
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'none',
  },
}))

const InvoiceTableCell = ({ invoice, monthlyPrice }) => {
  const classes = useStyles()
  return (
    <InvoiceTooltip invoice={invoice}>
      <Button color="primary" className={classes.button}>
        {monthlyPrice},-
      </Button>
    </InvoiceTooltip>
  )
}

export default InvoiceTableCell
