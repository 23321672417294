import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { createCustomer } from 'services/sweb/customer'
import { format } from 'date-fns'
import { fetchIntNumberVoxboneStock } from 'state/intNumberVoxboneStock/actions'
import { fetchBillingCountries } from 'state/billingCountries/actions'
import { fetchAzureDomains } from 'state/azureDomains/actions'
import { fetchVoxboneCapacityGroup } from 'state/intNumberCapacityGroup/actions'
import { fetchIntNumberCountryList } from 'state/intNumberCountryList/actions'
import { fetchIntNumberAddonProducts } from 'state/intNumberAddonProducts/actions'
import { fetchVoxboneVoiceUri } from 'state/intNumberVoiceUri/actions'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector, batch } from 'react-redux'
import { useFormik } from 'formik'
import { Alert } from '@skytdc/mui/components'
import { Button, LinearProgress, Popover, CircularProgress } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { fetchIntNumbersOrders } from 'state/intNumbersOrders/actions'
import { DialogWrapper, GradientButton } from 'components'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSnackbar } from 'notistack'
import countryCodes from 'country-code-info'
import SelectNumbersStep from './SelectNumbersStep'
import FormStep from './FormStep'

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: -theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(),
  },
  releaseButton: {
    float: 'right',
    marginBottom: theme.spacing(),
  },
  progressContainer: {
    marginTop: theme.spacing(3),
  },
  progressBar: {
    height: 10,
  },
  popoverContent: {
    textTransform: 'capitalize',
    padding: '10px 20px',
  },
  openPopover: {
    background: theme.palette.background.default,
  },
}))

const selectedNumberExtraValues = {
  useGlobalForm: true,
  hasBeenProvisioned: false,
  abbType: '',
  billingStartDate: '',
  azureDomain: '',
  capacityGroup: '',
}

interface Props extends RouteComponentProps {
  customerId: string
  redirectUrl: string
}

const ProvisionDialog = ({ customerId, redirectUrl, history }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const stockNumbers = useSelector((state) => state.intNumberVoxboneStock)
  const countryList = useSelector((state) => state.intNumberCountryList)
  const azureDomains = useSelector((state) => state.azureDomains)
  const billingCountries = useSelector((state) => state.billingCountries)
  const subscriptionProducts = useSelector((state) => state.intNumberAddonProducts)
  const voiceUri = useSelector((state) => state.intNumberVoiceUri)
  const [voiceUriData] = voiceUri?.data?.data || []
  const [releasedNumbers, setReleasedNumbers] = useState([])
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(0)
  const [progress, setProgress] = useState(0)
  // popover
  const [anchorEl, setAnchorEl] = useState(null)
  const [voxboneInfo, setVoxboneInfo] = useState(null)
  const id = !!anchorEl ? 'simple-popover' : undefined
  const onPopoverClose = () => {
    anchorEl.classList.remove(classes.openPopover)
    setAnchorEl(null)
  }
  // popover end

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => history.push(redirectUrl))
  }

  const goToOrders = () => {
    setOpen(false)
    setTimeout(() => history.push(`/${customerId}/internationale-numre/ordrer`))
  }

  const handleBack = () => setStep(0)
  const shortenString = (string = '', maxCharacters = 20) =>
    string?.length > maxCharacters ? string?.substring(0, maxCharacters) : string
  const handleFetchStockNumbers = (props) => {
    const fetchParams = {
      customerId,
      pagesize: 'pagesize' in props ? props.pagesize : stockNumbers.pagesize,
      pagenumber: 'pagenumber' in props ? props.pagenumber : stockNumbers.pagenumber,
      e164: 'e164' in props ? props.e164 : stockNumbers.e164,
      countryCode: 'countryCode' in props ? props.countryCode : stockNumbers.countryCode,
    }
    dispatch(fetchIntNumberVoxboneStock(fetchParams))
  }

  const { loading, data, pageNumber, pageSize, error } = useSelector(
    (state) => state.intNumberCapacityGroup
  )

  const { totalResultSize } = data?.pageing || {}

  const maxPageNumberReached = pageNumber <= (Math.ceil(totalResultSize / pageSize) - 1 || 0)

  useEffect(() => {
    if (
      (!loading && !pageNumber && !error) ||
      (!loading && !error && data?.data.length < totalResultSize && maxPageNumberReached)
    ) {
      dispatch(fetchVoxboneCapacityGroup({ customerId, id: '', pageNumber, pageSize }))
    }
  }, [!loading])

  useEffect(() => {
    setOpen(true)
    changeDocumentTitle(t('CreateIntNumbers'), ' - ', ':applicationName')
    handleFetchStockNumbers({
      pagesize: 10,
      pagenumber: 0,
      e164: '',
      countryCode: '',
    })
    batch(() => {
      dispatch(fetchIntNumberCountryList({ customerId }))
      dispatch(fetchBillingCountries({ customerId }))
      dispatch(fetchIntNumberAddonProducts({ customerId }))
    })
    if (!azureDomains.loading) dispatch(fetchAzureDomains({ customerId }))
  }, [])

  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)

  const { setFieldValue, handleSubmit, values, isSubmitting, errors, setErrors } = useFormik({
    initialValues: {
      selectedNumbers: [],
      abbType: '',
      billingStartDate: minDate,
      azureDomain: '',
      capacityGroup: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      if (step === 0) {
        setStep(1)
      } else {
        let prog = 0
        const promises = payload.selectedNumbers
          .filter((x) => !x.hasBeenProvisioned)
          .map((x) => {
            const cityName = shortenString(x.city)
            const azureDomainId = x.useGlobalForm ? payload.azureDomain : x.azureDomain
            const findAzureDomain = azureDomains.data?.find((y) => y.primarySBC === azureDomainId)
            // need to map from A3 (voxbone) to A2 (billing)
            const { a2 } = countryCodes.findCountry({ a3: x.countryCode })
            const findVoxboneCountry = countryList.data?.data.find(
              (y) => y.countryCodeA3 === x.countryCode
            )
            const findBillingCountry =
              billingCountries.data?.data.find((y) => y.code.toUpperCase() === a2) ||
              billingCountries.data?.data.find((y) => y.prefix === findVoxboneCountry.phoneCode)
            const body = {
              internationalnumber: x.e164,
              purchase: {},
              provision: {
                voxbone: {
                  primary_voice_uri: findAzureDomain?.primarySBC,
                  backup_voice_uri: findAzureDomain?.backupSBC,
                  capacity_group_id: x.useGlobalForm ? payload.capacityGroup : x.capacityGroup,
                  voice_uri_description: x.comment || cityName || `...`,
                },
                billing: {
                  productTypeId: x.useGlobalForm ? payload.abbType : x.abbType,
                  startDate: format(
                    x.useGlobalForm ? payload.billingStartDate : x.billingStartDate,
                    'yyyy-MM-dd'
                  ),
                  billingCountry: findBillingCountry?.id,
                  locationDescription: x.comment || cityName || `...`,
                },
                mediation: {
                  description: x.comment || cityName || `...`,
                  billingCustomerId: customerId,
                  originCountry: findBillingCountry?.name || x.countryCode,
                },
              },
              test_mode: false,
            }
            return createCustomer
              .intNumbers({
                customerId,
                json: body,
              })
              .then((response) => {
                return { ...response, number: x.e164 }
              })
              .catch((err) => {
                return err.response.json().then((decodedError) => {
                  const errorString = decodedError.error.items
                    .map((errString) => errString.key)
                    .join(' ')
                  return { error: errorString, number: x.e164 }
                })
              })
              .finally(() => {
                prog += 1
                setProgress(prog)
              })
          })
        const response = await Promise.all(promises)
        const findErrors = response.filter((x) => !x.status)
        if (findErrors.length > 0) {
          enqueueSnackbar(t('IntNumberProvErrorMessage', { amount: findErrors.length }), {
            variant: 'error',
          })
          const newNumbers = values.selectedNumbers.map((x) => {
            const hasError = Boolean(findErrors.find((y) => y.number === x.e164))
            return {
              ...x,
              hasBeenProvisioned: !hasError,
              useGlobalForm: hasError ? x.useGlobalForm : true,
            }
          })
          setFieldValue('selectedNumbers', newNumbers)
          setErrors({ selectedNumbers: findErrors })
          setProgress(0)
        } else {
          dispatch(fetchIntNumbersOrders({ customerId }))
          enqueueSnackbar(t('IntNumberProvSuccess'), { variant: 'success' })
          goToOrders()
        }
      }
    },
  })

  const handleToggleSelectNumber = (e, number) => {
    if (
      (!releasedNumbers.includes(number?.voiceUriId) && number?.voiceUriId) ||
      number?.mediation?.length
    ) {
      dispatch(fetchVoxboneVoiceUri({ customerId, id: number.voiceUriId }))
      e.currentTarget.classList.add(classes.openPopover)
      setAnchorEl(e.currentTarget)
      setVoxboneInfo(number)
    } else {
      const existsAlready = values.selectedNumbers.find((x) => x.didId === number.didId)
      if (existsAlready) {
        setFieldValue(
          'selectedNumbers',
          values.selectedNumbers.filter((x) => x.didId !== number.didId)
        )
      } else {
        setFieldValue('selectedNumbers', [
          ...values.selectedNumbers,
          {
            ...number,
            ...selectedNumberExtraValues,
            comment: shortenString(number.cityName) || `...`,
          },
        ])
      }
    }
  }

  const handleSelectAllNumbers = () => {
    const existingIds = values.selectedNumbers.map((x) => x.didId)
    const newNumbers = stockNumbers.data?.data
      .filter(
        (x) =>
          existingIds.indexOf(x.voxbone.didId) < 0 &&
          x.mediation.length === 0 &&
          !x.voxbone.voiceUriId
      )
      .map((x) => {
        return {
          ...x.voxbone,
          ...selectedNumberExtraValues,
          comment: shortenString(x.voxbone.cityName) || `...`,
        }
      })
    setFieldValue('selectedNumbers', [...values.selectedNumbers, ...newNumbers])
  }
  const showVoxboneData = (data: {}) => {
    const voxboneElements = []
    for (const [key, value] of Object.entries(data)) {
      if (key === 'id' || key === 'didId' || !value) continue
      voxboneElements.push(
        <p key={key}>
          <b>{key?.replace(/([A-Z])/g, ' $1')}: </b>
          {value}
        </p>
      )
    }
    return voxboneElements
  }

  const handleOnreleaseNumber = () => {
    setReleasedNumbers((prev) => [...prev, voxboneInfo.voiceUriId])
    onPopoverClose()
  }
  let content =
    step === 0 ? (
      <SelectNumbersStep
        fetchStockNumbers={handleFetchStockNumbers}
        pagenumber={stockNumbers.pagenumber}
        pagesize={stockNumbers.pagesize}
        countryCode={stockNumbers.countryCode}
        countryList={countryList.data?.data}
        selectedNumbers={values.selectedNumbers}
        numbers={countryList.data?.data && stockNumbers.data?.data ? stockNumbers.data?.data : []}
        releasedNumbers={releasedNumbers}
        loading={stockNumbers.loading}
        totalCount={stockNumbers.data?.pageing.totalResultSize || 0}
        toggleSelectNumber={handleToggleSelectNumber}
        selectAllNumbers={handleSelectAllNumbers}
      />
    ) : (
      <FormStep
        countryList={countryList.data?.data || []}
        capacityGroups={data?.data || []}
        azureDomains={azureDomains}
        subscriptionProducts={subscriptionProducts.data?.products || []}
        toggleSelectNumber={handleToggleSelectNumber}
        setValue={setFieldValue}
        loading={isSubmitting}
        minDate={minDate}
        customerId={customerId}
        errors={errors}
        {...values}
      />
    )

  if (stockNumbers.error && stockNumbers.error.statusText) {
    content = <Alert type="error" message={stockNumbers.error.statusText} />
  }

  if (countryList.error && countryList.error.statusText) {
    content = <Alert type="error" message={countryList.error.statusText} />
  }

  if (error && error.statusText) {
    content = <Alert type="error" message={error.statusText} />
  }

  if (azureDomains.error && azureDomains.error.statusText) {
    content = <Alert type="error" message={azureDomains.error.statusText} />
  }

  if (billingCountries.error && billingCountries.error.statusText) {
    content = <Alert type="error" message={billingCountries.error.statusText} />
  }

  if (isSubmitting) {
    content = (
      <div className={classes.progressContainer}>
        <LinearProgress
          className={classes.progressBar}
          variant="determinate"
          value={(progress / values.selectedNumbers.length) * 100}
        />
      </div>
    )
  }

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title={t('CreateIntNumbers')}
      description={step === 0 ? t('CreateIntNumbersStepOneDesc') : t('CreateIntNumbersStepTwoDesc')}
      maxWidth="md"
    >
      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault()
          handleSubmit()
        }}
      >
        <div style={{ paddingTop: 1, paddingBottom: 24 }}>
          {content}
          {voxboneInfo && (
            <Popover
              id={id}
              open={!!id}
              anchorEl={anchorEl}
              onClose={onPopoverClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div className={classes.popoverContent}>
                {showVoxboneData(voxboneInfo)}
                {voiceUri?.loading ? (
                  <CircularProgress size={10} />
                ) : (
                  voiceUriData?.uri && showVoxboneData(voiceUriData)
                )}
                <Button
                  color="primary"
                  className={classes.releaseButton}
                  onClick={handleOnreleaseNumber}
                >
                  {t('OverwriteNumber')}
                </Button>
              </div>
            </Popover>
          )}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            className={classes.cancelButton}
            disabled={isSubmitting}
            onClick={step === 0 ? handleClose : handleBack}
          >
            {step === 0 ? t('Drawer_create_sub_cancel') : t('Invoice_back')}
          </Button>
          <GradientButton disabled={values.selectedNumbers.length === 0} loading={isSubmitting}>
            {step === 0 ? t('Hardware_next') : t('Organization_unassignedsimcards_order_cta')}
          </GradientButton>
        </div>
      </form>
    </DialogWrapper>
  )
}

export default ProvisionDialog
