import React, { useEffect } from 'react'
import { teams } from 'services/appOpts'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Paper, PaperContent, PageTitle } from '@skytdc/mui/components'
import Layout from '../../Layout'

const OperationalStatus = ({ title }) => {
  const { t } = useTranslation()
  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
  }, [])

  const { isInTeams } = teams

  return (
    <Layout hideLayout={isInTeams}>
      <Grid container justify="center">
        <Grid item lg={6} sm={8} xs={12}>
          {!isInTeams && <PageTitle title={t(title)} />}
          <Paper>
            <PaperContent>
              <iframe
                title="Driftstatus"
                src="https://status.sky.tdc.dk/"
                width="100%"
                height="750"
                frameBorder="0"
              />
            </PaperContent>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default OperationalStatus
