import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { fetchLandlineNumbersCombined } from 'state/landlineNumbersCombined/actions'
import { getValidation } from 'services/sweb/validation'
import { SkySelect } from 'components'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ExtensionSelect from '../ExtensionSelect'
import NumberInUseBox from '../NumberInUseBox'

const validateLandlineForm = ({ landlineNumber, extension }) => {
  const landlineNumberIsSet =
    landlineNumber !== null &&
    Number.isInteger(landlineNumber.value.ani_id || landlineNumber.value.ddi.ani_id)

  const landlineNumberValid = landlineNumberIsSet && landlineNumber.isValid === true
  const extensionValid = extension !== null && Number.isNaN(extension.localExtension) === false

  return landlineNumberValid && extensionValid
}

const LandlineForm = ({
  initialForm,
  oneNumberType,
  onChange,
  processingOrder,
  customerId,
  azureId,
}) => {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const stored = useSelector(
    (state) => ({
      landlineNumbers: state.landlineNumbersCombined,
      validateExtension: state.validateExtension,
      validateLandlineNumber: state.validateLandlineNumber,
      validateImportMobileNumber: state.validateImportMobileNumber,
    }),
    shallowEqual
  )

  const [landlineNumber, setLandlineNumber] = useState(initialForm.landlineNumber || null)
  const [extension, setExtension] = useState(initialForm.extension || null)
  const [inUseInformation, setInUseInformation] = useState(null)

  const handleGoToSetupWizard = () => push(`/${customerId}/setup`)
  const handleSetFieldLandlineNumber = (event, val) => {
    const { formattedPhoneNumber, ...otherProps } = val
    setLandlineNumber({ ...otherProps, isValid: null })
    const prefix = val.value.country ? val.value.country.country_prefix : 45
    const ddi =
      val.value.country && val.value.productVersion === 1
        ? { number: val.value.ddi.number }
        : undefined

    getValidation
      .validatePhoneNumber(
        {
          customerId,
          phoneNumber: val.value.number,
          prefix,
          azureId,
        },
        ddi
      )
      .then((response) => {
        setLandlineNumber({ ...otherProps, isValid: !response.data.found })
        setInUseInformation(
          response.data.locationDetails && response.data.locationDetails.length > 0
            ? response.data.locationDetails
            : null
        )
      })
      .catch((err) => {
        if (err.response) {
          err.response.json().then((response) => {
            if (response.error.key === '_ServerError_') {
              enqueueSnackbar(t('CheckSetupWizardError'), {
                variant: 'warning',
                action: () => {
                  return (
                    <Button
                      fullWidth
                      disableElevation
                      color="secondary"
                      variant="contained"
                      onClick={handleGoToSetupWizard}
                    >
                      {t('GoToAzureIntegration')}
                    </Button>
                  )
                },
              })
            }
          })
        }
        setLandlineNumber({ ...otherProps, isValid: false })
        setInUseInformation(null)
      })
  }

  const handleSetFieldExtension = (val) => {
    setExtension(val)
  }

  useEffect(() => {
    const isValid = validateLandlineForm({ customerId, landlineNumber, extension })
    onChange({ isValid, landlineNumber, extension })
  }, [landlineNumber, extension])

  useEffect(() => {
    dispatch(
      fetchLandlineNumbersCombined({
        customerId,
        oneNumberType,
      })
    )
  }, [oneNumberType])

  const noAvailableLandlineNumbers =
    !stored.landlineNumbers.loading &&
    stored.landlineNumbers.data &&
    stored.landlineNumbers.data.length === 0

  const landlineNumberIsValid = landlineNumber && landlineNumber.isValid
  const landlineErr = noAvailableLandlineNumbers || landlineNumberIsValid === false

  const isCheckingNumber = Boolean(
    landlineNumberIsValid === null && landlineNumber && landlineNumber.value
  )

  const displayExtension =
    !isCheckingNumber && landlineErr === false && landlineNumberIsValid === true

  return (
    <>
      <SkySelect
        label={t('Drawer_create_choose_landline')}
        value={landlineNumber}
        onChange={handleSetFieldLandlineNumber}
        disableClearable
        getOptionSelected={(option, value) => {
          const findOptionAni = option.value.country ? option.value.ddi.ani_id : option.value.ani_id
          const findValueAni = value.value.country ? value.value.ddi.ani_id : value.value.ani_id
          return findOptionAni === findValueAni
        }}
        loading={
          stored.landlineNumbers.loading ||
          (isCheckingNumber &&
            stored.landlineNumbers.data &&
            stored.landlineNumbers.data.length > 0)
        }
        disabled={processingOrder || isCheckingNumber}
        options={
          (stored.landlineNumbers.data &&
            stored.landlineNumbers.data.length > 0 &&
            stored.landlineNumbers.data.map((x) => {
              return {
                ...x,
                group: t(x.group),
                // formattedPhoneNumber: x.label + x.label?.replace(/\s/g, ''),
              }
            })) ||
          []
        }
        groupBy={(option) => option.group}
        error={landlineErr}
        helperText={landlineErr && noAvailableLandlineNumbers && t('Drawer_create_no_landline')}
      />
      {displayExtension && (
        <ExtensionSelect
          landlineNumber={landlineNumber}
          extension={extension}
          disabled={processingOrder}
          onExtension={handleSetFieldExtension}
        />
      )}
      {!isCheckingNumber &&
        inUseInformation &&
        inUseInformation.map((x) => {
          return (
            <NumberInUseBox
              key={x.details}
              details={x.details}
              user={x.user}
              isDeleted={x.isDeleted}
            />
          )
        })}
    </>
  )
}

export default LandlineForm
