import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'voxboneCapacityGroup/FETCH_FAILURE'
export const FETCH_BEGIN = 'voxboneCapacityGroup/FETCH_BEGIN'
export const FETCH_SUCCESS = 'voxboneCapacityGroup/FETCH_SUCCESS'

export const fetchVoxboneCapacityGroupFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchVoxboneCapacityGroupBegin = ({ customerId, id }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
    id,
  }
}

export const fetchVoxboneCapacityGroupSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchVoxboneCapacityGroup = ({ customerId, id, pageNumber, pageSize }) => {
  return async (dispatch) => {
    dispatch(fetchVoxboneCapacityGroupBegin({ customerId, id }))
    try {
      const response = await getCustomer.intNumbersVoxboneCapacityGroup({
        customerId,
        id,
        pageNumber,
        pageSize,
      })
      dispatch(fetchVoxboneCapacityGroupSuccess(response.data))
    } catch (err) {
      dispatch(fetchVoxboneCapacityGroupFailure(err.response))
    }
  }
}
