import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'dataPoolTopSpenders/FETCH_FAILURE'
export const FETCH_BEGIN = 'dataPoolTopSpenders/FETCH_BEGIN'
export const FETCH_SUCCESS = 'dataPoolTopSpenders/FETCH_SUCCESS'

export const fetchdataPoolTopSpendersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchdataPoolTopSpendersBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchdataPoolTopSpendersSuccess = ({ data }) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchdataPoolTopSpenders = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchdataPoolTopSpendersBegin())
    try {
      const response = (await getSubscription.dataPoolTopSpenders({
        customerId,
      })) as { data: any }
      dispatch(fetchdataPoolTopSpendersSuccess({ data: response.data }))
    } catch (err) {
      dispatch(fetchdataPoolTopSpendersFailure(err.response))
    }
  }
}
