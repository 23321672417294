import React, { useState, useEffect } from 'react'
import { createCustomer } from 'services/sweb/customer'
import { fetchHuntgroupCallPatterns } from 'state/huntgroupCallPatterns/actions'
import { fetchAvailableHuntgroupNumbers } from 'state/availableHuntgroupNumbers/actions'
import { useSelector, useDispatch, batch } from 'react-redux'
import {
  DrawerWrapper,
  DrawerSection,
  PaperLoader,
  PaperFeedbackMessage,
  Paper,
  PaperContent,
} from 'components'
import { useSnackbar } from 'notistack'
import { Slide } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CreateForm from '../CreateForm'

const CreateDrawer = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { customer_id: customerId } = match.params
  const huntgroupCallPatterns = useSelector((state) => state.huntgroupCallPatterns)
  const availableHuntgroupNumbers = useSelector((state) => state.availableHuntgroupNumbers)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    batch(() => {
      dispatch(fetchHuntgroupCallPatterns({ customerId }))
      dispatch(fetchAvailableHuntgroupNumbers({ customerId }))
    })
  }, [])

  const closeDrawer = () => {
    history.push(`/${match.params.customer_id}/organisation/mobile-ringegrupper`)
  }

  const handleCreateHuntgroup = (json) => {
    setLoading(true)
    const payload = {
      ...json,
      group_name: `${json.group_number} - ${json.group_name}`,
    }
    createCustomer
      .huntgroup({ customerId: match.params.customer_id, json: payload })
      .then(() => {
        enqueueSnackbar(t('Organization_huntgroups_create_success'), {
          variant: 'success',
        })
        closeDrawer()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  let content = <PaperLoader />

  if (huntgroupCallPatterns.error || availableHuntgroupNumbers.error) {
    content = (
      <>
        {huntgroupCallPatterns.error && (
          <PaperFeedbackMessage
            type="error"
            message={t('Organization_huntgroups_call_patterns_error', {
              error: huntgroupCallPatterns.error.statusText,
            })}
          />
        )}
        {availableHuntgroupNumbers.error && (
          <PaperFeedbackMessage
            type="error"
            message={t('Organization_huntgroups_members_error', {
              error: availableHuntgroupNumbers.error.statusText,
            })}
          />
        )}
      </>
    )
  }

  if (huntgroupCallPatterns.data && availableHuntgroupNumbers.data) {
    content = (
      <Slide direction="left" in>
        <div>
          <CreateForm
            loading={loading}
            numbers={availableHuntgroupNumbers.data}
            callPatterns={huntgroupCallPatterns.data}
            backLink={closeDrawer}
            createHuntgroup={handleCreateHuntgroup}
            customerId={match.params.customer_id}
          />
        </div>
      </Slide>
    )
  }

  return (
    <DrawerWrapper closeDrawer={closeDrawer}>
      <Paper>
        <PaperContent>
          <DrawerSection title={t('Organization_huntgroups_create')}>{content}</DrawerSection>
        </PaperContent>
      </Paper>
    </DrawerWrapper>
  )
}

export default CreateDrawer
