import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { DialogActions, Button, Collapse, Link, Switch } from '@material-ui/core'
import { GradientButton, DrawerSection, SkyTextField, DrawerListItem } from 'components'
import { useSnackbar } from 'notistack'
import { createSubscription } from 'services/sweb/subscription'
import { useTranslation } from 'react-i18next'
import { Email as EmailIcon } from '@material-ui/icons'
import { Alert } from '@skytdc/mui/components'
import MobileForm from '../components/MobileForm'
import DisplayPrice from '../components/DisplayPrice'
import { formatSubscriptionOrder } from '../helpers/subscription'

const findBaseProductPrice = ({ productDetails = [] }) => {
  const result = productDetails.find((pd) => {
    return pd.license_requirements === null
  })
  return result ? result.price : 0
}

const FormContainer = ({ handleClose, customerId, mobilePackages, baseProducts, supportLink }) => {
  const { t } = useTranslation()
  const receiptEmail = useSelector((state) => state.me?.data.user.email)
  const { enqueueSnackbar } = useSnackbar()
  const [textFieldData, setTextFieldData] = useState<any>({})
  const [receiveWelcomeEmail, setReceiveWelcomeEmail] = useState(false)

  const initialMobileForm = {
    mobileNumber: null,
    mobilePackage: null,
    simcard: null,
    importMobileNumber: '',
    importSigner: '',
    datasim: null,
    alternativeSimcardAddress: '',
  }

  const [processingOrder, setProcessingOrder] = useState(false)
  const [processingOrderError, setProcessingOrderError] = useState(false)

  const mobileProductDetails = baseProducts.product_details.length
  const mobileHasWarnings = baseProducts.warnings.length > 0
  const mobileIsAvailable = mobileProductDetails === 1 && mobileHasWarnings === false

  const mobileOnlyBasePrice = findBaseProductPrice({
    productDetails: baseProducts?.product_details,
  })

  const [mobileForm, setMobileForm] = useState({ isValid: false, ...initialMobileForm })

  const handleSetMobileForm = (form) => {
    setMobileForm({ ...initialMobileForm, ...form })
  }
  const toggleWelcomeMailChecked = () => {
    setReceiveWelcomeEmail((prev) => !prev)
  }
  const isInvalidForms = () => {
    if (
      !textFieldData.first_name ||
      !textFieldData.last_name ||
      !mobileForm.isValid ||
      !mobileForm.mobilePackage
    ) {
      return true
    }
    return false
  }

  const submitSubscription = () => {
    setProcessingOrder(true)
    const json = formatSubscriptionOrder({
      withMobile: true,
      mobile: mobileForm,
      ...textFieldData,
      receiptEmail,
      receiveWelcomeEmail,
    })
    createSubscription
      .singlePlainSubscription({ customerId, json })
      .then(() => {
        setProcessingOrder(false)
        enqueueSnackbar(t('Drawer_create_sub_success'), {
          variant: 'success',
        })
        handleClose()
      })
      .catch((e) => {
        setProcessingOrderError(true)
        setProcessingOrder(false)
      })
  }

  const handleTextFieldData = (e) => {
    const { name, value } = e.target
    setTextFieldData((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <>
      <DrawerSection title={t('Organization_orders_drawer_user_information')}>
        <SkyTextField
          multiline
          value={textFieldData?.first_name || ''}
          onChange={handleTextFieldData}
          label={`${t('Organization_orders_drawer_first_name')} *`}
          name="first_name"
        />
        <SkyTextField
          multiline
          value={textFieldData?.last_name || ''}
          onChange={handleTextFieldData}
          label={`${t('Organization_orders_drawer_last_name')} *`}
          name="last_name"
        />
      </DrawerSection>
      <Collapse in={mobileIsAvailable}>
        <DrawerSection title={t('Drawer_create_sub_mobile_title')}>
          <MobileForm
            noneAd
            initialForm={initialMobileForm}
            mobilePackages={mobilePackages.available_packages}
            onChange={handleSetMobileForm}
            processingOrder={processingOrder}
          />
        </DrawerSection>
      </Collapse>
      <DrawerListItem
        icon={<EmailIcon />}
        tooltip={t('Drawer_receive_welcome_mail_tooltip')}
        primaryText={t('Drawer_receive_welcome_mail')}
      >
        <Switch
          color="primary"
          checked={receiveWelcomeEmail}
          onChange={toggleWelcomeMailChecked}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </DrawerListItem>
      <DrawerSection title={t('Drawer_create_sub_ppm')}>
        <DisplayPrice
          mobile={{
            enabled: mobileIsAvailable,
            price: mobileOnlyBasePrice,
            name: 'Drawer_price_mobile_sub',
          }}
          mobilePackage={
            mobileForm.mobilePackage && {
              name: mobileForm.mobilePackage.label,
              price: mobileForm.mobilePackage.value.monthly_price,
            }
          }
        />
      </DrawerSection>
      <DrawerSection title={t('Order_comment')}>
        <SkyTextField
          multiline
          value={textFieldData?.comment || ''}
          onChange={handleTextFieldData}
          label={t('Order_comment')}
          name="comment"
        />
      </DrawerSection>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {t('Drawer_create_sub_cancel')}
        </Button>
        <GradientButton
          onClick={submitSubscription}
          disabled={isInvalidForms()}
          loading={processingOrder}
        >
          {t('Drawer_create_sub_create')}
        </GradientButton>
      </DialogActions>
      {processingOrderError && (
        <Alert type="error">
          {t('ContactSupport')}
          <Link href={supportLink} target="_blank">
            {supportLink}
          </Link>
        </Alert>
      )}
    </>
  )
}

export default FormContainer
