import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoice/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoice/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoice/FETCH_SUCCESS'

export const fetchInvoiceFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchInvoiceSuccess = ({ customerId, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    customerId,
  }
}

export const fetchInvoice = ({ customerId, limit }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceBegin({ customerId }))
    try {
      const response = await getInvoice.all({ customerId, limit })
      dispatch(fetchInvoiceSuccess({ customerId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceFailure(err.response))
    }
  }
}
