import { getMonth } from 'utils/helpers'

export const getPercentageDifference = (current, last) => {
  if (current === 0 || last === 0) return 0
  if (last === 0 && current > 1) return 0
  if (last === 0 && current > 0 && current < 1) return 0
  if (last > current && current === 0) return 0
  const diff = ((current - last) / last) * 100
  if (diff < 0.01 && diff > 0) return 0
  return diff
}

export const getPercentageOfTotal = (current, total) => {
  return (current / total) * 100
}

export const formatInvoices = (invoices, ski) => {
  return invoices.map((invoice, index) => {
    const [date, month, year] = invoice.billing_from?.split('-')
    return {
      period: {
        date: `${invoice.billing_from} - ${invoice.billing_to}`,
        period: getMonth(invoice.billing_to),
        from: invoice.billing_from,
        year,
        quarter: Math.ceil(parseInt(month) / 3),
      },
      billingDate: {
        billingDate: invoice.billing_date,
        ocr: invoice.ocr_line,
      },
      subscriptionFees: {
        current: invoice.subscriptionFees,
        invoiceId: invoice.billing_id,
        percentageOfTotal:
          invoice.subscriptionFees > 0
            ? getPercentageOfTotal(invoice.subscriptionFees, invoice.totalFees)
            : null,
        percentageDifference:
          index < invoices.length - 1
            ? getPercentageDifference(
                invoice.subscriptionFees,
                invoices[index + 1].subscriptionFees
              )
            : null,
      },
      fixedLineFees: {
        current: invoice.fixedLineFees,
        invoiceId: invoice.billing_id,
        percentageOfTotal:
          invoice.fixedLineFees > 0
            ? getPercentageOfTotal(invoice.fixedLineFees, invoice.totalFees)
            : null,
        percentageDifference:
          index < invoices.length - 1
            ? getPercentageDifference(invoice.fixedLineFees, invoices[index + 1].fixedLineFees)
            : null,
      },
      mobileFees: {
        current: invoice.mobileFees,
        invoiceId: invoice.billing_id,
        percentageOfTotal:
          invoice.mobileFees > 0
            ? getPercentageOfTotal(invoice.mobileFees, invoice.totalFees)
            : null,
        percentageDifference:
          index < invoices.length - 1
            ? getPercentageDifference(invoice.mobileFees, invoices[index + 1].mobileFees)
            : null,
      },
      extraServiceFees: {
        current: invoice.extraServiceFees,
        invoiceId: invoice.billing_id,
        percentageOfTotal:
          invoice.extraServiceFees > 0
            ? getPercentageOfTotal(invoice.extraServiceFees, invoice.totalFees)
            : null,
        percentageDifference:
          index < invoices.length - 1
            ? getPercentageDifference(
                invoice.extraServiceFees,
                invoices[index + 1].extraServiceFees
              )
            : null,
      },
      totalFees: {
        current: invoice.totalFees,
        percentageDifference:
          index < invoices.length - 1
            ? getPercentageDifference(invoice.totalFees, invoices[index + 1].totalFees)
            : null,
      },
      utilities: {
        canDownloadPdf: invoice.pdf_download_enabled,
        invoiceId: invoice.billing_id,
        customerId: invoice.customer_id,
        chartData: {
          subscriptionFees: invoice.subscriptionFees,
          fixedLineFees: invoice.fixedLineFees,
          mobileFees: invoice.mobileFees,
          extraServiceFees: invoice.extraServiceFees,
        },
      },
    }
  })
}
