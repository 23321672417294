import React from 'react'
import classNames from 'classnames'
import { TableCellListItem } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { ListItemAvatar, Avatar } from '@material-ui/core'
import { ErrorRounded as ErrorIcon, PublicRounded as NumberIcon } from '@material-ui/icons'
import * as flags from 'round-flags'

const useStyles = makeStyles((theme) => ({
  listItemAvatar: {
    minWidth: 44,
  },
  avatar: {
    width: 22,
    height: 22,
    marginLeft: theme.spacing(),
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
}))

const StatusCell = ({
  hasErrors,
  countryCode,
  countryName,
  isMediationMissing,
  hasValidReference,
  hasInconsistentCustomerIds,
  numberWithCountryCode,
  isNumber,
}) => {
  const classes = useStyles()

  const avatarClassName = classNames([classes.avatar], {
    [classes.warning]: hasErrors,
  })

  const countryFlag = flags[countryCode.toUpperCase()]

  // build this later
  const errorString =
    isMediationMissing || hasValidReference || hasInconsistentCustomerIds ? 'Opsætningsfejl' : ''
  return (
    <TableCellListItem
      primary={numberWithCountryCode}
      secondary={hasErrors ? errorString : countryName}
    >
      <ListItemAvatar className={classes.listItemAvatar}>
        <Avatar className={avatarClassName}>
          {hasErrors ? (
            <ErrorIcon />
          ) : (
            (countryFlag && <img src={countryFlag} alt={countryName} />) || <NumberIcon />
          )}
        </Avatar>
      </ListItemAvatar>
    </TableCellListItem>
  )
}

export default StatusCell
