import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'teamsUsage/FETCH_FAILURE'
export const FETCH_BEGIN = 'teamsUsage/FETCH_BEGIN'
export const FETCH_SUCCESS = 'teamsUsage/FETCH_SUCCESS'

export const fetchTeamsUsageFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchTeamsUsageBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchTeamsUsageSuccess = ({ customerId, data }) => {
  return {
    type: FETCH_SUCCESS,
    customerId,
    data,
  }
}

export const fetchTeamsUsage = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchTeamsUsageBegin({ customerId }))
    try {
      const response = await getCustomer.teamsUsage({
        customerId,
      })
      dispatch(fetchTeamsUsageSuccess({ customerId, data: response.data }))
    } catch (err) {
      dispatch(fetchTeamsUsageFailure(err.response))
    }
  }
}
