import React from 'react'
import classNames from 'classnames'
import { InputAdornment } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import { EventOutlined as CalendarIcon } from '@material-ui/icons'
import useInputStyles from 'theme/hooks/useInputStyles'

const SkyDatePicker = ({ white = true, withMargin = true, fullWidth = true, ...props }) => {
  const classes = useInputStyles()

  const root = classNames({
    [classes.root]: true,
    [classes.white]: white,
  })

  const BaseComponent = (innerProps) => {
    // TODO it's acting weird and only some props are passed
    return (
      <TextField
        InputProps={{
          classes: { root, focused: classes.focused },
        }}
        {...innerProps}
        variant="filled"
      />
    )
  }

  return (
    <div className={withMargin ? classes.withMargin : ''}>
      <DatePicker
        disableToolbar
        TextFieldComponent={BaseComponent}
        fullWidth={fullWidth}
        format="dd-MM-yyyy"
        autoOk
        InputProps={{
          classes: { root, focused: classes.focused },
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon color="secondary" className={classes.calendarIcon} />
            </InputAdornment>
          ),
        }}
        variant="filled"
        {...props}
      />
    </div>
  )
}

export default SkyDatePicker
