import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Info } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(0.5),
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
  },
  listItemAvatar: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 43,
    justifyContent: 'center',
  },
}))

interface Props {
  selected: boolean
  primary: string
  secondary?: JSX.Element | string
  onClick: () => void
  disabled?: boolean
  loading: boolean
  inUse?: boolean
  endIcon?: JSX.Element
  withCheckbox?: boolean
  action?: JSX.Element
  startIcon?: JSX.Element
}

const NumberListItem = ({
  selected,
  primary,
  secondary,
  disabled,
  onClick,
  loading,
  inUse = true,
  endIcon,
  withCheckbox = true,
  action,
  startIcon,
  customerId,
}: Props) => {
  const classes = useStyles()
  return (
    <ListItem
      button
      className={classes.listItem}
      selected={!loading && selected}
      disabled={disabled}
      style={{ ...(inUse && { opacity: 0.5 }) }}
      onClick={onClick}
    >
      {withCheckbox && (
        <ListItemAvatar className={classes.listItemAvatar}>
          {!inUse ? (
            <Checkbox color="primary" checked={!loading && selected} />
          ) : (
            <IconButton>
              <Info color="secondary" />
            </IconButton>
          )}
        </ListItemAvatar>
      )}
      {startIcon && <ListItemAvatar className={classes.listItemAvatar}>{startIcon}</ListItemAvatar>}
      <ListItemText
        style={{ marginLeft: withCheckbox ? 0 : 8 }}
        primary={loading ? <Skeleton /> : primary}
        secondary={loading ? <Skeleton /> : secondary}
      />
      <ListItemSecondaryAction style={{ display: 'flex', alignItems: 'center' }}>
        {action}
        {endIcon}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default NumberListItem
