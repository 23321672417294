import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInvoiceMobileSummary } from 'state/invoiceMobileSummary/actions'
import { fetchInvoiceMobileNumberSummary } from 'state/invoiceMobileNumberSummary/actions'
import { fetchInvoiceMobileIntSummary } from 'state/invoiceMobileIntSummary/actions'
import { fetchInvoiceReferences } from 'state/invoiceReferences/actions'
import { fetchInvoiceMobileSpecifications } from 'state/invoiceMobileSpecifications/actions'
import { fetchInvoiceNumberReferences } from 'state/invoiceNumberReferences/actions'
import { Paper, PaperHeader, PaperTitle, PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import SpecificationRadios from '../SpecificationRadios'
import UsageCategoryTable from '../UsageCategoryTable'
import NumberSummaryTable from '../NumberSummaryTable'
import AbroadCallsTable from '../AbroadCallsTable'
import SpecificationsTable from '../UsageCategoryTable/SpecificationsTable'

const MobileDetails = ({ invoiceId, customerId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [prevSelectedLocationTypes, setPrevSelectedLocationTypes] = useState(null)
  const [specificationType, setSpecificationType] = useState('summary')
  const invoiceMobileSummary = useSelector((state) => state.invoiceMobileSummary)
  const invoiceMobileNumberSummary = useSelector((state) => state.invoiceMobileNumberSummary)
  const invoiceMobileIntSummary = useSelector((state) => state.invoiceMobileIntSummary)
  const invoiceMobileSpecifications = useSelector((state) => state.invoiceMobileSpecifications)
  const invoiceNumberReferences = useSelector((state) => state.invoiceNumberReferences)
  const invoiceReferences = useSelector((state) => state.invoiceReferences)

  useEffect(() => {
    dispatch(fetchInvoiceNumberReferences({ customerId }))
    dispatch(fetchInvoiceReferences({ customerId }))
    if (
      invoiceMobileSpecifications.locationtype &&
      invoiceMobileSpecifications.invoiceId &&
      invoiceMobileSpecifications.invoiceId === invoiceId
    ) {
      setPrevSelectedLocationTypes(invoiceMobileSpecifications.locationtype)
    }
  }, [])

  useEffect(() => {
    if (specificationType === 'category') {
      dispatch(fetchInvoiceMobileSummary({ customerId, invoiceId }))
    }
    if (specificationType === 'summary') {
      dispatch(fetchInvoiceMobileNumberSummary({ customerId, invoiceId }))
    }
    if (specificationType === 'abroad') {
      dispatch(fetchInvoiceMobileIntSummary({ customerId, invoiceId }))
    }
  }, [specificationType])

  let specificationContent

  const handleFetchSpecifications = (locationTypes) => {
    dispatch(
      fetchInvoiceMobileSpecifications({
        customerId,
        invoiceId,
        locationtype: locationTypes.join(),
        startdate: invoiceMobileSummary.data.invoice.billing_from,
        enddate: invoiceMobileSummary.data.invoice.billing_to,
      })
    )
  }

  if (specificationType === 'category') {
    specificationContent = (
      <>
        <UsageCategoryTable
          data={invoiceMobileSummary}
          invoiceId={invoiceId}
          fetchSpecifications={handleFetchSpecifications}
          prevLocationTypes={prevSelectedLocationTypes}
        />
        {invoiceMobileSpecifications.data && !invoiceMobileSpecifications.loading && (
          <>
            <Paper>
              <PaperHeader noDivider>
                <PaperTitle title={t('Invoice_specss')} />
              </PaperHeader>
            </Paper>
            <SpecificationsTable data={invoiceMobileSpecifications} invoiceId={invoiceId} />
          </>
        )}
        {invoiceMobileSpecifications.loading && <PaperLoader />}
      </>
    )
  }

  if (specificationType === 'summary') {
    specificationContent = (
      <>
        {invoiceMobileNumberSummary.data &&
        invoiceNumberReferences.data &&
        invoiceReferences.data ? (
          <NumberSummaryTable
            data={invoiceMobileNumberSummary}
            invoiceId={invoiceId}
            existingRefs={invoiceNumberReferences}
            references={invoiceReferences}
          />
        ) : (
          <PaperLoader />
        )}
      </>
    )
  }

  if (specificationType === 'abroad') {
    specificationContent = (
      <AbroadCallsTable data={invoiceMobileIntSummary} invoiceId={invoiceId} type="mobile" />
    )
  }
  return (
    <>
      <SpecificationRadios
        specificationType={specificationType}
        setSpecificationType={setSpecificationType}
      />
      {specificationContent}
    </>
  )
}

export default MobileDetails
