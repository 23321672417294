import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  removePadding: {
    padding: '0px',
  },
  heading: {
    fontSize: theme.typography.body1.fontSize,
  },
  root: {
    marginTop: theme.spacing(0.6),
    marginBottom: theme.spacing(),
  },
}))

const ListItemTableCell = ({ primary, secondary }) => {
  const classes = useStyles()

  return (
    <List className={classes.removePadding}>
      <ListItem className={classes.removePadding}>
        <ListItemText
          classes={{
            primary: classes.heading,
            root: classes.root,
          }}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
          primary={primary}
          secondary={secondary}
        />
      </ListItem>
    </List>
  )
}

export default ListItemTableCell
