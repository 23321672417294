import { getValidation } from 'services/sweb/validation'

export const FETCH_FAILURE = 'validateImportMobileNumber/FETCH_FAILURE'
export const FETCH_BEGIN = 'validateImportMobileNumber/FETCH_BEGIN'
export const FETCH_SUCCESS = 'validateImportMobileNumber/FETCH_SUCCESS'

export const fetchValidateImportMobileNumberFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchValidateImportMobileNumberBegin = ({ phoneNumber }) => {
  return {
    type: FETCH_BEGIN,
    phoneNumber,
  }
}

export const fetchValidateImportMobileNumberSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchValidateImportMobileNumber = ({ customerId, phoneNumber, prefix = 45 }) => {
  return async (dispatch) => {
    dispatch(fetchValidateImportMobileNumberBegin({ phoneNumber }))
    try {
      const response = await getValidation.validateImportNumber({
        customerId,
        phoneNumber,
        prefix,
      })
      dispatch(fetchValidateImportMobileNumberSuccess(response.data))
    } catch (err) {
      dispatch(fetchValidateImportMobileNumberFailure(err.response))
    }
  }
}
