// eslint-disable-next-line import/prefer-default-export
export const AUTH_REDIRECTED_LOCALSTORAGE_KEY_PREFIX = `@SSFAPP//STATIC//AUTH_REDIRECT//`

export const MS_AUTH_REDIRECTED_LOCALSTORAGE_KEY_PREFIX = `${AUTH_REDIRECTED_LOCALSTORAGE_KEY_PREFIX}MSAUTH_REDIRECTED::` /**
    used for obtaining result for redirect in (served)/html/msauth-redirect,
    IE fails to communicate via window.open/er (which is not a greate solution either),
    therefore localStorage is used to pickup result from redirection in the window.open instance.
    https://stackoverflow.com/questions/45448779/window-open-returns-null-in-ie11-if-opening
  */

const MS_AUTH_REDIRECTED_BASE_URI = `${window.location.origin}/static/html/msauth-redirect` /**
  he redirect uri for the origin should be created on the corresponding oauth client used in azure
*/

export const MS_AUTH_REDIRECTED_OUTSIDE_TEAMS_CALLBACK_URI = `${MS_AUTH_REDIRECTED_BASE_URI}/outside-teams-callback`
export const MS_AUTH_REDIRECTED_PARTY_OUTSIDE_TEAMS_CALLBACK_URI = `${MS_AUTH_REDIRECTED_BASE_URI}/party/outside-teams-callback`
export const MS_AUTH_REDIRECTED_INSIDE_TEAMS_INVOKE_URI = `${MS_AUTH_REDIRECTED_BASE_URI}/inside-teams-invoke`
export const MS_AUTH_REDIRECTED_INSIDE_TEAMS_CALLBACK_URI = `${MS_AUTH_REDIRECTED_BASE_URI}/inside-teams-callback`
