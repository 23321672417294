import React, { useEffect, useState } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { updateSubscription } from 'services/sweb/subscription'
import { fetchAvailableMobilePackages } from 'state/availableMobilePackages/actions'
import { PaperLoader, PaperFeedbackMessage } from 'components'
import { useTranslation } from 'react-i18next'
import MobilePackageSelect from './MobilePackageSelect'

const ChangeMobilePackage = ({ subscription, customerId, changeRoute, title }) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const availableMobilePackages = useSelector((state) => state.availableMobilePackages)
  const abbTypesMatch = Boolean(subscription.abb_base_type === availableMobilePackages.abbType)

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    dispatch(
      fetchAvailableMobilePackages({
        customerId,
        abbType: subscription.abb_base_type,
      })
    )
  }, [])

  const handleChangeMobilePackage = (id) => {
    setLoading(true)
    updateSubscription
      .mobilePackage({ customerId, mobileNumber: subscription.mobile_number, id })
      .then(() => {
        enqueueSnackbar(t('Drawer_mobile_package_change_success'), { variant: 'success' })
        changeRoute()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  if (abbTypesMatch && !availableMobilePackages.loading && availableMobilePackages.error) {
    return (
      <PaperFeedbackMessage
        withMargin
        message={availableMobilePackages.error.statusText}
        type="error"
      />
    )
  }

  if (abbTypesMatch && availableMobilePackages.data && availableMobilePackages.data.meta) {
    return (
      <MobilePackageSelect
        currentPackage={subscription.mobile_package.current}
        mobilePackages={availableMobilePackages.data.meta}
        backToUser={changeRoute}
        handleChangeMobilePackage={handleChangeMobilePackage}
        loading={loading}
      />
    )
  }
  return <PaperLoader />
}

export default ChangeMobilePackage
