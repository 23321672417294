import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'internationalNumbersV2/FETCH_FAILURE'
export const FETCH_BEGIN = 'internationalNumbersV2/FETCH_BEGIN'
export const FETCH_SUCCESS = 'internationalNumbersV2/FETCH_SUCCESS'

export const fetchIntNumbersOrdersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchIntNumbersOrdersBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchIntNumbersOrdersSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchIntNumbersOrders = ({ customerId, pageNumber, pageSize }) => {
  return async (dispatch) => {
    dispatch(fetchIntNumbersOrdersBegin({ customerId }))
    try {
      const response = await getCustomer.intNumberOrders({
        customerId,
        pageNumber,
        pageSize,
      })
      dispatch(fetchIntNumbersOrdersSuccess(response.data))
    } catch (err) {
      dispatch(fetchIntNumbersOrdersFailure(err.response))
    }
  }
}
