import client from '../client'

export const all = ({ customerId }) => {
  return client
    .get(`teams/${customerId}/combined-users`, { timeout: false })
    .json()
    .then((response) => {
      if (response.data && response.data.combined_user_list) {
        response.data.users = response.data.combined_user_list.map((user) => {
          return {
            azureId: user.teams && user.teams.id,
            userId: user.subscription && user.subscription.user_id,
            certainId:
              (user.subscription && user.subscription.abb_id) || (user.teams && user.teams.id),
            name:
              (user.teams && user.teams.displayName) ||
              (user.subscription &&
                `${user.subscription.first_name} ${user.subscription.last_name}`),
            email:
              (user.teams && user.teams.mail) || (user.subscription && user.subscription.email),
            jobTitle: (user.teams && user.teams.jobTitle) || null,
            department: (user.teams && user.teams.department) || null,
            avatar: (user.teams && user.teams.avatar) || null,
            abbType: user.subscription && user.subscription.abb_type,
            abbId: user.subscription && user.subscription.abb_id,
            mobileNumber: user.subscription && user.subscription.mobile_number,
            landlineNumber: user.subscription && user.subscription.landline_number,
            mobileOneNumberShadowNumber:
              user.subscription && user.subscription.mobile_onenumber_shadownumber,
            international_number: user.subscription && user.subscription.international_number,
            teamsDisplayNumber:
              (user.subscription && user.subscription.international_number) ||
              (user.subscription && user.subscription.landline_number),
            teamsMobileNumber: user.teams && user.teams.mobileNumber,
            teamsLandlines: user.teams && user.teams.businessPhones,
            simCards: user.subscription && user.subscription.sim_card_iccs,
            mobilePackage: user.mobile_package,
            invoice: user.invoice,
            inChange: user.subscription && user.subscription.in_change,
            monthlyPrice: (user.invoice && user.invoice.monthly_price) || 0,
            linkWarning: Boolean(user.subscription && user.subscription.abb_id && !user.teams),
            hasMobilePackage: Boolean(user.mobile_package && user.mobile_package.current),
            hasSubscription: Boolean(user.subscription && user.subscription.abb_id),
            hasTeamsTelephony: Boolean(
              user.subscription && user.subscription.abb_id && user.subscription.landline_number
            ),
            endDate: user.subscription && user.subscription.end_date,
            requiresAzureLink:
              user.subscription?.requires_azure_link || !user.subscription || false,
          }
        })
      } else {
        response.data.users = []
      }
      return response
    })
}

export const teamsUsersOnly = ({ customerId }) => {
  return client.get(`teams/${customerId}/users`, { timeout: false }).json()
}

export const licenseDetails = ({ customerId, azureId }) => {
  return client.get(`teams/${customerId}/users/${azureId}/license-details`).json()
}

export const zendeskTickets = () => {
  return client.get(`tickets/user`).json()
}

export const profilePhoto = ({ customerId, json }) => {
  return client.post(`teams/${customerId}/profile-photos?size=96x96`, { json }).json()
}
export const allAdmins = ({ customerId }) => {
  return client.get(`teams/${customerId}/admins`).json()
}
