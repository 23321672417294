import React from 'react'
import classNames from 'classnames'
import { IconButton, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { HelpOutline as HelpIcon } from '@material-ui/icons'
import useButtonStyles from '../../hooks/useButtonStyle'

const useStyles = makeStyles(() => ({
  iconButton: {
    padding: 4,
  },
}))

interface Props {
  className?: string
  tooltip?: string
  icon?: JSX.Element
  color?: 'success' | 'warning' | 'primary' | 'error'
}

const HighlightedIcon = ({
  className,
  tooltip = '',
  icon = <HelpIcon />,
  color = 'success',
}: Props) => {
  const classes = useStyles()
  const buttons = useButtonStyles()

  const iconButton = classNames({
    [classes.iconButton]: true,
    [buttons.success]: color === 'success',
    [buttons.warning]: color === 'warning',
    [buttons.primary]: color === 'primary',
    [buttons.error]: color === 'error',
    className,
  })

  return (
    <Tooltip title={tooltip} arrow>
      <IconButton className={iconButton}>{icon}</IconButton>
    </Tooltip>
  )
}

export default HighlightedIcon
