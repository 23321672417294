import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  typography: {
    marginBottom: theme.spacing(3),
  },
}))

const InnerContent = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="body1" gutterBottom display="block" className={classes.typography}>
        {t('ContactUs_1')}
      </Typography>
      <Typography variant="body1" gutterBottom display="block">
        {t('ContactUs_2')}{' '}
        <Link href="https://support.sky.tdc.dk/hc/da/requests/new" target="_blank">
          {t('ContactUs_3')}
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom display="block">
        {t('ContactUs_4')}{' '}
        <Link href="mailto:support@sky.tdc.dk" target="_blank">
          support@sky.tdc.dk
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom display="block">
        {t('ContactUs_5')}
      </Typography>
      <Typography variant="body1" gutterBottom display="block" className={classes.typography}>
        {t('ContactUs_6')}
      </Typography>
      <Typography variant="body1" gutterBottom display="block" className={classes.typography}>
        {t('ContactUs_7')}
      </Typography>
      <Typography variant="h3" gutterBottom display="block" className={classes.typography}>
        {t('ContactUs_8')}
      </Typography>
      <Typography variant="body1" gutterBottom display="block" className={classes.typography}>
        {t('ContactUs_9')}
      </Typography>
      <Typography variant="body1" gutterBottom display="block" className={classes.typography}>
        {t('ContactUs_11')}
      </Typography>
      <Typography variant="body1" gutterBottom display="block" className={classes.typography}>
        {t('ContactUs_22')}
      </Typography>
      <Typography variant="body1" gutterBottom display="block" className={classes.typography}>
        {t('ContactUs_33')}
      </Typography>
    </>
  )
}

export default InnerContent
