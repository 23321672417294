import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'availableHuntgroupNumbers/FETCH_FAILURE'
export const FETCH_BEGIN = 'availableHuntgroupNumbers/FETCH_BEGIN'
export const FETCH_SUCCESS = 'availableHuntgroupNumbers/FETCH_SUCCESS'

export const fetchAvailableHuntgroupNumbersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAvailableHuntgroupNumbersBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchAvailableHuntgroupNumbersSuccess = ({ customerId, data }) => {
  return {
    type: FETCH_SUCCESS,
    customerId,
    data,
  }
}

export const fetchAvailableHuntgroupNumbers = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchAvailableHuntgroupNumbersBegin({ customerId }))
    try {
      const response = await getCustomer.availableHuntgroupNumbers({
        customerId,
      })
      dispatch(fetchAvailableHuntgroupNumbersSuccess({ customerId, data: response.data }))
    } catch (err) {
      dispatch(fetchAvailableHuntgroupNumbersFailure(err.response))
    }
  }
}
