import palette from '../palette'

export default {
  root: {
    backgroundColor: palette.background.default,
    '&$focused': {
      backgroundColor: palette.common.white,
    },
    /* '&:hover:not($disabled)': {
      backgroundColor: palette.common.white,
    }, */
  },
}
