import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { DataUsage as MobilePackageIcon, Autorenew as InProgressIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  progressIcon: {
    color: theme.palette.warning.main,
  },
}))

const MobilePackage = ({ mobilePackage, currentUrl, disabled }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const mobilePackageIsInChange = Boolean(
    mobilePackage.current &&
      mobilePackage.current.abb_name &&
      mobilePackage.previous &&
      mobilePackage.previous.abb_name
  )
  const mobilePackageIsModifiable = Boolean(
    mobilePackage.current && mobilePackage.current.modifiable
  )

  return (
    <DrawerListItem
      tooltip={
        (mobilePackageIsInChange &&
          !mobilePackageIsModifiable &&
          t('Drawer_user_mobile_change', {
            from: mobilePackage.previous.abb_name,
            to: mobilePackage.current.abb_name,
          })) ||
        (!mobilePackageIsInChange && !mobilePackageIsModifiable && t('Drawer_user_mobile_created'))
      }
      icon={
        mobilePackageIsModifiable ? (
          <MobilePackageIcon />
        ) : (
          <InProgressIcon className={classes.progressIcon} />
        )
      }
      primaryText={t('Drawer_price_mobile')}
      secondaryText={
        (mobilePackage.current && mobilePackage.current.abb_name) || t('Drawer_user_no_mobile')
      }
      isDisabled={disabled}
      link={mobilePackageIsModifiable ? `${currentUrl}/skift-mobilpakke` : ''}
      linkAnchor={mobilePackageIsModifiable ? t('Drawer_user_skift') : ''}
    >
      {!mobilePackageIsModifiable && (
        <Typography variant="body2" color="textSecondary">
          {t('Drawer_user_mobile_cant_change')}
        </Typography>
      )}
    </DrawerListItem>
  )
}

export default memo(MobilePackage)
