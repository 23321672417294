import { format } from 'date-fns'

export const formatStatus = (status) => {
  switch (status) {
    case 'Done':
      return 'Organization_orders_status_done'
    case 'Pending':
      return 'Organization_orders_status_ongoing'
    case 'Cancelled':
      return 'Organization_orders_status_cancelled'
    default:
      return 'Organization_orders_status_unknown'
  }
}

export const formatType = (type) => {
  switch (type) {
    case 'cancel':
      return 'Organization_orders_type_cancel'
    case 'create':
      return 'Organization_orders_type_create'
    case 'upgrade':
      return 'Organization_orders_type_upgrade'
    case 'change_type':
      return 'Organization_orders_type_change_type'
    case 'update_user':
      return 'Organization_orders_type_update_user'
    case 'reassign_subscription':
      return 'Organization_orders_type_reassign'
    case 'mobile_huntgroup_created':
      return 'Organization_orders_type_huntgroup_created'
    case 'mobile_huntgroup_deleted':
      return 'Organization_orders_type_huntgroup_deleted'
    case 'reserve_sim':
      return 'Organization_orders_type_reserve_sim'
    default:
      return 'Organization_orders_type_none'
  }
}

export const formatDate = (date) => {
  return date ? format(new Date(date), 'dd-MM-yyyy') : 'Organization_orders_type_unknown'
}
