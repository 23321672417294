import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  extraGutterBottom: {
    paddingBottom: theme.spacing(3),
  },
}))

const DrawerMessage = ({ children, className, extraGutterBottom, ...props }) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      // [classes.root]: true,
      [classes.extraGutterBottom]: extraGutterBottom,
    },
    className
  )

  return (
    <Typography {...props} className={rootClassName}>
      {children}
    </Typography>
  )
}

export default DrawerMessage
