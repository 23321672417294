import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'externalProviders/FETCH_FAILURE'
export const FETCH_BEGIN = 'externalProviders/FETCH_BEGIN'
export const FETCH_SUCCESS = 'externalProviders/FETCH_SUCCESS'

export const fetchExternalProvidersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchExternalProvidersBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchExternalProvidersSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchExternalProviders = () => {
  return async (dispatch) => {
    dispatch(fetchExternalProvidersBegin())
    try {
      const response = await getCustomer.externalProviders()
      dispatch(fetchExternalProvidersSuccess(response.data))
    } catch (err) {
      dispatch(fetchExternalProvidersFailure(err.response))
    }
  }
}
