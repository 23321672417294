import React, { useState } from 'react'
import { updateSubscription } from 'services/sweb/subscription'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Slide } from '@material-ui/core'
import { DrawerSection } from 'components'
import { useTranslation } from 'react-i18next'
import DrawerButtons from '../components/DrawerButtons'
import MobileForm from '../components/MobileForm'
import LandlineForm from '../components/LandlineForm'
import DisplayPrice from '../components/DisplayPrice'
import { formatSubscriptionOrder } from '../helpers/subscription'

const FormContainer = ({
  user,
  abbId,
  handleClose,
  customerId,
  mobilePackages,
  baseProducts,
  hasMobileSubscription,
  currentBaseSubscription,
  currentMobilePackage,
}) => {
  const me = useSelector((state) => state.me)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const initialLandlineForm = {
    landlineNumber: null,
    extension: null,
  }

  const initialMobileForm = {
    mobileNumber: null,
    mobilePackage: null,
    simcard: null,
    importMobileNumber: user.teamsMobileNumber || '',
    importSigner: user.email || '',
    datasim: null,
    alternativeSimcardAddress: '',
  }

  const [landlineForm, setLandlineForm] = useState({ isValid: false, ...initialLandlineForm })
  const [mobileForm, setMobileForm] = useState({ isValid: false, ...initialMobileForm })
  const [loading, setLoading] = useState(false)

  const handleSetLandlineForm = (form) => {
    setLandlineForm({ isValid: false, ...initialLandlineForm, ...form })
  }

  const handleSetMobileForm = (form) => {
    setMobileForm({ isValid: false, ...initialMobileForm, ...form })
  }

  const isInvalidForms = () => {
    if (hasMobileSubscription && !landlineForm.isValid) return true
    if (!hasMobileSubscription && !mobileForm.isValid) return true
    return false
  }

  const submitIsDisabled = isInvalidForms()

  const submitSubscription = () => {
    setLoading(true)
    const { email: receiptEmail } = me.data.user
    const json = formatSubscriptionOrder({
      withLandline: hasMobileSubscription,
      withMobile: !hasMobileSubscription,
      receiptEmail,
      mobile: mobileForm,
      landline: landlineForm,
    })
    updateSubscription
      .updateSingle({ customerId, abbId, json })
      .then(() => {
        setLoading(false)
        enqueueSnackbar(t('Drawer_upgrade_success'), {
          variant: 'success',
        })
        handleClose()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const baseSubscriptionPrice = baseProducts.mobile_and_landline.product_details[0].price

  const formTitle = hasMobileSubscription
    ? t('Drawer_create_sub_teams_title')
    : t('Drawer_create_sub_mobile_title')
  const upgradeForm = hasMobileSubscription ? (
    <LandlineForm
      initialForm={initialLandlineForm}
      oneNumberType="classic"
      onChange={handleSetLandlineForm}
      processingOrder={loading}
      customerId={customerId}
      azureId={user.azureId}
    />
  ) : (
    <MobileForm
      initialForm={initialMobileForm}
      mobilePackages={mobilePackages.available_packages}
      onChange={handleSetMobileForm}
      processingOrder={loading}
      customerId={customerId}
      azureId={user.azureId}
    />
  )

  return (
    <Slide direction="left" in>
      <div>
        <DrawerSection title={formTitle}>{upgradeForm}</DrawerSection>
        <DrawerSection title={t('Drawer_create_sub_ppm')}>
          <DisplayPrice
            totalPricePrefix={t('Drawer_cancel_new_price_per_month')}
            mobileAndLandline={{
              enabled: true,
              price: baseSubscriptionPrice,
              name: 'Drawer_price_teams_and_mobile',
              oldProduct: {
                label: hasMobileSubscription
                  ? t('Drawer_cancel_mobile_title')
                  : t('Drawer_cancel_teams_title'),
                price: currentBaseSubscription.price,
              },
            }}
            mobilePackage={
              hasMobileSubscription
                ? currentMobilePackage && {
                    name: currentMobilePackage.abb_name,
                    price: currentMobilePackage.price,
                  }
                : mobileForm.mobilePackage && {
                    name: mobileForm.mobilePackage.label,
                    price: mobileForm.mobilePackage.value.monthly_price,
                  }
            }
          />
        </DrawerSection>
        <DrawerButtons
          loading={loading}
          backLink={handleClose}
          submitText={t('Drawer_create_sub_create')}
          onSubmit={submitSubscription}
          disabled={submitIsDisabled}
        />
      </div>
    </Slide>
  )
}

export default FormContainer
