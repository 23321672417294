import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  gutters: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  verticalPadding: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  withMargin: {
    marginBottom: theme.spacing(2),
  },
}))

const PaperFeedbackMessage = ({
  className,
  type = 'info',
  disablePadding = true,
  disableGutters = true,
  withMargin = false,
  title,
  message,
  style,
}) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.gutters]: !disableGutters,
      [classes.verticalPadding]: !disablePadding,
      [classes.withMargin]: withMargin,
    },
    className
  )

  return (
    <Alert severity={type} className={rootClassName} style={{ ...style }}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert>
  )
}

export default PaperFeedbackMessage
