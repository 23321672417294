import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core'
import { GradientButton, SkyTextField } from 'components'

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
}))

const SendTicketModal = ({
  isOpen,
  handleCloseDialog,
  sendTicket,
  title,
  email,
  description,
  loading,
}) => {
  const classes = useStyles()
  const [form, setForm] = useState({
    userName: '',
    email: '',
    phoneNumber: '',
    comments: '',
  })

  useEffect(() => {
    if (email) setForm({ ...form, email })
  }, [])
  const handleForm = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleSendTicket = () => {
    const payload = { ...form, reasonForCallback: title }
    sendTicket(payload)
  }

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={handleCloseDialog}>
      <DialogTitle disableTypography>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <Typography variant="body1" color="primary" className={classes.text}>
          Indtast dit navn og telefonnummer, så ringer vi dig op til en uforpligtende snak.
        </Typography>
        <SkyTextField
          label="E-mail"
          name="email"
          value={form.email}
          onChange={handleForm}
          disabled={loading}
          required
        />
        <SkyTextField
          label="Navn"
          name="userName"
          value={form.userName}
          onChange={handleForm}
          disabled={loading}
          required
        />
        <SkyTextField
          label="Telefonnummer"
          name="phoneNumber"
          value={form.phoneNumber}
          onChange={handleForm}
          disabled={loading}
          required
        />
        <SkyTextField
          label="Eventuelle kommentarer"
          multiline
          disabled={loading}
          onChange={handleForm}
          rowsMax="4"
          name="comments"
          value={form.comments}
        />
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button onClick={handleCloseDialog} color="primary">
          Annullér
        </Button>
        <GradientButton
          onClick={handleSendTicket}
          loading={loading}
          disabled={Boolean(
            form.email.length < 2 || form.userName.length < 2 || form.phoneNumber.length < 8
          )}
        >
          Send
        </GradientButton>
      </DialogActions>
    </Dialog>
  )
}

export default SendTicketModal
