import client from '../client'
import { formatAll } from './getInvoiceFormatter'
import { SWEB_API_URI } from '../../../constants/configurable'

export const all = ({ customerId, limit = 6 }) => {
  return client
    .get(`invoice/${customerId}`, { searchParams: { limit }, timeout: false })
    .json()
    .then(formatAll)
}

export const subscriptionDetails = ({ customerId, invoiceId }) => {
  return client.get(`invoice/${customerId}/${invoiceId}/subscriptions/miscellaneous`).json()
}

export const extraServicesDetails = ({ customerId, invoiceId }) => {
  return client.get(`invoice/${customerId}/${invoiceId}/extra/miscellaneous`).json()
}

export const locationTypes = ({ customerId, invoiceId }) => {
  return client.get(`invoice/${customerId}/${invoiceId}/locationtypes`).json()
}

// type is mobile/fixed
export const summary = ({ customerId, invoiceId, type }) => {
  return client.get(`invoice/${customerId}/${invoiceId}/${type}/summary`).json()
}

export const summaryByPhonenumber = ({ customerId, invoiceId, type }) => {
  return client.get(`invoice/${customerId}/${invoiceId}/${type}/phonesummary`).json()
}

export const internationalSummary = ({ customerId, invoiceId, type }) => {
  return client.get(`invoice/${customerId}/${invoiceId}/${type}/internationalsummary`).json()
}

export const downloadPdf = ({ customerId, invoiceId }) => {
  return `${SWEB_API_URI}/invoice/${customerId}/${invoiceId}/download?access_token=${
    client.getBearer().value
  }`
}

export const references = ({ customerId }) => {
  return client.get(`invoice/${customerId}/reference`).json()
}

export const subscriptions = ({ customerId }) => {
  return client.get(`invoice/${customerId}/subscription?active=true`).json()
}

export const numberReferences = ({ customerId }) => {
  return client.get(`invoice/${customerId}/activenumber`).json()
}

export const specifications = ({
  customerId,
  invoiceId,
  anr,
  startdate,
  enddate,
  limit = 100000000,
  offset = 0,
  orderby = 'period',
  orientation = 'asc',
}) => {
  return client
    .get(`invoice/${customerId}/${invoiceId}/specification`, {
      searchParams: {
        anr,
        startdate,
        enddate,
        limit,
        offset,
        orderby,
        orientation,
      },
      timeout: false,
    })
    .json()
}

export const categorySpecifications = ({
  customerId,
  invoiceId,
  startdate,
  enddate,
  limit = 100000000,
  offset = 0,
  orderby = 'period',
  orientation = 'asc',
  locationtype,
}) => {
  return client
    .get(`invoice/${customerId}/${invoiceId}/specification`, {
      searchParams: {
        locationtype,
        startdate,
        enddate,
        limit,
        offset,
        orderby,
        orientation,
      },
      timeout: false,
    })
    .json()
}

export const referenceNames = ({ customerId, invoiceId }) => {
  return client.get(`invoice/${customerId}/${invoiceId}/subscriptions/referencenames`).json()
}

export const referenceSpecifications = ({ customerId, invoiceId, referenceId }) => {
  return client.get(`invoice/${customerId}/${invoiceId}/reference/${referenceId}`).json()
}
