import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List } from '@material-ui/core'
import {
  LocalOfferOutlined as TitleIcon,
  GroupOutlined as TeamIcon,
  CloudOffOutlined as NoAzureLicenseIcon,
  LinkOutlined as AzureIcon,
  LinkOffOutlined as BrokenAzureIcon,
  // SettingsOutlined as RoleIcon,
} from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'
import OfficeItem from './OfficeItem'

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}))

const BaseInformation = ({ jobTitle, department, azureId, currentUrl, disabled }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <List disablePadding>
      <DrawerListItem
        icon={<TitleIcon />}
        primaryText={t('Drawer_user_base_title')}
        secondaryText={jobTitle || t('Drawer_user_missing_info')}
        isDisabled={disabled}
      />
      <DrawerListItem
        icon={<TeamIcon />}
        primaryText={t('Drawer_user_base_department')}
        secondaryText={department || t('Drawer_user_missing_info')}
        isDisabled={disabled}
      />
      <DrawerListItem
        icon={azureId ? <AzureIcon /> : <BrokenAzureIcon className={classes.error} />}
        primaryText={t('Drawer_user_base_azure_link')}
        secondaryText={azureId || t('Drawer_user_base_azure_404')}
        link={!azureId ? `${currentUrl}/manglende-azure-id` : ''}
        linkAnchor={!azureId ? t('Drawer_user_see_more') : ''}
        isDisabled={disabled}
      />
      {azureId ? (
        <OfficeItem disabled={disabled} />
      ) : (
        <DrawerListItem
          icon={<NoAzureLicenseIcon className={classes.error} />}
          primaryText={t('Drawer_user_license')}
          secondaryText={t('Drawer_user_license_404')}
          isDisabled={disabled}
        />
      )}
    </List>
  )
}

export default memo(BaseInformation)

/*
<DrawerListItem
  icon={<RoleIcon />}
  primaryText="Roller i TDC Erhverv Selvbetjening"
  secondaryText="Brugeradministrator, fakturakig"
  link="Link is here"
  linkAnchor="Tildel roller"
/>
*/
