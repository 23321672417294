import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'
import { IMG_FIREWORKS } from 'constants/cdn'
import { useTranslation } from 'react-i18next'
import ChangeContainer from './components/ChangeContainer'
import TextBlock from './components/TextBlock'
import ConditionalRouterLink from './components/ConditionalRouterLink'

const useStyles = makeStyles((theme) => ({
  gif: {
    maxWidth: 250,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}))

const InnerContent = ({ customerId }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <ChangeContainer label={t('Changelog_1')}>
        <TextBlock>
          <strong>{t('Changelog_2')}</strong>
        </TextBlock>
        <TextBlock>
          {t('Changelog_3')}
          <ConditionalRouterLink to={customerId ? `/${customerId}/brugere` : null}>
            {t('Changelog_4')}
          </ConditionalRouterLink>{' '}
          {t('Changelog_5')}
        </TextBlock>
      </ChangeContainer>
      <ChangeContainer label={t('Changelog_6')}>
        <TextBlock>
          <strong>{t('Changelog_7')}</strong>
        </TextBlock>
        <TextBlock>
          {t('Changelog_8')}{' '}
          <ConditionalRouterLink to={customerId ? `/${customerId}/brugere` : null}>
            {t('Changelog_4')}
          </ConditionalRouterLink>{' '}
          {t('Changelog_234')}
        </TextBlock>
      </ChangeContainer>
      <ChangeContainer label={t('Changelog_423q2')}>
        <TextBlock>
          <strong>{t('Changelog_q5')}</strong>
        </TextBlock>
        <TextBlock>{t('Changelog_q5a')}</TextBlock>
      </ChangeContainer>
      <ChangeContainer label={t('Changelog_234567')}>
        <TextBlock>
          <strong>{t('Changelog_asfd')}</strong>
        </TextBlock>
        <TextBlock>
          {t('Changelog_dfgh')}{' '}
          <ConditionalRouterLink
            to={customerId ? `/${customerId}/organisation/mobile-ringegrupper` : null}
          >
            {t('Changelog_52')}
          </ConditionalRouterLink>
          {t('Changelog_11')}
        </TextBlock>
      </ChangeContainer>
      <ChangeContainer label={t('Changelog_22')}>
        <TextBlock>
          <strong>{t('Changelog_33')}</strong>
        </TextBlock>
        <TextBlock>
          {t('Changelog_44')}{' '}
          <ConditionalRouterLink
            to={customerId ? `/${customerId}/organisation/mobile-ringegrupper` : null}
          >
            {t('Changelog_52')}
          </ConditionalRouterLink>
          .
        </TextBlock>
      </ChangeContainer>
      <ChangeContainer label={t('Changelog_66')}>
        <TextBlock>
          <strong>{t('Changelog_77')}</strong>
        </TextBlock>
        <TextBlock>{t('Changelog_32')}</TextBlock>
      </ChangeContainer>
      <ChangeContainer label={t('Changelog_15')}>
        <TextBlock>
          <strong>{t('Changelog_475')}</strong>
        </TextBlock>
        <TextBlock>
          {t('Changelog_876')}{' '}
          <Link href="https://support.sky.tdc.dk/hc/da/articles/360011345299" target="_blank">
            {t('Changelog_543')}
          </Link>
          {t('Changelog_awef')}
        </TextBlock>
      </ChangeContainer>
      <ChangeContainer label="15. januar 2020">
        <TextBlock>
          <strong>{t('Changelog_gfnh')}</strong>
        </TextBlock>
        <TextBlock endBlock>{t('Changelog_kuy')}</TextBlock>
        <TextBlock>
          <strong>{t('Changelog_drth')}</strong>
        </TextBlock>
        <TextBlock>
          {t('Changelog_afwe')}{' '}
          <ConditionalRouterLink
            to={customerId ? `/${customerId}/organisation/leveringsadresse` : null}
          >
            {t('Changelog_f')}
          </ConditionalRouterLink>
          .
        </TextBlock>
      </ChangeContainer>
      <ChangeContainer label="19. december 2019">
        <TextBlock>
          <strong>{t('Changelog_w')}</strong>
        </TextBlock>
        <TextBlock>{t('Changelog_e')}</TextBlock>
      </ChangeContainer>
      <ChangeContainer label={t('Changelog_r')}>
        <TextBlock>
          <strong>{t('Changelog_t')}</strong>
        </TextBlock>
        <TextBlock>
          <ConditionalRouterLink to={customerId ? `/${customerId}/faktura` : null}>
            {t('Changelog_y')}
          </ConditionalRouterLink>{' '}
          {t('Changelog_u')}
        </TextBlock>
      </ChangeContainer>
      <ChangeContainer label={t('Changelog_i')}>
        <TextBlock>
          <strong>{t('Changelog_o')}</strong>
        </TextBlock>
        <TextBlock>
          {t('Changelog_p')}{' '}
          <ConditionalRouterLink to={customerId ? `/${customerId}/dashboard` : null}>
            Dashboard
          </ConditionalRouterLink>
          {t('Changelog_a')}
        </TextBlock>
      </ChangeContainer>
      <ChangeContainer label={t('Changelog_s')}>
        <TextBlock>
          <strong>{t('Changelog_d')}</strong>
        </TextBlock>
        <TextBlock>
          <ConditionalRouterLink to={customerId ? `/${customerId}/cloud-adoption` : null}>
            {t('Changelog_fe')}
          </ConditionalRouterLink>{' '}
          {t('Changelog_g')}
        </TextBlock>
      </ChangeContainer>
      <ChangeContainer label="23. oktober 2019">
        <TextBlock>
          <strong>{t('Changelog_j')}</strong>
        </TextBlock>
        <TextBlock endBlock>{t('Changelog_k')}</TextBlock>
        <TextBlock>
          <strong>{t('Changelog_l')}</strong>
        </TextBlock>
        <TextBlock>
          {t('Changelog_æ')}{' '}
          <ConditionalRouterLink to={customerId ? `/${customerId}/brugere` : null}>
            {t('Changelog_ø')}
          </ConditionalRouterLink>{' '}
          {t('Changelog_z')}
        </TextBlock>
      </ChangeContainer>
      <ChangeContainer label={t('Changelog_x')}>
        <TextBlock>
          <strong>{t('Changelog_c')}</strong>
        </TextBlock>
        <TextBlock>{t('Changelog_v')}</TextBlock>
      </ChangeContainer>
      <ChangeContainer label={t('Changelog_b')}>
        <TextBlock endBlock>
          <strong>{t('Changelog_n')}</strong>
        </TextBlock>
        <img src={IMG_FIREWORKS} alt="lolgif" className={classes.gif} />
        <TextBlock endBlock>{t('Changelog_m')}</TextBlock>
        <TextBlock>
          <strong>{t('Changelog_qw')}</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelog_we')}</li>
            <li>{t('Changelog_er')}</li>
            <li>{t('Changelog_rt')}</li>
            <li>{t('Changelog_yu')}</li>
          </ul>
        </TextBlock>
        <TextBlock>
          <strong>{t('Changelog__1')}</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelog__2')}</li>
            <li>{t('Changelog__34')}</li>
            <li>{t('Changelog__3')}</li>
            <li>{t('Changelog__4')}</li>
          </ul>
        </TextBlock>
        <TextBlock>
          <strong>Dashboard</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelog__5')}</li>
            <li>{t('Changelog__6')}</li>
            <li>{t('Changelog__7')}</li>
            <li>{t('Changelog__8')}</li>
            <li>{t('Changelog__9')}</li>
            <li>{t('Changelog__39')}</li>
            <li>{t('Changelog__thr')}</li>
            <li>{t('Changelog__90')}</li>
          </ul>
        </TextBlock>
        <TextBlock>
          <strong>{t('Changelog_awfe')}</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelog_xcv')}</li>
            <li>{t('Changelog_cvb')}</li>
            <li>{t('Changelog_vnb')}</li>
            <li>{t('Changelog_ht')}</li>
            <li>{t('Changelog_ergs')}</li>
            <li>{t('Changelog_456')}</li>
            <li>{t('Changelog_gers')}</li>
          </ul>
        </TextBlock>

        <TextBlock>
          <strong>{t('Changelog_hjgf')}</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelog_kgjh')}</li>
            <li>{t('Changelog_oui')}</li>
            <li>{t('Changelog_khjg')}</li>
            <li>{t('Changelog_cvx')}</li>
            <li>{t('Changelog_vb')}</li>
          </ul>
        </TextBlock>
        <TextBlock>
          <strong>{t('Changelog_ncbv')}</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelog_mbnv')}</li>
            <li>{t('Changelog_1234')}</li>
            <li>{t('Changelog_2345')}</li>
          </ul>
        </TextBlock>
        <TextBlock>
          <strong>{t('Changelog_3456')}</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelog_4567')}</li>
          </ul>
        </TextBlock>
        <TextBlock>
          <strong>{t('Changelog_5678')}</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelog_6789')}</li>
            <li>{t('Changelog_7890')}</li>
          </ul>
        </TextBlock>
        <TextBlock>
          <strong>{t('Changelog23456_')}</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelog5435_')}</li>
            <li>{t('Changelog6435_')}</li>
          </ul>
        </TextBlock>
        <TextBlock>
          <strong>Hardware</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelog3453_')}</li>
            <li>{t('Changelog435564_')}</li>
            <li>{t('Changelog34568_')}</li>
          </ul>
        </TextBlock>
        <TextBlock>
          <strong>Support</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelog34563_')}</li>
            <li>status.sky.tdc.dk iframed</li>
            <li>{t('Changelog56475_')}</li>
            <li>{t('Changelogdhgf_')}</li>
          </ul>
        </TextBlock>
        <TextBlock>
          <strong>{t('Changelogjgh_')}</strong>
        </TextBlock>
        <TextBlock component="span">
          <ul>
            <li>{t('Changelogsfd_')}</li>
            <li>{t('Changeloguyi_')}</li>
            <li>{t('Changeloggetsr_')}</li>
            <li>Chat-widget</li>
            <li>{t('Changelogmjhg_')}</li>
          </ul>
        </TextBlock>
      </ChangeContainer>
    </>
  )
}

export default InnerContent
