import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'
import daLocale from 'date-fns/locale/da'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter } from 'react-router-dom'
import theme from '@skytdc/mui-style/theme'
import { MuiThemeProvider } from '@material-ui/core/styles'

import store from './state/store'
import Theming from './Theming'

import App from './App'
import appOpts from './services/appOpts'

const Selfservice = () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Theming>
          <BrowserRouter>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
              <App />
            </MuiPickersUtilsProvider>
          </BrowserRouter>
        </Theming>
      </MuiThemeProvider>
    </Provider>
  )
}

appOpts
  .then(() => {
    ReactDOM.render(<Selfservice />, document.getElementById('root'))
  })
  .catch((err) => {
    throw err
  })
