import React, { useState } from 'react'
import { SkySelect, SkyTextField, DrawerSection } from 'components'
import { useTranslation } from 'react-i18next'
import { Slide } from '@material-ui/core'
import DrawerButtons from 'views/Drawer/components/DrawerButtons'
// TODO move drawerbuttons to shared view components

const OrderForm = ({
  loading,
  backLink,
  orderUnassignedSimcards,
  address,
  attention,
  simcards,
}) => {
  const { t } = useTranslation()
  const [form, setForm] = useState({
    simcard: null,
    city: (address && address.city) || '',
    zip: (address && address.zip) || '',
    company: (address && address.recipient) || '',
    streetAddress: (address && address.streetAddress) || '',
    attention: attention || '',
  })

  const formIsValid = Boolean(
    form.simcard &&
      form.city.length > 2 &&
      form.zip.length === 4 &&
      form.company.length > 2 &&
      form.streetAddress.length > 2 &&
      form.attention.length > 2
  )

  const handleForm = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleSetSimcard = (event, val) => {
    setForm({ ...form, simcard: val })
  }

  const handleOrderSimcard = () => {
    const data = {}
    data.service_type_id = form.simcard.value.service_type_id
    data.alternate_shipping_address = {
      order_sent_to_city: form.city,
      order_sent_to_zip: form.zip,
      order_sent_to_recipient: form.company,
      order_sent_to_address: form.streetAddress,
      order_sent_to_attention: form.attention,
    }
    data.comment = ''
    data.test_mode = false
    orderUnassignedSimcards(data)
  }

  const simcardOptions = simcards.map((x) => {
    return {
      label: x.name,
      value: x,
    }
  })

  return (
    <Slide direction="left" in>
      <div>
        <DrawerSection title={t('Organization_unassignedsimcards_order')}>
          <SkySelect
            label={t('Organization_unassignedsimcards_choose')}
            value={form.simcard}
            onChange={handleSetSimcard}
            disabled={loading}
            options={simcardOptions}
          />
          <SkyTextField
            label={t('Organization_unassignedsimcards_company_name')}
            name="company"
            value={form.company}
            onChange={handleForm}
            disabled={loading}
            required
          />
          <SkyTextField
            label="Att."
            name="attention"
            value={form.attention}
            onChange={handleForm}
            disabled={loading}
            required
          />
          <SkyTextField
            label={t('Organization_orders_drawer_address')}
            name="streetAddress"
            value={form.streetAddress}
            onChange={handleForm}
            disabled={loading}
            required
          />
          <SkyTextField
            label={t('Organization_orders_drawer_zip')}
            name="zip"
            value={form.zip}
            onChange={handleForm}
            disabled={loading}
            required
          />
          <SkyTextField
            label={t('Organization_orders_drawer_city')}
            name="city"
            value={form.city}
            onChange={handleForm}
            disabled={loading}
            required
          />
          <DrawerButtons
            backLink={backLink}
            disabled={!formIsValid}
            onSubmit={handleOrderSimcard}
            submitText={t('Organization_unassignedsimcards_order_cta')}
            loading={loading}
          />
        </DrawerSection>
      </div>
    </Slide>
  )
}

export default OrderForm
