import React from 'react'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { ChevronRightRounded as ChevronRightIcon } from '@material-ui/icons'
import { TableCellListItem } from 'components'
import { phoneNumberFormat } from 'utils/helpers'
import StatusCell from './components/StatusCell'

const OrdersTableRow = ({ addon, mediation, orderInfo, voxboneProvision, openOrder }) => {
  const { resultText, resultId, inserted, orderedby, orderId } = orderInfo
  const {
    primaryDomain,
    backupDomain,
    e164Number: voxboneNumber,
    voiceUriDescription,
  } = voxboneProvision
  const { originCountry, dialPrefix, nationalNumber, description } = mediation || {}
  const { locationDescription, e164Number } = addon || {}

  const handleOpenOrder = () => openOrder(orderId)

  const getCountryCodeAndNumber = PhoneNumberUtil.getInstance().parseAndKeepRawInput(
    voxboneNumber || e164Number
  )

  const number = `+${dialPrefix || getCountryCodeAndNumber.getCountryCode()} ${phoneNumberFormat(
    nationalNumber || `${getCountryCodeAndNumber.getNationalNumber()}`
  )}`

  return (
    <TableRow hover onClick={handleOpenOrder}>
      <TableCell padding="none">
        <StatusCell resultId={resultId} resultText={resultText} inserted={inserted} />
      </TableCell>
      <TableCell padding="none">
        <TableCellListItem primary={number} secondary={originCountry} />
      </TableCell>
      <TableCell padding="none">
        <TableCellListItem primary={primaryDomain} secondary={backupDomain || 'No backup domain'} />
      </TableCell>
      <TableCell padding="none">
        <TableCellListItem
          primary={orderedby}
          secondary={<i>&quot;{locationDescription || description || voiceUriDescription}&quot;</i>}
        />
      </TableCell>
      <TableCell padding="none" align="right" style={{ paddingRight: 8, maxWidth: 50, width: 50 }}>
        <IconButton>
          <ChevronRightIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default OrdersTableRow
