import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAzureDomains } from 'state/azureDomains/actions'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useTranslation } from 'react-i18next'
import { Paper, PaperContent, Alert } from '@skytdc/mui/components'
import Table from './components/Table'

interface Props extends RouteComponentProps {
  customerId: string
}

const Domains = ({ customerId }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const azureDomains = useSelector((state) => state.azureDomains)

  useEffect(() => {
    changeDocumentTitle(t('Domains'), ' - ', ':applicationName')
    if (!azureDomains.loading) dispatch(fetchAzureDomains({ customerId }))
  }, [])

  if (azureDomains.error && azureDomains.error.statusText) {
    return (
      <Paper>
        <PaperContent>
          <Alert type="error" message={azureDomains.error.statusText} />
        </PaperContent>
      </Paper>
    )
  }

  return <Table domains={azureDomains.data || []} loading={!azureDomains.data} />
}

export default Domains
