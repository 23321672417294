import React from 'react'
import classNames from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    borderRadius: '50%',
    flexGrow: 0,
    flexShrink: 0,
    marginBottom: 1,
    marginRight: 6,
  },
  sm: {
    height: theme.spacing(0.5),
    width: theme.spacing(0.5),
  },
  md: {
    height: theme.spacing(1),
    width: theme.spacing(1),
  },
  lg: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  neutral: {
    backgroundColor: theme.palette.text.secondary,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
}))

interface Props {
  className?: string
  size?: 'sm' | 'md' | 'lg'
  color?: 'neutral' | 'primary' | 'info' | 'warning' | 'error' | 'success'
}

const StatusCircle = ({ className, size = 'md', color = 'primary' }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes[size]]: size,
      [classes[color]]: color,
    },
    className
  )

  return <span className={rootClassName} />
}

export default StatusCircle
