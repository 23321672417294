import React, { memo } from 'react'
import { SimCardOutlined as SimcardIcon, Code as CodeIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import useCopyToClipboard from 'theme/hooks/useCopyToClipboard'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const DatashareSimcards = ({ simcards }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { copyFn, CopyPlaceholder } = useCopyToClipboard()

  const copyToClipboard = ({ value = '' }) => {
    copyFn({ value })
    enqueueSnackbar(t('Drawer_mypage_copy', value))
  }

  return (
    <>
      <CopyPlaceholder />
      {simcards.map((simcard, index) => {
        return (
          <div key={simcard.icc}>
            <DrawerListItem
              icon={<SimcardIcon />}
              primaryText={`${t('Drawer_simcard_datashare_number', {
                number: index + 1,
              })} - ICC-nummer`}
              secondaryText={simcard.icc}
              linkAnchor={t('Drawer_mypage_copy_iicc')}
              onClick={() => copyToClipboard({ value: simcard.icc })}
            />
            <DrawerListItem
              icon={<CodeIcon />}
              primaryText={`${t('Drawer_simcard_datashare_number', {
                number: index + 1,
              })} - pin-kode`}
              secondaryText={simcard.pin_code}
              linkAnchor={t('Drawer_mypage_copy_pin')}
              onClick={() => copyToClipboard({ value: simcard.pin_code })}
            />
            <DrawerListItem
              icon={<CodeIcon />}
              primaryText={`${t('Drawer_simcard_datashare_number', {
                number: index + 1,
              })} - puk-kode`}
              secondaryText={simcard.puk_code}
              linkAnchor={t('Drawer_mypage_copy_puk')}
              onClick={() => copyToClipboard({ value: simcard.puk_code })}
            />
          </div>
        )
      })}
    </>
  )
}

export default memo(DatashareSimcards)
