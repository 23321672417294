import React, { useState, useEffect } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSelector, shallowEqual, useDispatch, batch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Slide } from '@material-ui/core'
import { updateSubscription } from 'services/sweb/subscription'
import { fetchMobileDisplayNumber } from 'state/mobileDisplayNumber/actions'
import { fetchLandlinePrivateNumber } from 'state/landlinePrivateNumber/actions'
import { fetchAvailableDisplayNumbers } from 'state/availableDisplayNumbers/actions'
import { fetchSubSharedNumbers } from 'state/subSharedNumbers/actions'
import { fetchLandlineDisplayNumber } from 'state/landlineDisplayNumber/actions'
import { PaperLoader, PaperFeedbackMessage } from 'components'
import { useTranslation } from 'react-i18next'
import DisplayNumberForm from '../components/DisplayNumberForm'
import GroupNumbersTransferList from '../components/GroupNumbersTransferList'

const LandlineDisplayNumber = ({ subscription, customerId, changeRoute, title }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const stored = useSelector(
    (state) => ({
      mobileDisplayNumber: state.mobileDisplayNumber,
      landlineDisplayNumber: state.landlineDisplayNumber,
      landlinePrivateNumber: state.landlinePrivateNumber,
      availableDisplayNumbers: state.availableDisplayNumbers,
      subSharedNumbers: state.subSharedNumbers,
    }),
    shallowEqual
  )

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    batch(() => {
      dispatch(
        fetchLandlineDisplayNumber({
          customerId,
          landlineNumber: subscription.landline_number,
        })
      )
      dispatch(
        fetchLandlinePrivateNumber({
          customerId,
          landlineNumber: subscription.landline_number,
        })
      )
      dispatch(
        fetchAvailableDisplayNumbers({
          customerId,
        })
      )
      dispatch(
        fetchSubSharedNumbers({
          customerId,
          abbId: subscription.abb_id,
        })
      )
    })
    if (subscription.mobile_number) {
      dispatch(
        fetchMobileDisplayNumber({
          customerId,
          mobileNumber: subscription.mobile_number,
        })
      )
    }
  }, [])

  const setDisplayNumber = (chosenDisplayNumber, disablePrivateNumber) => {
    setLoading(true)
    updateSubscription
      .setDisplayNumber({
        customerId,
        phoneNumber: subscription.landline_number,
        displayNumber: chosenDisplayNumber,
      })
      .then(() => {
        if (disablePrivateNumber) {
          updateSubscription
            .setPrivateNumber({
              customerId,
              phoneNumber: subscription.landline_number,
              json: { enabled: false },
            })
            .then(() => {
              enqueueSnackbar(
                t('Drawer_landline_display_success', { number: chosenDisplayNumber }),
                {
                  variant: 'success',
                }
              )
              changeRoute()
            })
            .catch((err) => {
              err.response
                .json()
                .then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
              setLoading(false)
            })
        } else {
          enqueueSnackbar(t('Drawer_landline_display_success', { number: chosenDisplayNumber }), {
            variant: 'success',
          })
          changeRoute()
        }
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  const setInternationalDisplayNumber = (json, disablePrivateNumber) => {
    setLoading(true)
    updateSubscription
      .setInternationalDisplayNumber({
        customerId,
        phoneNumber: subscription.landline_number,
        json,
      })
      .then(() => {
        if (disablePrivateNumber) {
          updateSubscription
            .setPrivateNumber({
              customerId,
              phoneNumber: subscription.landline_number,
              json: { enabled: false },
            })
            .then(() => {
              enqueueSnackbar(
                t('Drawer_landline_display_success', { number: `${json.prefix} ${json.number}` }),
                {
                  variant: 'success',
                }
              )
              changeRoute()
            })
            .catch((err) => {
              err.response
                .json()
                .then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
              setLoading(false)
            })
        } else {
          enqueueSnackbar(t('Drawer_landline_display_success', { number: `${json.number}` }), {
            variant: 'success',
          })
          changeRoute()
        }
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  const setPrivateNumber = (json) => {
    setLoading(true)
    updateSubscription
      .setPrivateNumber({
        customerId,
        phoneNumber: subscription.landline_number,
        json,
      })
      .then(() => {
        enqueueSnackbar(t('Drawer_landline_hidden_number_success'), {
          variant: 'success',
        })
        changeRoute()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  const setGroupNumbers = (json) => {
    setLoading(true)
    updateSubscription
      .setGroupNumbers({
        customerId,
        abbId: subscription.abb_id,
        json,
      })
      .then(() => {
        setLoading(false)
        enqueueSnackbar(t('Drawer_landline_group_number_success'), {
          variant: 'success',
        })
        batch(() => {
          dispatch(
            fetchAvailableDisplayNumbers({
              customerId,
            })
          )
          dispatch(
            fetchSubSharedNumbers({
              customerId,
              abbId: subscription.abb_id,
            })
          )
        })
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  let content = <PaperLoader />

  if (!stored.landlineDisplayNumber.loading && stored.landlineDisplayNumber.error) {
    content = <PaperFeedbackMessage withMargin message={t('Drawer_landline_error')} type="error" />
  }

  if (
    (!stored.availableDisplayNumbers.loading && stored.availableDisplayNumbers.error) ||
    (!stored.subSharedNumbers.loading && stored.subSharedNumbers.error) ||
    (!stored.landlinePrivateNumber.loading && stored.landlinePrivateNumber.error)
  ) {
    content = (
      <PaperFeedbackMessage withMargin message={t('Drawer_landline_another_error')} type="error" />
    )
  }

  if (
    stored.landlineDisplayNumber.data &&
    stored.availableDisplayNumbers.data &&
    stored.subSharedNumbers.data &&
    stored.landlinePrivateNumber.data
  ) {
    const landlineNumber =
      subscription.international_number_product_version === 1 ||
      !subscription.international_number_product_version
        ? subscription.landline_number
        : null

    content = (
      <Slide in direction="left">
        <div>
          <DisplayNumberForm
            loading={loading}
            backLink={changeRoute}
            setDisplayNumber={setDisplayNumber}
            privateNumber={stored.landlinePrivateNumber.data}
            setPrivateNumber={setPrivateNumber}
            displayNumber={stored.landlineDisplayNumber.data}
            setInternationalDisplayNumber={setInternationalDisplayNumber}
            availableDisplayNumbers={stored.subSharedNumbers.data}
            title={t('Drawer_landline_title')}
            currentLandlineNumber={landlineNumber}
            currentMobileNumber={null}
            currentIntNumber={
              subscription.international_number && subscription.international_number_prefix
                ? {
                    number: subscription.international_number,
                    prefix: subscription.international_number_prefix,
                  }
                : null
            }
          />
          <GroupNumbersTransferList
            loading={loading}
            backLink={changeRoute}
            customerGroupNumbers={stored.availableDisplayNumbers.data}
            subscriptionGroupNumbers={stored.subSharedNumbers.data}
            setGroupNumbers={setGroupNumbers}
            activeMobileDisplay={stored.mobileDisplayNumber.data}
            activeLandlineDisplay={stored.landlineDisplayNumber.data}
          />
        </div>
      </Slide>
    )
  }

  return content
}

export default LandlineDisplayNumber
