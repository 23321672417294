import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Slide, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import { PaperFeedbackMessage, DrawerListItem, DrawerSection } from 'components'
import { useTranslation } from 'react-i18next'
import DrawerButtons from '../components/DrawerButtons'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  feedbackMessage: {
    margin: theme.spacing(2),
  },
  buttons: {
    marginBottom: theme.spacing(4),
  },
  radio: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: theme.palette.common.white,
  },
}))

const MobilePackageSelect = ({
  currentPackage,
  mobilePackages,
  loading,
  backToUser,
  handleChangeMobilePackage,
}) => {
  const { t } = useTranslation()
  const [selectedPackage, setSelectedPackage] = useState(
    String(currentPackage.abb_type_id) || String(mobilePackages.available_packages[0].abb_type_id)
  )
  const classes = useStyles()
  const currentPackageSelected = Boolean(selectedPackage === String(currentPackage.abb_type_id))

  const handleChange = (event) => {
    setSelectedPackage(event.target.value)
  }
  const handleBtnClick = (id) => {
    setSelectedPackage(id)
  }

  return (
    <Slide direction="left" in>
      <div className={classes.container}>
        <DrawerSection title={t('Drawer_mobile_package_title')} />
        {mobilePackages.length > 0 ? (
          <RadioGroup
            aria-label="Available mobile packages"
            value={selectedPackage}
            onChange={handleChange}
          >
            {mobilePackages
              .sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
              .map((option) => (
                <DrawerListItem
                  key={option.abb_type_id}
                  isDisabled={
                    Boolean(String(option.abb_type_id) === String(currentPackage.abb_type_id)) ||
                    Boolean(loading)
                  }
                  icon={
                    <FormControlLabel
                      className={classes.radio}
                      value={String(option.abb_type_id)}
                      control={<Radio color="primary" />}
                    />
                  }
                  link="#"
                  primaryText={option.abb_name}
                  onClick={() => handleBtnClick(String(option.abb_type_id))}
                  secondaryActionText={t('Drawer_mobile_package_price_per_month', {
                    price: option.price,
                  })}
                />
              ))}
          </RadioGroup>
        ) : (
          <PaperFeedbackMessage
            message={t('Drawer_mobile_package_none_avail')}
            className={classes.feedbackMessage}
          />
        )}
        <DrawerButtons
          withSubmitButton={mobilePackages.length > 0}
          backLink={backToUser}
          onSubmit={() => handleChangeMobilePackage(selectedPackage)}
          disabled={currentPackageSelected}
          loading={Boolean(loading)}
          submitText={t('Drawer_mobile_package_title')}
        />
      </div>
    </Slide>
  )
}

export default MobilePackageSelect
