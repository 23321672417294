import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceLandlineNumberSummary/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceLandlineNumberSummary/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceLandlineNumberSummary/FETCH_SUCCESS'

export const fetchInvoiceLandlineNumberSummaryFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceLandlineNumberSummaryBegin = ({ invoiceId }) => {
  return {
    type: FETCH_BEGIN,
    invoiceId,
  }
}

export const fetchInvoiceLandlineNumberSummarySuccess = ({ invoiceId, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    invoiceId,
  }
}

export const fetchInvoiceLandlineNumberSummary = ({ customerId, invoiceId }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceLandlineNumberSummaryBegin({ invoiceId }))
    const type = 'fixed'
    try {
      const response = await getInvoice.summaryByPhonenumber({ customerId, invoiceId, type })
      dispatch(fetchInvoiceLandlineNumberSummarySuccess({ invoiceId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceLandlineNumberSummaryFailure(err.response))
    }
  }
}
