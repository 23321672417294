import React, { memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchEndUserProfileImage } from 'state/endUserProfileImage/actions'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  CircularProgress,
  Grow,
  Typography,
  Button,
} from '@material-ui/core'
import {
  DataUsage as MobilePackageIcon,
  SmartphoneOutlined as MobileIcon,
  LocalPhoneOutlined as LandlineIcon,
  PersonOutlined as UserIcon,
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import getInitials from 'utils/getInitials'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '128px',
    height: '128px',
    fontSize: '40px',
    marginRight: theme.spacing(2),
    backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    [theme.breakpoints.down('sm')]: {
      width: '75px',
      height: '75px',
      fontSize: '24px',
    },
  },
  userName: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  email: {
    fontSize: '14px',
    marginTop: theme.spacing(0.6),
    paddingBottom: theme.spacing(0.6),
    paddingLeft: '2px',
  },
  avatarContainer: {
    minWidth: '128px',
    minHeight: '128px',
    marginTop: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      minWidth: '80px',
      minHeight: '80px',
    },
  },
  avatarIcon: {
    height: 44,
    width: 44,
  },
  categoryContainer: {
    display: 'flex',
    marginTop: theme.spacing(2.5),
  },
  categoryPill: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1.2),
    paddingTop: theme.spacing(0.8),
    paddingBottom: theme.spacing(0.8),
    marginRight: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(0.6),
  },
  link: {
    textDecoration: 'none',
    marginLeft: 'auto',
    width: '100%',
  },
}))

const TopContainer = ({
  name,
  email,
  abbId,
  mobilePackageLabel,
  landlineNumber,
  mobileNumber,
  customerId,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const userProfilePhoto = useSelector((state) => state.endUserProfileImage)
  const hasProfilePhoto = userProfilePhoto.data && userProfilePhoto.data.profilePhoto.length > 0

  useEffect(() => {
    dispatch(fetchEndUserProfileImage({ abbId }))
  }, [])

  let profilePhoto = <CircularProgress size={28} />

  if (hasProfilePhoto) {
    profilePhoto = (
      <Grow in>
        <Avatar
          alt={name}
          src={`data:image/jpg;base64,${userProfilePhoto.data.profilePhoto}`}
          className={classes.avatar}
        />
      </Grow>
    )
  }

  if (!userProfilePhoto.loading && !hasProfilePhoto) {
    profilePhoto = (
      <Grow in>
        <Avatar className={classes.avatar}>
          {(name.length > 2 && getInitials(name)) || <UserIcon className={classes.avatarIcon} />}
        </Avatar>
      </Grow>
    )
  }

  return (
    <List disablePadding>
      <ListItem disableGutters dense>
        <ListItemAvatar className={classes.avatarContainer}>{profilePhoto}</ListItemAvatar>
        <ListItemText
          classes={{
            primary: classes.userName,
            secondary: classes.email,
          }}
          primaryTypographyProps={{ noWrap: true, component: 'p' }}
          secondaryTypographyProps={{ noWrap: true, color: 'secondary' }}
          primary={(name.length > 2 && name) || t('Drawer_mypage_as')}
          secondary={
            <>
              {email || t('Drawer_header_no_email')}
              <span className={classes.categoryContainer}>
                {mobilePackageLabel && (
                  <Typography
                    color="secondary"
                    className={classes.categoryPill}
                    component="span"
                    display="inline"
                    variant="body2"
                  >
                    <MobilePackageIcon className={classes.icon} /> {mobilePackageLabel}
                  </Typography>
                )}
                {mobileNumber && (
                  <Typography
                    color="secondary"
                    className={classes.categoryPill}
                    component="span"
                    display="inline"
                    variant="body2"
                  >
                    <MobileIcon className={classes.icon} /> {mobileNumber}
                  </Typography>
                )}
                {landlineNumber && (
                  <Typography
                    color="secondary"
                    className={classes.categoryPill}
                    component="span"
                    display="inline"
                    variant="body2"
                  >
                    <LandlineIcon className={classes.icon} /> {landlineNumber}
                  </Typography>
                )}
              </span>
            </>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText primary={t('Drawer_mypage_wef')} />
      </ListItem>
      <ListItem>
        <Link to={`/${customerId}/skyapp`} className={classes.link}>
          <Button fullWidth color="primary" variant="outlined">
            {t('Skyapp_link')}
          </Button>
        </Link>
      </ListItem>
    </List>
  )
}

export default memo(TopContainer)
