import { FETCH_BEGIN, FETCH_SUCCESS, FETCH_FAILURE } from './actions'

const initialState = {
  loading: false,
  customerId: null,
  data: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        data: action.customerId === state.customerId ? state.data : initialState.data,
      }

    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        customerId: action.customerId,
        error: null,
        data: action.data,
      }

    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: null,
      }

    default:
      return state
  }
}
