import React, { memo } from 'react'
import { ListItem, ListItemText, ListItemIcon, Grow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { HighlightOff as CloseIcon, CheckCircle as CheckIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  listItem: {
    marginBottom: 5,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    marginTop: 1,
  },
  passed: {
    color: theme.palette.success.main,
  },
  notPassed: {
    color: theme.palette.error.main,
  },
}))

const HealthCheckItem = ({ name, status, message }) => {
  const classes = useStyles()

  return (
    <Grow in={Boolean(name)}>
      <ListItem className={classes.listItem}>
        <ListItemIcon>
          {status === 'OK' ? (
            <CheckIcon className={classes.passed} />
          ) : (
            <CloseIcon className={classes.notPassed} />
          )}
        </ListItemIcon>
        <ListItemText primary={name} secondary={`${status}${message ? `. ${message}` : ''}`} />
      </ListItem>
    </Grow>
  )
}

export default memo(HealthCheckItem)
