import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchOCInvites } from 'state/oCInvites/actions'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Paper, PaperContent, Alert } from '@skytdc/mui/components'
import { updateUser, deleteUser } from 'services/sweb/user'
import CustomDialog, { DialogProps } from 'components/CustomDialog'
import { ButtonGroup, Button } from '@material-ui/core'
import { Delete as DeleteIcon, Email as EmailIcon } from '@material-ui/icons'
import { AppState } from 'state/reducers'
import UsersTable from './components/UsersTable'
import UserProfile from '../../InvitePage/components/UserProfile'

interface Props extends RouteComponentProps {
  customerId: string
}
interface allDialogProps extends DialogProps {
  invitedata: { status: string; sendtTo: string; inviteId: string }
}

const OCAdminInvite = ({ customerId }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [inviteStatus, setInviteStatus] = useState<{
    text?: string
    variant?: 'success' | 'error'
  }>({})
  const [dialog, setDialog] = useState<allDialogProps>({})

  const { loading, data, error } = useSelector((state: AppState) => state.oCInvites)
  const fetchInvites = () => {
    dispatch(fetchOCInvites({ customerId }))
  }

  useEffect(() => {
    changeDocumentTitle(t('OC_organisation_invited_users'), ' - ', ':applicationName')
    fetchInvites()
  }, [])

  if (error) {
    return (
      <Paper style={{ maxWidth: 600 }}>
        <PaperContent>
          <Alert type="error" message={t('Dashboard_stock_some_error')} />
        </PaperContent>
      </Paper>
    )
  }
  const onCancel = () => {
    setDialog({})
    setInviteStatus({})
  }

  const revokeEmail = (sendToEmail: string) => {
    updateUser
      .OCResendInvite({ customerId, json: { sendToEmail } })
      .then(() => {
        setInviteStatus({
          text: `Der er nu sendt en ny invitationsmail til ${sendToEmail}`,
          variant: 'success',
        })
        enqueueSnackbar(`Invitationen er nu sendt til ${sendToEmail}`, {
          variant: 'success',
        })
      })
      .catch((err) => {
        setInviteStatus({ text: 'Der opstod desværre en fejl, prøv igen', variant: 'error' })
        err.response
          .json()
          .then((res) =>
            enqueueSnackbar(`Der opstod en fejl ${res.error.text}, prøv igen`, { variant: 'error' })
          )
      })
  }
  const deleteInvite = (uuid: string) => {
    deleteUser
      .OCInvite({ customerId, uuid })
      .then(() => {
        fetchInvites()
        setInviteStatus({ text: 'brugeren invitation er nu slettet', variant: 'success' })
        enqueueSnackbar('Invitationen er nu slettet', {
          variant: 'success',
        })
      })
      .catch((err) => {
        setInviteStatus({ text: 'Der opstod desværre en fejl, prøv igen', variant: 'error' })
        err.response
          .json()
          .then((res) =>
            enqueueSnackbar(`Der opstod en fejl ${res.error.text}, prøv igen`, { variant: 'error' })
          )
      })
  }

  const openInviteDialog = (invitedata: any) => {
    setDialog({
      isOpen: true,
      title: t('Invitation info'),
      closeButtonText: 'Luk',
      invitedata,
      onCancel,
    })
  }

  return (
    <>
      <UsersTable
        users={data?.invites || []}
        loading={loading}
        openInviteDialog={openInviteDialog}
      />
      {dialog.isOpen && (
        <CustomDialog
          isOpen={dialog.isOpen}
          onCancel={dialog.onCancel}
          title={dialog.title}
          closeButtonText={dialog.closeButtonText}
        >
          <>
            <UserProfile userData={dialog.invitedata} />
            {dialog.invitedata.status !== 'completed' &&
              (inviteStatus?.text ? (
                <Alert type={inviteStatus.variant} message={inviteStatus.text} />
              ) : (
                <ButtonGroup disableElevation size="small" style={{ marginLeft: '1rem' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => revokeEmail(dialog.invitedata.sendtTo)}
                    startIcon={<EmailIcon />}
                  >
                    {t('OC_user_profile_resend_invite')}
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => deleteInvite(dialog.invitedata.inviteId)}
                    startIcon={<DeleteIcon />}
                  >
                    {t('OC_user_profile_delete_invite')}
                  </Button>
                </ButtonGroup>
              ))}
          </>
        </CustomDialog>
      )}
    </>
  )
}

export default OCAdminInvite
