import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'

import {
  Avatar,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
} from '@material-ui/core'
import {
  Business as BusinessIcon,
  Email as EmailIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Cloud as CloudIcon,
  ContactMail as ContactMailIcon,
} from '@material-ui/icons'
import { IMG_MICROSOFT_LOGO } from 'constants/cdn'

const useStyles = makeStyles((theme: Theme) => ({
  microsoftButton: {
    marginTop: '1rem',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}))

const UserProfile = ({ userData, handleAuthLogin }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { status, customerName, sendtBy, azureTenantName, sendtTo } = userData || {}

  return (
    <List style={{ marginBottom: '2rem' }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <CheckCircleOutlineIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={t('OC_user_profile_status')} secondary={status} />
      </ListItem>
      {customerName && (
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <BusinessIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t('OC_user_profile_customerName')} secondary={customerName} />
        </ListItem>
      )}
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <EmailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={t('OC_user_profile_sendBy')} secondary={sendtBy} />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ContactMailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={t('OC_user_profile_sendtTo')} secondary={sendtTo} />
      </ListItem>
      {azureTenantName && (
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <CloudIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('OC_user_profile_azureTenantName')}
            secondary={azureTenantName}
          />
        </ListItem>
      )}
      {handleAuthLogin && (
        <ListItem button onClick={handleAuthLogin} className={classes.microsoftButton}>
          <ListItemIcon>
            <img src={IMG_MICROSOFT_LOGO} width="20px" height="20px" alt="Log in with Microsoft" />
          </ListItemIcon>
          <ListItemText primary={t('Login_logon_microsoft')} />
        </ListItem>
      )}
    </List>
  )
}

export default UserProfile
