import { getValidation } from 'services/sweb/validation'

export const FETCH_FAILURE = 'validateMobileNumber/FETCH_FAILURE'
export const FETCH_BEGIN = 'validateMobileNumber/FETCH_BEGIN'
export const FETCH_SUCCESS = 'validateMobileNumber/FETCH_SUCCESS'

export const fetchValidateMobileNumberFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchValidateMobileNumberBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchValidateMobileNumberSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchValidateMobileNumber = ({ customerId, phoneNumber }) => {
  return async (dispatch) => {
    dispatch(fetchValidateMobileNumberBegin())
    try {
      const response = await getValidation.validatePhoneNumber({
        customerId,
        phoneNumber,
      })
      dispatch(fetchValidateMobileNumberSuccess(response.data))
    } catch (err) {
      dispatch(fetchValidateMobileNumberFailure(err.response))
    }
  }
}
