import React, { useState, useEffect } from 'react'
import { getValidation } from 'services/sweb/validation'
import { SkySelect, SkyTextField, DrawerMessage } from 'components'
import DrawerButtons from 'views/Drawer/components/DrawerButtons'
import { useTranslation } from 'react-i18next'
import callPatternDescriptions from '../../callPatternDescriptions'

const CreateForm = ({ loading, backLink, createHuntgroup, numbers, callPatterns, customerId }) => {
  const { t } = useTranslation()
  const [form, setForm] = useState({
    group_name: '',
    group_number: null,
    rule: null,
    abbreviated_dialing: '',
    extensionValid: false,
  })
  const [checkingExtension, setCheckingExtension] = useState(false)

  useEffect(() => {
    if (form.abbreviated_dialing.length === 4 && form.group_number && !form.extensionValid) {
      setCheckingExtension(true)
      getValidation
        .validateHuntgroupExtension({
          customerId,
          number: form.group_number.label,
          extension: form.abbreviated_dialing,
        })
        .then(() => {
          setForm({ ...form, extensionValid: true })
          setCheckingExtension(false)
        })
        .catch(() => {
          setCheckingExtension(false)
        })
    }
  }, [form])

  const showExtensionError = Boolean(
    !checkingExtension &&
      form.abbreviated_dialing.length === 4 &&
      !form.extensionValid &&
      form.group_number
  )

  const formIsValid = Boolean(
    form.group_name.length > 2 && form.group_number && form.rule && form.extensionValid
  )

  const handleForm = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleSetNumber = (event, val) => {
    setForm({ ...form, group_number: val })
  }

  const handleSetRule = (event, val) => {
    setForm({ ...form, rule: val })
  }

  const handleCreateHuntgroup = () => {
    const json = {
      group_name: form.group_name,
      group_number: form.group_number.label,
      rule: form.rule.value,
      abbreviated_dialing: form.abbreviated_dialing,
    }
    createHuntgroup(json)
  }

  const numberOptions = numbers.map((x) => {
    return {
      label: x.number,
      value: x,
    }
  })

  const callPatternOptions = callPatterns.map((x) => {
    return {
      label: t(callPatternDescriptions[x.rule].name),
      value: x.rule,
    }
  })

  return (
    <>
      <DrawerMessage>{t('Organization_huntgroups_drawer_description')}</DrawerMessage>
      <DrawerMessage component="span">
        <ul>
          {callPatterns.map((x) => {
            return (
              <li key={x.rule}>
                <strong>{t(callPatternDescriptions[x.rule].name)}: </strong>
                {t(callPatternDescriptions[x.rule].description)}
              </li>
            )
          })}
        </ul>
      </DrawerMessage>
      <DrawerMessage extraGutterBottom>
        {t('Organization_huntgroups_drawer_description_two')}
      </DrawerMessage>
      <SkyTextField
        label={t('Organization_huntgroups_choose_name')}
        name="group_name"
        value={form.group_name}
        onChange={handleForm}
        disabled={loading}
        required
        error={form.group_name.length > 15}
        helperText={form.group_name.length > 15 ? t('Huntgroup_name_length_warning') : ''}
      />
      <SkySelect
        label={t('Organization_huntgroups_choose_number')}
        value={form.group_number}
        onChange={handleSetNumber}
        disabled={loading}
        options={numberOptions}
        required
        disableClearable
      />
      <SkySelect
        label={t('Organization_huntgroups_choose_pattern')}
        value={form.rule}
        onChange={handleSetRule}
        disabled={loading}
        options={callPatternOptions}
        required
        disableClearable
        getOptionSelected={(option, value) => option.value === value.value}
      />
      <SkyTextField
        label={t('Organization_huntgroups_choose_abbr')}
        name="abbreviated_dialing"
        value={form.abbreviated_dialing}
        onChange={handleForm}
        disabled={loading}
        required
        loading={checkingExtension}
        error={showExtensionError}
        helperText={
          showExtensionError
            ? t('Organization_huntgroups_choose_abbr_description')
            : t('Organization_huntgroups_choose_abbr_in_use')
        }
      />
      <DrawerButtons
        backLink={backLink}
        disabled={!formIsValid}
        onSubmit={handleCreateHuntgroup}
        submitText={t('Organization_huntgroups_create_label')}
        loading={loading}
      />
    </>
  )
}

export default CreateForm
