import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
// import { Menu, MenuItem, Divider } from '@material-ui/core'
import { AvatarListItem /* , NestedMenuItem, UtilityInput */ } from '@skytdc/mui/components'
// import { ChevronRight as ChevronRightIcon, AddCircleOutline as AddIcon } from '@material-ui/icons'
// import matchSorter from 'match-sorter'

const useStyles = makeStyles((theme: Theme) => ({
  chevronDown: {
    color: theme.palette.text.secondary,
    verticalAlign: 'bottom',
  },
  divider: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.6),
  },
  menuList: {
    minWidth: 232,
  },
  searchInput: {
    margin: theme.spacing(2),
  },
  orgContainer: {
    maxHeight: 250,
    overflow: 'auto',
  },
  container: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  slimOrgAvatar: {
    paddingLeft: 9,
  },
}))

interface Props {
  customerName: string
  customerId: string
  changeRoute: (route: string) => void
  canAccessOverview: boolean
  unionName: string
  slim: boolean
}

const OrganizationSelect = ({
  customerName,
  customerId,
  changeRoute,
  canAccessOverview,
  unionName,
  slim,
}: Props) => {
  const classes = useStyles()
  /* const [orgSearch, setOrgSearch] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const searchedOrganisations = matchSorter(availableCustomers, orgSearch, {
    keys: ['name'],
  }) */

  return (
    <div className={classes.container}>
      <AvatarListItem
        primaryText={slim ? '' : customerName}
        secondaryText={slim ? '' : `${customerId} ${unionName}`}
        hasChevron={!slim && canAccessOverview}
        avatarChar={customerName.charAt(0).toUpperCase()}
        onClick={canAccessOverview ? () => changeRoute('/') : undefined}
        className={slim ? classes.slimOrgAvatar : ''}
        tooltip={slim ? customerName : ''}
      />
      {/* <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transitionDuration={200}
        classes={{ list: classes.menuList }}
      >
        <li>
          <AvatarListItem primaryText={name} secondaryText={role} dense hasChevron={false} />
        </li>
        <MenuItem>Indstillinger</MenuItem>
        <MenuItem>Team-medlemmer</MenuItem>
        <MenuItem>Betaling</MenuItem>
        <MenuItem>Hjælp og feedback</MenuItem>
        <div>
          <NestedMenuItem
            label={
              <>
                Skift organisation
                <ChevronRightIcon className={classes.chevronDown} />
              </>
            }
            parentMenuOpen={Boolean(anchorEl)}
          >
            <li>
              <UtilityInput
                onChange={setOrgSearch}
                value={orgSearch}
                placeholder="Søg..."
                className={classes.searchInput}
              />
            </li>
            <li className={classes.orgContainer}>
              {searchedOrganisations.map((x) => {
                return (
                  <AvatarListItem
                    key={x.customerId}
                    primaryText={x.name}
                    secondaryText={x.role}
                    dense
                    hasChevron={false}
                    // eslint-disable-next-line
                    onClick={() => alert('Change customer')}
                  />
                )
              })}
            </li>
            <Divider className={classes.divider} />
            <MenuItem>
              <AddIcon className={classes.icon} />
              Opret ny
            </MenuItem>
          </NestedMenuItem>
        </div>
            </Menu> */}
    </div>
  )
}

export default OrganizationSelect
