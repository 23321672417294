import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, Tooltip, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  removePadding: {
    padding: '0px',
  },
  heading: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 700,
  },
  root: {
    textAlign: 'right',
    marginTop: '3px',
  },
}))

const roundToTwoDecimals = (number) => Math.round(number * 100) / 100

const createPercentageString = (percentage) => {
  if (percentage < 0) return `-${Math.abs(roundToTwoDecimals(percentage))}%`
  if (percentage > 0) return `+${roundToTwoDecimals(percentage)}%`
  if (percentage === 0) return `${roundToTwoDecimals(percentage)}%`
  return null
}

const SecondaryTextWithTooltip = ({ percentage }) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      title={
        <Typography color="inherit" variant="inherit" display="block">
          {t('Invoice_comparison', { diff: percentage })}
        </Typography>
      }
    >
      <Typography component="span" variant="body2" color="textSecondary">
        {percentage}
      </Typography>
    </Tooltip>
  )
}

const TotalNumberCell = ({ current, percentageDifference }) => {
  const classes = useStyles()
  const showPercentage =
    percentageDifference !== 0 && Boolean(percentageDifference > 0 || percentageDifference < 0)

  return (
    <List className={classes.removePadding}>
      <ListItem className={classes.removePadding}>
        <ListItemText
          classes={{
            primary: classes.heading,
            root: classes.root,
          }}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
          primary={current.toLocaleString('da-DK', { minimumFractionDigits: 2 })}
          secondary={
            showPercentage && (
              <SecondaryTextWithTooltip percentage={createPercentageString(percentageDifference)} />
            )
          }
        />
      </ListItem>
    </List>
  )
}

export default TotalNumberCell
