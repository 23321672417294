import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, LinearProgress, Typography, Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  progressBar: {
    height: 6,
  },
  bar: {
    backgroundColor: theme.palette.primary.main,
  },
  box: {
    paddingLeft: theme.spacing(),
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
}))

const CapacityCell = ({ amount, totalAmount }) => {
  const classes = useStyles()
  const progressPercentage =
    amount > totalAmount ? 100 : ((amount || 0) / (totalAmount || 100)) * 100

  return (
    <Tooltip
      title={`${amount || 0} /
        ${totalAmount} (${Math.round(progressPercentage)}%)`}
      placement="left"
      arrow
    >
      <Box display="flex" alignItems="center" className={classes.box}>
        <Box width="100%" maxWidth={200} mr={1}>
          <LinearProgress
            variant="determinate"
            value={progressPercentage}
            color="secondary"
            className={classes.progressBar}
            classes={{
              barColorSecondary: classes.bar,
            }}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {amount}/{totalAmount}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  )
}

export default CapacityCell
