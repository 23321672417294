import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableBody, TableRow, TableCell, Table } from '@material-ui/core'
import {
  TableHead,
  TablePagination,
  LoadingTableRow,
  Paper,
  PaperContent,
  PaperToolbar,
  UtilityInput,
} from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'
import { stableSort, getSorting } from 'utils/tableSorting'
import matchSorter from 'match-sorter'
import NumberTableRow from '../NumberTableRow'
import SortingChips from '../SortingChips'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: theme.spacing(3),
  },
  tableContainer: {
    overflow: 'auto',
  },
  chipsContainer: {
    marginTop: '0px',
    paddingTop: '0px',
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}))

const NumbersTable = ({ numbers, loading }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = useState('status')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchQuery, setSearchQuery] = useState('')
  const [activeFilters, setActiveFilters] = useState({})

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const headCells = [
    {
      id: 'numberWithCountryCode',
      label: t('Organization_huntgroups_td_2'),
      numeric: false,
      disablePadding: false,
      paddingLeft: false,
      sortable: true,
    },
    {
      id: 'provider',
      label: t('Provider'),
      numeric: false,
      disablePadding: false,
      paddingLeft: true,
      sortable: true,
    },
    {
      id: 'productVersion',
      label: 'Version',
      numeric: false,
      disablePadding: false,
      paddingLeft: true,
      sortable: true,
    },
    {
      id: 'comments',
      label: t('Wizard_9sth'),
      numeric: false,
      disablePadding: false,
      paddingLeft: true,
      sortable: true,
    },
    {
      id: 'edit',
      label: '',
      numeric: true,
      disablePadding: false,
      paddingLeft: false,
      sortable: false,
    },
  ]

  const totalCount = numbers.length || 0

  const handleSetRowsPerPage = (newRows) => setRowsPerPage(newRows)

  const handleSetPage = (newPage) => setPage(newPage)

  const searchedNumbers = matchSorter(numbers, searchQuery, {
    keys: ['numberWithCountryCode', 'provider', 'productVersion', 'comments', 'id'],
  })

  const filteredNumbers = searchedNumbers.filter((user) =>
    Object.keys(activeFilters).every((key) => user[key] === activeFilters[key])
  )

  const handleSearch = (query) => {
    setPage(0)
    setSearchQuery(query)
  }

  return (
    <>
      <Paper>
        <PaperToolbar className={classes.toolbar}>
          <UtilityInput
            onChange={handleSearch}
            value={searchQuery}
            placeholder={t('SearchIntNumbers')}
          />
        </PaperToolbar>
        <PaperContent className={classes.chipsContainer}>
          <SortingChips
            setPage={setPage}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            numbers={filteredNumbers}
          />
        </PaperContent>
      </Paper>
      <div style={{ overflow: 'auto', marginTop: 16, width: '100%' }}>
        <Table aria-labelledby="international numbers table" size="small">
          <TableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            utilityIcon={null}
          />
          <TableBody>
            {loading ? (
              <LoadingTableRow />
            ) : (
              filteredNumbers.length > 0 &&
              stableSort(filteredNumbers, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((x) => {
                  return <NumberTableRow key={x.id} {...x} />
                })
            )}
            {!loading && filteredNumbers.length === 0 && (
              <TableRow>
                <TableCell colSpan={8}>{t('Organization_unassignedsimcards_noresult')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPage={rowsPerPage}
        page={page}
        totalCount={totalCount}
        setPage={handleSetPage}
        setRowsPerPage={handleSetRowsPerPage}
        showingFromTo={t('ShowingFromTo', {
          from: page * rowsPerPage + 1,
          to:
            page * rowsPerPage + rowsPerPage > totalCount
              ? totalCount
              : page * rowsPerPage + rowsPerPage,
          totalCount,
        })}
        perPage={t('PerPage')}
      />
    </>
  )
}

export default NumbersTable
