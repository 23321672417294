import React from 'react'
import { Link } from '@material-ui/core'
import { NavLinkWrapper } from 'components'

const ConditionalRouterLink = ({ to, children }) => {
  if (!to) return children
  return (
    <Link component={NavLinkWrapper} to={to}>
      {children}
    </Link>
  )
}

export default ConditionalRouterLink
