import React, { memo } from 'react'
import { useSnackbar } from 'notistack'
import {
  SimCardOutlined as SimcardIcon,
  Code as CodeIcon,
  BusinessOutlined as OperatorIcon,
  SmartphoneOutlined as MobileIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Button, DialogActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  LoadingButtonWrapper,
  PaperFeedbackMessage,
  DrawerListItem,
  DrawerSection,
} from 'components'
import useCopyToClipboard from 'theme/hooks/useCopyToClipboard'
import Esim from '../../../EndUser/components/Mobile/Simcards/Esim'

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: theme.spacing(3),
    paddingBottom: '0px',
  },
  error: {
    color: theme.palette.error.main,
  },
  msg: {
    marginTop: 0,
    padding: theme.spacing(2),
  },
}))

const Simcard = ({
  simcard,
  title,
  withButtons,
  goToOrderForm,
  changeRoute,
  handleOpenDialog,
  loading,
  mobileNumber,
  hideOrderButton,
  esimActivationCode,
  newEsimOrder,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { copyFn, CopyPlaceholder } = useCopyToClipboard()

  const copyToClipboard = ({ value = '' }) => {
    copyFn({ value })
    enqueueSnackbar(t('Drawer_mypage_copy', { value }))
  }

  return (
    <>
      {(newEsimOrder?.identifier || esimActivationCode) && (
        <>
          <DrawerSection title={t('EndUserEsimDrawerTitle')} />
          <Esim qrCodeValue={esimActivationCode} newEsimOrder={newEsimOrder} />
        </>
      )}
      <CopyPlaceholder />
      <DrawerSection title={title} />
      <DrawerListItem
        icon={<MobileIcon />}
        primaryText={t('Organization_orders_drawer_mobile')}
        secondaryText={mobileNumber}
      />
      <DrawerListItem
        icon={<SimcardIcon />}
        primaryText={t('Organization_unassignedsimcards_icc')}
        secondaryText={simcard.icc}
        secondaryActionButton={t('Drawer_sim_order_copy')}
        onSecondaryActionButton={() => copyToClipboard({ value: simcard.icc })}
      />
      <DrawerListItem
        icon={<CodeIcon />}
        primaryText={t('Organization_unassignedsimcards_pin')}
        secondaryText={simcard.pin_code}
        secondaryActionButton={t('Drawer_sim_order_copy')}
        onSecondaryActionButton={() => copyToClipboard({ value: simcard.pin_code })}
      />
      <DrawerListItem
        icon={<CodeIcon />}
        primaryText={t('Organization_unassignedsimcards_puk')}
        secondaryText={simcard.puk_code}
        secondaryActionButton={t('Drawer_sim_order_copy')}
        onSecondaryActionButton={() => copyToClipboard({ value: simcard.puk_code })}
      />
      {simcard.simtype.name !== 'Universal' && (
        <DrawerListItem
          icon={<SimcardIcon />}
          primaryText="Type"
          secondaryText={simcard.simtype.name}
        />
      )}

      {simcard.sim_operator.operator_name !== 'TDC' && (
        <DrawerListItem
          icon={<OperatorIcon />}
          primaryText={t('Operator')}
          secondaryText={simcard.puk_code}
        />
      )}
      {simcard.suspended && (
        <PaperFeedbackMessage type="error" className={classes.msg} message={t('Isblocked')} />
      )}

      {withButtons && (
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" onClick={() => changeRoute()} disabled={loading}>
            {t('Drawer_buttons_back')}
          </Button>
          {!simcard.suspended && (
            <LoadingButtonWrapper loading={loading}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleOpenDialog}
                disabled={loading}
              >
                {t('Drawer_simcard_block_labl')}
              </Button>
            </LoadingButtonWrapper>
          )}
          {!hideOrderButton && (
            <Button color="primary" variant="outlined" onClick={goToOrderForm} disabled={loading}>
              {t('OrderNew')}
            </Button>
          )}
        </DialogActions>
      )}
    </>
  )
}

export default memo(Simcard)
