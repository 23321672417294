import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'oCNumbers/FETCH_FAILURE'
export const FETCH_BEGIN = 'oCNumbers/FETCH_BEGIN'
export const FETCH_SUCCESS = 'oCNumbers/FETCH_SUCCESS'

export const fetchOCNumbersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchOCNumbersBegin = ({ customerId, e164, pagesize, pagenumber }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
    e164,
    pagesize,
    pagenumber,
  }
}

export const fetchOCNumbersSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchOCNumbers = ({ customerId, e164, pagesize, pagenumber }) => {
  return async (dispatch) => {
    dispatch(fetchOCNumbersBegin({ customerId, e164, pagesize, pagenumber }))
    try {
      const response = await getCustomer.OCNumbers({
        customerId,
        e164,
        pagesize,
        pagenumber,
      })
      dispatch(fetchOCNumbersSuccess(response.data))
    } catch (err) {
      dispatch(fetchOCNumbersFailure(err.response))
    }
  }
}
