import React, { /* createRef, */ ReactElement } from 'react'
import { SnackbarProvider } from 'notistack'
import { makeStyles, Theme } from '@material-ui/core/styles'
// import { IconButton } from '@material-ui/core'
import {
  // Close as CloseIcon,
  CheckCircleOutline as SuccessIcon,
  InfoOutlined as InfoIcon,
  ErrorOutline as ErrorIcon,
  ReportProblemOutlined as WarningIcon,
} from '@material-ui/icons'

const bg = '#242837'

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    background: bg,
  },
  successIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(),
    marginLeft: -theme.spacing(),
  },
  error: {
    backgroundColor: bg,
  },
  errorIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(),
    marginLeft: -theme.spacing(),
  },
  warning: {
    backgroundColor: bg,
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing(),
    marginLeft: -theme.spacing(),
  },
  info: {
    backgroundColor: bg,
  },
  infoIcon: {
    color: theme.palette.info.main,
    marginRight: theme.spacing(),
    marginLeft: -theme.spacing(),
  },
}))

interface Props {
  children: ReactElement
}

const ThemedSnackbarProvider = ({ children }: Props) => {
  const classes = useStyles()

  /* const notistackRef = createRef()

  const onClickDismiss = (key: string | number) => () => {
    // eslint-disable-next-line
    const currentSnackbar = notistackRef.current as any
    currentSnackbar.closeSnackbar(key)
  } */

  return (
    <SnackbarProvider
      maxSnack={1}
      disableWindowBlurListener
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      dense
      autoHideDuration={5000}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      /* ref={notistackRef}
      action={(key) => (
        <IconButton size="small" onClick={onClickDismiss(key)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )} */
      iconVariant={{
        success: <SuccessIcon className={classes.successIcon} />,
        error: <ErrorIcon className={classes.errorIcon} />,
        warning: <WarningIcon className={classes.warningIcon} />,
        info: <InfoIcon className={classes.infoIcon} />,
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

export default ThemedSnackbarProvider
