import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { createCustomer } from 'services/sweb/customer'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import changeDocumentTitle from 'routes/utils/changeDocumentTitle'

import {
  SimCardOutlined as SimcardIcon,
  PublicOutlined as IntNumbersIcon,
  // CallSplitOutlined as CallflowIcon,
  DialerSipOutlined as SipIcon,
  // DesktopWindowsOutlined as ContactCenterIcon,
  // RecordVoiceOverOutlined as RecordingIcon,
  // DataUsageOutlined as UsageIcon,
  // CloudOutlined as CloudIcon,
  WidgetsOutlined as IOTIcon,
  PhonelinkOutlined as OneNumberIcon,
  GetAppOutlined as SkyAppIcon,
  PermPhoneMsgOutlined as CallIcon,
  GroupOutlined as CallgrpIcon,
} from '@material-ui/icons'
import { LoadingSpinner, PageTitle } from '@skytdc/mui/components'
import Layout from '../Layout'
import AddonCard from './components/AddonCard'
import SendTicketModal from './components/SendTicketModal'

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '60px',
    height: '60px',
    fontSize: '60px',
  },
  header: {
    marginTop: theme.spacing(4),
  },
}))

const Addons = ({ title, history }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const customer = useSelector((state) => state.customer)
  const me = useSelector((state) => state.me)
  const broadworks = customer?.data?.customer_properties?.broadworks

  const [loading, setLoading] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    title: null,
    description: null,
  })

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
  }, [])

  const goToPage = (page: string) => {
    history.push(`/${customer.data.customer_id}/${page}`)
  }
  const getAddons = (customerprops) => {
    return [
      {
        title: t('Addons_unassignedSimcards_title'),
        description: t('Addons_unassignedSimcards_description'),
        active: Boolean(customerprops.customer_properties.display_reserved_sim_cards),
        price: 0,
        icon: <SimcardIcon className={classes.icon} />,
        canBeActivated: Boolean(customerprops.customer_properties.display_reserved_sim_cards),
        administrateLink: 'organisation/skuffesimkort',
      },
      {
        title: t('Addons_huntgroups_title'),
        description: t('Addons_huntgroups_description'),
        active: Boolean(customerprops.mobile_huntgroups_teams),
        price: 0,
        icon: <CallgrpIcon className={classes.icon} />,
        canBeActivated: Boolean(customerprops.mobile_huntgroups_teams),
        administrateLink: 'organisation/mobile-ringegrupper',
      },
      {
        title: t('Addons_int_numbers_title'),
        description: t('Addons_int_numbers_description'),
        active: Boolean(customerprops.internation_numbers),
        price: 0,
        icon: <IntNumbersIcon className={classes.icon} />,
        canBeActivated: Boolean(customerprops.internation_numbers),
      },
      {
        title: t('Addons_onenumber_title'),
        description: t('Addons_onenumber_description'),
        active: Boolean(customerprops.customer_properties.enable_onenumber_classic),
        price: 0,
        icon: <OneNumberIcon className={classes.icon} />,
        canBeActivated: Boolean(customerprops.customer_properties.enable_onenumber_classic),
      },
      {
        title: t('Addons_skyapp_title'),
        description: t('Addons_skyapp_description'),
        active: true,
        price: 0,
        icon: <SkyAppIcon className={classes.icon} />,
        canBeActivated: true,
      },
      /* {
        title: 'Call flows (IVR og køstyring)',
        description:
          'Styrk den gode kundeoplevelse fra start til slut med en professionel og imødekommende velkomst. Få større kundetilfredshed og færre tabte kald med et call flow.',
        active: false,
        price: 499,
        icon: <CallflowIcon className={classes.icon} />,
        canBeActivated: true,
      },
      {
        title: 'Kontaktcenter',
        description:
          'Få effektiv styring af alle jeres kommunikationskanaler. CC Touchpoint er en cloudbaseret call center-løsning, som er super fed.',
        active: false,
        price: 1999,
        icon: <ContactCenterIcon className={classes.icon} />,
        canBeActivated: true,
      },
      {
        title: 'Recording',
        description:
          'Opnå tryghed og klarhed ved at optage samtaler, så intet går tabt. Recording kan tilvælges og aktiveres på enkelte medarbejdere.',
        active: Boolean(customerprops.recording),
        price: 199,
        icon: <RecordingIcon className={classes.icon} />,
        canBeActivated: true,
      },
      {
        title: 'Rapportering - forbrug, abonnementsanalyse m.m.',
        description:
          'Bruger jeres medarbejdere abonnementerne til fulde? Lad os hjælpe jer med at spare penge ved hjælp af månedlige forbrugs- og abonnementsanalyser.',
        active: false,
        price: 899,
        icon: <UsageIcon className={classes.icon} />,
        canBeActivated: true,
      },
      {
        title: 'Rapportering - cloud adoption',
        description:
          'Benytter jeres medarbejdere Office 365 optimalt? Få overblik over hvor der skal gribes ind med en detaljeret rapport over Office 365 usage.',
        active: false,
        price: 899,
        icon: <CloudIcon className={classes.icon} />,
        canBeActivated: true,
      }, */
      {
        title: t('Addons_siptrunks_title'),
        description: t('Addons_siptrunks_description'),
        active: true,
        price: 0,
        icon: <SipIcon className={classes.icon} />,
        canBeActivated: true,
      },
      {
        title: t('Addons_iot_title'),
        description: t('Addons_iot_description'),
        active: false,
        price: 0,
        icon: <IOTIcon className={classes.icon} />,
        canBeActivated: false,
      },
    ]
  }

  const services = [
    {
      title: t('Addons_cloudadoption_title'),
      description: t('Addons_cloudadoption_description'),
      active: false,
      price: 0,
      icon: <CallIcon className={classes.icon} />,
      canBeActivated: false,
      isService: true,
    },
    {
      title: t('Addons_consultant_title'),
      description: t('Addons_consultant_description'),
      active: false,
      price: 0,
      icon: <CallIcon className={classes.icon} />,
      canBeActivated: false,
      isService: true,
    },
    {
      title: t('Addons_consultant_voucher_title'),
      description: t('Addons_consultant_voucher_description'),
      active: false,
      price: 0,
      icon: <CallIcon className={classes.icon} />,
      canBeActivated: false,
      isService: true,
    },
  ]

  const openTicketModal = (newDialog) => {
    setDialog(newDialog)
  }
  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }
  const sendTicket = (json) => {
    setLoading(true)
    createCustomer
      .callMeTicket({ customerId: customer.data.customer_id, json })
      .then(() => {
        enqueueSnackbar(t('Addons_callme_response'), {
          variant: 'success',
        })
        setLoading(false)
        handleCloseDialog()
      })
      .catch((err) => {
        err.response.json().then((res) =>
          enqueueSnackbar(t('Addons_callme_response', { message: res.error.text }), {
            variant: 'error',
          })
        )
        setLoading(false)
      })
  }

  return (
    <Layout>
      {customer.data && !customer.loading ? (
        <>
          <PageTitle title="Addons" />
          <Grid container spacing={4}>
            {getAddons(customer.data).map((addon) => {
              if ((broadworks && addon.title === t('Addons_siptrunks_title')) || !broadworks) {
                return (
                  <Grid key={addon.title} item lg={3} sm={6} xl={3} xs={12}>
                    <AddonCard
                      title={addon.title}
                      description={addon.description}
                      active={addon.active}
                      price={addon.price}
                      icon={addon.icon}
                      canBeActivated={addon.canBeActivated}
                      openTicketModal={openTicketModal}
                      page={addon.administrateLink}
                      goToPage={goToPage}
                    />
                  </Grid>
                )
              }
              return <></>
            })}
          </Grid>
          <PageTitle className={classes.header} title="Services" />
          <Grid container spacing={4}>
            {services.map((addon) => {
              return (
                <Grid key={addon.title} item lg={3} sm={6} xl={3} xs={12}>
                  <AddonCard
                    title={addon.title}
                    description={addon.description}
                    active={addon.active}
                    price={addon.price}
                    icon={addon.icon}
                    canBeActivated={addon.canBeActivated}
                    openTicketModal={openTicketModal}
                    isService={addon.isService}
                  />
                </Grid>
              )
            })}
          </Grid>
          <SendTicketModal
            isOpen={dialog.open}
            loading={loading}
            sendTicket={sendTicket}
            title={dialog.title}
            description={dialog.description}
            handleCloseDialog={handleCloseDialog}
            email={me.data.user.email || ''}
          />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </Layout>
  )
}

export default Addons
