export const INTERNATIONAL_NUMBERS_V2_FAILURE = 'INTERNATIONAL_NUMBERS_V2_FAILURE'
export const INTERNATIONAL_NUMBERS_V2_BEGIN = 'INTERNATIONAL_NUMBERS_V2_BEGIN'
export const INTERNATIONAL_NUMBERS_V2_SUCCESS = 'INTERNATIONAL_NUMBERS_V2_SUCCESS'

export interface ParamsProps {
  pageSize?: number
  pageNumber?: number
  order?: string
  orderby?: string
  customerId: string
}

interface FetchIntNumbersBegin extends ParamsProps {
  type: typeof INTERNATIONAL_NUMBERS_V2_BEGIN
}

interface FetchIntNumbersFailure {
  type: typeof INTERNATIONAL_NUMBERS_V2_FAILURE
  // eslint-disable-next-line
  error: any
}

export interface FetchIntNumbersSuccess {
  type: typeof INTERNATIONAL_NUMBERS_V2_SUCCESS
  // eslint-disable-next-line
  data: any
}

export type IntNumbersActions =
  | FetchIntNumbersBegin
  | FetchIntNumbersFailure
  | FetchIntNumbersSuccess

export interface IntNumbersState extends ParamsProps {
  loading: boolean
  // eslint-disable-next-line
  data?: any
  // eslint-disable-next-line
  error?: Error
}
