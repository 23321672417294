import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    marginRight: 'auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    color: theme.palette.text.secondary,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    fontSize: theme.typography.body1.fontSize,
    padding: '10px 8px 10px 56px',
    width: '100%',
  },
}))

const CustomSearchBar = ({ options, handleSearch, searchText }) => {
  const classes = useStyles()

  const handleChange = (event) => {
    handleSearch(event.target.value)
  }

  useEffect(() => {
    if (searchText) {
      localStorage.setItem('searchWord', searchText)
    }
    return () => {
      localStorage.removeItem('searchWord')
    }
  }, [searchText])

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        fullWidth
        value={searchText || ''}
        autoFocus
        placeholder={options.searchPlaceholder}
        onChange={handleChange}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': options.searchPlaceholder }}
      />
    </div>
  )
}

export default CustomSearchBar
