import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceLandlineSpecifications/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceLandlineSpecifications/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceLandlineSpecifications/FETCH_SUCCESS'

export const fetchInvoiceLandlineSpecificationsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceLandlineSpecificationsBegin = ({ invoiceId, locationtype }) => {
  return {
    type: FETCH_BEGIN,
    invoiceId,
    locationtype,
  }
}

export const fetchInvoiceLandlineSpecificationsSuccess = ({
  invoiceId,
  locationtype,
  response,
}) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    invoiceId,
    locationtype,
  }
}

export const fetchInvoiceLandlineSpecifications = ({
  customerId,
  invoiceId,
  startdate,
  enddate,
  locationtype,
}) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceLandlineSpecificationsBegin({ invoiceId, locationtype }))
    try {
      const response = await getInvoice.categorySpecifications({
        customerId,
        invoiceId,
        locationtype,
        startdate,
        enddate,
      })
      dispatch(fetchInvoiceLandlineSpecificationsSuccess({ invoiceId, locationtype, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceLandlineSpecificationsFailure(err.response))
    }
  }
}
