import { subscription } from 'services/sweb/me'

export const FETCH_FAILURE = 'endUserSubscription/FETCH_FAILURE'
export const FETCH_BEGIN = 'endUserSubscription/FETCH_BEGIN'
export const FETCH_SUCCESS = 'endUserSubscription/FETCH_SUCCESS'

export const fetchEndUserSubscriptionFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchEndUserSubscriptionBegin = ({ abbId }) => {
  return {
    type: FETCH_BEGIN,
    abbId,
  }
}

export const fetchEndUserSubscriptionSuccess = ({ data, abbId }) => {
  return {
    type: FETCH_SUCCESS,
    data,
    abbId,
  }
}

export const fetchEndUserSubscription = ({ abbId }) => {
  return async (dispatch) => {
    dispatch(fetchEndUserSubscriptionBegin({ abbId }))
    try {
      const response = await subscription({
        abbId,
      })
      dispatch(fetchEndUserSubscriptionSuccess({ abbId, data: response.data }))
    } catch (err) {
      dispatch(fetchEndUserSubscriptionFailure(err.response))
    }
  }
}
