import { getCustomer } from 'services/sweb/customer'
import { phoneNumberFormat } from 'utils/helpers'

export const FETCH_FAILURE = 'mobileNumbersCombined/FETCH_FAILURE'
export const FETCH_BEGIN = 'mobileNumbersCombined/FETCH_BEGIN'
export const FETCH_SUCCESS = 'mobileNumbersCombined/FETCH_SUCCESS'

export const fetchMobileNumbersCombinedFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchMobileNumbersCombinedBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchMobileNumbersCombinedSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchMobileNumbersCombined = ({ customerId, disableImport = false }) => {
  return async (dispatch) => {
    // TODO move this
    dispatch(fetchMobileNumbersCombinedBegin({ customerId }))
    try {
      const atRestResponse = await getCustomer.availableAtRestMobileNumbers({
        customerId,
      })
      const releaseResponse = await getCustomer.availableReleaseMobileNumbers({
        customerId,
      })
      const moveExistingOption = disableImport
        ? []
        : [
            {
              group: '',
              label: 'MoveNumber',
              value: 'port',
            },
          ]
      const atRestOptions =
        (atRestResponse.data &&
          atRestResponse.data.length > 0 &&
          atRestResponse.data.map((num) => ({
            group: 'RestingNumber',
            label: phoneNumberFormat(num.number),
            value: num,
          }))) ||
        []
      const releaseOptions =
        (releaseResponse.data &&
          releaseResponse.data.length > 0 &&
          releaseResponse.data.map((num) => ({
            group: 'NewNumber',
            label: phoneNumberFormat(num.number),
            value: num,
          }))) ||
        []
      const formattedResponse = [...moveExistingOption, ...atRestOptions, ...releaseOptions]
      dispatch(fetchMobileNumbersCombinedSuccess(formattedResponse))
    } catch (err) {
      dispatch(fetchMobileNumbersCombinedFailure(err.response))
    }
  }
}
