import { getUser } from 'services/sweb/user'

export const FETCH_FAILURE = 'zendeskTickets/FETCH_FAILURE'
export const FETCH_BEGIN = 'zendeskTickets/FETCH_BEGIN'
export const FETCH_SUCCESS = 'zendeskTickets/FETCH_SUCCESS'

export const fetchZendeskTicketsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchZendeskTicketsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchZendeskTicketsSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchZendeskTickets = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchZendeskTicketsBegin({ customerId }))
    try {
      const response = await getUser.zendeskTickets()
      dispatch(fetchZendeskTicketsSuccess(response.data))
    } catch (err) {
      dispatch(fetchZendeskTicketsFailure(err.response))
    }
  }
}
