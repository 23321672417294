import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { ChevronRightRounded as ChevronRightIcon } from '@material-ui/icons'
import { TableCellListItem } from 'components'
import { phoneNumberFormat } from 'utils/helpers'
import StatusCell from './components/StatusCell'

const OrdersTableRow = ({
  orderInfo,
  resultText,
  resultId,
  inserted,
  openOrder,
  orderedby,
  orderId,
}) => {
  const {
    nationalNumber,
    productName,
    country: { prefix },
  } = orderInfo
  const handleOpenOrder = () => openOrder(orderId)
  return (
    <TableRow hover onClick={handleOpenOrder}>
      <TableCell padding="none">
        <StatusCell resultId={resultId} resultText={resultText} inserted={inserted} />
      </TableCell>

      <TableCell padding="none">
        <TableCellListItem
          primary={`+${prefix} ${phoneNumberFormat(nationalNumber)}`}
          secondary={productName}
        />
      </TableCell>
      <TableCell padding="none">
        <TableCellListItem primary={orderedby} />
      </TableCell>
      <TableCell padding="none" align="right" style={{ paddingRight: 8, maxWidth: 50, width: 50 }}>
        <IconButton>
          <ChevronRightIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default OrdersTableRow
