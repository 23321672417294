import React from 'react'
import classNames from 'classnames'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Typography,
  Chip,
  Tooltip,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  TurnedInNot as BusinessIcon,
  ChevronRight as ChevronRightIcon,
  PeopleOutlined as PeopleIcon,
} from '@material-ui/icons'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      textDecoration: 'none',
      '& $listItemText': {
        color: theme.palette.primary.main,
      },
      '& $hoverIcon': {
        color: theme.palette.primary.main,
      },
    },
  },
  listItemText: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
  },
  activeListItem: {
    backgroundColor: theme.palette.primary.light,
    '& $listItemText': {
      color: theme.palette.primary.main,
    },
    '& $twoIcons': {
      color: theme.palette.primary.main,
    },
  },
  peopleIcon: {
    color: theme.palette.text.secondary,
  },
  userCount: {
    display: 'inline-flex',
    verticalAlign: 'top',
    marginRight: theme.spacing(),
  },
  twoIcons: {
    marginRight: theme.spacing(),
  },
  hoverIcon: {},
}))

const CustomerListItem = ({ name, customerID, userCount, active, goLiveDate, chooseCustomer }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const isXs = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <ListItem
      button
      className={classNames([classes.listItem], {
        [classes.activeListItem]: active,
      })}
      onClick={() => chooseCustomer(customerID)}
    >
      <ListItemIcon>
        <>
          {active && !isXs ? <ChevronRightIcon /> : null}
          <BusinessIcon
            className={classNames([classes.hoverIcon], {
              [classes.twoIcons]: active && !isXs,
            })}
          />
        </>
      </ListItemIcon>
      <ListItemText className={classes.listItemText} primary={name} secondary={customerID} />
      <ListItemSecondaryAction>
        <Tooltip title={!goLiveDate ? t('Organization_customer_select_need_setup') : ''}>
          <Chip
            className={classes.userCount}
            label={
              goLiveDate
                ? t('Organization_customer_select_golivedate', {
                    date: format(goLiveDate, 'dd-MM-yyyy'),
                  })
                : t('Organization_customer_select_nogolivedate')
            }
            size="small"
          />
        </Tooltip>
        <Typography className={classes.userCount}>{userCount}</Typography>
        <PeopleIcon className={classes.peopleIcon} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default CustomerListItem
