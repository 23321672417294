import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { List, ListItem, ListItemSecondaryAction, ListItemText, Divider } from '@material-ui/core'
import { DonutChartWithText } from 'components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: theme.spacing(3),
  },
  listItemText: {
    '&>span': {
      whiteSpace: 'nowrap',
      position: 'relative',
      paddingLeft: theme.spacing(3),
      width: '100%',
      textAlign: 'left',
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 10,
        transform: 'translateY(-50%)',
        height: 5,
        borderRadius: 16,
        top: '50%',
        left: 0,
      },
      '&+p': {
        paddingLeft: theme.spacing(3),
      },
    },
  },
  highlight: {
    fontWeight: 700,
    fontSize: theme.typography.subtitle2.fontSize,
    paddingRight: theme.spacing(1.5),
  },
  subscriptionFees: {
    '&>span:before': { backgroundColor: theme.palette.background.light },
  },
  mobileFees: {
    '&>span:before': { backgroundColor: theme.palette.primary.main },
  },
  landlineFees: {
    '&>span:before': { backgroundColor: theme.palette.background.orange },
  },
  extraServiceFees: {
    '&>span:before': { backgroundColor: theme.palette.background.pink },
  },
}))

const InvoiceWidgetLatestInvoice = ({ invoice, changePage, subTitle }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const listItemTextCommonProps = (className) => ({
    className: classNames(classes.listItemText, className),
    secondaryTypographyProps: { noWrap: true },
  })

  return (
    <>
      <DonutChartWithText
        chartData={[
          invoice.subscriptionFees,
          invoice.mobileFees,
          invoice.fixedLineFees,
          invoice.extraServiceFees,
        ]}
        backgroundColor={[
          theme.palette.background.light,
          theme.palette.primary.main,
          theme.palette.background.orange,
          theme.palette.background.pink,
        ]}
        title={invoice.totalFees}
        subTitle={subTitle}
      />
      <Divider />
      <List>
        <ListItem
          button
          className={classes.listItem}
          onClick={() => changePage(`faktura/${invoice.billing_id}/abonnementer`)}
        >
          <ListItemText
            {...listItemTextCommonProps(classes.subscriptionFees)}
            primary={t('Dashboard_invoice_subscriptions')}
            secondary={t('Dashboard_invoice_subscriptions_description')}
          />
          <ListItemSecondaryAction className={classes.highlight}>
            {invoice.subscription_fees}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          onClick={() => changePage(`faktura/${invoice.billing_id}/mobil`)}
        >
          <ListItemText
            {...listItemTextCommonProps(classes.mobileFees)}
            primary={t('Dashboard_invoice_mobile')}
            secondary={t('Dashboard_invoice_mobile_desc')}
          />
          <ListItemSecondaryAction className={classes.highlight}>
            {invoice.mobile_fees}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          onClick={() => changePage(`faktura/${invoice.billing_id}/fastnet`)}
        >
          <ListItemText
            {...listItemTextCommonProps(classes.landlineFees)}
            primary={t('Dashboard_invoice_landline')}
            secondary={t('Dashboard_invoice_landline_desc')}
          />
          <ListItemSecondaryAction className={classes.highlight}>
            {invoice.fixed_line_fees}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          onClick={() => changePage(`faktura/${invoice.billing_id}/ekstra-services`)}
        >
          <ListItemText
            {...listItemTextCommonProps(classes.extraServiceFees)}
            primary={t('Dashboard_invoice_extra')}
            secondary={t('Dashboard_invoice_extra_desc')}
          />
          <ListItemSecondaryAction className={classes.highlight}>
            {invoice.extra_service_fees}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  )
}

export default InvoiceWidgetLatestInvoice
