import React from 'react'
import { List } from '@material-ui/core'
import { DrawerListItem } from 'components'
import {
  NoSimRounded as SimIcon,
  PhonelinkLockRounded as PhoneIcon,
  PhonelinkSetup as MobilePackageIcon,
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const FunctionList = ({ setAction, disabled }) => {
  const { t } = useTranslation()

  return (
    <List>
      <DrawerListItem
        icon={<MobilePackageIcon />}
        primaryText={t('Dashboard_most_used_mobile')}
        secondaryText={t('Dashboard_most_used_mobile_desc')}
        isDisabled={disabled}
        isButton
        onClick={() => setAction('skift-mobilpakke')}
        withChevron
      />
      <DrawerListItem
        icon={<SimIcon />}
        primaryText={t('Dashboard_most_used_block_sim')}
        secondaryText={t('Dashboard_most_used_block_sim_desc')}
        isDisabled={disabled}
        isButton
        onClick={() => setAction('simkort')}
        withChevron
      />
      <DrawerListItem
        icon={<PhoneIcon />}
        primaryText={t('Dashboard_most_used_puk')}
        secondaryText={t('Dashboard_most_used_puk_desc')}
        isDisabled={disabled}
        isButton
        onClick={() => setAction('simkort')}
        withChevron
      />
    </List>
  )
}

export default FunctionList
