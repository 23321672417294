/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import partyweb from 'services/partyweb/client'
import { SWEB_API_URI } from 'constants/configurable'
import { createSubscription } from 'services/partyweb/subscription'
import { Paper, PaperHeader, PaperTitle, PaperContent } from '@skytdc/mui/components'
import { MuiDataTable, PaperLoader } from 'components'
import { fetchAllParties } from 'state/partyAllParties/actions'
import { fetchAllSignUps } from 'state/partyAllSignUps/actions'

import { Box, Typography, Tabs, Tab, Grid, Button, Chip } from '@material-ui/core'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import PartyModeIcon from '@material-ui/icons/PartyMode'
import FaceIcon from '@material-ui/icons/Face'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'

import PartyCard from '../components/PartyCard'
import Layout from '../components/Layout'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export default function Admin({ history, match }) {
  const partyId = parseFloat(match.params.partyId)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { value: accessToken } = partyweb.getBearer()

  const allParties = useSelector((state) => state.partyAllParties)
  const allSignUps = useSelector((state) => state.partyAllSignUps || [])
  const [value, setValue] = useState(0)
  const [wristbandReceivedList, setWristbandReceivedList] = useState([])

  const receivedWristband = async (received: boolean, employeeId: string) => {
    await createSubscription
      .receivedWristband({
        partyId,
        employeeId,
        json: { wristbandReceived: !received },
      })
      .then(() => {
        if (wristbandReceivedList.includes(employeeId)) {
          setWristbandReceivedList((prev) => [...prev.filter((id) => id !== employeeId)])
        } else {
          setWristbandReceivedList((prev) => [...prev, employeeId])
        }
        enqueueSnackbar(
          `${employeeId} ${!received ? 'Recieved a wristband' : 'Returned wristband'} `,
          { variant: 'success' }
        )
      })
      .catch((err) => {
        err.response.json().then((res) => {
          enqueueSnackbar(`${res.error.text}`, { variant: 'error' })
        })
      })
  }

  const columns = [
    {
      name: 'user.email',
      label: 'Email',
      align: 'left',
    },
    {
      name: 'user.firstname',
      label: 'Name',
      align: 'left',
      options: {
        customBodyRender: (firstName, { rowData }) => {
          const fullName = `${firstName} ${rowData[2]}`
          return fullName
        },
      },
    },
    {
      name: 'user.lastname',
      label: 'Last name',
      align: 'left',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'transport.transportLocation',
      label: 'Transport',
      align: 'left',
    },
    {
      name: 'wristbandReceived',
      label: 'Wristband',
      align: 'left',
      options: {
        customBodyRender: (wristbandReceived: boolean, { rowData }) => {
          const received = wristbandReceivedList?.includes(rowData[5])
          return (
            <Button
              onClick={() => receivedWristband(received, rowData[5])}
              size="small"
              variant={received ? 'contained' : 'outlined'}
              color="primary"
            >
              {received ? 'Received' : 'Not received'}
            </Button>
          )
        },
      },
    },
    {
      name: 'user.employeeId',
      label: 'Employee Id',
      align: 'left',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
  ]

  const options = {
    searchOpen: true,
    download: false,
    searchPlaceholder: 'Search for a participant',
    selectableRows: 'none',
    rowHover: false,
    viewColumns: false,
    searchInAllTable: true,
  }

  useEffect(() => {
    changeDocumentTitle('Admin: All parties for Nuuday employees')
    dispatch(fetchAllParties())
  }, [])
  useEffect(() => {
    setWristbandReceivedList(
      allSignUps.data?.signups
        .map(({ user, wristbandReceived }) => wristbandReceived && user.employeeId)
        .filter(Boolean)
    )
  }, [allSignUps])
  useEffect(() => {
    if (partyId) {
      dispatch(fetchAllSignUps({ partyId }))
    }
  }, [partyId])

  const downloadCsvLink = `${SWEB_API_URI}/party/admin/${partyId}/signups.csv?access_token=${accessToken}`

  return (
    <>
      <Layout>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper>
              <PaperHeader noDivider={false}>
                <PaperTitle title="All parties" />
              </PaperHeader>
              <PaperContent>
                {allParties.data?.map(
                  (content) =>
                    !content.location?.includes('Expired') && (
                      <PartyCard
                        checked={content.id === partyId}
                        onClick={() => history.push(`/party/admin/${content.id}`)}
                        key={content.id}
                        content={content}
                      />
                    )
                )}
              </PaperContent>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper>
              {!allSignUps.data?.signups && !allSignUps.loading ? (
                <Box p={3}>
                  <Typography variant="h1">Pick a party from the sidebar</Typography>
                </Box>
              ) : (
                <>
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    onChange={(e, newValue) => setValue(newValue)}
                    variant="scrollable"
                    aria-label="scrollable auto tabs"
                  >
                    <Tab icon={<PartyModeIcon />} label="All signups" />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    {allSignUps.loading ? (
                      <PaperLoader />
                    ) : (
                      <>
                        <Box display="flex" justifyContent="right">
                          {allSignUps.data?.signups.length > 0 && (
                            <Box mr={2}>
                              <Chip
                                variant="outlined"
                                icon={<FaceIcon />}
                                label={`Participants: ${allSignUps.data?.signups.length}`}
                                color="primary"
                              />
                            </Box>
                          )}
                          {allSignUps.data?.signups.length > 0 && (
                            <Button
                              target="_blank"
                              variant="contained"
                              href={downloadCsvLink}
                              color="primary"
                              startIcon={<CloudDownloadIcon />}
                            >
                              Download csv file
                            </Button>
                          )}
                        </Box>
                        <MuiDataTable
                          data={allSignUps.data?.signups}
                          options={options}
                          columns={columns}
                        />
                      </>
                    )}
                  </TabPanel>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Layout>
    </>
  )
}
