import React from 'react'
import classNames from 'classnames'
import { Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useButtonStyles from '../../hooks/useButtonStyle'

interface Props {
  className?: string
  wrapperClassName?: string
  children: JSX.Element | string
  color?: 'success' | 'warning' | 'primary' | 'error' | 'neutral'
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  loading?: boolean
  type?: 'submit' | 'reset' | 'button'
  fullWidth?: boolean
  containerClassName?: string
}

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  container: {
    display: 'flex',
  },
  fullWidth: {
    width: '100%',
  },
}))

const FilledButton = ({
  type = 'button',
  className,
  wrapperClassName,
  containerClassName,
  children,
  color = 'primary',
  onClick,
  disabled,
  loading,
  fullWidth,
}: Props) => {
  const classes = useStyles()
  const buttons = useButtonStyles()

  const root = classNames(
    {
      [buttons.success]: color === 'success',
      [buttons.warning]: color === 'warning',
      [buttons.primary]: color === 'primary',
      [buttons.error]: color === 'error',
      [buttons.neutral]: color === 'neutral',
    },
    className
  )

  const container = classNames([classes.container], containerClassName)

  const wrapper = classNames(
    [classes.wrapper],
    { [classes.fullWidth]: fullWidth },
    wrapperClassName
  )

  return (
    <div className={container}>
      <div className={wrapper}>
        <Button
          variant="outlined"
          className={root}
          onClick={onClick}
          fullWidth={fullWidth}
          disabled={disabled || loading}
          type={type}
        >
          {children}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  )
}

export default FilledButton
