import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceNumberSpecifications/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceNumberSpecifications/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceNumberSpecifications/FETCH_SUCCESS'

export const fetchInvoiceNumberSpecificationsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceNumberSpecificationsBegin = ({ invoiceId, anr }) => {
  return {
    type: FETCH_BEGIN,
    invoiceId,
    anr,
  }
}

export const fetchInvoiceNumberSpecificationsSuccess = ({ invoiceId, anr, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    invoiceId,
    anr,
  }
}

export const fetchInvoiceNumberSpecifications = ({
  customerId,
  invoiceId,
  anr,
  startdate,
  enddate,
}) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceNumberSpecificationsBegin({ invoiceId, anr }))
    try {
      const response = await getInvoice.specifications({
        customerId,
        invoiceId,
        anr,
        startdate,
        enddate,
      })
      dispatch(fetchInvoiceNumberSpecificationsSuccess({ invoiceId, anr, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceNumberSpecificationsFailure(err.response))
    }
  }
}
