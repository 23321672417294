import React, { useState, useEffect, useRef } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { isFuture } from 'date-fns'
import { deleteCustomer } from 'services/sweb/customer'
import { fetchAzureHealthCheck } from 'state/azureHealthCheck/actions'
import { fetchPresenceHealthCheck } from 'state/presenceHealthCheck/actions'
import { fetchAzureAppSecrets } from 'state/azureAppSecrets/actions'
import { fetchPresenceProduct } from 'state/presenceProduct/actions'
import { fetchPresenceAppSecrets } from 'state/presenceAppSecrets/actions'
import { fetchAzureAdminSecrets } from 'state/azureAdminSecrets/actions'
import { deleteSubscription, createSubscription } from 'services/sweb/subscription'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { teams } from 'services/appOpts'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { PaperLoader, PaperFeedbackMessage } from 'components'
import { useTranslation } from 'react-i18next'
import StepOneWelcome from './components/StepOneWelcome'
import DaemonApp from './components/DaemonApp'
import AdminUser from './components/AdminUser'
import AdminUserLocked from './components/AdminUserLocked'
import StepHealthCheck from './components/StepHealthCheck'
import PresenceActivation from './components/PresenceActivation'
import Roles from './components/Roles'
import Topbar from './components/Topbar'

const useStyles = makeStyles((theme) => ({
  wizardContainer: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(20),
  },
}))

const SetupWizard = ({ title, match, history, location }) => {
  const { params } = match
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(null)
  const gridRef = useRef(null)
  const [tenant, setTenant] = useState({
    name: '',
    id: '',
  })

  const presencePage = location.pathname?.split('/')[2] === 'presence-setup'
  const stored = useSelector(
    (state) => ({
      me: state.me,
      customer: state.customer,
      azureAppSecrets: state.azureAppSecrets,
      azureAdminSecrets: state.azureAdminSecrets,
      azureHealthCheck: state.azureHealthCheck,
      presenceHealthCheck: state.presenceHealthCheck,
      presenceAppSecrets: state.presenceAppSecrets,
      presenceProduct: state.presenceProduct,
    }),
    shallowEqual
  )

  if (presencePage && stored.presenceProduct.data?.available_presence_product === null) {
    history.push('organisation/azure-forbindelse')
  }

  const hasActiveSecret = stored.azureAppSecrets.data?.AppSecretOutput
  const hasActivePresenceSecret = stored.presenceAppSecrets.data?.AppSecretOutput
  const hasActiveAdminUser = stored.azureAdminSecrets.data?.AdminSecretOutput
  const goLiveDate = stored.customer.data.go_live_date
  const goLiveDateReached = goLiveDate && isFuture(goLiveDate)
  const userIsAdmin = stored.me.data.user.role === 'admin'
  const canGoBack = userIsAdmin || stored.me.data.authorizations.length > 0 || goLiveDateReached

  const backToCustomerList = () => {
    if (canGoBack) history.push('/')
  }
  const handleSetTenant = (tenantChange) => {
    setTenant({ ...tenantChange })
  }

  const handleFetchAppSecrets = () => {
    dispatch(fetchAzureAppSecrets({ customerId: params.customer_id }))
  }
  const handleFetchPresenceAppSecrets = () => {
    dispatch(fetchPresenceAppSecrets({ customerId: params.customer_id }))
  }

  const handleFetchAdminSecrets = () => {
    dispatch(fetchAzureAdminSecrets({ customerId: params.customer_id }))
  }
  const handleFetchPresenceProduct = () => {
    dispatch(fetchPresenceProduct({ customerId: params.customer_id }))
  }

  const handleHealthCheck = () => {
    setCurrentStep(3)
    dispatch(fetchAzureHealthCheck({ customerId: params.customer_id }))
  }
  const handlePresenceHealthCheck = () => {
    setCurrentStep(3)
    dispatch(fetchPresenceHealthCheck({ customerId: params.customer_id }))
  }

  const { fetchSecrets, secret, performHealthCheck, healthCheck } = presencePage
    ? {
        fetchSecrets: handleFetchPresenceAppSecrets,
        secret: hasActivePresenceSecret,
        performHealthCheck: handlePresenceHealthCheck,
        healthCheck: stored.presenceHealthCheck,
      }
    : {
        fetchSecrets: handleFetchAppSecrets,
        secret: hasActiveSecret,
        performHealthCheck: handleHealthCheck,
        healthCheck: stored.azureHealthCheck,
      }

  useEffect(() => {
    if (currentStep || currentStep !== 0) {
      setTimeout(() => {
        gridRef.current?.children[currentStep]?.scrollIntoView({ behavior: 'smooth' })
      }, 400)
    }
  }, [currentStep])

  useEffect(() => {
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    handleFetchAdminSecrets()
    if (presencePage) {
      handleFetchPresenceAppSecrets()
      !stored.presenceProduct.data && handleFetchPresenceProduct()
    } else {
      handleFetchAppSecrets()
    }
  }, [])

  useEffect(() => {
    if (
      stored.presenceAppSecrets.data ||
      (!presencePage && stored.azureAppSecrets.data && stored.azureAdminSecrets.data)
    ) {
      if (
        (presencePage && !hasActiveAdminUser && !hasActivePresenceSecret) ||
        (!presencePage && !hasActiveSecret)
      ) {
        setCurrentStep(0)
      } else if (
        (presencePage && hasActiveAdminUser && !hasActivePresenceSecret) ||
        (!presencePage && hasActiveSecret && !hasActiveAdminUser)
      ) {
        setCurrentStep(2)
      } else if (
        presencePage &&
        stored.presenceAppSecrets.customerId === params.customer_id &&
        hasActivePresenceSecret
      ) {
        handlePresenceHealthCheck()
      } else if (!presencePage && hasActiveSecret && hasActiveAdminUser) {
        // successful health check will set step to 4 (5)
        handleHealthCheck()
      } else {
        setCurrentStep(0)
      }
    }
  }, [stored.azureAppSecrets, stored.azureAdminSecrets, stored.presenceAppSecrets])

  useEffect(() => {
    const hasItems = healthCheck.data?.healthcheck.length > 0
    if (currentStep !== 4 && !healthCheck.loading && hasItems) {
      const hasError = healthCheck.data.healthcheck.find(
        (item) => item.status.toLowerCase() !== 'ok'
      )

      if (!hasError) {
        setCurrentStep(4)
      }
    }
  }, [healthCheck])

  const deleteSecret = (json) => {
    setLoading(true)
    deleteCustomer
      .secret({ customerId: params.customer_id, json })
      .then(() => {
        setLoading(false)
        enqueueSnackbar(t('Wizard_secret_delete_success'), { variant: 'success' })
        if (presencePage) {
          handleFetchPresenceAppSecrets()
        } else {
          handleFetchAppSecrets()
          handleFetchAdminSecrets()
        }
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }
  const disablePresence = (json) => {
    setLoading(true)
    deleteSubscription
      .stopPresence({ customerId: params.customer_id, json })
      .then(() => {
        setLoading(false)
        enqueueSnackbar(t('Wizard_presence_disable'), { variant: 'success' })
        handleFetchPresenceProduct()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  const activatePresence = () => {
    setLoading(true)
    createSubscription
      .activatePresence({ customerId: params.customer_id })
      .then(() => {
        setLoading(false)
        enqueueSnackbar(t('Wizard_presence_activated'), { variant: 'success' })
        handleFetchPresenceProduct()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  const showSteps = Boolean(
    (currentStep >= 0 && stored.azureAppSecrets.data && stored.azureAdminSecrets.data) ||
      (currentStep >= 0 && presencePage && stored.presenceAppSecrets.data)
  )

  let content = <PaperLoader />

  if (stored.azureAppSecrets.error || stored.azureAdminSecrets.error) {
    content = (
      <>
        {stored.azureAppSecrets.error && (
          <PaperFeedbackMessage
            type="error"
            message={t('Wizard_app_err', { error: stored.azureAppSecrets.error.statusText })}
            withMargin
          />
        )}
        {stored.azureAdminSecrets.error && (
          <PaperFeedbackMessage
            type="error"
            message={t('Wizard_app_err', { error: stored.azureAdminSecrets.error.statusText })}
            withMargin
          />
        )}
      </>
    )
  }

  if (showSteps) {
    const initialTenantId =
      (hasActiveSecret && hasActiveSecret.tenantId) ||
      (hasActiveAdminUser && hasActiveAdminUser.tenantId) ||
      (hasActivePresenceSecret && hasActivePresenceSecret.tenantId) ||
      (teams && teams.context.tid) ||
      ''
    const initialTenantName =
      (hasActiveSecret && hasActiveSecret.tenantName) ||
      (hasActiveAdminUser && hasActiveAdminUser.tenantName) ||
      (hasActivePresenceSecret && hasActivePresenceSecret.tenantName) ||
      ''
    content = (
      <>
        <StepOneWelcome
          onChange={handleSetTenant}
          loading={loading}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          initialTenantId={initialTenantId}
          initialTenantName={initialTenantName}
          lockFromEditing={Boolean(
            hasActiveSecret || hasActiveAdminUser || hasActivePresenceSecret
          )}
          isPresence={presencePage}
        />
        {presencePage && (
          <AdminUserLocked
            loading={loading}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            secret={hasActiveAdminUser}
          />
        )}
        <DaemonApp
          tenant={tenant}
          customerId={params.customer_id}
          fetchSecrets={fetchSecrets}
          deleteSecret={deleteSecret}
          loading={loading}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          secret={secret}
          presencePage={presencePage}
        />
        {!presencePage && (
          <AdminUser
            tenant={tenant}
            customerId={params.customer_id}
            fetchSecrets={handleFetchAdminSecrets}
            deleteSecret={deleteSecret}
            loading={loading}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            secret={hasActiveAdminUser}
          />
        )}
        <StepHealthCheck
          performHealthCheck={performHealthCheck}
          healthCheck={healthCheck}
          currentStep={currentStep}
          presencePage={presencePage}
        />
        {presencePage && (
          <PresenceActivation
            presenceProduct={stored.presenceProduct}
            loading={loading}
            fetchPresenceProduct={handleFetchPresenceProduct}
            disablePresence={disablePresence}
            activatePresence={activatePresence}
            currentStep={currentStep}
          />
        )}
        {!presencePage && (
          <Roles
            customerId={params.customer_id}
            goLiveDate={stored.customer.data.go_live_date}
            azureTenantId={tenant.id}
            currentStep={currentStep}
            canGoBack={canGoBack}
            goBack={backToCustomerList}
          />
        )}
      </>
    )
  }
  return (
    <>
      <Topbar
        showSteps={showSteps}
        currentStep={currentStep}
        goBack={backToCustomerList}
        presencePage={presencePage}
      />
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        direction="row"
        className={classes.wizardContainer}
      >
        <Grid ref={gridRef} item lg={5} md={6} sm={8} xs={11}>
          {content}
        </Grid>
      </Grid>
    </>
  )
}

export default SetupWizard
