import { getValidation } from 'services/sweb/validation'

export const FETCH_FAILURE = 'validateLandlineNumber/FETCH_FAILURE'
export const FETCH_BEGIN = 'validateLandlineNumber/FETCH_BEGIN'
export const FETCH_SUCCESS = 'validateLandlineNumber/FETCH_SUCCESS'

export const fetchValidateLandlineNumberFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchValidateLandlineNumberBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchValidateLandlineNumberSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchValidateLandlineNumber = ({ customerId, phoneNumber, prefix = 45 }) => {
  return async (dispatch) => {
    dispatch(fetchValidateLandlineNumberBegin())
    try {
      const response = await getValidation.validatePhoneNumber({
        customerId,
        phoneNumber,
        prefix,
      })
      dispatch(fetchValidateLandlineNumberSuccess(response.data))
    } catch (err) {
      dispatch(fetchValidateLandlineNumberFailure(err.response))
    }
  }
}
