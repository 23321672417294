export default [
  {
    title: 'Can I join both parties in Teglholmen and Sletvej?',
    description:
      'No, you can attend the party, you are invited to. So if you are invited to Teglholmen, you attend Teglholmen, and if you are invited to Aarhus, you attend Aarhus',
  },
  {
    title: 'When and where is the party?',
    description: [
      'Copenhagen: 16. September: Docken, Færgehavnsvej 35, 2150 København (www.docken.dk)',
      'Aarhus: 23. September er: Savværket, Søren Nymarks Vej 8A, 8270 Højbjerg (www.savvaerket-event.dk)',
    ],
  },
  {
    title: 'Is there a bus transport?',
    description:
      'Yes, you can go by bus. Find more information about the busses at the pages for transportation.Time and specific departure location will soon be updated.',
  },
  {
    title: 'Should I be dressed up? Or is there a dress code?',
    description:
      'There is no dress code, but you are very welcome to bring your best festival outfit or just dress up festively and fun.',
  },
  {
    title: 'When is the deadline for signing up for the Nuuday ’22 Party?',
    description: 'Registration deadline is Tuesday, 19th of August, end-of-day.',
  },
  {
    title: 'At what time does the party start and end?',
    description: 'The Nuuday ‘22 Party starts at 18.00 and ends at 00.00.',
  },
  {
    title: 'How do I sign up?',
    description:
      'You sign up by using the registration link in the invitation to the Nuuday ‘22 Party.',
  },
  {
    title: 'Can I be seated at the dinner with my colleagues in my team?',
    description:
      'There will be seating areas so you and your colleagues can sit together. But there will not be seating for everyone so remember to mingle around and talk to all you great colleagues.',
  },
  {
    title: 'How can I unsubscribe?',
    description:
      'You can unsubscribe on the invitation website. Search for ‘Nuuday ’22 Party’ in your mailbox and go to the website. In the right corner you have an unsubscribe button. ',
  },
  {
    title: 'Will we have a Christmas Party this year? ',
    description:
      'This year we are having a big Summer Party. The Christmas Party will be up to the local departments to decide whether they will have one or not this year.',
  },
  {
    title: 'What is the program for the Nuuday ’22 Party?',
    description: 'Will soon be updated. The program will be shared on Workplace when it is ready. ',
  },
  {
    title: 'Does it cost anything to attend?',
    description: 'It’s free to attend just remember to sign up.',
  },
  { title: 'Can I go by my own car?', description: 'Yes, it is possible to go by your own car.' },
  {
    title: 'Is there a driving allowance if you drive in your own car?',
    description:
      'No, since it is voluntary to participate, there is no driving allowance if you drive in your own car.',
  },
  {
    title: 'Will there be a free bar?',
    description:
      'There will be a free bar from 18.00 - 24.00.We will have soft bar (beer, wine, soda and water) from 18.00 – 24.00, and drinks with and without alcohol from 20.00 – 24.00.',
  },
  {
    title: 'Will it be possible to go outdoor during the party?',
    description: 'There will be outdoor areas for smoking, but the Nuuday ’22 Party is indoor. ',
  },
  {
    title: 'Is there a wardrobe?',
    description: 'Yes, we have a manned wardrobe at the location. ',
  },
  {
    title:
      'What is the menu? And is our different cultures and dietary requirements, etc. catered for?',
    description:
      'Yes, we take care of our different cultures and dietary requirements. We are currently working on defining the menu, and as soon as it is decided, we will send information on what it consists of.',
  },
  {
    title: 'Which type of food will be served?',
    description:
      'We will have delicious Festival-food, that all will be served at once so you and your colleagues easy can grab what you like and find a place to sit or stand while enjoying your food. When the menu is complete it will be shared on Workplace.',
  },
]
