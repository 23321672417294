import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'huntgroupCallPatterns/FETCH_FAILURE'
export const FETCH_BEGIN = 'huntgroupCallPatterns/FETCH_BEGIN'
export const FETCH_SUCCESS = 'huntgroupCallPatterns/FETCH_SUCCESS'

export const fetchHuntgroupCallPatternsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchHuntgroupCallPatternsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchHuntgroupCallPatternsuccess = ({ customerId, data }) => {
  return {
    type: FETCH_SUCCESS,
    customerId,
    data,
  }
}

export const fetchHuntgroupCallPatterns = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchHuntgroupCallPatternsBegin({ customerId }))
    try {
      const response = await getCustomer.huntgroupCallPatterns({
        customerId,
      })
      dispatch(fetchHuntgroupCallPatternsuccess({ customerId, data: response.data }))
    } catch (err) {
      dispatch(fetchHuntgroupCallPatternsFailure(err.response))
    }
  }
}
