import React, { useState } from 'react'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, FormControlLabel, Checkbox, Slide } from '@material-ui/core'
import { CustomDialog, SkyTextField, SkyDatePicker } from 'components'
import { useTranslation } from 'react-i18next'
import { Alert } from '@material-ui/lab'
import DrawerButtons from '../../../components/DrawerButtons'

const useStyles = makeStyles((theme) => ({
  date: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  checkbox: {
    width: '100%',
  },
  message: {
    paddingBottom: theme.spacing(3),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}))

const CancelSubscriptionForm = ({
  loading,
  backLink,
  hasMobile,
  receiptEmail,
  earliestCloseDate,
  cancelSubscription,
  hasAzureId,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [email, setEmail] = useState(receiptEmail || '')
  const [reserveMobileNumber, setReserveMobileNumber] = useState(true)
  const [cancellationDate, setCancellationDate] = useState(new Date())
  const [dialog, setDialog] = useState({
    open: false,
    title: null,
    message: null,
    callback: null,
  })

  const handleEmail = (event) => {
    setEmail(event.target.value)
  }

  const handleReserveMobileNumber = (event) => {
    setReserveMobileNumber(event.target.checked)
  }

  const handleCancellationDate = (date) => {
    setCancellationDate(date)
  }

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }

  const handleCancelSubscription = () => {
    const json = {
      date: format(cancellationDate, 'dd-MM-yyyy'),
      emailReceipt: email,
      mobile_cancel_action: reserveMobileNumber ? 'ondate' : 'onbillend',
      landline_cancel_action: 'ondate',
    }
    cancelSubscription(json)
    handleCloseDialog()
  }

  const handleOpenDialog = () => {
    setDialog({
      open: true,
      title: t('Drawer_cancel_all_title'),
      message: t('Drawer_cancel_all_description', { date: format(cancellationDate, 'dd-MM-yyyy') }),
      callback: () => handleCancelSubscription(), // could perhaps ref
    })
  }

  const isFormValid = Boolean(email.length > 2 && cancellationDate)

  return (
    <Slide in direction="left">
      <div>
        <Alert severity="warning" className={classes.alert}>
          Denne bruger kan ikke forbindes til jeres AD længere. Du kan derfor kun nedlægge alle
          brugerens abonnementer - ikke ned- eller opgradere til noget andet.
        </Alert>
        <Typography gutterBottom>{t('Drawer_cancel_all_text_one')}</Typography>
        <Typography component="span" className={classes.message}>
          <ul>
            <li>{t('Drawer_cancel_all_text_warning')}</li>
            <li>{t('Drawer_cancel_all_text_two')}</li>
            <li>{t('Drawer_cancel_all_text_three', { date: earliestCloseDate.date })}</li>
            <li>{t('Drawer_cancel_all_text_four')}</li>
          </ul>
        </Typography>
        <SkyTextField
          label={t('Drawer_cancel_input_email')}
          value={email}
          onChange={handleEmail}
          disabled={loading}
          required
          white
        />
        <SkyDatePicker
          label={t('Drawer_cancel_input_cancel_date')}
          disablePast
          disabled={loading}
          value={cancellationDate}
          onChange={handleCancellationDate}
          white
        />
        {hasMobile && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(reserveMobileNumber)}
                  onChange={handleReserveMobileNumber}
                  disabled={loading}
                  value={reserveMobileNumber}
                  color="primary"
                />
              }
              className={classes.checkbox}
              label={t('Drawer_cancel_reserve')}
            />
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {t('Drawer_cancel_reserve_desc')}
            </Typography>
            {!reserveMobileNumber && (
              <Typography variant="body2" color="error" gutterBottom>
                {t('Drawer_cancel_reserve_desc_two')}
              </Typography>
            )}
          </>
        )}
        <DrawerButtons
          backLink={backLink}
          withBackButton={hasAzureId}
          disabled={!isFormValid}
          onSubmit={handleOpenDialog}
          submitText={t('Confirm')}
          loading={loading}
        />
        <CustomDialog
          isOpen={dialog.open}
          onConfirm={dialog.callback}
          onCancel={handleCloseDialog}
          title={dialog.title}
          contentText={dialog.message}
          confirmButtonText={t('Confirm')}
        />
      </div>
    </Slide>
  )
}

export default CancelSubscriptionForm
