import palette from './palette'

export default {
  useNextVariants: true,
  fontFamily: '"Mukta Mahee", sans-serif',
  h1: {
    fontFamily: '"TDC", sans-serif',
    color: palette.text.primary,
    fontWeight: 100,
    fontSize: '35px',
  },
  h2: {
    fontFamily: '"TDC", sans-serif',
    color: palette.text.primary,
    fontWeight: 100,
    fontSize: '29px',
  },
  h3: {
    fontFamily: '"TDC", sans-serif',
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '24px',
  },
  h4: {
    fontFamily: '"TDC", sans-serif',
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '20px',
  },
  h5: {
    fontFamily: '"TDC", sans-serif',
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '16px',
  },
  h6: {
    fontFamily: '"TDC", sans-serif',
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '14px',
  },
  subtitle1: {
    fontFamily: '"Mukta Mahee", sans-serif',
    color: palette.text.primary,
    fontSize: '16px',
    fontWeight: 600,
  },
  subtitle2: {
    fontFamily: '"Mukta Mahee", sans-serif',
    color: palette.text.primary,
    fontSize: '14px',
    letterSpacing: 0,
    fontWeight: 600,
  },
  body1: {
    fontFamily: '"Mukta Mahee", sans-serif',
    color: palette.text.primary,
    fontSize: '15px',
    lineHeight: '21px',
    fontWeight: 300,
  },
  body2: {
    fontFamily: '"Mukta Mahee", sans-serif',
    color: palette.text.primary,
    fontSize: '13px',
    lineHeight: '14px',
    fontWeight: 300,
  },
  button: {
    fontFamily: '"Mukta Mahee", sans-serif',
    color: palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
  },
  caption: {
    fontFamily: '"Mukta Mahee", sans-serif',
    color: palette.text.secondary,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 300,
  },
}
