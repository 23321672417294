const locationHostname = window.location.hostname

export const isItObject = (val) => {
  return Object.prototype.toString.call(val) === '[object Object]'
}

export const objKeysToLowercase = (obj = {}) => {
  const lowKeyObj = {}
  Object.keys(obj).forEach((k) => {
    const kLower = k.toLowerCase()
    lowKeyObj[kLower] = obj[k]
  })
  return lowKeyObj
}

export const BEARER_NAME_PREFIX = `__!<bearer>${locationHostname}||`

export const getBearerName = (name) => `${BEARER_NAME_PREFIX}${name}`
export const getBearerNames = (namesArr) => namesArr.map(getBearerName)

export const getBearerFromStorage = (name) => {
  const bearerName = getBearerName(name)
  const [lStorage, sStorage] = [
    window.localStorage.getItem(bearerName),
    window.sessionStorage.getItem(bearerName),
  ]
  return lStorage || sStorage
}

export const cleanStoredBearersExcept = (bearerNames = []) => {
  const storedKeys = Object.keys({
    ...window.localStorage,
    ...window.sessionStorage,
  })
  storedKeys
    .filter((fkey) => {
      if (bearerNames.includes(fkey)) return false
      return fkey.substr(0, BEARER_NAME_PREFIX.length) === BEARER_NAME_PREFIX
    })
    .forEach((rmKey) => {
      window.localStorage.removeItem(rmKey)
      window.sessionStorage.removeItem(rmKey)
    })
}
