import React from 'react'
import List from '@material-ui/core/List'
import SecretItem from '../SecretItem'

const SecretsContainer = ({ secret, loading, deleteSecret, disabled }) => {
  return secret ? (
    <List>
      <SecretItem
        loading={loading}
        tenantId={secret.tenantId}
        tenantName={secret.tenantName}
        unionId={secret.unionId}
        deleteSecret={deleteSecret}
        key={secret.name}
        login={secret.login}
        notAfter={secret.notAfter}
        notBefore={secret.notBefore}
        name={secret.name}
        clientId={secret.clientId}
        disabled={disabled}
      />
    </List>
  ) : null
}

export default SecretsContainer
