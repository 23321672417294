import React, { useState, useEffect, Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { createCustomer } from 'services/sweb/customer'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Alert, SkyTextField, SkySelect } from '@skytdc/mui/components'
import { Button, Grid, Typography, Box, IconButton } from '@material-ui/core'
import { AddRounded as AddIcon, RemoveCircleRounded as RemoveIcon } from '@material-ui/icons'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { fetchExternalProviders } from 'state/externalProviders/actions'
import { DialogWrapper, GradientButton } from 'components'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: -theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(),
  },
  progressContainer: {
    marginTop: theme.spacing(3),
  },
  progressBar: {
    height: 10,
  },
  portingBox: {
    height: 58,
    border: `2px dashed ${theme.palette.background.default}`,
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    width: '100%',
    transition: theme.transitions.create(['opacity', 'border-color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'flex',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
      borderColor: theme.palette.primary.main,
    },
  },
  removeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -theme.spacing(2),
  },
}))

interface Props extends RouteComponentProps {
  customerId: string
}

const PortDialog = ({ customerId, history }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const externalProviders = useSelector((state) => state.externalProviders)

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => history.push(`/${customerId}/organisation/ledige-numre`))
  }

  useEffect(() => {
    setOpen(true)
    changeDocumentTitle(t('CreateIntNumbers'), ' - ', ':applicationName')
    dispatch(fetchExternalProviders())
  }, [])

  const { setFieldValue, handleSubmit, values, isSubmitting } = useFormik({
    initialValues: {
      companyName: '',
      mainNumber: '',
      addressOne: '',
      addressTwo: '',
      emailAddress: '',
      cvr: '',
      zip: '',
      city: '',
      comment: '',
      landlineNumbers: [],
      numberSeries: [],
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      const json = {
        company_name: payload.companyName,
        company_phone_number: payload.mainNumber,
        email: payload.emailAddress,
        cvr: payload.cvr,
        address_line1: payload.addressOne,
        address_line2: payload.addressTwo,
        zip_code: payload.zip,
        city: payload.city,
        comment: payload.comment,
        import_phone_numbers: payload.landlineNumbers.map((x) => {
          return {
            operator_id: x.provider,
            phone_number: x.number,
          }
        }),
        import_phone_number_series: payload.numberSeries.map((x) => {
          return {
            operator_id: x.provider,
            series_from: x.from,
            series_to: x.to,
          }
        }),
      }
      createCustomer
        .portLandlineNumbers({
          customerId,
          json,
        })
        .then(() => {
          enqueueSnackbar(t('IntNumberProvSuccess'), { variant: 'success' })
          handleClose()
        })
        .catch((err) => {
          err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        })
    },
  })

  const handleAddLandlineNumber = () => {
    const newLandlineNumbers = [
      ...values.landlineNumbers,
      {
        provider: '',
        number: '',
        id: uuidv4(),
      },
    ]
    setFieldValue('landlineNumbers', newLandlineNumbers)
  }

  const handleAddNumberSeries = () => {
    const newNumberSeries = [
      ...values.numberSeries,
      {
        provider: '',
        from: '',
        to: '',
        id: uuidv4(),
      },
    ]
    setFieldValue('numberSeries', newNumberSeries)
  }

  const handleRemoveLandlineNumber = (id: string) => {
    const newLandlineNumbers = values.landlineNumbers.filter((x) => x.id !== id)
    setFieldValue('landlineNumbers', newLandlineNumbers)
  }

  const handleRemoveNumberSeries = (id: string) => {
    const newNumberSeries = values.numberSeries.filter((x) => x.id !== id)
    setFieldValue('numberSeries', newNumberSeries)
  }

  const handleUpdateLandlineNumber = (value: string | number, name: string, index: number) => {
    const newLandlineNumbers = [...values.landlineNumbers]
    newLandlineNumbers[index] = {
      ...newLandlineNumbers[index],
      [name]: value,
    }
    setFieldValue('landlineNumbers', newLandlineNumbers)
  }

  const handleUpdateNumberSeries = (value: string | number, name: string, index: number) => {
    const newNumberSeries = [...values.numberSeries]
    newNumberSeries[index] = {
      ...newNumberSeries[index],
      [name]: value,
    }
    setFieldValue('numberSeries', newNumberSeries)
  }

  let content = (
    <Grid container spacing={1}>
      <Grid xs={12} item>
        <Typography variant="h4" style={{ marginTop: 8, marginBottom: 8 }}>
          {t('LegalOwner')}
        </Typography>
      </Grid>
      <Grid xs={6} item>
        <SkyTextField
          label={t('Organization_unassignedsimcards_company_name')}
          name="companyName"
          value={values.companyName}
          onChange={setFieldValue}
          disabled={isSubmitting}
          required
        />
      </Grid>
      <Grid xs={6} item>
        <SkyTextField
          label={t('MainNumber')}
          name="mainNumber"
          value={values.mainNumber}
          onChange={setFieldValue}
          disabled={isSubmitting}
          required
        />
      </Grid>
      <Grid xs={12} item>
        <SkyTextField
          label={t('Organization_orders_drawer_address')}
          name="addressOne"
          value={values.addressOne}
          onChange={setFieldValue}
          disabled={isSubmitting}
          required
        />
      </Grid>
      <Grid xs={12} item>
        <SkyTextField
          label={`${t('Organization_orders_drawer_address')} 2`}
          name="addressTwo"
          value={values.addressTwo}
          onChange={setFieldValue}
          disabled={isSubmitting}
        />
      </Grid>
      <Grid xs={6} item>
        <SkyTextField
          label={t('Organization_orders_drawer_city')}
          name="city"
          value={values.city}
          onChange={setFieldValue}
          disabled={isSubmitting}
          required
        />
      </Grid>
      <Grid xs={6} item>
        <SkyTextField
          label={t('Organization_orders_drawer_zip')}
          name="zip"
          value={values.zip}
          onChange={setFieldValue}
          disabled={isSubmitting}
          required
        />
      </Grid>
      <Grid xs={6} item>
        <SkyTextField
          label={t('CompanyEmail')}
          name="emailAddress"
          value={values.emailAddress}
          onChange={setFieldValue}
          disabled={isSubmitting}
          required
        />
      </Grid>
      <Grid xs={6} item>
        <SkyTextField
          label={t('Cvr')}
          name="cvr"
          value={values.cvr}
          onChange={setFieldValue}
          disabled={isSubmitting}
          required
        />
      </Grid>
      <Grid xs={12} item>
        <SkyTextField
          label={t('Organization_orders_drawer_comment')}
          name="comment"
          value={values.comment}
          onChange={setFieldValue}
          disabled={isSubmitting}
        />
      </Grid>
      {values.landlineNumbers.length > 0 &&
        values.landlineNumbers.map((x, index) => {
          return (
            <Fragment key={x.id}>
              <Grid xs={1} item className={classes.removeContainer}>
                <IconButton edge="start" onClick={() => handleRemoveLandlineNumber(x.id)}>
                  <RemoveIcon color="error" />
                </IconButton>
              </Grid>
              <Grid xs={6} item>
                <SkyTextField
                  label={t('LandlineNumber')}
                  name="number"
                  value={x.number}
                  onChange={(name, value) => handleUpdateLandlineNumber(value, name, index)}
                  disabled={isSubmitting}
                  required
                />
              </Grid>
              <Grid xs={5} item>
                <SkySelect
                  label={t('ReleasingOperator')}
                  required
                  name="provider"
                  disableClearable
                  value={externalProviders.data.find((y) => y.operator_id === x.provider) || null}
                  options={externalProviders.data}
                  disabled={isSubmitting}
                  onChange={(name, val) => handleUpdateLandlineNumber(val.operator_id, name, index)}
                  getOptionLabel={(option) => option.operator_name}
                  getOptionSelected={(option, value) => option.operator_id === value.operator_id}
                />
              </Grid>
            </Fragment>
          )
        })}
      <Box className={classes.portingBox} onClick={handleAddLandlineNumber}>
        <AddIcon color="inherit" />
        <Typography color="textSecondary"> {t('AddLandlineNumber')}</Typography>
      </Box>
      {values.numberSeries.length > 0 &&
        values.numberSeries.map((x, index) => {
          return (
            <Fragment key={x.id}>
              <Grid xs={1} item className={classes.removeContainer}>
                <IconButton edge="start" onClick={() => handleRemoveNumberSeries(x.id)}>
                  <RemoveIcon color="error" />
                </IconButton>
              </Grid>
              <Grid xs={3} item>
                <SkyTextField
                  label={t('From')}
                  name="from"
                  value={x.from}
                  onChange={(name, value) => handleUpdateNumberSeries(value, name, index)}
                  disabled={isSubmitting}
                  required
                />
              </Grid>
              <Grid xs={3} item>
                <SkyTextField
                  label={t('To')}
                  name="to"
                  value={x.to}
                  onChange={(name, value) => handleUpdateNumberSeries(value, name, index)}
                  disabled={isSubmitting}
                  required
                />
              </Grid>
              <Grid xs={5} item>
                <SkySelect
                  label={t('ReleasingOperator')}
                  required
                  name="provider"
                  disableClearable
                  value={externalProviders.data.find((y) => y.operator_id === x.provider) || null}
                  options={externalProviders.data}
                  disabled={isSubmitting}
                  onChange={(name, val) => handleUpdateNumberSeries(val.operator_id, name, index)}
                  getOptionLabel={(option) => option.operator_name}
                  getOptionSelected={(option, value) => option.operator_id === value.operator_id}
                />
              </Grid>
            </Fragment>
          )
        })}
      <Box className={classes.portingBox} onClick={handleAddNumberSeries}>
        <AddIcon color="inherit" />
        <Typography color="textSecondary"> {t('AddNumberSeries')}</Typography>
      </Box>
    </Grid>
  )

  if (externalProviders.error && externalProviders.error.statusText) {
    content = <Alert type="error" message={externalProviders.error.statusText} />
  }

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title={t('PortNumbers')}
      description={t('PortDescription')}
    >
      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault()
          handleSubmit()
        }}
      >
        <div style={{ paddingTop: 1, paddingBottom: 24 }}>{content}</div>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            className={classes.cancelButton}
            disabled={isSubmitting}
            onClick={handleClose}
          >
            {t('Drawer_create_sub_cancel')}
          </Button>
          <GradientButton loading={isSubmitting}>{t('Hardware_send')}</GradientButton>
        </div>
      </form>
    </DialogWrapper>
  )
}

export default PortDialog
