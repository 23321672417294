import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SwipeableDrawer } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '90%',
    maxWidth: '660px',
    paddingTop: theme.spacing(),
    overflowX: 'hidden',
    zIndex: 1600,
  },
  innerDrawer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(24),
  },
}))

const DrawerWrapper = ({ closeDrawer, children }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

  useEffect(() => {
    setOpen(true)
  }, [])

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      closeDrawer()
    }, 500)
  }

  const handleOnOpen = () => {}

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={handleOnOpen}
        onClose={handleClose}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        classes={{
          paper: classes.drawer,
        }}
        transitionDuration={500}
      >
        <div className={classes.innerDrawer}>{children}</div>
      </SwipeableDrawer>
    </div>
  )
}

export default DrawerWrapper
