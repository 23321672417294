import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'authorizations/FETCH_FAILURE'
export const FETCH_BEGIN = 'authorizations/FETCH_BEGIN'
export const FETCH_SUCCESS = 'authorizations/FETCH_SUCCESS'

export const fetchAuthorizationsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAuthorizationsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchAuthorizationsSuccess = ({ data, customerId }) => {
  return {
    type: FETCH_SUCCESS,
    data,
    customerId,
  }
}

export const fetchAuthorizations = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchAuthorizationsBegin({ customerId }))
    try {
      const response = await getCustomer.authorizations({ customerId })
      dispatch(fetchAuthorizationsSuccess({ data: response.data, customerId }))
    } catch (err) {
      dispatch(fetchAuthorizationsFailure(err.response))
    }
  }
}
