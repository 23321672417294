import React from 'react'
import classNames from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  withHeader: {
    paddingTop: 0,
  },
  noPadding: {
    padding: 0,
  },
}))

interface Props {
  className?: string
  children: JSX.Element | JSX.Element[] | string
  noPadding?: boolean
  withHeader?: boolean
}

const PaperContent = ({ className, children, noPadding, withHeader = false }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noPadding]: noPadding,
      [classes.withHeader]: withHeader,
    },
    className
  )

  return <div className={rootClassName}>{children}</div>
}

export default PaperContent
