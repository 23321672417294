import React from 'react'
import classNames from 'classnames'
import { InputAdornment, CircularProgress } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { FilledInputProps } from '@material-ui/core/FilledInput'
import useInputStyle from '../../hooks/useInputStyle'

interface Props {
  white?: boolean
  withMargin?: boolean
  disabled?: boolean
  type?: string
  loading?: boolean
  name: string
  required?: boolean
  className?: string
  value?: string | number
  onChange: (name: string, value: string | number) => void
  helperText?: string
  error?: boolean
  label: string
  showRequiredAsterisk?: boolean
  // eslint-disable-next-line
  endAdornment?: any
  // eslint-disable-next-line
  inputComponent?: any
  autoFocus?: boolean
  placeholder?: string
}

const SkyTextField = ({
  white = true,
  withMargin = true,
  disabled,
  loading,
  name,
  className,
  required,
  autoFocus = false,
  type = 'text',
  value,
  onChange,
  helperText,
  error,
  label,
  showRequiredAsterisk = true,
  endAdornment,
  placeholder,
  inputComponent,
}: Props) => {
  const classes = useInputStyle()

  const root = classNames({
    [classes.root]: true,
    [classes.white]: white,
    className,
  })

  const onChangeWithName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(name, type === 'number' ? Number(event.target.value) : event.target.value)
  }

  const hasEndAdornment =
    (endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>) || null

  return (
    <div className={withMargin ? classes.withMargin : ''}>
      <TextField
        fullWidth
        disabled={disabled || loading}
        value={value}
        type={type}
        name={name}
        onChange={onChangeWithName}
        label={label}
        placeholder={placeholder}
        autoFocus={autoFocus}
        helperText={helperText}
        required={required}
        error={error}
        InputLabelProps={{ required: required ? showRequiredAsterisk : false }}
        InputProps={
          {
            inputComponent,
            endAdornment: loading ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : (
              hasEndAdornment
            ),
            classes: {
              root,
              focused: classes.focused,
              error: classes.error,
              disabled: classes.disabled,
            },
            disableUnderline: true,
          } as Partial<FilledInputProps>
        }
        variant="filled"
      />
    </div>
  )
}

export default SkyTextField
