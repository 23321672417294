import React, { useState, useEffect } from 'react'
import { updateCustomer, deleteCustomer } from 'services/sweb/customer'
import { fetchHuntgroupCallPatterns } from 'state/huntgroupCallPatterns/actions'
import { fetchHuntgroup } from 'state/huntgroup/actions'
import { fetchAvailableHuntgroupMembers } from 'state/availableHuntgroupMembers/actions'
import { shallowEqual, useSelector, useDispatch, batch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  DrawerWrapper,
  DrawerSection,
  PaperLoader,
  PaperContent,
  Paper,
  PaperFeedbackMessage,
  CustomDialog,
} from 'components'
import { useSnackbar } from 'notistack'
import { DeleteOutline as DeleteIcon } from '@material-ui/icons'
import { Slide, IconButton } from '@material-ui/core'
import EditForm from '../EditForm'

const EditDrawer = ({ match, history, huntgroup }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const { customer_id: customerId } = match.params
  const stored = useSelector(
    (state) => ({
      huntgroup: state.huntgroup,
      availableHuntgroupMembers: state.availableHuntgroupMembers,
      huntgroupCallPatterns: state.huntgroupCallPatterns,
    }),
    shallowEqual
  )

  useEffect(() => {
    batch(() => {
      dispatch(fetchHuntgroup({ customerId, number: huntgroup.group_number }))
      dispatch(fetchAvailableHuntgroupMembers({ customerId, number: huntgroup.group_number }))
      dispatch(fetchHuntgroupCallPatterns({ customerId }))
    })
  }, [])

  const closeDrawer = () => {
    history.push(`/${match.params.customer_id}/organisation/mobile-ringegrupper`)
  }

  const handleUpdateHuntgroup = (data) => {
    setLoading(true)
    const json = {
      rule: data.rule.rule || data.rule,
      members: data.members,
      group_name: huntgroup.group_name,
      group_number: huntgroup.group_number,
    }
    updateCustomer
      .huntgroup({ customerId: match.params.customer_id, json })
      .then(() => {
        enqueueSnackbar(t('Organization_huntgroups_updated'), {
          variant: 'success',
        })
        setLoading(false)
        closeDrawer()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  const handleDeleteHuntgroup = () => {
    setModalOpen(false)
    setLoading(true)
    deleteCustomer
      .huntgroup({ customerId: match.params.customer_id, number: huntgroup.group_number })
      .then(() => {
        enqueueSnackbar(t('Organization_huntgroups_deleted'), {
          variant: 'success',
        })
        setLoading(false)
        closeDrawer()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }
  const deletePrompt = () => {
    setModalOpen(true)
  }

  const errorOccured =
    stored.huntgroup.error ||
    stored.availableHuntgroupMembers.error ||
    stored.huntgroupCallPatterns.error
  const dataResolved =
    stored.huntgroup.data &&
    stored.huntgroupCallPatterns.data &&
    stored.availableHuntgroupMembers.data

  let content = <PaperLoader />

  if (errorOccured) {
    content = (
      <>
        {stored.huntgroup.error && (
          <PaperFeedbackMessage
            type="error"
            message={t('Organization_huntgroups_fetch_error', {
              error: stored.huntgroup.error.statusText,
            })}
          />
        )}
        {stored.huntgroupCallPatterns.error && (
          <PaperFeedbackMessage
            type="error"
            message={t('Organization_huntgroups_call_patterns_error', {
              error: stored.huntgroupCallPatterns.error.statusText,
            })}
          />
        )}
        {stored.availableHuntgroupMembers.error && (
          <PaperFeedbackMessage
            type="error"
            message={t('Organization_huntgroups_members_error', {
              error: stored.availableHuntgroupMembers.error.statusText,
            })}
          />
        )}
      </>
    )
  }

  if (dataResolved) {
    content = (
      <>
        <Slide direction="left" in>
          <div>
            <DrawerSection
              title={stored.huntgroup.data.group_name}
              toolbar={
                <IconButton edge="end" onClick={deletePrompt}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <EditForm
                loading={loading}
                huntgroup={stored.huntgroup.data}
                callPatterns={stored.huntgroupCallPatterns.data}
                availableMembers={stored.availableHuntgroupMembers.data}
                backLink={closeDrawer}
                updateHuntgroup={handleUpdateHuntgroup}
              />
            </DrawerSection>
          </div>
        </Slide>
        <CustomDialog
          isOpen={modalOpen}
          onConfirm={handleDeleteHuntgroup}
          onCancel={() => setModalOpen(false)}
          title={t('Organization_huntgroups_delete_prompt_title')}
          maxWidth="xs"
          contentText={t('Organization_huntgroups_delete_prompt_description')}
          confirmButtonText={t('Organization_huntgroups_delete_prompt_confirm')}
        />
      </>
    )
  }

  return (
    <DrawerWrapper closeDrawer={closeDrawer}>
      <Paper>
        <PaperContent>{content}</PaperContent>
      </Paper>
    </DrawerWrapper>
  )
}

export default EditDrawer
