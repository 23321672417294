import { getValidation } from 'services/sweb/validation'

export const FETCH_FAILURE = 'validateEmail/FETCH_FAILURE'
export const FETCH_BEGIN = 'validateEmail/FETCH_BEGIN'
export const FETCH_SUCCESS = 'validateEmail/FETCH_SUCCESS'

export const fetchValidateEmailFailure = ({ error, email }) => {
  return {
    type: FETCH_FAILURE,
    error,
    email,
  }
}

export const fetchValidateEmailBegin = () => {
  return {
    type: FETCH_BEGIN,
  }
}

export const fetchValidateEmailSuccess = ({ email, data }) => {
  return {
    type: FETCH_SUCCESS,
    data,
    email,
  }
}

export const fetchValidateEmail = ({ customerId, email }) => {
  return async (dispatch) => {
    dispatch(fetchValidateEmailBegin())
    try {
      const response = await getValidation.validateEmail({
        customerId,
        email,
      })
      const data = { ...response.data, is_valid: true } // according to api it should response with {is_valid: true}, but it returns null instead. until fix we do them this favor
      dispatch(fetchValidateEmailSuccess({ email, data }))
    } catch (err) {
      dispatch(fetchValidateEmailFailure({ error: err.response, email }))
    }
  }
}
