import React, { useEffect } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import DrawerSection from 'components/DrawerSection'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()
  useEffect(() => {
    changeDocumentTitle(
      t('Drawer_meta_title_not_found'),
      ' - ',
      ':customerName',
      ' - ',
      ':applicationName'
    )
  }, [])
  return <DrawerSection title={t('Drawer_not_found')} />
}

export default NotFound
