import shape from '../shape'

export default {
  text: {
    borderRadius: shape.borderRadius,
  },
  rect: {
    borderRadius: shape.borderRadius,
    backgroundColor: '#e8e8e8',
  },
}
