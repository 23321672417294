import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'orderOverview/FETCH_FAILURE'
export const FETCH_BEGIN = 'orderOverview/FETCH_BEGIN'
export const FETCH_SUCCESS = 'orderOverview/FETCH_SUCCESS'

export const fetchOrderOverviewFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchOrderOverviewBegin = ({ orderId }) => {
  return {
    type: FETCH_BEGIN,
    orderId,
  }
}

export const fetchOrderOverviewSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchOrderOverview = ({ customerId, type, orderId }) => {
  return async (dispatch) => {
    dispatch(fetchOrderOverviewBegin({ orderId }))
    try {
      const response = await getCustomer.orderOverview({
        customerId,
        type,
        orderId,
      })
      dispatch(fetchOrderOverviewSuccess(response.data))
    } catch (err) {
      dispatch(fetchOrderOverviewFailure(err.response))
    }
  }
}
