import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'

const a11yProps = (index) => {
  return {
    id: `navigation-tab-${index}`,
    'aria-controls': `navigation-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {},
  scroller: {
    borderBottom: `2px solid ${theme.palette.border}`,
    overflow: 'visible !important',
  },
  withMargin: {
    marginBottom: theme.spacing(5),
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: -2,
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(0.5),
    transition: theme.transitions.create(['color', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  tabSelected: {
    color: theme.palette.primary.main,
  },
}))

const PageTabs = ({
  currentValue,
  handleChange,
  tabs,
  rootClassName,
  withMargin,
  variant = 'standard',
  orientation = 'horizontal',
}) => {
  const classes = useStyles()

  const rootClassNameCombined = classNames([classes.root], {
    [classes.withMargin]: withMargin,
    rootClassName,
  })

  const handleTabChange = (_event, value) => {
    handleChange(value)
  }

  return (
    <Tabs
      orientation={orientation}
      variant={variant}
      value={currentValue}
      onChange={handleTabChange}
      aria-label="navigation tabs"
      TabIndicatorProps={{ children: <span /> }}
      classes={{
        root: rootClassNameCombined,
        indicator: classes.indicator,
        scroller: classes.scroller,
      }}
    >
      {tabs.map((x, index) => {
        return (
          <Tab
            key={x.value}
            {...x}
            {...a11yProps(index)}
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
          />
        )
      })}
    </Tabs>
  )
}

export default PageTabs
