import React from 'react'
import { TablePagination } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const EnhancedTablePagination = (props) => {
  const { t } = useTranslation()
  const { arrayLength, rowsPerPage, page, setPage, setRowsPerPage, entriesPerPageText } = props

  return (
    <TablePagination
      rowsPerPageOptions={[10, 20, 50]}
      component="div"
      count={arrayLength}
      rowsPerPage={rowsPerPage}
      page={page}
      labelRowsPerPage={entriesPerPageText}
      labelDisplayedRows={({ from, to, count }) => {
        return t('Invoice_displayed_rows', { from, to, count })
      }}
      backIconButtonProps={{
        'aria-label': t('Router_rpev'),
      }}
      nextIconButtonProps={{
        'aria-label': t('Router_next'),
      }}
      onChangePage={(event, newPage) => setPage(newPage)}
      onChangeRowsPerPage={(event) => {
        setPage(0)
        setRowsPerPage(+event.target.value)
      }}
    />
  )
}

export default EnhancedTablePagination
