import React, { Fragment, useState } from 'react'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const MobileQuickMenu = ({ popUserAction, mobileOneNumberShadowNumber }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { t } = useTranslation()
  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  const handlePopUserAction = (event, action) => {
    event.stopPropagation()
    setAnchorEl(null)
    popUserAction(event, action)
  }
  return (
    <>
      <IconButton edge="end" aria-label="Edit mobile subscription" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      {anchorEl && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={(event) => handlePopUserAction(event, 'skift-mobilpakke')}>
            {t('Dashboard_most_used_mobile')}
          </MenuItem>
          <MenuItem onClick={(event) => handlePopUserAction(event, 'simkort')}>
            {t('Users_dsffdsfd')}
          </MenuItem>
          {!mobileOneNumberShadowNumber && (
            <>
              <MenuItem onClick={(event) => handlePopUserAction(event, 'mobil-nummervisning')}>
                {t('Users_fsdr')}
              </MenuItem>
              <MenuItem onClick={(event) => handlePopUserAction(event, 'mobil-viderestilling')}>
                {t('Users_hgj')}
              </MenuItem>
            </>
          )}
        </Menu>
      )}
    </>
  )
}

export default MobileQuickMenu
