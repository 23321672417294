import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  primary: {
    fontSize: 20,
  },
  list: {
    paddingTop: 0,
  },
  content: {
    marginBottom: theme.spacing(3),
  },
}))

const ChangeContainer = ({ label, children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <List className={classes.list}>
        <ListItem disableGutters>
          <ListItemText
            primary={label}
            classes={{
              primary: classes.primary,
            }}
          />
        </ListItem>
      </List>
      <Box className={classes.content}>{children}</Box>
    </Box>
  )
}

export default ChangeContainer
