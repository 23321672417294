import React from 'react'
import MUIDataTable from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import MuiDataTableHead from './MuiDataTableHead'
import CustomSearchBar from './CustomSearchBar'

const MuiDataTable = ({ columns, options, data }) => {
  const { t } = useTranslation()
  const { searchInAllTable } = options || { searchInAllTable: false }
  const queryParams = new URLSearchParams(window.location.search)
  const defaultOptions = {
    responsive: 'scrollFullHeight',
    ...(!searchInAllTable && {
      customSearch: (searchQuery: string, currentRow: Array<string>) => {
        const numberExist =
          currentRow[0]?.replace(/\s/g, '').includes(searchQuery) ||
          currentRow[0]?.includes(searchQuery)
        return numberExist
      },
    }),

    customSearchRender: (searchText, handleSearch, hideSearch, optionss) => {
      return (
        <CustomSearchBar searchText={searchText} handleSearch={handleSearch} options={optionss} />
      )
    },
    searchText: localStorage.getItem('searchWord'),
    elevation: 0,
    fixedHeader: false,
    print: false,
    textLabels: {
      body: {
        noMatch: t('Organization_customer_select_match'),
        toolTip: '',
      },
      pagination: {
        next: t('Router_next'),
        previous: t('Router_rpev'),
        rowsPerPage: t('Invoice_rowsperpage'),
        displayRows: t('Router_of'),
      },
      toolbar: {
        search: t('Router_searche'),
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: t('Router_columns'),
        filterTable: t('Router_seadrch3'),
      },
      filter: {
        all: t('Router_search2'),
        title: t('Router_search5'),
        reset: t('Router_setgdrarch'),
      },
      viewColumns: {
        title: t('Router_searc7h'),
        titleAria: t('Router_columns'),
      },
      selectedRows: {
        text: t('Router_search3'),
        delete: t('Router_search'),
        deleteAria: t('Router_searrytech'),
      },
    },
  }

  const combinedOptions = {
    ...options,
    ...defaultOptions,
  }

  const newColumns = columns.map((column) => {
    return {
      ...column,
      options: {
        ...column.options,
        customHeadRender: (columnMeta, updateDirection) => (
          <MuiDataTableHead
            key={columnMeta.index}
            align={column.align}
            hint={columnMeta.hint}
            sortDirection={columnMeta.sortDirection}
            index={columnMeta.index}
            label={columnMeta.label}
            isSortable={columnMeta.sort}
            updateDirection={updateDirection}
            width={column.width}
          />
        ),
        setCellProps: () => {
          return {
            align: column.align,
            width: column.width || null,
          }
        },
      },
    }
  })

  return <MUIDataTable data={data} columns={newColumns} options={combinedOptions} />
}

export default MuiDataTable
