import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  IconButton,
  ListItemIcon,
} from '@material-ui/core'
import { FilterList as MoreIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  removePadding: {
    padding: '0px',
  },
  heading: {
    fontSize: theme.typography.body1.fontSize,
  },
  root: {
    textAlign: 'right',
    marginTop: theme.spacing(0.6),
    marginBottom: theme.spacing(),
  },
  subscriptionFees: {
    color: theme.palette.primary.main,
  },
  mobileFees: {
    color: theme.palette.success.main,
  },
  fixedLineFees: {
    color: theme.palette.warning.main,
  },
  extraServiceFees: {
    color: theme.palette.error.main,
  },
}))

const roundToTwoDecimals = (number) => Math.round(number * 100) / 100

const createPercentageString = (percentage) => {
  if (percentage < 0) return `-${Math.abs(roundToTwoDecimals(percentage))}%`
  if (percentage > 0) return `+${roundToTwoDecimals(percentage)}%`
  if (percentage === 0) return `${roundToTwoDecimals(percentage)}%`
  return null
}

const SecondaryTextWithTooltip = ({ percentage, ofTotal }) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      title={
        <>
          <Typography color="inherit" variant="inherit" display="block">
            {t('Invoice_comparison', { diff: percentage })}
          </Typography>
          <Typography color="inherit" variant="inherit" display="block">
            {t('Invoice_percentage_of_total', { ofTotal })}
          </Typography>
        </>
      }
    >
      <Typography component="span" variant="body2" color="textSecondary">
        {percentage}
      </Typography>
    </Tooltip>
  )
}

const NumberTableCell = ({
  current,
  percentageDifference,
  percentageOfTotal,
  type,
  invoiceId,
  goToInvoiceRoute,
  ski,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const showPercentage =
    percentageDifference !== 0 && Boolean(percentageDifference > 0 || percentageDifference < 0)

  const handleGoToInvoiceRoute = () => {
    goToInvoiceRoute(invoiceId, type)
  }

  return (
    <List className={classes.removePadding}>
      <ListItem className={classes.removePadding}>
        <ListItemText
          classes={{
            primary: classNames([classes.heading], {
              [classes.subscriptionFees]: type === 'abonnementer',
              [classes.mobileFees]: type === 'mobil',
              [classes.fixedLineFees]: type === 'fastnet',
              [classes.extraServiceFees]: type === 'ekstra-services',
            }),
            root: classes.root,
          }}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
          primary={current.toLocaleString('da-DK', { minimumFractionDigits: 2 })}
          secondary={
            showPercentage && (
              <SecondaryTextWithTooltip
                percentage={createPercentageString(percentageDifference)}
                ofTotal={roundToTwoDecimals(percentageOfTotal)}
              />
            )
          }
        />
        <ListItemIcon>
          <Tooltip title={t('Invoice_specs')}>
            <IconButton edge="end" onClick={handleGoToInvoiceRoute}>
              <MoreIcon />
            </IconButton>
          </Tooltip>
        </ListItemIcon>
      </ListItem>
    </List>
  )
}

export default NumberTableCell
