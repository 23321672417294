import React from 'react'
import classNames from 'classnames'
import { ListSubheader } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  listSubheader: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: 1,
  },
  withMargin: {
    marginTop: theme.spacing(2),
  },
}))

interface Props {
  label: string
  withMargin?: boolean
}

const ListSubHeader = ({ label, withMargin = false }: Props) => {
  const classes = useStyles()

  const listSubheaderClasses = classNames([classes.listSubheader], {
    [classes.withMargin]: withMargin,
  })

  return (
    <ListSubheader component="div" className={listSubheaderClasses}>
      {label}
    </ListSubheader>
  )
}

export default ListSubHeader
