import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { PhonelinkRingOutlined as NumberMaskIcon } from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'
import { phoneNumberFormat } from 'utils/helpers'

const DisplayNumber = ({ currentUrl, disabled }) => {
  const { t } = useTranslation()
  const mobileDisplayNumber = useSelector((state) => state.mobileDisplayNumber)

  return (
    <DrawerListItem
      icon={<NumberMaskIcon />}
      primaryText={t('Drawer_user_number_shown')}
      secondaryText={
        mobileDisplayNumber.data && !mobileDisplayNumber.error
          ? `+${mobileDisplayNumber.data.prefix} ${phoneNumberFormat(
              mobileDisplayNumber.data.number
            )}`
          : t('Drawer_user_err')
      }
      isLoading={Boolean(!mobileDisplayNumber.data)}
      isDisabled={disabled}
      link={`${currentUrl}/mobil-nummervisning`}
      linkAnchor={mobileDisplayNumber.error ? t('Drawer_user_see_more') : t('Drawer_user_skift')}
    />
  )
}

export default memo(DisplayNumber)
