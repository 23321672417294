import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Slide, IconButton } from '@material-ui/core'
import { ArrowBackRounded as ArrowBackIcon } from '@material-ui/icons'
import { Paper, PaperContent, LoadingSpinner } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'

import FunctionList from './FunctionList'
import UserSearch from './UserSearch'

const useStyles = makeStyles((theme) => ({
  setMaxHeight: {
    height: '100%',
    overflow: 'hidden',
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  msg: {
    paddingLeft: theme.spacing(2),
  },
  iconBtn: {
    marginTop: -theme.spacing(),
    marginLeft: -theme.spacing(0.8),
  },
  alertContainer: {
    margin: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}))

const MostUsedFunctions = ({ users, changePage }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [action, setAction] = useState(null)

  const handleClearAction = () => {
    setAction(null)
  }

  let content = <LoadingSpinner />

  if (!action) {
    content = (
      <FunctionList
        setAction={setAction}
        disabled={users.error || (users.data && users.data.length === 0) || !users.data}
      />
    )
  }

  if (action) {
    content = (
      <Slide in direction="left">
        <div>
          <UserSearch
            users={users.data}
            setAction={setAction}
            action={action}
            changePage={changePage}
          />
        </div>
      </Slide>
    )
  }

  return (
    <Paper className={classes.setMaxHeight}>
      <Paper className={classes.setMaxHeight}>
        <PaperContent noPadding>
          <Typography className={classes.title} variant="body2">
            {action ? (
              <IconButton
                edge="start"
                aria-label="back"
                className={classes.iconBtn}
                size="small"
                onClick={handleClearAction}
              >
                <ArrowBackIcon fontSize="inherit" />
              </IconButton>
            ) : (
              t('Dashboard_most_used_title')
            )}
          </Typography>
          {content}
        </PaperContent>
      </Paper>
    </Paper>
  )
}

export default MostUsedFunctions
