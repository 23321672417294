import { makeStyles, Theme } from '@material-ui/core/styles'

// import useButtonStyle from '..'
// const buttons = useButtonStyle()
// <... className={buttons.gradient} on button you want to transform

export default makeStyles((theme: Theme) => ({
  gradient: {
    background: 'linear-gradient(#3ae5bb, #30bf9b)',
    border: 0,
    color: 'white',
    boxShadow: 'none',
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      opacity: '0.8',
      boxShadow: 'none',
    },
    '&:active': {
      opacity: '0.8',
      transform: 'translateY(.0625rem)',
      boxShadow: 'none',
    },
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      background: 'rgba(0, 0, 0, 0.12)',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  primary: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
    border: 'none',
    '&:hover': {
      background: theme.palette.primary.light,
      opacity: '0.7',
    },
    '&:disabled': {
      border: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  warning: {
    background: theme.palette.warning.light,
    color: theme.palette.warning.main,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
    border: 'none',
    '&:hover': {
      background: theme.palette.warning.light,
      opacity: '0.7',
    },
    '&:disabled': {
      border: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  error: {
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
    border: 'none',
    '&:hover': {
      background: theme.palette.error.light,
      opacity: '0.7',
    },
    '&:disabled': {
      border: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  success: {
    background: theme.palette.success.light,
    color: theme.palette.success.main,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
    border: 'none',
    '&:hover': {
      background: theme.palette.success.light,
      opacity: '0.7',
    },
    '&:disabled': {
      border: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  neutral: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
    border: 'none',
    '&:hover': {
      background: theme.palette.primary.light,
      opacity: '0.7',
    },
    '&:disabled': {
      border: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
}))
