import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'portfolio/FETCH_FAILURE'
export const FETCH_BEGIN = 'portfolio/FETCH_BEGIN'
export const FETCH_SUCCESS = 'portfolio/FETCH_SUCCESS'

export const fetchPsWebScriptsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchPsWebScriptsBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchPsWebScriptsSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchPsWebScripts = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchPsWebScriptsBegin({ customerId }))
    try {
      const response = await getCustomer.psWebScripts({ customerId })
      dispatch(fetchPsWebScriptsSuccess(response.data))
    } catch (err) {
      dispatch(fetchPsWebScriptsFailure(err.response))
    }
  }
}
