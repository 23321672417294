import { getSubscription } from 'services/sweb/subscription'

export const FETCH_FAILURE = 'displayNumberAvailable/FETCH_FAILURE'
export const FETCH_BEGIN = 'displayNumberAvailable/FETCH_BEGIN'
export const FETCH_SUCCESS = 'displayNumberAvailable/FETCH_SUCCESS'

export const fetchDisplayNumberAvailableFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchDisplayNumberAvailableBegin = ({ number }) => {
  return {
    type: FETCH_BEGIN,
    number,
  }
}

export const fetchDisplayNumberAvailableSuccess = ({ number, data }) => {
  return {
    type: FETCH_SUCCESS,
    number,
    data,
  }
}

export const fetchDisplayNumberAvailable = (number: string) => {
  return async (dispatch) => {
    dispatch(fetchDisplayNumberAvailableBegin({ number }))
    try {
      const response = await getSubscription.displayNumberAvailable(number)
      dispatch(fetchDisplayNumberAvailableSuccess({ number, data: response.data }))
    } catch (err) {
      dispatch(fetchDisplayNumberAvailableFailure(err.response))
    }
  }
}
