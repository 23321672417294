import React from 'react'
import classNames from 'classnames'
import { Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    justifyContent: 'space-between',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  toolbar: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
  },
  content: {
    paddingTop: theme.spacing(),
    padding: theme.spacing(2),
  },
}))

interface Props {
  title?: string
  toolbar?: JSX.Element | string
  className?: string
  children?: JSX.Element | JSX.Element[] | string
}

const Section = ({ title, toolbar, className, children }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
    },
    className
  )

  return (
    <>
      {title && (
        <div className={rootClassName}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          {toolbar && <div className={classes.toolbar}>{toolbar}</div>}
        </div>
      )}
      {children && <div className={classes.content}>{children}</div>}
    </>
  )
}

export default Section
