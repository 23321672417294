import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List } from '@material-ui/core'
import {
  LocalOfferOutlined as TitleIcon,
  GroupOutlined as TeamIcon,
  BusinessCenterOutlined as BusinessIcon,
  CloudOffOutlined as NoAzureLicenseIcon,
  LinkOutlined as AzureIcon,
  LinkOffOutlined as BrokenAzureIcon,
} from '@material-ui/icons'
import DrawerListItem from 'components/DrawerListItem'
import { useTranslation } from 'react-i18next'
import OfficeItem from './OfficeItem'

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}))

const BaseInformation = ({ jobTitle, department, azureId, abbId, customerName }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <List disablePadding>
      <DrawerListItem
        icon={<BusinessIcon />}
        primaryText={t('Organization_title')}
        secondaryText={customerName || t('Drawer_mypage_no_info')}
      />
      <DrawerListItem
        icon={<TitleIcon />}
        primaryText={t('Drawer_user_base_title')}
        secondaryText={jobTitle || t('Drawer_user_missing_info')}
      />
      <DrawerListItem
        icon={<TeamIcon />}
        primaryText={t('Drawer_user_base_department')}
        secondaryText={department || t('Drawer_user_missing_info')}
      />
      <DrawerListItem
        icon={azureId ? <AzureIcon /> : <BrokenAzureIcon className={classes.error} />}
        primaryText={t('Drawer_user_base_azure_link')}
        secondaryText={azureId || t('Drawer_user_base_azure_link_desc')}
      />
      {abbId && azureId ? (
        <OfficeItem abbId={abbId} />
      ) : (
        <DrawerListItem
          icon={<NoAzureLicenseIcon className={classes.error} />}
          primaryText={t('Drawer_user_license')}
          secondaryText={t('Drawer_user_base_azure_link_desc')}
        />
      )}
    </List>
  )
}

export default memo(BaseInformation)
