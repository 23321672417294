import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  LinearProgress,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {
  RadioButtonUncheckedRounded as UncheckedIcon,
  CheckCircleOutlineRounded as CheckedIcon,
  ChevronRightRounded as ChevronRightIcon,
  ClearRounded as FailedIcon,
} from '@material-ui/icons'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { fetchUsers } from 'state/users/actions'
import { useSnackbar } from 'notistack'
import { updateSubscription } from 'services/sweb/subscription'
import { fetchAvailableDisplayNumbers } from 'state/availableDisplayNumbers/actions'
import { PaperLoader, PaperFeedbackMessage, GradientButton } from 'components'
import { useTranslation } from 'react-i18next'
import EligibleCount from '../EligibleCount'

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: theme.shape.borderRadius,
    minHeight: '63px',
    marginBottom: theme.spacing(0.5),
  },
  listItemAvatar: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 46,
  },
  selected: {
    width: 34,
    height: 34,
    color: theme.palette.success.main,
  },
  notSelected: {
    width: 34,
    height: 34,
    color: theme.palette.text.secondary,
    opacity: 0.7,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    marginRight: -theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(),
  },
  progressContainer: {
    marginTop: theme.spacing(3),
  },
  progressBar: {
    height: 10,
  },
  alertContainer: {
    marginTop: theme.spacing(3),
  },
  userAction: {
    display: 'flex',
    alignItems: 'center',
    marginRight: -theme.spacing(),
  },
  error: {
    color: theme.palette.error.main,
  },
  link: {
    marginTop: theme.spacing(),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
}))

const SetGroupNumbers = ({ selectedUsers, customerId, handleClose }) => {
  const classes = useStyles()
  const { push } = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const availableDisplayNumbers = useSelector((state) => state.availableDisplayNumbers)

  const [progress, setProgress] = useState(0)
  const [showSuccessUsers, setShowSuccessUsers] = useState(false)

  // eligible users just needs a subscription. Doesn't matter if it's mobile or landline or both
  const eligibleUsers = selectedUsers.filter((x) => x.abbId)

  useEffect(() => {
    changeDocumentTitle(
      t('BulkUpdateGroupNumbersTitle'),
      ' - ',
      ':customerName',
      ' - ',
      ':applicationName'
    )
    dispatch(
      fetchAvailableDisplayNumbers({
        customerId,
      })
    )
  }, [])

  const { setFieldValue, handleSubmit, values, isSubmitting, errors, setErrors } = useFormik({
    initialValues: {
      anis: [],
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      let prog = 0
      const promises = eligibleUsers.map((x) => {
        return updateSubscription
          .setGroupNumbers({
            customerId,
            abbId: x.abbId,
            json: payload.anis,
          })
          .then((response) => {
            return { ...response, abbId: x.abbId }
          })
          .catch((err) => {
            const error = { ...err }
            return { error: error.response, abbId: x.abbId }
          })
          .finally(() => {
            prog += 1
            setProgress(prog)
          })
      })
      const response = await Promise.all(promises)
      const findErrors = response.filter((x) => x.status !== 'SUCCESS')
      if (findErrors.length > 0) {
        setErrors({ anis: findErrors })
        setProgress(0)
      } else {
        dispatch(fetchUsers({ customerId }))
        enqueueSnackbar(t('BulkUpdateSuccess'), { variant: 'success' })
        handleClose()
      }
    },
  })

  const handleSelect = (ani) => {
    const newAnis = values.anis.find((x) => x === ani)
      ? values.anis.filter((x) => x !== ani)
      : [...values.anis, ani]
    setFieldValue('anis', newAnis)
  }

  if (availableDisplayNumbers.error) {
    return (
      <PaperFeedbackMessage
        withMargin
        message={availableDisplayNumbers.error.message}
        type="error"
      />
    )
  }

  if (availableDisplayNumbers.data && availableDisplayNumbers.data.length === 0) {
    return (
      <PaperFeedbackMessage withMargin message={t('BulkUpdateGroupNumbersNoNumbers')} type="info" />
    )
  }

  const hasErrors = errors.anis && errors.anis.length > 0

  if (availableDisplayNumbers.data && availableDisplayNumbers.data.length > 0) {
    return (
      <>
        {hasErrors ? (
          <>
            <div className={classes.alertContainer}>
              <PaperFeedbackMessage
                withMargin
                message={t('BulkUpdateError', {
                  errorCount: errors.anis.length,
                  eligibleCount: eligibleUsers.length,
                })}
                type="warning"
              />
            </div>
            {eligibleUsers
              .filter((x) =>
                showSuccessUsers ? true : errors.anis.find((y) => y.abbId === x.abbId)
              )
              .map((x) => {
                const isErrorUser = Boolean(errors.anis.find((y) => y.abbId === x.abbId))
                return (
                  <ListItem
                    key={x.abbId}
                    button
                    onClick={() => push(`/${customerId}/brugere/${x.certainId}`)}
                    className={classes.listItem}
                  >
                    <ListItemAvatar className={classes.listItemAvatar}>
                      {isErrorUser ? (
                        <FailedIcon className={classes.error} />
                      ) : (
                        <CheckedIcon className={classes.selected} />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={x.name || 'No name'}
                      secondary={x.email || 'No e-mail'}
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.userAction}
                    >
                      {t('ShowUserBulk')} <ChevronRightIcon />
                    </Typography>
                  </ListItem>
                )
              })}
            {!showSuccessUsers && (
              <Typography
                color="primary"
                onClick={() => setShowSuccessUsers(true)}
                className={classes.link}
                variant="body2"
              >
                {t('ShowSuccessUsers')}
              </Typography>
            )}
            <div className={classes.buttonContainer}>
              <Button color="primary" onClick={handleClose}>
                {t('Users_jfhg')}
              </Button>
            </div>
          </>
        ) : (
          <>
            {eligibleUsers.length > 0 ? (
              <>
                <EligibleCount
                  totalCount={selectedUsers.length}
                  eligibleCount={eligibleUsers.length}
                />
                {availableDisplayNumbers.data.map((x) => {
                  const isSelected = Boolean(values.anis.find((y) => y === x.ani_id))
                  return (
                    <ListItem
                      key={x.ani_id}
                      button
                      onClick={() => handleSelect(x.ani_id)}
                      className={classes.listItem}
                      selected={isSelected}
                      disabled={isSubmitting}
                    >
                      <ListItemAvatar className={classes.listItemAvatar}>
                        {isSelected ? (
                          <CheckedIcon className={classes.selected} />
                        ) : (
                          <UncheckedIcon className={classes.notSelected} />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={`+${x.prefix} ${x.number}`}
                        secondary={x.description}
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="body2" color="textSecondary">
                          {isSelected ? t('Deselect') : t('Select')}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </>
            ) : (
              <PaperFeedbackMessage withMargin message={t('NoEligibleUsers')} type="warning" />
            )}
            {isSubmitting && (
              <div className={classes.progressContainer}>
                <LinearProgress
                  className={classes.progressBar}
                  variant="determinate"
                  value={(progress / eligibleUsers.length) * 100}
                />
              </div>
            )}
            <div className={classes.buttonContainer}>
              <Button
                className={classes.cancelButton}
                color="primary"
                onClick={handleClose}
                disabled={isSubmitting}
              >
                {t('Drawer_create_sub_cancel')}
              </Button>
              {eligibleUsers.length > 0 && (
                <GradientButton onClick={handleSubmit} loading={isSubmitting}>
                  {t('BulkUpdateUsers', { count: eligibleUsers.length })}
                </GradientButton>
              )}
            </div>
          </>
        )}
      </>
    )
  }

  return <PaperLoader />
}

export default SetGroupNumbers
