import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { createInvoice, updateInvoice } from 'services/sweb/invoice'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Typography,
  TextField,
} from '@material-ui/core'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons'
import CustomDialog from 'components/CustomDialog'
import { useTranslation } from 'react-i18next'

const ITEM_HEIGHT = 48

const useStyles = makeStyles((theme) => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
  icon: {
    width: 18,
    height: 18,
  },
  savingSpinner: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(),
    marginLeft: '-22px',
  },
  menuSeparator: {
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(),
  },
  link: {
    textDecoration: 'none',
  },
}))

const ReferencePopperNumbers = ({ value, updateValue, data, availableReferences }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [dialogOpen, setDialogOpen] = useState(false)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [newRef, setNewRef] = useState('')
  const [allAvailableReferences, setAllAvailableReferences] = useState(availableReferences || [])
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const sort = (alfabetic) => {
    const sortere = [...allAvailableReferences].sort((a, b) =>
      (alfabetic ? a : b).reference_name.localeCompare((alfabetic ? b : a).reference_name)
    )
    setAllAvailableReferences(sortere)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSetDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleNewRef = (event) => {
    setNewRef(event.target.value)
  }

  const handleRefUpdate = (updatedRef) => {
    setLoading(true)
    handleClose()

    const payload = {
      ani: { ani: data.updatedSubData.ani },
      reference: {
        reference_id: updatedRef.reference_id,
      },
    }
    const json = { items: [{ ...payload }] }

    updateInvoice
      .numberExtraInfoAndReference({ customerId: data.customerId, json })
      .then(() => {
        setLoading(false)
        updateValue(updatedRef.reference_name)
      })
      .catch((err) => {
        err.response.json().then((res) =>
          enqueueSnackbar(t('Invoice_ref_error', { error: res.error.text }), {
            variant: 'error',
          })
        )
        setLoading(false)
      })
  }

  const handleCreateNewRef = () => {
    setLoading(true)
    const json = {
      reference_name: newRef,
    }
    createInvoice
      .reference({ customerId: data.customerId, json })
      .then((response) => {
        setLoading(false)
        setDialogOpen(false)
        setNewRef('')
        handleRefUpdate(response.data)
        availableReferences.unshift(response.data)
      })
      .catch((err) => {
        err.response.json().then((res) =>
          enqueueSnackbar(t('Invoice_ref_error', { error: res.error.text }), {
            variant: 'error',
          })
        )
        setLoading(false)
      })
  }

  return data.updatedSubData?.ani ? (
    <div className={classes.noWrap}>
      {loading && <CircularProgress size={14} className={classes.savingSpinner} />}
      <Typography
        onClick={handleClick}
        component={value ? 'span' : 'i'}
        variant={value ? 'inherit' : 'body2'}
        color={loading || !value ? 'textSecondary' : 'inherit'}
      >
        {value || 'Ingen reference'}
      </Typography>
      <IconButton
        aria-label="setreference"
        aria-controls="long-menu"
        aria-haspopup="true"
        size="small"
        onClick={handleClick}
        disabled={loading}
      >
        <ArrowDropDownIcon className={classes.icon} />
      </IconButton>
      {anchorEl && (
        <>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
              },
            }}
          >
            <Link to="referencer" className={classes.link}>
              <MenuItem onClick={handleClose}>{t('Invoice_gotoref')}</MenuItem>
            </Link>
            <MenuItem onClick={handleSetDialogOpen}>{t('Invoice_newref')}</MenuItem>
            {allAvailableReferences.length > 0 && (
              <div>
                <MenuItem onClick={() => sort(true)}>A&#8594;Z</MenuItem>
                <MenuItem onClick={() => sort(false)}>Z&#8594;A</MenuItem>
              </div>
            )}
            <Typography variant="body2" color="textSecondary" className={classes.menuSeparator}>
              {t('Invoice_availaref')}
            </Typography>
            {allAvailableReferences.length > 0 ? (
              allAvailableReferences.map((ref) => (
                <MenuItem
                  key={ref.reference_id}
                  disabled={value === ref.reference_name}
                  onClick={() => handleRefUpdate(ref)}
                >
                  {ref.reference_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>{t('Invoice_noreftwo')}</MenuItem>
            )}
          </Menu>
          <CustomDialog
            isOpen={dialogOpen}
            onConfirm={handleCreateNewRef}
            onCancel={() => setDialogOpen(false)}
            title={t('Invoice_createref')}
            contentText={t('Invoice_refdesc')}
            confirmButtonText={t('Invoice_create')}
            confirmDisabled={Boolean(newRef.length < 2 || loading)}
          >
            <TextField
              fullWidth
              autoFocus
              label={t('Invoice_typeredf')}
              value={newRef}
              onChange={handleNewRef}
              margin="none"
              disabled={loading}
              required
            />
          </CustomDialog>
        </>
      )}
    </div>
  ) : null
}

export default ReferencePopperNumbers
