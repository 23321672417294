import React, { useState } from 'react'
import { createCustomer, updateCustomer } from 'services/sweb/customer'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { format } from 'date-fns'
import { GradientButton, SkyTextField, SkyDatePicker } from 'components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
  },
}))

const AdminUserModal = ({ isOpen, closeDialog, tenant, customerId, fetchSecrets, hasSecrets }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    login: '',
    pass: '',
    notBefore: null,
    notAfter: null,
  })

  const handleSetForm = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleNotBeforeDate = (date) => {
    setForm({ ...form, notBefore: date })
  }

  const handleNotAfterDate = (date) => {
    setForm({ ...form, notAfter: date })
  }

  const handleAzureAdminUser = () => {
    setLoading(true)
    if (hasSecrets) {
      const json = {
        login: form.login,
        pass: form.pass,
        notAfter: format(form.notAfter, 'yyyy-MM-dd'),
        tenantName: `${tenant.name}.onmicrosoft.com`,
        tenantId: tenant.id,
      }
      updateCustomer
        .azureAdminSecret({ customerId, json })
        .then(() => {
          setLoading(false)
          fetchSecrets()
          closeDialog()
        })
        .catch((err) => {
          err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
          setLoading(false)
        })
    } else {
      const json = {
        ...form,
        notBefore: format(form.notBefore, 'yyyy-MM-dd'),
        notAfter: format(form.notAfter, 'yyyy-MM-dd'),
        tenantName: `${tenant.name}.onmicrosoft.com`,
        tenantId: tenant.id,
      }
      createCustomer
        .azureAdminSecret({ customerId, json })
        .then(() => {
          setLoading(false)
          fetchSecrets()
          closeDialog()
        })
        .catch((err) => {
          err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
          setLoading(false)
        })
    }
  }

  const emailHasError = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    form.login
  )
  const hasValues = Boolean(
    form.login.length > 2 && form.pass.length > 2 && form.notAfter && form.notBefore
  )
  const hasReplaceValues = Boolean(form.login.length > 2 && form.pass.length > 2 && form.notAfter)

  const disableButton = (hasSecrets && !hasReplaceValues) || (!hasSecrets && !hasValues)

  const showErr = !emailHasError && form.login.length > 4

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={closeDialog}>
      <DialogTitle disableTypography>{t('Wizard_addadmin')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="secondary" className={classes.text}>
          {t('Wizard_addadmindesc')}
        </Typography>
        {hasSecrets && (
          <Alert severity="info" style={{ marginBottom: 16 }}>
            {t('Replace_message')}
          </Alert>
        )}
        <SkyTextField
          value={form.login}
          name="login"
          onChange={handleSetForm}
          disabled={loading}
          label="Login e-mail"
          error={showErr}
          helperText={showErr && t('Wizard_valid_email')}
        />
        <SkyTextField
          value={form.pass}
          name="pass"
          onChange={handleSetForm}
          disabled={loading}
          label="Login password"
        />
        {!hasSecrets && (
          <SkyDatePicker
            label={t('Wizard_expiredatefrom')}
            value={form.notBefore}
            disablePast
            disabled={loading}
            onChange={handleNotBeforeDate}
          />
        )}
        <SkyDatePicker
          label={t('Wizard_expiredateto')}
          value={form.notAfter}
          disabled={loading}
          disablePast
          onChange={handleNotAfterDate}
        />
        <Alert severity="warning">
          Når gyldighedsdatoen nås, vil Selvbetjening ikke længere kunne forbinde til jeres Azure
          AD. Det er derfor vigtigt at sætte gyldighedsdatoen tilpas ude i fremtiden, samt komme
          tilbage for at opdatere med en ny admin-bruger, når den nuværende udløber.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {t('Wizard_cancel')}
        </Button>
        <GradientButton
          onClick={handleAzureAdminUser}
          loading={loading}
          disabled={disableButton && !emailHasError}
        >
          {t('Wizard_add')}
        </GradientButton>
      </DialogActions>
    </Dialog>
  )
}

export default AdminUserModal
