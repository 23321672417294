import { getInvoice } from 'services/sweb/invoice'

export const FETCH_FAILURE = 'invoiceMobileIntSummary/FETCH_FAILURE'
export const FETCH_BEGIN = 'invoiceMobileIntSummary/FETCH_BEGIN'
export const FETCH_SUCCESS = 'invoiceMobileIntSummary/FETCH_SUCCESS'

export const fetchInvoiceMobileIntSummaryFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: { error },
  }
}

export const fetchInvoiceMobileIntSummaryBegin = ({ invoiceId }) => {
  return {
    type: FETCH_BEGIN,
    invoiceId,
  }
}

export const fetchInvoiceMobileIntSummarySuccess = ({ invoiceId, response }) => {
  return {
    type: FETCH_SUCCESS,
    payload: response.data,
    invoiceId,
  }
}

export const fetchInvoiceMobileIntSummary = ({ customerId, invoiceId }) => {
  return async (dispatch) => {
    dispatch(fetchInvoiceMobileIntSummaryBegin({ invoiceId }))
    const type = 'mobile'
    try {
      const response = await getInvoice.internationalSummary({ customerId, invoiceId, type })
      dispatch(fetchInvoiceMobileIntSummarySuccess({ invoiceId, response }))
    } catch (err) {
      if (err.response instanceof Response === false) throw err
      dispatch(fetchInvoiceMobileIntSummaryFailure(err.response))
    }
  }
}
