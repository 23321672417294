import React, { useState, useRef } from 'react'
import { DonutChartWithText } from 'components'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}))

const UserCreated = ({ history, customerId }) => {
  const [timer, setTimer] = useState(5)
  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyles()
  const id = useRef(null)
  const handleRedirect = () => {
    history.push(`/${customerId}/operator-connect/numre`)
  }
  const clear = () => {
    window.clearInterval(id.current)
  }
  React.useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1)
    }, 1000)
    return () => clear()
  }, [])

  React.useEffect(() => {
    if (timer === 0) {
      clear()
      handleRedirect()
    }
  }, [timer])

  return (
    <div className={classes.container}>
      <Typography variant="body1">{t('OC_invite_user_created_title')}</Typography>
      <DonutChartWithText
        size={150}
        chartData={[5 - timer, timer]}
        backgroundColor={[theme.palette.primary.main, theme.palette.common.neutral]}
        textInsideDonut={`${timer}s`}
      />
      <Button color="primary" onClick={handleRedirect}>
        {t('OC_invite_user_created_button')}
      </Button>
    </div>
  )
}

export default UserCreated
