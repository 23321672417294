import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect, Link as RouterLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUsers } from 'state/users/actions'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { InfoRounded as InfoIcon, PersonAdd as AddUserIcon } from '@material-ui/icons'
import { Button, IconButton, Tooltip, Link } from '@material-ui/core'
import { PaperContent, PageTitle, Alert, Paper } from '@skytdc/mui/components'
import { useTranslation } from 'react-i18next'

import Drawer from 'views/Drawer'
import { phoneNumberFormat } from 'utils/helpers'
import Layout from '../Layout'
import UsersTable from './components/UsersTable'
import AdGroupFilter from './components/AdGroupFilter'

const Users = ({ title, match, history, location }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [usersCalled, setUsersCalled] = useState(false)
  const { users, broadworks } = useSelector((state) => ({
    users: state.users,
    broadworks: state.customer.data?.customer_properties?.broadworks,
  }))
  const routeIsUsers = Boolean(match.url.endsWith('/brugere') || match.url.endsWith('/brugere/'))
  const customerIdsMatch = Boolean(users.customerId === match.params.customer_id)

  useEffect(() => {
    if (routeIsUsers) {
      changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
      if (!users.loading && usersCalled)
        dispatch(fetchUsers({ customerId: match.params.customer_id }))
    }
    if (users.loading) setUsersCalled(true)
    if (!users.loading && !usersCalled) {
      dispatch(fetchUsers({ customerId: match.params.customer_id }))
      setUsersCalled(true)
    }
  }, [match])

  const allUserData = users.data?.map((data) => ({
    ...data,
    mobileNumber: phoneNumberFormat(data.mobileNumber),
    teamsDisplayNumber: phoneNumberFormat(data.teamsDisplayNumber),
  }))

  let content = (
    <>
      <UsersTable
        storedUsers={allUserData || []}
        routeIsUsers={routeIsUsers}
        customerId={match.params.customer_id}
        loading={(!users.data && !users.error) || !customerIdsMatch}
        history={history}
        location={location}
      />
      <Switch>
        {allUserData &&
          allUserData.map((user) => {
            return (
              <Route
                key={user.certainId}
                render={(props) => <Drawer user={user} {...props} />}
                exact
                path={`${match.path}/${user.certainId}/:requested_action?`}
              />
            )
          })}
        <Route
          render={(props) => <Drawer {...props} />}
          exact
          path={`${match.path}/:abbId/:requested_action?`}
        />
        <Redirect to={`/${match.params.customer_id}/brugere`} />
      </Switch>
    </>
  )

  if (users.error && users.error.status === 408) {
    content = (
      <Paper style={{ maxWidth: 600 }}>
        <PaperContent>
          <Alert type="error" message={t('Users_1')} withMargin />
          <AdGroupFilter customerId={match.params.customer_id} asButton />
        </PaperContent>
      </Paper>
    )
  }

  if (users.error && users.error.status === 500) {
    content = (
      <Paper style={{ maxWidth: 600 }}>
        <PaperContent>
          <Alert type="error" message={t('CheckSetupWizardError')} withMargin />
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            onClick={() => history.push(`/${match.params.customer_id}/setup`)}
          >
            {t('Organization_azure_cta')}
          </Button>
        </PaperContent>
      </Paper>
    )
  }

  if (users.error && users.error.status !== 500 && users.error.status !== 408) {
    content = (
      <Paper style={{ maxWidth: 600 }}>
        <PaperContent>
          <Alert type="error" message={users.error.statusText} />
        </PaperContent>
      </Paper>
    )
  }

  return (
    <Layout>
      <div style={{ maxWidth: 1000, margin: 'auto' }}>
        <PageTitle title={t('Drawer_header_users')}>
          <Tooltip
            placement="left"
            title={`${t('Users_desc')}. ${t('Users_desctwo')} ${t('Users_anchor')}`}
            arrow
          >
            <Link
              href="https://support.sky.tdc.dk/hc/da/articles/360009722940-S%C3%A5dan-henter-Teams-Selvbetjening-data-fra-jeres-Azure-AD"
              target="_blank"
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Link>
          </Tooltip>
          {!broadworks && (
            <Tooltip placement="left" title={t('Users_none_ad_desc')} arrow>
              <RouterLink to={`${location.pathname}/opret-bruger`}>
                <IconButton>
                  <AddUserIcon />
                </IconButton>
              </RouterLink>
            </Tooltip>
          )}
        </PageTitle>
        {content}
      </div>
    </Layout>
  )
}

export default Users
