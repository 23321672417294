import { getCustomer } from 'services/sweb/customer'

export const FETCH_FAILURE = 'availableAtRestMobileNumbers/FETCH_FAILURE'
export const FETCH_BEGIN = 'availableAtRestMobileNumbers/FETCH_BEGIN'
export const FETCH_SUCCESS = 'availableAtRestMobileNumbers/FETCH_SUCCESS'

export const fetchAvailableAtRestMobileNumbersFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    error,
  }
}

export const fetchAvailableAtRestMobileNumbersBegin = ({ customerId }) => {
  return {
    type: FETCH_BEGIN,
    customerId,
  }
}

export const fetchAvailableAtRestMobileNumbersSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    data,
  }
}

export const fetchAvailableAtRestMobileNumbers = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(fetchAvailableAtRestMobileNumbersBegin({ customerId }))
    try {
      const response = await getCustomer.availableAtRestMobileNumbers({
        customerId,
      })
      dispatch(fetchAvailableAtRestMobileNumbersSuccess(response.data))
    } catch (err) {
      dispatch(fetchAvailableAtRestMobileNumbersFailure(err.response))
    }
  }
}
