export default {
  LINEAR: {
    name: 'Organization_huntgroups_linear_title',
    description: 'Organization_huntgroups_linear_description',
  },
  CYCLIC: {
    name: 'Organization_huntgroups_circular_title',
    description: 'Organization_huntgroups_circular_description',
  },
  RANDOM: {
    name: 'Organization_huntgroups_random_title',
    description: 'Organization_huntgroups_random_description',
  },
  ALL_AT_ONCE: {
    name: 'Organization_huntgroups_concurrent_title',
    description: 'Organization_huntgroups_concurrent_description',
  },
}
