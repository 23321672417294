import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  LinearProgress,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {
  CheckCircleOutlineRounded as CheckedIcon,
  ChevronRightRounded as ChevronRightIcon,
  ClearRounded as FailedIcon,
} from '@material-ui/icons'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { updateSubscription } from 'services/sweb/subscription'
import { fetchAvailableDisplayNumbers } from 'state/availableDisplayNumbers/actions'
import { LoadingSpinner, Alert } from '@skytdc/mui/components'
import { GradientButton, SkySelect } from 'components'
import { useTranslation } from 'react-i18next'
import EligibleCount from '../EligibleCount'

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: theme.shape.borderRadius,
    minHeight: '63px',
    marginBottom: theme.spacing(0.5),
  },
  listItemAvatar: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 46,
  },
  selected: {
    width: 34,
    height: 34,
    color: theme.palette.success.main,
  },
  notSelected: {
    width: 34,
    height: 34,
    color: theme.palette.text.secondary,
    opacity: 0.7,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    marginRight: -theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(),
  },
  progressContainer: {
    marginTop: theme.spacing(3),
  },
  progressBar: {
    height: 10,
  },
  alertContainer: {
    marginTop: theme.spacing(3),
  },
  userAction: {
    display: 'flex',
    alignItems: 'center',
    marginRight: -theme.spacing(),
  },
  error: {
    color: theme.palette.error.main,
  },
  link: {
    marginTop: theme.spacing(),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
  radioGroup: {
    marginBottom: theme.spacing(2),
  },
}))

interface ValuesProps {
  displayNumber: 'ownLandline' | 'ownMobile' | 'ownIntNumber' | string
  displayNumberType: 'landline' | 'mobile'
}

const initialValues: ValuesProps = {
  displayNumber: '',
  displayNumberType: 'landline',
}

const SetDisplayNumber = ({ selectedUsers, customerId, handleClose }) => {
  const classes = useStyles()
  const { push } = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const availableDisplayNumbers = useSelector((state) => state.availableDisplayNumbers)

  const [progress, setProgress] = useState(0)
  const [showSuccessUsers, setShowSuccessUsers] = useState(false)
  const [errors, setErrors] = useState<{ abbId: number; error: string }[] | null>(null)

  useEffect(() => {
    changeDocumentTitle(
      t('Bulk_set_display_number'),
      ' - ',
      ':customerName',
      ' - ',
      ':applicationName'
    )
    dispatch(
      fetchAvailableDisplayNumbers({
        customerId,
      })
    )
  }, [])

  const { setFieldValue, setValues, handleSubmit, values, isSubmitting } = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      let prog = 0
      const promises = eligibleUsers.map((x) => {
        return updateSubscription
          .setDisplayNumber({
            customerId,
            phoneNumber:
              payload.displayNumberType === 'landline' ? x.landlineNumber : x.mobileNumber,
            displayNumber:
              (payload.displayNumber === 'ownLandline' && x.landlineNumber) ||
              (payload.displayNumber === 'ownMobile' && x.mobileNumber) ||
              (payload.displayNumber === 'ownIntNumber' && x.international_number) ||
              payload.displayNumber,
          })
          .then((response) => {
            return { ...response, abbId: x.abbId }
          })
          .catch((err) => {
            return err.response.json().then((decodedError) => {
              const errorString = decodedError.error.items
                .map((errString) => errString.key)
                .join(' ')
              return { error: errorString || t('DisplayNumberNotAvailable'), abbId: x.abbId }
            })
          })
          .finally(() => {
            prog += 1
            setProgress(prog)
          })
      })
      const response = await Promise.all(promises)
      const findErrors = response.filter((x) => x.status !== 'SUCCESS')
      if (findErrors.length > 0) {
        setErrors(findErrors)
        setProgress(0)
      } else {
        // dispatch(fetchUsers({ customerId }))
        enqueueSnackbar(t('BulkUpdateSuccess'), { variant: 'success' })
        handleClose()
      }
    },
  })

  const eligibleUsers = selectedUsers.filter((x) => {
    if (values.displayNumberType === 'landline') {
      // user needs to have a landline number
      if (values.displayNumber === 'ownLandline') {
        return Boolean(x.landlineNumber)
        // user needs to have both landline and mobile numbers
      }
      if (values.displayNumber === 'ownMobile') {
        return Boolean(x.landlineNumber && x.mobileNumber)
        // user needs an int number
      }
      if (values.displayNumber === 'ownIntNumber') {
        return Boolean(x.landlineNumber && x.international_number)
        // user needs a landline number for group numbers
      }
      if (values.displayNumber) {
        return Boolean(x.landlineNumber)
      }
      return false
    }
    if (
      values.displayNumber === 'ownMobile' ||
      (values.displayNumber && values.displayNumber !== 'ownLandline')
    ) {
      return Boolean(x.mobileNumber)
    }
    if (values.displayNumber === 'ownLandline') {
      return Boolean(x.landlineNumber && x.mobileNumber)
    }
    return false
  })

  const displayNumberOptions =
    values.displayNumberType === 'landline'
      ? [
          {
            label: t('UserOwnLandline'),
            group: '',
            value: 'ownLandline',
          },
          /* {
      label: t('UserOwnIntNumber'),
      group: '',
      value: 'ownIntNumber',
    }, */
          ...(availableDisplayNumbers.data?.map((x) => {
            return {
              label: x.number,
              group: t('Drawer_display_group_numbers'),
              value: x.number,
            }
          }) || []),
        ]
      : [
          {
            label: t('UserOwnMobile'),
            group: '',
            value: 'ownMobile',
          },
          {
            label: t('UserOwnLandline'),
            group: '',
            value: 'ownLandline',
          },
          ...(availableDisplayNumbers.data?.map((x) => {
            return {
              label: x.number,
              group: t('Drawer_display_group_numbers'),
              value: x.number,
            }
          }) || []),
        ]

  if (availableDisplayNumbers.error) {
    return <Alert withMargin message={availableDisplayNumbers.error.message} type="error" />
  }

  if (errors && errors.length > 0 && !isSubmitting) {
    return (
      <>
        <div className={classes.alertContainer}>
          <Alert
            withMargin
            message={t('BulkUpdateError', {
              errorCount: errors.length,
              eligibleCount: eligibleUsers.length,
            })}
            type="warning"
          />
        </div>
        {eligibleUsers
          .filter((x) => (showSuccessUsers ? true : errors.find((y) => y.abbId === x.abbId)))
          .map((x) => {
            const isErrorUser = errors.find((y) => y.abbId === x.abbId)
            return (
              <ListItem
                key={x.abbId}
                button
                onClick={() => push(`/${customerId}/brugere/${x.certainId}`)}
                className={classes.listItem}
              >
                <ListItemAvatar className={classes.listItemAvatar}>
                  {isErrorUser ? (
                    <FailedIcon className={classes.error} />
                  ) : (
                    <CheckedIcon className={classes.selected} />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={x.name || 'No name'}
                  secondary={
                    <>
                      <Typography
                        display="block"
                        component="span"
                        variant="body2"
                        color="textSecondary"
                      >
                        {x.email || 'No e-mail'}
                      </Typography>
                      {isErrorUser?.error && (
                        <Typography variant="body2" color="error" component="span" display="block">
                          <i>{`- ${isErrorUser.error}` || ''}</i>
                        </Typography>
                      )}
                    </>
                  }
                />
                <Typography variant="body2" color="textSecondary" className={classes.userAction}>
                  {t('ShowUserBulk')} <ChevronRightIcon />
                </Typography>
              </ListItem>
            )
          })}
        {!showSuccessUsers && eligibleUsers.length < errors.length && (
          <Typography
            color="primary"
            onClick={() => setShowSuccessUsers(true)}
            className={classes.link}
            variant="body2"
          >
            {t('ShowSuccessUsers')}
          </Typography>
        )}
        <div className={classes.buttonContainer}>
          <Button color="primary" onClick={handleClose}>
            {t('Users_jfhg')}
          </Button>
        </div>
      </>
    )
  }

  if (!availableDisplayNumbers.loading) {
    return (
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" className={classes.radioGroup}>
          <FormLabel component="legend" style={{ marginBottom: 8, paddingTop: 8 }}>
            {t('Bulk_set_display_number_type')}
          </FormLabel>
          <RadioGroup
            aria-label="selectdisplaynumbertype"
            name="displayNumberType"
            value={values.displayNumberType}
            onChange={(
              _event: React.ChangeEvent<HTMLInputElement>,
              value: ValuesProps['displayNumberType']
            ) =>
              setValues({
                displayNumberType: value,
                displayNumber: '',
              })
            }
          >
            <FormControlLabel
              value="landline"
              control={<Radio color="primary" />}
              label={t('Bulk_display_number_type_landline')}
              disabled={isSubmitting}
            />
            <FormControlLabel
              value="mobile"
              control={<Radio color="primary" />}
              label={t('Bulk_display_number_type_mobile')}
              disabled={isSubmitting}
            />
          </RadioGroup>
        </FormControl>
        {availableDisplayNumbers.data ? (
          <SkySelect
            label={t('SelectDisplayNumber')}
            value={displayNumberOptions.find((x) => x.value === values.displayNumber) || null}
            disableClearable
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.value === value.value}
            required
            onChange={(_event, val) => setFieldValue('displayNumber', val.value)}
            options={displayNumberOptions}
            groupBy={(option) => option.group}
            disabled={isSubmitting}
          />
        ) : (
          <Alert withMargin message={t('BulkUpdateGroupNumbersNoNumbers')} type="info" />
        )}
        {eligibleUsers.length > 0 ? (
          <EligibleCount
            totalCount={selectedUsers.length}
            eligibleCount={eligibleUsers.length}
            withMargin={false}
          />
        ) : (
          values.displayNumber && <Alert withMargin message={t('NoEligibleUsers')} type="warning" />
        )}
        {values.displayNumber &&
          availableDisplayNumbers.data?.find((x) => x.number === values.displayNumber) && (
            <Alert withMargin message={t('MustHaveGroupNumber')} type="warning" />
          )}
        {isSubmitting && (
          <div className={classes.progressContainer}>
            <LinearProgress
              className={classes.progressBar}
              variant="determinate"
              value={(progress / eligibleUsers.length) * 100}
            />
          </div>
        )}
        <div className={classes.buttonContainer}>
          <Button
            className={classes.cancelButton}
            color="primary"
            onClick={handleClose}
            disabled={isSubmitting}
          >
            {t('Drawer_create_sub_cancel')}
          </Button>
          <GradientButton loading={isSubmitting} disabled={eligibleUsers.length === 0}>
            {eligibleUsers.length > 0
              ? t('BulkUpdateUsers', { count: eligibleUsers.length })
              : t('Organization_huntgroups_members_update_label')}
          </GradientButton>
        </div>
      </form>
    )
  }

  return <LoadingSpinner />
}

export default SetDisplayNumber
