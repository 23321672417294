import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { List } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {},
}))

const DrawerList = ({ className, children }) => {
  const classes = useStyles()
  const classNamesString = classNames({ [classes.root]: true }, className)

  return (
    <div className={classNamesString}>
      <List disablePadding>{children}</List>
    </div>
  )
}

export default DrawerList
