import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TablePagination, TableBody, TableRow, TableCell, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '0px solid #fff',
  },
  toolbarPadding: {
    paddingRight: theme.spacing(2),
  },
  highlight: {
    fontWeight: 700,
  },
  sumCell: {
    paddingLeft: theme.spacing(3),
    borderTop: `2px solid ${theme.palette.background.default}`,
  },
}))

const TableFooterWithTotal = ({
  count,
  page,
  rowsPerPage,
  changeRowsPerPage,
  changePage,
  totalPrice,
  totalCount,
  filteredPrice,
  withPagination = true,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <TableBody>
      {count < totalCount && (
        <TableRow>
          <TableCell colSpan={5} className={classes.sumCell}>
            <Typography className={classes.highlight}>
              {t('Invoice_filtercount')}
              {count}
            </Typography>
          </TableCell>
          <TableCell className={classes.sumCell} align="right">
            <Typography className={classes.highlight} color="primary">
              {filteredPrice.toLocaleString('da-DK', { minimumFractionDigits: 2 })}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell colSpan={5} className={classes.sumCell}>
          <Typography className={classes.highlight}>
            {t('Invoice_count')}
            {totalCount}
          </Typography>
        </TableCell>
        <TableCell className={classes.sumCell} align="right">
          <Typography className={classes.highlight} color="primary">
            {totalPrice.toLocaleString('da-DK', { minimumFractionDigits: 2 })}
          </Typography>
        </TableCell>
      </TableRow>
      {withPagination && (
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[15, 30, 100]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            classes={{
              root: classes.root,
              toolbar: classes.toolbarPadding,
            }}
            labelRowsPerPage={t('Invoice_rowsperpage')}
            labelDisplayedRows={({ from, to }) => {
              return t('Invoice_displayed_rows', { from, to, count })
            }}
            backIconButtonProps={{
              'aria-label': 'Forrige side',
            }}
            nextIconButtonProps={{
              'aria-label': 'Næste side',
            }}
            onChangePage={(event, newPage) => changePage(newPage)}
            onChangeRowsPerPage={(event) => {
              changeRowsPerPage(+event.target.value)
            }}
          />
        </TableRow>
      )}
    </TableBody>
  )
}

export default TableFooterWithTotal
