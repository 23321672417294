import React, { useEffect, useState } from 'react'
import { getSubscription, deleteSubscription } from 'services/sweb/subscription'
import { useSnackbar } from 'notistack'
import { PaperLoader } from 'components'
import { useTranslation } from 'react-i18next'
import CancelSubscriptionForm from '../CancelSubscriptionForm'

const CancelSubscription = ({ user, subscription, customerId, changeRoute, goBack }) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [earliestCloseDate, setEarliestCloseDate] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    getSubscription
      .earliestCloseDate({ customerId, abbId: subscription.abb_id })
      .then((response) => {
        setEarliestCloseDate(response.data)
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
      })
  }, [])

  const cancelSubscription = (json) => {
    setLoading(true)
    deleteSubscription
      .cancelSubscription({
        customerId,
        abbId: subscription.abb_id,
        date: json.date,
        emailReceipt: json.emailReceipt,
        mobileCancelAction: json.mobile_cancel_action,
        landlineCancelAction: json.landline_cancel_action,
      })
      .then(() => {
        setLoading(false)
        enqueueSnackbar(t('Drawer_cancel_cancel_success', { date: json.date }), {
          variant: 'success',
        })
        changeRoute()
      })
      .catch((err) => {
        err.response.json().then((res) => enqueueSnackbar(res.error.text, { variant: 'error' }))
        setLoading(false)
      })
  }

  const hasMobile = Boolean(subscription.mobile_number)

  if (earliestCloseDate) {
    return (
      <CancelSubscriptionForm
        hasMobile={hasMobile}
        hasAzureId={user.azureId}
        receiptEmail={user.email || ''}
        earliestCloseDate={earliestCloseDate}
        cancelSubscription={cancelSubscription}
        loading={loading}
        backLink={goBack}
      />
    )
  }

  return <PaperLoader />
}

export default CancelSubscription
