import React, { useEffect } from 'react'
import changeDocumentTitle from 'routes/utils/changeDocumentTitle'
import { useSelector, useDispatch, batch } from 'react-redux'
import { fetchPortfolio } from 'state/portfolio/actions'
import { fetchLicenseDetails } from 'state/licenseDetails/actions'
import { fetchValidateEmail } from 'state/validateEmail/actions'
import { PaperLoader } from 'components'
import { useSnackbar } from 'notistack'
import { Slide } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import FormContainer from './FormContainer'

const CreateSubscription = ({ user, customerId, handleClose, changeRoute, title }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const stored = useSelector((state) => ({
    portfolio: state.portfolio,
    licenseDetails: state.licenseDetails,
    validateEmail: state.validateEmail,
  }))

  const [portfolio, licenseDetails] = [stored.portfolio.data, stored.licenseDetails.data]

  const orderInProgressMessage = t('Drawer_create_sub_order_in_progress', { name: user.name })
  const noEmailMessage = t('Drawer_create_sub_no_email', { name: user.name })

  useEffect(() => {
    if (!user.email) {
      enqueueSnackbar(noEmailMessage, { variant: 'info' })
      changeRoute()
    }
    changeDocumentTitle(t(title), ' - ', ':customerName', ' - ', ':applicationName')
    batch(() => {
      dispatch(fetchValidateEmail({ customerId, email: user.email }))
      dispatch(fetchPortfolio({ customerId }))
      dispatch(fetchLicenseDetails({ customerId, azureId: user.azureId }))
    })
  }, [])

  const emailIsAvailable =
    (stored.validateEmail.email === user.email && stored.validateEmail.data !== null) || !user.email
  const emailIsOccupied =
    stored.validateEmail.email === user.email && stored.validateEmail.error !== null

  useEffect(() => {
    if (user.email && emailIsOccupied && !user.abbId) {
      enqueueSnackbar(orderInProgressMessage, { variant: 'info' })
      changeRoute()
    }
  }, [stored.validateEmail])

  const displayFormContainer =
    emailIsAvailable &&
    stored.licenseDetails.azureId === user.azureId &&
    portfolio &&
    licenseDetails

  if (displayFormContainer) {
    return (
      <Slide direction="left" in>
        <div>
          <FormContainer
            user={user}
            customerId={customerId}
            handleClose={handleClose}
            licenseDetails={licenseDetails}
            mobilePackages={portfolio.mobile_packages}
            baseProducts={portfolio.base_product}
          />
        </div>
      </Slide>
    )
  }
  return <PaperLoader />
}

export default CreateSubscription
