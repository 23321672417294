export const getInviteStatus = (statusCode: number) => {
  switch (statusCode) {
    case 0:
      return 'user profile'
    case 1:
      return 'no user'
    case 2:
      return 'already completed'
    case 3:
      return 'is cancelled'
    case 4:
      return 'consent withdrawn'
    case 5:
      return 'customer_account_not_ready'
    default:
      return 'error'
  }
}

export const getMicrosoftLoginStatus = (statusCode: number) => {
  switch (statusCode) {
    case 0:
      return 'user created'
    case 1:
    case 2:
      return 'wrong user'
    case 3:
      return 'user not found'
    case 4:
      return 'not synced to support'
    default:
      return 'error'
  }
}
